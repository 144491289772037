import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { FacetState } from "modules/facets/types";
import { Scope } from "modules/scope-metadata/types";
import { CommonFieldScopeMapping, CommonScopes } from "./constants";
import { SavedQuery } from "./types";

export const getRecentQueryList = () => {
	const recentQueryList = window.localStorage.getItem("recentQueryList");
	let recentQueryData;
	if (recentQueryList) {
		recentQueryData = JSON.parse(recentQueryList);
	}
	return recentQueryData || [];
};

export const addRecentQuery = (query: SavedQuery) => {
	const recentQueryData = getRecentQueryList();
	const index = existingQueryIndex({ recentQueryData, query });
	if (index !== -1) {
		replaceOldQuery({ recentQueryData, query, index });
	} else {
		recentQueryData.unshift(query);
		setRecentQueryList(recentQueryData);
	}
};

const existingQueryIndex = ({
	recentQueryData,
	query,
}: {
	recentQueryData: Array<SavedQuery>;
	query: SavedQuery;
}) => {
	return (recentQueryData || []).findIndex(recentQuery => {
		return recentQuery.criteria === query.criteria;
	});
};

export const replaceOldQuery = ({
	recentQueryData,
	query,
	index,
}: {
	recentQueryData: Array<SavedQuery>;
	query: SavedQuery;
	index: number;
}) => {
	recentQueryData.splice(index, 1);
	recentQueryData.unshift(query);
	setRecentQueryList(recentQueryData);
};

export const setRecentQueryList = (recentQueryData: Array<SavedQuery>) => {
	recentQueryData = getUniqueListBy(recentQueryData, "criteriaAsParams");
	window.localStorage.setItem(
		"recentQueryList",
		JSON.stringify(recentQueryData)
	);
};

export const getCommonFacetState = (
	scope: Scope | undefined,
	facetState: FacetState
): FacetState | undefined => {
	if (!facetState) {
		return facetState;
	}
	let updatedMap = new Map();
	for (const [key, value] of facetState) {
		const facetType = getCommonFacetType(key);
		let facetKey = key;
		if (scope && CommonFieldScopeMapping[scope]) {
			facetKey = getFacetKey(scope, facetType) ?? key;
		}
		updatedMap.set(facetKey, value);
	}
	return updatedMap;
};

export const getCommonFacetType = (currentKey: string): string => {
	for (let scope of Object.keys(CommonFieldScopeMapping) as CommonScopes[]) {
		if (Object.keys(CommonFieldScopeMapping[scope]).includes(currentKey)) {
			return CommonFieldScopeMapping[scope][
				currentKey as keyof (typeof CommonFieldScopeMapping)[typeof scope]
			];
		}
	}
	return currentKey;
};

export const getFacetKey = (
	scope: Scope,
	facetType: string
): string | undefined => {
	for (let facetKey of Object.keys(CommonFieldScopeMapping[scope])) {
		if (CommonFieldScopeMapping[scope][facetKey] === facetType) {
			return facetKey;
		}
	}
	return undefined;
};

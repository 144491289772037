import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Tooltip } from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";
import { CTRole } from "pages/organization/types";
import { InviteMemberForm } from "./components/invite-member-form";

interface InviteMemberDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;
	rolesList: Array<CTRole> | undefined;
	title?: string;
	btnTitle?: string;
}

export const InviteMemberDrawer = ({
	isOpen,
	onClose,
	onSuccess,
	rolesList,
	title,
	btnTitle = "invite",
}: InviteMemberDrawerProps) => {
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					p: 0,
					width: "50%",
					maxwidth: "786px",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<InviteMemberForm
				title={title ? title : `Invite New Member`}
				cancel={onClose}
				onSuccess={onSuccess}
				rolesList={rolesList}
				btnTitle={btnTitle}
			></InviteMemberForm>
		</Drawer>
	);
};

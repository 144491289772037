import { CTConfirmation } from "common/atoms/ct-confirmation";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useDecommissionApplianceAPI } from "pages/appliance-detail/hooks/useDecommissionApplianceAPI";
import { useApplianceStore } from "pages/appliances/store";
import { ApplianceDecommissionProps } from "../types";

export const ApplianceDecommission = ({
	agent,
	showDecommission,
	setShowDecommission,
}: ApplianceDecommissionProps) => {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const requestAPIRefresh = useApplianceStore(state => state.requestAPIRefresh);
	const applianceDecommissionMutation = useDecommissionApplianceAPI(
		agent?.agentId
	);

	const decommissionAppliance = () => {
		applianceDecommissionMutation.mutateAsync(
			{},
			{
				onSuccess(response) {
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						window.getCTTranslatedText("decommissionGatekeeperSuccess")
					);
					setShowDecommission(false);
					requestAPIRefresh();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			}
		);
	};
	return (
		<CTConfirmation
			open={showDecommission}
			onClose={() => setShowDecommission(false)}
			title={window.getCTTranslatedText("decommissionGatekeeper")}
			primaryText={window.getCTTranslatedText(
				"gatekeeperDecommissionConfirmation"
			)}
			secondaryText={window.getCTTranslatedText("gatekeeperDecommissionNote")}
			primaryButtonText={window.getCTTranslatedText("decommissionButton")}
			isLoading={applianceDecommissionMutation.isLoading}
			onSuccess={decommissionAppliance}
		/>
	);
};

import { Box, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { Report } from "pages/reports/types";
import { useState } from "react";
import { REPORTS_COLUMNS, ReportsColumnConfig } from "./constants";

export const ReportsDataGrid = (props: DataGridProps<Report>) => {
	const [selection, setSelection] = useState<Array<GridRowId>>([]);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					defaultPinnedColumns={ReportsColumnConfig.PinnedColumns}
					initialState={{
						sorting: {
							sortModel: [{ field: "createdAt", sort: "desc" }],
						},
					}}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						setSelection(selectionModel);
					}}
					rowHeight={64}
					columns={REPORTS_COLUMNS}
					pagination
					getRowId={({ historyId }: Report) => historyId!}
					paginationMode="server"
					sortingMode="server"
					{...props}
				/>
			</Box>
		</Stack>
	);
};

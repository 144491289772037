import { Link, Stack, Tooltip, Typography } from "@mui/material";
import numeral from "numeral";
import { Link as RouterLink } from "react-router-dom";
import { UserGroupColumnRenderProps } from "../user-group-status/UserGroupStatus";

export const AssetCount = ({
	userGroup,
	viewOnly = false,
}: UserGroupColumnRenderProps) => {
	const assetCount = numeral(userGroup?.endpoints ?? 0).format("0a");
	return (
		<Stack direction={"row"} spacing={1} flex={1} alignItems={"center"}>
			{viewOnly || userGroup?.endpoints === 0 ? (
				<Typography variant="body2">{assetCount} </Typography>
			) : (
				<Tooltip title={window.getCTTranslatedText("View assets")}>
					<Link
						typography="body2"
						component={RouterLink}
						to={`/assets?filters=${encodeURIComponent(
							`groupname=${userGroup.groupName}`
						)}`}
						sx={{ color: "primary.main" }}
					>
						{assetCount}{" "}
					</Link>
				</Tooltip>
			)}
		</Stack>
	);
};

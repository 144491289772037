/* eslint-disable no-useless-escape */
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyIcon from "@mui/icons-material/Key";
import {
	Alert,
	Box,
	Chip,
	DialogContent,
	Divider,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	Link,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	styled,
} from "@mui/material";
import { curlProxyEncodeURIComponent, textToBase64 } from "common/utils";
import debounce from "lodash/debounce";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { CopyInstructions } from "./CopyInstructions";
import {
	AGENT_TYPES,
	EMPTY_STRING,
	PLATFORMS,
	containerSteps,
} from "./constants";
import { useClusterIdValidation } from "./hooks/useClusterIdValidation";

export type IAgentInstallProp = {
	pname: string;
	agentType?: AGENT_TYPES;
	selectedAgentTypeValue: string;
	latestDepKey?: string;
	fileName?: string;
	downloadLink?: string;
	agentVersion?: string;
	latestLinuxScriptDownloadLink?: string;
	latestWindowsScriptDownloadLink?: string;
};

let envName = window.location.origin;

const StyledChip = styled(Chip)(() => ({
	color: "inherit",
	background: "secondary",
	font: "inherit",
}));

export const TerminalTextHeaders = styled(Typography)(() => ({
	textAlign: "center",
	color: "#00ADB5;",
	marginBottom: 5,
}));

const TerminalTextSubHeaders = styled(Typography)(() => ({
	color: "#00ADB5;",
	paddingBottom: 2,
}));

const InfoContent = styled(Chip)(() => ({
	color: "inherit",
	background: "black",
	font: "inherit",
}));

interface IAgentProxyFormProps {
	pname: string;
	children: React.ReactNode;
	proxy: boolean;
	authProxy: boolean;
	autoUpgrade: boolean;
	vScan: boolean;
	localRegistry: boolean;
	goldenImage: boolean;
}

const MyContext = createContext({
	proxyName: "",
	proxyPort: "",
	protocolType: "",
	proxy: false,
	authProxy: false,
	username: "",
	userPassword: "",
	autoUpgrade: false,
	vScan: false,
	dockerRepo: "",
	localRegistry: false,
	clusterId: "",
	targetNamespace: "",
	goldenImage: false,
});
function getVersionNumber(props: IAgentInstallProp) {
	const versionPattern = /[\d.]+/; // Regular expression to match version number
	const match = props?.agentVersion?.match(versionPattern);
	let versionNumber = match?.[0] ?? "";
	return versionNumber;
}

export function AgentProxyForm(props: IAgentProxyFormProps) {
	const [proxyValue, setProxyValue] = useState("<PROXY_IP>");
	const [portValue, setPortValue] = useState("<PROXY_PORT>");
	const [protocolType, setProtocolType] = useState("http");
	const [username, setUsername] = useState("<USERNAME>");
	const [userPassword, setUserPassword] = useState("<PASSWORD>");
	const [dockerRepo, setDockerRepo] = useState("<DOCKER_REPO>");
	const [clusterIdentifier, setClusterIdentifier] =
		useState("<k8s_cluster_id>");
	const [targetNamespace, setTargetNamespace] = useState("<target_namespace>");
	const [clusterIdValidity, setClusterIdValidity] = useState<boolean>(true);
	const isDockerOrOpenShift =
		props?.pname === PLATFORMS.DOCKER || props?.pname === PLATFORMS.OPENSHIFT;

	const handleProxyURLChange = (event: any) => {
		setProxyValue(event.target.value);
	};

	const handleUserName = (event: any) => {
		setUsername(event.target.value);
	};
	const handleUserPassword = (event: any) => {
		setUserPassword(event.target.value);
	};
	const handleProtocolTypeChange = (event: any) => {
		setProtocolType(event.target.value);
	};
	const handleDockerRepoChange = (event: any) => {
		setDockerRepo(event.target.value);
	};

	const clusterIdMutation = useClusterIdValidation(clusterIdentifier);

	const debouncedClusterIdMutate = useMemo(
		() => debounce(clusterIdMutation.mutate, 500),
		[clusterIdMutation.mutate]
	);

	useEffect(() => {
		if (clusterIdentifier !== "<k8s_cluster_id>") {
			debouncedClusterIdMutate(
				{},
				{
					onSuccess: () => {
						setClusterIdValidity(true);
					},
					onError: () => {
						setClusterIdValidity(false);
					},
				}
			);
		}
	}, [clusterIdentifier, debouncedClusterIdMutate]);

	function handleClusterIdChange(event: React.ChangeEvent<HTMLInputElement>) {
		setClusterIdentifier(event.target.value);
		if (event.target.value === "") {
			setClusterIdValidity(false);
		}
	}

	const handleTargetNamespaceChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setTargetNamespace(event.target.value);
	};

	const handlePortChange = (event: any) => {
		const input = event.target.value;
		setPortValue(input);
	};

	const authDetails = () => {
		return (
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					marginBottom: 5,
					alignItems: "center",
				}}
			>
				<TextField
					sx={{ width: "50%" }}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<AccountCircleIcon />
							</InputAdornment>
						),
					}}
					id="username"
					label="Username"
					variant="standard"
					value={username === "<USERNAME>" ? "" : username}
					onChange={handleUserName}
				/>
				<Box mx="1rem" />
				<TextField
					sx={{ width: "50%" }}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<KeyIcon />
							</InputAdornment>
						),
					}}
					type={"password"}
					id="password"
					label="Password"
					variant="standard"
					value={userPassword === "<PASSWORD>" ? "" : userPassword}
					onChange={handleUserPassword}
				/>
			</Box>
		);
	};

	const formInput = () => {
		return (
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<FormControl variant="standard" sx={{ width: "25%" }}>
					<InputLabel>{window.getCTTranslatedText("Protocol")}</InputLabel>
					<Select
						value={protocolType}
						label={window.getCTTranslatedText("Protocol")}
						onChange={handleProtocolTypeChange}
					>
						<MenuItem value={"http"}>http</MenuItem>
						<MenuItem value={"https"}>https</MenuItem>
					</Select>
				</FormControl>
				<Box mx="1rem" />
				<TextField
					sx={{ width: "50%" }}
					id="proxyUrl"
					label={window.getCTTranslatedText("Proxy IP/Domain Name")}
					variant="standard"
					value={proxyValue === "<PROXY_IP>" ? "" : proxyValue}
					onChange={handleProxyURLChange}
				/>
				<Box mx="1rem" />
				<TextField
					sx={{ width: "25%" }}
					type={"number"}
					id="proxyPort"
					label={window.getCTTranslatedText("Port")}
					variant="standard"
					value={portValue === "<PROXY_PORT>" ? "" : portValue}
					onChange={handlePortChange}
				/>
			</Box>
		);
	};

	const clusterIdentifierInput = () => {
		const getHelperText = () => {
			if (clusterIdentifier === "") {
				return window.getCTTranslatedText("k8sClusterIdEmptyError");
			} else if (clusterIdValidity === false) {
				return window.getCTTranslatedText("k8sClusterIdError");
			} else {
				return null;
			}
		};

		return (
			<Grid
				container
				columnGap={4}
				rowGap={2}
				sx={{ pl: 0 }}
				display="grid"
				gridTemplateColumns="1fr 1fr"
			>
				<TextField
					sx={{ width: "100%" }}
					id="clusterIdentifier"
					label={window.getCTTranslatedText("k8sClusterId")}
					variant="standard"
					value={
						clusterIdentifier === "<k8s_cluster_id>" ? "" : clusterIdentifier
					}
					onChange={handleClusterIdChange}
					helperText={getHelperText()}
					error={!clusterIdValidity}
				/>
				<TextField
					sx={{ width: "100%" }}
					id="targetNs"
					label={window.getCTTranslatedText("targetNs")}
					variant="standard"
					value={
						targetNamespace === "<target_namespace>" ? "" : targetNamespace
					}
					onChange={handleTargetNamespaceChange}
				/>
			</Grid>
		);
	};

	const proxyText = () => {
		return (
			<Typography
				variant="subtitle2"
				sx={{
					marginBottom: 5,
					marginTop: 5,
					color: theme => theme.palette.text.secondary,
				}}
			>
				{" "}
				{window.getCTTranslatedText("Authenticate User")}
			</Typography>
		);
	};
	const localRegistryDetails = () => {
		return (
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<TextField
					sx={{ width: "100%" }}
					id="DockerRepo"
					label={window.getCTTranslatedText("Docker Repositry")}
					variant="standard"
					value={dockerRepo === "<DOCKER_REPO>" ? "" : dockerRepo}
					onChange={handleDockerRepoChange}
				/>
			</Box>
		);
	};
	return (
		<DialogContent sx={{ px: 0 }}>
			<MyContext.Provider
				value={{
					proxyName: proxyValue,
					proxyPort: portValue,
					proxy: props.proxy,
					protocolType: protocolType,
					authProxy: props.authProxy,
					username: username,
					userPassword: userPassword,
					autoUpgrade: props.autoUpgrade,
					vScan: props.vScan,
					dockerRepo: dockerRepo,
					localRegistry: props.localRegistry,
					clusterId: clusterIdentifier,
					targetNamespace: targetNamespace,
					goldenImage: props.goldenImage,
				}}
			>
				{props.proxy && (
					<>
						<Stack
							sx={{
								background: theme =>
									theme.palette.mode === "dark"
										? theme.palette.background.paper
										: theme.palette.background.default,
								flexDirection: "column",
								alignItems: "flex-start",
								marginBottom: 5,
								padding: 2,
							}}
						>
							{formInput()}
							{props.authProxy && proxyText()}
							{props.authProxy && authDetails()}
						</Stack>
					</>
				)}

				{props.localRegistry && (
					<Box
						sx={{
							background: theme =>
								theme.palette.mode === "dark"
									? theme.palette.background.paper
									: theme.palette.background.default,
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
							marginBottom: 5,
							padding: 2,
						}}
					>
						{localRegistryDetails()}
					</Box>
				)}
				{isDockerOrOpenShift && (
					<Box
						sx={{
							background: theme =>
								theme.palette.mode === "dark"
									? theme.palette.background.paper
									: theme.palette.background.default,
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
							marginBottom: 5,
							padding: 2,
						}}
					>
						{clusterIdentifierInput()}
					</Box>
				)}
				{props.children}
			</MyContext.Provider>
		</DialogContent>
	);
}

export function GetAgentInstallationInfoManual(props: IAgentInstallProp) {
	const pValue = useContext(MyContext);
	let installedPackageName = getPackageName(props.agentType);
	let downloadpackageName = props?.fileName;
	let extractedPackageNameDarwin = downloadpackageName?.replace(".dmg", ".pkg");
	let mountDirNameDarwin = `/Volumes/${installedPackageName}`;
	let downloadpackageLink = props?.downloadLink;
	let backupDirWindows = "C:\\Program Files\\Colortokens";
	let backupDirLinux = "/etc/colortokens";
	let backupDirDarwin = `/usr/local/bin/colortokens/${installedPackageName}`;
	let windowsAgentInstallationDir = `C:\\Program Files\\Colortokens\\${installedPackageName}`;
	let winUpgrade = pValue.autoUpgrade
		? "--upgrade='true'"
		: "--upgrade='false'";
	let linUpgrade = pValue.autoUpgrade ? "--upgrade=true" : "--upgrade=false";
	let goldenImageLinux = pValue.goldenImage
		? "--golden-image=true"
		: EMPTY_STRING;
	let goldenImageWindows = pValue.goldenImage
		? "--golden-image='true'"
		: EMPTY_STRING;

	let windowsInstalledPackageName = "ColorTokens Xshield Agent";
	const isDockerOrOpenShift =
		props?.pname === PLATFORMS.DOCKER || props?.pname === PLATFORMS.OPENSHIFT;

	function getPackageName(agentType?: AGENT_TYPES) {
		if (
			agentType === AGENT_TYPES.CT_AGENT ||
			agentType === AGENT_TYPES.CT_LEGACY_AGENT ||
			props?.pname === PLATFORMS.WINDOWS ||
			props?.pname === PLATFORMS.DARWIN
		) {
			return "xshield-monitoring-agent";
		} else if (
			agentType === AGENT_TYPES.CT_USER_AGENT &&
			props?.pname !== PLATFORMS.WINDOWS &&
			props?.pname !== PLATFORMS.DARWIN
		) {
			return "xshield-user-agent";
		} else if (agentType === AGENT_TYPES.CT_REMOTE_CONNECTOR) {
			return "xshield-remote-connector";
		}
	}

	function isLesserVersion(version1: string, version2: string) {
		const v1Parts = version1.split(".").map(Number);
		const v2Parts = version2.split(".").map(Number);

		for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
			const v1Part = v1Parts[i] || 0;
			const v2Part = v2Parts[i] || 0;

			if (v1Part > v2Part) {
				return false;
			} else if (v1Part < v2Part) {
				return true;
			}
		}
		return false;
	}

	function getDomainName() {
		return window.location.hostname;
	}

	const solarisRegisterArgs = `
  sudo cat << EOF > /tmp/ct_register_args
  CT_DOMAIN=${envName}
  CT_DEPLOYMENT_KEY=${props?.latestDepKey}
  CT_AGENT_TYPE=${props?.selectedAgentTypeValue}
  CT_AUTO_UPGRADE=${pValue?.autoUpgrade}
  ${
		pValue?.proxy
			? `PROXY=${pValue?.protocolType}://${pValue?.proxyName}:${pValue?.proxyPort}`
			: EMPTY_STRING
	}
  ${pValue.authProxy ? `PROXY_USERNAME=${pValue.username}` : EMPTY_STRING}
  ${
		pValue.authProxy
			? `PROXY_PASSWORD=${textToBase64(
					curlProxyEncodeURIComponent(pValue.userPassword)
				)}`
			: EMPTY_STRING
	}
  EOF
  `;

	const solarisNoAsk = `
  sudo cat << EOF > admin_noask
  mail=
  instance=overwrite
  partial=nocheck
  runlevel=quit
  idepend=quit
  rdepend=quit
  space=quit
  setuid=nocheck
  conflict=nocheck
  action=nocheck
  basedir=default
  EOF
  `;

	function installPrerequisiteRaw(platform: string) {
		platform = props?.pname;
		switch (platform) {
			case PLATFORMS.RPM:
				return `sudo yum install curl; sudo yum install libpcap; sudo yum install nftables; sudo yum install iptables;\
        sudo yum install rsyslog; sudo systemctl start rsyslog.service; sudo systemctl enable rsyslog.service; sudo systemctl status rsyslog.service;\
        sudo systemctl start nftables.service; sudo systemctl enable nftables.service; sudo systemctl status nftables.service`;
			case PLATFORMS.SUSE:
				return `sudo zypper install curl; sudo zypper install libpcap; sudo zypper install nftables; sudo zypper install iptables; sudo zypper install rsyslog;\
        sudo systemctl start rsyslog.service; sudo systemctl enable rsyslog.service;\
        sudo systemctl status rsyslog.service; sudo systemctl start nftables.service; sudo systemctl enable nftables.service; sudo systemctl status nftables.service`;
			case PLATFORMS.DEBIAN:
				if (props.agentType === AGENT_TYPES.CT_REMOTE_CONNECTOR) {
					return `sudo apt-get update -y; sudo apt-get install curl -y; sudo apt-get install libpcap-dev -y; sudo apt-get install nftables -y; sudo apt-get install iptables -y; sudo systemctl start nftables.service; sudo systemctl enable nftables.service; sudo systemctl status nftables.service`;
				} else {
					return `sudo apt-get install curl; sudo apt-get install libpcap-dev; sudo apt-get install nftables; sudo apt-get install iptables;\
          sudo apt-get install rsyslog; sudo systemctl start rsyslog.service; sudo systemctl enable rsyslog.service; sudo systemctl status rsyslog.service;\
          sudo systemctl start nftables.service; sudo systemctl enable nftables.service; sudo systemctl status nftables.service`;
				}
			case PLATFORMS.DARWIN:
				return `brew install libpcap`;
			case PLATFORMS.DOCKER:
				return `Install Istio Service Mesh (if not installed already):;
        Please refer to istio.io to choose the best configuration and installation method for your cluster.;
        
        Note: ColorTokens does not mandate any specific installation method.;
        
        https://istio.io/latest/docs/setup/install/`;
			case PLATFORMS.OPENSHIFT:
				return `Install Istio Service Mesh (if not installed already) with openshift profile enabled.;
				Please refer to istio.io to choose the best configuration and installation method for your cluster:;
				https://istio.io/latest/docs/setup/platform-setup/openshift/.;
				Note: ColorTokens does not mandate any specific installation method, however, Istio with Ambient Mesh is currently not supported.`;
			case PLATFORMS.WINDOWS:
				return ``;
			case PLATFORMS.SOLARIS:
				return ``;
		}
	}
	function rpmConfigDetailsManual() {
		return (
			<>
				<Typography>
					{window.getCTTranslatedText(
						"Make sure that your Yum configuration is updated with proxy details. Or else, follow the instructions below"
					)}{" "}
					-{" "}
				</Typography>
				<Typography paddingLeft={2}>
					1. {window.getCTTranslatedText("Open")} /etc/yum.conf{" "}
				</Typography>
				<Typography paddingLeft={2}>
					2.{" "}
					{window.getCTTranslatedText(
						"Paste the below line in the section of the yum.conf file as a new line"
					)}
				</Typography>
				<Typography paddingLeft={5}>
					proxy={pValue.protocolType}://{pValue.proxyName}:{pValue.proxyPort}
				</Typography>
				{pValue.authProxy && (
					<>
						{" "}
						<Typography paddingLeft={5}>
							proxy_username={pValue.username}
						</Typography>
						<Typography paddingLeft={5}>
							proxy_password={pValue.userPassword}
						</Typography>
					</>
				)}
			</>
		);
	}
	function suseConfigDetailsManual() {
		return (
			<>
				<Typography>
					{window.getCTTranslatedText(
						"Make sure that your proxy configuration is updated with proxy details. Or else, follow the instructions below"
					)}{" "}
					-{" "}
				</Typography>
				<Typography paddingLeft={2}>
					1. {window.getCTTranslatedText("Open")} /etc/sysconfig/proxy{" "}
				</Typography>
				<Typography paddingLeft={2}>
					2.{" "}
					{window.getCTTranslatedText(
						"Paste the below line in the /etc/sysconfig/proxy file as a new line"
					)}
				</Typography>
				<Typography paddingLeft={5}>PROXY_ENABLED="yes"</Typography>
				<Typography paddingLeft={5}>
					{pValue.protocolType === "http" ? "HTTP_PROXY" : "HTTPS_PROXY"}="
					{pValue.protocolType}://{pValue.proxyName}:{pValue.proxyPort}"
				</Typography>
				{pValue.authProxy && (
					<>
						{" "}
						<Typography paddingLeft={2}>
							3. {window.getCTTranslatedText("Create a file named")}{" "}
							/root/.curlrc,{" "}
							{window.getCTTranslatedText(
								"and add the proxy user and password details to it as outlined below"
							)}
						</Typography>
						<Typography paddingLeft={5}>
							proxy-user="{pValue.username}:{pValue.userPassword}"
						</Typography>
					</>
				)}
			</>
		);
	}

	function debianConfigDetailsManual() {
		return (
			<>
				<Typography>
					{window.getCTTranslatedText(
						"Make sure that your Apt configuration is updated with proxy details. Or else, follow the instructions below"
					)}{" "}
					-{" "}
				</Typography>
				<Typography paddingLeft={2}>
					1.{" "}
					{window.getCTTranslatedText(
						"Open or create the file /etc/apt/apt.conf.d/proxy.conf"
					)}{" "}
				</Typography>
				<Typography paddingLeft={2}>
					2.{" "}
					{window.getCTTranslatedText(
						"Paste the below lines in the section of the file /etc/apt/apt.conf.d/proxy.conf as a new line"
					)}
				</Typography>
				<Typography paddingLeft={5}>
					Acquire::http::Proxy "http://
					{pValue.authProxy && `${pValue.username}:${pValue.userPassword}@`}
					{pValue.proxyName}:{pValue.proxyPort}";
				</Typography>
				<Typography paddingLeft={5}>
					Acquire::https::Proxy "https://
					{pValue.authProxy && `${pValue.username}:${pValue.userPassword}@`}
					{pValue.proxyName}:{pValue.proxyPort}";
				</Typography>
			</>
		);
	}

	const renderAgentPrerequisiteDetails = () => {
		let rawInfo = installPrerequisiteRaw(props?.pname);

		return (
			<Box>
				{props?.pname !== PLATFORMS.WINDOWS &&
					props?.pname !== PLATFORMS.AIX &&
					props?.pname !== PLATFORMS.RPM_LEGACY &&
					props?.pname !== PLATFORMS.SOLARIS && (
						<Box>
							<TerminalTextHeaders variant="h5">
								{window.getCTTranslatedText("Prerequisites")}
							</TerminalTextHeaders>
							{pValue?.proxy && props?.pname === PLATFORMS.RPM && (
								<>{rpmConfigDetailsManual()}</>
							)}
							{pValue?.proxy && props?.pname === PLATFORMS.SUSE && (
								<>{suseConfigDetailsManual()}</>
							)}
							{pValue?.proxy &&
								props?.pname === PLATFORMS.DEBIAN &&
								props.agentType !== AGENT_TYPES.CT_REMOTE_CONNECTOR && (
									<>{debianConfigDetailsManual()}</>
								)}
							<TerminalTextSubHeaders marginTop={5} variant="body1">
								##
								{window.getCTTranslatedText(
									"Install the prerequisites if not already installed"
								)}
							</TerminalTextSubHeaders>
							{rawInfo?.split(";")?.map((preReq: string, index: number) => (
								<Typography
									key={index}
									sx={{ wordBreak: "break-all", maxWidth: "100%" }}
								>
									{" "}
									{isDockerOrOpenShift ? <>{preReq}</> : <>$ {preReq}</>}
								</Typography>
							))}
							{props?.pname !== PLATFORMS.DOCKER &&
								props?.pname !== PLATFORMS.OPENSHIFT && (
									<>
										<Stack direction="row" justifyContent="flex-end">
											<CopyInstructions code={rawInfo} />
										</Stack>
										<Divider
											color="black"
											sx={{ marginBottom: 2, marginTop: 5 }}
										></Divider>
									</>
								)}
						</Box>
					)}
			</Box>
		);
	};

	function rawManualInstallationInfoInstall(platform: string) {
		platform = props?.pname;
		switch (platform) {
			case PLATFORMS.RPM:
			case PLATFORMS.SUSE:
				return `sudo rpm -ivh ${downloadpackageName}`;
			case PLATFORMS.RPM_LEGACY:
				return `CT_DOMAIN=${envName}${" "}CT_DEPLOYMENT_KEY=${
					props.latestDepKey
				}${" "}CT_AGENT_TYPE=${
					props.selectedAgentTypeValue
				}${" "}sudo rpm -ivh ${downloadpackageName}`;
			case PLATFORMS.AIX:
				return `CT_DOMAIN=${envName}${" "}CT_DEPLOYMENT_KEY=${
					props.latestDepKey
				}${" "}CT_AGENT_TYPE=${
					props.selectedAgentTypeValue
				}${" "}CT_AUTO_UPGRADE=${pValue.autoUpgrade}${" "}${
					pValue.proxy
						? `PROXY='${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}' `
						: ""
				}${
					pValue.authProxy
						? `PROXY_USERNAME='${
								pValue.username
							}'${" "}PROXY_PASSWORD='${textToBase64(
								curlProxyEncodeURIComponent(pValue.userPassword)
							)}' `
						: ""
				}rpm -ivh ${downloadpackageName}`;
			case PLATFORMS.DARWIN:
				return `sudo hdiutil attach ${downloadpackageName} ; sudo installer -pkg ${mountDirNameDarwin}/${extractedPackageNameDarwin} -target /usr/local/bin/ ;\
        sudo hdiutil detach ${mountDirNameDarwin}`;
			case PLATFORMS.DEBIAN:
				return `sudo dpkg -i ${downloadpackageName}`;
			case PLATFORMS.SOLARIS:
				return `echo all | sudo pkgadd -a admin_noask -d ${downloadpackageName}`;
			case PLATFORMS.WINDOWS:
				return `powershell -command "New-Item -ItemType Directory -Path 'C:\\ProgramData\\Colortokens' -ErrorAction SilentlyContinue | Out-Null; msiexec.exe /i ${downloadpackageName} /qb /l*v C:\\ProgramData\\Colortokens\\ctagent_MSI.log | Out-Null"`;
		}
	}

	function rawManualInstallationInfoRegister(platform: string) {
		platform = props?.pname;
		switch (platform) {
			case PLATFORMS.RPM:
			case PLATFORMS.SUSE:
				return `sudo /etc/colortokens/ctagent register --domain=${envName} --deploymentKey=${
					props.latestDepKey
				} --agentType=${props.selectedAgentTypeValue}\
        ${
					pValue.proxy
						? `--proxy='${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}'`
						: ""
				}${" "}${
					pValue.authProxy
						? `--proxyUser='${
								pValue.username
							}'${" "}--proxyPassword='${textToBase64(
								curlProxyEncodeURIComponent(pValue.userPassword)
							)}'`
						: ""
				}${" "}${linUpgrade}${" "}${goldenImageLinux}`;
			case PLATFORMS.AIX:
				return `/etc/colortokens/ctagent register --domain=${envName} --deploymentKey=${
					props.latestDepKey
				} --agentType=${props.selectedAgentTypeValue}\
          ${
						pValue.proxy
							? `--proxy='${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}'`
							: ""
					}${" "}${
						pValue.authProxy
							? `--proxyUser='${
									pValue.username
								}'${" "}--proxyPassword='${textToBase64(
									curlProxyEncodeURIComponent(pValue.userPassword)
								)}'`
							: ""
					}${" "}${linUpgrade}`;
			case PLATFORMS.SOLARIS:
				return `sudo /etc/colortokens/ctagent register --domain=${envName} --deploymentKey=${
					props.latestDepKey
				} --agentType=${props.selectedAgentTypeValue}\
              ${
								pValue.proxy
									? `--proxy='${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}'`
									: ""
							}${" "}${
								pValue.authProxy
									? `--proxyUser='${
											pValue.username
										}'${" "}--proxyPassword='${textToBase64(
											curlProxyEncodeURIComponent(pValue.userPassword)
										)}'`
									: ""
							}${" "}${linUpgrade}`;
			case PLATFORMS.DEBIAN:
				if (props.agentType === AGENT_TYPES.CT_REMOTE_CONNECTOR) {
					return `sudo /etc/colortokens/xshield-remote-connector/ctagent register --domain=${envName} --deploymentKey=${
						props.latestDepKey
					} --agentType=${props.selectedAgentTypeValue}${" "}${
						pValue.proxy
							? `--proxy='${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}'`
							: ""
					}${" "}${
						pValue.authProxy
							? `--proxyUser='${
									pValue.username
								}'${" "}--proxyPassword='${textToBase64(
									curlProxyEncodeURIComponent(pValue.userPassword)
								)}'`
							: ""
					}${" "}${linUpgrade}`;
				} else {
					return `sudo /etc/colortokens/ctagent register --domain=${envName} --deploymentKey=${
						props.latestDepKey
					} --agentType=${props.selectedAgentTypeValue}${" "}${
						pValue.proxy
							? `--proxy='${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}'`
							: ""
					}${" "}${
						pValue.authProxy
							? `--proxyUser='${
									pValue.username
								}'${" "}--proxyPassword='${textToBase64(
									curlProxyEncodeURIComponent(pValue.userPassword)
								)}'`
							: ""
					}${" "}${linUpgrade}${" "}${goldenImageLinux}`;
				}

			case PLATFORMS.DARWIN:
				return `sudo /usr/local/bin/colortokens/${installedPackageName}/ctagent register --domain=${envName} --deploymentKey=${
					props.latestDepKey
				} --agentType=${props.selectedAgentTypeValue}\
          ${
						pValue.proxy
							? `--proxy='${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}'`
							: ""
					}${" "}${
						pValue.authProxy
							? `--proxyUser='${
									pValue.username
								}'${" "}--proxyPassword='${textToBase64(
									curlProxyEncodeURIComponent(pValue.userPassword)
								)}'`
							: ""
					}${" "}${linUpgrade}`;
			case PLATFORMS.WINDOWS:
				return `powershell -command "cd '${windowsAgentInstallationDir}' ; .\\ctagent.exe register --domain='${envName}' --deploymentKey='${
					props.latestDepKey
				}' --agentType='${props.selectedAgentTypeValue}'${" "}${
					pValue.proxy
						? `--proxy='${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}'`
						: ""
				}${" "}${
					pValue.authProxy
						? `--proxyUser='${
								pValue.username
							}'${" "}--proxyPassword='${textToBase64(
								curlProxyEncodeURIComponent(pValue.userPassword)
							)}'`
						: ""
				}${" "}${winUpgrade}${" "}${goldenImageWindows}"`;
		}
	}

	function rawManualInstallationInfoRun(platform: string) {
		platform = props?.pname;
		switch (platform) {
			case PLATFORMS.RPM:
			case PLATFORMS.SUSE:
				return `sudo /etc/colortokens/ctagent start;sudo /etc/colortokens/ctagent status`;
			case PLATFORMS.RPM_LEGACY:
				return `sudo initctl start ctagent; sudo initctl status ctagent`;
			case PLATFORMS.AIX:
				return `/etc/colortokens/ctagent start; ps -aef | grep ctagent`;
			case PLATFORMS.SOLARIS:
				return `sudo /etc/colortokens/ctagent start; ps -aef | grep ctagent`;
			case PLATFORMS.DEBIAN:
				if (props.agentType === AGENT_TYPES.CT_REMOTE_CONNECTOR) {
					return `sudo /etc/colortokens/xshield-remote-connector/ctagent start; sudo /etc/colortokens/xshield-remote-connector/ctagent status`;
				} else {
					return `sudo /etc/colortokens/ctagent start; sudo /etc/colortokens/ctagent status`;
				}
			case PLATFORMS.DARWIN:
				return `sudo /usr/local/bin/colortokens/${installedPackageName}/ctagent start;sudo /usr/local/bin/colortokens/${installedPackageName}/ctagent status`;
			case PLATFORMS.WINDOWS:
				return `powershell -command "cd '${windowsAgentInstallationDir}'; .\\ctagent.exe  start ; .\\ctagent.exe status"`;
		}
	}

	function containerSecurityInstallationProcess(step: string) {
		switch (step) {
			case containerSteps.CREATE:
				return `kubectl create namespace ct-system`;
			case containerSteps.HELM:
				return `export HELM_EXPERIMENTAL_OCI=1;
        helm -n ct-system install ct-contsec oci://colortokenspublic.azurecr.io/helm/ct-contsec --version ${getVersionNumber(
					props
				)} --set global.colortokensDomainSuffix=${
					isLesserVersion(getVersionNumber(props), "5.9.0")
						? getDomainName()
						: envName
				} --set global.clusterIdentifier=${pValue.clusterId.replace(
					/\s/g,
					""
				)} --set global.colortokensAuthKey=${
					props?.latestDepKey
				}  --set global.service.classicMode="false" ${
					pValue.proxy
						? `--set global.useProxy="true" --set global.proxy='${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}'`
						: ""
				}${" "}${
					pValue.authProxy
						? `--set global.proxyUser=${
								pValue.username
							}${" "}--set global.proxyPassword=${textToBase64(
								curlProxyEncodeURIComponent(pValue.userPassword)
							)}`
						: ""
				}${
					pValue.localRegistry
						? ` --set global.registryAccount=${pValue.dockerRepo}`
						: " "
				} `;
			case containerSteps.COPY_CONFIGMAPS:
				return `kubectl get cm opa-istio-config --namespace=ct-system -o yaml | sed 's/namespace: ct-system/namespace: ${pValue.targetNamespace}/' | kubectl create -f -;
        kubectl get cm opa-policy --namespace=ct-system -o yaml | sed 's/namespace: ct-system/namespace: ${pValue.targetNamespace}/' | kubectl create -f -`;
			case containerSteps.INTEGRATE_CONFIGMAPS:
				return `kubectl edit configmap istio -n istio-system`;
			case containerSteps.COPY_TELEMETRY:
				return `kubectl get telemetry ct-telemetry --namespace=ct-system -o yaml | sed 's/namespace: ct-system/namespace: ${pValue.targetNamespace}/' | kubectl create -f -`;
			case containerSteps.INTEGRATE_CONFIGMAPS_SCRIPT:
				return `  data:
    mesh: |-
      accessLogEncoding: JSON
      defaultConfig:
        discoveryAddress: istiod.istio-system.svc:15012
        extraStatTags:
        - request_method
        - request_path
        tracing:
          zipkin:
            address: zipkin.istio-system:9411
      enableEnvoyAccessLogService: true
      extensionProviders:
      - envoyHttpAls:
          port: 9999
          service: collector.ct-system.svc.cluster.local
        name: ct-http-als
      - envoyTcpAls:
          port: 9999
          service: collector.ct-system.svc.cluster.local
        name: ct-tcp-als
      enablePrometheusMerge: true
      rootNamespace: istio-system
      trustDomain: cluster.local
    meshNetworks: 'networks: {}'`;
			case containerSteps.RESTART_ISTIO:
				return `kubectl -n istio-system rollout restart deploy`;
			case containerSteps.INJECT_ISTIO:
				return `kubectl label namespace ${pValue.targetNamespace} istio-injection=enabled ct-enforcement=enabled`;
			case containerSteps.RESTART_DEPLOYMENT:
				return `kubectl rollout restart deploy -n ${pValue.targetNamespace}`;
			case containerSteps.UPGRADE:
				return `helm -n ct-system upgrade ct-contsec oci://colortokenspublic.azurecr.io/helm/ct-contsec --version ${getVersionNumber(
					props
				)} -n ct-system`;
			case containerSteps.CLEANUP:
				return `kubectl delete cm opa-istio-config -n ${pValue.targetNamespace}; kubectl delete cm opa-policy -n ${pValue.targetNamespace}; kubectl delete telemetry ct-telemetry -n ${pValue.targetNamespace}`;
		}
	}

	function rawManualInstallationInfoDownload(platform: string) {
		platform = props?.pname;
		switch (platform) {
			case PLATFORMS.RPM:
			case PLATFORMS.SUSE:
			case PLATFORMS.SOLARIS:
				return `sudo rm -f ${downloadpackageName}; sudo curl --tlsv1.2 ${
					pValue.proxy
						? `--proxy ${pValue.protocolType}://${
								pValue.authProxy
									? `${pValue.username}:${curlProxyEncodeURIComponent(
											pValue.userPassword
										)}@`
									: ""
							}${pValue.proxyName}:${pValue.proxyPort}`
						: ""
				} '${downloadpackageLink}' -o ${downloadpackageName}`;
			case PLATFORMS.RPM_LEGACY:
				return `sudo rm -f ${downloadpackageName}; sudo curl --tlsv1.2 ${
					pValue.proxy
						? `--proxy ${pValue.protocolType}://${
								pValue.authProxy
									? `${pValue.username}:${curlProxyEncodeURIComponent(
											pValue.userPassword
										)}@`
									: ""
							}${pValue.proxyName}:${pValue.proxyPort}`
						: ""
				} '${downloadpackageLink}' -o ${downloadpackageName}`;
			case PLATFORMS.AIX:
				return `rm -f ${downloadpackageName}; curl --tlsv1.2 ${
					pValue.proxy
						? `--proxy ${pValue.protocolType}://${
								pValue.authProxy
									? `${pValue.username}:${curlProxyEncodeURIComponent(
											pValue.userPassword
										)}@`
									: ""
							}${pValue.proxyName}:${pValue.proxyPort}`
						: ""
				} '${downloadpackageLink}' -o ${downloadpackageName}`;
			case PLATFORMS.DEBIAN:
				return `sudo rm -f ${downloadpackageName}; sudo curl --tlsv1.2  ${
					pValue.proxy
						? `--proxy ${pValue.protocolType}://${
								pValue.authProxy
									? `${pValue.username}:${curlProxyEncodeURIComponent(
											pValue.userPassword
										)}@`
									: ""
							}${pValue.proxyName}:${pValue.proxyPort}`
						: ""
				} '${downloadpackageLink}' -o ${downloadpackageName}`;

			case PLATFORMS.DARWIN:
				return `sudo rm -f ${downloadpackageName}; sudo curl --tlsv1.2 ${
					pValue.proxy
						? `--proxy ${pValue.protocolType}://${
								pValue.authProxy
									? `${pValue.username}:${curlProxyEncodeURIComponent(
											pValue.userPassword
										)}@`
									: ""
							}${pValue.proxyName}:${pValue.proxyPort}`
						: ""
				} '${downloadpackageLink}' -o ${downloadpackageName}`;
			case PLATFORMS.WINDOWS:
				return `powershell -command  "Remove-Item ${downloadpackageName}*;\
        [Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12;\
        Invoke-WebRequest -ContentType application/octet-stream ${
					pValue.proxy
						? `-Proxy '${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}'`
						: ""
				} ${
					pValue.authProxy
						? `-ProxyCredential (New-Object System.Management.Automation.PSCredential -ArgumentList '${pValue.username}',(ConvertTo-SecureString '${pValue.userPassword}' -AsPlainText -Force))`
						: ""
				} -OutFile .\\${downloadpackageName} '${downloadpackageLink}'"`;
		}
	}

	function renderManualAgentInstallationDetails() {
		let rawInstallationInfoDownload = rawManualInstallationInfoDownload(
			props?.pname
		);
		let rawInstallationInfoInstall = rawManualInstallationInfoInstall(
			props?.pname
		);
		let rawInstallationInfoRegister = rawManualInstallationInfoRegister(
			props?.pname
		);
		let rawInstallationInfoRun = rawManualInstallationInfoRun(props?.pname);

		switch (props?.pname) {
			case PLATFORMS.WINDOWS:
				return (
					<Box>
						<TerminalTextSubHeaders variant="body1">
							##PowerShell Version {">"} 3
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							C:\{"> "}
							{rawInstallationInfoDownload}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoDownload} />
						</Box>
						<Box display={"flex"} justifyContent={"center"}>
							{window.getCTTranslatedText("Or")}
						</Box>
						<Box paddingTop={2} paddingBottom={2}>
							{window.getCTTranslatedText("If the PowerShell Version")} {"<"}{" "}
							{window.getCTTranslatedText(
								"3 or the above command does not work, please click"
							)}
							<Link href={downloadpackageLink}> {downloadpackageName} </Link>
							{window.getCTTranslatedText("to download the package")}
						</Box>
						<Divider
							color="black"
							sx={{ marginBottom: 2, marginTop: 5 }}
						></Divider>
						<TerminalTextHeaders paddingBottom={2} variant="h5">
							{pValue.goldenImage
								? window.getCTTranslatedText("Install and register the agent")
								: window.getCTTranslatedText("Install and run the agent")}
						</TerminalTextHeaders>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded in the previous step and run the command below to install the agent"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							C:\{"> "}
							{rawInstallationInfoInstall}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoInstall} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Register the agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							C:\{">"} {rawInstallationInfoRegister}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoRegister} />
						</Box>
						{!pValue.goldenImage && (
							<>
								<TerminalTextSubHeaders variant="body1">
									##{window.getCTTranslatedText("Run the agent")}
								</TerminalTextSubHeaders>
								<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
									C:\{">"} {rawInstallationInfoRun}
								</Typography>
								<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
									<CopyInstructions code={rawInstallationInfoRun} />
								</Box>
							</>
						)}
					</Box>
				);
			case PLATFORMS.DEBIAN:
				return (
					<Box>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoDownload}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoDownload} />
						</Box>
						<Box display={"flex"} justifyContent={"center"}>
							{window.getCTTranslatedText("Or")}
						</Box>
						<Box paddingTop={2} paddingBottom={2}>
							{window.getCTTranslatedText(
								"If the above command does not work, please click"
							)}
							<Link href={downloadpackageLink}> {downloadpackageName} </Link>
							{window.getCTTranslatedText("to download the package")}
						</Box>
						<Divider
							color="black"
							sx={{ marginBottom: 2, marginTop: 5 }}
						></Divider>
						<TerminalTextHeaders paddingBottom={2} variant="h5">
							{pValue.goldenImage
								? window.getCTTranslatedText("Install and register the agent")
								: window.getCTTranslatedText("Install and run the agent")}
						</TerminalTextHeaders>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded in the previous step and run the command below to install the agent"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoInstall}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoInstall} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Register the Agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoRegister}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoRegister} />
						</Box>
						{!pValue.goldenImage && (
							<>
								<TerminalTextSubHeaders variant="body1">
									##{window.getCTTranslatedText("Run the Agent")}
								</TerminalTextSubHeaders>
								<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
									{"$ "}
									{rawInstallationInfoRun}
								</Typography>
								<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
									<CopyInstructions code={rawInstallationInfoRun} />
								</Box>
							</>
						)}
					</Box>
				);
			case PLATFORMS.DARWIN:
				const detachMount = `[ -d ${mountDirNameDarwin} ] && ${rawInstallationInfoInstall
					?.split(";")[2]
					.trim()}`;
				return (
					<Box>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoDownload}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoDownload} />
						</Box>
						<Box display={"flex"} justifyContent={"center"}>
							{window.getCTTranslatedText("Or")}
						</Box>
						<Box paddingTop={2} paddingBottom={2}>
							{window.getCTTranslatedText(
								"If the above command does not work, please click"
							)}
							<Link href={downloadpackageLink}> {downloadpackageName} </Link>
							{window.getCTTranslatedText("to download the package")}
						</Box>
						<Divider
							color="black"
							sx={{ marginBottom: 2, marginTop: 5 }}
						></Divider>
						<TerminalTextHeaders paddingBottom={2} variant="h5">
							{window.getCTTranslatedText("Install and run the agent")}
						</TerminalTextHeaders>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Detach the volume mount if it already exists"
							)}
						</TerminalTextSubHeaders>
						{"$ "}
						{detachMount}
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={detachMount} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Navigate to the directory where the agent was downloaded in the previous step, and proceed to execute the following commands to install the agent"
							)}
						</TerminalTextSubHeaders>
						{rawInstallationInfoInstall
							?.split(";")
							.map((instInfo: string, index: number) => (
								<Typography
									key={index}
									sx={{ wordBreak: "break-all", maxWidth: "100%" }}
								>
									<>
										{"$ "}
										{instInfo}
									</>
								</Typography>
							))}

						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoInstall} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Register the Agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoRegister}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoRegister} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Run the Agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoRun}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoRun} />
						</Box>
					</Box>
				);
			case PLATFORMS.RPM:
			case PLATFORMS.SUSE:
				return (
					<Box>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoDownload}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoDownload} />
						</Box>
						<Box display={"flex"} justifyContent={"center"}>
							{window.getCTTranslatedText("Or")}
						</Box>
						<Box paddingTop={2} paddingBottom={2}>
							{window.getCTTranslatedText(
								"If the above command does not work, please click"
							)}
							<Link href={downloadpackageLink}> {downloadpackageName} </Link>
							{window.getCTTranslatedText("to download the package")}
						</Box>
						<Divider
							color="black"
							sx={{ marginBottom: 2, marginTop: 5 }}
						></Divider>
						<TerminalTextHeaders paddingBottom={2} variant="h5">
							{pValue.goldenImage
								? window.getCTTranslatedText("Install and register the agent")
								: window.getCTTranslatedText("Install and run the agent")}
						</TerminalTextHeaders>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded in the previous step and run the command below to install the agent"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoInstall}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoInstall} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Register the Agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoRegister}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoRegister} />
						</Box>
						{!pValue.goldenImage && (
							<>
								<TerminalTextSubHeaders variant="body1">
									##{window.getCTTranslatedText("Run the Agent")}
								</TerminalTextSubHeaders>
								<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
									{"$ "}
									{rawInstallationInfoRun}
								</Typography>
								<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
									<CopyInstructions code={rawInstallationInfoRun} />
								</Box>
							</>
						)}
					</Box>
				);
			case PLATFORMS.SOLARIS:
				return (
					<Box>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoDownload}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoDownload} />
						</Box>
						<Box display={"flex"} justifyContent={"center"}>
							Or
						</Box>
						<Box paddingTop={2} paddingBottom={2}>
							{window.getCTTranslatedText(
								"If the above command does not work, please click"
							)}
							<Link href={downloadpackageLink}> {downloadpackageName} </Link>
							{window.getCTTranslatedText("to download the package")}
						</Box>
						<Divider
							color="black"
							sx={{ marginBottom: 2, marginTop: 5 }}
						></Divider>
						<TerminalTextHeaders paddingBottom={2} variant="h5">
							{window.getCTTranslatedText("Install and run the agent")}
						</TerminalTextHeaders>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Run the below command to create a file named 'ct_register_args' in the '/tmp' directory"
							)}
						</TerminalTextSubHeaders>
						{solarisRegisterArgs
							?.trim()
							?.split("\n")
							?.map((registerArg: string, index: number) => (
								<Typography
									key={index}
									sx={{ wordBreak: "break-all", maxWidth: "100%" }}
								>
									<>
										{index === 0 ? "$ " : ""}
										{registerArg}
									</>
								</Typography>
							))}
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions
								code={solarisRegisterArgs
									?.split("\n")
									?.map(line => line?.replace(/^\s+/, ""))
									?.filter(line => line?.trim() !== "")
									?.join("\n")}
							/>
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded in the previous step and run the below command to create a file named 'admin_noask'"
							)}
						</TerminalTextSubHeaders>
						{solarisNoAsk
							?.trim()
							?.split("\n")
							?.map((noAskArg: string, index: number) => (
								<Typography
									key={index}
									sx={{ wordBreak: "break-all", maxWidth: "100%" }}
								>
									<>
										{index === 0 ? "$ " : ""}
										{noAskArg}
									</>
								</Typography>
							))}
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions
								code={solarisNoAsk
									?.split("\n")
									?.map(line => line?.replace(/^\s+/, ""))
									?.filter(line => line?.trim() !== "")
									?.join("\n")}
							/>
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Once the 'admin_noask' file is created, run the following command from the same directory as above"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoInstall}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoInstall} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"If registration fails after attempting the previous command and needs to be retried, use the following command"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoRegister}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoRegister} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Run the Agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoRun}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoRun} />
						</Box>
					</Box>
				);

			case PLATFORMS.RPM_LEGACY:
				return (
					<Box>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoDownload}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoDownload} />
						</Box>
						<Box display={"flex"} justifyContent={"center"}>
							Or
						</Box>
						<Box paddingTop={2} paddingBottom={2}>
							{window.getCTTranslatedText(
								"If the above command does not work, please click"
							)}
							<Link href={downloadpackageLink}> {downloadpackageName} </Link>
							{window.getCTTranslatedText("to download the package")}
						</Box>
						<Divider
							color="black"
							sx={{ marginBottom: 2, marginTop: 5 }}
						></Divider>
						<TerminalTextHeaders paddingBottom={2} variant="h5">
							{window.getCTTranslatedText("Install and run the agent")}
						</TerminalTextHeaders>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded in the previous step and run the command below to install the agent"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoInstall}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoInstall} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Run the Agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoRun}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoRun} />
						</Box>
					</Box>
				);
			case PLATFORMS.AIX:
				return (
					<Box>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoDownload}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoDownload} />
						</Box>
						<Box display={"flex"} justifyContent={"center"}>
							Or
						</Box>
						<Box paddingTop={2} paddingBottom={2}>
							{window.getCTTranslatedText(
								"If the above command does not work, please click"
							)}
							<Link href={downloadpackageLink}> {downloadpackageName} </Link>
							{window.getCTTranslatedText("to download the package")}
						</Box>
						<Divider
							color="black"
							sx={{ marginBottom: 2, marginTop: 5 }}
						></Divider>
						<TerminalTextHeaders paddingBottom={2} variant="h5">
							{window.getCTTranslatedText("Install and run the agent")}
						</TerminalTextHeaders>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded in the previous step and run the command below to install the agent"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoInstall}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoInstall} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"If registration fails after attempting the previous command and needs to be retried, use the following command"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoRegister}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoRegister} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Run the Agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInstallationInfoRun}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInstallationInfoRun} />
						</Box>
					</Box>
				);
			case PLATFORMS.DOCKER:
			case PLATFORMS.OPENSHIFT:
				return (
					<Box>
						<Divider
							color="black"
							sx={{ marginBottom: 2, marginTop: 5 }}
						></Divider>
						<TerminalTextHeaders paddingBottom={2} variant="h5">
							{window.getCTTranslatedText("installContainerAgents")}
						</TerminalTextHeaders>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText("createCTSystemNamespace")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{containerSecurityInstallationProcess(containerSteps.CREATE)}
						</Typography>
						<Stack direction="row" justifyContent="flex-end">
							<CopyInstructions
								code={containerSecurityInstallationProcess(
									containerSteps.CREATE
								)}
							/>
						</Stack>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText("installCTContsecHelmChart")}
						</TerminalTextSubHeaders>
						{containerSecurityInstallationProcess(containerSteps.HELM)
							?.split(";")
							.map((instInfo: string, index: number) => (
								<Typography
									key={instInfo}
									sx={{ wordBreak: "break-all", maxWidth: "100%" }}
								>
									<>
										{"$ "}
										{instInfo}
									</>
								</Typography>
							))}
						<Stack direction="row" justifyContent="flex-end">
							<CopyInstructions
								code={containerSecurityInstallationProcess(containerSteps.HELM)}
							/>
						</Stack>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText("integrateIstioWithCollector")}
						</TerminalTextSubHeaders>
						{containerSecurityInstallationProcess(
							containerSteps.INTEGRATE_CONFIGMAPS
						)
							?.split(";")
							.map((instInfo: string, index: number) => (
								<Typography
									key={instInfo}
									sx={{ wordBreak: "break-all", maxWidth: "100%" }}
								>
									<>
										{"$ "}
										{instInfo}
									</>
								</Typography>
							))}
						<Stack direction="row" justifyContent="flex-end">
							<CopyInstructions
								code={containerSecurityInstallationProcess(
									containerSteps.INTEGRATE_CONFIGMAPS
								)}
							/>
						</Stack>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							<pre>
								{containerSecurityInstallationProcess(
									containerSteps.INTEGRATE_CONFIGMAPS_SCRIPT
								)}
							</pre>
						</Typography>
						<Stack direction="row" justifyContent="flex-end">
							<CopyInstructions
								code={containerSecurityInstallationProcess(
									containerSteps.INTEGRATE_CONFIGMAPS_SCRIPT
								)}
							/>
						</Stack>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText("restartIstioPodsToLoadNewConfig")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{containerSecurityInstallationProcess(
								containerSteps.RESTART_ISTIO
							)}
						</Typography>
						<Stack direction="row" justifyContent="flex-end">
							<CopyInstructions
								code={containerSecurityInstallationProcess(
									containerSteps.RESTART_ISTIO
								)}
							/>
						</Stack>
					</Box>
				);
		}
	}

	function rawCleanupInfo(platform: string) {
		platform = props?.pname;
		switch (platform) {
			case PLATFORMS.RPM:
			case PLATFORMS.SUSE:
				return `sudo rpm -e ${installedPackageName}`;
			case PLATFORMS.RPM_LEGACY:
				return `sudo rpm -e ${installedPackageName}`;
			case PLATFORMS.AIX:
				return `rpm -e ${installedPackageName}`;
			case PLATFORMS.SOLARIS:
				return `yes | sudo pkgrm ${installedPackageName}`;
			case PLATFORMS.DEBIAN:
				return `sudo dpkg -P ${installedPackageName}`;
			case PLATFORMS.DARWIN:
				return `sudo /usr/local/bin/colortokens/${installedPackageName}/ctuninstaller`;
			case PLATFORMS.WINDOWS:
				return `powershell -command "Get-WmiObject -Class Win32_Product -Filter \\""Name = '${windowsInstalledPackageName}'\\""|Invoke-WmiMethod -Name Uninstall"`;
			case PLATFORMS.DOCKER:
			case PLATFORMS.OPENSHIFT:
				return `kubectl label namespace ${pValue.targetNamespace} istio-injection- ct-enforcement-; kubectl rollout restart deploy -n ${pValue.targetNamespace}; helm uninstall ct-contsec -n ct-system`;
		}
	}

	const renderCleanupDetails = () => {
		let rawInfo = rawCleanupInfo(props?.pname);
		return (
			<Box>
				<TerminalTextSubHeaders variant="body1">
					##
					{isDockerOrOpenShift
						? window.getCTTranslatedText("removeContainerAgents")
						: window.getCTTranslatedText(
								"Copy and run the following command in the terminal to remove the agent"
							)}
				</TerminalTextSubHeaders>
				{rawInfo?.split(";").map((cleanUp: string, index: number) => (
					<Typography
						key={cleanUp}
						sx={{ wordBreak: "break-all", maxWidth: "100%" }}
					>
						{props?.pname === PLATFORMS.WINDOWS ? "C:\\> " : "$ "}
						{cleanUp}
					</Typography>
				))}
				<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
					<CopyInstructions code={rawInfo} />
				</Box>

				{isDockerOrOpenShift &&
					isLesserVersion(getVersionNumber(props), "5.8.8") && (
						<>
							<TerminalTextSubHeaders variant="body1">
								##
								{window.getCTTranslatedText("deleteCTResources")}
							</TerminalTextSubHeaders>
							{containerSecurityInstallationProcess(containerSteps.CLEANUP)
								?.split(";")
								.map((instInfo: string, index: number) => (
									<Typography
										key={instInfo}
										sx={{ wordBreak: "break-all", maxWidth: "100%" }}
									>
										<>
											{"$ "}
											{instInfo}
										</>
									</Typography>
								))}
							<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
								<CopyInstructions
									code={containerSecurityInstallationProcess(
										containerSteps.CLEANUP
									)}
								/>
							</Box>
						</>
					)}
			</Box>
		);
	};

	function rawManualUpgradeInfo(platform: string) {
		switch (platform) {
			case PLATFORMS.RPM:
			case PLATFORMS.SUSE:
				return `sudo /etc/colortokens/ctagent stop+\
        sudo rpm -Uvh ${downloadpackageName} +\
        sudo /etc/colortokens/ctagent start`;
			case PLATFORMS.RPM_LEGACY:
				return `sudo initctl stop ctagent+\
          sudo rpm -Uvh ${downloadpackageName} +\
          sudo initctl start ctagent`;
			case PLATFORMS.AIX:
				return `/etc/colortokens/ctagent stop+\
            rpm -Uvh ${downloadpackageName} +\
            /etc/colortokens/ctagent start`;
			case PLATFORMS.SOLARIS:
				return `sudo /etc/colortokens/ctagent stop+\
            echo all | sudo pkgadd -a admin_noask -d ${downloadpackageName}+\
            sudo /etc/colortokens/ctagent start`;
			case PLATFORMS.DARWIN:
				return `sudo /usr/local/bin/colortokens/${installedPackageName}/ctagent stop+\
        sudo hdiutil attach ${downloadpackageName}; sudo installer -pkg ${mountDirNameDarwin}/${extractedPackageNameDarwin} -target /usr/local/bin/;\
        sudo hdiutil detach ${mountDirNameDarwin}+\
        sudo /usr/local/bin/colortokens/${installedPackageName}/ctagent start;sudo /usr/local/bin/colortokens/${installedPackageName}/ctagent status`;
			case PLATFORMS.DEBIAN:
				if (props.agentType === AGENT_TYPES.CT_REMOTE_CONNECTOR) {
					return `sudo /etc/colortokens/xshield-remote-connector/ctagent stop+\
          sudo dpkg -i ${downloadpackageName} +\
          sudo /etc/colortokens/xshield-remote-connector/ctagent start`;
				} else {
					return `sudo /etc/colortokens/ctagent stop+\
          sudo dpkg -i ${downloadpackageName} +\
          sudo /etc/colortokens/ctagent start`;
				}
			case PLATFORMS.WINDOWS:
				return `powershell -command "cd '${windowsAgentInstallationDir}'; .\\ctagent.exe  stop"+\
        powershell -command "msiexec.exe /i ${downloadpackageName} /qb /l*v C:\\ProgramData\\Colortokens\\ctagent_upgrade_MSI.log"+\
        powershell -command "cd '${windowsAgentInstallationDir}'; .\\ctagent.exe  start ; .\\ctagent.exe status"`;
		}
	}

	const renderManualUpgrade = () => {
		let rawInfo = rawManualUpgradeInfo(props?.pname);
		switch (props?.pname) {
			case PLATFORMS.DEBIAN:
				return (
					<Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Download the new agent package")}
						</TerminalTextSubHeaders>
						<Typography sx={{ paddingBottom: 2 }}>
							{window.getCTTranslatedText("Click on")}{" "}
							<Link href={downloadpackageLink}>{downloadpackageName}</Link>{" "}
							{window.getCTTranslatedText("to download the package")}
						</Typography>
						{!pValue.goldenImage && (
							<>
								<TerminalTextSubHeaders variant="body1">
									##{window.getCTTranslatedText("Stop the agent")}
								</TerminalTextSubHeaders>
								<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
									{"$ "}
									{rawInfo?.split("+")[0]}
								</Typography>
								<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
									<CopyInstructions code={rawInfo?.split("+")[0]} />
								</Box>
							</>
						)}
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Take a backup of existing Colortokens directory"
							)}
						</TerminalTextSubHeaders>
						<Box sx={{ paddingBottom: 2 }}>
							{" "}
							{window.getCTTranslatedText(
								"Before proceeding, take a backup of"
							)}{" "}
							<InfoContent label={backupDirLinux} />{" "}
							{window.getCTTranslatedText("directory")}
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded and run the command below to upgrade the agent"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[1]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[1].trim()} />
						</Box>
						{!pValue.goldenImage && (
							<>
								<TerminalTextSubHeaders variant="body1">
									##{window.getCTTranslatedText("Start the agent")}
								</TerminalTextSubHeaders>
								<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
									{"$ "}
									{rawInfo?.split("+")[2]}
								</Typography>
								<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
									<CopyInstructions code={rawInfo?.split("+")[2].trim()} />
								</Box>
							</>
						)}
					</Box>
				);
			case PLATFORMS.DARWIN:
				const detachMount = `[ -d ${mountDirNameDarwin} ] && ${rawInfo
					?.split("+")[1]
					.split(";")[2]
					.trim()}`;
				return (
					<Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Download the new agent package")}
						</TerminalTextSubHeaders>
						<Typography sx={{ paddingBottom: 2 }}>
							{window.getCTTranslatedText("Click on")}{" "}
							<Link href={downloadpackageLink}>{downloadpackageName}</Link>{" "}
							{window.getCTTranslatedText("to download the package")}
						</Typography>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Stop the agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[0]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[0]} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Take a backup of existing Colortokens directory"
							)}
						</TerminalTextSubHeaders>
						<Box sx={{ paddingBottom: 2 }}>
							{" "}
							{window.getCTTranslatedText(
								"Before proceeding, take a backup of"
							)}{" "}
							<InfoContent label={backupDirDarwin} />{" "}
							{window.getCTTranslatedText("directory")}
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Detach the volume mount if it already exists"
							)}
						</TerminalTextSubHeaders>
						{"$ "}
						{detachMount}
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={detachMount} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded and run the command below to upgrade the agent"
							)}
						</TerminalTextSubHeaders>
						{rawInfo
							?.split("+")[1]
							?.split(";")
							?.map((instInfo: string, index: number) => (
								<Typography
									key={index}
									sx={{ wordBreak: "break-all", maxWidth: "100%" }}
								>
									<>{`${"$ "}${instInfo}`}</>
								</Typography>
							))}
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[1]?.trim()} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Start the agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[2]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[2]?.trim()} />
						</Box>
					</Box>
				);
			case PLATFORMS.RPM:
			case PLATFORMS.SUSE:
				return (
					<Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Download the new agent package")}
						</TerminalTextSubHeaders>
						<Typography sx={{ paddingBottom: 2 }}>
							{window.getCTTranslatedText("Click on")}{" "}
							<Link href={downloadpackageLink}>{downloadpackageName}</Link>{" "}
							{window.getCTTranslatedText("to download the package")}
						</Typography>
						{!pValue.goldenImage && (
							<>
								<TerminalTextSubHeaders variant="body1">
									##{window.getCTTranslatedText("Stop the agent")}
								</TerminalTextSubHeaders>
								<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
									{"$ "}
									{rawInfo?.split("+")[0]}
								</Typography>
								<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
									<CopyInstructions code={rawInfo?.split("+")[0]} />
								</Box>
							</>
						)}
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Take a backup of existing Colortokens directory"
							)}
						</TerminalTextSubHeaders>
						<Box sx={{ paddingBottom: 2 }}>
							{" "}
							{window.getCTTranslatedText(
								"Before proceeding, take a backup of"
							)}{" "}
							<InfoContent label={backupDirLinux} />{" "}
							{window.getCTTranslatedText("directory")}
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded and run the command below to upgrade the agent"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[1]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[1].trim()} />
						</Box>
						{!pValue.goldenImage && (
							<>
								<TerminalTextSubHeaders variant="body1">
									##{window.getCTTranslatedText("Start the agent")}
								</TerminalTextSubHeaders>
								<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
									{"$ "}
									{rawInfo?.split("+")[2]}
								</Typography>
								<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
									<CopyInstructions code={rawInfo?.split("+")[2].trim()} />
								</Box>
							</>
						)}
					</Box>
				);
			case PLATFORMS.RPM_LEGACY:
				return (
					<Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Download the new agent package")}
						</TerminalTextSubHeaders>
						<Typography sx={{ paddingBottom: 2 }}>
							{window.getCTTranslatedText("Click on")}{" "}
							<Link href={downloadpackageLink}>{downloadpackageName}</Link>{" "}
							{window.getCTTranslatedText("to download the package")}
						</Typography>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Stop the agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[0]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[0]} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Take a backup of existing Colortokens directory"
							)}
						</TerminalTextSubHeaders>
						<Box sx={{ paddingBottom: 2 }}>
							{" "}
							{window.getCTTranslatedText(
								"Before proceeding, take a backup of"
							)}{" "}
							<InfoContent label={backupDirLinux} />{" "}
							{window.getCTTranslatedText("directory")}
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded and run the command below to upgrade the agent"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[1]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[1].trim()} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Start the agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[2]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[2].trim()} />
						</Box>
					</Box>
				);

			case PLATFORMS.AIX:
				return (
					<Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Download the new agent package")}
						</TerminalTextSubHeaders>
						<Typography sx={{ paddingBottom: 2 }}>
							{window.getCTTranslatedText("Click on")}{" "}
							<Link href={downloadpackageLink}>{downloadpackageName}</Link>{" "}
							{window.getCTTranslatedText("to download the package")}
						</Typography>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Stop the agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[0]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[0]} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Take a backup of existing Colortokens directory"
							)}
						</TerminalTextSubHeaders>
						<Box sx={{ paddingBottom: 2 }}>
							{" "}
							{window.getCTTranslatedText(
								"Before proceeding, take a backup of"
							)}{" "}
							<InfoContent label={backupDirLinux} />{" "}
							{window.getCTTranslatedText("directory")}
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded and run the command below to upgrade the agent"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[1]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[1].trim()} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Start the agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[2]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[2].trim()} />
						</Box>
					</Box>
				);
			case PLATFORMS.SOLARIS:
				return (
					<Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Download the new agent package")}
						</TerminalTextSubHeaders>
						<Typography sx={{ paddingBottom: 2 }}>
							{window.getCTTranslatedText("Click on")}{" "}
							<Link href={downloadpackageLink}>{downloadpackageName}</Link>{" "}
							{window.getCTTranslatedText("to download the package")}
						</Typography>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Stop the agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[0]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[0]} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Take a backup of existing Colortokens directory"
							)}
						</TerminalTextSubHeaders>
						<Box sx={{ paddingBottom: 2 }}>
							{" "}
							{window.getCTTranslatedText(
								"Before proceeding, take a backup of"
							)}{" "}
							<InfoContent label={backupDirLinux} />{" "}
							{window.getCTTranslatedText("directory")}
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded in the previous step and run the below command to create a file named 'admin_noask'"
							)}
						</TerminalTextSubHeaders>
						{solarisNoAsk
							?.trim()
							?.split("\n")
							?.map((noAskArg: string, index: number) => (
								<Typography
									key={index}
									sx={{ wordBreak: "break-all", maxWidth: "100%" }}
								>
									<>
										{index === 0 ? "$ " : ""}
										{noAskArg}
									</>
								</Typography>
							))}
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions
								code={solarisNoAsk
									?.split("\n")
									?.map(line => line?.replace(/^\s+/, ""))
									?.filter(line => line?.trim() !== "")
									?.join("\n")}
							/>
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Once the 'admin_noask' file is created, run the following command from the same directory as above"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[1]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[1].trim()} />
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Start the agent")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{rawInfo?.split("+")[2]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[2].trim()} />
						</Box>
					</Box>
				);
			case PLATFORMS.WINDOWS:
				return (
					<Box>
						<TerminalTextSubHeaders variant="body1">
							##{window.getCTTranslatedText("Download the new agent package")}
						</TerminalTextSubHeaders>
						<Typography sx={{ paddingBottom: 2 }}>
							{window.getCTTranslatedText("Click on")}{" "}
							<Link href={downloadpackageLink}>{downloadpackageName}</Link>{" "}
							{window.getCTTranslatedText("to download the package")}
						</Typography>
						{!pValue.goldenImage && (
							<>
								<TerminalTextSubHeaders variant="body1">
									##{window.getCTTranslatedText("Stop the agent")}
								</TerminalTextSubHeaders>
								<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
									{"C:\\> "}
									{rawInfo?.split("+")[0]}
								</Typography>
								<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
									<CopyInstructions code={rawInfo?.split("+")[0]} />
								</Box>
							</>
						)}
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Take a backup of existing Colortokens directory"
							)}
						</TerminalTextSubHeaders>
						<Box sx={{ paddingBottom: 2 }}>
							{" "}
							{window.getCTTranslatedText(
								"Before proceeding, take a backup of"
							)}{" "}
							<InfoContent label={backupDirWindows} />{" "}
							{window.getCTTranslatedText("directory")}
						</Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText(
								"Go to the directory where the agent was downloaded and run the command below to upgrade the agent"
							)}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"C:\\> "}
							{rawInfo?.split("+")[1]}
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<CopyInstructions code={rawInfo?.split("+")[1].trim()} />
						</Box>
						{!pValue.goldenImage && (
							<>
								<TerminalTextSubHeaders variant="body1">
									##{window.getCTTranslatedText("Start the agent")}
								</TerminalTextSubHeaders>
								<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
									{"C:\\> "}
									{rawInfo?.split("+")[2]}
								</Typography>
								<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
									<CopyInstructions code={rawInfo?.split("+")[2].trim()} />
								</Box>
							</>
						)}
					</Box>
				);
			case PLATFORMS.DOCKER:
			case PLATFORMS.OPENSHIFT:
				return (
					<Box>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText("UpgradeContainerAgents")}
						</TerminalTextSubHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"$ "}
							{containerSecurityInstallationProcess(containerSteps.UPGRADE)}
						</Typography>
						<Stack direction="row" justifyContent="flex-end">
							<CopyInstructions
								code={containerSecurityInstallationProcess(
									containerSteps.UPGRADE
								)}
							/>
						</Stack>
					</Box>
				);
		}
	};

	const enableIstioSccCommand =
		"oc adm policy add-scc-to-group privileged system:serviceaccounts:<target-namespace>";

	const renderManualEnforce = () => {
		return (
			<Box>
				<Stack style={{ paddingBottom: 10 }}>
					<Alert severity="info" style={{ borderRadius: "50px" }}>
						{window.getCTTranslatedText("verifyServicesReady")}
						<StyledChip
							label="kubectl -n ct-system get pods"
							variant="filled"
						/>
						{window.getCTTranslatedText("beforeProceeding")}
					</Alert>
				</Stack>
				{isLesserVersion(getVersionNumber(props), "5.8.8") && (
					<>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText("copyConfigmaps")}
						</TerminalTextSubHeaders>
						{containerSecurityInstallationProcess(
							containerSteps.COPY_CONFIGMAPS
						)
							?.split(";")
							.map((instInfo: string, index: number) => (
								<Typography
									key={instInfo}
									sx={{ wordBreak: "break-all", maxWidth: "100%" }}
								>
									<>
										{"$ "}
										{instInfo}
									</>
								</Typography>
							))}
						<Stack direction="row" justifyContent="flex-end">
							<CopyInstructions
								code={containerSecurityInstallationProcess(
									containerSteps.COPY_CONFIGMAPS
								)}
							/>
						</Stack>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText("copyCTTelemetry")}
						</TerminalTextSubHeaders>
						{containerSecurityInstallationProcess(containerSteps.COPY_TELEMETRY)
							?.split(";")
							.map((instInfo: string, index: number) => (
								<Typography
									key={instInfo}
									sx={{ wordBreak: "break-all", maxWidth: "100%" }}
								>
									{"$ "}
									{instInfo}
								</Typography>
							))}
						<Stack direction="row" justifyContent="flex-end">
							<CopyInstructions
								code={containerSecurityInstallationProcess(
									containerSteps.COPY_TELEMETRY
								)}
							/>
						</Stack>
					</>
				)}
				{props?.pname === PLATFORMS.OPENSHIFT && (
					<>
						<TerminalTextSubHeaders variant="body1">
							##
							{window.getCTTranslatedText("grantingSccForOpenShift")}
						</TerminalTextSubHeaders>

						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{window.getCTTranslatedText("istioSccPrimaryText")}
						</Typography>
						<Typography
							sx={{ wordBreak: "break-all", maxWidth: "100%", my: 3 }}
						>
							{"$ "}
							{enableIstioSccCommand}
						</Typography>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{window.getCTTranslatedText("istioSccSecondaryText")}
						</Typography>
						<Stack direction="row" justifyContent="flex-end">
							<CopyInstructions
								code={
									"oc adm policy add-scc-to-group privileged system:serviceaccounts:<target-namespace>"
								}
							/>
						</Stack>
					</>
				)}
				<TerminalTextSubHeaders variant="body1">
					##
					{window.getCTTranslatedText(
						"injectIstioAndOPASidecarsOnTargetNamespace"
					)}
				</TerminalTextSubHeaders>
				<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
					{"$ "}
					{containerSecurityInstallationProcess(containerSteps.INJECT_ISTIO)}
				</Typography>
				<Stack direction="row" justifyContent="flex-end">
					<CopyInstructions
						code={containerSecurityInstallationProcess(
							containerSteps.INJECT_ISTIO
						)}
					/>
				</Stack>
				<TerminalTextSubHeaders variant="body1">
					##
					{window.getCTTranslatedText("restartDeploymentsInTargetNamespace")}
				</TerminalTextSubHeaders>
				{containerSecurityInstallationProcess(containerSteps.RESTART_DEPLOYMENT)
					?.split(";")
					.map((instInfo: string, index: number) => (
						<Typography
							key={instInfo}
							sx={{ wordBreak: "break-all", maxWidth: "100%" }}
						>
							<>
								{"$ "}
								{instInfo}
							</>
						</Typography>
					))}
				<Stack direction="row" justifyContent="flex-end">
					<CopyInstructions
						code={containerSecurityInstallationProcess(
							containerSteps.RESTART_DEPLOYMENT
						)}
					/>
				</Stack>
			</Box>
		);
	};

	return (
		<Box>
			{renderAgentPrerequisiteDetails()}
			{props?.pname !== PLATFORMS.DOCKER &&
				props?.pname !== PLATFORMS.OPENSHIFT && (
					<TerminalTextHeaders variant="h5">
						{window.getCTTranslatedText("Download the agent")}
					</TerminalTextHeaders>
				)}
			{renderManualAgentInstallationDetails()}
			{
				<>
					{isDockerOrOpenShift && (
						<>
							<Divider
								color="black"
								sx={{ marginBottom: 2, marginTop: 5 }}
							></Divider>
							<TerminalTextHeaders variant="h5">
								{window.getCTTranslatedText("enforceTargetNamespace")}
							</TerminalTextHeaders>
							{renderManualEnforce()}
						</>
					)}
					<Divider
						color="black"
						sx={{ marginBottom: 2, marginTop: 5 }}
					></Divider>
					<TerminalTextHeaders variant="h5">
						{isDockerOrOpenShift
							? window.getCTTranslatedText("UpgradeContainerAgents")
							: window.getCTTranslatedText("Upgrade the agent")}
					</TerminalTextHeaders>
					{renderManualUpgrade()}
					<Divider
						color="black"
						sx={{ marginBottom: 2, marginTop: 5 }}
					></Divider>
				</>
			}
			{isDockerOrOpenShift ? (
				<TerminalTextHeaders variant="h5">
					{window.getCTTranslatedText("uninstallContainerAgent")}
				</TerminalTextHeaders>
			) : (
				<TerminalTextHeaders variant="h5">
					{window.getCTTranslatedText("Uninstall the agent")}
				</TerminalTextHeaders>
			)}
			{renderCleanupDetails()}
			{props.pname === PLATFORMS.WINDOWS && (
				<Box
					sx={{ wordBreak: "break-all", maxWidth: "100%", paddingBottom: 2 }}
				>
					( Note : The above command might not work in some of the older
					versions of Windows. In that case, Go to{" "}
					<InfoContent label="Control Panel" /> {"-->"}{" "}
					<InfoContent label="Programs" /> {"-->"}{" "}
					<InfoContent label="Uninstall a program" /> and click on{" "}
					<InfoContent label={windowsInstalledPackageName} /> to remove the
					agent package)
				</Box>
			)}
		</Box>
	);
}

export function GetAgentInstallationInfoScript(props: IAgentInstallProp) {
	const pValue = useContext(MyContext);
	let upgradeLinux =
		props?.pname === PLATFORMS.RPM_LEGACY
			? EMPTY_STRING
			: pValue.autoUpgrade
				? `CT_AUTO_UPGRADE="true"`
				: `CT_AUTO_UPGRADE="false"`;

	let upgradeWindows = pValue.autoUpgrade
		? "-CT_AUTO_UPGRADE 'true'"
		: "-CT_AUTO_UPGRADE 'false'";

	let goldenImageLinux = [
		PLATFORMS.SOLARIS,
		PLATFORMS.RPM_LEGACY,
		PLATFORMS.DARWIN,
		PLATFORMS.AIX,
		PLATFORMS.DOCKER,
		PLATFORMS.OPENSHIFT,
	]
		.map(plat => plat.toString())
		.includes(props.pname)
		? EMPTY_STRING
		: pValue.goldenImage
			? `CT_GOLDEN_IMAGE="true"`
			: EMPTY_STRING;

	let goldenImageWindows = pValue.goldenImage
		? "-CT_GOLDEN_IMAGE 'true'"
		: EMPTY_STRING;

	function rpmConfigDetails() {
		return (
			<>
				<Typography>
					{window.getCTTranslatedText(
						"Make sure that your yum configuration is updated with proxy details. Or else, follow the instructions below"
					)}{" "}
					-{" "}
				</Typography>
				<Typography paddingLeft={2}>1. Open /etc/yum.conf </Typography>
				<Typography paddingLeft={2}>
					2. Paste the below line in the section of the yum.conf file as a new
					line
				</Typography>
				<Typography paddingLeft={5}>
					proxy={pValue.protocolType}://{pValue.proxyName}:{pValue.proxyPort}
				</Typography>
				{pValue.authProxy && (
					<>
						{" "}
						<Typography paddingLeft={5}>
							proxy_username={pValue.username}
						</Typography>
						<Typography paddingLeft={5}>
							proxy_password={pValue.userPassword}
						</Typography>
					</>
				)}
				<Divider color="black" sx={{ marginBottom: 2, marginTop: 5 }}></Divider>
			</>
		);
	}
	function suseConfigDetails() {
		return (
			<>
				<>
					<Typography>
						{window.getCTTranslatedText(
							"Make sure that your proxy configuration is updated with proxy details. Or else, follow the instructions below "
						)}
						-{" "}
					</Typography>
					<Typography paddingLeft={2}>1. Open /etc/sysconfig/proxy </Typography>
					<Typography paddingLeft={2}>
						2. Paste the below line in the /etc/sysconfig/proxy file as a new
						line
					</Typography>
					<Typography paddingLeft={5}>PROXY_ENABLED="yes"</Typography>
					<Typography paddingLeft={5}>
						{pValue.protocolType === "http" ? "HTTP_PROXY" : "HTTPS_PROXY"}="
						{pValue.protocolType}://{pValue.proxyName}:{pValue.proxyPort}"
					</Typography>
					{pValue.authProxy && (
						<>
							{" "}
							<Typography paddingLeft={2}>
								3. Create a file named /root/.curlrc, and add the proxy user and
								password details to it as outlined below
							</Typography>
							<Typography paddingLeft={5}>
								proxy-user="{pValue.username}:{pValue.userPassword}"
							</Typography>
						</>
					)}
				</>
				<Divider color="black" sx={{ marginBottom: 2, marginTop: 5 }}></Divider>
			</>
		);
	}

	let linuxInstallScript = `${upgradeLinux}${" "}${" "}${goldenImageLinux}${" "}${
		pValue.proxy
			? `PROXY="${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}"`
			: ""
	}${" "}${
		pValue.authProxy
			? `PROXY_USERNAME="${
					pValue.username
				}"${" "}PROXY_PASSWORD="${textToBase64(
					curlProxyEncodeURIComponent(pValue.userPassword)
				)}"`
			: ""
	}${" "}CT_DEPLOYMENT_KEY="${
		props?.latestDepKey
	}" CT_DOMAIN="${envName}" CT_AGENT_VERSION="${getVersionNumber(
		props
	)}" CT_AGENT_TYPE="${
		props?.selectedAgentTypeValue
	}" bash -c "$(curl -L --tlsv1.2 ${
		pValue.proxy
			? `--proxy ${pValue.protocolType}://${
					pValue.authProxy
						? `${pValue.username}:${curlProxyEncodeURIComponent(
								pValue.userPassword
							)}@`
						: ""
				}${pValue.proxyName}:${pValue.proxyPort}`
			: ""
	} '${props?.latestLinuxScriptDownloadLink}')"`;

	let windowsInstallScript = `powershell -command  "Invoke-Expression 'Set-Location -Path $env:USERPROFILE';\
  Set-ExecutionPolicy -ExecutionPolicy Unrestricted -Scope Process;\
  [Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12;\
  Invoke-WebRequest ${
		pValue.proxy
			? `-Proxy '${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}'`
			: ""
	} ${
		pValue.authProxy
			? `-ProxyCredential (New-Object System.Management.Automation.PSCredential -ArgumentList '${pValue.username}',(ConvertTo-SecureString '${pValue.userPassword}' -AsPlainText -Force))`
			: ""
	} -ContentType application/octet-stream -OutFile .\\installation-script.ps1 '${
		props?.latestWindowsScriptDownloadLink
	}';\
  .\\installation-script.ps1 -CT_DEPLOYMENT_KEY '${
		props?.latestDepKey
	}' -CT_DOMAIN '${envName}' -CT_AGENT_VERSION '${getVersionNumber(props)}'\
  ${
		pValue.proxy
			? `-PROXY '${pValue.protocolType}://${pValue.proxyName}:${pValue.proxyPort}'`
			: ""
	}  ${
		pValue.authProxy
			? `-PROXY_USERNAME '${pValue.username}' -PROXY_PASSWORD '${textToBase64(
					curlProxyEncodeURIComponent(pValue.userPassword)
				)}'`
			: ""
	} -CT_AGENT_TYPE '${
		props?.selectedAgentTypeValue
	}'${" "}${upgradeWindows}${" "}${goldenImageWindows}${" "}"`;

	function renderInstallationScript(platform: string): JSX.Element {
		switch (platform) {
			case PLATFORMS.WINDOWS:
				return (
					<Box>
						<TerminalTextHeaders variant="h5">
							{window.getCTTranslatedText("Installation Script")}
						</TerminalTextHeaders>
						<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
							{"C:\\> "}
							{windowsInstallScript}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								paddingBottom: 2,
							}}
						>
							<CopyInstructions code={windowsInstallScript} />
						</Box>
					</Box>
				);
			case PLATFORMS.RPM:
			case PLATFORMS.SUSE:
			case PLATFORMS.DEBIAN:
			case PLATFORMS.DARWIN:
			case PLATFORMS.AIX:
			case PLATFORMS.RPM_LEGACY:
			case PLATFORMS.SOLARIS:
				return (
					<>
						{pValue?.proxy && platform === PLATFORMS.RPM && (
							<Box>
								<TerminalTextHeaders variant="h5">
									{window.getCTTranslatedText("Prerequisites")}
								</TerminalTextHeaders>
								{rpmConfigDetails()}
							</Box>
						)}
						{pValue?.proxy && platform === PLATFORMS.SUSE && (
							<Box>
								<TerminalTextHeaders variant="h5">
									{window.getCTTranslatedText("Prerequisites")}
								</TerminalTextHeaders>
								{suseConfigDetails()}
							</Box>
						)}
						<>
							<TerminalTextHeaders variant="h5">
								{window.getCTTranslatedText("Installation Script")}
							</TerminalTextHeaders>
							<Typography sx={{ wordBreak: "break-all", maxWidth: "100%" }}>
								{"$ "}
								{linuxInstallScript}
							</Typography>
							<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
								<CopyInstructions code={linuxInstallScript} />
							</Box>
						</>
					</>
				);
			default:
				return <></>;
		}
	}

	return <>{renderInstallationScript(props?.pname)}</>;
}

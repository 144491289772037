import { Box, Stack, Typography } from "@mui/material";
import { AgentColumnRenderProps } from "pages/agents/types";

type utilization = "cpuUtilizationStr" | "memoryUtilizationStr";

interface AgentUtilizationProps extends AgentColumnRenderProps {
	field: utilization;
}

export function AgentUtilization({ agent, field }: AgentUtilizationProps) {
	if (!agent?.agentId) {
		return <Box style={{ minWidth: 120 }} />;
	}
	return (
		<Box style={{ width: 120 }}>
			<Stack alignItems="left" justifyContent="center">
				<Typography variant="body2">{agent[field] || ""}</Typography>
			</Stack>
		</Box>
	);
}

import { Drawer, ListItem, ListItemProps, styled } from "@mui/material";

interface StyledNavSidebarProps {
	expandedWidth: string;
}

export const StyledNavSidebar = styled(Drawer)<StyledNavSidebarProps>(
	({ theme, expandedWidth }) => `
	& {
		width: 70px;
		transition: width ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp};
		
		flex-shrink: 0;
		white-space: nowrap;
		box-sizing: border-box;
		position: relative;
	}

	&.expanded {
		width: ${expandedWidth};
	}

	& > .MuiPaper-root {
		position: static;
		width: inherit;
		max-height: 100dvh;
		background-color: ${theme.palette.custom.appNavbar};
		border-color: ${theme.palette.custom.appNavbarBdr};
		box-shadow: unset;
	}

	.menu-expander {
		position: absolute;
		top: 60px;
		right: 0;
		translate: 50% 0;
	}
`
);

interface StyledNavListItemProps extends ListItemProps {
	isActive: boolean;
}

export const StyledNavListItem = styled(ListItem)<StyledNavListItemProps>(
	({ theme, isActive }) => `
	& {
		background-color: transparent;
		${
			isActive
				? `
				background-color: ${theme.palette.custom.appNavbarActive};
				border-left: ${`4px solid ${theme.palette.primary.main}`};
			`
				: ""
		}
	}

	.MuiButtonBase-root {
		min-height: 48px;
		padding: 0.375rem 1.3125rem;
		gap: 0.75rem;
	}

	.MuiListItemText-root {
		overflow: hidden;
	}
`
);

import { DisplayTextMap } from "common/constants/displayTextMap";
import { isValidIp, isValidMAC, isValidSubnet } from "common/utils";
import { ipToLong } from "common/utils/ipSubnetValid";
import { isValidIPMAC } from "common/utils/macValidator";
import { Appliance, ApplianceFormProps } from "pages/appliances/types";
import {
	ApplianceConfig,
	ApplianceConfigPayload,
	AuthenticationOptions,
	BooleanFlags,
	DHCPModes,
	HAModes,
	IPRangeInt,
	NetworkInterfaceConfig,
	NetworkInterfaces,
	PeerDetails,
	ProxyOptions,
	ProxySettings,
	VLANConfig,
} from "./types";

export const IP_PLACEHOLDER = "eg 192.0.2.0";
export const IPWithMask_PLACEHOLDER = "eg 192.0.2.0/24";
export const MACAddress_PLACEHOLDER = `MAC or MAC;IP Address`;

export const isPasswordValid = (password: string) => {
	if (password?.length >= 8) {
		return true;
	}
	return false;
};

export const getStartIp = (subnet: string) => {
	return subnet.split("/")[0];
};

export const mapDNSServers = (dnsServers: Array<IPRangeInt>) => {
	let servers: Array<string> = [];
	for (let dnsServer of dnsServers) {
		servers.push(dnsServer?.value);
	}
	return servers;
};

export const SToMin = (seconds?: string) => {
	if (!seconds) {
		seconds = "0";
	}
	const s: number = Number(seconds);
	return `${(s / 60).toFixed(2)}`;
};

export const minToS = (min: string) => {
	if (!min) {
		min = "0";
	}
	const m: number = Number(min);
	return `${m * 60}`;
};

export const getRandomId = () => {
	return `${Math.floor(Math.random() * 100 + 1)}`;
};

export const getDefaultDNSServers = () => {
	return [
		{
			value: "8.8.8.8",
		},
	];
};

export const getPeerType = (HAMode: string) => {
	if (HAMode === HAModes.Primary) {
		return DisplayTextMap[HAModes.Standby];
	}
	return DisplayTextMap[HAModes.Primary];
};

export const hasPrimaryAppliance = (HAMode: string) => {
	if (
		HAMode === HAModes.Primary ||
		HAMode === HAModes.StandAlone ||
		HAMode === HAModes.Standby
	) {
		return true;
	}
	return false;
};

export const exportConfig = (
	config: ApplianceConfigPayload,
	fileName?: string
) => {
	// create file in browser
	const json = JSON.stringify(config, null, 2);
	const blob = new Blob([json], { type: "application/json" });
	const href = URL.createObjectURL(blob);

	// create "a" HTLM element with href to file
	const link = document.createElement("a");
	link.href = href;
	link.download = fileName + ".json";
	document.body.appendChild(link);
	link.click();

	// clean up "a" element & remove ObjectURL
	document.body.removeChild(link);
	URL.revokeObjectURL(href);
};

export const isConfigurable = (appliance?: ApplianceFormProps | Appliance) => {
	return Boolean(appliance?.gatekeeperConfigUpdateAllowed);
};

export const isConfigInvalid = (
	config: ApplianceConfig,
	peerApplianceDetails?: PeerDetails,
	proxySettings?: ProxySettings
) => {
	//Schema Validator
	if (!config.dhcp || !config.interfaces) {
		return "Config";
	}

	if (!config?.agent?.hostname?.trim().length) {
		return "gatekeeperName";
	}

	//Proxy Validator
	if (proxySettings?.proxyEnabled === ProxyOptions.Yes) {
		try {
			new URL(config?.agent?.proxy?.url ?? "");
		} catch {
			return "Proxy URL";
		}
		if (proxySettings?.authenticationEnabled === AuthenticationOptions.Yes) {
			if (!config?.agent?.proxy?.username) {
				return "Proxy Username";
			}
			if (!config?.agent?.proxy?.password) {
				return "Proxy Password";
			}
		}
	}

	//HA Validator
	if (config?.vrrp && config?.vrrp?.enabled === BooleanFlags.True) {
		if (!config?.vrrp?.password || config?.vrrp?.password?.length > 8) {
			return "VRRP Password";
		}
	}

	//DHCP Validator
	if (config?.dhcp && config?.dhcp?.enabled === BooleanFlags.True) {
		if (config?.dhcp?.mode === DHCPModes.Relay) {
			if (!isValidIp(config?.dhcp?.serverIp)) {
				return "DHCP Server IP";
			}
		}
		if (isDNSServerInvalid(config?.dhcp?.dnsServers)) {
			return "DNS Servers";
		}
	}

	//MAC Address Validator
	if (config?.dhcp?.optInSupport === BooleanFlags.True) {
		if (isMacAddressInvalid(config?.dhcp.optInDevices)) {
			return "MAC Address";
		}
	}

	//Peer Appliance Validator
	if (
		config?.vrrp?.enabled === BooleanFlags.True &&
		peerApplianceDetails?.peerEnabled &&
		!peerApplianceDetails?.peerApplianceIDs?.length
	) {
		return "Peer Gatekeeper Selected";
	}

	//Network Validator
	if (config?.interfaces?.length) {
		let areNetworksInvalid = areNetworkInterfacesInvalid(
			config,
			peerApplianceDetails?.peerApplianceIDs
		);
		if (areNetworksInvalid) {
			return areNetworksInvalid;
		}
	}
	return false;
};

function isMacAddressInvalid(optInDevices?: Array<string>) {
	if (optInDevices?.length) {
		for (let device of optInDevices) {
			if (!isValidMAC(device) && !isValidIPMAC(device)) {
				return true;
			}
		}
	}
	return false;
}

function areNetworkInterfacesInvalid(
	config: ApplianceConfig,
	peerApplianceIDs?: Array<string>
) {
	if (config?.interfaces?.length) {
		for (let interf of config.interfaces) {
			if (interf?.type === NetworkInterfaces.LAN) {
				let LANInvalid = isLANInvalid(config, interf, "LAN", peerApplianceIDs);
				if (LANInvalid) {
					return LANInvalid;
				}
			}
			if (interf?.type === NetworkInterfaces.VLAN) {
				if (interf?.vlans?.length) {
					for (let vlan of interf.vlans) {
						let LANInvalid = isLANInvalid(
							config,
							vlan,
							"VLAN",
							peerApplianceIDs
						);
						if (LANInvalid) {
							return LANInvalid;
						}
					}
				}
				if (!interf?.vlans?.length) {
					return "VLAN List";
				}
			}
			if (interf?.type === NetworkInterfaces.WAN) {
				let WANInvalid = isWANInvalid(config, interf, peerApplianceIDs);
				if (WANInvalid) {
					return WANInvalid;
				}
			}
		}
	}
	return false;
}

function isWANInvalid(
	config: ApplianceConfig,
	interf: NetworkInterfaceConfig,
	peerApplianceIDs?: Array<string>
) {
	if (!isValidSubnet(interf.ipAddress)) {
		return "WAN IP Address";
	}
	if (!isValidIp(interf.gatewayAddress)) {
		return "WAN Gateway Address";
	}
	if (
		config?.vrrp?.enabled === BooleanFlags.True &&
		!isValidIp(interf?.vrrp?.virtualIp)
	) {
		return "WAN Virtual IP";
	}
	if (
		config?.vrrp?.enabled === BooleanFlags.True &&
		interf?.vrrp?.peerIps?.length &&
		peerApplianceIDs?.length &&
		!isValidSubnet(interf?.vrrp?.peerIps[0])
	) {
		return "WAN Peer Physical IP";
	}
	return false;
}

function isLANInvalid(
	config: ApplianceConfig,
	interf: NetworkInterfaceConfig | VLANConfig,
	type: string,
	peerApplianceIDs?: Array<string>
) {
	if (!isValidSubnet(interf.ipAddress)) {
		return `${type} IP Address`;
	}
	if (
		config?.dhcp?.enabled === BooleanFlags.True &&
		config?.dhcp?.mode === DHCPModes.Server &&
		!isValidIp(interf?.dhcp?.rangeStart)
	) {
		return `${type} Start IP`;
	}
	if (
		config?.dhcp?.enabled === BooleanFlags.True &&
		config?.dhcp?.mode === DHCPModes.Server &&
		!isValidIp(interf?.dhcp?.rangeEnd)
	) {
		return `${type} End IP`;
	}
	if (
		config?.dhcp?.enabled === BooleanFlags.True &&
		config?.dhcp?.mode === DHCPModes.Server &&
		!compareIPs(interf?.dhcp?.rangeStart, interf?.dhcp?.rangeEnd)
	) {
		return `${type} Start & End IP Range`;
	}
	if (
		config?.vrrp?.enabled === BooleanFlags.True &&
		!isValidIp(interf?.vrrp?.virtualIp)
	) {
		return `${type} Virtual IP`;
	}
	if (
		config?.dhcp?.mode === DHCPModes.Server &&
		config?.dhcp?.enabled === BooleanFlags.True &&
		config?.dhcp?.optInSupport === BooleanFlags.True &&
		interf?.dhcp?.existingGatewayIp &&
		!isValidIp(interf?.dhcp?.existingGatewayIp)
	) {
		return `${type} Existing Gateway IP`;
	}
	if (interf?.dhcp?.existingGatewayIp === interf?.ipAddress?.split("/")[0]) {
		return `${type} Existing Gateway IP, leave existing gateway field empty to use gatekeeper as gateway`;
	}
	if (
		config?.vrrp?.enabled === BooleanFlags.True &&
		interf?.vrrp?.peerIps?.length &&
		peerApplianceIDs?.length &&
		!isValidSubnet(interf?.vrrp?.peerIps[0])
	) {
		return `${type} Peer Physical IP`;
	}
	return false;
}

function isDNSServerInvalid(dnsServers?: Array<string>) {
	if (!dnsServers) {
		return true;
	}
	for (let server of dnsServers) {
		if (!isValidIp(server)) {
			return true;
		}
	}
	return false;
}

const compareIPs = (ip1?: string, ip2?: string) => {
	// Convert IPs to long format to compare numerically
	const ip1Long = ipToLong(ip1 ?? "");
	const ip2Long = ipToLong(ip2 ?? "");
	return ip1Long < ip2Long;
};

import { useQuery } from "@tanstack/react-query";

export interface Tenant {
	name: string;
	tenantID: string;
	email: string;
	website: string | null;
}

export function useTenantProfileAPI() {
	return useQuery<Tenant, Error>(["tenant-profile", "auth/tenant-profile"], {
		refetchOnWindowFocus: false,
	});
}

import { Stack, Typography } from "@mui/material";
import { Terminal } from "pages/agents/components/agent-download-page/Terminal";
import { InstructionItemProps, InstructionsRendererProps } from "./type";

function InstructionItem({
	key,
	instruction,
	stepCount,
}: InstructionItemProps) {
	function renderStepCount() {
		return (
			<Typography
				sx={{
					textTransform: "uppercase",
					color: theme => theme.palette.grey[600],
				}}
				variant="caption"
			>
				{window.getCTTranslatedText("stepCount", {
					count: stepCount,
				})}
				{instruction.isOptional
					? ` (${window.getCTTranslatedText("optional")})`
					: null}
			</Typography>
		);
	}

	function renderTitle() {
		return (
			<Typography variant="subtitle1">
				{window.getCTTranslatedText(instruction.title)}
			</Typography>
		);
	}

	function renderSubtitle() {
		if (!instruction.subtitle) {
			return null;
		}
		return (
			<Typography variant="body2">
				{window.getCTTranslatedText(instruction.subtitle)}
			</Typography>
		);
	}

	function renderTerminal(terminalCommands: string[]) {
		if (!terminalCommands?.length) {
			return null;
		}
		return (
			<Stack pt={1}>
				<Terminal>
					<>
						{terminalCommands.map((command, index) => {
							return (
								<Typography key={index} variant="body1">
									{window.getCTTranslatedText(command)}
								</Typography>
							);
						})}
					</>
				</Terminal>
			</Stack>
		);
	}

	function renderInstructionContent() {
		if (!instruction.instructionContent) {
			return null;
		}
		return instruction.instructionContent();
	}

	return (
		<Stack spacing={2} mb={10} key={key}>
			{renderStepCount()}
			{renderTitle()}
			{renderSubtitle()}
			{renderTerminal(instruction?.terminalCommands ?? [])}
			{renderInstructionContent()}
			{renderTerminal(instruction?.secondaryTerminalCommands ?? [])}
		</Stack>
	);
}

export function InstructionsRenderer({
	instructions,
}: InstructionsRendererProps) {
	return (
		<Stack mt={4}>
			{instructions.map((instruction, index) => {
				return (
					<InstructionItem
						key={instruction?.title}
						instruction={instruction}
						stepCount={index + 1}
					/>
				);
			})}
		</Stack>
	);
}

import {
	getAgentVersions,
	getLatestVersion,
} from "pages/agents/components/agent-download-page/helpers";
import { useVersionAPI } from "../agent-downlaod-version";
import { DownloadItem } from "./DownloadItem";
import { AGENT_TYPES, PLATFORMS } from "./constants";
import {
	AgentVersionDetails,
	AgentVersion as DownloadAgentVersion,
} from "./type";

export interface AgentDownloadListProps
	extends Pick<
		DownloadAgentVersion,
		| "agentType"
		| "latestDepKey"
		| "page"
		| "agentsGroupByPlatforms"
		| "setAgentsGroupByPlatforms"
	> {
	agents: string[];
	shouldShowListItem: (platform?: string) => boolean;
}

export const AgentDownloadList = ({
	agents,
	agentType,
	latestDepKey,
	page,
	agentsGroupByPlatforms,
	setAgentsGroupByPlatforms,
	shouldShowListItem,
}: AgentDownloadListProps) => {
	const { data: AgentPlatformData } = useVersionAPI();
	let platformAgentData = getAgentVersions(AgentPlatformData);

	const latestLinuxScriptDetails = () => platformAgentData[PLATFORMS.LINUX];
	const latestWindowsScriptDetails = () =>
		platformAgentData[PLATFORMS.POWERSHELL];

	return (
		<>
			{agents.map(platform => {
				if (
					!platformAgentData[platform as keyof AgentVersionDetails]
						?.versionsInfo?.length ||
					!shouldShowListItem(platform)
				) {
					return null;
				}

				return (
					<DownloadItem
						key={`agent-${platform}`}
						platform={platform as PLATFORMS}
						agentType={agentType}
						platformData={
							platformAgentData[platform as keyof AgentVersionDetails]
						}
						agentUserVersions={getLatestVersion(
							platformAgentData,
							platform,
							AGENT_TYPES.CT_USER_AGENT
						)}
						agentServerVersions={getLatestVersion(
							platformAgentData,
							platform,
							AGENT_TYPES.CT_AGENT
						)}
						agentLegacyVersions={getLatestVersion(
							platformAgentData,
							platform,
							AGENT_TYPES.CT_LEGACY_AGENT
						)}
						agentConnectorVersions={getLatestVersion(
							platformAgentData,
							platform,
							AGENT_TYPES.CT_REMOTE_CONNECTOR
						)}
						agentContainerVersions={getLatestVersion(
							platformAgentData,
							platform,
							AGENT_TYPES.CT_CONTAINER_AGENT
						)}
						latestDepKey={latestDepKey}
						latestLinuxScriptDetails={latestLinuxScriptDetails()}
						latestWindowsScriptDetails={latestWindowsScriptDetails()}
						page={page}
						agentsGroupByPlatforms={agentsGroupByPlatforms}
						setAgentsGroupByPlatforms={setAgentsGroupByPlatforms}
					/>
				);
			})}
		</>
	);
};

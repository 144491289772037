import { Stack, Tooltip } from "@mui/material";
import { UsersColumnRenderProps } from "../../types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export const UserName = ({ user, viewOnly }: UsersColumnRenderProps) => {
	const typeFacetName = "displayname";
	const typeOptionName = user?.displayName?.toString();

	const typeTitle = user.displayName;

	return (
		<Stack
			sx={{
				height: "100%",
				justifyContent: "space-evenly",
				width: "100%",
				overflow: "hidden",
				textOverflow: "ellipsis",
			}}
		>
			<Stack direction="row">
				<Tooltip title={`${typeTitle}`}>
					<CTDatagridButton
						asText={viewOnly}
						isDisabled={viewOnly}
						facetName={typeFacetName}
						facetOption={typeOptionName}
					>
						{typeTitle}
					</CTDatagridButton>
				</Tooltip>
			</Stack>
		</Stack>
	);
};

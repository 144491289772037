export const baseRowStyle = {
	pt: 0,
	pb: 2,
	px: 3,
};

export const rulesTitleWrapper = {
	py: 2,
	px: 3,
	borderRadius: 1,
};

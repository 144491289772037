import { useQueryClient } from "@tanstack/react-query";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useTagStore } from "pages/tags/store";
import { CreateTagFormProps } from "pages/tags/types";
import { Modes } from "../CreateTag";
import { useCreateTagAPI } from "./useCreateTagAPI";
import { useEditTagAPI } from "./useEditTagAPI";

export function useUpdateTag({
	tag,
	setShowCreateForm,
	setName,
	name,
	mode,
	cancelCallback,
}: any) {
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const requestAPIRefresh = useTagStore(state => state.requestAPIRefresh);
	const setCurrentTag = useTagStore(state => state.setCurrentTag);
	const createTagAPI = useCreateTagAPI();
	const editTagAPI = useEditTagAPI(tag?.key);

	const queryClient = useQueryClient();

	const handleClick = () => {
		setShowCreateForm(true);
	};

	const handleClose = () => {
		if (mode === Modes.Edit && cancelCallback) {
			cancelCallback();
		}
		setShowCreateForm(false);
		resetFormData();
	};

	const handleUpdate = ({ value }: TextFieldUpdate) => {
		if (value !== name) {
			setName(value);
		}
	};

	const isValidTag = () => {
		let isValid = false;
		const isNameValid = name?.trim().length > 0;

		if (isNameValid) {
			isValid = true;
		}
		return isValid;
	};

	const keyListener = (event: any) => {
		if (event?.key === "Enter" || event?.keyCode === 13) {
			saveTag();
		}
	};

	const saveTag = () => {
		if (mode === Modes.Edit) {
			editTag();
		} else {
			createTag();
		}
	};

	const createTag = () => {
		if (isValidTag()) {
			const body: CreateTagFormProps = {
				displayName: name,
			};
			createTagAPI.mutate(body, {
				onSuccess: response => {
					queryClient.invalidateQueries({
						queryKey: [`${Scope.Asset}Metadata`],
					});
					requestAPIRefresh();
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "CreateTagRequestSubmittedSuccessfully",
					});
					setCurrentTag(response?.internalName);
					resetFormData();
					setTimeout(() => {
						handleClose();
					}, 300);
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		}
	};

	const editTag = () => {
		if (isValidTag()) {
			const body: CreateTagFormProps = {
				displayName: name,
			};
			editTagAPI.mutate(body, {
				onSuccess: response => {
					queryClient.invalidateQueries({
						queryKey: [`${Scope.Asset}Metadata`],
					});
					requestAPIRefresh();
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "UpdateTagRequestSubmittedSuccessfully",
					});
					resetFormData();
					setTimeout(() => {
						handleClose();
					}, 300);
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		}
	};

	const resetFormData = () => {
		setName("");
	};

	return {
		saveTag,
		handleUpdate,
		keyListener,
		handleClick,
		handleClose,
		isValidTag,
	};
}

import { Button, capitalize, Paper, Stack, Typography } from "@mui/material";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { useCore } from "modules/core";
import { Scope, ScopeMetadata } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FirewallHostGroupsDataGrid from "../components/FirewallHostGroupsDataGrid";
import { FIREWALL_HOST_GROUPS_FACETS } from "../constants";
import useGetFirewallHostGroups from "../hooks/useGetFirewallHostGroups";
import {
	useFirewallHostGroupFacetStore,
	useFirewallHostGroupStore,
} from "../store";
import { FirewallHostGroupListItem } from "../types";
import useEditPrecedenceMutation from "./hooks/useEditPrecedenceMutation";

export function FirewallHostGroupsEditPrecedence() {
	const navigate = useNavigate();
	const [orderedRows, setOrderedRows] = useState<FirewallHostGroupListItem[]>(
		[]
	);
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const { mutate } = useEditPrecedenceMutation();

	const coreResponse = useCore<FirewallHostGroupListItem>({
		useStore: useFirewallHostGroupStore,
		facetGroupInfo: FIREWALL_HOST_GROUPS_FACETS,
		scope: Scope.TagPolicy, // TODO - change to Scope.CrowdStrikeFirewallHostGroup when API is updated
		defaultSortOrder: [{ field: "precedence", order: "asc" }],
		useApi: useGetFirewallHostGroups,
		useFacetStore: useFirewallHostGroupFacetStore,
		pageSize: 100,
		skipUseFacetQueryConnector: true,
	});

	const metadata = {
		...(coreResponse.metadata ?? {}),
		scope: Scope.CrowdStrikeFirewallHostGroup,
	} as ScopeMetadata;

	useEffect(() => {
		if (coreResponse?.rows) {
			const rows =
				coreResponse.rows === undefined
					? []
					: coreResponse.rows.map((obj, i) => ({
							...obj,
							precedence: i + 1,
							__reorder__: obj.crowdstrikeFirewallHostgroupName,
						}));

			setOrderedRows(rows);
		}
	}, [coreResponse.rows]);

	const onCancel = () => {
		navigate("/crowdstrike-groups?tab=firewall-host-groups");
	};

	const onSubmit = () => {
		const idsArray = orderedRows.map(row => row.crowdstrikeFirewallHostgroupId);
		const body = {
			crowdstrikeFirewallHostgroupIds: idsArray,
		};
		mutate(body, {
			onSuccess: () => {
				notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
					label: "EditPrecedenceFirewallHostGroupSuccess",
				});
				navigate("/crowdstrike-groups?tab=firewall-host-groups");
			},
			onError: error => {
				// @ts-ignore: Axios error type
				let message = error?.response?.data?.message ?? "SomethingWentWrong";
				const capitalizedMessage = capitalize(message);

				setSnackbar(true, SnackBarSeverity.Error, capitalizedMessage);
			},
		});
	};

	const onRowOrderChange = ({
		oldIndex,
		targetIndex,
	}: GridRowOrderChangeParams) => {
		const updatedRows = [...orderedRows];
		const [movedRow] = updatedRows.splice(oldIndex, 1);
		updatedRows.splice(targetIndex, 0, movedRow);

		const updatedPrecedenceRows = updatedRows.map((row, index) => ({
			...row,
			precedence: index + 1,
		}));

		setOrderedRows(updatedPrecedenceRows);
	};

	return (
		<>
			<Stack direction="row" mb={4} justifyContent="flex-end" gap={2}>
				<Typography variant="h6" mr="auto">
					{window.getCTTranslatedText("Edit Precedence")}
				</Typography>
				<Button variant="outlined" onClick={onCancel}>
					{window.getCTTranslatedText("Cancel")}
				</Button>
				<Button variant="contained" onClick={onSubmit}>
					{window.getCTTranslatedText("Submit")}
				</Button>
			</Stack>

			<Paper component={Stack}>
				<FirewallHostGroupsDataGrid
					rows={orderedRows}
					rowReordering
					onRowOrderChange={onRowOrderChange}
					isLoading={coreResponse.mutation.isLoading}
					metadata={metadata}
				/>
			</Paper>
		</>
	);
}

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";

import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useAssetStore } from "pages/assets/store";
import { useTagPolicyStore } from "pages/tags/components/tag-policy-list/store";
import { useTemplateStore } from "pages/templates/store";
import { Template } from "pages/templates/types";

interface RemoveTemplatesPayload {
	criteria?: string;
	templates: Array<string>;
	comment?: null | string;
}

const useRemoveTemplatesAPI = ({
	policyId,
	page,
}: {
	policyId?: string;
	page: string;
}) => {
	let path = `assets/templates`;
	let apiKey = "asset";
	if (page === "tags" && policyId) {
		path = `tagbasedpolicies/${policyId}/templates`;
		apiKey = "tagbasedpolicy";
	}

	return useMutation<any, Error, any>([apiKey, path, "delete"]);
};

export interface TemplateRemoveProps {
	criteria: string;
	templates: Array<Template>;
	handleAllowRemove: Function;
	allowRemove: boolean;
	page?: string;
	policyId?: string;
	onConfirm: Function;
	comment?: null | string;
}

export function RemoveTemplatesDialog({
	criteria,
	templates,
	handleAllowRemove,
	allowRemove,
	page = "assets",
	policyId,
	onConfirm,
	comment,
}: TemplateRemoveProps) {
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const requestAPIRefresh = useTemplateStore(state => state.requestAPIRefresh);
	const requestAssetAPIRefresh = useAssetStore(
		state => state.requestAPIRefresh
	);
	const requestPoliciesAPIRefresh = useTagPolicyStore(
		state => state.requestAPIRefresh
	);

	const removeTemplatesAPI = useRemoveTemplatesAPI({
		page: page,
		policyId: policyId,
	});

	const [loading, setLoading] = useState(false);

	const queryClient = useQueryClient();

	const updateTemplateDetails = () => {
		if ((criteria || policyId) && templates?.length > 0) {
			setLoading(true);
			let body: RemoveTemplatesPayload = {
				criteria: criteria,
				templates: (templates ?? [])?.map(
					(template: Template) => template.templateId
				),
				comment: comment,
			};

			if (page === "tags") {
				delete body["criteria"];
			}

			removeTemplatesAPI.mutate(body, {
				onSuccess: response => {
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "RemoveTemplatesRequestSubmittedSuccessfully",
					});
					queryClient.invalidateQueries({
						queryKey: ["policy"],
					});
					queryClient.invalidateQueries({
						queryKey: ["asset"],
					});
					queryClient.invalidateQueries({
						queryKey: ["tagbasedpolicy"],
					});
					requestAPIRefresh();
					requestAssetAPIRefresh();
					requestPoliciesAPIRefresh();
					setTimeout(() => {
						handleAllowRemove(false);
						onConfirm();
						setLoading(false);
					}, 500);
				},
				onError: error => {
					setLoading(false);
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		}
	};

	return (
		<Dialog
			open={allowRemove}
			onClose={() => handleAllowRemove(false)}
			aria-labelledby="remove-template"
		>
			<DialogTitle id="remove-template">
				{window.getCTTranslatedText(`Remove Template`)}
			</DialogTitle>
			<DialogContent>
				{window.getCTTranslatedText("removeTemplate", {
					page: window.getCTTranslatedText(
						page === "tags" ? "segment" : "assets"
					),
				})}
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="inherit"
					onClick={() => handleAllowRemove(false)}
				>
					{window.getCTTranslatedText("Cancel")}
				</Button>
				<LoadingButton
					loading={removeTemplatesAPI?.isLoading || loading}
					onClick={updateTemplateDetails}
					variant="contained"
					autoFocus
					color={"error"}
					type="submit"
					disabled={!((criteria || policyId) && templates?.length > 0)}
				>
					{window.getCTTranslatedText("Remove")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}

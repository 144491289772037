import { AbstractParseTreeVisitor } from "antlr4ts/tree/AbstractParseTreeVisitor";
import {
	EqualityExpressionContext,
	InExpressionContext,
} from "parser/ctqlParser";
import { ctqlVisitor } from "parser/ctqlVisitor";

type NodeOperator = "!=" | "=";

export interface NodeValue {
	values: string[];
	operator: NodeOperator;
}

export class CriteriaVisitor
	extends AbstractParseTreeVisitor<Record<string, NodeValue>>
	implements ctqlVisitor<Record<string, NodeValue>>
{
	protected defaultResult(): Record<string, NodeValue> {
		return {};
	}

	// Visit 'in' expressions
	visitInExpression(ctx: InExpressionContext): Record<string, NodeValue> {
		const field = ctx.field().text; // Assuming field() returns the field name
		const values = ctx
			.valueList()
			.value()
			.map(valueCtx => valueCtx.text.replace(/'/g, ""));
		const operator: NodeOperator = ctx.NOT() ? "!=" : "=";
		return {
			[field]: {
				values,
				operator,
			},
		};
	}

	// Visit equality expressions
	visitEqualityExpression(
		ctx: EqualityExpressionContext
	): Record<string, NodeValue> {
		const field = ctx.field().text;
		const value = ctx.value().text.replace(/'/g, ""); // Remove quotes
		const operator: NodeOperator = ctx.NEQ() ? "!=" : "=";
		return { [field]: { values: [value], operator } };
	}

	// Aggregate results from different expressions
	aggregateResult(
		aggregate: Record<string, NodeValue>,
		nextResult: Record<string, NodeValue>
	): Record<string, NodeValue> {
		Object.keys(nextResult).forEach(key => {
			if (aggregate[key]) {
				// Combine values for the same key and remove duplicates
				aggregate[key] = {
					values: [
						...new Set([...aggregate[key].values, ...nextResult[key].values]),
					],
					operator: nextResult[key].operator,
				};
			} else {
				aggregate[key] = nextResult[key];
			}
		});
		return aggregate;
	}
}

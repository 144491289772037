import BadgeIcon from "@mui/icons-material/Badge";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import MonitorIcon from "@mui/icons-material/Monitor";
import NumbersOutlinedIcon from "@mui/icons-material/NumbersOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StoreIcon from "@mui/icons-material/Store";
import { Grid, SvgIconTypeMap, SxProps, Theme } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { CTInputField } from "common/atoms/ct-input-field";
import { Scope } from "modules/scope-metadata/types";
import { CoreTagsKeys } from "pages/assets/types";
import { ReactElement } from "react";
import {
	Controller,
	ControllerRenderProps,
	FieldValues,
	useForm,
} from "react-hook-form";
import BusinessValueSelect from "./BusinessValueSelect";
import EditTagAutocomplete from "./EditTagAutocomplete";
import { FormBuilderElement } from "./types";

interface Props {
	data: FormBuilderElement[];
	scope: Scope;
}

function useMetadataEditForm({ data, scope }: Props) {
	const { control, handleSubmit } = useForm();

	const iconStyle = "rgba(0, 0, 0, 0.38)";
	const IconStyleDarkMode = "rgba(255, 255, 255, 0.38)";

	const createIcon = (
		IconComponent: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
			muiName: string;
		},
		sx: SxProps<Theme> = {}
	) => (
		<IconComponent
			width="24"
			height="24"
			sx={{
				color: theme =>
					theme.palette.mode === "dark" ? IconStyleDarkMode : iconStyle,
				...sx,
			}}
		/>
	);

	const iconMap: Record<string, JSX.Element> = {
		[CoreTagsKeys.Application]: createIcon(MonitorIcon),
		[CoreTagsKeys.Location]: createIcon(LocationOnIcon),
		[CoreTagsKeys.Role]: createIcon(BadgeIcon),
		[CoreTagsKeys.Owner]: createIcon(PersonOutlineIcon),
		[CoreTagsKeys.Environment]: createIcon(CloudQueueIcon),
		[CoreTagsKeys.SerialNumber]: createIcon(NumbersOutlinedIcon),
		[CoreTagsKeys.KernelVersion]: createIcon(MemoryOutlinedIcon),
		[CoreTagsKeys.PrimarySubnet]: createIcon(LanguageOutlinedIcon),
	};

	const DEFAULT_ICON = createIcon(LocalOfferOutlinedIcon);

	const getTagIcon = (coreTagKey: string) => {
		return iconMap[coreTagKey] || DEFAULT_ICON;
	};

	const textFormElementDisplayProps: Record<string, any> = {
		assetName: {
			placeholder: "Enter asset name",
		},
		deviceName: {
			placeholder: "Enter asset name",
		},
		vendorInfo: {
			placeholder: "Enter vendor info",
		},
	};

	const generateFormElements = () => {
		const createFormElementWithController = ({
			key,
			label,
			type,
			value,
		}: FormBuilderElement) => {
			const formElementRenderMap: Record<
				string,
				({
					field,
				}: {
					field: ControllerRenderProps<FieldValues, string>;
				}) => ReactElement
			> = {
				text: ({
					field: { onChange: handleUpdate, name: field, ref, ...rest },
				}) => {
					const startIcon =
						field === "vendorInfo" ? (
							createIcon(StoreIcon, { mr: "0.5rem" })
						) : (
							<></>
						);

					return (
						<CTInputField
							field={field}
							handleUpdate={handleUpdate}
							type="text"
							{...textFormElementDisplayProps[field]}
							displayName={label}
							startIcon={startIcon}
							sx={{
								"& input::placeholder": {
									fontSize: "inherit",
								},
							}}
							{...rest}
						/>
					);
				},
				tag: ({ field: { ref, name, ...rest } }) => {
					const nameArr = name.split(".");
					const key = nameArr[nameArr.length - 1];

					return (
						<EditTagAutocomplete
							tagKey={key}
							label={window.getCTTranslatedText(label)}
							icon={getTagIcon(key)}
							scope={scope}
							{...rest}
						/>
					);
				},
				businessValue: ({ field: { ref, name, ...rest } }) => {
					return <BusinessValueSelect {...rest} />;
				},
			};

			const render = formElementRenderMap[type];

			return (
				<Controller
					defaultValue={value}
					control={control}
					name={key}
					render={render}
				/>
			);
		};

		return data.map(obj => (
			<Grid item xs={obj.columns} key={obj.key}>
				{createFormElementWithController(obj)}
			</Grid>
		));
	};

	const formElements = generateFormElements();

	return { formElements, handleSubmit };
}

export default useMetadataEditForm;

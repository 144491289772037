import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { CustomCommonStoreType } from "common/types/types";
import { PortAnnotations } from "pages/ports/components/port-annotations";
import {
	BASE_PORT_COLUMNS,
	ColumnType,
} from "pages/ports/components/port-data-grid/constants";
import { PortStatusView } from "pages/ports/components/port-status-view";

interface PortReviewColumnsProps {
	useFacetStore?: CustomCommonStoreType;
}

export const getPortReviewColumns = ({
	useFacetStore,
}: PortReviewColumnsProps) => {
	const ASSET_DETAILS_PORT_COLUMNS: GridColDef[] = [
		...BASE_PORT_COLUMNS(ColumnType.DRAWER, useFacetStore),
		{
			field: "listenportreviewed",
			headerName: "Port Status",
			width: 160,
			minWidth: 140,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<PortStatusView port={params.row} useFacetStore={useFacetStore} />
				);
			},
		},
		{
			field: "comment",
			headerName: "",
			headerAlign: "left",
			align: "left",
			width: 50,
			minWidth: 50,
			sortingOrder: ["desc", "asc"],
			sortable: false,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PortAnnotations port={params.row} />;
			},
		},
	];

	return ASSET_DETAILS_PORT_COLUMNS;
};

export const PATHS_REVIEW_DATA_GRID_ID = "paths-review-data-grid";

import { create } from "zustand";
import {
	CheckboxSelectionInterface,
	PartialIPData,
	CheckboxSelectionType,
	SubnetOrAllObservedIPsData,
} from "./types";

const mapSelectedIPsArrayToMap = (selectedIPs?: string[]) => {
	return selectedIPs?.reduce((acc, ip) => ({ ...acc, [ip]: true }), {});
};

const createCheckboxSelectionStore = (
	set: (
		fn: (
			state: CheckboxSelectionInterface
		) => Partial<CheckboxSelectionInterface>
	) => void
): CheckboxSelectionInterface => ({
	selection: {},
	setSubnetOrAllObservedIPs: ({
		rowID,
		selectionType,
		isChecked,
		selectedIPs,
	}: SubnetOrAllObservedIPsData) => {
		set(state => {
			const existingSelection = state.selection[rowID];

			const shouldUnselectPartialIPs =
				existingSelection?.selectionType ===
					CheckboxSelectionType.PARTIAL_IPS &&
				selectionType !== CheckboxSelectionType.SUBNET;

			const shouldUnselectAllIPs =
				existingSelection?.selectionType === selectionType;

			if (
				isChecked &&
				Boolean(existingSelection) &&
				(shouldUnselectPartialIPs || shouldUnselectAllIPs)
			) {
				const clonedSelection = { ...state.selection };
				delete clonedSelection[rowID];

				return {
					selection: clonedSelection,
				};
			}

			const selection = {
				subnet: rowID,
				selectionType,
				...(selectionType === CheckboxSelectionType.ALL_IPS
					? {
							selectedIPs: mapSelectedIPsArrayToMap(selectedIPs),
						}
					: {}),
			};

			return {
				selection: {
					...state.selection,
					[rowID]: selection,
				},
			};
		});
	},
	setPartialIPs: ({
		rowID,
		selectionType,
		isChecked,
		selectedIP,
		availableIPs,
	}: PartialIPData) => {
		set(state => {
			const existingSelection = state.selection[rowID];

			const clonedSelection = { ...state.selection };
			const currentlySelectedIPCount = Object.keys(
				existingSelection?.selectedIPs ?? {}
			).length;

			// Unselect
			if (isChecked) {
				// Unselecting 1 IP when all IPs are selected
				if (
					existingSelection?.selectionType === CheckboxSelectionType.ALL_IPS
				) {
					const selectedIPs = { ...(existingSelection?.selectedIPs ?? {}) };
					delete selectedIPs[selectedIP];

					return {
						selection: {
							...state.selection,
							[rowID]: {
								subnet: rowID,
								selectedIPs,
								selectionType,
							},
						},
					};
				}

				clonedSelection[rowID] = existingSelection
					? {
							...existingSelection,
							selectedIPs: {
								...(existingSelection?.selectedIPs ?? {}),
							},
						}
					: undefined;
				delete clonedSelection[rowID]?.selectedIPs?.[selectedIP];

				// if all ips are unselected, delete the selection from store
				if (currentlySelectedIPCount === 1) {
					delete clonedSelection[rowID];
				}

				return {
					selection: clonedSelection,
				};
			}

			// Select ALL_IPS
			if (
				currentlySelectedIPCount > 0 &&
				currentlySelectedIPCount === availableIPs.length - 1
			) {
				return {
					selection: {
						...state.selection,
						[rowID]: {
							subnet: rowID,
							selectionType: CheckboxSelectionType.ALL_IPS,
							selectedIPs: mapSelectedIPsArrayToMap(availableIPs),
						},
					},
				};
			}

			return {
				selection: {
					...state.selection,
					[rowID]: {
						selectionType,
						subnet: rowID,
						selectedIPs: {
							...(state.selection[rowID]?.selectedIPs ?? {}),
							[selectedIP]: true,
						},
					},
				},
			};
		});
	},
	resetSelection: () => set(() => ({ selection: {} })),
});

export const usePublicNNCheckboxSelectionStore =
	create<CheckboxSelectionInterface>(createCheckboxSelectionStore);

export const usePrivateNNCheckboxSelectionStore =
	create<CheckboxSelectionInterface>(createCheckboxSelectionStore);

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { CloneDataGridRowAction } from "modules/clone-datagrid-row";
import { NetworkDelete } from "pages/networks/components/network-delete";
import { useNetworkStore } from "pages/networks/store";
import { NetworkColumnRenderProps } from "pages/networks/types";
import { useState } from "react";

export function useNamedNetworkCloneAPI() {
	return useMutation<any, Error, any>(["ports", "namednetworks/clone"]);
}

export function ActionNetworkDialog({ network }: NetworkColumnRenderProps) {
	const [allowDelete, setAllowDelete] = useState(false);
	const apiRefreshPath: VoidFunction = useNetworkStore(
		state => state.requestAPIRefresh
	);

	const handleAllowDelete = (event: any, value: boolean) => {
		if (event) {
			event.stopPropagation();
		}

		setAllowDelete(value);
	};

	if (!network.namedNetworkId) {
		return <Box style={{ width: 100 }} />;
	}

	const generateBody = () => {
		return {
			namedNetworkName: `${
				network.namedNetworkName
			} ${window.getCTTranslatedText("(copy)")}`,
			namedNetworkDescription: network.namedNetworkDescription
				? `${network.namedNetworkDescription} ${window.getCTTranslatedText(
						"(copy)"
					)}`
				: "",
			srcNamedNetworkId: network.namedNetworkId,
		};
	};

	const isDisabled =
		network?.isOOBNetwork ||
		Boolean(network.namednetworkTagBasedPolicyAssignments) ||
		Boolean(network?.programAsIntranet || network?.programAsInternet || false);

	let tooltipText = "Delete named network";

	if (Boolean(network.namednetworkTagBasedPolicyAssignments)) {
		tooltipText = "Named network cannot be deleted when it is being used";
	}

	if (network?.isOOBNetwork) {
		tooltipText = "System named network cannot be deleted";
	} else if (network?.programAsIntranet || network?.programAsInternet) {
		tooltipText = "programmedNamedNetworkDeleteBlock";
	}

	return (
		<Box>
			<Stack direction="row" spacing={2}>
				<Tooltip title={window.getCTTranslatedText(tooltipText)}>
					<Stack>
						<IconButton
							aria-label="Delete"
							onClick={$event => handleAllowDelete($event, true)}
							sx={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
							disabled={isDisabled}
						>
							<DeleteOutlineIcon color={isDisabled ? "disabled" : "action"} />
						</IconButton>
					</Stack>
				</Tooltip>

				<CloneDataGridRowAction
					generateBody={generateBody}
					title="Clone Named Network"
					refreshAPI={apiRefreshPath}
					useAPI={useNamedNetworkCloneAPI}
				/>
			</Stack>
			{allowDelete && (
				<NetworkDelete
					network={network}
					allowDelete={allowDelete}
					handleAllowDelete={handleAllowDelete}
				></NetworkDelete>
			)}
		</Box>
	);
}

import { SearchOutlined } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import {
	CircularProgress,
	IconButton,
	InputAdornment,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { InputBaseCompact } from "common/atoms/input-base";
import { useCommonStore } from "common/store";
import { CustomCommonStoreType } from "common/types/types";
import { getCurrentTenantID } from "hooks/useComplianceController/helper";
import debounce from "lodash/debounce";
import { useCriteriaBuilder } from "modules/core/Core";
import { Facet, ServerFacet } from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { useSearchStore } from "modules/search/store";
import { useUserDepartmentAPI } from "pages/users-segmentation/components/user-departments/components/userDepartments-data-grid/hooks";
import { useUserGroupsAPI } from "pages/users-segmentation/components/user-groups/hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { SearchContainerCompact } from "./SearchContainerCompact";
import { FacetName } from "./constants";

export interface FacetSearchProps {
	facet: Facet;
	initialLoadAllowed: boolean;
	useFacetStore?: CustomCommonStoreType;
	isSelection?: boolean;
	setIsGroupLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FacetSearchParams {
	criteria: string;
	facetField: string;
	facetFieldFilter: string;
	scope: Scope;
}

interface FacetResponse {
	Facet: ServerFacet;
}

export function useFacetSearch(fieldName: string) {
	return useMutation<FacetResponse, Error, FacetSearchParams>([
		`facetSearch_${fieldName}`,
		"fields/actions/summarize",
	]);
}

const disableMetricsForTenants: Record<string, boolean> = {
	"": true,
	"ae967b9b-d01f-4b23-bcb9-c60ed9898945": true,
	"8cabac6f-0b72-4959-9e31-b0764c1c8bd6": true,
};

export function FacetSearch({
	facet,
	initialLoadAllowed,
	useFacetStore = useCommonStore,
	isSelection,
	setIsGroupLoading,
}: FacetSearchProps) {
	const theme = useTheme();
	const isReady = useRef(initialLoadAllowed);

	const [inputValue, setInputValue] = useState("");

	const updateServerFacetOptions = useFacetStore(state => {
		return state.updateServerFacetOptions;
	});

	const externalCriteria = useFacetStore(state => state.externalCriteria);
	const facetState = useFacetStore(state => state.facets);

	const facetStateWithoutSelection = useMemo(() => {
		const state = new Map(facetState);

		state.delete(facet.name);

		return state;
	}, [facetState, facet.name]);

	const useQualifierScope = useFacetStore(state => state.useQualifierScope);
	const facetScope = useFacetStore(state => state.scope);
	const scope = useQualifierScope ? facet.qualifier : facetScope;

	const { data: metadata } = useScopeMetadata({ scope });

	const searchQuery = useSearchStore(state => state.search);

	const searchCriteria = useCriteriaBuilder(
		searchQuery,
		facetStateWithoutSelection,
		metadata,
		externalCriteria
	);

	const criteriaRef = useRef(searchCriteria);
	criteriaRef.current = searchCriteria;

	const mutationFacetSearch = useFacetSearch(facet.name);
	const mutationDepartment = useUserDepartmentAPI();
	const mutationGroup = useUserGroupsAPI();
	const mutation = useMemo(() => {
		if (!isSelection) {
			return mutationFacetSearch;
		}

		if (facet.name === FacetName.Department) {
			return mutationDepartment;
		} else if (facet.name === FacetName.UserGroup) {
			return mutationGroup;
		} else {
			return mutationFacetSearch;
		}
	}, [
		facet.name,
		mutationDepartment,
		mutationGroup,
		mutationFacetSearch,
		isSelection,
	]);

	const mutate = mutation.mutateAsync;

	const searchResponse = useMemo(() => {
		const currentTenant = getCurrentTenantID();
		if (!isSelection) {
			if (
				currentTenant &&
				scope === Scope.Path &&
				disableMetricsForTenants[currentTenant]
			) {
				return removeCounts(mutation.data);
			}
			return mutation.data;
		}

		function transformData(data: any) {
			if (!data) {
				return undefined;
			}
			const values = data?.items?.reduce((result: any, item: any) => {
				result[item?.department ?? item?.groupName] = item.users;
				return result;
			}, {});

			return {
				Facet: {
					field: facet.name,
					hasMore: false,
					values,
				},
			};
		}

		function removeCounts(data: any) {
			if (!data || !data.Facet) {
				return undefined;
			}

			if (data.Facet.values === null) {
				return data;
			}

			Object.keys(data.Facet.values ?? {}).forEach(key => {
				data.Facet.values[key] = -1;
			});
			return data;
		}

		if (
			facet.name === FacetName.Department ||
			facet.name === FacetName.UserGroup
		) {
			return transformData(mutation.data);
		} else {
			return mutation.data;
		}
	}, [facet.name, mutation.data, isSelection, scope]);

	const [hasMore, setHasMore] = useState<boolean | undefined>(undefined);

	useEffect(() => {
		if (searchResponse) {
			setHasMore(prevValue => {
				if (prevValue !== undefined) {
					return prevValue;
				}
				if (facet?.qualifier === Scope.PathStat) {
					return false;
				}
				return searchResponse?.Facet?.hasMore;
			});
		}
	}, [searchResponse, facet?.qualifier]);

	useEffect(() => {
		if (searchResponse) {
			updateServerFacetOptions(searchResponse.Facet, false);
		}
	}, [searchResponse, updateServerFacetOptions, inputValue]);

	const updateSearch = useMemo(() => {
		return debounce((inputValue: string) => {
			if (!isReady.current || !scope || !criteriaRef.current) {
				return;
			}

			mutate({
				criteria: isSelection ? "*" : criteriaRef.current!,
				facetField: facet.name,
				facetFieldFilter: inputValue,
				scope,
			});
		}, 300);
	}, [scope, mutate, isSelection, facet.name]);

	const updateSearchRef = useRef(updateSearch);

	// Load first time
	useEffect(() => {
		updateSearchRef.current("");
	}, []);

	// on change of input, reload facets
	useEffect(() => {
		if (inputValue) {
			isReady.current = true;
		}
		updateSearch(inputValue);
	}, [inputValue, updateSearch]);

	useEffect(() => {
		setIsGroupLoading(!hasMore && mutation.isLoading);
	}, [hasMore, mutation.isLoading, setIsGroupLoading]);

	const noContent = (
		<Stack alignItems={"center"} py={2}>
			<Typography color="primary.main" variant="caption">
				{window.getCTTranslatedText("No results found")}
			</Typography>
		</Stack>
	);

	if (!hasMore && mutation.isLoading) {
		return <></>;
	}

	if (!hasMore) {
		if (!facet.options?.length && hasMore === false) {
			return noContent;
		}
		return null;
	}

	return (
		<>
			<Stack py={1} pl={1.5} pr={0.5}>
				<SearchContainerCompact>
					<InputBaseCompact
						size="small"
						onChange={e => {
							e.stopPropagation();
							setInputValue(e.target.value);
						}}
						onKeyDown={e => e.stopPropagation()}
						value={inputValue}
						placeholder={`Search`}
						inputProps={{
							"aria-label": "search",
						}}
						startAdornment={
							<InputAdornment position="end">
								<SearchOutlined
									sx={{
										fontSize: 20,
										color: theme.palette.grey[600],
										pointerEvents: "none",
									}}
								/>
							</InputAdornment>
						}
						endAdornment={
							mutation.isLoading ? (
								<Stack pr={2}>
									<CircularProgress size={18} />
								</Stack>
							) : (
								<InputAdornment
									position="end"
									sx={{ paddingRight: "6px !important" }}
								>
									<IconButton
										size="small"
										color="inherit"
										aria-label="clear search"
										onClick={() => {
											setInputValue("");
										}}
										onMouseDown={() => {}}
										edge="end"
									>
										{inputValue?.length > 0 && (
											<ClearIcon fontSize="small" color="inherit" />
										)}
									</IconButton>
								</InputAdornment>
							)
						}
					/>
				</SearchContainerCompact>
			</Stack>
			{mutation.isSuccess &&
				searchResponse &&
				Object.keys(searchResponse.Facet?.values || {}).length === 0 &&
				noContent}

			{mutation.isError && (
				<Stack alignItems={"center"}>
					<Typography color="error" variant="caption">
						Something went wrong
					</Typography>
				</Stack>
			)}
		</>
	);
}

import { useMutation } from "@tanstack/react-query";
import { Container } from "pages/containers/types";

interface Metadata {
	facets: null;
	total: number;
}
export interface ContainerResponse {
	items: Container[];
	metadata: Metadata;
}

export function useContainersAPI() {
	return useMutation<ContainerResponse, Error, any>([
		"clusters-list",
		"collectors/actions/search?computeTotal=true",
	]);
}

import { Box, Stack } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { useCommonStore } from "common/store";
import { PathColumnRenderProps } from "pages/paths/types";

export function PathDetails({
	path,
	viewOnly,
	useFacetStore = useCommonStore,
}: PathColumnRenderProps) {
	if (!path.channelHash) {
		return <Box />;
	}

	return (
		<Box>
			<Stack direction="row" alignItems="center" justifyContent="flex-start">
				{path?.protocol && (
					<CTDatagridButton
						isDisabled={viewOnly || !path.protocol}
						asText={viewOnly}
						useFacetStore={useFacetStore}
						facetName={"protocol"}
						facetOption={path?.protocol ?? ""}
					>
						{path?.protocol}
					</CTDatagridButton>
				)}

				<CTDatagridButton
					isDisabled={viewOnly || !path.port}
					asText={viewOnly}
					useFacetStore={useFacetStore}
					facetName={"port"}
					facetOption={path.port.toString() ?? ""}
				>
					{path?.port}
				</CTDatagridButton>
			</Stack>
		</Box>
	);
}

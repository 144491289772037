import { Box } from "@mui/material";
import { AnnotationDrawer } from "modules/annotation-drawer";
import { EntityType } from "modules/annotation-drawer/type";
import { AnnotationCount } from "modules/annotation/components/annotation-count";
import { usePathStore } from "pages/paths/store";
import { PathColumnRenderProps } from "pages/paths/types";
import { useState } from "react";

export function PathAnnotations({ path }: PathColumnRenderProps) {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const handleDrawerOpen = (event: any) => {
		if (event) {
			event.stopPropagation();
		}
		setIsDrawerOpen(true);
	};

	if (!path.channelHash) {
		return <Box style={{ width: 70 }} />;
	}

	return (
		<>
			<AnnotationCount
				count={path.totalComments}
				clickCallback={handleDrawerOpen}
			/>
			{isDrawerOpen && (
				<AnnotationDrawer
					isOpen={isDrawerOpen}
					onClose={() => setIsDrawerOpen(false)}
					entity={EntityType.Paths}
					entityId={path.channelHash}
					useStore={usePathStore}
				/>
			)}
		</>
	);
}

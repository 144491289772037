import { Stack } from "@mui/material";
import { AssetColumnRenderProps } from "pages/assets/types";
import { AssetAnnotations } from "../asset-annotations";
import { AssetSyncStatus } from "../asset-sync-status";
import { AssetPathLimitReachedIcon } from "../asset-path-limit-reached";

export function AssetMetadataIcons({
	asset,
	viewOnly,
}: AssetColumnRenderProps) {
	if (!asset?.assetId) {
		return null;
	}

	return (
		<Stack
			direction="row"
			justifyContent="flex-end"
			alignItems="center"
			spacing={1}
			px={1}
			sx={{ width: "100%" }}
		>
			<AssetPathLimitReachedIcon asset={asset} />
			<AssetSyncStatus
				asset={asset}
				viewOnly={viewOnly}
				width="18px"
				height="18px"
			/>
			<AssetAnnotations viewOnly={viewOnly} asset={asset} />
		</Stack>
	);
}

import StarIcon from "@mui/icons-material/Star";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import {
	Box,
	DialogActions,
	FormControl,
	IconButton,
	Popover,
	Stack,
	Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { useCommonStore } from "common/store";
import { CustomCommonStoreType } from "common/types/types";
import { parseErrorMessage } from "common/utils";
import { useCriteriaBuilder } from "modules/core/Core";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { decodeThrowable } from "modules/facets/hooks/useFacetQueryConnector";
import {
	RequiredSaveQueryFields,
	SaveQueryFieldDisplayText,
	SaveQueryFieldOptions,
} from "modules/saved-query/constants";
import {
	useSaveQueryAPI,
	useUpdateSavedQueryAPI,
} from "modules/saved-query/hooks/useSaveQueryAPI";
import { useSavedQueryStore } from "modules/saved-query/store";
import {
	SaveQueryFieldKeys,
	SaveQueryFormProps,
	SavedQuery,
} from "modules/saved-query/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import React, { useEffect, useState } from "react";

export const SaveQuery = ({
	query,
	useFacetStore = useCommonStore,
}: {
	query?: SavedQuery;
	useFacetStore?: CustomCommonStoreType;
}) => {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const setSavedQuery = useSavedQueryStore(state => state.setSavedQuery);
	const savedQuery = useSavedQueryStore(state => state.savedQuery);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const [name, setName] = useState(savedQuery?.name ?? "");
	const [description, setDescription] = useState(savedQuery?.description ?? "");
	const currentSearchCriteria = useCommonStore(
		state => state.currentSearchCriteria
	);
	const saveQueryAPI = useSaveQueryAPI();
	const updateSavedQueryAPI = useUpdateSavedQueryAPI(savedQuery?.id);
	const queryClient = useQueryClient();
	const queryString = window.location.search ?? "";

	const urlSearchParams = new URLSearchParams(queryString);

	const facetString = urlSearchParams.get("filters");

	const searchString = urlSearchParams.get("search") ?? "";
	const facetState = decodeThrowable(facetString ?? "");

	const metadata = useFacetStore(state => state.metadata);
	const searchCriteria = useCriteriaBuilder(searchString, facetState, metadata);

	useEffect(() => {
		if (savedQuery?.id) {
			setName(savedQuery.name);
			setDescription(savedQuery.description);
		}
	}, [savedQuery]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		resetFormData();
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleUpdate = ({ field, value }: TextFieldUpdate) => {
		switch (field) {
			case SaveQueryFieldKeys.Name:
				if (value !== name) {
					setName(value);
				}
				break;
			case SaveQueryFieldKeys.Description:
				if (value !== description) {
					setDescription(value);
				}
				break;
		}
	};

	const saveQuery = () => {
		if (isValidQuery()) {
			const body: SaveQueryFormProps = {
				name: name,
				description: description,
				criteria: currentSearchCriteria ?? searchCriteria ?? "*",
				criteriaAsParams: queryString,
			};

			if (savedQuery) {
				editQuery(body);
			} else {
				createQuery(body);
			}
		}
	};

	const isValidQuery = () => {
		let isValid = false;
		const isNameValid = name?.trim().length > 0;

		if (isNameValid) {
			isValid = true;
		} else {
			isValid = false;
		}
		return isValid;
	};

	const keyListener = (event: any) => {
		if (event?.key === "Enter" || event?.keyCode === 13) {
			saveQuery();
		}
	};

	const createQuery = (body: SaveQueryFormProps) => {
		saveQueryAPI.mutate(body, {
			onSuccess: response => {
				queryClient.invalidateQueries({
					queryKey: ["savedsearch"],
				});
				setSavedQuery(response);
				setSnackbar(true, SnackBarSeverity.Success, "QuerySavedSuccessfully");
				resetFormData();
				setTimeout(() => {
					handleClose();
				}, 300);
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const editQuery = (body: SaveQueryFormProps) => {
		updateSavedQueryAPI.mutate(body, {
			onSuccess: response => {
				queryClient.invalidateQueries({
					queryKey: ["savedsearch"],
				});
				setSavedQuery(response);
				setSnackbar(true, SnackBarSeverity.Success, "QueryUpdatedSuccessfully");
				resetFormData();
				setTimeout(() => {
					handleClose();
				}, 300);
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const resetFormData = () => {
		if (!savedQuery?.id) {
			setName("");
			setDescription("");
		}
	};

	return (
		<Stack alignItems="flex-start">
			<IconButton
				size="small"
				color={"inherit"}
				onClick={handleClick}
				disabled={!Boolean(searchCriteria) || searchCriteria === "*"}
			>
				{savedQuery ? (
					<StarIcon color="primary" />
				) : (
					<StarOutlineOutlinedIcon color="inherit" />
				)}
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<Box sx={{ p: 3, pb: 1, minWidth: "300px" }}>
					<Typography variant="h6">Save Query</Typography>
					<Box sx={{ width: "100%", my: 2 }}>
						<Stack
							alignItems="flex-start"
							justifyContent="flex-start"
							spacing={4}
							sx={{
								width: "100%",
							}}
						>
							<FormControl sx={{ m: 0, minWidth: "100%" }} size="small">
								{SaveQueryFieldOptions.map(
									(key: SaveQueryFieldKeys, index: number) => {
										return (
											<Box key={`${key}`}>
												<CTInputField
													field={key}
													displayName={SaveQueryFieldDisplayText[key]}
													value={
														key === SaveQueryFieldKeys.Name ? name : description
													}
													handleUpdate={handleUpdate}
													required={RequiredSaveQueryFields[key]}
													autoFocus={index === 0}
													onKeyUp={keyListener}
												/>
											</Box>
										);
									}
								)}
							</FormControl>
						</Stack>
					</Box>
				</Box>
				<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
					<ToolbarAction
						loading={false}
						save={saveQuery}
						cancel={handleClose}
						actionBtnText={"Save"}
						background={false}
						size={"small"}
						isValid={isValidQuery()}
					/>
				</DialogActions>
			</Popover>
		</Stack>
	);
};

import { Box, Stack, Typography } from "@mui/material";
import { TagRule } from "../../types";
import { CopyButton } from "common/atoms/copy-button";

export function RuleName({ rule }: { rule: TagRule }) {
	if (!rule.ruleId || !rule?.ruleName) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	return (
		<Stack
			direction={"row"}
			justifyContent={"space-between"}
			alignItems={"center"}
			sx={{
				width: "100%",
				textOverflow: "ellipsis",
			}}
		>
			<Box sx={{ textOverflow: "ellipsis" }}>
				<Typography variant="body2">{rule?.ruleName}</Typography>
				<Typography
					variant="caption"
					sx={{ color: theme => theme.palette.text.secondary }}
				>
					{rule?.ruleDescription}
				</Typography>
			</Box>
			<CopyButton text={rule?.ruleName ?? ""} />
		</Stack>
	);
}

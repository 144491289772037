interface LabelWithName {
  name: string;
  label: string;
}
export interface Datum extends LabelWithName {
  count: number;
  groupCount?: number;
}

export interface ChartDatum {
  datum: Datum;
  id: string;
  parent: string;
}

export enum ClickSource {
  LEGEND = "legend",
  CHART = "chart",
}

export interface TreeMapProps {
  data: Array<ChartDatum>;
  selected: Array<LabelWithName> | undefined;
  width: number;
  height: number;
  getColor: (d: ChartDatum) => string;
  onClick: (nodeName: string | undefined, source: ClickSource) => void;
}

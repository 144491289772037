import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";

import { PortColumnRenderProps } from "pages/ports/types";
import { Sparklines, SparklinesLine } from "react-sparklines";

export function PortDataVolume({ port }: PortColumnRenderProps) {
	const theme = useTheme();

	if (!port.lpId) {
		return <Box style={{ width: 80, minHeight: 45 }} />;
	}

	let number, unit;
	if ((port.bandwidthInBytes as string).split != null) {
		[number, unit] = (port.bandwidthInBytes as string).split(" ");
	}

	return (
		<Stack
			sx={{ width: "100%" }}
			direction={"column"}
			alignItems="flex-start"
			justifyContent="center"
		>
			<Tooltip
				title={window.getCTTranslatedText("Last 30 days")}
				arrow={false}
				placement="bottom-start"
			>
				<Stack sx={{ width: "100%" }}>
					<Typography variant="body2">
						{number}{" "}
						<Typography variant="caption">
							{window.getCTTranslatedText(unit ?? "")}
						</Typography>
					</Typography>
					<Sparklines
						data={port.bandwidthInBytesSeries}
						limit={50}
						width={100}
						height={20}
						margin={5}
						svgHeight={20}
					>
						<SparklinesLine
							color={theme.palette.info.main}
							style={{ fill: "none" }}
						/>
					</Sparklines>
				</Stack>
			</Tooltip>
		</Stack>
	);
}

import {
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Stack,
} from "@mui/material";
import { DisplayTextMap } from "common/constants/displayTextMap";
import { OptInOptions } from "../types";

interface DevicesToProtectProps {
	optIn: string;
	setOptIn: (value: string) => void;
	isFormDisabled: boolean;
}

export function DevicesToProtect({
	optIn,
	setOptIn,
	isFormDisabled,
}: Readonly<DevicesToProtectProps>) {
	return (
		<FormControl>
			<Stack spacing={2}>
				<Grid
					container
					columnGap={4}
					rowGap={2}
					sx={{ pl: 0 }}
					display="grid"
					gridTemplateColumns="1fr 1fr"
				>
					<Stack justifyContent="center" height={52}>
						<RadioGroup
							row
							aria-labelledby="select-source-destination-label"
							name="path-direction-radio-buttons-group"
							defaultValue={OptInOptions.No}
							value={optIn}
							onChange={(e, value) => setOptIn(value)}
						>
							<FormControlLabel
								value={OptInOptions.No}
								control={<Radio size="small" />}
								label={window.getCTTranslatedText(
									DisplayTextMap[OptInOptions.No]
								)}
								disabled={isFormDisabled}
							/>
							<FormControlLabel
								value={OptInOptions.Yes}
								control={<Radio size="small" />}
								label={window.getCTTranslatedText(
									DisplayTextMap[OptInOptions.Yes]
								)}
								disabled={isFormDisabled}
							/>
						</RadioGroup>
					</Stack>
				</Grid>
			</Stack>
		</FormControl>
	);
}

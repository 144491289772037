import { useMutation } from "@tanstack/react-query";
import { AssignNetworkProps } from "../types";

export function useAssignNetwork({
	namedNetworkid,
	assetId,
}: AssignNetworkProps) {
	const path = `assets/${assetId}/namednetworks/${namedNetworkid}`;
	return useMutation<any, Error, any>(["asset", path, "put"]);
}

export function useAssignNetworkToAssets() {
	const path = `assets/namednetworks`;
	return useMutation<any, Error, any>(["asset", path, "put"]);
}

export function useAssignNetworkToTags({
	policyId,
}: {
	policyId: string | undefined;
}) {
	const path = `tagbasedpolicies/${policyId}/namednetworks`;
	return useMutation<any, Error, any>(["tagbasedpolicy", path, "put"]);
}

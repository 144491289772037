import CloseIcon from "@mui/icons-material/Close";
import { Alert, Link, Stack, Typography } from "@mui/material";
import { useKnowledgeBaseUrl } from "common/hooks/useKnowledgeBaseUrl";
import { Link as RouterLink } from "react-router-dom";

const SUPPORT_URL = "https://docs.ng.colortokens.com/en/article/support";

interface Props {
	setShowAssetBanner: (value: boolean) => void;
	text: string;
	fromDashboard?: boolean;
}

function AssetBanner({
	setShowAssetBanner,
	text,
	fromDashboard = false,
}: Props) {
	const { getUpdatedUrl } = useKnowledgeBaseUrl([SUPPORT_URL]);
	return (
		<Alert
			severity={"error"}
			sx={{ mb: 2, width: "100%", display: "flex" }}
			action={
				<Stack flexDirection="row">
					<Link
						typography={"button"}
						underline="hover"
						color="inherit"
						component={"a"}
						noWrap
						href={getUpdatedUrl(SUPPORT_URL ?? "")}
						rel="noreferrer"
						target="_blank"
					>
						{window.getCTTranslatedText("GoToSupport")}
					</Link>
					{fromDashboard && (
						<Link
							typography={"button"}
							underline="hover"
							color="inherit"
							component={RouterLink}
							noWrap
							to="/assets"
							ml={3}
						>
							{window.getCTTranslatedText("ViewAssets")}
						</Link>
					)}
					<CloseIcon
						sx={{ cursor: "pointer", ml: 5 }}
						onClick={() => setShowAssetBanner(false)}
					/>
				</Stack>
			}
		>
			<Stack
				display="flex"
				justifyContent="space-between"
				flexDirection="row"
				sx={{ width: "100%" }}
			>
				<Typography variant="body2">{text}</Typography>
			</Stack>
		</Alert>
	);
}

export default AssetBanner;

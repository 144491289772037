import {
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { AgentLogs } from "modules/agent-logs";
import { DebugLogStatus } from "../agent-debug-log-status";
import { AgentDiagnostic } from "../agent-diagnostic";
import { AGENT_DRAWER_BASE_COLUMNS } from "../agent-drawers/helpers/constants";
import { NorthSouthTrafficCollectionStatus } from "../agent-nstrafficcollection-status";
import { AgentPolicyTamperStatus } from "../agent-policy-tamper-status";

export enum AgentActionStatusInfo {
	Pending = "pending",
	Synchronized = "synchronized",
}

export enum AgentDebugLogStates {
	Enabled = "Enabled",
	Disabled = "Disabled",
}

export enum AgentPolicyTamperingStates {
	Enabled = "enabled",
	Disabled = "disabled",
}

export enum AgentNorthSouthCollectionStates {
	Enabled = "Enabled",
	Disabled = "Disabled",
}

export const AgentDiagnosticSupportedMinVersion = "5.8.2";
export const AgentDiagnosticSupportedDevVersion = "1.0.1";

export const AgentDecommissionSupportedMinVersion = "5.9.4";
export const AgentDecommissionSupportedDevVersion = "1.0.1";

export const AgentDebugLogSupportedMinVersion = "5.9.6";
export const AgentDebugLogSupportedDevVersion = "1.0.1";

export const AgentTrafficConfigSupportedMinVersion = "5.9.6";
export const AgentTrafficConfigSupportedDevVersion = "1.0.1";

export const AgentNsTrafficConfigSupportedMinVersion = "24.6.0";
export const AgentNsTrafficConfigSupportedDevVersion = "1.0.1";

export const AgentPolicyTamperMonitoringSuppportedMinVersion = "24.8.0";
export const AgentPolicyTamperMonitoringSuppportedDevVersion = "5.8.0";

export const AGENT_COLUMNS = (): GridColDef[] => [
	...AGENT_DRAWER_BASE_COLUMNS,
	{
		field: "policyTampering",
		headerName: window.getCTTranslatedText("policyTampering"),
		width: 150,
		minWidth: 150,
		headerAlign: "left",
		align: "center",
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentPolicyTamperStatus agent={params.row} />;
		},
	},
	{
		field: "debuglog",
		headerName: "debugLog",
		width: 140,
		minWidth: 140,
		headerAlign: "left",
		align: "center",
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <DebugLogStatus agent={params.row} />;
		},
	},
	{
		field: "logs",
		headerName: "Logs",
		width: 100,
		minWidth: 100,
		headerAlign: "left",
		align: "center",
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentLogs agent={params.row} asset={params.row?.asset} />;
		},
	},
	{
		field: "diagnostics",
		headerName: "",
		headerClassName: "hideRightSeparator",
		width: 70,
		minWidth: 70,
		headerAlign: "left",
		align: "center",
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				params?.row?.isDiagnosticsSupported && (
					<AgentDiagnostic agent={params.row} />
				)
			);
		},
	},
];

export const NS_TRAFFIC_AGENT_COLUMNS = (): GridColDef[] => [
	...AGENT_COLUMNS(),
	{
		field: "North South Traffic Collection",
		headerName: "North South Traffic Collection",
		width: 140,
		minWidth: 140,
		headerAlign: "left",
		align: "center",
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <NorthSouthTrafficCollectionStatus agent={params.row} />;
		},
	},
];

const ColumnVisibilityModel: { [key: string]: boolean } = {
	// Hide columns from the display
};

const PinnedColumns = {
	left: [
		GRID_CHECKBOX_SELECTION_COL_DEF.field,
		"agentstatus",
		"currentversion",
	],
	right: ["logs", "diagnostics"],
};

export const AgentColumnConfig = {
	ColumnVisibilityModel: ColumnVisibilityModel,
	PinnedColumns: PinnedColumns,
};

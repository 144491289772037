import { IconsProps } from "./common";

export const AIXLogo = ({
	width = "16px",
	height = "16px",
	color = "#000",
}: IconsProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.0"
			width={width}
			height={height}
			fill={color}
			viewBox="0 0 2048.000000 2048.000000"
			preserveAspectRatio="xMidYMid meet"
		>
			<g
				transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
				stroke="none"
			>
				<path d="M0 10240 l0 -10240 10240 0 10240 0 0 10240 0 10240 -10240 0 -10240 0 0 -10240z m11620 2655 l0 -515 -380 0 -380 0 2 -1957 3 -1958 378 -3 377 -2 0 -515 0 -515 -1400 0 -1400 0 0 515 0 515 378 2 377 3 3 1958 2 1957 -380 0 -380 0 0 515 0 515 1400 0 1400 0 0 -515z m2725 -500 c402 -707 580 -1010 591 -1010 12 0 193 306 598 1007 l581 1007 688 1 689 0 -19 -32 c-27 -45 -1420 -2280 -1623 -2603 -89 -143 -163 -264 -163 -270 0 -5 422 -683 938 -1505 516 -822 947 -1510 958 -1528 l19 -32 -742 2 -743 3 -636 1095 c-475 816 -642 1096 -656 1097 -17 2 -126 -186 -640 -1097 l-620 -1100 -692 0 -693 0 54 88 c619 996 1843 3008 1837 3016 -14 17 -1791 2867 -1791 2872 0 2 336 3 746 2 l745 -3 574 -1010z m-7094 -1980 c527 -1639 959 -2981 959 -2982 0 -2 -300 -3 -667 -3 l-668 0 -210 708 -210 707 -990 3 -990 2 -211 -707 -210 -708 -647 -3 c-418 -1 -647 1 -647 8 0 8 1230 3785 1801 5533 l140 427 796 -2 795 -3 959 -2980z" />
				<path d="M5112 11103 c-177 -621 -322 -1134 -322 -1140 0 -10 142 -13 676 -13 639 0 675 1 670 18 -4 12 -526 1810 -642 2210 -13 42 -19 52 -37 52 -21 0 -43 -70 -345 -1127z" />
			</g>
		</svg>
	);
};

import { useTheme } from "@mui/material";

export const Rapid7IconSmall = () => {
	const theme = useTheme();
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M29.0852 11.8691H9.9622C10.2533 12.1724 10.5414 12.4757 10.8068 12.7926C12.972 15.4582 14.129 18.8013 14.0746 22.2351C14.0936 24.8479 13.5626 27.4354 12.5161 29.8295C11.4695 32.2236 9.93079 34.3707 8 36.1311H19.7079L25.2715 22.3018H18.3098L19.4714 16.9369H33.3007L26.0388 36.1281H28.888C35.5176 36.1705 40 30.6691 40 23.9774C40 17.2856 35.7268 11.8691 29.0821 11.8691"
				fill="#E85E26"
			/>
			<rect
				x="0.5"
				y="0.5"
				width="47"
				height="47"
				rx="3.5"
				stroke={theme.palette.text.primary}
				strokeOpacity="0.12"
			/>
		</svg>
	);
};

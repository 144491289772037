import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";
import { AssetCount } from "../asset-count";

export const TAG_COLUMNS: GridColDef[] = [
	{
		field: "key",
		headerName: "Value",
		minWidth: 290,
		flex: window.screen.availWidth > 1200 ? 1 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <Typography variant="body2">{params.row?.value}</Typography>;
		},
	},
	{
		field: "count",
		headerName: "Used by",
		width: 120,
		sortable: false,
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetCount tag={params.row} />;
		},
	},
];

import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from "@mui/material";
import { PathDirection } from "pages/paths/types";

export interface PathDirectionOptionProps {
	updatePathDirection: (direction: PathDirection) => void;
}

export const PathDirectionOption = ({
	updatePathDirection,
}: PathDirectionOptionProps) => {
	const onPathDirectionChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => {
		const direction: PathDirection = value as PathDirection;
		updatePathDirection(direction);
	};

	return (
		<>
			<Stack
				direction="column"
				sx={{ width: "100%", paddingLeft: "4px", mt: 5 }}
			>
				<Stack direction="row" justifyContent="flex-start">
					<Typography variant="subtitle2">
						{window.getCTTranslatedText(
							"selectInboundOrOutboundAsPartOfTemplate"
						)}
					</Typography>
				</Stack>
				<Stack direction="row" justifyContent="flex-start">
					<FormControl>
						<RadioGroup
							row
							aria-labelledby="select-source-destination-label"
							name="path-direction-radio-buttons-group"
							defaultValue={PathDirection.Inbound}
							onChange={onPathDirectionChange}
						>
							<FormControlLabel
								value={PathDirection.Inbound}
								control={<Radio size="small" />}
								label={window.getCTTranslatedText("Inbound")}
								sx={{
									"& .MuiFormControlLabel-label": {
										fontSize: 14,
									},
									pr: 3,
								}}
							/>
							<FormControlLabel
								value={PathDirection.Outbound}
								control={<Radio size="small" />}
								label={window.getCTTranslatedText("Outbound")}
								sx={{
									"& .MuiFormControlLabel-label": {
										fontSize: 14,
									},
								}}
							/>
						</RadioGroup>
					</FormControl>
				</Stack>
			</Stack>
		</>
	);
};

import { useQueryClient } from "@tanstack/react-query";
import { METHOD_TYPE } from "common/constants/methods";
import { parseErrorMessage } from "common/utils";
import { Field, TextFieldsValues } from "modules/form-fields/type";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { processIntegrationFormatData } from "pages/Integration/IntegrationUtils";
import {
	INTEGRATIONS,
	INTEGRATION_PROVIDERS,
	Integration,
	IntegrationConfigType,
	getIntegrationEnum,
} from "pages/Integration/constants";
import {
	useIntegrationActionConnection,
	useIntegrationFieldsAPI,
	useIntegrationUpdate,
} from "pages/Integration/hooks/useIntegrationAPI";
import { useSubmitAuthConfigAPI } from "pages/Integration/tabs/authentication/hooks";
import { IntegrationIdentifierMappings } from "pages/Integration/type";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { useCallback, useEffect, useState } from "react";
import { useIntegrationUpdateConfigFormExtraAfter } from "./useIntegrationUpdateConfigFormExtraAfter";

export function useIntegrationUpdateConfig(integration: IntegrationConfigType) {
	const [isLoading, setIsLoading] = useState(false);
	const [formExtraAfterPayload, setFormExtraAfterPayload] = useState<{
		formExtraAfterTextFieldsValues: TextFieldsValues;
		configId: string;
		onClose?: () => void;
	}>();

	const { updateSamlOrOauthIntegrationConfig } =
		useIntegrationUpdateConfigFormExtraAfter(
			formExtraAfterPayload?.configId ?? ""
		);

	const submitConfigMutation = useIntegrationUpdate(
		integration.integrationType,
		integration.integrationProvider,
		integration.configured ? METHOD_TYPE.PUT : METHOD_TYPE.POST
	);

	const submitConfigMutate = submitConfigMutation.mutate;

	const submitAuthConfigMutation = useSubmitAuthConfigAPI();
	const submitAuthConfigMutate = submitAuthConfigMutation.mutate;
	const integrationActionMutation = useIntegrationActionConnection();
	const integrationActionMutate = integrationActionMutation.mutate;
	const queryClient = useQueryClient();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	type ReconciliationData = {
		reconciliationIdentifier: IntegrationIdentifierMappings[];
		reconciliationAttributes: IntegrationIdentifierMappings[];
	};

	const updateIntegrationstatus = useCallback(
		(status: boolean, onClose?: () => void) => {
			const body = [
				{
					integrationProvider: integration.integrationProvider,
					integrationType: integration.integrationType,
					status: status,
				},
			];

			integrationActionMutate(body, {
				onSuccess: (response: any) => {
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						"ConfigurationActivated",
						{
							name: capitalizeFirstLetter(integration?.name ?? ""),
						}
					);
					onClose?.();
				},
				onError: (error: any) => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		},
		[
			integration.integrationProvider,
			integration.integrationType,
			integration?.name,
			integrationActionMutate,
			setSnackbar,
		]
	);

	useEffect(() => {
		if (formExtraAfterPayload?.configId) {
			updateSamlOrOauthIntegrationConfig(
				formExtraAfterPayload?.formExtraAfterTextFieldsValues ?? {},
				formExtraAfterPayload?.onClose
			);
		}
	}, [formExtraAfterPayload, updateSamlOrOauthIntegrationConfig]);

	const updateIntegrationConfig = useCallback(
		async function (
			formConfig: Array<Field>,
			integration: IntegrationConfigType,
			textFieldsValues: TextFieldsValues,
			formExtraAfterTextFieldsValues: TextFieldsValues,
			mappingData: ReconciliationData,
			onClose: () => void
		) {
			setIsLoading(true);
			try {
				if (textFieldsValues?.status) {
					delete textFieldsValues.status;
				}

				if (
					getIntegrationEnum(integration) ===
						INTEGRATIONS.OAUTH_IDENTITYPROVIDER ||
					getIntegrationEnum(integration) === INTEGRATIONS.SAML_IDENTITYPROVIDER
				) {
					// Handle OAuth2 and SAML2 cases
					const body = {
						authName: integration.name,
						authType:
							integration.integrationProvider === INTEGRATION_PROVIDERS.OAUTH
								? "OAUTH"
								: "SAML",
						config: {
							clientID: textFieldsValues?.clientID,
							clientSecret: textFieldsValues?.clientSecret,
							clientScopes: textFieldsValues?.clientScopes,
							authUrl: textFieldsValues?.authUrl,
							tokenUrl: textFieldsValues?.tokenUrl,
							logoutUrl: textFieldsValues?.logoutUrl,
							dicoveryEndpoint: textFieldsValues?.dicoveryEndpoint,
							tokenType: textFieldsValues?.tokenType,
							profileEndpoint: textFieldsValues?.profileEndpoint,
							algo: textFieldsValues?.algo,
							ssoURL: textFieldsValues?.ssoURL,
							ssoIssuer: textFieldsValues?.ssoIssuer,
							emailAttribute: textFieldsValues?.emailAttribute,
							ssoLogoutUrl: textFieldsValues?.ssoLogoutUrl,
							terminateAllSessions: textFieldsValues?.terminateAllSessions,
							ca: textFieldsValues?.ca?.replace(/(\\n)/g, "\n"),
							metaDataUrl: textFieldsValues?.metaDataUrl,
							refreshTokenRollingEnabled:
								textFieldsValues?.refreshTokenRollingEnabled,
							refreshTokenTTL: textFieldsValues?.refreshTokenTTL,
							accessTokenTTL:
								textFieldsValues?.accessTokenTTL &&
								Number(textFieldsValues?.accessTokenTTL),
						},
					};

					submitAuthConfigMutate(body, {
						onSuccess: response => {
							setFormExtraAfterPayload({
								formExtraAfterTextFieldsValues,
								onClose,
								configId: response,
							});

							queryClient.invalidateQueries({
								predicate: (q: any) => {
									let tag: string = q.queryKey[0] as string;
									return tag.startsWith("auth");
								},
							});
						},
						onError: error => {
							setSnackbar(
								true,
								SnackBarSeverity.Error,
								parseErrorMessage(error)
							);
						},
					});
				} else {
					const processData = processIntegrationFormatData(
						integration,
						formConfig,
						textFieldsValues
					);
					let body: Integration = {
						integrationProvider: integration.integrationProvider,
						integrationType: integration.integrationType,
						integrationParameters: processData,
					};
					if (
						getIntegrationEnum(integration) === INTEGRATIONS.SERVICENOW_CMDB
					) {
						body.integrationParameters.reconciliationIdentifier =
							mappingData?.reconciliationIdentifier;
						body.integrationParameters.reconciliationAttributes =
							mappingData?.reconciliationAttributes;
					}
					await submitConfigMutate(body, {
						onSuccess: response => {
							setSnackbar(
								true,
								SnackBarSeverity.Success,
								"IntegrationConfigurationSaved",
								{ name: capitalizeFirstLetter(integration?.name ?? "") }
							);

							setTimeout(() => {
								updateIntegrationstatus(true, onClose);
							}, 500);
						},
						onError: error => {
							setSnackbar(
								true,
								SnackBarSeverity.Error,
								parseErrorMessage(error)
							);
						},
					});
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		},
		[
			queryClient,
			setIsLoading,
			setSnackbar,
			submitAuthConfigMutate,
			submitConfigMutate,
			updateIntegrationstatus,
		]
	);

	return { isLoading, updateIntegrationConfig };
}

export function useFetchFormConfig(selectedIntegration: IntegrationConfigType) {
	const formConfig = useIntegrationFieldsAPI(
		selectedIntegration.integrationType,
		selectedIntegration.integrationProvider
	);
	return formConfig;
}

import { CustomCommonStoreType } from "common/types/types";
import CriteriaChips from "modules/facets/components/criteria-chips/CriteriaChips";
import { useTagPolicyFacetStore } from "../../store";
import { TagPolicy } from "../../types";

export function PolicyCriteria({
	policy,
	useFacetStore = useTagPolicyFacetStore,
}: {
	policy: TagPolicy;
	useFacetStore?: CustomCommonStoreType;
}) {
	return (
		<CriteriaChips
			data={policy}
			useFacetStore={useFacetStore}
			isValid={!!policy?.tagBasedPolicyId}
		/>
	);
}

import DragRowIcon from "assets/svgs/DragRowIcon";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { GridToolbar } from "modules/data-grid/components/data-grid-toolbar";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { useCallback } from "react";
import {
	FIREWALL_HOST_GROUPS_COLUMN_CONFIG,
	FIREWALL_HOST_GROUPS_COLUMNS,
	FIREWALL_HOST_GROUPS_PERMISSION,
} from "../constants";
import { useFirewallHostGroupStore } from "../store";
import { FirewallHostGroupListItem } from "../types";
import DeleteFirewallHostGroupModal from "./DeleteFirewallHostGroupModal";

interface Props
	extends Pick<
		DataGridProps<any>,
		"rowReordering" | "onRowOrderChange" | "isLoading" | "metadata"
	> {
	rows: FirewallHostGroupListItem[] | undefined;
	isFacetable?: boolean;
	showDelete?: boolean;
}

function FirewallHostGroupsDataGrid({
	rows,
	isFacetable = false,
	showDelete: showDeleteProp = false,
	rowReordering = false,
	...props
}: Props) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const showDelete =
		showDeleteProp &&
		userPermissions.has(FIREWALL_HOST_GROUPS_PERMISSION.DELETE);

	const columns = FIREWALL_HOST_GROUPS_COLUMNS(
		isFacetable,
		rowReordering,
		showDelete
	);

	const requestAPIRefresh = useFirewallHostGroupStore(
		state => state.requestAPIRefresh
	);

	const renderToolbar = useCallback(
		(children?: React.ReactNode) => (
			<GridToolbar
				show
				hideToolbar={() => {}}
				requestAPIRefresh={requestAPIRefresh}
				hiddenColumns={children}
			/>
		),
		[requestAPIRefresh]
	);

	return (
		<>
			<DataGrid
				columns={columns}
				rows={rows}
				getRowId={row => row.crowdstrikeFirewallHostgroupId}
				rowCount={rows?.length ?? 0}
				defaultPinnedColumns={FIREWALL_HOST_GROUPS_COLUMN_CONFIG.PinnedColumns}
				slots={{
					rowReorderIcon: DragRowIcon,
				}}
				renderToolbar={renderToolbar}
				rowReordering={rowReordering}
				{...props}
			/>
			<DeleteFirewallHostGroupModal />
		</>
	);
}

export default FirewallHostGroupsDataGrid;

import { PortIcon, TemplateIcon } from "assets/svgs";

export const getCustomMenuIcon = (key: string, color: string) => {
	key = key?.toLowerCase();

	let icon = null;
	switch (key) {
		case "ports":
			icon = <PortIcon color={color} />;
			break;
		case "templates":
			icon = <TemplateIcon color={color} />;
			break;
		default:
			return null;
	}

	return <>{icon}</>;
};

import { AGENT_TYPES, ARCHITECTURE_TYPE, PLATFORMS } from "../constants";

interface DefaultAgentTypeOptions {
	platform: PLATFORMS;
}

export function defaultAgentType({ platform }: DefaultAgentTypeOptions) {
	switch (platform) {
		case PLATFORMS.AIX:
		case PLATFORMS.RPM_LEGACY:
		case PLATFORMS.SOLARIS:
			return AGENT_TYPES.CT_LEGACY_AGENT;
		default:
			return AGENT_TYPES.CT_AGENT;
	}
}

interface SupportedPlatformArchitecturesDownloadPageOptions {
	platform: PLATFORMS;
}

export function supportedPlatformArchitecturesDownloadPage({
	platform,
}: SupportedPlatformArchitecturesDownloadPageOptions) {
	const { X86_64, ARM64, PPC } = ARCHITECTURE_TYPE;

	switch (platform) {
		case PLATFORMS.WINDOWS:
		case PLATFORMS.SOLARIS:
		case PLATFORMS.RPM_LEGACY:
			return [X86_64];
		case PLATFORMS.AIX:
			return [PPC];
		case PLATFORMS.DARWIN:
			return [ARM64, X86_64];
		default:
			return [X86_64, ARM64];
	}
}

import { Box, Stack, Typography } from "@mui/material";
import { AppliedFacetChipsLocation } from "common/atoms/facet-open-close-controller/FacetOpenCloseController";
import { AppliedFacetsChips } from "modules/facets/components/applied-facets-chips";
import { decodeThrowable } from "modules/facets/hooks/useFacetQueryConnector";
import { useTagRuleFacetStore } from "../../store";
import { TagRule } from "../../types";

export function RuleCriteria({ rule }: { rule: TagRule }) {
	if (!rule.ruleId) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	let urlParams = new URLSearchParams(rule.ruleCriteriaAsParams);
	let filters = urlParams.get("filters");
	let search = urlParams.get("search");

	let facetState = decodeThrowable(filters);
	if (!facetState && search) {
		facetState = new Map();
	}

	if (search && facetState) {
		let options = new Map();
		options.set(search, {
			isSelected: true,
		});
		facetState?.set("Query", options);
	}

	return (
		<>
			<Stack
				sx={{
					ml: facetState ? -1 : 0,
					minWidth: 0,
					width: "100%",
					"& .facet-chips": {
						flexWrap: "nowrap",
					},
					"& .facet-chips :last-child": {
						minWidth: 0,
						textOverflow: "ellipsis",
					},
				}}
			>
				{facetState ? (
					<>
						<AppliedFacetsChips
							sortByName={true}
							useFacetStore={useTagRuleFacetStore}
							appliedChipsLocation={AppliedFacetChipsLocation.SAVED_QUERY}
							facetState={facetState}
							facetChipProps={{
								size: "small",
								sx: {
									fontSize: "12px",
								},
							}}
						/>
					</>
				) : (
					<>
						<Typography variant="body2">{rule?.ruleCriteria}</Typography>
					</>
				)}
			</Stack>
		</>
	);
}

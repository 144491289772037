import { Box, Grid, Link, Stack, Tooltip, Typography } from "@mui/material";
import { VulnerabilityDrawer } from "modules/vulnerability-drawer";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { UnmanagedDevicesColumnRenderProps } from "../../appliance-unmanaged-devices/types";

export function UnmanagedDeviceVulnerabilities({
	device,
	viewOnly,
}: UnmanagedDevicesColumnRenderProps) {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	if (!device.deviceId) {
		return <Box style={{ width: 150 }} />;
	}

	let missingValue = false;
	if (device.vulnerabilities === null || device.vulnerabilities === undefined) {
		missingValue = true;
	}

	const openDrawer = (
		e:
			| React.MouseEvent<HTMLAnchorElement, MouseEvent>
			| React.MouseEvent<HTMLSpanElement, MouseEvent>
	) => {
		e.preventDefault();
		setIsDrawerOpen(true);
	};

	const isClickable = !viewOnly && device.vulnerabilities;

	return (
		<>
			<Stack alignItems="center" justifyContent="center">
				<Grid
					container
					direction="row"
					justifyContent="start"
					alignItems="center"
				>
					<Tooltip
						title={window.getCTTranslatedText(
							isClickable ? "View vulnerabilities" : ""
						)}
					>
						{!isClickable ? (
							<Typography variant="body2" sx={{ color: "warning.main" }}>
								{missingValue ? "" : device?.vulnerabilities}
							</Typography>
						) : (
							<Link
								typography={"body2"}
								component={RouterLink}
								to={"#"}
								onClick={e => openDrawer(e)}
								sx={{ color: "warning.main" }}
							>
								{missingValue ? "" : device?.vulnerabilities}
							</Link>
						)}
					</Tooltip>
				</Grid>
			</Stack>
			{isDrawerOpen && (
				<VulnerabilityDrawer
					isOpen={isDrawerOpen}
					onClose={() => setIsDrawerOpen(false)}
					assetId={device.deviceId || ""}
					assetName={device.deviceName || ""}
					assetType="unmanageddevices"
				/>
			)}
		</>
	);
}

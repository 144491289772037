import { Box, Link, TextField, Typography } from "@mui/material";

import { API_URL } from "hooks/useNetworking/constants";
import { AuthForm, HiddenForm } from "modules/auth-form";
import React, { useState } from "react";

export function Login() {
	const [loading, setLoading] = useState(false);
	const [showRedirectMessage, setShowRedirectMessage] = useState(false);

	const login = (e: React.FormEvent<HTMLFormElement>) => {
		setLoading(true);
	};

	const [email, setEmail] = useState("");
	const urlParams = new URLSearchParams(window.location.search);
	const signupRedirectURL = urlParams.get("signupRedirectURL") || "";

	const visibleForm = () => {
		return (
			<>
				<Typography variant="h6">
					{window.getCTTranslatedText("Enter your email to sign in")}
				</Typography>
				<TextField
					name="email"
					type="email"
					variant="standard"
					placeholder="you@domain.com"
					fullWidth
					label={window.getCTTranslatedText("Email")}
					value={email}
					onChange={e => setEmail(e.target.value.toLowerCase())}
					InputLabelProps={{ shrink: true }}
				/>
			</>
		);
	};

	const hiddenForm = () => {
		return <HiddenForm email={email} loading={showRedirectMessage} />;
	};

	return (
		<AuthForm
			buttonAction="Sign In"
			action={`${API_URL}/auth/user/login`}
			onSubmit={login}
			visibleForm={visibleForm()}
			hiddenForm={hiddenForm()}
			setLoading={setLoading}
			loading={loading}
			authConfiguration={{ email }}
			setShowRedirectMessage={setShowRedirectMessage}
		>
			<>
				{signupRedirectURL ? (
					<Box mt={3} textAlign="center">
						<Typography color="text">
							{window.getCTTranslatedText("Don't have an account?")}&nbsp;
							<Link href={signupRedirectURL} underline="hover">
								{window.getCTTranslatedText("Sign up")}
							</Link>
						</Typography>
					</Box>
				) : null}
			</>
		</AuthForm>
	);
}

import LoadingButton from "@mui/lab/LoadingButton";
import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Drawer,
	FormControl,
	FormControlLabel,
	Link,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { FilePicker } from "modules/file-picker";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useMemo, useState } from "react";
import { APIKey } from "../types";
import { GenerateAPIKeyButton } from "./GenerateAPIKeyButton";
import { Toolbar } from "common/atoms/toolbar";

const OPTIONS = {
	UPLOAD: "upload",
	// PASTE: "paste",
	GENERATE: "generate",
};

function useNewAPIKey() {
	const path = `auth/user/api-key`;
	return useMutation<any, Error, APIKey>(["apiKey", path]);
}

const validPrefixes = [
	"-----BEGIN RSA PUBLIC KEY-----",
	"-----BEGIN PUBLIC KEY-----",
];

export function NewAPIKeyButton({
	defaultShowForm,
}: {
	defaultShowForm?: boolean;
}) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const [option, onChangeOption] = useState(OPTIONS.UPLOAD);

	const [showForm, setShowForm] = useState(defaultShowForm === true);

	const [publicKey, setPublicKey] = useState("");

	const createKeyMutation = useNewAPIKey();
	const queryClient = useQueryClient();

	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const createKey = async () => {
		await createKeyMutation.mutateAsync({
			publicKey: window.btoa(publicKey),
		});
		resetDrawer();
		queryClient.invalidateQueries({
			queryKey: ["apiKey"],
		});
	};

	const checkValid = (text: string) => {
		let isValid = false;
		validPrefixes.forEach(prefix => {
			if (text.startsWith(prefix)) {
				isValid = true;
			}
		});
		return isValid;
	};

	const onChangeFile = async (files: File[]) => {
		if (!files.length) {
			return;
		}
		const text = await files[0].text();
		const isValid = checkValid(text);
		if (!isValid) {
			setSnackbar(true, SnackBarSeverity.Error, "InvalidKey");
			return;
		}
		setPublicKey(text);
	};

	const resetDrawer = () => {
		setPublicKey("");
		setShowForm(false);
		onChangeOption(OPTIONS.UPLOAD);
	};

	const isKeyInvalid = useMemo(() => {
		if (!publicKey) {
			return true;
		}
		return false === checkValid(publicKey);
	}, [publicKey]);

	return (
		<>
			{true === userPermissions.get("CREATE_API_KEY") && (
				<Button
					variant="contained"
					color="primary"
					onClick={() => setShowForm(true)}
					size="medium"
				>
					{window.getCTTranslatedText("New API Key")}
				</Button>
			)}

			<Drawer
				anchor="right"
				open={showForm}
				onClose={() => {
					resetDrawer();
				}}
				aria-labelledby="delete-api-key"
				PaperProps={{
					elevation: 1,
				}}
			>
				<Toolbar />
				<DialogTitle id="delete-api-key">
					{window.getCTTranslatedText("New API Key")}
				</DialogTitle>

				<DialogContent
					sx={{ minWidth: 800, display: "flex", flexDirection: "column" }}
				>
					<DialogContentText sx={{ mb: 1 }}>
						{window.getCTTranslatedText(
							`An API key is required to access ColorTokens API. You can either upload public key file of an RSA key (minimum 2048 bits) or generate a new one in the browser.`
						)}
					</DialogContentText>

					<FormControl
						sx={{
							display: Boolean(publicKey) ? "none" : undefined,
							width: "100%",
							mb: 2,
						}}
					>
						<RadioGroup
							sx={{ flexDirection: "row" }}
							value={option}
							onChange={(e, value) => onChangeOption(value)}
						>
							<FormControlLabel
								value={OPTIONS.UPLOAD}
								control={<Radio size="small" />}
								label={
									<Typography variant="body1">
										{window.getCTTranslatedText("Choose Public Key File")}{" "}
										<span
											style={{ opacity: 0.5 }}
										>{`(${window.getCTTranslatedText("Recommended")})`}</span>
									</Typography>
								}
							/>
							<FormControlLabel
								sx={{ ml: 8 }}
								value={OPTIONS.GENERATE}
								control={<Radio size="small" />}
								label={window.getCTTranslatedText(
									"Generate a new private key in browser"
								)}
							/>
						</RadioGroup>
					</FormControl>

					{option === OPTIONS.UPLOAD && !Boolean(publicKey) && (
						<Stack alignItems="center" justifyContent={"center"} flex={1}>
							<Link
								href="https://colortokens.atlassian.net/wiki/spaces/~6307a5cbf87d21c16228ac7c/pages/3306356835/How+to+enable+API+key+access+to+your+ColorTokens+account?atlOrigin=eyJpIjoiZGU5OTY5ZTYzYjczNGMzYzk5MjE2NDJjMzMzNzRjNDMiLCJwIjoiYyJ9"
								target="_blank"
								color={"info.main"}
							>
								{window.getCTTranslatedText(
									"How to generate RSA keys on your machine?"
								)}
							</Link>
							<Stack sx={{ mt: 2, flex: 0.7, width: 500 }}>
								<FilePicker
									onChangeFiles={onChangeFile}
									options={{
										accept: {
											"text/html": [".pem"],
										},
										maxFiles: 1,
									}}
								/>
							</Stack>
						</Stack>
					)}

					{option === OPTIONS.GENERATE && !Boolean(publicKey) && (
						<>
							<GenerateAPIKeyButton
								onGenerate={publicKey => {
									setPublicKey(publicKey);
								}}
							/>
						</>
					)}

					{Boolean(publicKey) && (
						<TextField
							sx={{
								"& textarea": {
									fontFamily: "monospace",
								},
							}}
							multiline={true}
							rows={5}
							fullWidth
							id="publicKey"
							label="Public Key"
							value={publicKey}
							onChange={e => {
								setPublicKey(e.target.value);
								const isValid = checkValid(e.target.value);
								if (!isValid) {
									setSnackbar(true, SnackBarSeverity.Error, "InvalidKey");
									return;
								}
							}}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						color="info"
						onClick={() => {
							resetDrawer();
						}}
					>
						{window.getCTTranslatedText("Cancel")}
					</Button>
					<LoadingButton
						disabled={isKeyInvalid}
						loading={createKeyMutation.isLoading}
						variant="contained"
						color="info"
						onClick={() => {
							createKey();
						}}
					>
						{window.getCTTranslatedText("Save")}
					</LoadingButton>
				</DialogActions>
			</Drawer>
		</>
	);
}

import { CustomCommonStoreType } from "common/types/types";
import { CoreStoreBuilder } from "modules/core/store";
import { CoreStore } from "modules/core/types";
import { FacetStoreBuilder } from "modules/facets";
import { FacetStore } from "modules/facets/types";
import { create } from "zustand";

export const useTagRuleStore = create<CoreStore>()(set => ({
	...CoreStoreBuilder(set),
}));

export const useTagRuleFacetStore: CustomCommonStoreType = create<FacetStore>(
	set => ({
		...FacetStoreBuilder(set),
	})
);

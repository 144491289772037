import { CheckCircleOutline } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { CopyInviteButton } from "pages/organization/components/copy-invite-button";
import { Invite } from "pages/organization/types";
import { useState } from "react";

interface InviteParams {
	adminName: string;
	adminEmail: string;
	tenantName: string;
	tenantEmail: string;
}

export function ReferralForm() {
	const inviteMutation = useMutation<Invite, Error, InviteParams>([
		"invite",
		"auth/invite/tenant",
	]);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [organization, setOrganization] = useState("");
	const [checkErrors, setCheckErrors] = useState(false);

	const sendInvite = () => {
		setCheckErrors(true);

		if (!name || !email || !organization) {
			return;
		}

		inviteMutation.mutate({
			adminName: name,
			adminEmail: email,
			tenantName: organization,
			tenantEmail: email,
		});
	};

	const reset = () => {
		setCheckErrors(false);
		setName("");
		setEmail("");
		setOrganization("");
		inviteMutation.reset();
	};

	const inviteMutationResponse = inviteMutation.data;

	if (Boolean(inviteMutationResponse?.inviteLink)) {
		return (
			<Stack
				spacing={2}
				sx={{ width: 600, pt: 12, flex: 1 }}
				alignItems="center"
			>
				<CheckCircleOutline sx={{ fontSize: 200 }} color="success" />
				<Typography>
					An email with the invite code has been sent to {email}. You can also
					copy the invite code and send directly to {name}.
				</Typography>
				<Stack
					spacing={2}
					direction="row"
					alignItems={"center"}
					justifyContent="center"
				>
					<Button color="inherit" variant="outlined" onClick={() => reset()}>
						Send Another
					</Button>
					<CopyInviteButton code={inviteMutationResponse?.inviteLink || ""} />
				</Stack>
			</Stack>
		);
	}

	return (
		<Stack sx={{ minWidth: 300, pt: 12 }}>
			<img
				style={{ width: 400, height: 150 }}
				src={"/assets/images/referral.svg"}
				alt="Refer"
			/>
			<TextField
				sx={{ mt: 4 }}
				value={name}
				onChange={e => setName(e.target.value)}
				label="Name"
				required
				error={checkErrors && !Boolean(name)}
				variant="standard"
			></TextField>
			<TextField
				value={email}
				onChange={e => setEmail(e.target.value)}
				label="Email"
				required
				error={checkErrors && !Boolean(email)}
				variant="standard"
			></TextField>
			<TextField
				value={organization}
				onChange={e => setOrganization(e.target.value)}
				label="Organization"
				required
				error={checkErrors && !Boolean(organization)}
				variant="standard"
			></TextField>
			<LoadingButton
				loading={inviteMutation.isLoading}
				sx={{ mt: 2, mb: 2 }}
				variant="contained"
				onClick={() => {
					sendInvite();
				}}
			>
				Send Invite
			</LoadingButton>
		</Stack>
	);
}

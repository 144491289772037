import { dayjsWithPlugins } from "common/utils/dayjsWithPlugins";

export const getRelativeLastObserved = (
	lastObserved: string,
	truncateHour = false
) => {
	if (!lastObserved) {
		return "";
	}

	const lastObservedTime = dayjsWithPlugins(lastObserved);
	if (truncateHour) {
		const currentTime = dayjsWithPlugins();

		const minutesDiff = currentTime.diff(lastObservedTime, "minute");

		if (minutesDiff > 60) {
			return lastObservedTime.fromNow();
		} else {
			return "in last hour";
		}
	}

	return lastObservedTime.fromNow();
};

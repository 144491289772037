import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
	Button,
	IconButton,
	Paper,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { PORTLESS_PROTOCOLS } from "pages/paths/types";
import {
	PortFormInt,
	TemplateFormMode,
	defaultBlockPortObj,
	defaultPortObj,
} from "pages/templates/components/template-form-drawer/components/template-form/types";
import { TemplateType } from "pages/templates/types";
import { useCallback, useState } from "react";
import { PortForm } from "../../../port-form";
import { baseRowStyle, rulesTitleWrapper } from "../../constants";
import { AssignPortProps } from "./types";

export const AssignPort = ({
	portList,
	setPortList,
	templateType,
	mode,
	hideSource = false,
	showInlineActionButton = false,
	hideProcess = false,
	containerProps,
}: AssignPortProps) => {
	const [tempPortList, setTempPortList] = useState(portList ?? []);

	const addPort = () => {
		const newPortList = [...tempPortList];
		const portObj =
			templateType === TemplateType.BlockTemplate
				? { ...defaultBlockPortObj }
				: { ...defaultPortObj };
		newPortList.push(portObj);
		setTempPortList(newPortList);
	};

	const isValid = () => {
		const newPortList = tempPortList;

		return !newPortList.every(
			port =>
				PORTLESS_PROTOCOLS.includes(port.listenPortProtocol?.toUpperCase()) ||
				(port.listenPort.length > 0 && port.listenPortProtocol.length > 0)
		);
	};

	const onAdd = () => {
		const newPortList = tempPortList;
		setPortList(newPortList);
		setTempPortList([]);
	};

	const removePort = (index: number) => {
		if (index || index === 0) {
			const newPortList = [...tempPortList];
			newPortList.splice(index, 1);
			setPortList(newPortList);
			setTempPortList(newPortList);
		}
	};

	const updatePortData = useCallback(
		(port: PortFormInt, index: number) => {
			if (index || index === 0) {
				setTempPortList(prev => {
					let newPortList = [...prev];
					newPortList[index] = port;
					return newPortList;
				});

				if (showInlineActionButton) {
					return;
				}

				let newPortList = [...(portList ?? [])];
				newPortList[index] = port;
				setPortList(newPortList);
			}
		},
		[portList, setPortList, showInlineActionButton]
	);

	return (
		<Paper sx={{ minWidth: "100%" }} {...containerProps}>
			<Stack alignItems="flex-start" justifyContent="flex-start" spacing={0}>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent={"space-between"}
					sx={{
						...rulesTitleWrapper,
						width: "100%",
						background: theme =>
							theme.palette.mode === "dark"
								? theme.palette.background.paper
								: theme.palette.custom.lightGreyBg,
					}}
				>
					<Stack alignItems="center">
						<Typography variant="body1">
							{window.getCTTranslatedText("Ports")}
						</Typography>
					</Stack>
					<Stack alignItems="center">
						<Button
							variant="text"
							startIcon={<AddCircleOutlineIcon />}
							color="primary"
							onClick={() => addPort()}
						>
							{window.getCTTranslatedText(
								mode === TemplateFormMode.ADD ? "Add" : "Assign"
							)}{" "}
							{window.getCTTranslatedText("Ports")}
						</Button>
					</Stack>
				</Stack>

				<Stack sx={{ width: "100%", px: 3 }}>
					<Stack>
						{tempPortList &&
							tempPortList.length > 0 &&
							tempPortList.map((item, index, values) => {
								item = item as PortFormInt;
								const showLabel = Boolean(index === 0);
								return (
									<Stack
										direction="row"
										alignItems="center"
										sx={{
											...baseRowStyle,
											mt: index === 0 ? 3 : 0,
											mb: index === values?.length - 1 ? 2 : 0,
										}}
										className={`port-${index}`}
										key={`port-${index}`}
									>
										<PortForm
											port={item}
											updatePortData={(port: PortFormInt) =>
												updatePortData(port, index)
											}
											showLabel={showLabel}
											templateType={templateType}
											hideSource={hideSource}
											hideProcess={hideProcess}
										/>
										<Stack sx={{ pl: 5 }} alignItems="center">
											<IconButton
												size="medium"
												aria-label="remove port"
												onClick={() => removePort(index)}
											>
												<Tooltip
													title={window.getCTTranslatedText("Remove Port")}
												>
													<RemoveCircleOutlineIcon fontSize="small" />
												</Tooltip>
											</IconButton>
										</Stack>
									</Stack>
								);
							})}
					</Stack>
				</Stack>

				{showInlineActionButton && tempPortList.length > 0 && (
					<Stack width={"100%"} px={3} pb={2}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent={"flex-end"}
							width={"100%"}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={() => onAdd()}
								disabled={isValid()}
							>
								{window.getCTTranslatedText(
									mode === TemplateFormMode.ADD ? "Add" : "Assign"
								)}
							</Button>
						</Stack>
					</Stack>
				)}
			</Stack>
		</Paper>
	);
};

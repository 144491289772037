import { useTheme } from "@mui/material";
import grey from "@mui/material/colors/grey";
import { getColor } from "common/constants/colors";
import { BreachImpactChart } from "@colortokens/ng-ui";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { AssetSecurityLevelIcons } from "pages/assets/components/asset-security-level-icons";
import { SecurityLevels } from "pages/assets/types";
import { getSecurityLevelText } from "pages/dashboard/helpers/getSecurityLevelText";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const RiskGaugeChart = ({
	value,
	viewOnly,
}: {
	value: number;
	viewOnly?: boolean;
}) => {
	value = Math.round(value ?? 0);
	return <GaugeChart value={value} viewOnly={viewOnly} />;
};

interface GaugeChartProps {
	value: number;
	width?: number;
	height?: number;
	viewOnly?: boolean;
}

const GaugeChart = ({
	value,
	width = 400,
	height = 400,
	viewOnly,
}: GaugeChartProps) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const processBtnClickCallback = (text: SecurityLevels) => {
		const link = getBtnLink(text, "assetrisk");
		if (link) {
			navigate(link);
		}
	};

	const arcData = useMemo(
		() =>
			value
				? [
						{
							innerRadiusInPercent: 65,
							outerRadiusInPercent: 81,
							startAngleInPercent: 0,
							endAngleInPercent: value,
							color: theme.palette.mode === "dark" ? grey[800] : grey[100],
							key: "highlight",
						},
						{
							innerRadiusInPercent: 64.9,
							outerRadiusInPercent: 81.1,
							startAngleInPercent: -2.5,
							endAngleInPercent: value - 2.5,
							color: theme.palette.mode === "dark" ? grey[800] : grey[100],
							cornerRadius: 20,
							key: "highlight-rounded",
						},
						{
							innerRadiusInPercent: 70,
							outerRadiusInPercent: 76,
							startAngleInPercent: 0,
							endAngleInPercent: 27,
							color: getColor(SecurityLevels.Low),
							cornerRadius: 6,
							key: "low",
						},
						{
							innerRadiusInPercent: 70,
							outerRadiusInPercent: 76,
							startAngleInPercent: 25,
							endAngleInPercent: 50,
							color: getColor(SecurityLevels.Medium),
							cornerRadius: 0,
							key: "medium",
						},
						{
							innerRadiusInPercent: 70,
							outerRadiusInPercent: 76,
							startAngleInPercent: 73,
							endAngleInPercent: 100,
							color: getColor(SecurityLevels.Critical),
							cornerRadius: 6,
							key: "high",
						},
						{
							innerRadiusInPercent: 70,
							outerRadiusInPercent: 76,
							startAngleInPercent: 50,
							endAngleInPercent: 75,
							color: getColor(SecurityLevels.High),
							cornerRadius: 0,
							key: "critical",
						},
						{
							innerRadiusInPercent: 62,
							outerRadiusInPercent: 84,
							startAngleInPercent: value - 0.5,
							endAngleInPercent: value + 0.5,
							color: theme.palette.text.primary,
							cornerRadius: 5,
							key: "marker",
						},
					]
				: [],
		[value, theme.palette.mode, theme.palette.text.primary]
	);

	const text = getSecurityLevelText(value ?? 0);

	const buttonProps = useMemo(() => {
		const text = getSecurityLevelText(value ?? 0) ?? "unknown";

		return {
			text: window.getCTTranslatedText(capitalizeFirstLetter(text)),
			tooltipText: `${window.getCTTranslatedText(
				"SecurityLevelTextButtonToolTip",
				{
					category: window.getCTTranslatedText("BreachImpact"),
					text: window.getCTTranslatedText(capitalizeFirstLetter(text ?? "")),
				}
			)}`,
			bgColor: getColor(`${text}bg`),
			icon: <AssetSecurityLevelIcons securityLevel={text} />,
			labelButtonStyles: {
				color: theme.palette.text.primary,
			},
		};
	}, [value, theme.palette.text.primary]);

	return (
		<BreachImpactChart
			viewOnly={viewOnly}
			width={width}
			height={height}
			value={value}
			onClick={() => processBtnClickCallback(text)}
			data={arcData}
			buttonProps={buttonProps}
		/>
	);
};

function getBtnLink(text: SecurityLevels, type: string) {
	let link = "";
	let queryParam = encodeURI(`${type}=${text}`);
	link = `/assets?filters=${queryParam}`;

	return link;
}

import { GridRowId } from "@mui/x-data-grid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useAssetStore } from "pages/assets/store";
import { usePathStore } from "pages/paths/store";
import { PathStoreType } from "pages/paths/store/types";
import { PathStatus } from "pages/paths/types";

interface PathStatusActionbarProps {
	selectedStatus: PathStatus | undefined;
	selection: GridRowId[];
	update: () => void;
	cancel: () => void;
	useStore?: PathStoreType;
	comment?: string | null;
}

function useUpdatePathStatus() {
	const path = `paths/approvals`;
	return useMutation<any, Error, any>(["paths", path, "put"]);
}

export const PathStatusActionBar = ({
	selectedStatus,
	selection,
	update,
	cancel,
	useStore = usePathStore,
	comment,
}: PathStatusActionbarProps) => {
	const notify = useEventSubscriptionStore(state => state.notify);
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const updatePathsMutation = useUpdatePathStatus();
	const queryClient = useQueryClient();
	const requestAssetAPIRefresh = useAssetStore(
		state => state.requestAPIRefresh
	);
	const requestPathAPIRefresh = useStore(state => state.requestAPIRefresh);

	const updateStatus = async (
		selectedPathIds: GridRowId[],
		selectedOperation: PathStatus | undefined
	) => {
		if (selectedPathIds?.length > 0 && selectedOperation) {
			const body = {
				toState: selectedOperation,
				criteria: `channelHash in (${selectedPathIds
					.map(id => `'${id}'`)
					.join(",")})`,
				comment: comment,
			};

			await updatePathsMutation.mutateAsync(body, {
				onSuccess: response => {
					update();
					// setPathsSelection([]);
					queryClient.invalidateQueries({
						queryKey: ["asset"],
					});
					queryClient.invalidateQueries({
						queryKey: ["paths"],
					});
					requestAssetAPIRefresh();
					requestPathAPIRefresh();
					selectedStatus = undefined;
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "Updated Path Status successfully",
					});
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		}
	};

	const confirmAction = () => {
		if (selection?.length > 0) {
			updateStatus(
				selection.map(rowId => rowId.valueOf().toString().split("-")[0]),
				selectedStatus
			);
		}
	};

	return (
		<>
			{userPermissions.has("UPDATE_PATH") && (
				<ToolbarAction
					loading={updatePathsMutation?.isLoading}
					isValid={selection?.length > 0 && !!selectedStatus}
					actionBtnText={"Confirm"}
					save={confirmAction}
					cancel={cancel}
					hasPermission={userPermissions.has("UPDATE_PATH")}
				></ToolbarAction>
			)}
		</>
	);
};

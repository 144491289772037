import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
	Box,
	Button,
	Paper,
	Stack,
	Tab,
	Tabs,
	TextField,
	useTheme,
} from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";
import { TabPanel } from "common/atoms/ct-tabs";
import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { Referral } from "pages/referral";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes";
import { InviteMemberDrawer } from "./components/invite-member-drawer";
import { TenantProfileEditor } from "./components/tenant-profile-editor";
import { UpdateUserRole } from "./components/update-user-role";
import { UserActionsMenu } from "./components/user-actions";
import { INVITE_COLUMNS, MEMBERS_COLUMNS, QUERY_ACTIONS } from "./constants";
import {
	CTRole,
	CTUser,
	Invite,
	InviteResult,
	TeamMembersResult,
} from "./types";

const iconStyle = "rgba(0, 0, 0, 0.38)";
const IconStyleDarkMode = "rgba(255, 255, 255, 0.38)";

interface ReferralSettings {
	referralAllowed: boolean;
}

function useTeamMembers() {
	return useQuery<TeamMembersResult, Error>([
		"teamMembers",
		"auth/admin/tenant/user",
	]);
}

export function useRoles() {
	return useQuery<Array<CTRole> | undefined, Error>([
		"teamMembers",
		"auth/roles",
	]);
}

function useInviteList() {
	return useQuery<InviteResult, Error>(["invites", "auth/admin/invite/user"]);
}

export function OrgManagement() {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	// TODO: add error handling and loading states
	const { data: membersList, isLoading } = useTeamMembers();
	const { data: rolesList } = useRoles();
	const [showInviteDialog, setShowInviteDialog] = useState(false);

	const navigate = useNavigate();
	const [selectedTab, setTab] = useState(0);
	const urlParams = new URLSearchParams(window.location.search);
	const action = urlParams.get("action") || "";
	const redirectURL = urlParams.get("redirectURL") || "";

	useEffect(() => {
		if (!userPermissions.has("CREATE_SUBJECT")) {
			navigate(ROUTES.DASHBOARD.pathname);
		}
	}, [navigate, userPermissions]);

	useEffect(() => {
		if (action === QUERY_ACTIONS.INVITE) {
			handleInviteDialogOpen();
		}
	}, [action]);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	const handleInviteDialogOpen = () => {
		setShowInviteDialog(true);
	};

	const handleClose = () => {
		setShowInviteDialog(false);
		if (action === QUERY_ACTIONS.INVITE && redirectURL) {
			window.location.href = redirectURL;
		}
	};

	const onSuccess = () => {
		handleClose();
		setTab(1);
	};

	const { data: referralSettings } = useQuery<ReferralSettings>([
		"canRefer",
		"auth/referralSettings",
	]);

	return (
		<Stack direction={"row"} spacing={2} sx={{ height: "100%" }}>
			<TenantProfileEditor />
			<Stack spacing={3} sx={{ flex: 1, height: "100%" }}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Tabs value={selectedTab} onChange={handleTabChange}>
							<Tab label={window.getCTTranslatedText("Members")} />
							{userPermissions.has("CREATE_SUBJECT") && (
								<Tab label={window.getCTTranslatedText("Invites")} />
							)}
							{referralSettings && referralSettings.referralAllowed && (
								<Tab label="Refer and earn" />
							)}
						</Tabs>
						{userPermissions.has("CREATE_SUBJECT") && (
							<Button
								variant="contained"
								onClick={handleInviteDialogOpen}
								color="primary"
								sx={{ ml: 2 }}
							>
								{window.getCTTranslatedText("Invite Members")}
							</Button>
						)}
					</Stack>
				</Box>
				<TabPanel
					value={selectedTab}
					index={0}
					style={{ flex: 1, overflow: "hidden" }}
				>
					<>
						<Stack
							direction={"column"}
							spacing={2}
							sx={{ height: "100%", width: "100%" }}
						>
							<Stack sx={{ flex: 1, width: "100%", overflow: "hidden" }}>
								<MembersList
									users={membersList?.Users}
									loading={isLoading}
									rolesList={rolesList}
								/>
							</Stack>
						</Stack>
					</>
				</TabPanel>
				<TabPanel
					value={selectedTab}
					index={1}
					style={{ flex: 1, overflow: "hidden" }}
				>
					<>
						<InviteList />
					</>
				</TabPanel>
				<TabPanel
					value={selectedTab}
					index={2}
					style={{ flex: 1, overflow: "hidden" }}
				>
					<Referral />
				</TabPanel>
				{showInviteDialog && userPermissions.has("CREATE_SUBJECT") && (
					<InviteMemberDrawer
						isOpen={showInviteDialog}
						onClose={handleClose}
						onSuccess={() => {
							onSuccess();
						}}
						rolesList={rolesList}
						title="Invite New Member"
						btnTitle={"invite"}
					/>
				)}
			</Stack>
		</Stack>
	);
}

interface MembersListProps {
	users?: Array<CTUser>;
	loading: boolean;
	rolesList: Array<CTRole> | undefined;
}

function MembersList({ users, loading, rolesList }: MembersListProps) {
	const theme = useTheme();
	const [searchText, setSearchText] = useState("");
	const [userRows, setUserRows] = useState(users);
	useEffect(() => {
		setUserRows(users);
	}, [users]);

	const columns = useMemo(() => {
		if (!users || !users.length) {
			return MEMBERS_COLUMNS;
		}

		const MEMBERS_LIST_COLUMNS: GridColDef[] = [
			...MEMBERS_COLUMNS,
			{
				field: "role",
				headerName: "Role",
				headerAlign: "left",
				align: "left",
				flex: 1,
				sortingOrder: ["asc", "desc"],
				renderCell: (params: GridRenderCellParams<any, any, any>) => {
					return <UpdateUserRole rolesList={rolesList} user={params?.row} />;
				},
			},
			{
				field: "action",
				headerName: "",
				sortable: false,
				width: 60,
				renderCell: (params: GridRenderCellParams<any, any, any>) => {
					return <UserActionsMenu user={params?.row} />;
				},
			},
		];

		return MEMBERS_LIST_COLUMNS;
	}, [rolesList, users]);

	const handleUpdate = (
		e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const value = e.target.value;
		setSearchText(value);
		const filteredUsers = (users ?? []).filter(
			user =>
				user.name.toLowerCase().includes(value.toLowerCase()) ||
				user.email.toLowerCase().includes(value.toLowerCase())
		);
		setUserRows(filteredUsers);
	};

	const onClear = () => {
		setSearchText("");
		setUserRows(users);
	};

	return (
		<Paper sx={{ flex: 1, overflow: "hidden" }}>
			<TextField
				variant="standard"
				name="Members"
				value={searchText}
				type="text"
				placeholder={window.getCTTranslatedText("membersSearch")}
				fullWidth
				onChange={handleUpdate}
				InputProps={{
					startAdornment: (
						<SearchIcon
							sx={{
								color:
									theme.palette.mode === "dark" ? IconStyleDarkMode : iconStyle,
								m: 1,
								ml: 1.5,
							}}
						/>
					),
					endAdornment: searchText && (
						<CloseIcon
							onClick={onClear}
							sx={{
								color:
									theme.palette.mode === "dark" ? IconStyleDarkMode : iconStyle,
								cursor: "pointer",
								mr: 2,
							}}
						/>
					),
				}}
				sx={{
					mb: 1,
					mt: 0,
					"& .MuiInputBase-root": {
						pb: "8px",
						pt: "16px",
					},
				}}
			/>
			<DataGrid
				sx={{ height: "calc(100% - 66px)" }}
				initialState={{
					sorting: {
						sortModel: [{ field: "name", sort: "asc" }],
					},
				}}
				isLoading={loading}
				rows={getUniqueListBy(userRows || [], "userId")}
				rowCount={userRows?.length ?? 0}
				columns={columns}
				getRowId={({ userId }: CTUser) => userId}
			/>
		</Paper>
	);
}

function InviteList() {
	const invites = useInviteList();

	const columns = INVITE_COLUMNS;

	return (
		<Paper sx={{ flex: 1, overflow: "hidden" }}>
			<DataGrid
				initialState={{
					sorting: {
						sortModel: [{ field: "name", sort: "asc" }],
					},
				}}
				isLoading={invites.isLoading}
				rows={getUniqueListBy(invites.data || [], "inviteCode")}
				rowCount={invites.data?.length ?? 0}
				columns={columns}
				getRowId={({ inviteCode }: Invite) => inviteCode}
			/>
		</Paper>
	);
}

import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import { TemplateColumnRenderProps } from "pages/templates/types";
import { Link as RouterLink } from "react-router-dom";
import numeral from "numeral";

export function AssetCount({ template, viewOnly }: TemplateColumnRenderProps) {
	if (!template.templateId) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="flex-start">
			<Stack alignItems="flex-start">
				{viewOnly || template?.templateAssignments === 0 ? (
					<Typography variant="body2">
						{numeral(template?.templateAssignments || 0).format("0a")}{" "}
						{window.getCTTranslatedText(
							template?.templateAssignments && template?.templateAssignments > 1
								? "assets"
								: "asset"
						)}
					</Typography>
				) : (
					<Tooltip title={window.getCTTranslatedText("View assets")}>
						<Link
							typography={"body2"}
							component={RouterLink}
							to={`/assets?filters=${encodeURIComponent(
								`templatename=${template.templateName}`
							)}`}
							sx={{ color: "primary.main" }}
						>
							{numeral(template?.templateAssignments || 0).format("0a")}{" "}
							{window.getCTTranslatedText(
								template?.templateAssignments &&
									template?.templateAssignments > 1
									? "assets"
									: "asset"
							)}
						</Link>
					</Tooltip>
				)}
			</Stack>
		</Stack>
	);
}

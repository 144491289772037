import { useQuery } from "@tanstack/react-query";

export interface ConfiguredDataResult {
	configID: string;
	authName: string;
	authType: string;
	clientDetail: string;
	config: {
		refreshTokenTTL: number;
		accessTokenTTL: number;
		refreshTokenRollingEnabled: boolean;
	};
}

export function useAuthConfigAPI({
	enabled,
	refetchOnWindowFocus,
}: {
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
}) {
	return useQuery<Array<ConfiguredDataResult>, Error>(
		["auth", "auth/admin/auth-config"],
		{
			enabled: enabled ?? true,
			refetchOnWindowFocus: refetchOnWindowFocus ?? true,
		}
	);
}

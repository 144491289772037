import { AssetSecurityDetailWithoutAggregatedEndpoint } from "pages/asset/components/asset-detail/AssetSecurityDetailWithoutAggregatedEndpoint";
import { ReviewDetailsInt } from "pages/asset/components/asset-detail/components/single-assets-classification-by-levels/SingleAssetClassificationByLevels";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { Asset } from "pages/assets/types";
import { useState } from "react";

interface AssetSecurityDetailProps {
	asset: Asset;
	data: any;
	isLoading: boolean;
}

export const AssetSecurityDetail = ({
	asset,
	data,
	isLoading,
}: AssetSecurityDetailProps) => {
	const [activeTab, setActiveTab] = useState<Direction>(Direction.Inbound);
	const [showUnreviewedFacet, setShowUnreviewedFacet] = useState<boolean>(true);
	const [showUserGroupPaths, setShowUserGroupPaths] = useState<boolean>(false);

	const [reviewASDrawerVisibility, setReviewASDrawerVisibility] =
		useState(false);
	const [reviewBRDrawerVisibility, setReviewBRDrawerVisibility] =
		useState(false);

	const reviewAttackSurface = ({ data, unreviewed }: ReviewDetailsInt) => {
		const activeTab =
			data?.name === "ports" ? Direction.Inbound : Direction.Outbound;
		setActiveTab(activeTab);
		setShowUnreviewedFacet(unreviewed);
		setReviewASDrawerVisibility(true);
	};

	const reviewBlastRadius = ({ data, unreviewed }: ReviewDetailsInt) => {
		const activeTab =
			data?.name === "ports" ? Direction.Inbound : Direction.Outbound;
		setActiveTab(activeTab);
		setShowUserGroupPaths(data?.name === "userPath");
		setShowUnreviewedFacet(unreviewed);
		setReviewBRDrawerVisibility(true);
	};

	const onCloseReviewDrawer = () => {
		setReviewASDrawerVisibility(false);
		setReviewBRDrawerVisibility(false);
	};

	return (
		<AssetSecurityDetailWithoutAggregatedEndpoint
			asset={asset}
			data={data}
			isLoading={isLoading}
			reviewAttackSurface={reviewAttackSurface}
			reviewBlastRadius={reviewBlastRadius}
			reviewASDrawerVisibility={reviewASDrawerVisibility}
			reviewBRDrawerVisibility={reviewBRDrawerVisibility}
			showUnreviewedFacet={showUnreviewedFacet}
			showUserGroupPaths={showUserGroupPaths}
			activeTab={activeTab}
			onCloseReviewDrawer={onCloseReviewDrawer}
		/>
	);
};

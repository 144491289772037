import { Box, Stack, Tooltip } from "@mui/material";
import { PendingWorkRequestsColumnRenderProps } from "modules/PendingWorkRequestsDrawer/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { useWorkRequestFacetStore } from "modules/PendingWorkRequestsDrawer/store/useWorkRequestStore";

export function PendingWorkRequestActor({
	pendingItems,
}: PendingWorkRequestsColumnRenderProps) {
	if (!pendingItems?.subjectEmail || pendingItems.subjectEmail === "NULL") {
		return <Box style={{ minWidth: 310, minHeight: 45 }} />;
	}

	return (
		<Stack
			sx={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
			alignItems="left"
			justifyContent="center"
		>
			<Tooltip
				title={`${window.getCTTranslatedText("Triggered By")}: ${
					pendingItems?.subjectEmail
				}`}
			>
				<CTDatagridButton
					facetName={"actoremail"}
					facetOption={pendingItems.subjectEmail}
					useFacetStore={useWorkRequestFacetStore}
				>
					{pendingItems?.subjectEmail}
				</CTDatagridButton>
			</Tooltip>
		</Stack>
	);
}

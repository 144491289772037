import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { TagPolicy } from "../../types";
import { useTagPolicyStore } from "../../store";
import { Box, Button, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { CreateReportDialog } from "pages/reports/components/create-report-dialog";

export interface TagPolicyToolbarProps extends Omit<ToolbarProps, "children"> {
	selection?: Array<TagPolicy> | undefined;
}

export default function TagPolicyToolbar(props: TagPolicyToolbarProps) {
	const [isCreateReportDrawerOpen, setIsCreateReportDrawerOpen] =
		useState(false);
	const selectedCriteria = useTagPolicyStore(state => state.selectedCriteria);
	const setSelectedCriteria = useTagPolicyStore(
		state => state.setSelectedCriteria
	);
	const requestAPIRefresh = useTagPolicyStore(store => store.requestAPIRefresh);

	useEffect(() => {
		setSelectedCriteria([]);
	}, [setSelectedCriteria]);

	const TooltipText =
		selectedCriteria.length > 5
			? window.getCTTranslatedText("PleaseSelectUpto5SegmentsToCreateAReport")
			: "";

	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}>
			{(selectedCriteria?.length ?? 0) > 0 ? (
				<Tooltip title={TooltipText}>
					<Box>
						<Button
							variant="outlined"
							color="primary"
							disabled={selectedCriteria.length > 5}
							onClick={() => {
								setIsCreateReportDrawerOpen(true);
							}}
							sx={{
								ml: 2,
							}}
						>
							{window.getCTTranslatedText("CreateReport")}
						</Button>
					</Box>
				</Tooltip>
			) : null}
			{isCreateReportDrawerOpen && (
				<CreateReportDialog
					selectedCriteria={selectedCriteria}
					open={isCreateReportDrawerOpen}
					onClose={() => {
						setIsCreateReportDrawerOpen(false);
						setSelectedCriteria([]);
					}}
				/>
			)}
		</GridToolbar>
	);
}

import { Stack, Typography, debounce, useTheme } from "@mui/material";
import { useCommonStore } from "common/store";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	PieChartDatum as ChartDatum,
	DashboardWidget,
} from "@colortokens/ng-ui";
import { SegmentationLevels } from "pages/assets/types";
import { SegmentationProgressChartDialogProps } from "pages/dashboard/types";
import { getChartColor, getLink } from "pages/dashboard/utils";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PieChartGraph } from "../security-posture/SecurityPosture";
import {
	avgDataMapper,
	barChartRequestBody,
} from "../security-progress/helpers";
import { facetMetricsToChartData } from "../security-progress/helpers/facetMetricsToChartData";
import { useSummarizeAPI, useTimeSeriesAPI } from "../security-progress/hooks";
import { SummarizeReqParams } from "../security-progress/hooks/useSummarizeAPI";

export const SegmentationProgressChartWrapper = ({
	type,
}: SegmentationProgressChartDialogProps) => {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const searchCriteria = useCommonStore(state => state.currentSearchCriteria);

	const [segmentationData, setSegmentationData] = useState<
		ChartDatum[] | undefined
	>(undefined);

	const [totalAssetsCount, setTotalAssetsCount] = useState<number>(0);

	const keyMetricsMutation = useSummarizeAPI(type);
	const keyMetricsAvgMutation = useTimeSeriesAPI();

	const keyMetricsMutationObj = useMemo(
		() => debounce(keyMetricsMutation.mutate, 100),
		[keyMetricsMutation.mutate]
	);

	const keyMetricsAvgMutationObj = useMemo(
		() => debounce(keyMetricsAvgMutation.mutate, 100),
		[keyMetricsAvgMutation.mutate]
	);

	useEffect(() => {
		if (type) {
			let criteria = searchCriteria ?? "*";

			const requestObj = barChartRequestBody({
				criteria,
				scope: Scope.Asset,
				groupBy: [],
				statistics: ["count(assetid)"],
			});

			keyMetricsAvgMutationObj(requestObj, {
				onSuccess(data) {
					if (data) {
						const responseData = data;
						const items = responseData?.items ?? {};

						const totalAssets = avgDataMapper({
							items,
							statisticsId: "assetidcount",
						});

						setTotalAssetsCount(totalAssets ?? 0);
					}
				},
				onError: error => {
					setTotalAssetsCount(0);
					setSnackbar(true, SnackBarSeverity.Error, "Failed to fetch Assets");
				},
			});
		}
	}, [keyMetricsAvgMutationObj, searchCriteria, setSnackbar, type]);

	useEffect(() => {
		if (type) {
			let criteria = searchCriteria ?? "*";

			let requestObj: SummarizeReqParams = {
				criteria: criteria,
				scope: Scope.Asset,
				facetField: type,
				facetFieldFilter: "",
			};

			keyMetricsMutationObj(requestObj, {
				onSuccess(data) {
					if (data) {
						const responseData = data;
						const facet = responseData?.Facet ?? {};

						const chartData = facetMetricsToChartData({
							facet: facet?.values ?? {},
							labelMapper: {
								[SegmentationLevels.Tagged]: "Tagged",
								[SegmentationLevels.UnTagged]: "Untagged",
							},
							possibleValues: Object.values(SegmentationLevels),
						});
						setSegmentationData(chartData);
					}
				},
				onError: error => {
					setSegmentationData([]);
					setSnackbar(true, SnackBarSeverity.Error, "Failed to fetch Assets");
				},
			});
		}
	}, [keyMetricsMutationObj, searchCriteria, setSnackbar, type]);

	const chartData = useMemo(() => {
		if (type) {
			return {
				data: segmentationData,
			};
		}
	}, [type, segmentationData]);

	const SEGMENTATION_PRIORITY: { [key: string]: number } = {
		[SegmentationLevels.Tagged]: 0,
		[SegmentationLevels.UnTagged]: 1,
	};

	const theme = useTheme();
	const navigate = useNavigate();

	const onClickDatum = (data: any, type: string) => {
		const link = getLink(data, type);
		if (link) {
			navigate(link);
		}
	};

	const fontFamily = {
		fontFamily: `${theme.typography.body2.fontFamily} !important`,
	};

	return (
		<DashboardWidget
			title={`${window.getCTTranslatedText("Assets")}`}
			isLoading={
				keyMetricsMutation?.isLoading || keyMetricsAvgMutation?.isLoading
			}
			children={
				keyMetricsMutation?.error ? (
					<Stack alignSelf={"center"}>
						<Typography variant="caption">
							{window.getCTTranslatedText("Not data")}
						</Typography>
					</Stack>
				) : (
					<PieChartGraph
						title={`${window.getCTTranslatedText("Assets")}`}
						titleStyle={{
							...fontFamily,
							ml: 1,
							color: theme.palette.text.primary,
						}}
						chartProps={{
							data: chartData?.data ?? [],
							getColor: getChartColor,
						}}
						loading={
							keyMetricsMutation?.isLoading || keyMetricsAvgMutation?.isLoading
						}
						total={totalAssetsCount ?? 0}
						totalTitle={"Assets"}
						priorityMap={SEGMENTATION_PRIORITY}
						onClickDatum={data => onClickDatum(data, "untagged")}
					/>
				)
			}
			showTrend={false}
		/>
	);
};

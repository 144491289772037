import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Stack } from "@mui/material";
import { getRelativeLastObserved, parseErrorMessage } from "common/utils";
import { DRAWER_TITLES, FormFields } from "modules/form-fields";

import { Field, TextFieldsValues } from "modules/form-fields/type";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	processChannelConfig,
	processIntegrationFormatData,
} from "pages/Integration/IntegrationUtils";
import { hasErrors } from "pages/Integration/components/integration-toolbar-action/helper";
import { SessionRefreshLimitOptions } from "pages/Integration/tabs/authentication/components/form-extras-after/constants";
import { useAuthConfigAPI } from "pages/Integration/tabs/authentication/hooks";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import React, { useEffect, useState } from "react";
import {
	INTEGRATIONS,
	INTEGRATION_CATEGORY,
	INTEGRATION_PROVIDERS,
	INTEGRATION_TYPES,
	Integration,
	IntegrationConfigType,
	Reconciliation,
	getIntegrationEnum,
} from "../../constants";
import {
	useIntegrationFieldsAPI,
	useIntegrationGetAPI,
	useTestIntegrationConnection,
} from "../../hooks";
import { FormExtrasAfter } from "../../tabs/authentication/components/form-extras-after";
import { FormExtrasBefore } from "../../tabs/authentication/components/form-extras-before";
import { IntegrationStaticData } from "../integration-static-data";
import { IntegrationToolbarAction } from "../integration-toolbar-action";
import { MappingConfig } from "../serviceNow-config";
import { useIntegrationUpdateConfig } from "./hooks/useIntegrationUpdateConfig";

interface IntegrationDetailsDrawerProps {
	open: boolean;
	onClose: () => void;
	loaderActive: (isLoading: boolean, item: IntegrationConfigType) => void;
	integration: IntegrationConfigType;
}

export const IntegrationDetailsDrawer: React.FC<
	IntegrationDetailsDrawerProps
> = ({ open, onClose, integration, loaderActive }) => {
	const selectedValue = integration;

	const { data: formConfig = [] } = useIntegrationFieldsAPI(
		integration.integrationType,
		integration.integrationProvider
	);

	const { data: fieldData = {}, isLoading: isFieldDataLoading } =
		useIntegrationGetAPI(
			integration.integrationType,
			integration.integrationProvider
		);

	const { data: authConfigData } = useAuthConfigAPI({
		enabled:
			selectedValue.integrationProvider === INTEGRATION_PROVIDERS.SAML ||
			selectedValue.integrationProvider === INTEGRATION_PROVIDERS.OAUTH,
	});

	const configID = authConfigData?.[0]?.configID;

	const [textFieldsValues, setTextFieldsValues] = useState<TextFieldsValues>(
		fieldData.integrationParameters || {} || []
	);
	const [formExtraAfterTextFieldsValues, setFormExtraAfterTextFieldsValues] =
		useState<any>({
			refreshTokenTTL:
				SessionRefreshLimitOptions[SessionRefreshLimitOptions.length - 1].value,
		});
	const [testTriggered, setTestTriggered] = useState<boolean>(false);

	const [mappingData, setMappingData] = useState<Reconciliation>({
		reconciliationIdentifier: [],
		reconciliationAttributes: [],
	});

	const testConfigMutation = useTestIntegrationConnection(
		integration.integrationType,
		integration.integrationProvider
	);

	const { isLoading, updateIntegrationConfig } =
		useIntegrationUpdateConfig(integration);

	const callUpdateConfig = () => {
		updateIntegrationConfig(
			formConfig,
			integration,
			textFieldsValues,
			formExtraAfterTextFieldsValues,
			mappingData,
			onClose
		);
	};

	const setSnackbar = useSnackbarStore((state: any) => state.setSnackbar);
	const [lastSyncTime, setLastSyncTime] = useState<string>("");
	const [integrationLoaded, setIntegrationLoaded] = useState(false);

	useEffect(() => {
		if (configID) {
			setFormExtraAfterTextFieldsValues(authConfigData?.[0]?.config);
		}
	}, [configID, setFormExtraAfterTextFieldsValues, authConfigData]);

	useEffect(() => {
		if (fieldData) {
			if (fieldData.integrationParameters) {
				if (
					getIntegrationEnum(fieldData) === INTEGRATIONS.MSTEAMS_COLLABORATION
				) {
					const channelConfig = processChannelConfig(
						fieldData.integrationParameters.channels
					);
					setTextFieldsValues(channelConfig);
				} else {
					setTextFieldsValues(fieldData.integrationParameters);
					if (getIntegrationEnum(fieldData) === INTEGRATIONS.SERVICENOW_CMDB) {
						const mappingReconciliationData = {
							reconciliationIdentifier:
								fieldData.integrationParameters.reconciliationIdentifier,
							reconciliationAttributes:
								fieldData.integrationParameters.reconciliationAttributes,
						};
						updateMappingData(mappingReconciliationData);
					}
					setIntegrationLoaded(true);
				}
			}
			const lastSyncTime = fieldData.lastSyncTime
				? getRelativeLastObserved(fieldData.lastSyncTime)
				: "";
			setLastSyncTime(lastSyncTime);
		}
	}, [fieldData, integration, integrationLoaded]);

	const testConfig = () => {
		if (textFieldsValues?.status) {
			delete textFieldsValues.status;
		}

		const processData = processIntegrationFormatData(
			integration,
			formConfig,
			textFieldsValues
		);
		const body: Integration = {
			integrationProvider: integration.integrationProvider,
			integrationType: integration.integrationType,
			integrationParameters: processData,
		};

		testConfigMutation.mutateAsync(body, {
			onSuccess: response => {
				setTestTriggered(true);
				const message =
					integration?.integrationType !== INTEGRATION_TYPES.SIEM
						? "integrationConfigurationTestSuccessful"
						: "integrationTestSuccessful";

				const variables = {
					name:
						integration?.integrationType !== INTEGRATION_TYPES.SIEM
							? capitalizeFirstLetter(integration?.name ?? "")
							: integration.displayCardName,
				};
				setSnackbar(true, SnackBarSeverity.Success, message, variables);
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const updateMappingData = (data: Reconciliation) => {
		setMappingData(data);
	};

	const drawerToolbar = () =>
		getIntegrationEnum(integration) !== INTEGRATIONS.VSPHERE_CONNECTORS && (
			<IntegrationToolbarAction
				formConfig={formConfig}
				textFieldsValues={textFieldsValues}
				handlerOnCloseDrawer={onClose}
				updateConfig={callUpdateConfig}
				loading={isLoading}
			/>
		);

	const formExtrasAfter = () => {
		const staticData = formConfig.filter(
			(item: Field) => item.type === "static"
		);
		switch (getIntegrationEnum(integration)) {
			case INTEGRATIONS.CLAROTY_CMDB:
			case INTEGRATIONS.MEDIGATE_CMDB:
			case INTEGRATIONS.ARMIS_CMDB:
			case INTEGRATIONS.RAPID7_VULNERABILITYSCANNER:
			case INTEGRATIONS.SPLUNK_SIEM:
			case INTEGRATIONS.QRADAR_SIEM:
			case INTEGRATIONS.OTHERS_SIEM:
			case INTEGRATIONS.ARCSIGHT_SIEM:
			case INTEGRATIONS.SERVICENOW_CMDB:
			case INTEGRATIONS.NESSUS_VULNERABILITYSCANNER:
				return (
					<>
						<Stack direction="row" justifyContent="flex-end">
							<LoadingButton
								loading={testConfigMutation.isLoading}
								variant="contained"
								type="submit"
								disabled={hasErrors(textFieldsValues, formConfig)}
								onClick={() => testConfig()}
								sx={{ ml: 1, mt: 2 }}
							>
								{window.getCTTranslatedText("Test")}
							</LoadingButton>
						</Stack>
						{getIntegrationEnum(integration) ===
							INTEGRATIONS.SERVICENOW_CMDB && (
							<MappingConfig
								formConfig={formConfig}
								integration={integration}
								textFieldsValues={textFieldsValues}
								fieldData={fieldData}
								isFieldDataLoading={isFieldDataLoading}
								testTriggered={testTriggered}
								setTestTriggered={setTestTriggered}
								updateMappingData={updateMappingData}
							></MappingConfig>
						)}
						{staticData.length > 0 && (
							<IntegrationStaticData
								field={staticData[0]}
								integration={selectedValue}
							></IntegrationStaticData>
						)}
					</>
				);
			case INTEGRATIONS.MSTEAMS_COLLABORATION:
			case INTEGRATIONS.CROWDSTRIKE_EDR:
				return (
					<Stack direction="row" justifyContent="flex-end">
						<LoadingButton
							loading={testConfigMutation.isLoading}
							variant="contained"
							type="submit"
							disabled={hasErrors(textFieldsValues, formConfig)}
							onClick={() => testConfig()}
							sx={{ ml: 1, mt: 2 }}
						>
							{window.getCTTranslatedText("Test")}
						</LoadingButton>
					</Stack>
				);

			case INTEGRATIONS.OAUTH_IDENTITYPROVIDER:
			case INTEGRATIONS.SAML_IDENTITYPROVIDER:
				return (
					<FormExtrasAfter
						selectedValue={selectedValue}
						setTextFieldsValues={setFormExtraAfterTextFieldsValues}
						textFieldsValues={formExtraAfterTextFieldsValues}
					/>
				);
			case INTEGRATIONS.VSPHERE_CONNECTORS:
				return (
					<>
						{staticData.length > 0 && (
							<IntegrationStaticData
								field={staticData[0]}
								integration={selectedValue}
								showHeading={false}
							/>
						)}
					</>
				);
			default:
				return <></>;
		}
	};
	const formExtrasBefore = () => {
		switch (getIntegrationEnum(integration)) {
			case INTEGRATIONS.OAUTH_IDENTITYPROVIDER:
			case INTEGRATIONS.SAML_IDENTITYPROVIDER:
				return <FormExtrasBefore selectedValue={selectedValue} />;
			case INTEGRATIONS.SPLUNK_SIEM:
			case INTEGRATIONS.ARCSIGHT_SIEM:
			case INTEGRATIONS.QRADAR_SIEM:
			case INTEGRATIONS.OTHERS_SIEM:
				if (lastSyncTime) {
					return (
						<Alert severity="info" sx={{ my: 3 }}>
							{window.getCTTranslatedText("lastSyncedAt", {
								lastSyncedAt: lastSyncTime,
							})}
						</Alert>
					);
				} else {
					return <></>;
				}
			case INTEGRATIONS.MSTEAMS_COLLABORATION: {
				const channels = fieldData?.integrationParameters?.channels;
				if (channels) {
					const { channelName1, channelName2, channelName3 } =
						processChannelConfig(channels);
					const hasChannelNameChanged =
						(channelName1 && textFieldsValues.channelName1 !== channelName1) ||
						(channelName2 && textFieldsValues.channelName2 !== channelName2) ||
						(channelName3 && textFieldsValues.channelName3 !== channelName3);

					return hasChannelNameChanged ? (
						<Alert severity="error" sx={{ mt: 2 }}>
							{window.getCTTranslatedText("teamsErrorMessage")}
						</Alert>
					) : null;
				}
				return null;
			}

			case INTEGRATIONS.CROWDSTRIKE_EDR:
				return (
					<Alert severity="info" sx={{ mt: 2 }}>
						{window.getCTTranslatedText("SavingConfigurationImportsHostGroups")}
					</Alert>
				);
			default:
				return <></>;
		}
	};
	const getDialogTitle = () => {
		if (selectedValue.category === INTEGRATION_CATEGORY.SIEM) {
			return selectedValue?.displayCardName ?? "";
		}
		return selectedValue?.name ?? "";
	};

	const getTitleTemplate = () => {
		if (getIntegrationEnum(selectedValue) === INTEGRATIONS.VSPHERE_CONNECTORS) {
			return DRAWER_TITLES.CONFIGURE_AND_INSTALL;
		}
		return DRAWER_TITLES.CONFIGURE;
	};

	return (
		<FormFields
			textFieldsValues={textFieldsValues}
			setTextFieldsValues={setTextFieldsValues}
			formConfig={formConfig ?? []}
			key={selectedValue?.name}
			isDrawer={true}
			dialogTitle={getDialogTitle()}
			titleTemplate={getTitleTemplate()}
			onClose={onClose}
			drawerToolbar={drawerToolbar}
			formExtrasAfter={formExtrasAfter}
			formExtrasBefore={formExtrasBefore}
		/>
	);
};

import { Stack } from "@mui/material";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { AgentDownload } from "pages/agents/components/agent-downlaod";
import { AGENT_TYPES } from "pages/agents/components/agent-download-page/constants";
import { useContainerStore } from "pages/containers/store";
import { ROUTES } from "routes";

export interface ClusterToolbarProps extends Omit<ToolbarProps, "children"> {
	hideToolbar: Function;
}

export function ContainersToolbar(props: ClusterToolbarProps) {
	const route = ROUTES.INSTALL_COLLECTOR.pathname;
	const agentType = AGENT_TYPES.CT_CONTAINER_AGENT;
	const requestAPIRefresh = useContainerStore(store => store.requestAPIRefresh);
	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}>
			<Stack
				direction="row"
				spacing={3}
				sx={{ width: "100%" }}
				justifyItems="flex-end"
				alignItems={"flex-end"}
				justifyContent="flex-end"
			>
				<AgentDownload agentType={agentType} route={route} />
			</Stack>
		</GridToolbar>
	);
}

export interface AlertRuleConfig {
	name: string;
	description: string;
	alerttype: string;
	alertseverity: string;
	creationtime: string;
	uirulecriteria: string;
	uidismisscriteria: string;
	alertRuleStatus: AlertRuleStatus;
	alertRuleId: string;
	alertRuleName: string;
	configuration: string;
	notificationStatus: string;
	recipientConfigurationStatus: string;
}

export enum AlertRuleStatus {
	Enabled = "Enabled",
	Disabled = "Disabled",
	Snoozed = "Snoozed",
}

export interface AlertColumnRenderProps {
	alertRule: AlertRuleConfig;
	viewOnly?: boolean;
}
export enum AlertRuleConfigurationType {
	Preconfigured = "Preconfigured",
	Userconfigured = "UserConfigured",
}

export enum AlertNotificationStatus {
	On = "On",
	Off = "Off",
	Configured = "Configured",
	Notconfigured = "Not Configured",
	Enabled = "Enabled",
	Disabled = "Disabled",
}

import { Box, Stack } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { IdP } from "pages/Integration/components/scim-management-config/types";
import { IDPToolbar } from "../IDPToolbar";
import { BASE_IDP_COLUMNS, IDP_COLUMNS } from "./../constants";

export const IDPDataGrid = (props: DataGridProps<IdP>) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	return (
		<Stack sx={{ width: "100%", height: "300px", overflow: "hidden" }}>
			<IDPToolbar />
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					rowHeight={64}
					columns={
						userPermissions.has("READ_SCIM_TOKEN") &&
						userPermissions.has("ADD_IDP_INFO") &&
						userPermissions.has("UPDATE_IDP_INFO")
							? IDP_COLUMNS
							: BASE_IDP_COLUMNS
					}
					pagination
					getRowId={(idp: IdP) => `${idp.idpID}`}
					paginationMode="server"
					sortingMode="server"
					noRowsOverlayText="NoIDPsAdded"
					{...props}
				/>
			</Box>
		</Stack>
	);
};

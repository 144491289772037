import { Box, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { useCallback, useMemo, useState } from "react";
import { AlertRuleConfig } from "../../types";
import { AlertConfigToolbar } from "../alert-config-data-grid-toolbar";
import {
	ALERTCONFIG_COLUMNS,
	AlertConfigColumnConfig,
	BASE_ALERTCONFIG_COLUMNS,
} from "./constants";

export const AlertConfigDataGrid = (
	props: DataGridProps<AlertRuleConfig> & {}
) => {
	const [selection, setSelection] = useState<Array<GridRowId>>([]);
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const selectedData: Array<AlertRuleConfig> | undefined = useMemo(() => {
		return props.rows?.filter((row: AlertRuleConfig) => {
			return selection.indexOf(row.alertRuleId) !== -1;
		});
	}, [selection, props.rows]);

	const selectedRawData: Array<AlertRuleConfig> | undefined = useMemo(() => {
		return (props?.rawData ?? [])?.filter((row: AlertRuleConfig) => {
			return selection.indexOf(row.alertRuleId) !== -1;
		});
	}, [selection, props?.rawData]);

	const onClickClearSelection = () => {
		setSelection([]);
	};

	const renderToolbar = useCallback(
		(children?: React.ReactNode) => (
			<AlertConfigToolbar
				hideToolbar={onClickClearSelection}
				show={true}
				selectedData={selectedData}
				hiddenColumns={children}
			/>
		),
		[selectedData]
	);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflowY: "auto" }}>
				<DataGrid
					columnVisibilityModel={AlertConfigColumnConfig.ColumnVisibilityModel}
					defaultPinnedColumns={AlertConfigColumnConfig.PinnedColumns}
					rowHeight={64}
					columns={
						userPermissions.has("UPDATE_ALERTRULES")
							? ALERTCONFIG_COLUMNS
							: BASE_ALERTCONFIG_COLUMNS
					}
					pagination={true}
					getRowId={(alert: AlertRuleConfig) => `${alert.alertRuleId}`}
					paginationMode="server"
					sortingMode="server"
					checkboxSelection={userPermissions.has("UPDATE_ALERTRULES")}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						setSelection(selectionModel);
					}}
					initialState={{
						sorting: {
							sortModel: [
								{ field: "severity", sort: "desc" },
								{ field: "alertRuleStatus", sort: "desc" },
							],
						},
					}}
					selectedRawData={selectedRawData}
					{...props}
					renderToolbar={renderToolbar}
				/>
			</Box>
		</Stack>
	);
};

import { useEffect, useState } from "react";
import { DepKeyItem } from "../../agent-downlaod-version/useDepKeyAPI";
import {
	useDepKeyCreateAPI,
	useDepKeyQueryAPI,
} from "../../agent-downlaod-version";

function compare(a: DepKeyItem, b: DepKeyItem) {
	if (a.lastAccess < b.lastAccess) {
		return 1;
	} else if (a.lastAccess > b.lastAccess) {
		return -1;
	}
	return 0;
}

export function useDepKey() {
	const { data: depKeyData, isSuccess: isDepKeySuccess } = useDepKeyQueryAPI();
	const depKeyMutation = useDepKeyCreateAPI();
	const createDepKey = depKeyMutation.mutateAsync;
	const [depKey, setDepKey] = useState<string | undefined>();
	useEffect(() => {
		if (isDepKeySuccess && !depKey) {
			const latestDepKey = depKeyData?.items?.sort(compare)[0]?.key;
			setDepKey(latestDepKey);

			if (!latestDepKey) {
				createDepKey({}).then(newKey => setDepKey(newKey.key));
			}
		}
	}, [depKey, isDepKeySuccess, depKeyData, createDepKey]);
	return { depKeyData, depKey };
}

import { Field, FieldType, TextFieldsValues } from "modules/form-fields/type";
import {
	INTEGRATION_CATEGORY,
	INTEGRATION_TYPES,
	IntegrationConfigType,
	TEAMS_CONFIG,
	TeamsChannel,
	TeamsChannelData,
} from "./constants";

export const getIntegrationGroupedData = (
	integrationConfigData: IntegrationConfigType[]
) => {
	const groupedData: { [category: string]: IntegrationConfigType[] } = {};
	integrationConfigData.forEach((item: IntegrationConfigType) => {
		if (!groupedData[item.category]) {
			groupedData[item.category] = [];
		}
		groupedData[item.category].push(item);
	});

	const updateDisableProperty = () => {
		for (const category in groupedData) {
			const categoryData = groupedData[category];
			const hasActiveIntegration = categoryData.some(
				(item: IntegrationConfigType) => item.status === true
			);
			categoryData.forEach((item: IntegrationConfigType) => {
				item.disable = hasActiveIntegration && !item.status;
			});
		}
	};

	updateDisableProperty();

	return groupedData;
};

export const filteredIntegrationData = (
	selectedCategory: string,
	integrationConfigData: IntegrationConfigType[]
) => {
	let filteredData: IntegrationConfigType[] = [];
	filteredData =
		selectedCategory === INTEGRATION_CATEGORY.ALL
			? integrationConfigData
			: integrationConfigData.filter(
					(item: IntegrationConfigType) => item.category === selectedCategory
				);

	filteredData = filteredData.sort(function (a, b) {
		const nameA = a.name?.toUpperCase();
		const nameB = b.name?.toUpperCase();
		if (
			a.category === INTEGRATION_CATEGORY.SIEM ||
			b.category === INTEGRATION_CATEGORY.SIEM
		) {
			if (nameA < nameB) {
				return 1;
			}
			if (nameA > nameB) {
				return -1;
			}
		}
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}

		return 0;
	});
	return filteredData;
};

export const getSortedCategories = (
	integrationConfigData: IntegrationConfigType[]
) => {
	const categories: string[] = Array.from(
		new Set(
			integrationConfigData.map((item: IntegrationConfigType) => item.category)
		)
	);

	return categories.slice().sort((a, b) => a.localeCompare(b));
};

export const processIntegrationFormatData = (
	integration: IntegrationConfigType,
	formConfig: Array<Field>,
	textFieldsValues: TextFieldsValues
) => {
	if (integration.integrationType === INTEGRATION_TYPES.COLLABORATION) {
		let data: TeamsChannelData = { channels: [] };

		const processChannelConfig = (input: { [key: string]: string }) => {
			const channels = Object.entries(input)
				.filter(([key]) => key.startsWith(TEAMS_CONFIG.CHANNEL_NAME))
				.reduce((teamsConfig, [key, channelName]) => {
					const channelIndex = key.replace(TEAMS_CONFIG.CHANNEL_NAME, "");
					const webhookUrl =
						input[`${TEAMS_CONFIG.WEBHOOK_URL}${channelIndex}`];

					if (channelName && webhookUrl) {
						teamsConfig.push({ channelName, webhookUrl });
					}

					return teamsConfig;
				}, [] as TeamsChannel[]);

			return { channels };
		};
		data = processChannelConfig(textFieldsValues);
		return data;
	}

	if (integration.integrationType === INTEGRATION_TYPES.SIEM) {
		const data: any = {};
		data.siemType = integration.name;
		const processField = (field: Field) => {
			data[field.key] = textFieldsValues[field.key];

			if (field.children?.length) {
				field.children.forEach((child: any) => {
					processField(child);
				});
			}
		};

		formConfig.forEach((obj: Field) => {
			if (obj?.type === FieldType.DROPDOWN && obj?.possibleValues?.length) {
				const value = textFieldsValues[obj.key];
				data[obj.key] = textFieldsValues[obj.key];

				obj.possibleValues.forEach((possibleValue: any) => {
					if (possibleValue.key === value && possibleValue.children?.length) {
						possibleValue.children.forEach((child: any) => {
							processField(child);
						});
					}
				});
			} else if (obj?.type === FieldType.SECTION && obj?.children?.length) {
				obj.children.forEach((child: any) => {
					processField(child);
				});
			} else if (
				obj?.type === FieldType.TEXT ||
				obj?.type === FieldType.PASSWORD ||
				obj?.type === FieldType.CHECKBOX
			) {
				data[obj?.key] = textFieldsValues[obj?.key];
			}
		});
		return data;
	}

	return textFieldsValues;
};

export const processChannelConfig = (channels: any) => {
	const channelConfig: { [key: string]: string } = {};
	channels?.forEach(
		(channel: { channelName: string; webhookUrl: string }, index: number) => {
			channelConfig[`${TEAMS_CONFIG.CHANNEL_NAME}${index + 1}`] =
				channel.channelName;
			channelConfig[`${TEAMS_CONFIG.WEBHOOK_URL}${index + 1}`] =
				channel.webhookUrl;
		}
	);
	return channelConfig;
};

import { CoreStoreBuilder } from "modules/core/store";

import { CustomCommonStoreType } from "common/types/types";
import { FacetStoreBuilder } from "modules/facets";
import { FacetStore } from "modules/facets/types";
import { Scope } from "modules/scope-metadata/types";
import { create } from "zustand";
import { UsergroupStore } from "./types";

export const useUsergroupStore = create<UsergroupStore>()(set => ({
	...CoreStoreBuilder(set),
}));

export const useUsergroupsFacetStore: CustomCommonStoreType =
	create<FacetStore>()(set => ({
		...FacetStoreBuilder(set),
		scope: Scope.Network,
	}));

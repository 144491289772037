import {
	MutationOptions,
	QueryClient as ReactQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { AuthError, AuthState } from "hooks/useAuthState";
import { axiosInstance } from "./axiosInstance";
import {
	API_URL,
	EXTENDED_TIMEOUT,
	EXTENDED_TIMEOUT_API_PATH_LIST,
	WORK_REQUEST_UPDATED_EVENT,
} from "./constants";

type ConfigOptions =
	| {
			headers: undefined | { [key: string]: string | number | boolean };
	  }
	| undefined;

const event = new Event(WORK_REQUEST_UPDATED_EVENT);

export const queryClient = (checkAuthState: Function) => {
	const defaultHandler = async (execute: () => Promise<any>) => {
		try {
			return await execute();
		} catch (e: any) {
			if (axios.isCancel(e)) {
				return;
			}
			if (e?.response?.status === 304) {
				return Promise.resolve();
			}
			if (e?.response?.status === 401) {
				// trying auth
				try {
					let authState = await checkAuthState();
					if (authState === AuthState.LoggedIn) {
						// we refreshed token
						return execute();
					} else {
						throw new AuthError(); // No more retries
					}
				} catch (e) {
					throw e; // We might need to retry
				}
			}
			throw e;
		}
	};

	const defaultQueryFn = async (args: any) => {
		const executor = () => {
			const { queryKey, signal } = args;
			return axiosInstance
				.get(`${API_URL}/${queryKey[1]}`, {
					signal,
				})
				.then(response => response.data);
		};
		return defaultHandler(executor);
	};

	const defaultMutationFn = function (this: MutationOptions, args: any) {
		const getResponseData = (response: AxiosResponse<any, any>) => {
			if (response.status === 202) {
				window.dispatchEvent(event);
			}
			return response.data;
		};

		const executor = function (this: MutationOptions) {
			let [, path, method, configOptions] = this.mutationKey as [
				string,
				string,
				string,
				ConfigOptions,
			];
			method = method || "post";

			let url = `${API_URL}/${path}`;

			const variables = this.variables;

			let callFn = axiosInstance.post.bind(axiosInstance);

			let config: AxiosRequestConfig | undefined = undefined;

			if (EXTENDED_TIMEOUT_API_PATH_LIST.some(p => path.includes(p))) {
				config = {
					timeout: EXTENDED_TIMEOUT,
				};
			}

			if (method.toLocaleLowerCase() === "put") {
				callFn = axiosInstance.put.bind(axiosInstance);
			}

			if (configOptions?.headers) {
				config = {
					...(config ?? {}),
					headers: configOptions.headers,
				};
			}

			// @ts-ignore: custom handling
			if (variables?._download) {
				let downloadURL = new URL(url, window.origin);
				downloadURL.searchParams.append("download", "true");
				url = downloadURL.toString();
				config = {
					responseType: "blob",
				};
				config.headers = {
					...config.headers,
					Accept: "text/csv",
				};
				// @ts-ignore: custom handling
				delete variables._download;
			}

			if (method.toLocaleLowerCase() === "delete") {
				return axiosInstance
					.delete(url, { ...config, data: variables })
					.then(getResponseData);
			}

			return callFn(url, variables, config).then(getResponseData);
		};
		return defaultHandler(executor.bind(this));
	};

	const retryConfig = (failureCount: any, error: any) => {
		if (
			failureCount > 1 ||
			// @ts-ignore: Auth error type
			error?.name === AuthError.name ||
			error?.response?.status === 400 ||
			error?.response?.status === 404 ||
			error?.response?.status === 403 ||
			error?.response?.status === 409 ||
			// @ts-ignore: Axios error type
			error?.code === "ECONNABORTED"
		) {
			return false;
		}
		return true;
	};
	return new ReactQueryClient({
		defaultOptions: {
			queries: {
				queryFn: defaultQueryFn,
				retry: retryConfig,
			},
			mutations: {
				mutationFn: defaultMutationFn,
				retry: retryConfig,
			},
		},
	});
};

import { Stack, Typography } from "@mui/material";
import { getLocalDate } from "common/utils/getDate";
import { PendingWorkRequestsColumnRenderProps } from "modules/PendingWorkRequestsDrawer/types";

export const PendingWorkRequestTime = (
	props: PendingWorkRequestsColumnRenderProps
) => {
	const { pendingItems } = props;

	const time = getLocalDate(pendingItems?.createdAt);

	return (
		<Stack
			alignItems="left"
			sx={{
				height: "100%",
				justifyContent: "space-evenly",
				width: "100%",
				overflow: "hidden",
				textOverflow: "ellipsis",
			}}
		>
			<Typography variant="body2" noWrap>
				{time}
			</Typography>
		</Stack>
	);
};

import { Grid, Paper, Stack } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { CoreResponse } from "modules/core/types";
import { FacetControllerGroup } from "modules/facets";
import { Scope, ScopeMetadata } from "modules/scope-metadata/types";
import DisabledTooltipButton from "./components/DisabledTooltipButton";
import FirewallHostGroupsDataGrid from "./components/FirewallHostGroupsDataGrid";
import { FIREWALL_HOST_GROUPS_PERMISSION } from "./constants";
import { FirewallHostGroupListItem } from "./types";

interface Props {
	coreResponse: CoreResponse<FirewallHostGroupListItem>;
}

function Listing({ coreResponse }: Props) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const isEditDisabled = !userPermissions.has(
		FIREWALL_HOST_GROUPS_PERMISSION.EDIT
	);
	const isCreateDisabled = !userPermissions.has(
		FIREWALL_HOST_GROUPS_PERMISSION.CREATE
	);

	const rows =
		coreResponse.rows === undefined
			? []
			: coreResponse.rows.map((obj, i) => ({ ...obj, precedence: i + 1 }));

	const metadata = {
		...(coreResponse.metadata ?? {}),
		scope: Scope.CrowdStrikeFirewallHostGroup,
	} as ScopeMetadata;

	return (
		<>
			<Grid item xs={6}>
				<Stack direction="row" justifyContent="flex-end" gap={2}>
					<DisabledTooltipButton
						variant="outlined"
						link="./edit-firewall-host-groups"
						disabled={isEditDisabled}
						text="Edit Precedence"
					/>
					<DisabledTooltipButton
						variant="contained"
						link="./create-firewall-host-group"
						text="Create New"
						disabled={isCreateDisabled}
					/>
				</Stack>
			</Grid>
			<Grid item xs={12} sx={{ "&&": { height: "100%" } }}>
				<Stack direction="row" mb={4}>
					<Stack
						sx={{ flex: 1, "& .saved-query-container": { display: "none" } }}
					>
						<FacetOpenCloseController
							isDisabled
							facetsOpen={coreResponse.facetsOpen}
							setFacetsOpen={coreResponse.setFacetsOpen}
						>
							<FacetControllerGroup
								config={coreResponse.facetConfig}
								value={coreResponse.facetState}
								onChange={coreResponse.updateFacet}
							/>
						</FacetOpenCloseController>
					</Stack>
				</Stack>

				<Paper
					component={Stack}
					sx={{
						"& > .MuiBox-root": { flexGrow: "1" },
						"& .MuiDataGrid-virtualScroller": { minHeight: "initial" },
						"& .MuiDataGrid-pinnedColumnHeaders--right:last-of-type": {
							paddingRight: "0 !important",
						},
					}}
				>
					<FirewallHostGroupsDataGrid
						rows={rows}
						isLoading={coreResponse.mutation.isLoading}
						metadata={metadata}
						showDelete
					/>
				</Paper>
			</Grid>
		</>
	);
}

export default Listing;

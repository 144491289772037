import { Box, Stack } from "@mui/material";
import { AssetColumnRenderProps } from "pages/assets/types";
import { AssetSecurityLevelIcons } from "../asset-security-level-icons";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function AssetBlastRadius({ asset, viewOnly }: AssetColumnRenderProps) {
	if (!asset?.assetId) {
		return <Box style={{ width: 70 }} />;
	}

	return (
		<Stack
			sx={{ width: !viewOnly ? "100%" : undefined }}
			direction={viewOnly ? "row" : "column"}
			alignItems={viewOnly ? "center" : undefined}
		>
			<CTDatagridButton
				isDisabled={viewOnly === true}
				asText={viewOnly === true}
				facetName={"blastradius"}
				facetOption={asset?.blastRadius}
				startIcon={
					<AssetSecurityLevelIcons securityLevel={asset?.blastRadius} />
				}
			>
				{window.getCTTranslatedText(asset?.blastRadius)}
			</CTDatagridButton>
		</Stack>
	);
}

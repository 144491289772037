import produce from "immer";
import { FacetGroupInfo } from "modules/core/types";
import { Scope } from "modules/scope-metadata/types";
// import { COMMON_FACETS } from "../constants";
import { FacetUtils } from "../FacetUtils";
import { FacetChange, FacetCommit, FacetState, FacetStore } from "../types";

type Setter = (
	partial:
		| FacetStore
		| Partial<FacetStore>
		| ((state: FacetStore) => FacetStore | Partial<FacetStore>),
	replace?: boolean | undefined
) => void;

const initialState = {
	scope: undefined,
	lastInteractedFacetName: null,
	metadata: undefined,
	facetConfig: undefined,
	currentSearchCriteria: "*",
	openFacets: new Map(),
	facetGroupInfo: {},
};

export default function FacetStoreBuilder(set: Setter): FacetStore {
	return {
		...initialState,
		scope: undefined,
		setScope: (scope: Scope) => {
			set(() => ({ scope }));
		},
		currentSearchCriteria: "*",
		updateCurrentSearchCriteria: (criteria: string) => {
			set(() => ({
				currentSearchCriteria: criteria,
			}));
		},
		metadata: undefined,
		updateMetadata: metadata =>
			set(state => {
				if (state.serverfacetsConfig && metadata) {
					return {
						metadata,
						serverfacetsConfig: null,
						facetConfig: FacetUtils.getFacetConfigFromServerValues(
							state.serverfacetsConfig,
							state.facetConfig,
							state.lastInteractedFacetName,
							metadata!,
							state.facetGroupInfo,
							state.facets
						),
					};
				}
				return { metadata };
			}),
		lastInteractedFacetName: null,
		clearLastInteractedFacet: () => {
			set(() => ({
				lastInteractedFacetName: null,
			}));
		},
		facets: undefined,
		clear: (keepTags = false, keepMetadataAndFacetInfo = false) => {
			set(state => {
				let base = keepMetadataAndFacetInfo ? state : initialState;

				if (!state.facets) {
					return {
						...base,
					};
				}

				const newFacets = produce(state.facets, draft => {
					if (!keepTags) {
						return new Map();
					} else {
						return draft;
					}
				});

				const newOpenFacets = new Map(state.openFacets);
				return {
					...base,
					facets: newFacets,
					openFacets: newOpenFacets,
				};
			});
		},
		setFacets: (
			facetState: FacetState | ((prev: FacetState) => FacetState)
		) => {
			set(state => {
				if (typeof facetState === "function") {
					facetState = facetState(state.facets || new Map());
				}

				return { facets: facetState };
			});
		},
		updateFacet: (commit: FacetCommit) => {
			set(state => ({
				facets: FacetUtils.updateFacet(state.facets, commit),
			}));
		},
		updateFacetOption: (commit: FacetChange) => {
			set(state => ({
				facets: FacetUtils.handleFacetOptionChange(state.facets, commit),
			}));
		},
		serverfacetsConfig: null,
		facetConfig: [],
		updateServerFacetOptions: (serverFacet, considerHasMore) => {
			set(state => {
				return {
					facetConfig: FacetUtils.updateServerFacetOptions(
						serverFacet,
						considerHasMore,
						state.facetConfig,
						state.metadata!
					),
				};
			});
		},
		setFacetConfig: serverFacets => {
			set(state => {
				if (!state.metadata) {
					return { serverfacetsConfig: serverFacets };
				}
				return {
					serverfacetsConfig: null,
					facetConfig: FacetUtils.getFacetConfigFromServerValues(
						serverFacets,
						state.facetConfig,
						state.lastInteractedFacetName,
						state.metadata!,
						state.facetGroupInfo,
						state.facets
					),
				};
			});
		},
		openFacets: new Map(),
		setOpenFacets: (openFacets: Array<string>) => {
			set(state => {
				let newState = new Map(state.openFacets);
				for (let facet of openFacets) {
					newState.set(facet, true);
				}
				return {
					openFacets: newState,
				};
			});
		},
		closeAllFacets: (forceClose: boolean = false) => {
			set(state => {
				let newState = new Map();
				if (!forceClose && state.lastInteractedFacetName) {
					newState.set(state.lastInteractedFacetName, true);
				}
				return {
					openFacets: newState,
				};
			});
		},
		toggleFacetOpen: (name: string) => {
			set(state => {
				let newState = new Map(state.openFacets);
				let newValue = !(state.openFacets.get(name) || false);
				if (newValue) {
					newState.set(name, newValue);
				} else {
					newState.delete(name);
				}
				return {
					openFacets: newState,
				};
			});
		},
		setFacetGroupInfo: (order: FacetGroupInfo) => {
			set(state => {
				if (state.metadata) {
					return {
						...state,
						facetGroupInfo: order,
						serverfacetsConfig: null,
						facetConfig: FacetUtils.getFacetConfigFromServerValues(
							state.serverfacetsConfig,
							state.facetConfig,
							state.lastInteractedFacetName,
							state.metadata,
							order,
							state.facets
						),
					};
				}

				return { facetGroupInfo: order };
			});
		},
		setExternalCriteria: (c: string | undefined) => {
			set(() => ({ externalCriteria: c }));
		},
	};
}

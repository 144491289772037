import { Dimension } from "modules/hierarchy-vis/types";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { useEffect, useRef } from "react";
import { useVisxStore } from "../store";
import {
	CTNodeType,
	GroupNodeDataType,
	PathReviewStatus,
	StatisticType,
	TypeDimension,
} from "../types";
import {
	createParentCriteriaJoins,
	getCriteriaForNode,
	isSubnet,
	joinCriterias,
	withHub,
	withoutHub,
} from "../visx-utils";
import { HUB_PARENT } from "./useTrafficData";
import { DEFAULT_HUB_CRITERIA } from "./useVisualizerCriteriaBuilder";
import { useVisualizerData } from "./useVisualizerData";

export function useNodeDataLoadApis({
	data,
	mainDimension,
	selectedDimension,
	nodeType,
}: {
	data: GroupNodeDataType;
	mainDimension?: Dimension;
	selectedDimension: Dimension | undefined;
	nodeType: CTNodeType;
}) {
	const destinationCriteria = useVisxStore(state => state.destinationCriteria);
	const sourceCriteria = useVisxStore(state => state.sourceCriteria);

	const trafficCriteria = useVisxStore(state => state.trafficCriteria);
	const selectedDirection = useVisxStore(state => state.selectedDirection);

	const changeParentChildRelationShip = useVisxStore(
		state => state.changeParentChildRelationship
	);

	let criteria = "";
	let outwardsSourceCriteria;
	let outwardsDestinationCriteria;

	let inwardsSourceCriteria;
	let inwardsDestinationCriteria;

	let outwardsDestinationDimension = mainDimension;
	let outwardsSourceDimension = selectedDimension;

	let inwardsSourceDimension: Dimension | undefined = mainDimension;
	let inwardsDestinationDimension: Dimension | undefined = selectedDimension;

	const hubCriteria =
		selectedDirection === Direction.Inbound
			? destinationCriteria
			: sourceCriteria;

	if (hubCriteria !== DEFAULT_HUB_CRITERIA) {
		if (selectedDirection === Direction.Inbound) {
			inwardsSourceDimension = TypeDimension;
			inwardsDestinationDimension = selectedDimension;
		} else {
			outwardsDestinationDimension = TypeDimension;
			outwardsSourceDimension = selectedDimension;
		}
	}

	if (selectedDimension) {
		criteria = getCriteriaForNode(data.id, data.dimension || mainDimension);

		let parentJoins = createParentCriteriaJoins(data.trafficData);

		if (parentJoins.length) {
			criteria = joinCriterias([criteria, ...parentJoins]);
		}
		criteria = `${criteria}`;

		if (data.type === CTNodeType.HUB) {
			criteria = withHub(criteria, hubCriteria);
		}

		let filteredSourceCriteria = sourceCriteria;
		let filteredDestinationCriteria = destinationCriteria;

		if (selectedDirection === Direction.Inbound) {
			filteredSourceCriteria = criteria;
			if (
				data.type !== CTNodeType.HUB &&
				selectedDimension.name !== "namednetworkname" &&
				!isSubnet(selectedDimension.name)
			) {
				filteredSourceCriteria = withoutHub(
					filteredSourceCriteria,
					hubCriteria
				);
			}
		} else {
			filteredDestinationCriteria = criteria;

			if (
				data.type !== CTNodeType.HUB &&
				selectedDimension.name !== "namednetworkname" &&
				!isSubnet(selectedDimension.name)
			) {
				filteredDestinationCriteria = withoutHub(
					filteredDestinationCriteria,
					hubCriteria
				);
			}
		}

		if (selectedDirection === Direction.Inbound) {
			outwardsSourceCriteria = filteredSourceCriteria;
			outwardsDestinationCriteria = filteredDestinationCriteria;

			if (criteria === destinationCriteria && selectedDimension) {
				outwardsDestinationCriteria = undefined;
				outwardsSourceCriteria = undefined;
			}

			inwardsDestinationCriteria = filteredSourceCriteria;
			inwardsSourceCriteria = sourceCriteria;
		} else {
			outwardsSourceCriteria = filteredDestinationCriteria;
			outwardsDestinationCriteria = destinationCriteria;

			inwardsDestinationCriteria = filteredDestinationCriteria;
			inwardsSourceCriteria = filteredSourceCriteria;

			if (criteria === sourceCriteria && selectedDimension) {
				inwardsDestinationCriteria = undefined;
				inwardsSourceCriteria = undefined;
			}
		}
	}

	const includeHubParent = hubCriteria !== DEFAULT_HUB_CRITERIA;
	const hubParent = includeHubParent ? HUB_PARENT : undefined;

	const { isLoading: outwardsLoading, trafficData: outwardsTrafficData } =
		useVisualizerData({
			destinationCriteria: outwardsDestinationCriteria,
			sourceCriteria: outwardsSourceCriteria,
			trafficCriteria,
			selectedDestinationDimension:
				nodeType !== CTNodeType.HUB && selectedDirection === Direction.Outbound
					? undefined
					: outwardsDestinationDimension,
			selectedSourceDimension:
				nodeType !== CTNodeType.HUB && selectedDirection === Direction.Outbound
					? undefined
					: outwardsSourceDimension,
			tag: `outwards-${data.label}`,
			topParent: selectedDirection === Direction.Outbound ? data : hubParent,
			leafParent: selectedDirection === Direction.Inbound ? data : undefined,
		});

	const { isLoading: inwardsLoading, trafficData: inwardsTrafficData } =
		useVisualizerData({
			destinationCriteria: inwardsDestinationCriteria,
			sourceCriteria: inwardsSourceCriteria,
			trafficCriteria,
			selectedSourceDimension:
				nodeType !== CTNodeType.HUB && selectedDirection === Direction.Inbound
					? undefined
					: inwardsSourceDimension,
			selectedDestinationDimension:
				nodeType !== CTNodeType.HUB && selectedDirection === Direction.Inbound
					? undefined
					: inwardsDestinationDimension,
			tag: `inwards-${data.label}`,
			topParent: selectedDirection === Direction.Inbound ? data : hubParent,
			leafParent: selectedDirection === Direction.Outbound ? data : undefined,
		});

	const { isLoading: isInternalLoading, trafficData: internalTrafficData } =
		useVisualizerData({
			destinationCriteria: criteria,
			sourceCriteria: criteria,
			trafficCriteria,
			selectedDestinationDimension:
				nodeType !== CTNodeType.HUB ? undefined : selectedDimension,
			selectedSourceDimension:
				nodeType !== CTNodeType.HUB ? undefined : selectedDimension,
			tag: `internal-${data.label}`,
			loadAssets: true,
			topParent: data,
			leafParent: data,
		});

	const addTrafficData = useVisxStore(state => state.addTrafficData);

	const dataRef = useRef(data);
	dataRef.current = data;

	useEffect(() => {
		if (isInternalLoading || !internalTrafficData) {
			return;
		}
		console.log("Internal loading finished", {
			internalTrafficData,
		});

		addTrafficData(internalTrafficData, dataRef.current.label, false, true);
	}, [internalTrafficData, isInternalLoading, addTrafficData]);

	const selectedPathStatus = useVisxStore(state => state.selectedPathStatus);

	let selectedStatus =
		selectedPathStatus === PathReviewStatus.Enforced ||
		selectedPathStatus === PathReviewStatus.DIFF
			? StatisticType.Enforced
			: StatisticType.Candidate;

	useEffect(() => {
		if (outwardsLoading || !outwardsTrafficData) {
			return;
		}

		console.log("outwards loading finished", {
			outwardsTrafficData,
		});
		delete outwardsTrafficData[dataRef.current.label];

		let newDimensionKeys = Object.keys(outwardsTrafficData);

		if (dataRef.current.type === CTNodeType.CONNECTIONS) {
			for (let key of newDimensionKeys) {
				outwardsTrafficData[key].data[selectedStatus]?.dimensionStats?.forEach(
					(_, key) => {
						if (key !== dataRef.current.label) {
							changeParentChildRelationShip([key], dataRef.current.label);
						}
					}
				);

				outwardsTrafficData[key].data[
					selectedStatus
				]?.eastWestNetworkStats?.forEach((_, key) => {
					if (key !== dataRef.current.id) {
						let id = dataRef.current.id;
						changeParentChildRelationShip([key], id);
					}
				});
			}
		}

		if (selectedDirection === Direction.Outbound) {
			addTrafficData(outwardsTrafficData, dataRef.current.label, false, true);
		} else {
			addTrafficData(outwardsTrafficData);
		}
	}, [
		outwardsLoading,
		outwardsTrafficData,
		changeParentChildRelationShip,
		addTrafficData,
		selectedDirection,
		selectedStatus,
	]);

	useEffect(() => {
		if (inwardsLoading || !inwardsTrafficData) {
			return;
		}

		console.log("inwards loading finished", {
			inwardsTrafficData,
		});

		if (dataRef.current.type === CTNodeType.HUB) {
			delete inwardsTrafficData[dataRef.current.label];

			if (selectedDirection === Direction.Inbound) {
				addTrafficData(inwardsTrafficData, dataRef.current.label, false, true);
			} else {
				addTrafficData(inwardsTrafficData);
			}
			return;
		} else {
			if (selectedDirection === Direction.Outbound) {
				addTrafficData(inwardsTrafficData);
				let newDimensionKeys = Object.keys(inwardsTrafficData);

				for (let key of newDimensionKeys) {
					inwardsTrafficData[key].data[selectedStatus]?.dimensionStats?.forEach(
						(_, key) => {
							if (key !== dataRef.current.label) {
								changeParentChildRelationShip([key], dataRef.current.label);
							}
						}
					);

					inwardsTrafficData[key].data[
						selectedStatus
					]?.eastWestNetworkStats?.forEach((_, key) => {
						if (key !== dataRef.current.id) {
							let id = dataRef.current.id;
							changeParentChildRelationShip([key], id);
						}
					});
				}
			} else {
				addTrafficData(inwardsTrafficData);
			}
		}
	}, [
		addTrafficData,
		changeParentChildRelationShip,
		inwardsLoading,
		inwardsTrafficData,
		selectedDirection,
		selectedStatus,
	]);

	return {
		isLoading: isInternalLoading || inwardsLoading || outwardsLoading,
	};
}

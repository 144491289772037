import { useTheme } from "@mui/material";

export const MedigateIcon = () => {
	const theme = useTheme();
	return (
		<svg
			width="49"
			height="48"
			viewBox="0 0 49 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M34.196 20.3078C34.6563 19.8475 35.0804 19.4389 35.4942 19.02C36.7924 17.7011 37.201 15.6944 36.3941 13.9514C35.1529 11.2672 31.5273 10.5017 29.3033 12.7515C27.55 14.5204 25.7967 16.2892 24.0434 18.0632C23.0194 19.0976 21.9953 20.1371 20.9764 21.1819C20.9092 21.2543 20.8575 21.3422 20.8213 21.3939C19.9524 20.5302 19.13 19.7027 18.3025 18.8752C18.5042 18.6528 18.7369 18.3787 18.9852 18.1201C19.5489 17.5408 20.123 16.9719 20.6868 16.403C22.7245 14.3549 24.7623 12.3067 26.7949 10.2483C27.9327 9.10011 29.2568 8.30362 30.8601 8.00365C33.0272 7.60023 35.0753 7.93641 36.87 9.22941C38.3905 10.3207 39.5749 11.7327 40.03 13.6049C40.78 16.6564 40.149 19.3613 37.8837 21.6163C37.4854 22.0094 37.0717 22.387 36.6631 22.7697C35.8925 21.9887 35.0598 21.1664 34.196 20.3078Z"
				fill="#1E4488"
			/>
			<path
				d="M30.5505 29.1348C30.3902 29.2537 30.2971 29.3106 30.2195 29.383C28.0679 31.5449 25.9164 33.712 23.7648 35.8791C22.9942 36.6549 22.265 37.4824 21.4478 38.2065C18.0394 41.2217 12.1796 40.87 9.45393 36.2411C7.78337 33.4017 8.0006 29.9261 9.97113 27.3815C10.4935 26.7039 11.1245 26.1092 11.6986 25.4782C11.7658 25.4058 11.8124 25.3179 11.833 25.292C12.7485 26.1299 13.6226 26.9315 14.507 27.7383C14.0363 28.209 13.6432 28.6124 13.2398 29.0003C11.1503 31.0381 11.5331 34.3792 14.0053 35.9411C15.3345 36.779 16.6999 36.8204 18.086 36.1997C18.567 35.9825 18.9963 35.6101 19.3842 35.2377C20.553 34.1102 21.6909 32.9569 22.8339 31.8087C24.3234 30.314 25.8026 28.8141 27.287 27.3091C27.5145 27.0815 27.7214 26.8281 27.8611 26.6781C28.7506 27.4849 29.6144 28.2814 30.5505 29.1348Z"
				fill="#1E4488"
			/>
			<path
				d="M19.2955 30.1352C19.2128 30.0628 19.161 30.0214 19.1093 29.9697C18.2249 29.0853 17.3353 28.2061 16.4509 27.3165C15.2562 26.1166 14.0718 24.9063 12.8822 23.7013C12.0185 22.8272 11.16 21.9376 10.2807 21.0739C9.51527 20.3188 9.0653 19.393 8.76533 18.3844C8.4757 17.4173 8.35157 16.4243 8.40329 15.4105C8.50156 13.4917 9.19977 11.8263 10.5031 10.4196C11.6772 9.1576 13.0891 8.30422 14.8062 8.00424C16.9474 7.62669 18.9593 7.9939 20.723 9.27138C21.6022 9.90754 22.3418 10.7351 23.2004 11.5264C22.3004 12.3642 21.4522 13.1504 20.6816 13.8693C20.2782 13.5072 19.8282 13.0728 19.3421 12.6797C18.9283 12.3487 18.5042 12.0022 18.0284 11.7798C15.9803 10.8385 13.4563 11.6919 12.4064 13.6831C11.6358 15.1571 11.641 16.657 12.5047 18.1052C12.7322 18.4879 13.0219 18.8447 13.3374 19.1654C14.3821 20.236 15.4527 21.2808 16.5078 22.341C18.0335 23.8771 19.5593 25.4184 21.0798 26.9596C21.2712 27.151 21.4522 27.3527 21.6229 27.5647C21.6643 27.6113 21.6746 27.7509 21.6384 27.7871C20.873 28.5681 20.092 29.3387 19.2955 30.1352Z"
				fill="#F57E36"
			/>
			<path
				d="M25.5288 36.5052C26.4184 35.6984 27.2718 34.9277 28.1252 34.1571L28.0683 34.0588C28.151 34.1364 28.2389 34.2088 28.3217 34.2916C28.9682 34.9381 29.5733 35.6466 30.3801 36.0914C30.8353 36.3449 31.368 36.5155 31.8852 36.5983C32.6817 36.7224 33.4937 36.65 34.2178 36.2621C35.6401 35.507 36.6021 34.395 36.7986 32.74C36.9434 31.5194 36.6848 30.4074 35.9193 29.4299C35.8883 29.3885 35.8469 29.3471 35.8107 29.3109C34.466 27.9507 33.1213 26.5905 31.7818 25.2354C30.2405 23.6786 28.6941 22.1219 27.1528 20.5599C27.0959 20.503 27.0494 20.441 26.9511 20.3272C27.8148 19.541 28.6682 18.7652 29.5475 17.9688C29.6509 18.0825 29.8319 18.3049 30.0336 18.5066C30.7887 19.2617 31.5594 20.0065 32.3093 20.7668C34.3522 22.8253 36.4055 24.8682 38.4226 26.9525C39.3277 27.8886 39.8604 29.0472 40.1294 30.3195C40.5328 32.2331 40.3518 34.0743 39.4518 35.8225C38.2416 38.1757 36.3124 39.5722 33.7161 40.0066C31.6525 40.3532 29.7026 39.9653 27.97 38.7705C27.3649 38.3516 26.8684 37.7775 26.3253 37.2706C26.0822 37.0431 25.8495 36.8155 25.5288 36.5052Z"
				fill="#F57E36"
			/>
			<rect
				x="0.833252"
				y="0.5"
				width="47"
				height="47"
				rx="3.5"
				stroke={theme.palette.text.primary}
				strokeOpacity="0.12"
			/>
		</svg>
	);
};

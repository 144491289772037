import { Box, Stack } from "@mui/material";
import { ContainerColumnRenderProps } from "pages/containers/types";
import CircleIcon from "@mui/icons-material/Circle";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function AgentStatus({
	agent,
	viewOnly,
}: Readonly<ContainerColumnRenderProps>) {
	if (!agent?.agentId) {
		return <Box style={{ width: 120 }} />;
	}

	let icon = <CircleIcon color={"disabled"} style={{ marginRight: 8 }} />;

	let text = "Absent";
	if (agent?.agentStatus === "active") {
		icon = <CircleIcon color={"success"} style={{ marginRight: 8 }} />;
		text = "Active";
	}

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
			<CTDatagridButton
				startIcon={icon}
				asText={viewOnly === true}
				isDisabled={viewOnly === true}
				facetName={"agentstatus"}
				facetOption={agent.agentStatus}
			>
				{window.getCTTranslatedText(text)}
			</CTDatagridButton>
		</Stack>
	);
}

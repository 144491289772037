import { green, grey, lightBlue, orange, red } from "@mui/material/colors";
import { DeviceSegmentationLevels } from "pages/appliance-detail/types";
import {
	AssetStatus,
	SecurityLevels,
	SecurityLevelsBg,
	SegmentationLevels,
} from "pages/assets/types";
import { PathStatus } from "pages/paths/types";
import { PortStatus } from "pages/ports/types";

const COLORS: { [key: string]: string } = {
	[SecurityLevels.Critical]: red[700],
	[SecurityLevels.High]: red[300],
	[SecurityLevels.Medium]: orange[300],
	[SecurityLevels.Low]: green[500],
	[PortStatus.Unreviewed]: lightBlue[400],
	[PortStatus.AllowAny]: "#F44336",
	[PortStatus.AllowAnyByTemplate]: "#F44336",
	[PortStatus.AllowIntranetByTemplate]: "#F44336",
	[PortStatus.AllowIntranet]: "#FB8C00",
	[PortStatus.PathRestricted]: "#009688",
	[PathStatus.Allow]: green[500],
	[PathStatus.Deny]: red[400],
	Mixed: "#9575CD",
	[PathStatus.AllowedByTemplate]: "#4CAF50",
	[PathStatus.AllowedByProgressive]: "#4CAF50",
	[PathStatus.DeniedByTemplate]: "#F44336",
	[PathStatus.AllowTestDenied]: orange[300],
	[PathStatus.AllowTestDeniedViolation]: orange[300],
	[PathStatus.AllowedByTestUIOnly]: orange[300],
	allowedbytest: orange[300],
	unknown: grey.A200,
	ports: "#0071bc",
	paths: "#29abe2",
	assets: "#00ccff",
	[SecurityLevelsBg.Critical]: "rgba(183, 28, 28, 0.08)",
	[SecurityLevelsBg.High]: "rgba(244, 67, 54, 0.08)",
	[SecurityLevelsBg.Medium]: "rgba(255, 152, 0, 0.08)",
	[SecurityLevelsBg.Low]: "rgba(76, 175, 80, 0.08)",
	[AssetStatus.Unsecured]: grey[400],
	[AssetStatus.SimulateSecureInternet]: orange[300],
	[AssetStatus.SecureInternet]: green[500],
	[AssetStatus.SimulateSecureAll]: orange[300],
	[AssetStatus.SecureAll]: green[500],
	[SegmentationLevels.Tagged]: green[500],
	[SegmentationLevels.UnTagged]: grey[400],
	[DeviceSegmentationLevels.Active]: green[600],
	[DeviceSegmentationLevels.Connected]: green[200],
	[DeviceSegmentationLevels.Disconnected]: grey[300],
};

export function getColor(key: string, defaultColor?: string) {
	return COLORS[key] || defaultColor || COLORS["unknown"];
}

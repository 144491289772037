import { Stack, Typography } from "@mui/material";
import { getLocalDate } from "common/utils/getDate";
import { AuditLogColumnRenderProps } from "pages/monitoring/types";
import React from "react";

export const AuditTime = React.forwardRef<any, AuditLogColumnRenderProps>(
	(props, ref) => {
		const { audit } = props;
		const CreatedOnTime = getLocalDate(audit?.creationTime);

		return (
			<Stack
				{...props}
				ref={ref}
				alignItems="left"
				sx={{
					height: "100%",
					justifyContent: "space-evenly",
					width: "100%",
					overflow: "hidden",
					textOverflow: "ellipsis",
				}}
			>
				<Typography variant="body2" noWrap>
					{CreatedOnTime}
				</Typography>
			</Stack>
		);
	}
);

import { Button, Stack } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { PendingWorkRequestsDrawer } from "modules/PendingWorkRequestsDrawer";
import { useWorkRequestStore } from "modules/PendingWorkRequestsDrawer/store";
import { CreateTagBasedPolicyDrawer } from "modules/create-tag-based-policy-drawer";
import { useState } from "react";
import { Link } from "react-router-dom";

export const SegmentsHeader = () => {
	const [isCreateTagPolicyDrawerOpen, setIsCreateTagPolicyDrawerOpen] =
		useState(false);
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const isPendingWorkRequestsDrawerOpen = useWorkRequestStore(
		state => state.isOpen
	);
	const setIsPendingWorkRequestsDrawerOpen = useWorkRequestStore(
		state => state.setOpen
	);

	return (
		<Stack alignItems="center" direction={"row"} justifyContent={"flex-end"}>
			{userPermissions.has("CREATE_TAGBASEDPOLICY") && (
				<>
					<Link to="/reports">
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {}}
							sx={{
								ml: 2,
							}}
						>
							{window.getCTTranslatedText("Reports")}
						</Button>
					</Link>

					<Button
						variant="outlined"
						color="primary"
						onClick={() => {
							setIsCreateTagPolicyDrawerOpen(true);
						}}
						sx={{
							ml: 2,
						}}
					>
						{window.getCTTranslatedText("Create Access Policy")}
					</Button>

					{isCreateTagPolicyDrawerOpen && (
						<CreateTagBasedPolicyDrawer
							isOpen={isCreateTagPolicyDrawerOpen}
							onClose={() => {
								setIsCreateTagPolicyDrawerOpen(false);
							}}
						/>
					)}

					{isPendingWorkRequestsDrawerOpen && (
						<PendingWorkRequestsDrawer
							isOpen={isPendingWorkRequestsDrawerOpen}
							onClose={() => setIsPendingWorkRequestsDrawerOpen(false)}
						/>
					)}

					<Link to="/segment/create">
						<Button
							variant="contained"
							color="primary"
							onClick={() => {}}
							sx={{
								ml: 2,
							}}
						>
							{window.getCTTranslatedText("CreateSegment")}
						</Button>
					</Link>
				</>
			)}
		</Stack>
	);
};

import { useMutation } from "@tanstack/react-query";
import { ResponseType } from "modules/core/types";
import { FirewallHostGroupListItem } from "../types";

function useGetFirewallHostGroups() {
	return useMutation<ResponseType<FirewallHostGroupListItem>, Error, any>([
		"firewall-host-groups",
		"edrs/crowdstrike/firewall-host-groups/search?computeTotal=true",
	]);
}

export default useGetFirewallHostGroups;

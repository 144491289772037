import { CoreStore } from "../types";

type Setter = (
	partial:
		| CoreStore
		| Partial<CoreStore>
		| ((state: CoreStore) => CoreStore | Partial<CoreStore>),
	replace?: boolean | undefined
) => void;

export default function CoreStoreBuilder(set: Setter): CoreStore {
	return {
		apiRefreshRequest: undefined,
		requestAPIRefresh: () => {
			set({ apiRefreshRequest: Date.now() });
		},
	};
}

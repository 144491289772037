import { parseErrorMessage } from "common/utils";
import { AuthState } from "hooks/useAuthState";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useEffect, useState } from "react";
import { useAcceptTnC, useGetTnCAPI } from "./useTnCAPI";

interface TnCConfig {
	authState: AuthState;
}

export function useTncModal({ authState }: TnCConfig) {
	const [showAcceptTnCModal, setShowAcceptTnCModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const { data } = useGetTnCAPI({
		authState: authState,
	});

	useEffect(() => {
		if (data?.updateRequired) {
			setShowAcceptTnCModal(true);
		}
	}, [data?.updateRequired]);

	const mutation = useAcceptTnC();
	const acceptTermsAndCondition = () => {
		setLoading(true);
		mutation.mutateAsync(
			{},
			{
				onSuccess: () => {
					setShowAcceptTnCModal(false);
					setLoading(false);
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
					setLoading(false);
				},
			}
		);
	};

	return {
		acceptTermsAndCondition,
		showAcceptTnCModal,
		loading,
	};
}

import { Autocomplete, Chip, Grid, TextField } from "@mui/material";
import { isValidEmail } from "common/utils/isEmailValid";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import { useEffect, useState } from "react";
import {
	NotificationActionEnum,
	NotificationFormInt,
	NotificationFormKeys,
	NotificationFormKeysDisplayText,
	NotificationFormProps,
	NotificationActionLabelsEnums,
} from "./types";

export const NotificationForm = ({
	recipients,
	index,
	updateNotificationData,
	showLabel = true,
}: NotificationFormProps) => {
	const handleUpdate = ({
		field,
		value,
	}: {
		field: NotificationFormKeys;
		value: string;
	}) => {
		if (field) {
			setFieldValue({
				data: recipients,
				key: field,
				value,
				rowIndex: index,
				updateData: updateNotificationData,
			});
		}
	};

	const handleEmails = ({
		field,
		value,
	}: {
		field: NotificationFormKeys;
		value: string[];
	}) => {
		if (field) {
			setEmails({
				data: recipients,
				key: field,
				value,
				rowIndex: index,
				updateData: updateNotificationData,
			});
		}
	};

	const [validEmail, setValidEmail] = useState<boolean>(true);
	const [isEmailDuplicate, setIsEmailDuplicate] = useState<boolean>(false);
	const [emailHelperText, setEmailHelperText] = useState<string>(
		window.getCTTranslatedText("notifierEmailsMsg")
	);
	const [selectedRecipients, setSelectedRecipients] = useState(
		(recipients?.[NotificationFormKeys.Recipients] as string[]) || []
	);

	const isEmailValid = (email: string) => {
		const validEmail = email ? isValidEmail(email) : true;
		const duplicateEmail = selectedRecipients.includes(email);
		setIsEmailDuplicate(duplicateEmail);
		setValidEmail(validEmail);
		let helperMsg = "notifierEmailsMsg";
		if (!validEmail) {
			helperMsg = "invalidEmail";
		}
		if (duplicateEmail) {
			helperMsg = "duplicateEmail";
		}
		setEmailHelperText(window.getCTTranslatedText(helperMsg));
	};

	const onChangeOption = (event: any, newValue: string[]) => {
		const validEmails = newValue.filter(email => isValidEmail(email));
		if (event.target.value) {
			if (isValidEmail(event.target.value)) {
				setSelectedRecipients(validEmails);
				setValidEmail(true);
				handleEmails({
					field: NotificationFormKeys.Recipients,
					value: validEmails,
				});
			} else {
				setValidEmail(false);
			}
		} else {
			setSelectedRecipients(validEmails);
			handleEmails({
				field: NotificationFormKeys.Recipients,
				value: validEmails,
			});
			setValidEmail(true);
		}
	};

	useEffect(() => {
		setSelectedRecipients(
			(recipients?.[NotificationFormKeys.Recipients] as string[]) || []
		);
	}, [recipients]);

	const actionValues = [
		{
			label: NotificationActionLabelsEnums.Emails,
			value: NotificationActionEnum.Emails,
		},
	];

	const placeholder =
		selectedRecipients.length > 0
			? undefined
			: window.getCTTranslatedText("Emails");

	return (
		<Grid container columnSpacing={3} sx={{ width: "100%" }}>
			<Grid item xs={3}>
				<CTSelectDropDown
					field={NotificationFormKeys.Action}
					displayName={window.getCTTranslatedText(
						NotificationFormKeysDisplayText[NotificationFormKeys.Action]
					)}
					selectedValue={window.getCTTranslatedText(
						NotificationActionLabelsEnums.Emails
					)}
					handleUpdate={handleUpdate}
					showLabel={true}
					data={actionValues}
				/>
			</Grid>
			<Grid item xs={9} sx={{ m: 0 }}>
				<Autocomplete
					multiple
					id="tags-filled"
					options={[]}
					value={selectedRecipients}
					freeSolo
					onChange={onChangeOption}
					renderTags={(value: readonly string[], getTagProps) =>
						value.map(
							(option: string, index: number) =>
								isValidEmail(option) && (
									<Chip
										variant="filled"
										label={option}
										{...getTagProps({ index })}
									/>
								)
						)
					}
					renderInput={params => (
						<TextField
							{...params}
							onChange={(event: any) => {
								isEmailValid(event.target.value);
							}}
							variant="standard"
							label={window.getCTTranslatedText(
								NotificationFormKeysDisplayText[NotificationFormKeys.Recipients]
							)}
							placeholder={placeholder}
							helperText={emailHelperText}
							error={!validEmail || isEmailDuplicate}
						/>
					)}
				/>
			</Grid>
		</Grid>
	);
};

interface FieldValueProps {
	data: NotificationFormInt;
	key: NotificationFormKeys;
	value: string;
	rowIndex: number;
	updateData: Function;
}

export function setFieldValue({
	data,
	key,
	value,
	rowIndex,
	updateData,
}: FieldValueProps) {
	if (value !== data[key]) {
		let newDataObj: NotificationFormInt = { ...data };
		if (key !== NotificationFormKeys.Recipients) {
			// @ts-expect-error
			newDataObj[key] = value;
		}
		updateData(newDataObj, rowIndex);
	}
}

interface MultiFieldValueProps {
	data: NotificationFormInt;
	key: NotificationFormKeys;
	value: string[];
	rowIndex: number;
	updateData: Function;
}

function setEmails({
	data,
	key,
	value,
	rowIndex,
	updateData,
}: MultiFieldValueProps) {
	if (value !== data[key]) {
		let newDataObj: NotificationFormInt = { ...data };
		if (key === NotificationFormKeys.Recipients) {
			if (!newDataObj[key]) {
				newDataObj[key] = [];
			}
			newDataObj[key] = value;
		}

		updateData(newDataObj, rowIndex);
	}
}

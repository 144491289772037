import { useMutation } from "@tanstack/react-query";
import { DeleteRequestProps } from "../../appliance-unmanaged-devices/types";

export const useDeleteUnmanagedDevicesAPI = () => {
	return useMutation<undefined, Error, DeleteRequestProps>([
		"delete-unmanaged-devices",
		`unmanageddevices`,
		"delete",
	]);
};

import { useCore } from "modules/core";
import { Scope } from "modules/scope-metadata/types";
import { useIDPFacetStore } from "pages/Integration/components/scim-management-config/store/useIDPFacetStore";
import { IDPDataGrid } from "./IDP-data-grid";
import { FACET_ORDER } from "./constants";
import { useIDPListAPI } from "./hooks";
import { useIDPStore } from "./store";
import { IdP } from "./types";

export const IDPList = () => {
	const coreResponse = useCore<IdP>({
		useStore: useIDPStore,
		facetGroupInfo: FACET_ORDER,
		useFacetStore: useIDPFacetStore,
		scope: Scope.SCIM_IDP,
		useApi: useIDPListAPI,
		pageSize: 10,
		skipSearch: true,
	});

	return (
		<IDPDataGrid
			rows={coreResponse.rows}
			metadata={coreResponse.metadata}
			rowCount={coreResponse.rowCount}
			mutation={coreResponse.mutation}
			onPageChange={coreResponse.onPageChange}
			page={coreResponse.page}
			pageSize={coreResponse.pageSize}
			onPageSizeChange={coreResponse.onPageSizeChange}
			onSortChange={coreResponse.onSortChange}
		/>
	);
};

import { Box, Chip, Stack } from "@mui/material";
import React, { useMemo } from "react";
import { TagRule } from "../../types";
import { useTagRuleFacetStore } from "../../store";
import { FacetGroups } from "modules/facets/types";
import { useFacetGroupInfo } from "pages/tags/components/tag-rules-list/constants";
import { ScopeMetadata } from "modules/scope-metadata/types";
import { getFacetDisplayName } from "modules/facets/helpers";

export const useFacetOrder = (metadata?: ScopeMetadata) => {
	const facetOrderInfo = useFacetGroupInfo();
	const facetOrder = useMemo(() => {
		if (!metadata?.columns) {
			return;
		}
		let columnNames = Object.keys(metadata?.columns || {});
		let otherGroup: any = {};
		columnNames.forEach(name => {
			if (metadata?.columns[name]?.userDefined) {
				otherGroup[name] = FacetGroups.Tags;
			} else {
				otherGroup[name] = FacetGroups.Others;
			}
		});

		let order = {
			...otherGroup,
			...facetOrderInfo,
		};

		Object.keys(order).forEach(key => {
			let group = order[key];
			if ([FacetGroups.Agents].includes(group)) {
				delete order[key];
			}
		});
		return order;
	}, [facetOrderInfo, metadata?.columns]);

	return facetOrder;
};

export function MatchingTags({ rule }: { rule: TagRule }) {
	const metadata = useTagRuleFacetStore(state => state.metadata);
	const facetOrder = useFacetOrder(metadata);

	if (!rule.ruleId) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	function getTagDisplayName(inputString: string): string {
		const tagName: string = inputString.replace(/\$<|>/g, "");
		let tagDisplayName = metadata?.columns[tagName]?.displayName ?? tagName;
		if (facetOrder[tagName] === "Properties") {
			tagDisplayName = `${tagDisplayName} (Properties)`;
		}
		return tagDisplayName;
	}

	return (
		<>
			<Stack
				direction="row"
				alignItems={"center"}
				justifyItems="flex-start"
				flexWrap={"wrap"}
			>
				{(Object.keys(rule?.onMatch) ?? []).map(
					(tagName: string, index: number) => {
						let tagDisplayName = getTagDisplayName(rule?.onMatch[tagName]);
						const facetDisplayName = getFacetDisplayName(
							metadata?.columns[tagName]?.displayName ?? ""
						);
						return (
							<React.Fragment key={`tagname-${index}`}>
								<Chip
									key={`tag-${index}`}
									label={`${window.getCTTranslatedText(
										facetDisplayName
									)} = ${window.getCTTranslatedText(tagDisplayName)}`}
									size="small"
									sx={{
										my: 0.5,
										mx: 0.5,
										fontSize: "12px",
									}}
								/>
							</React.Fragment>
						);
					}
				)}
			</Stack>
		</>
	);
}

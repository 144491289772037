import { Button, Stack } from "@mui/material";
import { useState } from "react";
import { AgentDrawer } from "./AgentDownloadDrawer";
import { AgentDrawerSwitchControl } from "./AgentDrawerSwitchControl";
import { GetAgentInstallationInfoManual } from "./AgentInstallationInfo";
import { Terminal } from "./Terminal";
import { AGENT_TYPES, PLATFORMS, manualInstall } from "./constants";
import {
	RenderDownloadFileName,
	RenderDownloadLink,
} from "./helpers/versionHelpers";
import { VersionInfo } from "./type";

interface ManualInstallationScriptProps {
	agentVersionTypeInfo: VersionInfo[];
	platform: PLATFORMS;
	agentType?: AGENT_TYPES;
	selectedOption: AGENT_TYPES;
	selectedAgent: string;
	latestDepKey?: string;
	selectedAgentType: string;
}

export const ManualInstallationScript = ({
	agentVersionTypeInfo,
	platform,
	agentType,
	selectedOption,
	selectedAgent,
	latestDepKey,
	selectedAgentType,
}: ManualInstallationScriptProps) => {
	const [drawerISourceOpen, setDrawerISourceOpen] = useState(false);
	const onCloseInstallSourceDrawer = (value?: boolean) => {
		setDrawerISourceOpen(false);
	};

	return (
		<Stack sx={{ minWidth: 100 }}>
			<Button
				color="primary"
				variant="outlined"
				disabled={agentVersionTypeInfo.length === 0}
				onClick={() => setDrawerISourceOpen(true)}
			>
				{window.getCTTranslatedText(manualInstall)}
			</Button>
			<AgentDrawer
				drawerOpen={drawerISourceOpen}
				onClose={onCloseInstallSourceDrawer}
				platform={platform}
				installationType={manualInstall}
				agentType={agentType}
			>
				<AgentDrawerSwitchControl
					platform={platform}
					agentType={selectedOption}
				>
					<Terminal>
						<GetAgentInstallationInfoManual
							pname={platform}
							agentType={selectedOption}
							selectedAgentTypeValue={selectedAgent}
							latestDepKey={latestDepKey}
							fileName={RenderDownloadFileName(
								agentVersionTypeInfo,
								selectedAgentType
							)}
							downloadLink={RenderDownloadLink(
								agentVersionTypeInfo,
								selectedAgentType
							)}
							agentVersion={selectedAgentType}
						/>
					</Terminal>
				</AgentDrawerSwitchControl>
			</AgentDrawer>
		</Stack>
	);
};

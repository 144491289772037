import { Box, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { getRelativeLastObserved } from "common/utils";
import { SearchBar } from "modules/add-to-templates/components/search-bar";
import { useCore } from "modules/core";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { Scope } from "modules/scope-metadata/types";
import { Asset } from "pages/assets/types";
import { NamedNetwork, PathDirection } from "pages/paths/types";
import { useMemo, useState } from "react";
import { NN_HITS_COLS } from "./data-grid-constants";
import { useNNHitStore, useNNHitsFacetStore } from "./store";
import { NNHit } from "./types";

export function useNNHitsAPI() {
	return useMutation<any, Error, any>([
		"nnhits",
		"namednetworks/hits?computeTotal=true",
	]);
}

const facetGroupInfo = {};

interface PathNnHitsDataGridProps {
	nn: NamedNetwork;
	asset: Asset;
	port: string;
	protocol: string;
	direction: PathDirection;
}

export function PathNnHitsDataGrid({
	nn,
	asset,
	port,
	protocol,
	direction,
}: PathNnHitsDataGridProps) {
	const [searchText, setSearchText] = useState("");
	const defaultCriteria = `assetid='${asset?.assetId}' and namednetworkid='${nn.namedNetworkId}' and (namednetworkmatchport=${port}) and namednetworkmatchprotocol='${protocol}' and namednetworkmatchdirection=${direction}`;
	const [criteria, setCriteria] = useState(defaultCriteria);

	const coreResponse = useCore<NNHit>({
		useStore: useNNHitStore,
		facetGroupInfo: facetGroupInfo,
		dataMapper: item => {
			item.lastHitObserved = getRelativeLastObserved(item.lastHitObserved);
			return item;
		},
		defaultSortOrder: [{ field: "lasthitobserved", order: "desc" }],
		useApi: useNNHitsAPI,
		pageSize: 100,
		additionalCriteria: criteria,
		scope: Scope.NNHits,
		useFacetStore: useNNHitsFacetStore,
		skipSearch: true,
	});

	const filteredData = useMemo(() => {
		return coreResponse?.rows
			?.map(item => ({
				...item,
				protocol: item.protocol.toString(),
				direction: item.direction.toString(),
				port: item.port.toString(),
				portName: "test",
			}))
			.filter(
				item =>
					nn.namedNetworkId === item.namedNetwork.namedNetworkId &&
					asset?.assetId === item.assetId
			);
	}, [nn, asset, coreResponse.rows]);

	const onSearchTextChange = (searchText: string) => {
		setSearchText(searchText ?? "");
		if (searchText.trim().length) {
			setCriteria(defaultCriteria + ` and '${searchText}'`);
		} else {
			setCriteria(defaultCriteria);
		}
	};

	return (
		<>
			<Stack direction="row" sx={{ width: "100%", mb: 2 }} spacing={3}>
				<SearchBar
					id="NN-hits-list-searchbar"
					label={window.getCTTranslatedText("Search")}
					placeholder={"Search"}
					selectedValue={searchText}
					onChange={onSearchTextChange}
					onFocus={() => {}}
				/>
			</Stack>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid<NNHit>
					rowHeight={64}
					columns={NN_HITS_COLS}
					pagination
					getRowId={({ namedNetworkIpAddress, domain }) =>
						`${namedNetworkIpAddress} ${domain}`
					}
					paginationMode="server"
					sortingMode="server"
					rowCount={coreResponse.rowCount}
					rows={filteredData ?? []}
					mutation={coreResponse.mutation}
					onPageChange={coreResponse.onPageChange}
					page={coreResponse.page}
					pageSize={coreResponse.pageSize}
					onPageSizeChange={coreResponse.onPageSizeChange}
					onSortChange={coreResponse.onSortChange}
				/>
			</Box>
		</>
	);
}

import { SelectChangeEvent, Stack, Typography, useTheme } from "@mui/material";
import { AgentActionType } from "../agent-data-grid-toolbar/Toolbar";
import { AgentGroupByObj } from "../agent-drawers/hooks/useGroupByAgentData";
import { PlatformArchitecturesSelectCurrentVersion } from "./PlatformArchitecturesSelectCurrentVersion";
import { UpgradeAgentCount } from "./UpgradeAgentCount";
import { ARCHITECTURE_TYPE, PLATFORMS } from "./constants";
import {
	processPlatformDisplayName,
	renderProcessPlatformIcon,
} from "./helpers";
import { VersionInfo } from "./type";

interface AgentUpgradeContentProps {
	platform: PLATFORMS;
	page?: AgentActionType;
	supportedPlatformArchitectures: ARCHITECTURE_TYPE[];
	getAgentVersionInfo: (architecture: ARCHITECTURE_TYPE) => VersionInfo[];
	agentsGroupByPlatforms?: AgentGroupByObj;
	selectedAgentType: (
		agentVersionTypeInfo: VersionInfo[],
		architecture: string,
		index: number
	) => string;
	highestCurrentAgentVersion: string;
	handleChange: (event: SelectChangeEvent, index: number) => void;
	setHighestAgentVersion: (str: string) => void;
}

export const AgentUpgradeContent = ({
	platform,
	supportedPlatformArchitectures,
	getAgentVersionInfo,
	agentsGroupByPlatforms,
	selectedAgentType,
	highestCurrentAgentVersion,
	handleChange,
	page,
	setHighestAgentVersion,
}: AgentUpgradeContentProps) => {
	const theme = useTheme();

	return (
		<>
			<Stack direction="row" gap={1.5} alignItems={"center"} width="100%">
				{renderProcessPlatformIcon(platform, theme.palette.text.primary)}
				{processPlatformDisplayName(platform)}
			</Stack>

			{supportedPlatformArchitectures.map((architecture, index) => {
				let agentVersionTypeInfo: VersionInfo[] =
					getAgentVersionInfo(architecture);

				return (
					<Stack
						ml={7}
						direction="row"
						alignItems={"center"}
						justifyContent={"space-between"}
						width="100%"
						key={`${architecture + index}`}
					>
						<Typography variant="body2" sx={{ minWidth: 80 }}>
							{architecture}
						</Typography>

						<UpgradeAgentCount
							platform={platform}
							architecture={architecture}
							agentsGroupByPlatforms={agentsGroupByPlatforms}
							index={index}
						/>

						<PlatformArchitecturesSelectCurrentVersion
							selectedAgentType={selectedAgentType}
							handleChange={handleChange}
							agentVersionTypeInfo={agentVersionTypeInfo}
							highestCurrentAgentVersion={highestCurrentAgentVersion}
							architecture={architecture}
							index={index}
							page={page}
							platform={platform}
							agentsGroupByPlatforms={agentsGroupByPlatforms}
							setHighestAgentVersion={setHighestAgentVersion}
						/>
					</Stack>
				);
			})}
		</>
	);
};

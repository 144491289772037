import { Stack, Tooltip } from "@mui/material";
import { useCommonStore } from "common/store";

import { AuditLogColumnRenderProps } from "pages/monitoring/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export const AuditUserAndLocation = ({
	audit,
	viewOnly,
}: AuditLogColumnRenderProps) => {
	const facetState = useCommonStore(state => state.facets);

	const actionByFacetName = "actionby";
	const actionByOptionName = audit?.actionBy?.toString() || "";
	const isActionBySelected =
		!viewOnly &&
		facetState?.get(actionByFacetName)?.get(actionByOptionName)?.isSelected;

	return (
		<Stack
			sx={{
				height: "100%",
				justifyContent: "space-evenly",
				width: "100%",
				textOverflow: "ellipsis",
			}}
		>
			<Tooltip
				title={`${window.getCTTranslatedText(
					"Action By"
				)}: ${window.getCTTranslatedText(audit.actionBy)}`}
			>
				<CTDatagridButton
					sx={{
						p: isActionBySelected ? undefined : 0,
					}}
					asText={viewOnly}
					isDisabled={viewOnly}
					facetName={actionByFacetName}
					facetOption={actionByOptionName}
					stopPropagation={true}
				>
					{window.getCTTranslatedText(audit.actionBy)}
				</CTDatagridButton>
			</Tooltip>
			{/* <Typography variant="body2">{audit.sourceIp}</Typography> */}
		</Stack>
	);
};

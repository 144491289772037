interface AnnotationsBody {
	commentId: string;
	entityType: EntityType;
	entityId: string;
	comment: string;
	createdAt: string;
	author: string;
}

export enum EntityType {
	Assets = "assets",
	Ports = "openports",
	Paths = "paths",
	Templates = "templates",
	NamedNetworks = "namednetworks",
}

export type AnnotationsListResult = { items?: AnnotationsBody[] };

import { isValidIpv4, subnet } from "ip-utils";
import { Scope } from "modules/scope-metadata/types";
import { Appliance } from "pages/appliances/types";
import { Asset } from "pages/assets/types";
import { Network } from "pages/networks/types";
import { Path } from "pages/paths/types";
import { TagPolicy } from "pages/tags/components/tag-policy-list/types";
import { Suggestion } from "./types";

export const onLoadPathData = (
	searchText: string,
	data: Path[],
	includeDomain: boolean
): Suggestion[] => {
	let suggestList: Suggestion[] = [];

	if ("any".includes(searchText)) {
		suggestList.push({
			type: "IP",
			value: "0.0.0.0/0",
			displayName: "any",
		});
	}
	data?.forEach((path: Path) => {
		if (path?.sourceNamedNetwork) {
			suggestList.push({
				type: Scope.Network,
				displayName: path?.sourceNamedNetwork?.namedNetworkName,
				value: path?.sourceNamedNetwork?.namedNetworkId,
				rawData: path?.sourceNamedNetwork,
			});
		}
		if (path?.sourceTagBasedPolicy) {
			suggestList.push({
				type: Scope.TagBasedPolicy,
				displayName: path?.sourceTagBasedPolicy?.tagBasedPolicyName,
				value: path?.sourceTagBasedPolicy?.tagBasedPolicyId ?? "",
				rawData: path?.sourceTagBasedPolicy,
			});
		}
		if (path?.destinationNamedNetwork) {
			suggestList.push({
				type: Scope.Network,
				displayName: path?.destinationNamedNetwork?.namedNetworkName,
				value: path?.destinationNamedNetwork?.namedNetworkId,
				rawData: path?.destinationNamedNetwork,
			});
		}
		if (path?.destinationTagBasedPolicy) {
			suggestList.push({
				type: Scope.TagBasedPolicy,
				displayName: path?.destinationTagBasedPolicy?.tagBasedPolicyName,
				value: path?.destinationTagBasedPolicy?.tagBasedPolicyId ?? "",
				rawData: path?.destinationTagBasedPolicy,
			});
		}
		if (path?.srcIp) {
			suggestList.push({
				type: "IP",
				value: path?.srcIp,
				displayName: path?.srcIp,
			});
		}
		if (path?.dstIp && path?.dstIp?.length > 0) {
			path?.dstIp.forEach((ip: string) => {
				suggestList.push({
					type: "IP",
					value: ip,
					displayName: ip,
				});
			});
		}

		if (includeDomain && path?.domain) {
			suggestList.push({
				type: "Domain",
				value: path?.domain,
				displayName: path?.domain,
			});
		}
	});

	return suggestList;
};

export const segmentsDataMapper = (segment: TagPolicy): Suggestion => {
	return {
		type: Scope.TagBasedPolicy,
		displayName: segment.tagBasedPolicyName,
		value: segment.tagBasedPolicyId,
		rawData: {
			tagBasedPolicyName: segment.tagBasedPolicyName,
			tagBasedPolicyvalie: segment.tagBasedPolicyId,
		},
	};
};

export const networkDataMapper = (network: Network): Suggestion => {
	return {
		type: Scope.Network,
		displayName: network.namedNetworkName,
		value: network.namedNetworkId,
		rawData: {
			namedNetworkName: network.namedNetworkName,
			namedNetworkvalue: network.namedNetworkId,
		},
	};
};

export const assetIpv4Filter = (asset: Asset): boolean => {
	if (!asset.interfaces?.length) {
		return false;
	}

	const ipv4s = asset.interfaces?.[0].ipaddresses?.filter(isValidIpv4) ?? [];

	if (ipv4s.length === 0) {
		return false;
	}

	if (ipv4s.length === 1) {
		return true;
	}

	const { primarysubnet } = asset.coreTags;

	if (!primarysubnet) {
		return false;
	}

	return ipv4s.some((ip: string) => subnet(primarysubnet).contains(ip));
};

export const assetDataMapper = (asset: Asset): Suggestion => {
	return {
		type: Scope.Asset,
		displayName: asset.assetName,
		value: asset.assetId,
		rawData: asset,
	};
};

export const applianceDataMapper = (
	applianceData: Appliance[],
	agentId?: string
): Suggestion[] => {
	let appliances: Suggestion[] = [];
	if (applianceData?.length) {
		for (let appliance of applianceData) {
			if (appliance.agentId !== agentId) {
				appliances.push({
					type: Scope.Appliance,
					displayName: appliance.agentName,
					value: appliance.agentId,
					rawData: appliance,
				});
			}
		}
	}

	return appliances;
};

import { useTheme } from "@mui/material";
import { IconsProps } from "./common";

export const SecureAllIcon = ({
	width = "16px",
	height = "20px",
	color,
	x = 0,
	y = 0,
}: IconsProps) => {
	const theme = useTheme();
	color = color || theme.palette.text.disabled;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			x={x}
			y={y}
		>
			<path
				d="M8 0L0 3L0 9.09C0 14.14 3.41 18.85 8 20C12.59 18.85 16 14.14 16 9.09L16 3L8 0ZM14 9.09C14 13.09 11.45 16.79 8 17.92C4.55 16.79 2 13.1 2 9.09L2 4.31L8 2.19L14 4.31L14 9.09ZM4.82 8.59L3.4 10L6.94 13.54L12.6 7.88L11.19 6.47L6.95 10.71L4.82 8.59Z"
				fill={color}
			/>
		</svg>
	);
};

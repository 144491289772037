import { IconsProps } from "./common";

export const FirewallWarningIcon = ({
	width = "24px",
	height = "24px",
	color = "#000",
}: IconsProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 17 16"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 0H5H4H1H0V1V3V4V6V7V9V10V12V13H1H4H5H8H9H10.355C10.9121 14.461 12.0763 15.6331 13.5 16C15.5081 15.4825 17 13.363 17 11.0905V8.35L13.5 7L13 7.19286V7V6V4V3V1V0H12H9H8ZM12 7.57857V7H11H10H9H8V8V9H9H10V8.35L12 7.57857ZM10 10H9V11V12H10H10.0794C10.0273 11.702 10 11.3977 10 11.0905V10ZM1 10V11V12H2H3H4V11V10H3H2H1ZM1 7V8V9H2V8V7H1ZM1 6H2H3H4H5V5V4H4H2H1V5V6ZM3 7V8V9H4H5H6H7V8V7H6H5H4H3ZM5 10V11V12H6H7H8V11V10H7H6H5ZM5 2V1H6H7H8V2V3H7H6H5V2ZM7 6H6V5V4H7H8H9H10V5V6H9H8H7ZM2 3H1V2V1H2H3H4V2V3H3H2ZM9 1V2V3H10H11H12V2V1H11H10H9ZM11 6H12V5V4H11V5V6ZM16.125 11.0905C16.125 12.8905 15.0094 14.5555 13.5 15.064C11.9906 14.5555 10.875 12.895 10.875 11.0905V8.9755L13.5 7.963L16.125 8.9755V11.0905ZM13.0625 12.4V13.3H13.9375V12.4H13.0625ZM13.0625 11.5V9.25H13.9375V11.5H13.0625Z"
				fill={color}
			/>
		</svg>
	);
};

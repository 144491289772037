import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { CTWidget } from "common/molecules/widget";
import { WidgetListItem } from "common/molecules/widget/components/widget-list-item";
import { ApplianceWidgetProps } from "pages/appliance-detail/types";
import { Appliance } from "pages/appliances/types";

interface WidgetProps {
	appliance?: Appliance;
}

const InterfacesWidgetContent = ({ appliance }: WidgetProps) => {
	const theme = useTheme();

	const renderVLANs = () => {
		return (
			<Stack spacing={2}>
				<Typography
					variant="caption"
					sx={{
						color: theme.palette.text.disabled,
					}}
				>
					VLAN
				</Typography>
				<Stack>
					<WidgetListItem
						alignItems="flex-start"
						keyText={window.getCTTranslatedText("ID:IP Address")}
						valueElement={
							<Stack spacing={1}>
								{appliance?.vlans?.map(vlan => {
									return (
										<Typography key={vlan?.id} variant="body2">
											{vlan?.id} : {vlan?.ipAddress}
										</Typography>
									);
								})}
							</Stack>
						}
					/>
				</Stack>
			</Stack>
		);
	};

	return (
		<Stack width="100%" spacing={3}>
			<Stack spacing={2}>
				<Typography
					variant="caption"
					sx={{
						color: theme.palette.text.disabled,
					}}
				>
					WAN
				</Typography>
				<Stack>
					<WidgetListItem
						keyText={window.getCTTranslatedText("IP Address")}
						valueText={appliance?.wanIp}
					/>
				</Stack>
			</Stack>
			<Divider />
			{appliance?.vlans?.length ? (
				renderVLANs()
			) : (
				<Stack spacing={2}>
					<Typography
						variant="caption"
						sx={{
							color: theme.palette.text.disabled,
						}}
					>
						LAN
					</Typography>
					<Stack>
						<WidgetListItem
							keyText={window.getCTTranslatedText("IP Address")}
							valueText={appliance?.lanIp}
						/>
					</Stack>
				</Stack>
			)}
		</Stack>
	);
};

export const ApplianceInterfaces = ({
	appliance,
	isLoading,
}: ApplianceWidgetProps) => {
	return (
		<CTWidget
			title={window.getCTTranslatedText("interfaces")}
			isLoading={isLoading}
			children={<InterfacesWidgetContent appliance={appliance} />}
		/>
	);
};

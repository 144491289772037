import { PORTLESS_PROTOCOLS, Path } from "pages/paths/types";
import {
	Port,
	PortAllowedStatus,
	PortRule,
	PortStatus,
} from "pages/ports/types";
import { TemplateType } from "pages/templates/types";

export function PortRulesBody({
	rules,
	portStatus,
	templateType,
}: {
	rules: Array<Port>;
	portStatus: PortAllowedStatus | PortStatus;
	templateType: TemplateType;
}) {
	const PortRules: Array<Port> = rules as Array<Port>;
	const portRules: Array<PortRule> | undefined = PortRules?.map(
		(rule: Port) => {
			const currentPortStatus: PortStatus =
				rule.listenPortReviewed as PortStatus;
			const overideStatus: boolean =
				(currentPortStatus !== PortStatus.AllowAny &&
					currentPortStatus !== PortStatus.AllowIntranet) ||
				portStatus === PortStatus.PathRestricted;
			let newPortStatus: PortAllowedStatus | PortStatus = overideStatus
				? portStatus
				: currentPortStatus;

			if (templateType === TemplateType.BlockTemplate) {
				newPortStatus = PortStatus.Deny;
			}

			let listenPort = "";

			if (PORTLESS_PROTOCOLS.includes(rule.listenPortProtocol?.toUpperCase())) {
				listenPort = "any";
			}

			if (rule?.listenPort?.length) {
				listenPort = rule.listenPort;
			}

			const obj: PortRule = {
				listenPort: listenPort,
				listenPortName: rule.listenPortName,
				listenProcessNames: rule.listenProcessNames,
				listenPortProtocol: rule.listenPortProtocol,
				listenPortReviewed: newPortStatus,
			};
			return obj;
		}
	);

	return portRules;
}

export function PortRulesBodyFromPathRules({
	rules,
	portStatus,
}: {
	rules: Array<Path>;
	portStatus: PortAllowedStatus | PortStatus;
}) {
	const portRules: Array<PortRule> | undefined = rules?.map((rule: Path) => {
		const obj: PortRule = {
			listenPort: rule.port,
			listenPortName: rule.portName,
			listenProcessNames: [],
			listenPortProtocol: rule.protocol,
			listenPortReviewed: portStatus,
		};
		return obj;
	});

	return portRules;
}

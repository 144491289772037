import { CheckCircle } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
	Card,
	CardContent,
	Grid,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { UploadIcon } from "assets/svgs/UploadIcon";
import { FilePicker } from "modules/file-picker";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { useEffect, useState } from "react";
import { isValidFile } from "./FileValidator";
import { FileFieldProps, FileType, MimeType } from "./type";
import pluralize from "pluralize";

export const getSampleFile = (name: string, type: string) => {
	const sampleFile = new File([""], name, {
		type: type,
	});
	return sampleFile;
};
export const getMIMEType = (extension: string) => {
	switch (extension) {
		case FileType.JSON:
			return MimeType.JSON;
		case FileType.CERT:
			return MimeType.CERT;
		case FileType.TEXT:
			return MimeType.TEXT;
	}
};
export const FormFileUploader = ({
	field,
	value,
	handleChange,
	maxFileSizeInBytes = 20000,
	allowedExtensions = [".json"],
	fileName,
}: FileFieldProps) => {
	const setSnackbar = useSnackbarStore((state: any) => state.setSnackbar);
	const [attachment, setAttachment] = useState<File | null>(null);
	const [fileUploaded, setFileUploaded] = useState<boolean>(false);
	useEffect(() => {
		if (!fileUploaded) {
			if (value && Object.keys(value)?.length) {
				const fileType = getMIMEType(allowedExtensions[0]) ?? "";
				const sampleFile = getSampleFile(fileName, fileType);
				setAttachment(sampleFile);
			} else {
				if (!attachment) setAttachment(null);
			}
		}
	}, [value, fileUploaded, allowedExtensions, fileName, attachment]);
	const handleChangeform = (files: File[]) => {
		const file = files[0];
		if (file) {
			const fileSize = file?.size;
			if (!isValidFile(file, allowedExtensions)) {
				setSnackbar(true, SnackBarSeverity.Error, "InvalidFile", {
					allowedExtensions: allowedExtensions.join(", "),
					is: window.getCTTranslatedText(
						pluralize("is", allowedExtensions?.length ?? 0)
					),
				});
				return;
			}
			if (fileSize > maxFileSizeInBytes) {
				setSnackbar(true, SnackBarSeverity.Error, "FileSizeLimit", {
					maxFileSizeInKb: maxFileSizeInBytes / 1024,
				});
				return;
			} else {
				const reader = new FileReader();
				reader.onload = e => {
					const fileData = e.target?.result as string;
					const jsonData = JSON.parse(fileData);
					const body = {
						target: {
							name: field.key,
							value: jsonData,
						},
					};
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						"FileUploadedSuccessfully"
					);
					setFileUploaded(true);
					handleChange(body);
				};
				reader.readAsText(file as Blob);
			}
			setAttachment(file);
		}
	};
	const removeFile = () => {
		setAttachment(null);
		const body = {
			target: {
				name: field.key,
				value: {},
			},
		};
		setSnackbar(true, SnackBarSeverity.Success, "FileRemovedSuccessfully");
		handleChange(body);
	};
	return (
		<Grid item xs={12} sx={{ gridColumn: "span 2" }}>
			<Typography width={"100%"} variant="body2">
				{capitalizeFirstLetter(window.getCTTranslatedText(field.displayName))}
				{field.information && (
					<Tooltip title={field.information}>
						<InfoOutlinedIcon fontSize="small" sx={{ ml: 1 }} />
					</Tooltip>
				)}
			</Typography>
			{!attachment && (
				<FilePicker
					onChangeFiles={handleChangeform}
					options={{
						accept: {
							"application/json": allowedExtensions,
						},
						maxFiles: 1,
					}}
				/>
			)}
			{attachment && (
				<Card>
					<CardContent>
						<ListItem
							secondaryAction={
								<Stack direction="row" alignItems="center">
									<IconButton edge="end" aria-label="delete">
										<CloseIcon onClick={removeFile} />
									</IconButton>
									<CheckCircle sx={{ ml: 2 }} color="success" />
								</Stack>
							}
						>
							<ListItemAvatar>
								<UploadIcon />
							</ListItemAvatar>
							<ListItemText
								primary={attachment.name}
								secondary={
									attachment.size
										? `${Math.round(attachment.size / 1024)} kb`
										: ""
								}
								sx={{ alignItems: "center" }}
							/>
						</ListItem>
					</CardContent>
				</Card>
			)}
		</Grid>
	);
};

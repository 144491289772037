import { debounce } from "@mui/material";
import { useCommonStore } from "common/store";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	PieChartDatum as ChartDatum,
	DashboardWidget,
} from "@colortokens/ng-ui";
import { SecurityLevels } from "pages/assets/types";
import { SecurityChartDialogProps } from "pages/dashboard/types";
import { useEffect, useMemo, useState } from "react";
import { AssetClassificationByLevels } from "../assets-classification-by-levels";
import { ClassificationData } from "../assets-classification-by-levels/AssetClassificationByLevels";
import {
	avgDataMapper,
	barChartRequestBody,
} from "../security-progress/helpers";
import { facetMetricsToChartData } from "../security-progress/helpers/facetMetricsToChartData";
import { useSummarizeAPI, useTimeSeriesAPI } from "../security-progress/hooks";
import { SummarizeReqParams } from "../security-progress/hooks/useSummarizeAPI";
import { SecurityChartDialog } from "./components/security-chart-dialog";

export const SecurityProgressChartWrapper = ({
	type,
}: SecurityChartDialogProps) => {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const searchCriteria = useCommonStore(state => state.currentSearchCriteria);

	const [avgSecurityValue, setSecurityValue] = useState<number | undefined>(
		undefined
	);
	const [securityData, setSecurityData] = useState<ChartDatum[] | undefined>(
		undefined
	);

	const [totalAssetsCount, setTotalAssetsCount] = useState<number>(0);

	const keyMetricsMutation = useSummarizeAPI("assetinboundstatus");
	const keyMetricsAvgMutation = useTimeSeriesAPI();

	const keyMetricsMutationObj = useMemo(
		() => debounce(keyMetricsMutation.mutate, 100),
		[keyMetricsMutation.mutate]
	);

	const keyMetricsAvgMutationObj = useMemo(
		() => debounce(keyMetricsAvgMutation.mutate, 100),
		[keyMetricsAvgMutation.mutate]
	);

	useEffect(() => {
		if (type) {
			let criteria = searchCriteria ?? "*";

			const requestObj = barChartRequestBody({
				criteria,
				scope: Scope.Asset,
				groupBy: [],
				statistics: [`mean(${type}score)`, "count(assetid)"],
			});

			keyMetricsAvgMutationObj(requestObj, {
				onSuccess(data) {
					if (data) {
						const responseData = data;
						const items = responseData?.items ?? {};
						const avgSecurityVal = avgDataMapper({
							items,
							statisticsId: `${type}scoremean`,
						});
						const totalAssets = avgDataMapper({
							items,
							statisticsId: "assetidcount",
						});

						setTotalAssetsCount(totalAssets ?? 0);
						setSecurityValue(avgSecurityVal ?? 0);
					}
				},
				onError: error => {
					setSnackbar(
						true,
						SnackBarSeverity.Error,
						"ErrorMessageForAssetDistribution",
						{
							type: window.getCTTranslatedText(type),
						}
					);
				},
			});
		}
	}, [keyMetricsAvgMutationObj, searchCriteria, setSnackbar, type]);

	useEffect(() => {
		if (type) {
			let criteria = searchCriteria ?? "*";

			let requestObj: SummarizeReqParams = {
				criteria: criteria,
				scope: Scope.Asset,
				facetField: type,
				facetFieldFilter: "",
			};

			keyMetricsMutationObj(requestObj, {
				onSuccess(data) {
					if (data) {
						const responseData = data;
						const facet = responseData?.Facet ?? {};

						const chartData = facetMetricsToChartData({
							facet: facet?.values ?? {},
							labelMapper: {},
							possibleValues: Object.values(SecurityLevels),
						});
						setSecurityData(chartData);
					}
				},
				onError: error => {
					setSnackbar(
						true,
						SnackBarSeverity.Error,
						"ErrorMessageForAssetDistribution",
						{
							type: window.getCTTranslatedText(type),
						}
					);
				},
			});
		}
	}, [keyMetricsMutationObj, searchCriteria, setSnackbar, type]);

	const chartData = useMemo(() => {
		if (totalAssetsCount && securityData) {
			return {
				totalAssets: totalAssetsCount ?? 0,
				total: avgSecurityValue,
				loading: keyMetricsMutation?.isLoading,
				data: securityData,
			};
		}
	}, [securityData, totalAssetsCount, avgSecurityValue, keyMetricsMutation]);

	return (
		<DashboardWidget
			title={`${window.getCTTranslatedText("SecurityProgressChartTitle", {
				type: window.getCTTranslatedText(type),
			})}`}
			isLoading={
				keyMetricsAvgMutation?.isLoading || keyMetricsMutation?.isLoading
			}
			children={
				keyMetricsAvgMutation?.isLoading ||
				keyMetricsMutation?.isLoading ||
				avgSecurityValue === undefined ||
				securityData === undefined ? null : (
					<AssetClassificationByLevels
						data={chartData as ClassificationData}
						type={type}
						category={`${window.getCTTranslatedText(type)}`}
					/>
				)
			}
			showTrend={true}
			trendComponent={
				<SecurityChartDialog
					type={type}
					key={`security-type-${type}`}
					totalAssetsCount={totalAssetsCount}
				/>
			}
		/>
	);
};

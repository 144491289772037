import { grammar } from "grammar";
import { useCallback } from "react";

export const useAntlrParser = () => {
	const parseCriteria = useCallback((input: string) => {
		return grammar(input);
	}, []);

	return parseCriteria;
};

import { create } from "zustand";
import { SavedQueryInterface } from "./types";
import { SavedQuery } from "../types";

export const useSavedQueryStore = create<SavedQueryInterface>()(set => ({
	savedQuery: undefined,
	savedQueryList: [],
	setSavedQuery: (savedQuery: SavedQuery) => {
		set({
			savedQuery: savedQuery,
		});
	},
	setSavedQueryList: (savedQueryList: Array<SavedQuery>) => {
		set({
			savedQueryList: savedQueryList,
		});
	},
}));

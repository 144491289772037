import { WorkRequestAction } from "modules/PendingWorkRequestsDrawer/types";

export interface Product {
	productName: string;
	homePath: string;
}

export interface Tenant {
	name: string;
	tenantID: string;
	products: Array<Product>;
}

export interface TenantListResponse {
	tenants: Array<Tenant>;
	current: Tenant;
}

export enum WorkRequestStatus {
	Pending = "Pending",
	InProgress = "InProgress",
	Completed = "Completed",
	Cancelled = "Cancelled",
	Superseded = "Superseded",
}

export interface WorkRequest {
	action?: WorkRequestAction;
	completedAt: string;
	createdAt: string;
	id: string;
	payload: any;
	resourceId: string;
	resourceName?: string;
	retryAfter: string;
	status: WorkRequestStatus;
	subject?: string;
	subjectEmail?: string;
	onClose?: () => void;
}

export interface WorkRequestsResponse {
	items: WorkRequest[];
	metadata: {
		total: number;
	};
}

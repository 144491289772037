export const PropertiesList = [
	{
		name: "Name",
		valueProp: "agentName",
	},
	{
		name: "os",
		valueProp: "platform",
	},
	{
		name: "architecture",
		valueProp: "architecture",
	},
	{
		name: "lastCheckIn",
		valueProp: "lastCheckIn",
	},
	{
		name: "Location",
		valueProp: "agentLocation",
	},
];

import { useQuery } from "@tanstack/react-query";
import { AuthState } from "hooks/useAuthState";
import { useEffect, useState } from "react";
import { ProductFruits } from "react-product-fruits";

export interface KnowledgeBaseType {
	expirationFinalDate: string;
	hmacHash: string;
	username: string;
}

export interface KnowledgeBaseHmac {
	hash?: string;
	expiration?: string;
}

export interface UserInfoType {
	username: string;
	hmac: {
		hash: string;
		expiration?: string;
	};
}

export function useKnowledgeBaseAPI(isEnabled: boolean) {
	return useQuery<KnowledgeBaseType, Error>(["auth-me", "auth/help/token"], {
		staleTime: 180000, // don't refetch for 180 secs
		enabled: isEnabled,
		refetchOnWindowFocus: false,
		refetchInterval: 180000,
	});
}

export function KnowledgeBase({ authState }: { authState: AuthState }) {
	const { data } = useKnowledgeBaseAPI(authState === AuthState.LoggedIn);
	const [userInfo, setUserInfo] = useState<UserInfoType | null>(null);

	useEffect(() => {
		if (data?.username && data?.hmacHash) {
			setUserInfo({
				username: data.username,
				hmac: {
					hash: data.hmacHash,
					expiration: data.expirationFinalDate,
				},
			});
		}
	}, [data]);

	// console.log(`https://docs.ng.colortokens.com/?hmac=${encodeURIComponent(JSON.stringify(userInfo?.hmac))}`)

	return (
		<>
			{process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE &&
				userInfo?.username &&
				userInfo?.hmac.hash && (
					<ProductFruits
						workspaceCode={process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE}
						language="en"
						user={userInfo}
					/>
				)}
		</>
	);
}

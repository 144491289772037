import { Appliance, ApplianceActionsMap } from "./types";
import { ConfigStatusMap } from "./components/appliance-form-drawer/components/appliance-config-form/types";

const CODE_LENGTH = 9;

export function isValidActivationCode(code: string) {
	return code?.length === CODE_LENGTH;
}

export const pendingApplianceActions = (appliance?: Appliance) => {
	let actions: Array<string> = [];
	if (appliance?.pendingCommands?.length) {
		for (let action of appliance?.pendingCommands) {
			actions.push(
				ApplianceActionsMap[action.action as keyof typeof ApplianceActionsMap]
			);
		}
	}
	if (
		appliance?.gatekeeperConfigUpdateStatus === ConfigStatusMap.Pending ||
		appliance?.gatekeeperConfigUpdateStatus === ConfigStatusMap.Inprogress ||
		appliance?.gatekeeperConfigUpdateStatus === ConfigStatusMap.ForceUpdate
	) {
		actions.push(ApplianceActionsMap.CONFIG_UPDATE);
	}
	return actions;
};

export const getPendingActionsTooltip = (appliance?: Appliance) => {
	let tooltipMessage = "";
	let actions = pendingApplianceActions(appliance);
	if (actions.length) {
		tooltipMessage = window.getCTTranslatedText("disabledActionsTooltip");
	}
	for (const [index, action] of actions.entries()) {
		if (index !== 0) {
			tooltipMessage = tooltipMessage + " | ";
		}
		tooltipMessage = tooltipMessage + action;
	}
	return tooltipMessage;
};

import { IconsProps } from "./common";

export const InstallAgent = ({
	width = "20px",
	height = "20px",
}: IconsProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 387 258"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.2">
				<path
					d="M322.5 225.75C340.237 225.75 354.589 211.238 354.589 193.5L354.75 32.25C354.75 14.5125 340.237 0 322.5 0H64.5C46.7625 0 32.25 14.5125 32.25 32.25V193.5C32.25 211.238 46.7625 225.75 64.5 225.75H0V258H387V225.75H322.5ZM64.5 32.25H322.5V193.5H64.5V32.25Z"
					fill="#02adb5"
				/>
				<rect x="91" y="52" width="205" height="121" fill="#00ACC1" />
				<path
					d="M191.72 131.26C181.332 130.77 173.067 122.207 173.067 111.683C173.067 100.865 181.854 92.0732 192.667 92.0732C203.186 92.0732 211.745 100.342 212.235 110.735L205.374 108.676C204.035 102.891 198.841 98.6098 192.667 98.6098C185.448 98.6098 179.6 104.46 179.6 111.683C179.6 117.86 183.88 123.057 189.662 124.397L191.72 131.26ZM225.334 111.683C225.334 112.663 225.301 113.644 225.203 114.624L218.768 112.696C218.801 112.369 218.801 112.01 218.801 111.683C218.801 97.2371 207.106 85.5366 192.667 85.5366C178.228 85.5366 166.533 97.2371 166.533 111.683C166.533 126.129 178.228 137.829 192.667 137.829C192.994 137.829 193.353 137.829 193.68 137.797L195.607 144.235C194.627 144.333 193.647 144.366 192.667 144.366C174.635 144.366 160 129.724 160 111.683C160 93.642 174.635 79 192.667 79C210.699 79 225.334 93.642 225.334 111.683ZM213.019 125.606L225.334 121.488L192.667 111.683L202.467 144.366L206.583 132.044L220.532 146L227 139.529L213.019 125.606Z"
					fill="#F44336"
				/>
			</g>
		</svg>
	);
};

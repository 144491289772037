import { Chip, Stack, Tooltip } from "@mui/material";
import { ApplianceColumnRenderProps } from "pages/appliances/types";

export function AgentLanIp({ agent, viewOnly }: ApplianceColumnRenderProps) {
	let ips = [];
	if (agent?.lanIp) {
		ips.push(agent?.lanIp);
	}
	if (agent?.vlans?.length) {
		for (let vlan of agent.vlans) {
			ips.push(`${vlan.id?.trim()}: ${vlan.ipAddress?.trim()}`);
		}
	}

	let tooltipText = "";
	ips.forEach(ip => {
		if (tooltipText !== "") {
			tooltipText = tooltipText.concat(", ");
		}
		tooltipText = tooltipText.concat(ip);
	});

	return (
		<>
			{ips.length ? (
				<Tooltip
					title={tooltipText || ""}
					placement="bottom-start"
					arrow={false}
				>
					<Stack sx={{ maxWidth: "100%", my: 1 }} direction="row" spacing={1}>
						{ips.map(ip => {
							return <Chip key={ip} label={ip} />;
						})}
					</Stack>
				</Tooltip>
			) : (
				<Chip label={"No LAN/VLAN"} color={"default"} disabled={true} />
			)}
		</>
	);
}

import { Box, IconButton, Tooltip } from "@mui/material";
import { TemplateColumnRenderProps } from "pages/templates/types";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { TemplateRemoveEntity } from "pages/templates/components/template-remove-entity";
import { useState } from "react";

interface RemoveTemplateDialogProps extends TemplateColumnRenderProps {
	page?: string;
	policyId?: string;
}

export function RemoveTemplateDialog({
	template,
	page = "assets",
	policyId,
}: RemoveTemplateDialogProps) {
	const [allowRemove, setAllowRemove] = useState(false);

	const handleAllowRemove = (event: any, value: boolean) => {
		if (event) {
			event.stopPropagation();
		}
		setAllowRemove(value);
	};

	if (!template.templateId) {
		return <Box style={{ width: 100 }} />;
	}

	const isDisabled = template.assignedByTagBasedPolicy;

	return (
		<Box>
			{isDisabled ? (
				<Tooltip
					title={window.getCTTranslatedText(
						"Templates assigned by policies can not be removed"
					)}
				>
					<span>
						<IconButton aria-label="Remove template" disabled={true}>
							<DeleteOutlineIcon color="disabled" />
						</IconButton>
					</span>
				</Tooltip>
			) : (
				<IconButton
					aria-label="Remove template"
					onClick={$event => handleAllowRemove($event, true)}
				>
					<DeleteOutlineIcon color="action" />
				</IconButton>
			)}
			{allowRemove && (
				<TemplateRemoveEntity
					template={template}
					allowRemove={allowRemove}
					handleAllowRemove={handleAllowRemove}
					page={page}
					policyId={policyId}
				/>
			)}
		</Box>
	);
}

import { Box } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useAssetNetworks } from "modules/asset-networks/hooks";
import { AssignNetworksDrawer } from "modules/assign-networks-to-assets/components/assign-networks-drawer";
import { AssignedNetworksViewDrawer } from "modules/asset-networks/assigned-networks-view-drawer";
import { AssignNetworksOption } from "pages/tags/components/tag-policy-list/components/tag-networks/components/assigned-networks-tags/AssignedNetworksTags";

export function ManageAssetNetworks({
	criteria,
	multiselect = false,
	open,
	onClose,
	onConfirm,
}: {
	criteria: string;
	multiselect?: boolean;
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
}) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const {
		showAssignNetworksDrawer,
		openAssignNetworksDrawer,
		closeAssignNetworksDrawer,
		networkList,
		isLoading,
		onSortChange,
		page,
		pageSize,
		setPage,
		setPageSize,
		totalCount,
		networkListMutation,
		onSearchCriteriaChange,
	} = useAssetNetworks({ criteria });

	const onConfirmChanges = () => {
		closeAssignNetworksDrawer();
	};

	if (!criteria) {
		return <Box style={{ width: 42 }} />;
	}

	return (
		<>
			{showAssignNetworksDrawer && (
				<AssignNetworksDrawer
					isOpen={showAssignNetworksDrawer}
					page={"assets"}
					title="Assign Named Network"
					criteria={criteria}
					rules={[]}
					onCancel={closeAssignNetworksDrawer}
					onConfirm={onConfirmChanges}
					btnTitle={"assign"}
					multiselect={multiselect}
					hasPermission={userPermissions.has("UPDATE_ASSET")}
				/>
			)}

			{open && (
				<AssignedNetworksViewDrawer
					isOpen={open}
					onClose={onClose}
					networkList={networkList}
					networksSuggestMutation={networkListMutation}
					isLoading={isLoading}
					page="assets"
					onSortChange={onSortChange}
					AssignNetworksOption={() =>
						AssignNetworksOption({
							openAssignNetworksDrawer,
							hasPermission: userPermissions.has("UPDATE_ASSET"),
						})
					}
					multiselect={multiselect}
					criteria={criteria}
					hasPermission={userPermissions.has("UPDATE_ASSET")}
					setPageSize={setPageSize}
					setPage={setPage}
					pageSize={pageSize}
					pageCount={page}
					totalCount={totalCount}
					showSearch={false}
					onSearchCriteriaChange={onSearchCriteriaChange}
				/>
			)}
		</>
	);
}

import { Grid } from "@mui/material";
import { usePrevious } from "common/hooks/usePrevious";
import { CTScopeAutoSuggest } from "modules/ct-scope-auto-suggest";
import { AllowedField, Suggestion } from "modules/ct-scope-auto-suggest/types";
import { Scope } from "modules/scope-metadata/types";
import { PORTLESS_PROTOCOLS } from "pages/paths/types";
import { PortStatus } from "pages/ports/types";
import { TemplateType } from "pages/templates/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CTSelectDropDown } from "../ct-select";
import { isInvalidProcessPath } from "../template-form/helpers/validators";
import {
	PortFieldDisplayText,
	PortFieldKeys,
	PortFormHelperText,
	PortFormInt,
	PortFormProps,
	PortOptions,
	ProtocolOptionsList,
	RequiredPortFields,
} from "../template-form/types";

export function PortForm({
	port,
	templateType,
	updatePortData,
	showLabel = true,
	hideSource = false,
	hideProcess = false,
}: PortFormProps) {
	const [portSuggestion, setPortSuggestion] = useState<
		Suggestion | undefined
	>();
	const [portProcessSuggestion, setPortProcessSuggestion] = useState<
		Suggestion | undefined
	>();

	const wasPortLessProtocol = usePrevious(
		PORTLESS_PROTOCOLS.includes(port.listenPortProtocol?.toUpperCase())
	);

	const StatusOptionsList = useMemo(() => {
		if (templateType === TemplateType.ApplicationTemplate) {
			return [PortStatus.AllowIntranet, PortStatus.AllowAny];
		} else if (templateType === TemplateType.BlockTemplate) {
			return [PortStatus.Deny];
		}
		return [];
	}, [templateType]);

	const handlePortSuggestion = useCallback(
		(suggestion?: Suggestion) => {
			setPortSuggestion(suggestion);
			setFieldValue({
				data: port,
				key: PortFieldKeys.Port,
				value: suggestion?.value ?? "",
				updateData: updatePortData,
			});
		},
		[port, updatePortData]
	);

	const handlePortProcessUpdate = useCallback(
		(suggestion?: Suggestion) => {
			setPortProcessSuggestion(suggestion);
			setFieldValue({
				data: port,
				key: PortFieldKeys.Process,
				value: suggestion?.value ?? "",
				updateData: updatePortData,
			});
		},
		[port, updatePortData]
	);

	const handleUpdate = useCallback(
		({ field, value }: { field: string; value: string }) => {
			if (field) {
				setFieldValue({
					data: port,
					key: field as PortFieldKeys,
					value,
					updateData: updatePortData,
				});
			}
		},
		[port, updatePortData]
	);

	interface selectedValueProps {
		port: PortFormInt;
		key: PortFieldKeys;
	}

	const getSelectedValue = ({ port, key }: selectedValueProps): string => {
		return port && port[key] ? port[key] : "";
	};

	function isValidProcess() {
		let process = getSelectedValue({
			port,
			key: PortFieldKeys.Process,
		});
		return process ? isInvalidProcessPath(process) : true;
	}

	const protocolSelectedValue = getSelectedValue({
		port,
		key: PortFieldKeys.Protocol,
	});

	const isPortLessProtocol = PORTLESS_PROTOCOLS.includes(
		port.listenPortProtocol?.toUpperCase()
	);

	useEffect(() => {
		if (port.listenPortProtocol && isPortLessProtocol !== wasPortLessProtocol) {
			updatePortData({ ...port, listenPort: "any", listenProcessNames: "" });
			setPortSuggestion({ displayName: "any", type: "Default", value: "any" });
			setPortProcessSuggestion({ displayName: "", type: "Default", value: "" });
		}
	}, [
		updatePortData,
		setPortSuggestion,
		port,
		wasPortLessProtocol,
		isPortLessProtocol,
	]);

	return (
		<>
			<Grid container columnSpacing={3} sx={{ width: "100%" }}>
				<Grid item xs>
					<CTSelectDropDown
						field={PortFieldKeys.Protocol}
						displayName={PortFieldDisplayText[PortFieldKeys.Protocol]}
						selectedValue={protocolSelectedValue}
						handleUpdate={handleUpdate}
						data={ProtocolOptionsList}
						required={Boolean(RequiredPortFields[PortFieldKeys.Protocol])}
						showLabel={showLabel}
					></CTSelectDropDown>
				</Grid>
				{PortOptions.map((key: PortFieldKeys, index: number) => {
					return (
						<Grid item xs key={`${key}-${index}`}>
							{key && (
								<CTScopeAutoSuggest
									displayName={window.getCTTranslatedText("Port")}
									scopeConfig={[
										{
											scope: Scope.Port,
											field: AllowedField.Port,
										},
									]}
									required={true}
									showLabel={showLabel}
									selectedValue={portSuggestion}
									disabled={PORTLESS_PROTOCOLS.includes(
										protocolSelectedValue?.toUpperCase()
									)}
									defaultSearchText={
										isPortLessProtocol ? portSuggestion?.displayName : undefined
									}
									handleUpdate={handlePortSuggestion}
								/>
							)}
						</Grid>
					);
				})}
				{!hideProcess && (
					<Grid item xs>
						<CTScopeAutoSuggest
							displayName={PortFieldDisplayText[PortFieldKeys.Process]}
							selectedValue={portProcessSuggestion}
							handleUpdate={handlePortProcessUpdate}
							error={!isValidProcess()}
							scopeConfig={[{ scope: Scope.Port, field: AllowedField.Process }]}
							helperText={window.getCTTranslatedText(
								!isValidProcess()
									? PortFormHelperText.ProcessError
									: PortFormHelperText.Process
							)}
							required={Boolean(RequiredPortFields[PortFieldKeys.Process])}
							showLabel={showLabel}
							defaultSearchText={
								isPortLessProtocol
									? portProcessSuggestion?.displayName
									: undefined
							}
							disabled={portSuggestion?.value?.toLowerCase() === "any"}
						/>
					</Grid>
				)}
				{!hideSource && (
					<Grid item xs>
						<CTSelectDropDown
							field={PortFieldKeys.Status}
							displayName={PortFieldDisplayText[PortFieldKeys.Status]}
							selectedValue={getSelectedValue({
								port,
								key: PortFieldKeys.Status,
							})}
							handleUpdate={handleUpdate}
							data={StatusOptionsList}
							required={Boolean(RequiredPortFields[PortFieldKeys.Status])}
							showLabel={showLabel}
							disabled={Boolean(templateType === TemplateType.BlockTemplate)}
						/>
					</Grid>
				)}
			</Grid>
		</>
	);
}

interface FieldValueProps {
	data: PortFormInt;
	key: PortFieldKeys;
	value: Suggestion | string | any;
	updateData: Function;
}

function setFieldValue({ data, key, value, updateData }: FieldValueProps) {
	if (value !== data[key]) {
		let newDataObj = { ...data };
		newDataObj[key] = value;
		updateData(newDataObj);
	}
}

import { useQuery } from "@tanstack/react-query";

export interface Features {
	whitelisted: { [key: string]: { config: any } };
	blacklisted: { [key: string]: { config: any } };
}

export function useFeatureListAPI() {
	return useQuery<Features, Error>(
		[`featuresList`, `integrations/feature-config`],
		{
			staleTime: 1000 * 60 * 5,
		}
	);
}

import {
	Box,
	Button,
	CircularProgress,
	Paper,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { CopyButton } from "common/atoms/copy-button";
import { dayjsWithPlugins } from "common/utils";
import { AgentBaseDrawer } from "pages/appliances/components/agent-base-drawer";
import { DRAWER_TYPE } from "pages/appliances/components/agent-base-drawer/types";
import { useCallback, useState } from "react";
import { RenderContentProps } from "../agent-drawers/AgentUpgradeDrawer";

export const AgentOfflinePassword = () => {
	const [showAgentOfflinePasswordDrawer, setShowAgentOfflinePasswordDrawer] =
		useState<boolean>(false);

	const renderContent = useCallback(
		({ agentOtpData, isLoading = false }: RenderContentProps) => {
			const expiresOnDisplay = dayjsWithPlugins(
				agentOtpData?.expirationTime ?? 0
			).format("MMM DD, YYYY h:mm A");

			if (isLoading) {
				return (
					<Stack
						sx={{
							width: "100%",
							height: "100%",
						}}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<CircularProgress size={24} />
					</Stack>
				);
			}

			return (
				<>
					<Stack sx={{ mb: 3, width: "100%" }} spacing={1} gap={3}>
						<Typography variant="body2">
							{window.getCTTranslatedText("ReconnectAgentPasswordInstructions")}
						</Typography>

						<Box>
							<Typography variant="subtitle2" fontWeight="500">
								{window.getCTTranslatedText("Password")}
							</Typography>

							<Stack
								component={Paper}
								direction={"row"}
								justifyContent={"space-between"}
								alignItems={"center"}
								p={2}
							>
								<Typography variant="body2">{agentOtpData?.otp}</Typography>
								<CopyButton text={agentOtpData?.otp} />
							</Stack>
						</Box>

						<Box>
							<Typography variant="subtitle2" fontWeight="500">
								{window.getCTTranslatedText("Validity")}
							</Typography>
							<Typography variant="body2">
								{`${window.getCTTranslatedText("ExpiresOn")} ${expiresOnDisplay}`}
							</Typography>
						</Box>
					</Stack>
				</>
			);
		},
		[]
	);

	const onClose = useCallback(
		() => setShowAgentOfflinePasswordDrawer(false),
		[]
	);

	return (
		<Stack direction="row">
			<Tooltip
				title={window.getCTTranslatedText("GetPasswordToReconnectOfflineAgent")}
			>
				<span>
					<Button
						color="primary"
						variant="outlined"
						onClick={() => setShowAgentOfflinePasswordDrawer(true)}
					>
						{window.getCTTranslatedText("FirewallResetPassword")}
					</Button>
				</span>
			</Tooltip>

			{showAgentOfflinePasswordDrawer && (
				<AgentBaseDrawer
					isOpen={showAgentOfflinePasswordDrawer}
					onCancel={onClose}
					drawerType={DRAWER_TYPE.AGENT_OFFLINE_PASSWORD}
					renderContent={renderContent}
					drawerWidth="40%"
				/>
			)}
		</Stack>
	);
};

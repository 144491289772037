import { useEffect, useMemo, useState } from "react";
import { AssetDistributionData } from "../types";
import { useFacetSearch } from "modules/facets/components/facet-search/FacetSearch";
import debounce from "lodash/debounce";
import { Scope } from "modules/scope-metadata/types";

// Asset distribution stats
export function useAssetsDistributionStats({
	field,
	criteria,
	enabled,
}: {
	field: string;
	criteria: string | undefined;
	enabled: boolean;
}): {
	data: AssetDistributionData | undefined;
	isLoading: boolean;
	reload: Function;
} {
	const [data, setData] = useState<any>(undefined);

	const [loadTime, setLoadTime] = useState(Date.now());

	const mutation = useFacetSearch(field);

	const reset = mutation.reset;

	const mutate = useMemo(
		() => debounce(mutation.mutate, 300),
		[mutation.mutate]
	);

	useEffect(() => {
		reset();

		if (!field || !criteria || !enabled) {
			return;
		}

		const body = {
			criteria: criteria,
			facetField: field,
			facetFieldFilter: "",
			scope: Scope.Asset,
		};

		mutate(body, {
			onSuccess: response => {
				if (response?.Facet?.values) {
					const facetData = response?.Facet?.values;
					const total = getTotal(facetData);
					facetData["total"] = total || 0;
					setData(facetData);
				}
			},
		});
	}, [mutate, field, criteria, reset, loadTime, enabled]);

	return useMemo(
		() => ({
			data,
			reload: () => {
				setLoadTime(Date.now());
			},
			isLoading: mutation?.isLoading,
		}),
		[data, mutation?.isLoading]
	);

	function getTotal(obj: any) {
		return (Object.keys(obj) || []).reduce(
			(sum, key) => sum + parseFloat(obj[key] || 0),
			0
		);
	}
}

import { useQuery } from "@tanstack/react-query";
import { Diagnostics } from "pages/agents/types";
import { DiagnosticFetcherProps } from "../DiagnosticFetcher";

interface ApplianceDiagnosticOptions
	extends Pick<DiagnosticFetcherProps, "agentId"> {}
export function useDiagnosticAPI({ agentId }: ApplianceDiagnosticOptions) {
	return useQuery<Diagnostics, Error>([
		`agents/${agentId}/diagnostics`,
		`agents/${agentId}/diagnostics`,
	]);
}

export const getValidObjectEntries = (data: {
	[key: string]: string | undefined;
}) => {
	const validEntries = Object.entries(data).reduce((a: any, [k, v]) => {
		const value = v?.trim() ?? "";
		return value ? ((a[k] = value), a) : a;
	}, {});

	return validEntries;
};

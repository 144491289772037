import { FormControl, Stack, Typography } from "@mui/material";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { parseErrorMessage } from "common/utils";
import { Field, FieldType, TextFieldsValues } from "modules/form-fields/type";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	IntegrationConfigType,
	Reconciliation,
} from "pages/Integration/constants";
import { useServicenowAttributeAPI } from "pages/Integration/hooks";
import { IntegrationIdentifierMappings } from "pages/Integration/type";
import { useCallback, useEffect, useState } from "react";
import { IntegrationMappingComponent } from "./integrationMappingComponent";

interface MappingConfigProps {
	integration: IntegrationConfigType;
	textFieldsValues: TextFieldsValues;
	formConfig: any;
	fieldData: any;
	isFieldDataLoading: boolean;
	testTriggered: boolean;
	setTestTriggered: (data: boolean) => void;
	updateMappingData: (serviceNowData: Reconciliation) => void;
}

interface MappingReconciliationData {
	reconciliationIdentifier: IntegrationIdentifierMappings[];
	reconciliationAttributes: IntegrationIdentifierMappings[];
}

const enum ContainerName {
	IDENTIFIER = "Identifier",
	ATTRIBUTE = "Attribute",
}

export function MappingConfig({
	integration,
	textFieldsValues,
	formConfig,
	fieldData,
	isFieldDataLoading,
	testTriggered,
	setTestTriggered,
	updateMappingData,
}: MappingConfigProps) {
	const initialData = {
		integrationProvider: integration.integrationProvider,
		integrationType: integration.integrationType,
		integrationParameters: {
			clientId: "",
			clientSecret: "",
			refreshToken: "",
			instanceId: "",
		},
	};

	const attributeListMutation = useServicenowAttributeAPI(
		integration.integrationType,
		integration.integrationProvider
	);

	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const [addBtnDisable, setAddBtnDisable] = useState<boolean>(false);
	const maxAttributeSelect: number = 3;

	const [identifierOptionsList, setIdentifierOptionsList] = useState<any>([]);

	const [attributeOptionsList, setAttributeOptionsList] = useState<any>([]);

	const [serviceNowApiConfig, setServiceNowApiConfig] = useState<any>(
		fieldData?.integrationParameters || initialData.integrationParameters
	);

	const [servicenowIdentifierList, setServicenowIdentifierList] = useState<any>(
		[]
	);
	const [servicenowAttributeList, setServicenowAttributeList] = useState<any>(
		[]
	);
	const [identifierList, setIdentifierList] = useState<
		IntegrationIdentifierMappings[]
	>([]);
	const [attributeList, setAttributeList] = useState<
		IntegrationIdentifierMappings[]
	>([]);

	const setOptionList = useCallback((dataReconciliation: any) => {
		const identifierList = dataReconciliation
			? dataReconciliation.children.find((config: Field) => {
					return config.key === ContainerName.IDENTIFIER.toLowerCase();
				})
			: {};
		if (identifierList["possibleValues"].length) {
			const identifierData: any[] = [];
			identifierList["possibleValues"].forEach((obj: Field) => {
				identifierData.push({ value: obj.key, label: obj.displayName });
			});

			setIdentifierOptionsList(identifierData);
		}
		const attributeList = dataReconciliation
			? dataReconciliation.children.find((config: Field) => {
					return config.key === ContainerName.ATTRIBUTE.toLowerCase();
				})
			: {};
		if (attributeList["possibleValues"].length) {
			const attributeData: any[] = [];
			attributeList["possibleValues"].forEach((obj: Field) => {
				attributeData.push({ value: obj.key, label: obj.displayName });
			});

			setAttributeOptionsList(attributeData);
		}
	}, []);

	useEffect(() => {
		if (formConfig) {
			const dataReconciliation = formConfig.find((config: Field) => {
				return (
					config.key === "Data Reconciliation" &&
					config.type === FieldType.MAPPING
				);
			});
			if (Object.keys(dataReconciliation).length) {
				setOptionList(dataReconciliation);
			}
		}
	}, [formConfig, setOptionList]);

	const [isFieldDataPresent, setIsFieldDataPresent] = useState<boolean>(false);
	const [showDataReconciliation, setShowDataReconciliation] =
		useState<boolean>(false);
	const [hasFetchedAttributes, setHasFetchedAttributes] = useState(false);
	type ReconciliationData = {
		reconciliationIdentifier: IntegrationIdentifierMappings[];
		reconciliationAttributes: IntegrationIdentifierMappings[];
	};
	let reconciliationData: ReconciliationData = {
		reconciliationIdentifier: [],
		reconciliationAttributes: [],
	};
	const [dataReconciliationData, setDataReconciliationData] =
		useState<Reconciliation>(reconciliationData);

	const [containerName, setContainerName] = useState<string>("");

	const buildData = useCallback(
		(data: any, type?: string) => {
			const updatedDataReconciliationData = { ...dataReconciliationData };

			if (type) {
				if (type === ContainerName.IDENTIFIER) {
					updatedDataReconciliationData.reconciliationIdentifier = data;
				} else if (type === ContainerName.ATTRIBUTE) {
					updatedDataReconciliationData.reconciliationAttributes = data;
				}
			} else {
				updatedDataReconciliationData.reconciliationIdentifier =
					data.reconciliationIdentifier;
				updatedDataReconciliationData.reconciliationAttributes =
					data.reconciliationAttributes;
			}

			setDataReconciliationData(updatedDataReconciliationData);
			updateMappingData(updatedDataReconciliationData);
		},
		[dataReconciliationData, updateMappingData, setDataReconciliationData]
	);

	const setAttributeData = useCallback(
		(response: any, containerName: string) => {
			if (response) {
				const dataWithoutChildrens = response.filter((obj: any) => {
					return !obj.childAttributes;
				});
				setServicenowAttributeList(response);
				setServicenowIdentifierList(dataWithoutChildrens);
				if (containerName === ContainerName.IDENTIFIER) {
					const newIdentifier = [...identifierList];
					newIdentifier.push({ ctField: "", integrationField: [] });
					setIdentifierList(newIdentifier);
				}
				if (containerName === ContainerName.ATTRIBUTE) {
					const newAttribute = [...attributeList];
					newAttribute.push({ ctField: "", integrationField: [] });
					setAttributeList(newAttribute);
					setIsFieldDataPresent(true);
				}
				setAddBtnDisable(false);
			}
		},
		[attributeList, identifierList]
	);

	const getAllAttributes = useCallback(
		(containerName = "") => {
			if (!hasFetchedAttributes) {
				setTestTriggered(false);
				const body = {
					integrationProvider: integration.integrationProvider,
					integrationType: integration.integrationType,
					integrationParameters: {
						clientId:
							serviceNowApiConfig?.clientId ||
							fieldData?.integrationParameters?.clientId ||
							"",
						clientSecret:
							serviceNowApiConfig?.clientSecret ||
							fieldData?.integrationParameters?.clientSecret ||
							"",
						refreshToken:
							serviceNowApiConfig?.refreshToken ||
							fieldData?.integrationParameters?.refreshToken ||
							"",
						instanceId:
							serviceNowApiConfig?.instanceId ||
							fieldData?.integrationParameters?.instanceId ||
							"",
					},
				};
				if (containerName) {
					setAddBtnDisable(true);
				}
				attributeListMutation.mutate(body, {
					onSuccess: response => {
						if (response?.items) {
							setAttributeData(response.items, containerName);
						}
						setHasFetchedAttributes(true);
						setShowDataReconciliation(true);
						setContainerName("");
					},
					onError: error => {
						setAddBtnDisable(false);
						setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
						setHasFetchedAttributes(false);
					},
				});

				setHasFetchedAttributes(true);
			}
		},
		[
			hasFetchedAttributes,
			integration,
			fieldData,
			setSnackbar,
			setAttributeData,
			setTestTriggered,
			serviceNowApiConfig,
			attributeListMutation,
		]
	);

	const [firstTimeData, setFirstTimeData] = useState<boolean>(true);

	useEffect(() => {
		if (fieldData?.integrationParameters && firstTimeData) {
			setServiceNowApiConfig((prevConfig: any) => ({
				...prevConfig,
				...fieldData.integrationParameters,
			}));
			let mappingReconciliationData: MappingReconciliationData = {
				reconciliationIdentifier: [],
				reconciliationAttributes: [],
			};
			const reconciliationAttributes =
				fieldData.integrationParameters?.reconciliationAttributes ?? [];
			let output: IntegrationIdentifierMappings[] = [];
			if (!reconciliationAttributes.length) {
				output = [{ ctField: "", integrationField: [] }];
			} else {
				output = fieldData.integrationParameters?.reconciliationAttributes;
			}
			setAttributeList(output);
			mappingReconciliationData.reconciliationAttributes = output;
			const reconciliationIdentifier =
				fieldData.integrationParameters?.reconciliationIdentifier ?? [];
			let outputreconciliationIdentifier: IntegrationIdentifierMappings[] = [];
			if (!reconciliationIdentifier.length) {
				outputreconciliationIdentifier = [
					{ ctField: "", integrationField: [] },
				];
			} else {
				outputreconciliationIdentifier =
					fieldData.integrationParameters?.reconciliationIdentifier;
			}
			setIdentifierList(outputreconciliationIdentifier);
			mappingReconciliationData.reconciliationIdentifier =
				outputreconciliationIdentifier;
			buildData(mappingReconciliationData);
			if (!showDataReconciliation) {
				setShowDataReconciliation(false);
			}
			setIsFieldDataPresent(true);
			setFirstTimeData(false);
		} else {
			setIsFieldDataPresent(false);
			setShowDataReconciliation(true);
		}
	}, [fieldData, firstTimeData, buildData, showDataReconciliation]);

	useEffect(() => {
		if (!isFieldDataLoading && isFieldDataPresent) {
			getAllAttributes(containerName);
		}
	}, [isFieldDataLoading, isFieldDataPresent, containerName, getAllAttributes]);

	useEffect(() => {
		setServiceNowApiConfig(textFieldsValues);
	}, [textFieldsValues]);

	useEffect(() => {
		if (testTriggered) {
			setHasFetchedAttributes(false);
			getAllAttributes("");
		}
	}, [testTriggered, getAllAttributes]);

	const handleIdentifierChange = (e: TextFieldUpdate, index: number) => {
		if (e?.value !== undefined) {
			const value = e?.value.trim() || "";
			let newIdentifierList = [...identifierList];
			newIdentifierList[index].ctField = value;

			const uniqueIdentiferList = newIdentifierList.filter((obj, index) => {
				return (
					index === newIdentifierList.findIndex(o => obj.ctField === o.ctField)
				);
			});
			setIdentifierList(uniqueIdentiferList);
			buildData(uniqueIdentiferList, ContainerName.IDENTIFIER);
		}
	};

	const handleServiceNowIdentifierChange = (event: any, index: any) => {
		const value = event?.value;
		let newIdentifierList = [...identifierList];
		newIdentifierList[index].integrationField = value;

		setIdentifierList(newIdentifierList);
		buildData(newIdentifierList, ContainerName.IDENTIFIER);
	};

	const addNewIdentifier = () => {
		if (!servicenowIdentifierList.length) {
			if (!identifierList.length) {
				setHasFetchedAttributes(false);
				setContainerName(ContainerName.IDENTIFIER);
				getAllAttributes(ContainerName.IDENTIFIER);
			}
		} else {
			const newIdentifier = [...identifierList];
			newIdentifier.push({ ctField: "", integrationField: [] });
			setIdentifierList(newIdentifier);
			buildData(newIdentifier, ContainerName.IDENTIFIER);
		}
	};

	const removeIdentifier = (index: number) => {
		const newIdentifier = [...identifierList];
		newIdentifier.splice(index, 1);
		setIdentifierList(newIdentifier);
		buildData(newIdentifier, ContainerName.IDENTIFIER);
	};

	const handleAttributeChange = (e: TextFieldUpdate, index: number) => {
		if (e?.value !== undefined) {
			const value = e?.value.trim() || "";
			let newAttributeList = [...attributeList];
			newAttributeList[index].ctField = value;
			if (value === "application") {
				newAttributeList[index].integrationField = [value];
			}

			const uniqueAttributeList = newAttributeList.filter((obj, index) => {
				return (
					index === newAttributeList.findIndex(o => obj.ctField === o.ctField)
				);
			});
			setAttributeList(uniqueAttributeList);
			buildData(uniqueAttributeList, ContainerName.ATTRIBUTE);
		}
	};

	const handleServiceNowAttributeChange = (event: any, index?: any) => {
		const value = event?.value;
		let newAttributeList = [...attributeList];
		newAttributeList[index].integrationField = value;

		setAttributeList(newAttributeList);
		buildData(newAttributeList, ContainerName.ATTRIBUTE);
	};

	const addNewAttribute = () => {
		if (!servicenowAttributeList.length) {
			if (!attributeList.length) {
				setHasFetchedAttributes(false);
				setContainerName(ContainerName.ATTRIBUTE);
				getAllAttributes(ContainerName.ATTRIBUTE);
			}
		} else {
			const newAttribute = [...attributeList];
			newAttribute.push({ ctField: "", integrationField: [] });
			setAttributeList(newAttribute);
			buildData(newAttribute, ContainerName.ATTRIBUTE);
		}
	};

	const removeAttribute = (index: number) => {
		const newAttribute = [...attributeList];
		newAttribute.splice(index, 1);
		setAttributeList(newAttribute);
		buildData(newAttribute, ContainerName.ATTRIBUTE);
	};

	return (
		<>
			<FormControl sx={{ m: 0, minWidth: "100%" }}>
				<Stack
					alignItems={"flex-start"}
					style={{ position: "relative", height: "100%" }}
				></Stack>
				{showDataReconciliation && (
					<Stack
						alignItems={"flex-start"}
						style={{ position: "relative", width: "100%" }}
					>
						<Typography variant="body1" sx={{ mt: 0, mb: 2 }}>
							{window.getCTTranslatedText("Data Reconciliation")}
						</Typography>
						<IntegrationMappingComponent
							title="Identifier"
							itemList={identifierList}
							itemOptionsList={identifierOptionsList}
							addNewBtnDisable={addBtnDisable}
							addNewItem={addNewIdentifier}
							removeItem={removeIdentifier}
							handleItemChange={handleIdentifierChange}
							handleServiceNowItemChange={handleServiceNowIdentifierChange}
							servicenowIdentifierList={servicenowIdentifierList}
							maxValueSelect={maxAttributeSelect}
						/>
						<IntegrationMappingComponent
							title="Attribute"
							itemList={attributeList}
							itemOptionsList={attributeOptionsList}
							addNewBtnDisable={addBtnDisable}
							addNewItem={addNewAttribute}
							removeItem={removeAttribute}
							handleItemChange={handleAttributeChange}
							handleServiceNowItemChange={handleServiceNowAttributeChange}
							servicenowIdentifierList={servicenowAttributeList}
							maxValueSelect={maxAttributeSelect}
						/>
					</Stack>
				)}
			</FormControl>
		</>
	);
}

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Button, Paper, Stack } from "@mui/material";

interface PolicyAutomationActionsProps {
	isDeployLoading: boolean;
	updateDeployMode: VoidFunction;
	cancel: VoidFunction;
}

export const PolicyAutomationActions = ({
	updateDeployMode,
	cancel,
	isDeployLoading,
}: PolicyAutomationActionsProps) => {
	return (
		<>
			<Paper
				elevation={2}
				sx={{
					borderRadius: 0,
					width: "100%",
					backgroundColor: theme =>
						theme.palette.mode === "dark"
							? theme.palette.background.paper
							: theme.palette.background.default,
					px: 3,
					py: 2,
				}}
			>
				<Stack
					justifyContent={"flex-end"}
					alignContent={"flex-end"}
					alignItems="flex-end"
					direction="row"
					spacing={2}
				>
					<Button variant="outlined" onClick={cancel}>
						{window.getCTTranslatedText("Cancel")}
					</Button>
					<LoadingButton
						loading={isDeployLoading}
						color="primary"
						variant="contained"
						onClick={updateDeployMode}
					>
						{window.getCTTranslatedText("Save")}
					</LoadingButton>
				</Stack>
			</Paper>
		</>
	);
};

import { useQuery } from "@tanstack/react-query";
import { ACTIVATION_STATUS_INTERVAL } from "pages/appliances/constants";

interface StatusResult {
	activationCompleted: boolean;
}

export function useActivationStatusCheck(
	isEnabled: boolean,
	statusFileName?: string
) {
	return useQuery<StatusResult, Error>(
		["activation-status", `gateways/activationstatus/${statusFileName}`],
		{
			enabled: Boolean(isEnabled && statusFileName),
			refetchInterval: ACTIVATION_STATUS_INTERVAL,
		}
	);
}

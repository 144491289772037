import { Stack } from "@mui/material";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { TagRule } from "../../types";
import { useTagRuleStore } from "../../store";

export interface TagRulesToolbarProps extends Omit<ToolbarProps, "children"> {
	selectedData: Array<TagRule> | undefined;
}

export default function TagRulesToolbar(props: TagRulesToolbarProps) {
	// const actions = (
	//   <Stack
	//     direction="row"
	//     spacing={2}
	//     alignItems={"center"}
	//     sx={{ height: "100%" }}
	//   ></Stack>
	// );
	const requestAPIRefresh = useTagRuleStore(store => store.requestAPIRefresh);
	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}>
			<Stack
				direction="row"
				spacing={2}
				sx={{ width: "100%" }}
				justifyItems="flex-end"
				alignItems={"center"}
				justifyContent="flex-end"
			></Stack>
		</GridToolbar>
	);
}

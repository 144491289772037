import { useTheme } from "@mui/material";

export const ArcSightIcon = () => {
	const theme = useTheme();
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.9736 11C16.3607 11 10.9999 16.3608 10.9999 22.9737V37C17.6128 37 22.9736 31.6392 22.9736 25.0263V11Z"
				fill="#1A6AFF"
			/>

			<path
				d="M25.0259 11C31.6388 11 36.9996 16.3608 36.9996 22.9737V37C30.3867 37 25.0259 31.6392 25.0259 25.0263V11Z"
				fill="#1A6AFF"
			/>

			<rect
				x="0.5"
				y="0.5"
				width="47"
				height="47"
				rx="3.5"
				stroke={theme.palette.text.primary}
				strokeOpacity="0.12"
			/>
		</svg>
	);
};

import { Stack } from "@mui/material";
import { PathStatus } from "pages/paths/types";
import { useVisxStore } from "pages/traffic-visualizer/store";
import { PathReviewStatus, PathTypes } from "pages/traffic-visualizer/types";
import { PathLegend } from "../PathLegend";

interface PathLegendsGroupProps {
	statuses: Array<PathStatus | "Mixed">;
	enabledStatus?: Map<PathTypes, boolean> | undefined;
	setStatusEnabled: (status: PathTypes, enabled: boolean) => void;
}

export const UnsupportedStatuses = {
	[PathReviewStatus.Enforced]: [
		PathStatus.AllowedByTemplate,
		PathStatus.DeniedByTemplate,
		PathStatus.AllowedByProgressive,
	],
	[PathReviewStatus.DIFF]: [],
	[PathReviewStatus.WIP]: [
		PathStatus.AllowTestDenied,
		PathStatus.AllowTestDeniedViolation,
	],
};

export const PathStatuses: { [key: string]: PathTypes } = {
	...PathStatus,
	Mixed: "Mixed",
};

export const allowedStatuses = [
	PathStatuses.Allow,
	PathStatuses.AllowedByTestUIOnly,
	PathStatuses.Unreviewed,
];

export const deniedStatuses = [PathStatuses.Deny];

export const mixedStatuses: Array<PathStatus | "Mixed"> = [PathStatuses.Mixed];

export const isSupportedFilterType = (
	pathReviewStatus: PathReviewStatus,
	type: PathTypes
) => {
	const unsupported = UnsupportedStatuses[pathReviewStatus];
	if (unsupported && unsupported.findIndex(a => a === type) > -1) {
		return false;
	}
	return true;
};

export const PathLegendsGroup = ({
	statuses,
	enabledStatus,
	setStatusEnabled,
}: PathLegendsGroupProps) => {
	const selectedPathStatus = useVisxStore(state => state.selectedPathStatus);

	return (
		<Stack direction="row" alignItems="center" justifyContent="flex-start">
			{statuses.map((status, index) => {
				if (!isSupportedFilterType(selectedPathStatus, status)) {
					return null;
				}

				return (
					<Stack
						sx={{ pr: index === statuses.length - 1 ? 0 : 2 }}
						key={status}
					>
						<PathLegend
							status={status}
							enabled={enabledStatus?.get(status) ?? false}
							setEnabled={enabled => {
								setStatusEnabled(status, enabled);
							}}
							disabled={status === PathStatuses.Mixed}
						/>
					</Stack>
				);
			})}
		</Stack>
	);
};

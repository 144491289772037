import {
	Divider,
	LinearProgress,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { DataGridButton } from "common/atoms/data-grid-button";
import numeral from "numeral";
import { SecurityLevels } from "pages/assets/types";
import React, { useMemo } from "react";
import { SingleSecurityLevelTextButton } from "./components/singleSecurityLevelText";

export interface ListDatum {
	label: string;
	name: string;
	unreviewed: number;
	total: number;
}

export interface SingleClassificationData {
	loading?: boolean;
	status?: SecurityLevels;
	userStatus?: string;
	data: Array<ListDatum>;
}

const ASSET_FIELDS_PRIORITY: { [key: string]: number } = {
	ports: 0,
	paths: 2,
	userPath: 1,
};

export interface ReviewDetailsInt {
	data: ListDatum;
	unreviewed: boolean;
}

export interface SingleAssetClassificationByLevelsProps {
	hide?: boolean;
	data: SingleClassificationData;
	type: string;
	category: string;
	reviewDetails: ({ data, unreviewed }: ReviewDetailsInt) => void;
}

const countStyle = {
	fontSize: "18px",
	px: 2,
	py: 1,
};
const dividerStyle = {
	height: "18px",
	width: "2px",
};

const sectionDividerStyle = {
	height: "60px",
	width: "2px",
};

const labelStyle = {
	textTransform: "uppercase",
	letterSpacing: "1",
	fontSize: "12px",
};

export const SingleAssetClassificationByLevels = ({
	hide,
	data: config,
	type,
	category,
	reviewDetails,
}: SingleAssetClassificationByLevelsProps) => {
	const linksData: ListDatum[] = useMemo(() => {
		let sortedData: ListDatum[] = [];

		config.data.forEach((option: ListDatum) => {
			const idx: number = ASSET_FIELDS_PRIORITY[option.name];
			sortedData[idx] = option;
		});

		return sortedData;
	}, [config]);

	const processBtnClickCallback = (text: SecurityLevels) => {};

	const processLinkCallback = (data: ListDatum, unreviewed: boolean) => {
		reviewDetails({ data, unreviewed });
	};

	if (config?.loading) {
		return <LinearProgress sx={{ width: "100%" }} variant="indeterminate" />;
	}

	return (
		<Stack direction="row" alignItems="flex-start" sx={{ p: 3, pb: 1 }}>
			<Stack sx={{ width: "40%", minWidth: "110px" }}>
				<Stack direction="row" alignItems="flex-start" sx={{ width: "100%" }}>
					{!hide && (
						<SingleSecurityLevelTextButton
							status={config?.status || SecurityLevels.Low}
							onClickCallback={processBtnClickCallback}
							category={category}
							viewOnly={true}
						></SingleSecurityLevelTextButton>
					)}
					<Stack
						direction={"row"}
						alignItems={"center"}
						borderRadius={1}
						sx={{
							px: 3,
							py: 2,
						}}
					>
						<Typography variant="body1" sx={{ textTransform: "capitalize" }}>
							{config.userStatus}
						</Typography>
					</Stack>
				</Stack>
			</Stack>
			{!hide && (
				<Stack
					direction="row"
					justifyContent="flex-end"
					sx={{ width: "60%" }}
					spacing={2}
				>
					{linksData?.length &&
						(linksData || []).map((option: any, index: number) => {
							return (
								<React.Fragment key={`${type}-${index}`}>
									<Stack
										alignItems="flex-end"
										justifyContent="flex-end"
										justifyItems={"flex-end"}
										spacing={0}
										sx={{
											height: "60px",
										}}
									>
										<Stack
											sx={{
												minHeight: "60px",
												textAlign: "right",
												borderRadius: 0,
												width: "100%",
											}}
										>
											<Stack alignItems={"center"}>
												<Typography variant="caption" sx={labelStyle}>
													{window.getCTTranslatedText(`${option?.label}`)}
												</Typography>
											</Stack>
											<Stack
												alignItems={"center"}
												alignContent={"center"}
												justifyContent={"center"}
												direction="row"
												spacing={0}
											>
												<Tooltip
													title={window.getCTTranslatedText("Unreviewed")}
												>
													<span>
														<DataGridButton
															onClick={() => processLinkCallback(option, true)}
															disabled={option?.unreviewed === 0}
														>
															<Typography
																variant="h5"
																sx={{
																	...countStyle,
																	color:
																		option?.unreviewed === 0
																			? "divider"
																			: "warning.main",
																}}
															>
																{numeral(option?.unreviewed).format("0a")}
															</Typography>
														</DataGridButton>
													</span>
												</Tooltip>
												<Divider
													orientation={"vertical"}
													sx={dividerStyle}
												></Divider>
												<Tooltip title={window.getCTTranslatedText("Total")}>
													<span>
														<DataGridButton
															onClick={() => processLinkCallback(option, false)}
															disabled={option?.total === 0}
														>
															<Typography
																variant="h5"
																sx={{
																	...countStyle,
																	color:
																		option?.total === 0
																			? "divider"
																			: "primary.main",
																}}
															>
																{numeral(option?.total).format("0a")}
															</Typography>
														</DataGridButton>
													</span>
												</Tooltip>
											</Stack>
										</Stack>
									</Stack>
									{index < linksData?.length - 1 && (
										<Divider
											orientation={"vertical"}
											sx={sectionDividerStyle}
										></Divider>
									)}
								</React.Fragment>
							);
						})}
				</Stack>
			)}
		</Stack>
	);
};

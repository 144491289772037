import { Box, Stack, Tooltip } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { HostGroupColumnRenderProps } from "pages/crowdstrike-groups/host-groups/types";

export function HostGroupName({
	hostGroup,
	viewOnly = false,
}: HostGroupColumnRenderProps) {
	const hostGroupName = hostGroup.hostgroupname;

	if (!hostGroupName) {
		return <Box style={{ minWidth: 310, minHeight: 45 }} />;
	}

	return (
		<Stack
			sx={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
			alignItems="left"
			justifyContent="center"
		>
			<Tooltip
				title={
					viewOnly
						? ""
						: `${window.getCTTranslatedText("Show only for")} ${hostGroupName}`
				}
			>
				<CTDatagridButton
					asText={viewOnly === true}
					isDisabled={viewOnly === true}
					facetName={"hostgroupname"}
					facetOption={hostGroupName!}
				>
					{hostGroupName}
				</CTDatagridButton>
			</Tooltip>
		</Stack>
	);
}

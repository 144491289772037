import { PendingActions } from "@mui/icons-material";
import { CircularProgress, Stack } from "@mui/material";
import { usePollPendingWorkRequest } from "modules/AppBar/hooks";

export const BackgroundProcessesMenuItem = () => {
	const { badgeConfig, isLoading } = usePollPendingWorkRequest();

	const renderIcon = () => {
		if (isLoading) {
			return (
				<CircularProgress
					sx={{
						width: "16px !important",
						height: "16px !important",
					}}
				/>
			);
		}

		if (badgeConfig.count === 0) {
			return null;
		}

		return <PendingActions color="primary" fontSize="small" />;
	};

	return (
		<Stack width="100%" direction="row" justifyContent="space-between">
			{window.getCTTranslatedText("Background Processes")}
			{renderIcon()}
		</Stack>
	);
};

import { ROUTES } from "routes";
import { routeObjInt } from "../types";
import { PREVENT_BREADCRUMBS, getValidRoutes } from "./getValidRoutes";

export const generateBreadCrumbs = (routesPaths: Array<string>) => {
	const validRoutes = getValidRoutes();
	let breadCrumbs: Array<routeObjInt> = [];

	let preventedBreadcrumbs = false;
	(routesPaths || []).forEach((route, index) => {
		let routeObj: routeObjInt = {};
		const routeId = route?.replaceAll("-", "_")?.toUpperCase();
		const routeConfig = ROUTES[routeId];
		routeObj["title"] = routeConfig?.title || route;

		const linkTo = routeConfig?.redirect || `/${route}`;
		if (routeConfig?.title) routeObj["route"] = linkTo;
		routeObj["link"] = true;
		if (routeConfig?.secondaryBreadcrumbTitle) {
			breadCrumbs = [
				{
					title: routeConfig.title || " ",
					route: `/${routeConfig.title}`,
					link: true,
				},
				{
					title: `${getDetailPageTitle(
						routeConfig.secondaryBreadcrumbTitle
					)} details`,
					route: "",
					link: false,
				},
			];
			preventedBreadcrumbs = true;
		}
		if (index + 1 === routesPaths?.length) {
			if (!validRoutes[route] && breadCrumbs[index - 1]) {
				routeObj["title"] = routeConfig?.displayTitle
					? routeConfig.title
					: `${getDetailPageTitle(
							breadCrumbs[index - 1]?.title ?? ""
						)} details`;
				routeObj["id"] = route;
			}
			routeObj["link"] = false;
		}

		if (!preventedBreadcrumbs) {
			breadCrumbs.push(routeObj);
		}

		preventedBreadcrumbs = preventedBreadcrumbs || PREVENT_BREADCRUMBS[route];
	});

	return breadCrumbs;
};

const getDetailPageTitle = (title: string) => {
	const noReduceIndex =
		title.toLowerCase() === "clone" || title.toLowerCase() === "edit";
	return title?.substring(0, noReduceIndex ? title?.length : title?.length - 1);
};

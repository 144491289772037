import { Box, Stack, Typography } from "@mui/material";
import { AssetMetaDataReadonlyProps } from "pages/assets/types";

export function AssetTrafficConfig({ asset }: AssetMetaDataReadonlyProps) {
	if (!asset?.assetId) {
		return <Box style={{ width: 42 }} />;
	}

	return (
		<Stack
			direction={"row"}
			spacing={3}
			alignItems="center"
			justifyContent="flex-start"
			sx={{ width: "100%", minHeight: "36.5px" }}
		>
			<Typography variant="subtitle1">
				<b>{window.getCTTranslatedText("trafficConfig")}</b>
			</Typography>
			<Stack
				sx={{ mr: 1, flex: 1 }}
				direction="row"
				spacing={1}
				alignItems="center"
			>
				<Typography variant="body2">
					{window
						.getCTTranslatedText(`${asset.currentTrafficConfiguration}`)
						.toLocaleUpperCase()}
				</Typography>
			</Stack>
		</Stack>
	);
}

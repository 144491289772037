import { Box, Stack, Typography } from "@mui/material";
import { AppliedFacetChipsLocation } from "common/atoms/facet-open-close-controller/FacetOpenCloseController";
import { CustomCommonStoreType } from "common/types/types";
import { useAntlrParser } from "hooks/useAntlrParser/useAntlrParser";
import { decodeThrowable } from "modules/facets/hooks/useFacetQueryConnector";
import { NIL } from "pages/paths/constants";
import { CriteriaVisitor, NodeValue } from "parser/CriteriaVisitor";
import { AppliedFacetsChips } from "../applied-facets-chips";

function transformVisitorObject(input: Record<string, NodeValue>): string {
	const transformedPairs: string[] = [];

	for (const [key, node] of Object.entries(input)) {
		const { values, operator } = node;
		const transformedValues = values.map(value => value || NIL).join(",");
		transformedPairs.push(`${key}${operator}${transformedValues}`);
	}

	return transformedPairs.join("|");
}

type CriteriaData = {
	[key: string]: any;
	criteriaAsParams?: string | undefined;
	criteria?: string | undefined;
};

interface Props {
	data: CriteriaData;
	useFacetStore: CustomCommonStoreType;
	isValid: boolean;
}

function CriteriaChips({ data, useFacetStore, isValid }: Props) {
	const parseCriteria = useAntlrParser();

	if (!isValid) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	let urlParams = new URLSearchParams(data.criteriaAsParams);
	let filters = decodeURIComponent(urlParams.get("filters") ?? "");
	let search = urlParams.get("search");

	const getFacetState = () => {
		if (data?.criteriaAsParams) {
			return decodeThrowable(filters);
		}

		const parseResult = parseCriteria(data?.criteria ?? "");
		if (parseResult) {
			const visitor = new CriteriaVisitor();
			const criteriaString = transformVisitorObject(visitor.visit(parseResult));
			return decodeThrowable(criteriaString);
		}
	};

	let facetState = getFacetState();

	if (!facetState && search) {
		facetState = new Map();
	}

	if (search && facetState) {
		let options = new Map();
		options.set(search, {
			isSelected: true,
		});
		facetState?.set("Query", options);
	}

	const facetHasKey = Array.from(facetState?.keys() || [])?.[0]?.length > 0;

	return (
		<Stack
			sx={{
				ml: facetHasKey ? -1 : 0,
				width: "100%",
				"& .facet-chips": {
					flexWrap: "nowrap",
				},
			}}
		>
			{facetHasKey ? (
				<>
					<AppliedFacetsChips
						useFacetStore={useFacetStore}
						appliedChipsLocation={AppliedFacetChipsLocation.SAVED_QUERY}
						facetState={facetState}
						sortByName={true}
						facetChipProps={{
							size: "small",
							sx: {
								fontSize: "12px",
							},
						}}
					/>
				</>
			) : (
				<>
					<Typography variant="body2">{data?.criteria}</Typography>
				</>
			)}
		</Stack>
	);
}

export default CriteriaChips;

export enum AlertStatus {
	PENDING = "Pending",
	DISMISSED = "Dismissed",
}

export enum AlertFilter {
	tab = "alerts",
	filters = "alertstatus",
}

export interface AlertsCountReq {
	criteria: string;
	groupBy: [];
	statistics: string[];
	scope: string;
}

export interface AlertResponse {
	items: {
		statistics: {
			alerthashcount: number;
		};
	};
}

import {
	Alert,
	Box,
	Button,
	Chip,
	DialogActions,
	DialogContent,
	DialogTitle,
	Drawer,
	Link,
	Stack,
	Typography,
	styled,
} from "@mui/material";

import { Toolbar } from "common/atoms/toolbar";
import { getArtifactOrigin } from "common/utils";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import {
	AGENT_TYPES,
	CORE_OS,
	EMPTY_STRING,
	PLATFORMS,
	installScript,
} from "./constants";

interface AgentDownloadDrawerProps {
	drawerOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
	platform: string;
	installationType: string;
	agentType?: AGENT_TYPES;
}

const StyledChip = styled(Chip)(() => ({
	// borderRadius: 0,
	color: "inherit",
	background: "secondary",
	font: "inherit",
}));
export const AgentDrawer = ({
	drawerOpen,
	onClose,
	children,
	platform,
	installationType,
	agentType,
}: AgentDownloadDrawerProps) => {
	const basePlatform = (platform: string) => {
		switch (platform) {
			case PLATFORMS.WINDOWS:
				return CORE_OS[PLATFORMS.WINDOWS];
			case PLATFORMS.DEBIAN:
				return CORE_OS[PLATFORMS.DEBIAN];
			case PLATFORMS.RPM:
				return CORE_OS[PLATFORMS.RPM];
			case PLATFORMS.DARWIN:
				return CORE_OS[PLATFORMS.DARWIN];
			case PLATFORMS.DOCKER:
				return CORE_OS[PLATFORMS.DOCKER];
			case PLATFORMS.AIX:
				return CORE_OS[PLATFORMS.AIX];
			case PLATFORMS.RPM_LEGACY:
				return CORE_OS[PLATFORMS.RPM_LEGACY];
			case PLATFORMS.SUSE:
				return CORE_OS[PLATFORMS.SUSE];
			case PLATFORMS.SOLARIS:
				return CORE_OS[PLATFORMS.SOLARIS];
			case PLATFORMS.OPENSHIFT:
				return CORE_OS[PLATFORMS.OPENSHIFT];
		}
	};

	const artifactURL = getArtifactOrigin();
	const brewInstallLink = "https://brew.sh/";
	const curlInstallLink = "https://curl.se/";
	const kubectlInstallLink =
		"https://docs.aws.amazon.com/eks/latest/userguide/install-kubectl.html";
	const helmInstallLink = "https://helm.sh/docs/intro/install/";

	const linuxCertLocation = (platform: string) => {
		switch (platform) {
			case PLATFORMS.RPM:
				return `/etc/pki/ca-trust/source/anchors`;
			case PLATFORMS.DEBIAN:
				return `/usr/local/share/ca-certificates`;
			case PLATFORMS.SUSE:
				return `/etc/pki/trust/anchors`;
			default:
				return EMPTY_STRING;
		}
	};

	const installCertsInfo = (platform: string) => {
		switch (platform) {
			case PLATFORMS.RPM:
				return `sudo curl ${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertGlobalG2TLSRSASHA2562020CA1-1.crt -o /etc/pki/ca-trust/source/anchors/DigiCertGlobalG2TLSRSASHA2562020CA1-1.crt --insecure;;\
        sudo curl ${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertTLSRSASHA2562020CA1-1.crt -o /etc/pki/ca-trust/source/anchors/DigiCertTLSRSASHA2562020CA1-1.crt --insecure;;\
        sudo curl ${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertGlobalRootG2.crt -o /etc/pki/ca-trust/source/anchors/DigiCertGlobalRootG2.crt --insecure;;\
        sudo curl ${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertGlobalRootCA.crt -o /etc/pki/ca-trust/source/anchors/DigiCertGlobalRootCA.crt --insecure;;\
        sudo update-ca-trust`;
			case PLATFORMS.SUSE:
				return `sudo curl ${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertGlobalG2TLSRSASHA2562020CA1-1.crt -o /etc/pki/trust/anchors/DigiCertGlobalG2TLSRSASHA2562020CA1-1.crt --insecure;;\
          sudo curl ${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertTLSRSASHA2562020CA1-1.crt -o /etc/pki/trust/anchors/DigiCertTLSRSASHA2562020CA1-1.crt --insecure;;\
          sudo curl ${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertGlobalRootG2.crt -o /etc/pki/trust/anchors/DigiCertGlobalRootG2.crt --insecure;;\
          sudo curl ${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertGlobalRootCA.crt -o /etc/pki/trust/anchors/DigiCertGlobalRootCA.crt --insecure;;\
          sudo update-ca-certificates`;
			case PLATFORMS.DEBIAN:
				return `sudo curl ${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertGlobalG2TLSRSASHA2562020CA1-1.crt.pem -o /usr/local/share/ca-certificates/DigiCertGlobalG2TLSRSASHA2562020CA1-1.crt --insecure;;\
        sudo curl ${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertTLSRSASHA2562020CA1-1.crt.pem -o /usr/local/share/ca-certificates/DigiCertTLSRSASHA2562020CA1-1.crt --insecure;;\
        sudo curl ${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertGlobalRootG2.crt.pem -o /usr/local/share/ca-certificates/DigiCertGlobalRootG2.crt --insecure;;\
        sudo curl ${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertGlobalRootCA.crt.pem -o /usr/local/share/ca-certificates/DigiCertGlobalRootCA.crt --insecure;;\
        sudo update-ca-certificates`;
			case PLATFORMS.WINDOWS:
				return `powershell -Command {[Net.ServicePointManager]::ServerCertificateValidationCallback = { $true }; $client = New-Object System.Net.WebClient; $client.DownloadFile('${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertGlobalG2TLSRSASHA2562020CA1-1.crt', 'DigiCertGlobalG2TLSRSASHA2562020CA1-1.crt')};;\
        powershell -Command {[Net.ServicePointManager]::ServerCertificateValidationCallback = { $true }; $client = New-Object System.Net.WebClient; $client.DownloadFile('${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertTLSRSASHA2562020CA1-1.crt', 'DigiCertTLSRSASHA2562020CA1-1.crt')};;\
        powershell -Command {[Net.ServicePointManager]::ServerCertificateValidationCallback = { $true }; $client = New-Object System.Net.WebClient; $client.DownloadFile('${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertGlobalRootG2.crt', 'DigiCertGlobalRootG2.crt')};;\
        powershell -Command {[Net.ServicePointManager]::ServerCertificateValidationCallback = { $true }; $client = New-Object System.Net.WebClient; $client.DownloadFile('${artifactURL}/agent-dependencies/digicert-root-ca/DigiCertGlobalRootCA.crt', 'DigiCertGlobalRootCA.crt')};;\
        certutil -addstore -f "ROOT" DigiCertGlobalG2TLSRSASHA2562020CA1-1.crt;;\
        certutil -addstore -f "ROOT" DigiCertTLSRSASHA2562020CA1-1.crt;;\
        certutil -addstore -f "ROOT" DigiCertGlobalRootG2.crt;;\
        certutil -addstore -f "ROOT" DigiCertGlobalRootCA.crt`;
			case PLATFORMS.DARWIN:
			case PLATFORMS.DOCKER:
				return EMPTY_STRING;
			default:
				return EMPTY_STRING;
		}
	};

	const installCertsInstructions: string = installCertsInfo(platform);

	return (
		<Drawer
			anchor={"right"}
			open={drawerOpen}
			onClose={onClose}
			PaperProps={{
				elevation: 1,
				sx: {
					width: "85%",
					minWidth: "1000px",
				},
			}}
		>
			<Toolbar />

			<DialogTitle textAlign={"left"}>
				{basePlatform(platform)}: {window.getCTTranslatedText(installationType)}
			</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Alert severity="info">
						{window.getCTTranslatedText(
							"Copy the instructions using the button below and paste the copied instructions into a Terminal window"
						)}
					</Alert>

					{installationType === installScript && (
						<Alert severity="info">
							{window.getCTTranslatedText(
								"For more controlled installation, please use the manual installation steps"
							)}
						</Alert>
					)}
					<Alert severity="warning">
						{window.getCTTranslatedText("You need to have")}{" "}
						{window.getCTTranslatedText(
							platform === PLATFORMS.WINDOWS ? "Administrator" : "root"
						)}{" "}
						{window.getCTTranslatedText("privileges")}
						{platform !== PLATFORMS.WINDOWS && (
							<>
								{platform !== PLATFORMS.DOCKER &&
								platform !== PLATFORMS.OPENSHIFT ? (
									<>
										{" "}
										{window.getCTTranslatedText("and")}{" "}
										<Link href={curlInstallLink} target="_blank">
											curl
										</Link>
									</>
								) : (
									<>
										{", "}
										<Link href={helmInstallLink} target="_blank">
											helm
										</Link>{" "}
										and{" "}
										<Link href={kubectlInstallLink} target="_blank">
											kubectl
										</Link>
									</>
								)}
							</>
						)}
						{platform === PLATFORMS.DARWIN && (
							<>
								,{" "}
								<Link href={brewInstallLink} target="_blank">
									{window.getCTTranslatedText("brew")}
								</Link>
							</>
						)}
						{platform !== PLATFORMS.WINDOWS && " installed"}{" "}
						{window.getCTTranslatedText("to run the below commands in the")}{" "}
						{window.getCTTranslatedText(
							platform === PLATFORMS.WINDOWS
								? "Command Prompt or in PowerShell"
								: "Terminal"
						)}
					</Alert>
					{platform === PLATFORMS.WINDOWS &&
						installationType === installScript && (
							<Alert severity="warning">
								{window.getCTTranslatedText(
									"Please proceed to Manual Installation section if the PowerShell version on your machine is less than 3"
								)}
							</Alert>
						)}
					{(platform === PLATFORMS.RPM ||
						platform === PLATFORMS.RPM_LEGACY) && (
						<>
							<Alert severity="warning">
								{window.getCTTranslatedText(
									"For RHEL servers, please ensure your server has a valid developer subscription and SELinux policy set to permissive."
								)}
							</Alert>
							<Alert severity="warning">
								{" "}
								{window.getCTTranslatedText(
									"If installing the prerequisites fails, please update your packages using"
								)}{" "}
								<StyledChip label="sudo yum update" variant="filled" />{" "}
								{window.getCTTranslatedText("command")}
							</Alert>
						</>
					)}
					{platform === PLATFORMS.DEBIAN && (
						<>
							<Alert severity="warning">
								{window.getCTTranslatedText(
									"If installing the prerequisites fails, please update your packages using"
								)}{" "}
								<StyledChip label={"sudo apt-get update"} variant="filled" />{" "}
								{window.getCTTranslatedText("command")}
							</Alert>
						</>
					)}
					{(platform === PLATFORMS.DOCKER ||
						platform === PLATFORMS.OPENSHIFT) && (
						<Alert severity="warning">
							{" "}
							{window.getCTTranslatedText("useValidClusterId")}{" "}
							<StyledChip
								label="helm -n ct-system uninstall ct-contsec --no-hooks"
								variant="filled"
							/>{" "}
							{window.getCTTranslatedText("installAgain")}
						</Alert>
					)}
					{platform === PLATFORMS.DOCKER && (
						<Alert severity="warning">
							{window.getCTTranslatedText("kubernetsSupportedVersion")}
						</Alert>
					)}
					{platform !== PLATFORMS.DOCKER &&
						platform !== PLATFORMS.AIX &&
						platform !== PLATFORMS.SOLARIS &&
						platform !== PLATFORMS.SUSE &&
						platform !== PLATFORMS.DEBIAN &&
						platform !== PLATFORMS.DARWIN &&
						platform !== PLATFORMS.RPM &&
						platform !== PLATFORMS.OPENSHIFT && (
							<>
								<Alert severity="warning">
									{window.getCTTranslatedText(
										"Currently, the ColorTokens Xshield Agent for"
									)}{" "}
									{window.getCTTranslatedText(
										capitalizeFirstLetter(
											platform === PLATFORMS.RPM_LEGACY ? "RHEL 6" : platform
										)
									)}{" "}
									{window.getCTTranslatedText("is available for")}{" "}
									<StyledChip label="x86_64" variant="filled" />{" "}
									{window.getCTTranslatedText(
										"platform architecture only. Support for"
									)}{" "}
									<StyledChip label="arm64" variant="filled" />{" "}
									{window.getCTTranslatedText("will be released soon")}
								</Alert>
							</>
						)}
					{platform === PLATFORMS.WINDOWS && (
						<Alert severity="warning">
							{window.getCTTranslatedText(
								"For Windows Server 2008 R2 Standard, please ensure that KB4474419 and KB4490628 articles are present on the system"
							)}
						</Alert>
					)}
					{installCertsInstructions !== EMPTY_STRING ? (
						<Alert severity="warning">
							{window.getCTTranslatedText(
								"If the peer certificate issuer has been marked as not trusted, it is because the root certificate of the trusted Certificate Authority used by ColorTokens is not present on the target asset. You can install the root certificate by running the below command"
							)}
							:
							{platform === PLATFORMS.WINDOWS && (
								<pre>
									{window.getCTTranslatedText(
										"installCertsInstructionsPowershell"
									)}{" "}
									<pre>{"C:/>"} powershell</pre>
								</pre>
							)}
							<pre>
								{window.getCTTranslatedText("installCertsInstructionsCode", {
									command:
										platform !== PLATFORMS.WINDOWS ? "curl" : "powershell",
								})}{" "}
								-
							</pre>
							{platform !== PLATFORMS.WINDOWS ? (
								<>
									<pre>
										{"      "}
										{window.getCTTranslatedText(
											"For proxy without authentication"
										)}{" "}
										: curl --proxy http://PROXY_IP:PROXY_PORT ARTIFACTS-URL -o{" "}
										{linuxCertLocation(platform)}/OUTFILE_NAME.crt --insecure
									</pre>
									<pre>
										{"      "}
										{window.getCTTranslatedText(
											"For proxy with authentication"
										)}{" "}
										: curl --proxy
										http://USERNAME:URL-ENCODED-PASSWORD@PROXY_IP:PROXY_PORT
										ARTIFACTS-URL -o {linuxCertLocation(platform)}
										/OUTFILE_NAME.crt --insecure
									</pre>
								</>
							) : (
								<>
									<pre>
										{"      "}
										{window.getCTTranslatedText(
											"For proxy without authentication"
										)}{" "}
										:{" "}
										{
											"powershell -Command {[Net.ServicePointManager]::ServerCertificateValidationCallback = { $true }; $client = New-Object System.Net.WebClient; $client.Proxy = (New-Object System.Net.WebProxy('http://PROXY_IP:PROXY_PORT')); $client.DownloadFile('ARTIFACTS-URL', 'OUTFILE_NAME.crt')}"
										}
									</pre>
									<pre>
										{"      "}
										{window.getCTTranslatedText(
											"For proxy with authentication"
										)}{" "}
										:{" "}
										{
											"powershell -Command {[Net.ServicePointManager]::ServerCertificateValidationCallback = { $true }; $client = New-Object System.Net.WebClient; $client.Proxy = (New-Object System.Net.WebProxy('http://PROXY_IP:PROXY_PORT')); $client.Proxy.Credentials = (New-Object System.Net.NetworkCredential('USERNAME', 'PASSWORD')); $client.DownloadFile('ARTIFACTS-URL', 'OUTFILE_NAME.crt')}"
										}
									</pre>
								</>
							)}
							{installCertsInstructions
								?.split(";;")
								?.map((instruction: string, index: number) => (
									<Typography
										key={index}
										sx={{
											wordBreak: "break-all",
											maxWidth: "100%",
											fontSize: "inherit",
										}}
									>
										{platform === PLATFORMS.WINDOWS
											? `PS C:\\> ${instruction}`
											: `$${" "}${instruction}`}
									</Typography>
								))}
						</Alert>
					) : null}
				</Stack>
				{children}
			</DialogContent>
			<Box width={"100%"}>
				<DialogActions>
					<Button
						size="large"
						variant="contained"
						aria-label="close drawer"
						onClick={onClose}
					>
						{window.getCTTranslatedText("Close")}
					</Button>
				</DialogActions>
			</Box>
		</Drawer>
	);
};

import { GridRowId } from "@mui/x-data-grid";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useAlertConfigStatusAPI } from "../../hooks/useAlertConfig/useAlertConfigAPI";

interface AlertRuleNotificationActionBarProps {
	selection: GridRowId[];
	cancel: () => void;
	update: (selectedData: any) => void;
}

export const AlertRuleNotificationActionBar = ({
	selection,
	cancel,
	update,
}: AlertRuleNotificationActionBarProps) => {
	const alertRuleStatusAPI = useAlertConfigStatusAPI();

	const confirmAction = () => {
		if (selection?.length > 0) {
			update(selection);
		}
	};

	return (
		<ToolbarAction
			loading={alertRuleStatusAPI?.isLoading}
			isValid={selection?.length > 0}
			actionBtnText={"Confirm"}
			save={confirmAction}
			cancel={cancel}
		></ToolbarAction>
	);
};

import { Box, Stack, Typography } from "@mui/material";
import { useCommonStore } from "common/store";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { PortColumnRenderProps } from "pages/ports/types";

interface PortDetailsProps extends PortColumnRenderProps {
	showInternetFacingComp?: boolean;
}

export function PortService({
	port,
	viewOnly,
	useFacetStore = useCommonStore,
}: PortDetailsProps) {
	if (!port.lpId) {
		return <Box />;
	}

	return (
		<Box sx={{ maxWidth: "100%" }}>
			<Stack
				direction={"row"}
				alignItems="center"
				justifyContent="flex-start"
				sx={{ maxWidth: "100%" }}
			>
				<Stack direction={"column"}>
					{Boolean(port?.listenPortName) && (
						<CTDatagridButton
							isDisabled={!port.listenPortName || viewOnly === true}
							asText={viewOnly === true}
							useFacetStore={useFacetStore}
							facetName={"listenportname"}
							facetOption={port.listenPortName}
						>
							<Typography
								sx={{
									maxWidth: "100%",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
								variant="body2"
							>
								{port?.listenPortName ?? ""}
							</Typography>
						</CTDatagridButton>
					)}
				</Stack>
			</Stack>
		</Box>
	);
}

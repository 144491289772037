import { Button, Stack, Tooltip } from "@mui/material";

import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { ManageAssetNetworks } from "pages/assets/components/manage-asset-networks";
import { ManageAssetTemplates } from "pages/assets/components/manage-asset-templates";
import { HostAndUserGroupChangeStatusDrawer } from "modules/host-and-user-group-change-status-drawer";
import { useState } from "react";
import { useUserGroupStore } from "../../store";
import { UserGroup, HostAndUserGroupStatusState } from "../../types";
import { UserGroupUpdateAction } from "./UserGroupToolbarAction";
import {
	DrawerType,
	HostAndUserGroupStatusEnum,
} from "modules/host-and-user-group-change-status-drawer/HostAndUserGroupChangeStatusDrawer";

export interface UserGroupToolbarProps extends Omit<ToolbarProps, "children"> {
	selectedData: Array<UserGroup> | undefined;
	hideToolbar: () => void;
	onSelectCallback?: Function;
	multiselect?: boolean;
}

export enum UserGroupStatus {
	Enable = "Active",
	Disable = "Inactive",
}

export function UserGroupToolbar(props: Readonly<UserGroupToolbarProps>) {
	const requestAPIRefresh = useUserGroupStore(store => store.requestAPIRefresh);
	const [showAddToTemplateDialog, setShowAddToTemplateDialog] = useState(false);
	const [showChangeStatusDrawer, setShowChangeStatusDrawer] = useState(false);
	const [showAssignNetworksDrawer, setShowAssignNetworksDrawer] =
		useState(false);
	const [selectedOperation, setSelectedOperation] = useState<
		HostAndUserGroupStatusEnum | undefined
	>();

	const handleAssignTemplate = () => {
		setShowAddToTemplateDialog(true);
	};

	const handleButtonChange = (btnActionType: string) => {
		setSelectedOperation(btnActionType as HostAndUserGroupStatusEnum);
		setShowChangeStatusDrawer(true);
	};

	const handleClose = () => {
		setShowAddToTemplateDialog(false);
		requestAPIRefresh();
		props.hideToolbar();
	};

	const addToTemplate = () => {
		setShowAddToTemplateDialog(false);
		requestAPIRefresh();
		props.hideToolbar();
	};

	const handleConfirm = () => {
		setSelectedOperation(undefined);
		setShowChangeStatusDrawer(false);
		requestAPIRefresh();
		props.hideToolbar();
	};
	const handleAssignNamedNetwork = () => {
		setShowAssignNetworksDrawer(true);
	};
	const handleAssignNamedNetworkConfirm = () => {
		setShowAssignNetworksDrawer(false);
		requestAPIRefresh();
		props.hideToolbar();
	};
	const closeAssignNetworksDrawer = () => {
		setShowAssignNetworksDrawer(false);
		requestAPIRefresh();
		props.hideToolbar();
	};

	let tooltipText = "";
	let namedNetworkTooltipText = "";
	if (props.selectedData) {
		const disbaledData = props.selectedData.filter(
			item => item.userGroupStatus !== HostAndUserGroupStatusState.Active
		);
		if (disbaledData?.length) {
			tooltipText = "Templates won't be assigned to deactivated user groups.";
			namedNetworkTooltipText =
				"Named Networks won't be assigned to deactivated user groups.";
		}
	}
	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}>
			<Stack
				direction="row"
				spacing={2}
				sx={{ width: "100%", p: 1 }}
				justifyItems="flex-end"
				alignItems={"flex-end"}
				justifyContent="flex-end"
			>
				<Stack direction="row" alignItems={"center"}>
					<Stack direction="row">
						{(props.selectedData?.length ?? 0) > 0 && (
							<>
								<UserGroupUpdateAction {...{ handleButtonChange }} />
								<Tooltip
									title={`${window.getCTTranslatedText(
										namedNetworkTooltipText
									)}`}
								>
									<Button
										variant="outlined"
										aria-label="dismiss"
										onClick={handleAssignNamedNetwork}
										sx={{ marginLeft: 2 }}
									>
										{window.getCTTranslatedText("Manage Named Network")}
									</Button>
								</Tooltip>
								<Tooltip title={`${window.getCTTranslatedText(tooltipText)}`}>
									<Button
										variant="outlined"
										aria-label="dismiss"
										onClick={handleAssignTemplate}
										sx={{ marginLeft: 2 }}
									>
										{window.getCTTranslatedText("Manage Template")}
									</Button>
								</Tooltip>
							</>
						)}
						{showAddToTemplateDialog && (
							<ManageAssetTemplates
								open={showAddToTemplateDialog}
								criteria={`groupID in (${(props.selectedData ?? [])
									.map(obj => `'${obj.groupID}'`)
									.join(",")})`}
								onClose={handleClose}
								onConfirm={addToTemplate}
								multiselect={true}
							/>
						)}

						{showChangeStatusDrawer && (
							<HostAndUserGroupChangeStatusDrawer
								isOpen={showChangeStatusDrawer}
								selectedStatus={selectedOperation}
								selectedData={props?.selectedData}
								confirm={handleConfirm}
								onClose={() => {
									setShowChangeStatusDrawer(false);
									setSelectedOperation(undefined);
									props.hideToolbar();
								}}
								drawerType={DrawerType.UserGroup}
							/>
						)}
						{showAssignNetworksDrawer && (
							<ManageAssetNetworks
								open={showAssignNetworksDrawer}
								criteria={`groupID in (${(props.selectedData ?? [])
									.map(obj => `'${obj.groupID}'`)
									.join(",")})`}
								onClose={closeAssignNetworksDrawer}
								onConfirm={handleAssignNamedNetworkConfirm}
								multiselect={true}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>
		</GridToolbar>
	);
}

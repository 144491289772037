import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

export const ReactFlowContainer = styled("div")(({ theme }) => ({
	flex: 1,
	position: "relative",

	".react-flow": {
		"--node-box-shadow":
			"5px 0 7px rgba(42, 138, 246, 0.3), -5px 0 7px rgba(233, 42, 103, 0.3)",
	},

	"& .react-flow__node-ctNode": {
		borderRadius: "0 !important",
		background: "transparent !important",
		border: "none !important",
		boxShadow: "initial !important",
		pointerEvents: "none !important",
		cursor: "pointer !important",
	},

	"& .react-flow__edge-path-selector": {
		fill: "none",
		stroke: "transparent",
		strokeWidth: 18,
	},

	"& .react-flow__node": {
		borderWidth: "0 !important",
	},

	"& .react-flow__node.react-flow__node-default": {
		textOverflow: "ellipsis",
		wordBreak: "break-word",
		whiteSpace: "nowrap",
		padding: "0px !important",

		display: "flex",
		alignItems: "center",
		flexDirection: "column",

		background: "transparent",
		border: `1px dashed ${theme.palette.text.secondary} !important`,
		borderWidth: `1px !important`,
		justifyContent: `flex-start`,
	},

	"& .react-flow__handle": {
		left: "50%",
		top: "50%",
		transform: "translate(-50%,-50%)",
		opacity: 0,
		pointerEvents: "none",
	},

	"& .react-flow__node-ctNode .ctNode-internal": {
		textAlign: "center",
		fontSize: "18px",
		width: "100%",
		height: "100%",
		padding: "2px !important",
		background:
			theme.palette.mode === "dark"
				? theme.palette.custom.lightGrey
				: grey[100],
		borderRadius: "8px",
		border: `1px solid ${theme.palette.grey[500]}`,
		boxShadow: `${
			theme.palette.mode === "dark"
				? theme.palette.grey[900]
				: theme.palette.grey[500]
		} 0px 4px 6px -1px, ${
			theme.palette.mode === "dark"
				? theme.palette.grey[900]
				: theme.palette.grey[500]
		} 0px 2px 4px -1px`,
	},

	"& .react-flow__node-ctNode .ctNode-internal.others": {
		borderStyle: "double",
		borderWidth: "4px",
	},

	"& .react-flow__node-ctNode .ct-drag-handle": {
		pointerEvents: "all",
	},

	"& .react-flow__node-ctNode.ctSelected .ctNode-internal, & .react-flow__node-ctNode.ctSelected .ctNode-container":
		{
			border: `1px solid ${theme.palette.primary.main}`,
		},

	"& .react-flow__node-ctNode.ctSelected .ctNode-internal.others": {
		border: `1px solid ${theme.palette.primary.main}`,
		borderStyle: "double",
		borderWidth: "4px",
	},

	"@keyframes spinner": {
		"100%": {
			transform: "translate(-50%, -50%) rotate(-360deg)",
		},
	},
}));

import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { setComplianceReqCheck, setCurrentTenantID } from "./helper";
import { useNavigate } from "react-router-dom";
import { PRODUCT_NAME, EXT_ACCESS_PATHS } from "common/constants/product";
import { AuthState } from "hooks/useAuthState";

export interface Product {
	productName: string;
	homePath: string;
}
export interface TenantInt {
	tenantID: string;
	products: Array<Product> | undefined;
}
interface TenantListInt {
	tenants: Array<TenantInt>;
	current: TenantInt;
}

function useTenantListAPI({ authState }: { authState: AuthState }) {
	return useQuery<TenantListInt, Error>(["tenants", `auth/tenants`], {
		enabled: authState === AuthState.LoggedIn,
		refetchOnWindowFocus: false,
	});
}

export function useComplianceController({
	authState,
}: {
	authState: AuthState;
}) {
	const tenantListAPI = useTenantListAPI({ authState });
	const navigate = useNavigate();

	let tenantList = tenantListAPI?.data?.tenants;
	let currentTenant = tenantListAPI?.data?.current;

	const userTenantList: any = useMemo(() => {
		if (!tenantList) {
			return;
		}
		return tenantList;
	}, [tenantList]);

	useEffect(() => {
		if (currentTenant && setCurrentTenantID) {
			if (!EXT_ACCESS_PATHS.includes(window.location.pathname)) {
				redirectCorrectProduct(currentTenant);
			}
			setCurrentTenantID(currentTenant?.tenantID);
		}

		if (
			tenantList &&
			Array.isArray(tenantList) &&
			tenantList.length > 0 &&
			setComplianceReqCheck
		) {
			// TODO use list from BE
			const reqComplianceCheckList = [
				{
					tenantID: "ae967b9b-d01f-4b23-bcb9-c60ed9898945",
					name: "MD Anderson",
					email: "",
					website: null,
					status: "active",
					products: [],
				},
				{
					tenantID: "ae967b9b-d01f-4b23-bcb9-c60ed9898945",
					name: "MDA - Clone",
					email: "",
					website: null,
					status: "active",
					products: [],
				},
				// {
				//   tenantID: "a5cbb761-2b66-4c0d-97e0-9dc67a4070d4",
				//   name: "CT Demo Dev",
				//   email: "",
				//   website: null,
				//   status: "active",
				// },
			];
			setComplianceReqCheck(reqComplianceCheckList);
		}
	}, [tenantList, currentTenant, userTenantList, navigate]);

	return {
		tenantList,
		currentTenant,
	};
}

function redirectCorrectProduct(tenant: TenantInt) {
	let products = tenant?.products?.filter(product => {
		return product.productName === PRODUCT_NAME;
	});
	if (!products?.length && tenant?.products?.length) {
		window.location.href = `${tenant?.products[0]?.homePath}`;
	}
	return true;
}

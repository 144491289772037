import CloseIcon from "@mui/icons-material/Close";
import {
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Toolbar,
	Tooltip,
	Typography,
} from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { TimeFilter } from "common/molecules/TimeFilter";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { PathsReview } from "modules/ports-paths-review/components/paths-review";
import { PathStatusActionBar } from "modules/ports-paths-review/components/paths-review/components/path-status-actionbar";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";
import { useAssetDetailCommonStore as useFacetStore } from "pages/asset/components/asset-detail/components/asset-review-drawer/store/common";
import { usePathAssetStore } from "pages/asset/components/asset-detail/components/asset-review-drawer/store/path-store";
import { PATH_TIME_FILTER_OPTIONS } from "pages/paths/constants";
import { PathFilters, PathStatus } from "pages/paths/types";
import { useState } from "react";
import { UserGroup } from "../../types";
interface GroupPathReviewDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	pathReviewState?: boolean;
	userGroup: UserGroup;
}
export function UserGroupPathsDrawer({
	isOpen,
	onClose,
	userGroup,
	pathReviewState = false,
}: Readonly<GroupPathReviewDrawerProps>) {
	const pathFilter: PathFilters = {
		criteria: `assetid in ('${
			userGroup.groupID
		}') AND 'direction' in ('outbound') ${
			pathReviewState ? `AND 'reviewed' in ('unreviewed')` : ""
		}`,
	};
	const [selectedPathStatus, setSelectedPathStatus] = useState<
		PathStatus | undefined
	>(undefined);
	const onSelectPathStatus = (status: PathStatus | undefined) => {
		setSelectedPathStatus(status);
	};
	const [pathsSelection, setPathsSelection] = useState<Array<GridRowId>>([]);
	const selectedTime = useUserPreferencesStore(state => state.timeFilter);
	const setSelectedTime = useUserPreferencesStore(state => state.setTimeFilter);

	const confirmPathAction = () => {
		setPathsSelection([]);
		setSelectedPathStatus(undefined);
		onClose();
	};

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					p: 0,
					width: "70%",
					minWidth: "600px",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<Stack direction="row" spacing={2} sx={{ mt: 5, mx: 4 }}>
				<Typography variant="h5">
					<b>{window.getCTTranslatedText("Review User Group Paths")}</b>
				</Typography>
			</Stack>
			<DialogContent
				sx={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
					overflow: "hidden",
				}}
			>
				<Stack direction="row" justifyContent={"flex-end"} pb={3}>
					<TimeFilter
						selectedTime={selectedTime}
						setSelectedTime={setSelectedTime}
						options={PATH_TIME_FILTER_OPTIONS}
					/>
				</Stack>
				<PathsReview
					includeCommonFacet={true}
					setPathSelection={setPathsSelection}
					pathFilter={pathFilter}
					updatePathstatus={onSelectPathStatus}
					useFacetStore={useFacetStore}
					mode={AssetReviewModeType.Review}
					readOnlyDimensions={["pathlastobserved", "listenportlastobserved"]}
				/>
			</DialogContent>
			<DialogActions>
				<PathStatusActionBar
					selectedStatus={selectedPathStatus}
					selection={pathsSelection}
					update={confirmPathAction}
					cancel={onClose}
					useStore={usePathAssetStore}
				/>
			</DialogActions>
		</Drawer>
	);
}

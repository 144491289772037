import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useNetworkStore } from "pages/networks/store";
import { Network } from "pages/networks/types";

export interface NetworkToolbarProps extends Omit<ToolbarProps, "children"> {
	selection: Array<Network> | undefined;
}

export function NetworkToolBar(props: NetworkToolbarProps) {
	const requestAPIRefresh = useNetworkStore(store => store.requestAPIRefresh);

	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}></GridToolbar>
	);
}

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IconButton, Tooltip } from "@mui/material";
import { useFirewallHostGroupStore } from "../store";
import { FirewallHostGroupListItem } from "../types";

interface Props
	extends Pick<FirewallHostGroupListItem, "crowdstrikeFirewallHostgroupId"> {}

function DeleteFirewallHostGroupButton({
	crowdstrikeFirewallHostgroupId,
}: Props) {
	const tooltipTitle = window.getCTTranslatedText("Delete");
	const ariaLabel = tooltipTitle;
	const setIdForDeletion = useFirewallHostGroupStore(
		state => state.setIdForDeletion
	);

	const onDeleteClick = () => {
		setIdForDeletion(crowdstrikeFirewallHostgroupId);
	};

	return (
		<Tooltip title={tooltipTitle}>
			<IconButton aria-label={ariaLabel} onClick={() => onDeleteClick()}>
				<DeleteOutlineIcon />
			</IconButton>
		</Tooltip>
	);
}

export default DeleteFirewallHostGroupButton;

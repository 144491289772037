export const getDateLabelFromMinuteUnit = (minute: string) => {
	if (!minute) {
		return "";
	}
	const minutes = Number(minute);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 1440);
	const weeks = Math.floor(hours / 10080);
	const months = Math.floor(hours / 43200);
	const years = Math.floor(hours / 525600);

	if (minutes === 1) {
		return `${minutes} minute`;
	}
	if (minutes < 60) {
		return `${minutes} minutes`;
	}

	if (minutes < 61) {
		return `${hours} hour`;
	}
	if (minutes < 1440) {
		return `${hours} hours`;
	}

	if (minutes < 1441) {
		return `${days} day`;
	}
	if (minutes < 10080) {
		return `${days} days`;
	}

	if (minutes < 10081) {
		return `${weeks} week`;
	}
	if (minutes < 43200) {
		return `${weeks} weeks`;
	}

	if (minutes < 43201) {
		return `${months} month`;
	}
	if (minutes < 525600) {
		return `${months} months`;
	}

	if (minutes < 525601) {
		return `${years} year`;
	}
	return `${years} years`;
};

import { Stack, Typography } from "@mui/material";
import { DebianFileIcon } from "assets/svgs/DebianFileIcon";
import { ISOFileIcon } from "assets/svgs/ISOFileIcon";
import { OVAFileIcon } from "assets/svgs/OVAFileIcon";
import { Loader } from "common/atoms/loader";
import {
	UserInfoType,
	useKnowledgeBaseAPI,
} from "modules/analytics/KnowledgeBase";
import {
	AgentVersion,
	AgentVersions,
	useVersionAPI,
} from "pages/agents/components/agent-downlaod-version/useVersionAPI";
import {
	AGENT_TYPES,
	ARCHITECTURE_TYPE,
} from "pages/agents/components/agent-download-page/constants";
import { PlatformData } from "pages/agents/components/agent-download-page/type";
import { useEffect, useState } from "react";
import { ProductFruits } from "react-product-fruits";
import { PLATFORMS } from "../../constants";
import { DownloadItem } from "./components/DownloadItem";

export interface AgentVersionDetails {
	debian: PlatformData;
	iso: PlatformData;
	ova: PlatformData;
}

export function renderIcons(platform: string, color: string) {
	switch (platform) {
		case PLATFORMS.ISO:
			return <ISOFileIcon width="35" height="35" color={color} />;
		case PLATFORMS.OVA:
			return <OVAFileIcon width="35" height="35" color={color} />;
		case PLATFORMS.DEBIAN:
			return <DebianFileIcon width="35" height="35" color={color} />;

		default:
			return <OVAFileIcon width="35" height="35" color={color} />;
	}
}

export function getAgentVersions(data: AgentVersions | undefined) {
	let agentVersionMap: AgentVersionDetails = {
		iso: { versionsInfo: [] },
		ova: { versionsInfo: [] },
		debian: { versionsInfo: [] },
	};
	let filteredAgentData = data?.versions?.filter(
		(ele: AgentVersion) => ele?.agentType === AGENT_TYPES.GATEWAY
	);
	filteredAgentData?.forEach((agentVersion: AgentVersion) => {
		agentVersionMap[
			agentVersion?.targetPlatform as keyof AgentVersionDetails
		]?.versionsInfo.push({
			version: agentVersion?.version,
			downloadLink: agentVersion?.downloadLink,
			fileName: agentVersion?.fileName,
			agentType: agentVersion?.agentType,
			architecture: agentVersion?.architecture,
			targetPlatform: agentVersion?.targetPlatform,
			recommended: agentVersion?.recommended,
		});
	});

	return agentVersionMap;
}

function getLatestVersion(
	agentVersionDetails: AgentVersionDetails,
	platform: string,
	agentType: string
) {
	let agentVdetails = agentVersionDetails[
		platform as keyof AgentVersionDetails
	]?.versionsInfo.filter(d => d.agentType === agentType);

	let x8664LatestVersion = agentVdetails?.filter(
		arch => arch.architecture === ARCHITECTURE_TYPE.X86_64
	);

	if (
		x8664LatestVersion[0]?.version &&
		!x8664LatestVersion[0]?.version.includes(
			`${window.getCTTranslatedText("Latest Version")} (${
				x8664LatestVersion[0]?.version
			})`
		)
	) {
		x8664LatestVersion[0].version = `${window.getCTTranslatedText(
			"Latest Version"
		)} (${x8664LatestVersion[0]?.version})`;
	}

	let agentDetails = [...x8664LatestVersion];
	return agentDetails;
}

export function ApplianceInstall() {
	const { data: AgentPlatformData } = useVersionAPI();
	const { data } = useKnowledgeBaseAPI(true);
	const [userInfo, setUserInfo] = useState<UserInfoType | null>(null);

	const renderVirtualAppliances = () => {
		return (
			<Stack spacing={3} pt={2}>
				{Object.keys(platformAgentData)?.map((platform: string) => {
					return (
						<DownloadItem
							key={platform}
							platform={platform}
							platformData={
								platformAgentData[platform as keyof AgentVersionDetails]
							}
							agentGatwayVersion={getLatestVersion(
								platformAgentData,
								platform,
								AGENT_TYPES.GATEWAY
							)}
							knowledgeBaseHmac={userInfo}
						/>
					);
				})}
			</Stack>
		);
	};

	const installOptions = [
		{
			name: `a) ${window.getCTTranslatedText("hardwareGatekeeper")}`,
			description: window.getCTTranslatedText("hardwareGatekeeperDescription"),
		},
		{
			name: `b) ${window.getCTTranslatedText("virtualGatekeeper")}`,
			description: window.getCTTranslatedText("virtualGatekeeperDescription"),
			content: renderVirtualAppliances,
		},
	];

	useEffect(() => {
		if (data?.username && data?.hmacHash) {
			setUserInfo({
				username: data.username,
				hmac: {
					hash: data.hmacHash,
					expiration: data.expirationFinalDate,
				},
			});
		}
	}, [data]);
	let platformAgentData = getAgentVersions(AgentPlatformData);

	const renderKnowledgbaseDocs = () => {
		return (
			<>
				{process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE &&
					userInfo &&
					userInfo?.username &&
					userInfo?.hmac.hash && (
						<ProductFruits
							workspaceCode={
								process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE
							}
							language="en"
							user={userInfo}
						/>
					)}
			</>
		);
	};

	return AgentPlatformData === undefined ? (
		<Loader />
	) : (
		<Stack sx={{ alignItems: "center", pb: 6, mt: 2 }}>
			<Stack spacing={2} width="95%">
				<Typography variant="h6" sx={{ mb: 2 }}>
					{window.getCTTranslatedText("step")} 1:{" "}
					{window.getCTTranslatedText("installGatekeeper")}
				</Typography>
				<Stack spacing={4}>
					{installOptions.map((option, index) => {
						return (
							<Stack key={index} spacing={1}>
								<Typography variant="subtitle1">{option.name}</Typography>
								<Typography variant="body2">{option.description}</Typography>
								{option?.content?.()}
							</Stack>
						);
					})}
				</Stack>
			</Stack>
			{renderKnowledgbaseDocs()}
		</Stack>
	);
}

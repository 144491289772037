import { SelectChangeEvent } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AgentActionType } from "../agent-data-grid-toolbar/Toolbar";
import { isHigherOrEqualVersions } from "../agent-data-grid/helpers/columnHelpers";
import { AgentGroupByObj } from "../agent-drawers/hooks/useGroupByAgentData";
import { AgentDownloadItemContent } from "./AgentDownloadItemContent";
import { AgentUpgradeContent } from "./AgentUpgradeContent";
import { AGENT_TYPES, PLATFORMS } from "./constants";
import { supportedPlatformArchitecturesUpgradePage } from "./helpers";
import {
	defaultAgentType,
	supportedPlatformArchitecturesDownloadPage,
} from "./helpers/agentTypeHelpers";
import { AgentVersion, VersionInfo } from "./type";

export function DownloadItem(props: AgentVersion) {
	const [architecture, setArchitecture] = useState<string>("");
	const {
		page,
		platform,
		agentServerVersions,
		agentType,
		agentsGroupByPlatforms,
		setAgentsGroupByPlatforms,
	} = props;

	const [selectedOption, setSelectedOption] = useState<AGENT_TYPES>(() => {
		switch (agentType) {
			case AGENT_TYPES.CT_REMOTE_CONNECTOR:
			case AGENT_TYPES.CT_CONTAINER_AGENT:
				return agentType;
			default:
				return agentType ?? defaultAgentType({ platform });
		}
	});

	const [agentServerVersion, setAgentServerVersion] = useState([
		agentServerVersions.find(
			arch => architecture === arch.architecture && arch.recommended
		)?.version ?? "",
	]);

	const [agentUserVersion, setAgentUserVersion] = useState([
		props?.agentUserVersions.find(
			arch => architecture === arch.architecture && arch.recommended
		)?.version ?? "",
	]);

	const [agentConnectorVersion, setAgentConnectorVersion] = useState([
		props?.agentConnectorVersions.find(
			arch => architecture === arch.architecture && arch.recommended
		)?.version ?? "",
	]);

	const [agentContainerVersion, setAgentContainerVersion] = useState([
		props?.agentContainerVersions.find(arch => arch.recommended)?.version ?? "",
	]);

	const [agentLegacyVersion, setAgentLegacyVersion] = useState([
		props?.agentLegacyVersions.find(arch => arch.recommended)?.version ?? "",
	]);

	function getAgentVersionInfo(architecture: string) {
		if (
			selectedOption === AGENT_TYPES.CT_AGENT ||
			platform === PLATFORMS.WINDOWS ||
			platform === PLATFORMS.DARWIN
		) {
			return agentServerVersions.filter(
				arch => architecture === arch.architecture
			);
		} else if (selectedOption === AGENT_TYPES.CT_USER_AGENT) {
			return props?.agentUserVersions.filter(
				arch => architecture === arch.architecture
			);
		} else if (selectedOption === AGENT_TYPES.CT_CONTAINER_AGENT) {
			return props?.agentContainerVersions.filter(
				arch => architecture === arch.architecture
			);
		} else if (selectedOption === AGENT_TYPES.CT_LEGACY_AGENT) {
			return props?.agentLegacyVersions.filter(
				arch => architecture === arch.architecture
			);
		} else {
			return props?.agentConnectorVersions.filter(
				arch => architecture === arch.architecture
			);
		}
	}

	let agentVersionTypeInfo: VersionInfo[] = getAgentVersionInfo(architecture);

	const handleChange = (event: SelectChangeEvent, index: number) => {
		if (
			!isHigherOrEqualVersions(highestCurrentAgentVersion, event?.target?.value)
		) {
			switch (selectedOption) {
				case AGENT_TYPES.CT_AGENT:
					setAgentServerVersion(prev => {
						const newVersion = [...prev];
						newVersion[index] = event?.target?.value;
						return newVersion;
					});
					break;
				case AGENT_TYPES.CT_USER_AGENT:
					setAgentUserVersion(prev => {
						const newVersion = [...prev];
						newVersion[index] = event?.target?.value;
						return newVersion;
					});
					break;
				case AGENT_TYPES.CT_CONTAINER_AGENT:
					setAgentContainerVersion(prev => {
						const newVersion = [...prev];
						newVersion[index] = event?.target?.value;
						return newVersion;
					});
					break;
				case AGENT_TYPES.CT_LEGACY_AGENT:
					setAgentLegacyVersion(prev => {
						const newVersion = [...prev];
						newVersion[index] = event?.target?.value;
						return newVersion;
					});
					break;
				default:
					setAgentConnectorVersion(prev => {
						const newVersion = [...prev];
						newVersion[index] = event?.target?.value;
						return newVersion;
					});
			}
			updateGroupByPlatformsData(event, index);
		}
	};

	let agentGrpByObj: AgentGroupByObj = { ...agentsGroupByPlatforms };

	const updateGroupByPlatformsData = (
		event: SelectChangeEvent,
		index: number
	) => {
		if (
			page === AgentActionType.UPGRADE &&
			agentsGroupByPlatforms &&
			platform &&
			agentsGroupByPlatforms[platform] &&
			event?.target?.value
		) {
			if (agentGrpByObj[platform][index].version !== event?.target?.value) {
				agentGrpByObj[platform][index].version = event?.target?.value;
				if (setAgentsGroupByPlatforms) {
					setAgentsGroupByPlatforms(agentGrpByObj);
				}
			}
		}
	};

	const selectedAgentType = useCallback(
		(
			agentVersionTypeInfo: VersionInfo[],
			architecture: string,
			index: number
		) => {
			let filteredVersions = agentVersionTypeInfo.find(
				(versionInfo: VersionInfo) => {
					let selectedVersion;
					switch (selectedOption) {
						case AGENT_TYPES.CT_AGENT:
							selectedVersion = agentServerVersion;
							break;
						case AGENT_TYPES.CT_USER_AGENT:
							selectedVersion = agentUserVersion;
							break;
						case AGENT_TYPES.CT_CONTAINER_AGENT:
							selectedVersion = agentContainerVersion;
							break;
						case AGENT_TYPES.CT_LEGACY_AGENT:
							selectedVersion = agentLegacyVersion;
							break;
						default:
							selectedVersion = agentConnectorVersion;
							break;
					}

					return versionInfo.version === selectedVersion[index];
				}
			);

			let currentVersion: string = "";

			if (filteredVersions?.architecture === architecture) {
				currentVersion = filteredVersions?.version;
			} else {
				currentVersion =
					agentVersionTypeInfo.find(
						(versionInfo: VersionInfo) =>
							versionInfo.architecture === architecture &&
							versionInfo.recommended
					)?.version ?? "";
			}

			return currentVersion;
		},
		[
			agentConnectorVersion,
			agentContainerVersion,
			agentLegacyVersion,
			agentServerVersion,
			agentUserVersion,
			selectedOption,
		]
	);

	const [highestCurrentAgentVersion, setHighestAgentVersion] =
		useState("0.0.0");

	const supportedPlatformArchitectures = useMemo(
		() =>
			page === AgentActionType.UPGRADE
				? supportedPlatformArchitecturesUpgradePage({
						platform: platform,
						agentsGroupByPlatforms: agentsGroupByPlatforms,
					})
				: supportedPlatformArchitecturesDownloadPage({
						platform: platform,
					}),
		[page, agentsGroupByPlatforms, platform]
	);

	useEffect(() => {
		if (supportedPlatformArchitectures[0]) {
			setArchitecture(supportedPlatformArchitectures[0]);
		}
	}, [setArchitecture, supportedPlatformArchitectures]);

	return (
		<Stack
			sx={{
				px: page === AgentActionType.UPGRADE ? 0 : 5,
				py: page === AgentActionType.UPGRADE ? 5 : 0,
				height: page === AgentActionType.UPGRADE ? "100%" : 100,
				width: "100%",
				background: theme =>
					page === AgentActionType.UPGRADE
						? "transparent"
						: theme.palette.mode === "dark"
							? theme.palette.divider
							: theme.palette.background.paper,
				borderBottom: theme => `1px solid ${theme.palette.divider}`,
			}}
			direction="row"
			alignItems={"center"}
			justifyContent={"space-between"}
			boxShadow={page === AgentActionType.UPGRADE ? 0 : 2}
			borderRadius={page === AgentActionType.UPGRADE ? 0 : 1}
			flexWrap={page === AgentActionType.UPGRADE ? "wrap" : "nowrap"}
		>
			{page === AgentActionType.UPGRADE ? (
				<AgentUpgradeContent
					platform={platform}
					supportedPlatformArchitectures={supportedPlatformArchitectures}
					getAgentVersionInfo={getAgentVersionInfo}
					agentsGroupByPlatforms={agentsGroupByPlatforms}
					selectedAgentType={selectedAgentType}
					highestCurrentAgentVersion={highestCurrentAgentVersion}
					handleChange={handleChange}
					page={page}
					setHighestAgentVersion={setHighestAgentVersion}
				/>
			) : (
				<AgentDownloadItemContent
					{...props}
					supportedPlatformArchitectures={supportedPlatformArchitectures}
					selectedAgentType={selectedAgentType}
					highestCurrentAgentVersion={highestCurrentAgentVersion}
					handleChange={handleChange}
					architecture={architecture}
					setArchitecture={setArchitecture}
					agentGrpByObj={agentGrpByObj}
					selectedOption={selectedOption}
					agentVersionTypeInfo={agentVersionTypeInfo}
					setSelectedOption={setSelectedOption}
					setHighestAgentVersion={setHighestAgentVersion}
				/>
			)}
		</Stack>
	);
}

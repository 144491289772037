export const getDateLabelFromHourUnit = (hour: string) => {
	if (!hour) {
		return "";
	}
	const hours = Number(hour);
	const days = Math.floor(hours / 24);
	const weeks = Math.floor(hours / 168);
	const months = Math.floor(hours / 720);
	const years = Math.floor(hours / 8760);

	if (hours === 1) {
		return `${hours} hour`;
	}
	if (hours < 24) {
		return `${hours} hours`;
	}

	if (hours < 25) {
		return `${days} day`;
	}
	if (hours < 168) {
		return `${days} days`;
	}

	if (hours < 169) {
		return `${weeks} week`;
	}
	if (hours < 720) {
		return `${weeks} weeks`;
	}

	if (hours < 721) {
		return `${months} month`;
	}
	if (hours < 8760) {
		return `${months} months`;
	}

	if (hours < 8761) {
		return `${years} year`;
	}
	return `${years} years`;
};

import { Box, Stack, debounce } from "@mui/material";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { SecurityPatchBody } from "../../types";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { SECURITY_PATCHES_COLUMNS } from "./constants";
import { useVulnerabilityStore } from "modules/vulnerability-drawer/stores";

interface SecurityPatchesDataGridProps {
	assetId: string;
}

const useSecurityPatchesAPI = (assetId: string) => {
	return useMutation<any, Error, any>([
		"security-patches-list",
		`assets/${assetId}/securitypatches?computeTotal=true`,
	]);
};

export const SecurityPatchesDataGrid = (
	props: SecurityPatchesDataGridProps
) => {
	const patchesAPI = useSecurityPatchesAPI(props.assetId);
	const [rows, setRows] = useState<Array<SecurityPatchBody> | undefined>(
		undefined
	);
	const [totalCount, setTotalCount] = useState(0);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const apiRefreshRequest = useVulnerabilityStore(
		(state: { apiRefreshRequest: any }) => state.apiRefreshRequest
	);

	const mutate = useMemo(
		() => debounce(patchesAPI.mutate, 300),
		[patchesAPI.mutate]
	);

	useEffect(() => {
		const search = {
			pagination: {
				offset: page * pageSize,
				limit: pageSize,
			},
		};

		const dataMapper = (pack: SecurityPatchBody) => {
			return pack;
		};
		mutate(search, {
			onSuccess(data) {
				if (data) {
					const responseData = data;
					setRows((responseData?.items || []).map(dataMapper) || []);
					setTotalCount(responseData?.metadata.total || 0);
				}
			},
		});
	}, [props, mutate, page, pageSize, apiRefreshRequest]);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					rowHeight={64}
					columns={SECURITY_PATCHES_COLUMNS}
					pagination
					getRowId={({ packageName }: SecurityPatchBody) => packageName}
					paginationMode="server"
					sortingMode="server"
					isLoading={patchesAPI.isLoading || !rows}
					rows={rows}
					rowCount={totalCount}
					onPageChange={setPage}
					page={page}
					pageSize={pageSize}
					onPageSizeChange={setPageSize}
				/>
			</Box>
		</Stack>
	);
};

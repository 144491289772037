import { Tooltip, TooltipProps, tooltipClasses, styled } from "@mui/material";

export const CTTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow={props.arrow} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
		margin: "0.5rem !important",
	},
}));

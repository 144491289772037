import { Asset, CoverageCount, ReviewCoverage } from "pages/assets/types";

const empty: CoverageCount = {
	unreviewed: 0,
	reviewed: 0,
	total: 0,
	allowed: 0,
	denied: 0,
};
const zeroReviewCoverage: ReviewCoverage = {
	inboundInternetPorts: empty,
	// outboundInternetPorts: empty, Not supported for now
	inboundIntranetPorts: empty,
	// outboundIntranetPorts: empty, Not supported for now
	inboundInternetPaths: empty,
	outboundInternetPaths: empty,
	inboundIntranetPaths: empty,
	outboundIntranetPaths: empty,
};

export function calculateReviewCoverage(asset: Asset): ReviewCoverage {
	if (!asset) {
		return zeroReviewCoverage;
	} else {
		return {
			inboundInternetPorts: {
				...empty,
				...asset.inboundInternetPorts,
			},
			// outboundInternetPorts: number; Not supported for now
			inboundIntranetPorts: {
				...empty,
				...asset.inboundIntranetPorts,
			},
			// outboundIntranetPorts: number; Not supported for now
			inboundInternetPaths: {
				...empty,
				...asset.inboundInternetPaths,
			},
			outboundInternetPaths: {
				...empty,
				...asset.outboundInternetPaths,
			},
			inboundIntranetPaths: {
				...empty,
				...asset.inboundIntranetPaths,
			},
			outboundIntranetPaths: {
				...empty,
				...asset.outboundIntranetPaths,
			},
		};
	}
}

import { Link, Stack, Tooltip } from "@mui/material";
import { getWorkRequestRedirectRoute } from "modules/PendingWorkRequestsDrawer/helpers";
import { useWorkRequestStore } from "modules/PendingWorkRequestsDrawer/store";
import { PendingWorkRequestsColumnRenderProps } from "modules/PendingWorkRequestsDrawer/types";
import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";

export const PendingWorkRequestResource = ({
	pendingItems,
}: PendingWorkRequestsColumnRenderProps) => {
	const setOpen = useWorkRequestStore(state => state.setOpen);

	const redirectPath = useMemo(() => {
		return getWorkRequestRedirectRoute(
			pendingItems.resourceId,
			pendingItems?.action
		);
	}, [pendingItems.action, pendingItems.resourceId]);

	const onClick = () => {
		if (!redirectPath) {
			return;
		}
		setOpen(false);
	};

	return (
		<Stack width="100%">
			<Link
				typography={"body2"}
				underline={redirectPath ? "hover" : "none"}
				color={redirectPath ? "inherit" : "text.disabled"}
				component={RouterLink}
				to={redirectPath ?? "#"}
				onClick={onClick}
				noWrap
			>
				<Tooltip title={window.getCTTranslatedText("View resource")}>
					<span>
						{pendingItems?.resourceName ?? window.getCTTranslatedText("View")}
					</span>
				</Tooltip>
			</Link>
		</Stack>
	);
};

import { Box } from "@mui/material";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { AssignedTemplatesViewDrawer } from "modules/asset-templates/assigned-templates-view-drawer";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useAssetTemplates } from "modules/asset-templates/hooks";
import { AssignTemplatesOption } from "pages/tags/components/tag-policy-list/components/tag-templates/components/assigned-templates-tags/AssignedTemplatesTags";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";

export function ManageAssetTemplates({
	criteria,
	multiselect = false,
	open,
	onClose,
	onConfirm,
}: {
	criteria: string;
	multiselect?: boolean;
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
}) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const {
		showAddToTemplateDrawer,
		openAddToTemplateDrawer,
		closeAddToTemplateDrawer,
		templateList,
		isLoading,
		onSortChange,
		setPageSize,
		setPage,
		pageSize,
		page,
		sort,
		totalCount,
		templateListMutation,
	} = useAssetTemplates({ criteria });

	const onConfirmChanges = () => {
		closeAddToTemplateDrawer();
	};

	if (!criteria) {
		return <Box style={{ width: 42 }} />;
	}

	return (
		<>
			{showAddToTemplateDrawer && (
				<AddToTemplateDrawer
					isOpen={showAddToTemplateDrawer}
					page={"assets"}
					title="Assign Template"
					rules={[]}
					onCancel={closeAddToTemplateDrawer}
					onConfirm={onConfirmChanges}
					btnTitle={"assign"}
					criteria={criteria}
					multiselect={multiselect}
					hasPermission={userPermissions.has("UPDATE_ASSET")}
					actionType={TemplateActionType.assign}
				/>
			)}

			{open && (
				<AssignedTemplatesViewDrawer
					isOpen={open}
					onClose={onClose}
					templateList={templateList}
					templatesSuggestMutation={templateListMutation}
					isLoading={isLoading}
					AssignTemplatesOption={() =>
						AssignTemplatesOption({
							openAddToTemplateDrawer,
							hasPermission: userPermissions.has("UPDATE_ASSET"),
						})
					}
					page={"assets"}
					onSortChange={onSortChange}
					multiselect={multiselect}
					criteria={criteria}
					hasPermission={userPermissions.has("UPDATE_ASSET")}
					setPageSize={setPageSize}
					setPage={setPage}
					pageSize={pageSize}
					pageCount={page}
					sort={sort}
					totalCount={totalCount}
					showSearch={false}
				/>
			)}
		</>
	);
}

import CloseIcon from "@mui/icons-material/Close";
import {
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { Toolbar } from "common/atoms/toolbar";
import { PathsReview } from "modules/ports-paths-review/components/paths-review";
import { PathStatusActionBar } from "modules/ports-paths-review/components/paths-review/components/path-status-actionbar";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";
import { StatusOptionsText } from "pages/paths/constants";
import { Path, PathStatus } from "pages/paths/types";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { usePathListCommonStore as useFacetStore } from "./store/common/usePathListCommonStore";
import { usePathListStore } from "./store/path-store";

interface PathsViewDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	selectedData: Path[] | undefined;
	selectedStatus: PathStatus | undefined;
	confirm: Function;
	title?: string;
}

export const PathsViewDrawer = ({
	isOpen,
	onClose,
	selectedStatus,
	selectedData,
	title,
	confirm,
}: PathsViewDrawerProps) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [selectedPathStatus, setSelectedPathStatus] = useState<
		PathStatus | undefined
	>(selectedStatus || undefined);

	// paths table config
	const [preSelectedPaths, setPreSelectedPaths] = useState<Array<GridRowId>>(
		[]
	);
	const [pathsSelection, setPathsSelection] = useState<Array<GridRowId>>([]);

	const [comment, setComment] = useState<string | undefined>(undefined);

	const onSelectPathStatus = (status: PathStatus | undefined) => {
		setSelectedPathStatus(status);
	};

	const confirmPathAction = () => {
		if (pathsSelection?.length > 0) {
			confirm(pathsSelection);
		}
		setPathsSelection([]);
		setComment(undefined);
		setSelectedPathStatus(undefined);
	};

	useEffect(() => {
		if (selectedData && selectedData?.length) {
			const selectedPathIds: GridRowId[] = selectedData.map(
				(path: Path) => `${path.channelHash}-${path.direction}`
			);
			setPreSelectedPaths(selectedPathIds);

			if (searchParams.has("recommendation")) {
				searchParams.delete("recommendation");
				setSearchParams(searchParams);
			}
		}
	}, [selectedData, setSearchParams, searchParams]);

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					padding: "0px",
					width: "80%",
					minWidth: "1000px",
					height: "100%",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title="Close Drawer">
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<Stack direction="row" spacing={2} sx={{ mt: 5, mb: 3, mx: 4 }}>
				<Typography variant="h5">
					<b>{title}</b>
					{Boolean(selectedPathStatus) && ":"}
				</Typography>
				<Typography variant="h5">
					{!!selectedPathStatus && StatusOptionsText[selectedPathStatus]}
				</Typography>
			</Stack>
			<DialogContent>
				<PathsReview
					preSelectedPaths={preSelectedPaths}
					preSelectedPathStatus={selectedStatus}
					setPathSelection={setPathsSelection}
					pathFilter={{
						criteria: `channelHash in (${selectedData
							?.map(({ channelHash }) => `'${channelHash}'`)
							.join(",")})`,
					}}
					updatePathstatus={onSelectPathStatus}
					useFacetStore={useFacetStore}
					useStore={usePathListStore}
					includeCommonFacet={true}
					setComment={setComment}
					comment={comment}
					mode={AssetReviewModeType.Selection}
				></PathsReview>
			</DialogContent>
			<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
				<PathStatusActionBar
					selectedStatus={selectedPathStatus}
					selection={pathsSelection}
					update={confirmPathAction}
					cancel={onClose}
					comment={comment}
				></PathStatusActionBar>
			</DialogActions>
		</Drawer>
	);
};

import { getColor } from "common/constants/colors";
import { SecurityLevels } from "pages/assets/types";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import KeyboardDoubleArrowDown from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";

export function AssetSecurityLevelIcons({
	securityLevel,
}: {
	securityLevel: SecurityLevels;
}) {
	let icon = (
		<ChangeHistoryIcon
			color="inherit"
			style={{ fontSize: 14, marginLeft: 2 }}
		/>
	);

	switch (securityLevel) {
		case SecurityLevels.High:
			icon = (
				<KeyboardDoubleArrowUpIcon
					style={{ color: getColor(SecurityLevels.High) }}
				/>
			);
			break;
		case SecurityLevels.Critical:
			icon = (
				<FlagOutlinedIcon
					style={{ color: getColor(SecurityLevels.Critical) }}
				/>
			);
			break;
		case SecurityLevels.Low:
			icon = (
				<KeyboardDoubleArrowDown
					style={{ color: getColor(SecurityLevels.Low) }}
				/>
			);
			break;
		case SecurityLevels.Medium:
			icon = (
				<WarningAmberOutlinedIcon
					style={{ color: getColor(SecurityLevels.Medium) }}
				/>
			);
			break;
	}

	return <>{icon}</>;
}

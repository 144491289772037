import { Button, Stack } from "@mui/material";
import { useState } from "react";
import { AgentDrawer } from "./AgentDownloadDrawer";
import { AgentDrawerSwitchControl } from "./AgentDrawerSwitchControl";
import { GetAgentInstallationInfoScript } from "./AgentInstallationInfo";
import { Terminal } from "./Terminal";
import { AGENT_TYPES, PLATFORMS, installScript } from "./constants";
import {
	RenderDownloadFileName,
	RenderDownloadLink,
} from "./helpers/versionHelpers";
import { VersionInfo } from "./type";

interface InstallationScriptProps {
	agentVersionTypeInfo: VersionInfo[];
	platform: PLATFORMS;
	agentType?: AGENT_TYPES;
	selectedOption: AGENT_TYPES;
	selectedAgent: string;
	latestDepKey?: string;
	selectedAgentType: string;
	linuxScriptDownloadLink?: string;
	windowsScriptDownloadLink?: string;
}

export const InstallationScript = ({
	agentVersionTypeInfo,
	platform,
	agentType,
	selectedOption,
	selectedAgent,
	latestDepKey,
	selectedAgentType,
	linuxScriptDownloadLink,
	windowsScriptDownloadLink,
}: InstallationScriptProps) => {
	const [drawerIScriptOpen, setDrawerIScriptOpen] = useState(false);

	const onCloseIScriptDrawer = (value?: boolean) => {
		setDrawerIScriptOpen(false);
	};

	const installScriptDisabled = () => {
		return Boolean(agentVersionTypeInfo?.length === 0);
	};

	return (
		<Stack sx={{ minWidth: 200 }}>
			<Button
				color="primary"
				variant="outlined"
				disabled={installScriptDisabled()}
				onClick={() => setDrawerIScriptOpen(true)}
			>
				{window.getCTTranslatedText(installScript)}
			</Button>

			<AgentDrawer
				drawerOpen={drawerIScriptOpen}
				onClose={onCloseIScriptDrawer}
				platform={platform}
				installationType={installScript}
				agentType={agentType}
			>
				<AgentDrawerSwitchControl
					platform={platform}
					agentType={selectedOption}
				>
					<Terminal>
						<GetAgentInstallationInfoScript
							pname={platform}
							agentType={selectedOption}
							selectedAgentTypeValue={selectedAgent}
							latestDepKey={latestDepKey}
							fileName={RenderDownloadFileName(
								agentVersionTypeInfo,
								selectedAgentType
							)}
							downloadLink={RenderDownloadLink(
								agentVersionTypeInfo,
								selectedAgentType
							)}
							agentVersion={selectedAgentType}
							latestLinuxScriptDownloadLink={linuxScriptDownloadLink}
							latestWindowsScriptDownloadLink={windowsScriptDownloadLink}
						/>
					</Terminal>
				</AgentDrawerSwitchControl>
			</AgentDrawer>
		</Stack>
	);
};

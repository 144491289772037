import { AuthState } from "hooks/useAuthState";
import { useComplianceController } from "hooks/useComplianceController";
import { useUserPermission } from "hooks/useUserPermission";
import { KnowledgeBase } from "modules/analytics";
import { NavigationLayout } from "modules/NavigationLayout/components";
import { Invite } from "pages/invite";
import { Route, Routes, useLocation } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { ROUTES } from "./routes";

interface CTRoutesProps {
	authState: AuthState;
	onLogout: Function;
}

export const CTRoutes = ({ authState, onLogout }: CTRoutesProps) => {
	const location = useLocation();

	useUserPermission({ authState, onLogout });
	useComplianceController({ authState });

	if (location.pathname.startsWith("/auth/invite")) {
		return (
			<Routes>
				<Route path={ROUTES.AUTH_INVITE.pathname} element={<Invite />} />;
			</Routes>
		);
	}

	if (authState === AuthState.LoggedIn) {
		return (
			<>
				<KnowledgeBase authState={authState} />
				<NavigationLayout onLogout={onLogout}>
					<Routes>
						<Route path={ROUTES.ALL.pathname} element={<PrivateRoutes />} />
					</Routes>
				</NavigationLayout>
			</>
		);
	}

	return (
		<Routes>
			<Route path={ROUTES.ALL.pathname} element={<PublicRoutes />} />
		</Routes>
	);
};

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IconButton, List, Stack } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { Toolbar } from "common/atoms/toolbar";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { NavListItem } from "modules/NavSidebar/components/NavListItem";
import { ROUTES } from "routes";
import { StyledNavSidebar } from "./styles";

export const NavSidebar = () => {
	const theme = useTheme();
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const isMenuExpanded = useUserPreferencesStore(
		state => state.isSidebarExpanded
	);
	const setIsMenuExpanded = useUserPreferencesStore(
		state => state.setIsSidebarExpanded
	);

	const handleMenuExpander = () => {
		const value = !isMenuExpanded;
		setIsMenuExpanded(value);
	};

	const menuExpanderStyle = {
		position: "relative",
		zIndex: "2",
		width: "18px",
		height: "18px",
		background: theme.palette.primary.contrastText,
		border: `1px solid ${
			theme.palette.mode === "dark"
				? alpha(theme.palette.common.white, 0.2)
				: alpha(theme.palette.common.black, 0.08)
		}`,
		color:
			theme.palette.mode === "dark"
				? theme.palette.common.white
				: theme.palette.common.black,
		"&:hover": {
			background: theme.palette.primary.main,
			color:
				theme.palette.mode === "dark"
					? theme.palette.common.black
					: theme.palette.common.white,
			border: `1px solid ${theme.palette.primary.main}`,
		},
	};

	const { isFeatureEnabled: isPanopticMapfeatureEnabled } =
		useFeatureFlagControl(FEATURES.PANOPTIC_MAP);
	const { isFeatureEnabled: isScimFeaturesEnabled } = useFeatureFlagControl(
		FEATURES.USER_SEGMENTATION
	);
	const { isFeatureEnabled: isCrowdStrikeFeatureEnabled } =
		useFeatureFlagControl(FEATURES.CROWDSTRIKE_EDR);

	const sideBarNavList = Object.entries(ROUTES).filter(([routeName, route]) => {
		if (
			!route.sidebar ||
			(!isPanopticMapfeatureEnabled && routeName === FEATURES.PANOPTIC_MAP) ||
			(!isScimFeaturesEnabled &&
				route.pathname === ROUTES.USER_SEGMENTATION.pathname) ||
			(!isCrowdStrikeFeatureEnabled &&
				route.pathname === ROUTES.CROWDSTRIKE_GROUPS.pathname)
		) {
			return false;
		}

		const excludedRoutes: string[] = [];

		return !excludedRoutes.includes(route.title ?? "");
	});

	const topSidebarNavList = sideBarNavList.filter(
		([_, route]) => !route.sidebar?.bottom
	);
	const bottomSidebarNavList = sideBarNavList.filter(([_, route]) => {
		if (
			route.title === ROUTES.SETTINGS.title &&
			!userPermissions.has("UPDATE_TENANT_AGENT_SECRET") &&
			!userPermissions.has("UPDATE_ALERTRULES")
		) {
			return false;
		}

		return route.sidebar?.bottom;
	});

	const expandedWidth = "180px";

	return (
		<StyledNavSidebar
			PaperProps={{
				elevation: 1,
			}}
			variant="permanent"
			anchor="left"
			theme={theme}
			className={isMenuExpanded ? "expanded" : ""}
			expandedWidth={expandedWidth}
		>
			<Stack
				alignItems="center"
				justifyItems="center"
				justifyContent="center"
				className="menu-expander"
			>
				<IconButton
					aria-label="toggle menu expander"
					sx={{ ...menuExpanderStyle }}
					onClick={handleMenuExpander}
				>
					{isMenuExpanded ? (
						<NavigateBeforeIcon
							sx={{
								fontSize: theme.typography.body2.fontSize,
							}}
						/>
					) : (
						<NavigateNextIcon
							sx={{
								fontSize: theme.typography.body2.fontSize,
							}}
						/>
					)}
				</IconButton>
			</Stack>
			<Toolbar />
			<List
				disablePadding
				sx={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					flex: 1,
					mt: 5,
				}}
			>
				{topSidebarNavList.map(([key, route]) => {
					return (
						<NavListItem
							key={key}
							id={key}
							route={route}
							isMenuExpanded={isMenuExpanded}
						/>
					);
				})}
			</List>
			<List sx={{ display: "flex", flexDirection: "column" }}>
				{bottomSidebarNavList.map(([key, route]) => {
					return (
						<NavListItem
							key={key}
							id={key}
							route={route}
							isMenuExpanded={isMenuExpanded}
						/>
					);
				})}
			</List>
		</StyledNavSidebar>
	);
};

import CloseIcon from "@mui/icons-material/Close";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Tab,
	Tooltip,
	Typography,
} from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { CtTabs } from "common/atoms/ct-tabs";
import { TabPanel } from "common/atoms/ct-tabs/CtTabs";
import { Toolbar } from "common/atoms/toolbar";
import { PathsReview } from "modules/ports-paths-review/components/paths-review";
import { PathStatusActionBar } from "modules/ports-paths-review/components/paths-review/components/path-status-actionbar";
import { PortsReview } from "modules/ports-paths-review/components/ports-review";
import { PortStatusActionBar } from "modules/ports-paths-review/components/ports-review/components/port-status-actionbar";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { PathFilters, PathStatus } from "pages/paths/types";
import { PortStatus } from "pages/ports/types";
import { PathType } from "pages/traffic-visualizer/types";
import { useEffect, useState } from "react";
import { EdgeCountBreakdown } from "./components/edge-count-breakdown";
import { useAssetDetailCommonStore as useFacetStore } from "./store/common/useAssetDetailCommonStore";

interface EdgeDetailsReviewDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	activeTab?: Direction;
	pathType: PathType;
	pathFilter: PathFilters | undefined;
	portFilter: PathFilters | string | undefined;
	reload: VoidFunction;
	pathReviewState: string;
}

export const EdgeDetailsReviewDrawer = ({
	isOpen,
	onClose,
	activeTab = Direction.Inbound,
	pathType,
	pathFilter,
	portFilter,
	pathReviewState = "reviewed",
}: EdgeDetailsReviewDrawerProps) => {
	const facets = useFacetStore(state => state.facets);
	const readOnlyDimension = Array.from(facets?.keys() ?? []);
	const [selectedPortStatus, setSelectedPortStatus] = useState<
		PortStatus | undefined
	>(undefined);
	const [selectedPathStatus, setSelectedPathStatus] = useState<
		PathStatus | undefined
	>(undefined);

	const [selectedTab, setTab] = useState<Direction>(activeTab);
	useEffect(() => {
		setTab(activeTab);
	}, [activeTab]);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	// ports table config
	const [portsSelection, setPortsSelection] = useState<Array<GridRowId>>([]);

	// paths table config
	const [pathsSelection, setPathsSelection] = useState<Array<GridRowId>>([]);

	const [comment, setComment] = useState<string | undefined>(undefined);

	const confirmPortAction = () => {
		setPortsSelection([]);
		setSelectedPortStatus(undefined);
		setComment(undefined);
		onClose();
	};

	const onSelectPortStatus = (status: PortStatus | undefined) => {
		setSelectedPortStatus(status);
	};

	const onSelectPathStatus = (status: PathStatus | undefined) => {
		setSelectedPathStatus(status);
	};

	const confirmPathAction = () => {
		setPathsSelection([]);
		setSelectedPathStatus(undefined);
		setComment(undefined);
		onClose();
	};

	const [showEdgeDetails, setShowEdgeDetails] = useState(false);

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					p: 0,
					width: "80%",
					minWidth: "1000px",
					height: "100%",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<DialogContent sx={{ width: "100%" }}>
				<Stack
					alignItems="flex-start"
					sx={{ position: "relative", width: "100%", height: "100%" }}
				>
					<Tooltip title="Close Drawer">
						<IconButton
							size="medium"
							aria-label="close drawer"
							onClick={onClose}
							sx={{
								position: "absolute",
								right: "0",
								top: "8px",
								zIndex: 2,
							}}
						>
							<CloseIcon fontSize="medium" />
						</IconButton>
					</Tooltip>

					<Stack
						alignItems={"flex-start"}
						spacing={0}
						sx={{ width: "100%", height: "100%" }}
					>
						<Stack sx={{ mt: 4 }}>
							<Typography variant="h5">
								<b>
									{window.getCTTranslatedText(
										pathType === PathType.Internet
											? "Internet Traffic"
											: "Intranet Traffic"
									)}
								</b>
							</Typography>
						</Stack>
						<Stack sx={{ width: "100%", flex: 1, overflow: "hidden" }}>
							<Box sx={{ borderBottom: 1, borderColor: "divider", my: 3 }}>
								<Stack
									direction="row"
									justifyContent={"space-between"}
									alignItems={"center"}
								>
									<CtTabs value={selectedTab} onChange={handleTabChange}>
										<Tab
											style={{
												display: !Boolean(portFilter) ? "none" : undefined,
											}}
											disabled={!Boolean(portFilter)}
											icon={<InstallDesktopIcon />}
											iconPosition="start"
											label={window.getCTTranslatedText("Ports")}
										/>

										<Tab
											icon={<ForkRightIcon />}
											iconPosition="start"
											label={window.getCTTranslatedText("Paths")}
										/>
									</CtTabs>
									<Tooltip
										title={window.getCTTranslatedText(
											"View breakdown by path status"
										)}
									>
										<Button
											variant="outlined"
											onClick={() => setShowEdgeDetails(true)}
										>
											{window.getCTTranslatedText("View breakdown")}
										</Button>
									</Tooltip>
								</Stack>
							</Box>
							<TabPanel
								style={{ flex: 1, overflow: "hidden" }}
								value={selectedTab}
								index={Direction.Inbound}
							>
								{Boolean(portFilter) && (
									<PortsReview
										mergeWithExistingFacets
										setPortSelection={setPortsSelection}
										portFilter={portFilter || ""}
										updatePortstatus={onSelectPortStatus}
										useFacetStore={useFacetStore}
										updatePathstatus={onSelectPathStatus}
										setComment={setComment}
										comment={comment}
										mode={AssetReviewModeType.EdgeReview}
										readOnlyDimensions={readOnlyDimension}
									></PortsReview>
								)}
							</TabPanel>

							<TabPanel
								style={{ flex: 1, overflow: "hidden" }}
								value={selectedTab}
								index={Direction.Outbound}
							>
								{Boolean(pathFilter) && (
									<PathsReview
										mergeWithExistingFacets
										setPathSelection={setPathsSelection}
										pathFilter={pathFilter}
										updatePathstatus={onSelectPathStatus}
										useFacetStore={useFacetStore}
										setComment={setComment}
										comment={comment}
										mode={AssetReviewModeType.EdgeReview}
										readOnlyDimensions={readOnlyDimension}
									/>
								)}
							</TabPanel>
						</Stack>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
				{selectedTab === Direction.Inbound && (
					<PortStatusActionBar
						selectedStatus={selectedPortStatus}
						selection={portsSelection}
						update={confirmPortAction}
						cancel={onClose}
						selectedPathStatus={selectedPathStatus}
						comment={comment}
					></PortStatusActionBar>
				)}
				{selectedTab === Direction.Outbound && (
					<PathStatusActionBar
						selectedStatus={selectedPathStatus}
						selection={pathsSelection}
						update={confirmPathAction}
						cancel={onClose}
						comment={comment}
					></PathStatusActionBar>
				)}
			</DialogActions>

			<EdgeCountBreakdown
				title={"Path Status Breakup"}
				open={showEdgeDetails}
				handleClose={() => setShowEdgeDetails(false)}
				destinationCriteria={pathFilter?.dstCriteria}
				sourceCriteria={pathFilter?.srcCriteria}
				trafficCriteria={pathFilter?.criteria}
				pathReviewState={pathReviewState}
			/>
		</Drawer>
	);
};

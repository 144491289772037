import { Box, Stack } from "@mui/material";
import {
	GRID_REORDER_COL_DEF,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { COMMON_FACETS } from "modules/facets/constants";
import DeleteFirewallHostGroupButton from "./components/DeleteFirewallHostGroupButton";
import FirewallHostGroupCriteria from "./components/FirewallHostGroupCriteria";
import { FirewallhostGroupDataGridRowItem } from "./types";

export const FIREWALL_HOST_GROUPS_FACETS = {
	...COMMON_FACETS,
};

export enum FIREWALL_HOST_GROUPS_PERMISSION {
	CREATE = "CREATE_CROWDSTRIKE_FIREWALL_HOSTGROUP",
	LIST = "LIST_CROWDSTRIKE_FIREWALL_HOSTGROUPS",
	EDIT = "UPDATE_PRECEDENCE_CROWDSTRIKE_FIREWALL_HOSTGROUPS",
	DELETE = "DELETE_CROWDSTRIKE_FIREWALL_HOSTGROUP",
}

export const FIREWALL_HOST_GROUPS_COLUMNS: (
	isFacetable: boolean,
	isRowReorderingEnabled: boolean,
	showDelete: boolean
) => GridColDef[] = (isFacetable, isRowReorderingEnabled, showDelete) => [
	{
		field: isRowReorderingEnabled ? "__reorder__" : "precedence",
		headerName: "Precedence",
		sortable: false,
		pinnable: true,
		flex: 1,
		renderCell: (
			params: GridRenderCellParams<FirewallhostGroupDataGridRowItem>
		) => {
			return (
				<Stack
					direction="row"
					alignItems="center"
					gap={2}
					sx={{
						"& .MuiDataGrid-rowReorderCell": {
							flex: 0,
							pb: "2px",
						},
					}}
				>
					{isRowReorderingEnabled && GRID_REORDER_COL_DEF.renderCell?.(params)}
					{params.row.precedence}
				</Stack>
			);
		},
	},
	{
		field: "name",
		headerName: "Name",
		sortable: false,
		pinnable: true,
		hideable: true,
		flex: 1,
		renderCell: (
			params: GridRenderCellParams<FirewallhostGroupDataGridRowItem>
		) => params.row.crowdstrikeFirewallHostgroupName,
	},
	{
		field: "criteria",
		headerName: "Criteria",
		sortable: false,
		pinnable: true,
		hideable: true,

		flex: 1,
		renderCell: (
			params: GridRenderCellParams<FirewallhostGroupDataGridRowItem>
		) => {
			return (
				<Box sx={{ pointerEvents: isFacetable ? "auto" : "none" }}>
					<FirewallHostGroupCriteria data={params.row} />
				</Box>
			);
		},
	},
	{
		field: "description",
		headerName: "Description",
		sortable: false,
		pinnable: true,
		hideable: true,
		flex: 1,
		renderCell: (
			params: GridRenderCellParams<FirewallhostGroupDataGridRowItem>
		) => params.row.description,
	},
	...(showDelete
		? [
				{
					field: "action",
					headerName: "",
					sortable: false,
					disableColumnMenu: true,
					width: 52,
					minWidth: 52,
					renderCell: (
						params: GridRenderCellParams<FirewallhostGroupDataGridRowItem>
					) => {
						return (
							<DeleteFirewallHostGroupButton
								crowdstrikeFirewallHostgroupId={
									params.row.crowdstrikeFirewallHostgroupId
								}
							/>
						);
					},
				},
			]
		: []),
];

const FIREWALL_HOST_GROUPS_PINNED_COLUMNS = {
	left: [GRID_REORDER_COL_DEF.field, "precedence"],
	right: [],
};

const ColumnVisibilityModel: { [key: string]: boolean } = {
	// Hide columns from the display
};

export const FIREWALL_HOST_GROUPS_COLUMN_CONFIG = {
	ColumnVisibilityModel: ColumnVisibilityModel,
	PinnedColumns: FIREWALL_HOST_GROUPS_PINNED_COLUMNS,
};

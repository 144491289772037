import { Box, Stack } from "@mui/material";

import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { useCommonStore } from "common/store";
import { PortColumnRenderProps } from "pages/ports/types";
import { PortOpenToInternet } from "../port-open-to-internet";

interface PortDetailsProps extends PortColumnRenderProps {
	showInternetFacingComp?: boolean;
}

export function PortDetails({
	port,
	viewOnly,
	useFacetStore = useCommonStore,
	showInternetFacingComp = true,
}: PortDetailsProps) {
	if (!port.lpId) {
		return <Box />;
	}

	return (
		<Box sx={{ maxWidth: "100%" }}>
			<Stack
				direction={"row"}
				alignItems="center"
				justifyContent="flex-start"
				sx={{ maxWidth: "100%" }}
			>
				{Boolean(showInternetFacingComp) && (
					<PortOpenToInternet
						port={port}
						viewOnly={viewOnly}
						useFacetStore={useFacetStore}
					/>
				)}
				<Stack direction={"column"}>
					<Stack
						direction={"row"}
						alignItems="center"
						justifyContent="flex-start"
						sx={{ maxWidth: "100%" }}
					>
						{port?.listenPortProtocol && (
							<CTDatagridButton
								asText={viewOnly}
								isDisabled={viewOnly}
								useFacetStore={useFacetStore}
								facetName={"listenportprotocol"}
								facetOption={port.listenPortProtocol ?? ""}
							>
								{port?.listenPortProtocol}
							</CTDatagridButton>
						)}

						<CTDatagridButton
							asText={viewOnly}
							isDisabled={viewOnly}
							useFacetStore={useFacetStore}
							facetName={"listenport"}
							facetOption={port.listenPort.toString() ?? ""}
						>
							{port.listenPort}
						</CTDatagridButton>
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);
}

import { FacetOptionUnit } from "modules/scope-metadata/types";
import prettyBytes from "pretty-bytes";

export const convertStringRangeToReadableBytes = (
	key: string,
	unit: FacetOptionUnit
) => {
	if (!key || key === "zero" || key === "0" || key === "null") {
		return "Zero";
	}

	if (key.includes("+")) {
		const start = parseInt(key.split("+")[0]);
		return `${prettyBytes(start)}+`;
	}

	const [start, end] = key.split(" - ");

	if (!end) {
		return `${prettyBytes(parseInt(start))}`;
	} else {
		return `${prettyBytes(parseInt(start))} - ${prettyBytes(parseInt(end))}`;
	}
};

import { Box, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { HostGroup } from "pages/crowdstrike-groups/host-groups/types";
import { useCallback, useMemo, useState } from "react";
import { HostGroupToolbar } from "../host-group-toolbar";
import { HOST_GROUPS_COLUMNS, HostGroupsColumnConfig } from "./constants";

export const HostGroupsDataGrid = (props: DataGridProps<HostGroup>) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const [selection, setSelection] = useState<Array<GridRowId>>([]);

	const selectedData: Array<HostGroup> | undefined = useMemo(() => {
		return props.rows?.filter((row: HostGroup) => {
			return selection.indexOf(row.id) !== -1;
		});
	}, [selection, props.rows]);

	const onClickClearSelection = () => {
		setSelection([]);
	};

	const showCheckboxSelection =
		userPermissions.has("UPDATE_CROWDSTRIKE") ||
		userPermissions.has("UPDATE_CROWDSTRIKE_HOSTGROUPS");

	const renderToolbar = useCallback(
		(children?: React.ReactNode) => (
			<HostGroupToolbar
				hideToolbar={onClickClearSelection}
				show={true}
				selectedData={selectedData}
				hiddenColumns={children}
			/>
		),
		[selectedData]
	);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					initialState={{
						sorting: {
							sortModel: [
								{ field: "hostgroupstatus", sort: "desc" },
								{ field: "hostgroupname", sort: "desc" },
							],
						},
					}}
					defaultPinnedColumns={HostGroupsColumnConfig.PinnedColumns}
					checkboxSelection={showCheckboxSelection}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						setSelection(selectionModel);
					}}
					rowHeight={64}
					columns={HOST_GROUPS_COLUMNS}
					pagination
					getRowId={({ id }: HostGroup) => id!}
					paginationMode="server"
					sortingMode="server"
					renderToolbar={renderToolbar}
					{...props}
				/>
			</Box>
		</Stack>
	);
};

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
	Box,
	Button,
	FormControl,
	Grid,
	Stack,
	Typography,
} from "@mui/material";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { CTIPInput } from "common/atoms/ct-ip-input";
import { IP_FIELD_TYPES } from "common/atoms/ct-ip-input/CTIPInput";
import { Appliance } from "pages/appliances/types";
import { NetworkInterfaces, VLAN } from "../types";
import { IPWithMask_PLACEHOLDER } from "../utils";
import { HAStandby } from "./components";

interface PeerApplianceProps {
	agentId?: string;
	peerAppliances?: Array<string>;
	selectedPeerAppliance?: Appliance;
	setSelectedPeerAppliance: (value: Appliance) => void;
	wanPeerIP: string;
	setWanPeerIP: (value: string) => void;
	networkInterface: string;
	lanPeerIP: string;
	setLanPeerIP: (value: string) => void;
	VlanList: VLAN[];
	setVlanList: (VlanList: VLAN[]) => void;
	keyListener: (event: any) => void;
	addPeers: boolean;
	setAddPeers: (value: boolean) => void;
	disabledPeer: boolean;
	isFormDisabled: boolean;
	HAMode: string;
}

export function PeerAppliances({
	agentId,
	peerAppliances = [],
	selectedPeerAppliance,
	setSelectedPeerAppliance,
	wanPeerIP,
	setWanPeerIP,
	networkInterface,
	lanPeerIP,
	setLanPeerIP,
	VlanList,
	setVlanList,
	keyListener,
	addPeers,
	setAddPeers,
	disabledPeer,
	isFormDisabled,
	HAMode,
}: PeerApplianceProps) {
	const handleVlanFieldChange = (e: TextFieldUpdate, index: number) => {
		if (e?.value !== undefined) {
			const value = e?.value.trim() || "";
			const field = e?.field as keyof VLAN;
			let newVlanList = [...VlanList];
			newVlanList[index][field] = value;
			setVlanList(newVlanList);
		}
	};

	const scrollIntoView = () => {
		const element = document.getElementById(`peer-appliances`);
		if (element) {
			element.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	};

	function renderVLANPeerIPs() {
		return (
			<>
				{VlanList &&
					VlanList.length > 0 &&
					VlanList.map((item, index) => {
						return (
							<CTIPInput
								key={`${item?.id}-${index}`}
								field={"peerIP"}
								displayName={`${item?.id} ${window.getCTTranslatedText(
									"peerPhysicalIP"
								)}`}
								fieldType={IP_FIELD_TYPES.SUBNET}
								placeholder={IPWithMask_PLACEHOLDER}
								value={item?.peerIP}
								handleUpdate={(event: TextFieldUpdate) =>
									handleVlanFieldChange(event, index)
								}
								onKeyUp={keyListener}
								disabled={isFormDisabled}
							/>
						);
					})}
			</>
		);
	}

	function renderContent() {
		return (
			<Stack
				mt={2}
				py={2}
				sx={{
					background: theme =>
						theme.palette.mode === "dark"
							? theme.palette.background.paper
							: theme.palette.custom.lightGreyBg,
				}}
			>
				<FormControl>
					<Stack ml={4} mr={4}>
						<Stack>
							<HAStandby
								agentId={agentId}
								peerAppliances={peerAppliances}
								setSelectedPeerAppliance={setSelectedPeerAppliance}
								disabled={disabledPeer}
							/>
						</Stack>
						<Grid
							container
							columnGap={4}
							rowGap={2}
							sx={{ pl: 0 }}
							display="grid"
							gridTemplateColumns="1fr 1fr"
						>
							<Stack mt={4}>
								<Typography mb={2}>
									{window.getCTTranslatedText("WAN")}
								</Typography>
								<CTIPInput
									field="wanPeerIP"
									displayName={window.getCTTranslatedText("peerPhysicalIP")}
									fieldType={IP_FIELD_TYPES.SUBNET}
									placeholder={IPWithMask_PLACEHOLDER}
									value={wanPeerIP}
									handleUpdate={(event: TextFieldUpdate) =>
										setWanPeerIP(event?.value)
									}
									onKeyUp={keyListener}
									disabled={isFormDisabled}
								/>
							</Stack>
							<Stack mt={4}>
								<>
									<Typography mb={2}>{networkInterface}</Typography>
									{networkInterface === NetworkInterfaces.LAN ? (
										<CTIPInput
											field="lanPeerIP"
											displayName={window.getCTTranslatedText("peerPhysicalIP")}
											fieldType={IP_FIELD_TYPES.SUBNET}
											placeholder={IPWithMask_PLACEHOLDER}
											value={lanPeerIP}
											handleUpdate={(event: TextFieldUpdate) =>
												setLanPeerIP(event?.value)
											}
											onKeyUp={keyListener}
											disabled={isFormDisabled}
										/>
									) : (
										renderVLANPeerIPs()
									)}
								</>
							</Stack>
						</Grid>
					</Stack>
				</FormControl>
			</Stack>
		);
	}

	return (
		<Box sx={{ minWidth: "100%", mt: 8 }} id="peer-appliances">
			<Stack alignItems="flex-end" justifyContent="center">
				{addPeers ? (
					<Button
						variant="text"
						startIcon={<RemoveCircleOutlineIcon />}
						color="primary"
						onClick={() => setAddPeers(false)}
						disabled={isFormDisabled}
					>
						{window.getCTTranslatedText("Remove Peer Gatekeeper")}
					</Button>
				) : (
					<Button
						variant="text"
						startIcon={<AddCircleOutlineIcon />}
						color="primary"
						onClick={() => {
							setAddPeers(true);
							scrollIntoView();
						}}
						disabled={disabledPeer}
					>
						{window.getCTTranslatedText("Add Peer Gatekeeper")}
					</Button>
				)}
			</Stack>
			{addPeers ? renderContent() : null}
		</Box>
	);
}

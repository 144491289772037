import {
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GridColDef,
} from "@mui/x-data-grid-pro";
import { AlertRuleTypeIcon } from "pages/monitoring/components/alert-rule-type-icon/AlertRuleTypeIcon";
import { AlertRulename } from "pages/monitoring/components/alert-rulename";
import { AlertSeverity } from "pages/monitoring/components/alert-severity";
import { AlertType } from "pages/monitoring/components/alert-type/AlertType";
import { AlertConfigRuleStatus } from "../alert-config-rule-status";
import { AlertRuleActions } from "../alert-rule-actions";
import { NotificationConfigStatus } from "../notification-config-status/NotificationConfigStatus";

export const BASE_ALERTCONFIG_COLUMNS: GridColDef[] = [
	{
		field: "configuration",
		headerName: "",
		width: 40,
		flex: 0.05,
		sortingOrder: ["asc", "desc"],
		sortable: false,
		renderCell: params => {
			return <AlertRuleTypeIcon alertRule={params?.row} />;
		},
	},
	{
		field: "severity",
		headerName: "Severity",
		sortingOrder: ["asc", "desc"],
		width: 120,
		minWidth: 120,
		renderCell: params => {
			return (
				<AlertSeverity alert={params.row} severity={params.row.alertSeverity} />
			);
		},
	},
	{
		field: "alertRuleName",
		headerName: "Alert Name",
		width: 140,
		flex: 0.4,
		sortable: true,
		renderCell: params => {
			return <AlertRulename alert={params?.row} />;
		},
	},
	{
		field: "alertType",
		headerName: "Category",
		width: 140,
		flex: 0.3,
		sortable: true,
		renderCell: params => {
			return <AlertType alert={params?.row} />;
		},
	},
	{
		field: "description",
		headerName: "Description",
		width: 140,
		flex: 0.6,
		sortable: false,
		renderCell: params => {
			return window.getCTTranslatedText(params?.row.description);
		},
	},
	{
		field: "alertRuleStatus",
		headerName: "Status",
		width: 140,
		flex: 0.4,
		sortable: true,
		renderCell: params => {
			return <AlertConfigRuleStatus alertRule={params.row} />;
		},
	},
];

export const ALERTCONFIG_COLUMNS: GridColDef[] = [
	...BASE_ALERTCONFIG_COLUMNS,
	{
		field: "notificationStatus",
		headerName: "Notifications",
		width: 140,
		flex: 0.4,
		sortable: true,
		renderCell: params => {
			return <NotificationConfigStatus alertRule={params.row} />;
		},
	},
	{
		field: "actions",
		headerName: "",
		width: 60,
		sortable: false,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			return <AlertRuleActions alertRule={params.row} />;
		},
	},
];

const ColumnVisibilityModel = {
	// Hide columns from the display
};

const PinnedColumns = {
	left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
	right: [],
};

export const AlertConfigColumnConfig = {
	ColumnVisibilityModel: ColumnVisibilityModel,
	PinnedColumns: PinnedColumns,
};

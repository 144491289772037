import { Box, SliderProps, SxProps } from "@mui/material";
import { CtSlider } from "modules/ct-slider";

interface SecuritySliderProps<T> {
	minValue?: T;
	className?: string;
	selectedStatus: T;
	labelFormatter: (value: number) => string;
	onChange: SliderProps["onChange"];
	getMarks: () => SliderProps["marks"];
	disabled?: boolean;
	sliderSx?: SxProps;
}

export const SecuritySlider = <T,>({
	minValue,
	selectedStatus,
	labelFormatter,
	onChange,
	getMarks,
	className = "",
	disabled = false,
	sliderSx = {},
}: SecuritySliderProps<T>) => {
	return (
		<Box sx={{ position: "relative" }}>
			<Box
				sx={{
					border: theme => `1.5px solid ${theme.palette.custom.sliderBg}`,
					position: "absolute",
					width: `${minValue}%`,
					top: "13px",
					zIndex: 1,
				}}
			/>
			<CtSlider
				className={className}
				sx={sliderSx}
				disabled={disabled}
				value={Number(selectedStatus)}
				defaultValue={0}
				valueLabelFormat={labelFormatter}
				onChange={onChange}
				step={null}
				valueLabelDisplay="auto"
				marks={getMarks()}
			/>
		</Box>
	);
};

import { IconsProps } from "./common";

export const ContainerIcon = ({
	width = "16px",
	height = "16px",
	color = "black",
}: IconsProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 32"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M27.2877 7.44298L14.7065 0.19125C14.4881 0.0659264 14.2408 0 13.9892 0C13.7376 0 13.4904 0.0659264 13.2719 0.19125L0.722565 7.44298C0.504087 7.56957 0.322437 7.75135 0.195672 7.97024C0.068906 8.18914 0.00144826 8.43753 0 8.6907V23.2688C0.00116063 23.5206 0.0685885 23.7676 0.195477 23.9849C0.322366 24.2021 0.504201 24.3818 0.722565 24.5059L13.2826 31.8003C13.4992 31.931 13.7471 32 13.9998 32C14.2526 32 14.5005 31.931 14.7171 31.8003L27.2877 24.5059C27.5064 24.3804 27.6877 24.1986 27.8129 23.9792C27.9381 23.7599 28.0026 23.5109 27.9997 23.2582V8.7227C28.005 8.46515 27.9418 8.21086 27.8166 7.98594C27.6915 7.76103 27.509 7.57362 27.2877 7.44298ZM25.577 21.8718L21.1884 19.323V12.6258L14.7065 16.3583V23.898L20.4871 20.5494L24.8756 23.0875L14.0052 29.3901L3.13469 23.0875L7.52324 20.5494L13.3038 23.898V16.3797L6.82193 12.6472V19.323L2.43336 21.8718V9.27724L13.3038 2.97463V8.06151L7.52324 11.4101L14.0052 15.1426L20.4871 11.4101L14.7065 8.06151V2.97463L25.577 9.27724V21.8718Z"
				fill={color}
			/>
		</svg>
	);
};

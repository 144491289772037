import { Alert, CircularProgress, Stack, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { Annotation } from "modules/annotation";
import numeral from "numeral";
import { AssetStatusAction } from "pages/asset/components/asset-detail/components/asset-status-action";
import { ActionBarModes } from "pages/asset/components/asset-detail/components/asset-status-action/types";
import { AssetStatusSlider } from "pages/asset/components/asset-detail/components/asset-status-slider";
import {
	Direction,
	SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { AssetStatusDistributionControls } from "./components/AssetStatusDistributionControls";
import { useAssetsDistributionStats } from "./hooks/useAssetDistributionStats";
import {
	AssetSecurityStatusCriteriaBuilderProps,
	useAssetSecurityStatusCriteria,
} from "./hooks/useAssetSecurityStatusCriteria";

interface BulkAssetStatusControlProps
	extends AssetSecurityStatusCriteriaBuilderProps {
	setSelectedAssetGroupSecurityStatus: (status: SecurityStatus) => void;
	selectedSecurityStatus: SecurityStatus;
	setSelectedSecurityStatus: (status: SecurityStatus) => void;
	onClose: VoidFunction;
	isTestMode?: boolean;
	onChangeTestMode?: (testMode: boolean) => void;
	isUserGroupDimension?: boolean;
}

export function BulkAssetStatusControl({
	criteria,
	selectedAssetGroupSecurityStatus,
	setSelectedAssetGroupSecurityStatus,
	selectedSecurityStatus,
	selectedDirection,
	setSelectedSecurityStatus,
	assetMetadata,
	onClose,
	isTestMode,
	onChangeTestMode,
	isUserGroupDimension,
}: BulkAssetStatusControlProps) {
	const [isSimulated, setIsSimulated] = useState(false);
	const [comment, setComment] = useState<string | undefined>(undefined);
	const [isTestModeVisible, setIsTestModeVisible] = useState<boolean>(
		isTestMode ?? true
	);

	useEffect(() => {
		if (isTestMode !== undefined) {
			setIsTestModeVisible(isTestMode);
		}
	}, [isTestMode]);

	useEffect(() => {
		onChangeTestMode?.(isTestModeVisible);
	}, [isTestModeVisible, onChangeTestMode]);

	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const assetDistributionStats = useAssetsDistributionStats({
		criteria: criteria,
		field: `asset${
			selectedDirection === Direction.Inbound ? "inbound" : "outbound"
		}status`,
		enabled: true,
	});

	const [sliderValue, setSliderValue] = useState(
		selectedAssetGroupSecurityStatus
	);
	const theme = useTheme();

	useEffect(() => {
		setSliderValue(selectedAssetGroupSecurityStatus);
	}, [selectedAssetGroupSecurityStatus]);

	useEffect(() => {
		setSliderValue(selectedSecurityStatus);
	}, [selectedSecurityStatus]);

	const filterCriteria = useAssetSecurityStatusCriteria({
		selectedAssetGroupSecurityStatus,
		selectedDirection,
		assetMetadata,
		criteria,
	});

	useEffect(() => {
		if (sliderValue === SecurityStatus.Unsecure) {
			setIsTestModeVisible(false);
		}
	}, [sliderValue, isUserGroupDimension]);

	const shouldShowSimulationInfoAlert =
		sliderValue === selectedAssetGroupSecurityStatus;

	if (isUserGroupDimension) {
		return (
			<Stack spacing={2}>
				<Stack mt={15}>
					<Box sx={{ width: "100%", px: 2 }}>
						<AssetStatusSlider
							simulatedStatus={isTestModeVisible || isSimulated}
							direction={selectedDirection}
							currentStatus={selectedAssetGroupSecurityStatus}
							selectedStatus={sliderValue}
							setSelectedStatus={(status: SecurityStatus) => {
								setSelectedSecurityStatus(status);
							}}
							disabled={!userPermissions.get("UPDATE_ASSET_ZEROTRUST")}
						/>
					</Box>
				</Stack>
			</Stack>
		);
	}

	return (
		<>
			<Stack flex={1}>
				<Stack spacing={2}>
					{assetDistributionStats.isLoading || !assetDistributionStats?.data ? (
						<CircularProgress size={24} />
					) : (
						<>
							<Stack spacing={1} direction={"row"} alignItems={"center"}>
								<Typography
									variant="subtitle2"
									sx={{ fontSize: "20px", lineHeight: "24px" }}
								>
									{numeral(assetDistributionStats?.data?.total ?? 0).format(
										"0,0"
									)}
								</Typography>
								<Typography
									variant="caption"
									sx={{
										fontSize: "10px",
										lineHeight: "12px",
										color: theme.palette.text.secondary,
									}}
								>
									{pluralize(
										window.getCTTranslatedText("ASSETS"),
										assetDistributionStats?.data?.total
									)}
								</Typography>
							</Stack>

							<AssetStatusDistributionControls
								onInitStatus={status => {
									setIsSimulated(status?.includes("simulate"));
								}}
								data={assetDistributionStats.data}
								onChangeSecurityStatus={setSelectedSecurityStatus}
								onChangeSelectedAssetGroupSecurityStatus={
									setSelectedAssetGroupSecurityStatus
								}
								selectedAssetGroupSecurityStatus={
									selectedAssetGroupSecurityStatus
								}
								selectedSecurityStatus={selectedSecurityStatus}
							/>
						</>
					)}
				</Stack>

				<Stack sx={{ mt: 15 }}>
					<Box sx={{ width: "100%", px: 2 }}>
						<AssetStatusSlider
							simulatedStatus={isTestModeVisible || isSimulated}
							direction={selectedDirection}
							currentStatus={selectedAssetGroupSecurityStatus}
							selectedStatus={sliderValue}
							setSelectedStatus={(status: SecurityStatus) => {
								setSelectedSecurityStatus(status);
								setIsTestModeVisible(status !== SecurityStatus.Unsecure);
							}}
							disabled={!userPermissions.get("UPDATE_ASSET_ZEROTRUST")}
						/>
					</Box>
				</Stack>
				{shouldShowSimulationInfoAlert && (
					<Alert severity="info">
						{window.getCTTranslatedText("simulateCandidatePolicyHelperText")}
					</Alert>
				)}

				<Annotation setComment={setComment} comment={comment} />
			</Stack>

			{userPermissions.has("UPDATE_ASSET_ZEROTRUST") && (
				<Stack
					direction="row"
					sx={{ width: "100%" }}
					justifyContent={"space-between"}
				>
					<AssetStatusAction
						direction={selectedDirection}
						currentStatus={selectedAssetGroupSecurityStatus}
						selectedStatus={sliderValue}
						disabled={!userPermissions.get("UPDATE_ASSET_ZEROTRUST")}
						statusChangeCallback={onClose}
						criteria={`${filterCriteria}`}
						mode={ActionBarModes.VISUALIZER}
						isTestMode={isTestModeVisible}
						onChangeTestMode={setIsTestModeVisible}
						comment={comment}
						setComment={setComment}
					/>
				</Stack>
			)}
		</>
	);
}

import { Diagnostics } from "pages/agents/types";
import { Appliance } from "pages/appliances/types";
import { useEffect } from "react";
import { useDiagnosticAPI } from "./hooks";

export interface DiagnosticFetcherProps extends Pick<Appliance, "agentId"> {
	setOptionsData: React.Dispatch<React.SetStateAction<Diagnostics | undefined>>;
	setAreOptionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DiagnosticFetcher = ({
	agentId,
	setOptionsData,
	setAreOptionsLoading,
}: DiagnosticFetcherProps) => {
	const { data, isLoading } = useDiagnosticAPI({ agentId });

	useEffect(() => {
		setAreOptionsLoading(isLoading);
	}, [isLoading, setAreOptionsLoading]);

	useEffect(() => {
		setOptionsData(data);
	}, [data, setOptionsData]);

	return null;
};

import { ExpandCircleDownOutlined } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { CTDrawer } from "common/molecules/drawer";
import { PortProcessTabs } from "pages/ports/components/port-process-drawer.tsx/types";
import { Port } from "pages/ports/types";
import { useState } from "react";
import { PortProcessDataGrid } from "./portProcessDataGrid";
import { DataGridButton } from "common/atoms/data-grid-button";

export interface PortProcessProps {
	port: Port;
}

export const PortProcessDrawer = ({ port }: PortProcessProps) => {
	const [showPortDetails, setShowPortDetails] = useState(false);
	const [selectedTab, setSelectedTab] = useState(PortProcessTabs.Names);
	const handleShowPortDetails = () => {
		setShowPortDetails(false);
	};

	const moreProcessNames = false && port.listenProcessNames.length - 1;
	if (!port?.listenProcessPaths?.length) {
		return null;
	}

	return (
		<>
			<Stack direction="row" spacing={0.5}>
				{moreProcessNames && (
					<Tooltip
						title={window.getCTTranslatedText("showRemainingPortProcesses")}
					>
						<DataGridButton
							color="info"
							onClick={() => {
								setSelectedTab(PortProcessTabs.Names);
								setShowPortDetails(true);
							}}
						>
							<Stack
								sx={{
									opacity: 0.6,
								}}
							>
								+{moreProcessNames}
							</Stack>
						</DataGridButton>
					</Tooltip>
				)}
				<Tooltip title={window.getCTTranslatedText("showPortProcessDetails")}>
					<IconButton
						sx={{
							"& svg": {
								transform: "rotate(218deg)",
							},
						}}
						aria-label={window.getCTTranslatedText("showPortProcessDetails")}
						onClick={() => {
							setSelectedTab(PortProcessTabs.Paths);
							setShowPortDetails(true);
						}}
					>
						<ExpandCircleDownOutlined
							sx={{ opacity: 0.6 }}
							fontSize="small"
							color="info"
						/>
					</IconButton>
				</Tooltip>
			</Stack>

			<CTDrawer
				open={showPortDetails}
				onClose={handleShowPortDetails}
				title={window.getCTTranslatedText("portProcessDetails")}
			>
				<Stack sx={{ width: "100%", height: "100%" }}>
					{showPortDetails && (
						<PortProcessDataGrid data={port} defaultSelection={selectedTab} />
					)}
				</Stack>
			</CTDrawer>
		</>
	);
};

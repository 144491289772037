import { IconsProps } from "./common";

export const IstioIcon = ({
	width = "32px",
	height = "32px",
	color = "#000",
}: IconsProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill={color}
			viewBox="0 0 32 32"
		>
			<path
				fill={color}
				d="M13.515 17.097v8.3c0 .016-.01.03-.024.032L6.034 26.8c-.016.003-.031-.01-.034-.027 0-.005 0-.011.002-.017l7.457-17.85a.029.029 0 01.04-.014c.01.005.016.016.016.028v8.178zm1.257-4.784v13.12c0 .016.011.03.026.031l11.17 1.337c.015.002.03-.011.032-.029 0-.005 0-.01-.003-.016L14.83.018a.029.029 0 00-.04-.015.032.032 0 00-.017.028v12.282zm11.11 15.712l-12.356 3.973a.028.028 0 01-.02 0l-7.467-3.973a.032.032 0 01-.015-.042.03.03 0 01.028-.018h19.821c.016.002.027.019.025.036a.032.032 0 01-.017.024z"
			></path>
		</svg>
	);
};

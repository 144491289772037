import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { IconButton, Stack } from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { IPRangeInt } from "pages/networks/types";
import { useState } from "react";
import {
	NetworkFieldDisplayText,
	NetworkFieldKeys,
	RequiredNetworkFields,
} from "./NetworkForm";

const baseRowStyle = {
	pt: 0,
	pb: 1.75,
	px: 3,
};

type Props = {
	item: IPRangeInt;
	index: number;
	handleIPRangesChange: (e: TextFieldUpdate, index: number) => void;
	removeIPRange: (index: number) => void;
	isDuplicate: (val: string) => boolean;
};

const AddIPInput = ({
	item,
	index,
	handleIPRangesChange,
	removeIPRange,
	isDuplicate,
}: Props) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const showLabel = Boolean(index === 0);
	const [showDuplicateMessage, setShowDuplicateMessage] = useState(false);

	const onChange = (event: TextFieldUpdate, index: number) => {
		setShowDuplicateMessage(isDuplicate(event.target.value));
		handleIPRangesChange(event, index);
	};
	return (
		<Stack
			key={`ip-${index}`}
			direction="row"
			alignItems={"center"}
			justifyContent="flex-start"
			spacing={2}
			sx={{
				...baseRowStyle,
				width: "100%",
			}}
		>
			<CTInputField
				field={NetworkFieldKeys.IPRange}
				displayName={NetworkFieldDisplayText[NetworkFieldKeys.IPRange]}
				value={item?.value}
				handleUpdate={(event: TextFieldUpdate) => onChange(event, index)}
				required={RequiredNetworkFields[NetworkFieldKeys.IPRange]}
				showLabel={showLabel}
				error={showDuplicateMessage}
				helperText={
					showDuplicateMessage
						? window.getCTTranslatedText("DuplicateIPAlreadyExists")
						: ""
				}
			/>
			{userPermissions.has("UPDATE_NAMED_NETWORK") && (
				<IconButton
					size="small"
					aria-label="remove IP range"
					onClick={() => removeIPRange(index)}
				>
					<RemoveCircleOutlineIcon fontSize="small" />
				</IconButton>
			)}
		</Stack>
	);
};

export default AddIPInput;

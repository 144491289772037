import { RecommendationScope } from "./constant";

export interface Recommendation {
	category: string;
	name: string;
	attributes: {
		count: number;
		query: string;
		scope: RecommendationScope;
		durationInDays?: number | undefined;
	};
}

export interface RecommendationsData {
	items: Recommendation[];
}

export interface RecommendationProps {
	data: Recommendation[] | undefined;
	type?: string;
	isLoading: boolean;
}

export enum RecommendationCategory {
	ATTACK_SURFACE = "attacksurface",
	BLAST_RADIUS = "blastradius",
}

export const RecommendationCategoryText = {
	[RecommendationCategory.ATTACK_SURFACE]: "Attack Surface",
	[RecommendationCategory.BLAST_RADIUS]: "Blast Radius",
};

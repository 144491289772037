import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { SortOrder } from "modules/core/types";
import { Network } from "pages/networks/types";

export function useNetworksAssignedSuggestions({
	policyId,
}: {
	policyId?: string;
}) {
	return useQuery<any, Error>(
		["tagbasedpolicy", `tagbasedpolicies/${policyId}`],
		{ enabled: Boolean(policyId) }
	);
}

export function useAssignedNetworksTags({
	criteria,
	policyId,
}: {
	criteria?: string;
	policyId?: string;
}) {
	const [search, setSearch] = useState("");
	let { data, isLoading } = useNetworksAssignedSuggestions({ policyId });

	const onSortChange = useCallback((sort: Array<SortOrder>) => {}, []);

	const onSearchCriteriaChange = (str: string) => {
		if (!str) {
			setSearch("");
			return;
		}

		setSearch(str);
	};

	const networkList: Network[] = useMemo(() => {
		if (!data) {
			return [];
		}

		const namedNetworks = data.namednetworks ?? [];
		const lowerCaseSearch = search?.toLowerCase();

		if (lowerCaseSearch) {
			return namedNetworks.filter((network: Network) =>
				network?.namedNetworkName?.toLowerCase()?.includes(lowerCaseSearch)
			);
		}

		return namedNetworks;
	}, [data, search]);

	return {
		networkList,
		isLoading,
		onSortChange,
		onSearchCriteriaChange,
	};
}

import { Stack, Button, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { UserDepartment } from "pages/users-segmentation/components/user-departments/types";
import { Link as RouterLink } from "react-router-dom";

export const BASE_USER_DEPARTMENTS_COLUMNS: GridColDef<UserDepartment>[] = [
	{
		field: "department",
		headerName: "Departments",
		width: 140,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
		sortable: false,
	},
	{
		field: "users",
		headerName: "Users",
		width: 140,
		flex: 0.4,
		sortable: false,
	},
];

export const USER_DEPARTMENTS_COLUMNS: GridColDef<UserDepartment>[] = [
	...BASE_USER_DEPARTMENTS_COLUMNS,
	{
		field: "actions",
		headerName: "Actions",
		width: 140,
		flex: 0.4,
		sortable: false,
		renderCell: params => {
			const row = params.row;
			const department = row.department;

			return (
				<Stack
					sx={{ width: "100%" }}
					alignItems="flex-start"
					justifyItems={"center"}
				>
					<Link
						component={RouterLink}
						to={`/tag-policy/create?tab=policy&filters=${encodeURIComponent(
							`department=${department}`
						)}`}
					>
						<Button variant="outlined" color="primary">
							Create Policy
						</Button>
					</Link>
				</Stack>
			);
		},
	},
];

import { IconsProps } from "./common";

export const ServiceSVCIcon = ({
	width = "16px",
	height = "16px",
	color = "#000",
}: IconsProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 20"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M18 20H10V13H13V7H10V0L18 0V7H15V9H25V13H28L28 20H20V13H23V11H15V13H18V20ZM16 5V2H12V5H16ZM22 15V18H26V15H22ZM12 15V18H16V15L12 15Z" />
			<path d="M10 20H18V13H15V7H18V0L10 0V7H13V9H3L3 13H0L0 20H8V13H5V11H13V13H10L10 20ZM12 5V2H16V5H12ZM6 15V18H2L2 15H6ZM16 15V18H12V15L16 15Z" />
		</svg>
	);
};

import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Tooltip } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { Path } from "pages/paths/types";
import { Port } from "pages/ports/types";
import { AddToTemplateDialog } from "../AddToTemplateDialog";
import { PathRules, TemplateActionType } from "../AddToTemplateDialog/types";
import { Toolbar } from "common/atoms/toolbar";

export interface TemplateDrawerProps {
	isOpen: boolean;
	page: string;
	title?: string;
	rules?:
		| Array<Port>
		| Array<Path>
		| Array<PathRules>
		| Array<string>
		| Array<GridRowId>
		| undefined;
	showSrcDestOption?: boolean;
	showPortStatusOption?: boolean;
	onCancel: () => void;
	onConfirm: () => void;
	btnTitle?: string;
	criteria?: string;
	multiselect?: boolean;
	onSelectCallback?: Function;
	policyId?: string;
	showNonWindows?: boolean;
	hasPermission?: boolean;
	actionType: TemplateActionType;
}

export const AddToTemplateDrawer = ({
	isOpen,
	page,
	title,
	rules,
	showSrcDestOption,
	showPortStatusOption,
	onCancel,
	onConfirm,
	btnTitle = "save",
	criteria,
	multiselect = false,
	onSelectCallback,
	policyId,
	showNonWindows = false,
	hasPermission,
	actionType,
}: TemplateDrawerProps) => {
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onCancel}
			PaperProps={{
				sx: {
					p: 0,
					width: "70%",
					maxwidth: "1000px",
					minWidth: "600px",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onCancel}
						sx={{ position: "absolute", right: "16px", top: "8px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<AddToTemplateDialog
				onCancel={onCancel}
				onConfirm={onConfirm}
				page={page}
				open={isOpen}
				rules={rules}
				showSrcDestOption={showSrcDestOption}
				showPortStatusOption={showPortStatusOption}
				title={title || "Add to Template"}
				btnTitle={btnTitle}
				criteria={criteria}
				multiselect={multiselect}
				onSelectCallback={onSelectCallback}
				policyId={policyId}
				showNonWindows={showNonWindows}
				hasPermission={hasPermission}
				actionType={actionType}
			/>
		</Drawer>
	);
};

import { Box, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { UserToolBar } from "pages/users-segmentation/components/user-data-grid-toolbar";
import { User } from "pages/users-segmentation/components/users/types";
import { useCallback, useMemo, useState } from "react";
import { BASE_USERS_COLUMNS } from "./constants";

export const UserDataGrid = (props: DataGridProps<User>) => {
	const [selection, setSelection] = useState<Array<GridRowId>>([]);
	const selectedData: Array<User> | undefined = useMemo(() => {
		return props.rows?.filter((row: User) => {
			return selection.indexOf(row?.userID) !== -1;
		});
	}, [selection, props.rows]);

	const onClickClearSelection = () => {
		setSelection([]);
	};

	const renderToolbar = useCallback(
		(children?: React.ReactNode) => (
			<UserToolBar
				show={true}
				hideToolbar={onClickClearSelection}
				selection={selectedData}
				hiddenColumns={children}
			/>
		),
		[selectedData]
	);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					rowHeight={64}
					columns={BASE_USERS_COLUMNS}
					pagination={true}
					getRowId={(user: User) => `${user.userID}`}
					paginationMode="server"
					sortingMode="server"
					{...props}
					renderToolbar={renderToolbar}
				/>
			</Box>
		</Stack>
	);
};

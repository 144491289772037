import { InputBase, styled } from "@mui/material";

export const InputBaseCompact = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	width: "100%",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 1),
		// vertical padding + font size from searchIcon
		// paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		fontSize: theme.typography.body2.fontSize,
	},
	"& .MuiInputAdornment-root": {
		padding: 0,
	},
}));

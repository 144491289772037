import { FacetOptionMappingItem } from "modules/core/types";

export const getLabelFromDisplayValues = (
	key: string,
	valuesDisplayOptions: FacetOptionMappingItem
) => {
	if (!key) {
		return key;
	}

	if (valuesDisplayOptions[key].displayName) {
		return valuesDisplayOptions[key].displayName;
	} else {
		return key;
	}
};

import { Appliance } from "pages/appliances/types";
import { useMemo } from "react";
import { Agent } from "pages/agents/types";

export function useSelectedApplianceData(updatedData: Appliance[]) {
	const selectedItems = useMemo(
		() => updatedData?.map((item: Appliance) => item.agentId),
		[updatedData]
	);

	return selectedItems;
}

export function useSelectedData(
	updatedData: Agent[] | Appliance[] | undefined
) {
	const selectedItems = useMemo(
		() => (updatedData ?? []).map((item: Agent | Appliance) => item.agentId),
		[updatedData]
	);

	return selectedItems;
}

import { InputBase as MuiInputBase, alpha, styled } from "@mui/material";

export const InputBase = styled(MuiInputBase)(({ theme }) => ({
	color: "inherit",
	width: "100%",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 1),
		width: "100%",
		flex: 1,
		color: alpha(theme.palette.common.white, 1),
	},
}));

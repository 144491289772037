export const getChartDates = (timeline = 29) => {
	let currentDateTimeStamp = new Date();
	currentDateTimeStamp.setHours(0, 0, 0, 0);
	let startDateTimeStamp = new Date(
		new Date().setDate(currentDateTimeStamp.getDate() - timeline)
	);
	startDateTimeStamp.setHours(0, 0, 0, 0);
	const timeStart = startDateTimeStamp.toISOString();
	const timeEnd = currentDateTimeStamp.toISOString();

	return { timeStart, timeEnd };
};

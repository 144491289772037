import {
	Box,
	Stack,
	FormControl,
	Grid,
	FormControlLabel,
	Switch,
} from "@mui/material";
import { FormSectionHeader } from "common/atoms/form-section-header";
import {
	AuthenticationOptions,
	ProxyOptions,
	ProxyProtocolOptions,
	LoggingLevelOptions,
	AsymmetricModeOptions,
	FailOpenOptions,
} from "../types";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import {
	CTInputField,
	TextFieldUpdate,
} from "common/atoms/ct-input-field/CTInputField";

interface AdvancedProps {
	proxyEnabled: string;
	setProxyEnabled: (value: string) => void;
	proxyProtocol: string;
	setProxyProtocol: (value: string) => void;
	proxyIP: string;
	setProxyIP: (value: string) => void;
	proxyPort: string;
	setProxyPort: (value: string) => void;
	authenticationEnabled: string;
	setAuthenticationEnabled: (value: string) => void;
	authUsername: string;
	setAuthUsername: (value: string) => void;
	authPassword: string;
	setAuthPassword: (value: string) => void;
	loggingLevel: string;
	setLoggingLevel: (value: string) => void;
	discoveryMode: string;
	setDiscoveryMode: (value: string) => void;
	asymmetricModeEnabled: string;
	setAsymmetricModeEnabled: (value: string) => void;
	failOpenEnabled: string;
	setFailOpenEnabled: (value: string) => void;
	isFormDisabled: boolean;
}

export function Advanced({
	proxyEnabled,
	setProxyEnabled,
	proxyProtocol,
	setProxyProtocol,
	proxyIP,
	setProxyIP,
	proxyPort,
	setProxyPort,
	authenticationEnabled,
	setAuthenticationEnabled,
	authUsername,
	setAuthUsername,
	authPassword,
	setAuthPassword,
	loggingLevel,
	setLoggingLevel,
	discoveryMode,
	setDiscoveryMode,
	asymmetricModeEnabled,
	setAsymmetricModeEnabled,
	failOpenEnabled,
	setFailOpenEnabled,
	isFormDisabled,
}: AdvancedProps) {
	const handleProxyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setProxyEnabled(
			event.currentTarget.checked ? ProxyOptions.Yes : ProxyOptions.No
		);
	};

	const handleDebugLogChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLoggingLevel(
			event.currentTarget.checked
				? LoggingLevelOptions.Debug
				: LoggingLevelOptions.Info
		);
	};

	const handleAuthenticationChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setAuthenticationEnabled(
			event.currentTarget.checked
				? AuthenticationOptions.Yes
				: AuthenticationOptions.No
		);
	};

	const handleAsymmetricModeChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setAsymmetricModeEnabled(
			event.currentTarget.checked
				? AsymmetricModeOptions.Yes
				: AsymmetricModeOptions.No
		);
	};

	const handleFailOpenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFailOpenEnabled(
			event.currentTarget.checked ? FailOpenOptions.Yes : FailOpenOptions.No
		);
	};

	const renderDebugLog = () => {
		return (
			<Stack>
				<FormControl>
					<Stack spacing={2}>
						<Stack justifyContent="center" height={52} ml={1}>
							<FormControlLabel
								control={
									<Switch
										checked={loggingLevel === LoggingLevelOptions.Debug}
										onChange={handleDebugLogChange}
									/>
								}
								label={window.getCTTranslatedText("debugLog")}
							/>
						</Stack>
					</Stack>
				</FormControl>
			</Stack>
		);
	};

	const renderAsymmetricMode = () => {
		return (
			<Stack>
				<FormControl>
					<Stack spacing={2}>
						<Stack justifyContent="center" height={52} ml={1}>
							<FormControlLabel
								control={
									<Switch
										checked={
											asymmetricModeEnabled === AsymmetricModeOptions.Yes
										}
										onChange={handleAsymmetricModeChange}
									/>
								}
								label={window.getCTTranslatedText("asymmetricMode")}
							/>
						</Stack>
					</Stack>
				</FormControl>
			</Stack>
		);
	};

	const renderFailOpen = () => {
		return (
			<Stack>
				<FormControl>
					<Stack spacing={2}>
						<Stack justifyContent="center" height={52} ml={1}>
							<FormControlLabel
								control={
									<Switch
										checked={failOpenEnabled === FailOpenOptions.Yes}
										onChange={handleFailOpenChange}
									/>
								}
								label={window.getCTTranslatedText("failOpen")}
							/>
						</Stack>
					</Stack>
				</FormControl>
			</Stack>
		);
	};

	const renderAuthenticationSettings = () => {
		return (
			<Grid
				container
				columnGap={4}
				rowGap={2}
				sx={{ pl: 0 }}
				display="grid"
				gridTemplateColumns="1fr 1fr"
			>
				<Stack>
					<CTInputField
						field="authUsername"
						displayName={window.getCTTranslatedText("Username")}
						value={authUsername}
						handleUpdate={(event: TextFieldUpdate) =>
							setAuthUsername(event?.value)
						}
						disabled={isFormDisabled}
					/>
				</Stack>
				<Stack>
					<CTInputField
						field="authPassword"
						displayName={window.getCTTranslatedText("Password")}
						value={authPassword}
						handleUpdate={(event: TextFieldUpdate) =>
							setAuthPassword(event?.value)
						}
						type="password"
						disabled={isFormDisabled}
					/>
				</Stack>
			</Grid>
		);
	};

	const renderProxySettings = () => {
		return (
			<Stack
				sx={{
					background: theme =>
						theme.palette.mode === "dark"
							? theme.palette.background.paper
							: theme.palette.custom.lightGreyBg,
				}}
				px={3}
				py={1}
			>
				<Grid
					container
					columnGap={4}
					rowGap={2}
					sx={{ pl: 0 }}
					display="grid"
					gridTemplateColumns="1fr 1fr"
				>
					<Stack>
						<CTSelectDropDown
							field={"proxyProtocol"}
							displayName={window.getCTTranslatedText("Protocol")}
							selectedValue={proxyProtocol}
							handleUpdate={(event: TextFieldUpdate) =>
								setProxyProtocol(event.value)
							}
							data={ProxyProtocolOptions}
							textTransform="none"
							showLabel={true}
						/>
					</Stack>
					<Stack>
						<CTInputField
							field="proxyIP"
							displayName={window.getCTTranslatedText("proxyIPDomainName")}
							value={proxyIP}
							handleUpdate={(event: TextFieldUpdate) =>
								setProxyIP(event?.value)
							}
							disabled={isFormDisabled}
						/>
					</Stack>
					<Stack>
						<CTInputField
							field="proxyPort"
							displayName={window.getCTTranslatedText("Port")}
							value={proxyPort}
							handleUpdate={(event: TextFieldUpdate) =>
								setProxyPort(event?.value)
							}
							disabled={isFormDisabled}
						/>
					</Stack>
				</Grid>
				<Stack>
					<FormControl>
						<Stack spacing={2}>
							<Stack justifyContent="center" height={52} ml={1}>
								<FormControlLabel
									control={
										<Switch
											checked={
												authenticationEnabled === AuthenticationOptions.Yes
											}
											onChange={handleAuthenticationChange}
										/>
									}
									label={window.getCTTranslatedText("Use Authentication")}
								/>
							</Stack>
							{authenticationEnabled === AuthenticationOptions.Yes
								? renderAuthenticationSettings()
								: null}
						</Stack>
					</FormControl>
				</Stack>
			</Stack>
		);
	};

	return (
		<Box sx={{ minWidth: "100%", my: 8 }}>
			<FormSectionHeader header={window.getCTTranslatedText("Advanced")} />
			<Stack>
				<FormControl>
					<Stack spacing={2}>
						<Stack justifyContent="center" height={52} ml={1}>
							<FormControlLabel
								control={
									<Switch
										checked={proxyEnabled === ProxyOptions.Yes}
										onChange={handleProxyChange}
									/>
								}
								label={window.getCTTranslatedText("Use Proxy")}
							/>
						</Stack>
						{proxyEnabled === ProxyOptions.Yes ? renderProxySettings() : null}
					</Stack>
				</FormControl>
			</Stack>
			{renderDebugLog()}
			{renderAsymmetricMode()}
			{renderFailOpen()}
		</Box>
	);
}

import { useTemplateAPI } from "pages/templates/components/template-detail/hooks";
import React, { useEffect } from "react";

interface MemoizedTemplateDataFetcherProps {
	templateId: string;
	setTemplateRefetchFunctions: (index: number, refetch: Function) => void;
	index: number;
}

const TemplateDataFetcher = ({
	templateId,
	setTemplateRefetchFunctions,
	index,
}: MemoizedTemplateDataFetcherProps) => {
	const response = useTemplateAPI({ templateId, enabled: false });

	useEffect(() => {
		if (response.refetch) {
			setTemplateRefetchFunctions(index, response.refetch);
		}
	}, [response, setTemplateRefetchFunctions, index]);

	return null;
};

export const MemoizedTemplateDataFetcher = React.memo(TemplateDataFetcher);

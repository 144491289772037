export const isValidMAC = (macAddress?: string): boolean => {
	if (!macAddress) {
		return false;
	}
	// Define the regular expression for a MAC address with ':' or '-' as separators
	const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;

	// Check if the input matches the regular expression
	return macRegex.test(macAddress);
};

export const isValidIPMAC = (ipMAC?: string): boolean => {
	if (!ipMAC) {
		return false;
	}
	// Define the regular expression for a MAC address with ':' or '-' as separators
	const macRegex =
		/([0-9A-Fa-f]{2}[:-]){5}[0-9A-Fa-f]{2};\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/;
	// Check if the input matches the regular expression
	return macRegex.test(ipMAC);
};

export const isMAC = (macAddress?: string): boolean => {
	if (!macAddress) {
		return false;
	}
	const parts = macAddress.split(/[:-]/);
	// Check if there are exactly 6 parts
	if (parts.length !== 6) {
		return false;
	}
	return true;
};

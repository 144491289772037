import { Asset, AssetStatus } from "pages/assets/types";
import { Direction, SecurityStatus } from "../../constants";
import { StoreApi, UseBoundStore } from "zustand";
import { CoreStore } from "modules/core/types";

export enum ActionBarModes {
	DEFAULT = "default",
	VISUALIZER = "visualizer",
}

export interface AssetStatusActionProps {
	asset?: Asset;
	direction: Direction;
	currentStatus?: SecurityStatus;
	selectedStatus: SecurityStatus;
	disabled: boolean;
	statusChangeCallback: Function;
	criteria?: string | null | undefined;
	mode?: ActionBarModes;
	comment?: string;
	setComment?: (value: string | undefined) => void;
	minValue?: SecurityStatus;
	setIsCommentDrawerOpen?: (value: boolean) => void;
	isCommentDrawerOpen?: boolean;
	useAssetStore?: UseBoundStore<StoreApi<CoreStore>>;
	isPreviewMode?: boolean;
	isCommentsVisible?: boolean;
	isTestMode?: boolean;
	hasPermission?: boolean;
	onChangeTestMode?: (value: boolean) => void;
}

export interface StatusChangeProps {
	direction: Direction;
	status: AssetStatus;
}

export interface DeployStatusChangeProps extends StatusChangeProps {
	force?: boolean;
}

export interface StatusChangeReqBodyProps extends StatusChangeProps {
	simulationMode?: boolean;
}

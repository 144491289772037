import { CheckOutlined } from "@mui/icons-material";
import Button, { ButtonProps } from "@mui/material/Button";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function CopyInviteButton({
	code,
	buttonProps,
}: {
	code: string;
	buttonProps?: ButtonProps;
}) {
	const [copied, setCopied] = useState(false);
	return (
		<CopyToClipboard
			onCopy={() => {
				setCopied(true);
				setTimeout(() => {
					setCopied(false);
				}, 1000);
			}}
			text={code || ""}
		>
			<Button
				startIcon={copied ? <CheckOutlined /> : null}
				color="info"
				variant="contained"
				{...buttonProps}
			>
				{copied
					? window.getCTTranslatedText("Copied code")
					: window.getCTTranslatedText("Copy invite code")}
			</Button>
		</CopyToClipboard>
	);
}

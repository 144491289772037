import { Menu, MenuItem } from "@mui/material";

const TRANSLATE_LANGUAGE_MENU_ID = "primary-search-translate-language-menu";

interface TranslateLanguageMenuProps {
	anchorElLang: HTMLElement | null;
	isLangMenuOpen: boolean;
	setAnchorElLang: Function;
	handleMenuClose: () => void;
}

interface LanguageItem {
	langCode: "en" | "ja";
	label: string;
	isSelected: (selectedLang: string) => boolean;
}

const MENU_ITEMS: LanguageItem[] = [
	{
		langCode: "en",
		label: "English",
		isSelected: selectedLang => !selectedLang || selectedLang === "en",
	},
	{
		langCode: "ja",
		label: "日本語",
		isSelected: selectedLang => !selectedLang || selectedLang.includes("ja"),
	},
];

export const TranslateLanguageMenu = ({
	anchorElLang,
	isLangMenuOpen,
	setAnchorElLang,
	handleMenuClose,
}: TranslateLanguageMenuProps) => {
	const handleLangMenuClose = () => {
		setAnchorElLang(null);
		handleMenuClose();
	};

	const handlerLangClick = (lang: string) => {
		window.changeLanguage(lang);
		handleLangMenuClose();
	};

	const selectedLang = window.CTLangCode;

	return (
		<Menu
			anchorEl={anchorElLang}
			keepMounted
			id={TRANSLATE_LANGUAGE_MENU_ID}
			open={isLangMenuOpen}
			onClose={handleLangMenuClose}
			PaperProps={{
				sx: { width: "240px" },
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			anchorOrigin={{
				vertical: -6,
				horizontal: -4,
			}}
		>
			{MENU_ITEMS.map(({ langCode, label, isSelected }) => (
				<MenuItem
					key={langCode}
					selected={isSelected(selectedLang)}
					onClick={() => handlerLangClick(langCode)}
				>
					{label}
				</MenuItem>
			))}
		</Menu>
	);
};

import { useMutation } from "@tanstack/react-query";
import { Template, TemplateFormProps } from "pages/templates/types";

export const useAddRulesAPI = (templateId: string | undefined) => {
	const path = `templates/${templateId}/append`;
	return useMutation<Template, Error, TemplateFormProps>([
		"policy",
		path,
		"put",
	]);
};

import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
	Box,
	Button,
	IconButton,
	ListItemIcon,
	Tooltip,
	useTheme,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MenuOption } from "common/molecules/more-options-menu/types";
import * as React from "react";
import { useMemo } from "react";
import { MenuItemWithDropDown } from "./MenuItemWithDropDown";

interface MoreOptionsMenuProps {
	menuOptions: Array<MenuOption>;
	disabled?: boolean;
	color?: string;
	size?: "small" | "medium" | "large";
	toolTipPlacement?: any;
	triggerButtonText?: string;
}

export function MoreOptionsMenu({
	menuOptions,
	disabled = false,
	color = "inherit",
	size = "small",
	toolTipPlacement = "bottom",
	triggerButtonText,
}: MoreOptionsMenuProps) {
	const id = useMemo(() => {
		return Date.now() + "";
	}, []);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event?.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const theme = useTheme();

	return (
		<Box>
			{triggerButtonText ? (
				<Button
					size={size}
					aria-label="more options"
					id={`${id}-positioned-button`}
					aria-controls={open ? `${id}-positioned-menu` : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
					disabled={disabled}
					variant="outlined"
					endIcon={<ArrowDropDownIcon fontSize={size} />}
				>
					{triggerButtonText}
				</Button>
			) : (
				<IconButton
					size={size}
					aria-label="more options"
					id={`${id}-positioned-button`}
					aria-controls={open ? `${id}-positioned-menu` : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
					disabled={disabled}
				>
					<Tooltip title={window.getCTTranslatedText("More options")}>
						<MoreVertOutlinedIcon fontSize={size} sx={{ color: color }} />
					</Tooltip>
				</IconButton>
			)}
			<Menu
				id={`${id}-positioned-menu`}
				aria-labelledby={`${id}-positioned-button`}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					sx: {
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1.5,
						"& .MuiAvatar-root": {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						"&:before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: 0,
							right: 10,
							width: 10,
							height: 10,
							background:
								theme.palette.mode === "dark"
									? `linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), ${theme.palette.background.paper}`
									: theme.palette.background.paper,
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				{menuOptions.map((option, index) => {
					return (
						<Tooltip
							title={
								option?.disabled && option?.tooltipMessage
									? option?.tooltipMessage
									: ""
							}
							placement={toolTipPlacement}
							key={`${id}-positioned-menu-item-${index}`}
						>
							<span>
								{option?.type === "subMenu" ? (
									<MenuItemWithDropDown
										label={option.label}
										disabled={option?.disabled}
										handleOuterMenuClose={handleClose}
										menuOptions={option.subMenuOptions}
									/>
								) : (
									<MenuItem
										onClick={() => {
											handleClose();
											option.handler();
										}}
										disabled={option?.disabled}
									>
										{option?.icon ? (
											<ListItemIcon>{option.icon}</ListItemIcon>
										) : null}
										{window.getCTTranslatedText(option?.label)}
									</MenuItem>
								)}
							</span>
						</Tooltip>
					);
				})}
			</Menu>
		</Box>
	);
}

import { Field } from "modules/form-fields/type";

export const isFieldError = (value: string, field?: Field): boolean => {
	if (!field || !value) {
		return false;
	}
	if (field.minLength !== undefined && value.length < Number(field.minLength)) {
		return true;
	}

	if (field.maxLength !== undefined && value.length > Number(field.maxLength)) {
		return true;
	}

	if (field.pattern) {
		const regex = new RegExp(field.pattern);
		if (!regex.test(value)) {
			return true;
		}
	}
	return false;
};

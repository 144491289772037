export function reduceMaps<T>(maps: Array<Map<T, number>>) {
	const reducedMap = new Map();

	for (const map of maps) {
		for (const [key, value] of map) {
			if (reducedMap.has(key)) {
				reducedMap.set(key, reducedMap.get(key) + value);
			} else {
				reducedMap.set(key, value);
			}
		}
	}

	return reducedMap;
}

import { Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { Scope } from "modules/scope-metadata/types";
import { AUDIT_LOGS_DATA_GRID_ID } from "pages/monitoring/components/audit-data-grid/AuditLogDataGrid";
import { FACET_ORDER } from "pages/monitoring/constants";
import { useAuditLogStore } from "pages/monitoring/store";
import { AuditLog } from "pages/monitoring/types";
import { formatCoreTagsAsset } from "pages/paths/components/path-data-grid/helpers/columnHelpers";
import { useAuditLogsAPI } from "../alerts-action/hooks";
import { AuditLogDataGrid } from "../audit-data-grid";

interface CreationTimeFilterType {
	startTime: string;
	endTime: string;
}

interface AuditLogTabProps {
	creationFilter: CreationTimeFilterType;
}

export function AuditLogTab({ creationFilter }: AuditLogTabProps) {
	const theme = useTheme();

	const coreResponse = useCore<AuditLog>({
		useStore: useAuditLogStore,
		facetGroupInfo: FACET_ORDER,
		scope: Scope.AuditLog,
		dataMapper: log => {
			if (log?.asset) {
				log.asset.coreTags = formatCoreTagsAsset(log.asset);
			}
			return log;
		},
		useApi: useAuditLogsAPI,
		pageSize: 100,
		defaultSortOrder: [{ field: "creationtime_ts", order: "desc" }],
		additionalCriteria:
			creationFilter.startTime && creationFilter.endTime
				? `creationtime_ts > '${creationFilter.startTime}' AND creationtime_ts < '${creationFilter.endTime}'`
				: "",
		isNewCsvAPIVersion: true,
	});

	const rowCount =
		coreResponse.rowCount > 0 &&
		coreResponse.rowCount === coreResponse.maxRowCount
			? coreResponse.rowCount - 1
			: coreResponse.rowCount;

	return (
		<Stack direction={"column"} sx={{ height: "100%", width: "100%" }}>
			<Stack direction={"row"}>
				<Stack sx={{ flex: 1, py: 2 }}>
					<FacetOpenCloseController
						facetsOpen={coreResponse.facetsOpen}
						setFacetsOpen={coreResponse.setFacetsOpen}
						timeFilterConfig={{
							shouldShowTimeFilter: true,
						}}
					>
						<FacetControllerGroup
							config={coreResponse?.facetConfig}
							value={coreResponse.facetState}
							onChange={coreResponse.updateFacet}
						/>
					</FacetOpenCloseController>
				</Stack>
			</Stack>
			<Paper
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					flex: 1,
					overflow: "hidden",
				}}
				id={AUDIT_LOGS_DATA_GRID_ID}
			>
				<AuditLogDataGrid
					initialState={{
						sorting: {
							sortModel: [{ field: "creationtime_ts", sort: "desc" }],
						},
					}}
					rows={coreResponse.rows?.map((obj, index) => ({
						...obj,
						id: obj.creationTime + index,
					}))}
					originalRowCount={coreResponse.rowCount}
					rowCount={rowCount}
					metadata={coreResponse.metadata}
					maxRowCount={coreResponse.maxRowCount}
					mutation={coreResponse.mutation}
					onPageChange={coreResponse.onPageChange}
					page={coreResponse.page}
					pageSize={coreResponse.pageSize}
					onPageSizeChange={coreResponse.onPageSizeChange}
					onSortChange={coreResponse.onSortChange}
					triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
					getExportStatus={coreResponse?.getExportStatus}
					getUrlToDownload={coreResponse?.getUrlToDownload}
					resetDownloadUrl={coreResponse?.resetDownloadUrl}
					rawData={coreResponse?.rawData}
				/>
			</Paper>
		</Stack>
	);
}

import { Box, Button, ButtonProps } from "@mui/material";

import { Asset, AssetType } from "pages/assets/types";
import { useEffect, useState } from "react";
import { DiffViewerDrawer } from "./DiffViewer";

interface DiffViewerButtonProps {
	buttonTitle: string;
	direction: string;
	asset?: Asset;
	size?: "small" | "medium" | "large";
	variant?: "contained" | "outlined" | "text";
	children?: React.ReactElement;
	inboundToState?: string;
	outboundToState?: string;
	isPreviewMode?: boolean;
	buttonProps?: ButtonProps;
	handlerCloseMenu?: VoidFunction;
	isOpen?: boolean;
	handlerDiffViewDrawerOpen?: () => void;
}

export const DiffViewerButton = ({
	buttonTitle,
	direction,
	asset,
	size = "small",
	variant = "outlined",
	children,
	inboundToState,
	outboundToState,
	isPreviewMode,
	buttonProps,
	handlerCloseMenu,
	isOpen = false,
	handlerDiffViewDrawerOpen,
}: DiffViewerButtonProps) => {
	const [isDiffViewerDrawerOpen, setIsDiffViewerDrawerOpen] = useState<boolean>(
		isOpen ?? false
	);

	useEffect(() => {
		setIsDiffViewerDrawerOpen(isOpen);
	}, [isOpen]);

	return (
		<Box>
			<Button
				variant={variant}
				onClick={() => {
					setIsDiffViewerDrawerOpen(true);
					handlerDiffViewDrawerOpen?.();
				}}
				size={size}
				disableRipple={true}
				disableTouchRipple={true}
				disableFocusRipple={true}
				{...buttonProps}
			>
				{window.getCTTranslatedText(buttonTitle)}
			</Button>
			{(isOpen || isDiffViewerDrawerOpen) && (
				<DiffViewerDrawer
					isOpen={isOpen || isDiffViewerDrawerOpen}
					onClose={() => {
						setIsDiffViewerDrawerOpen(false);
						handlerCloseMenu?.();
					}}
					assetId={asset!.assetId}
					attackSurface={direction === "inbound"}
					blastRadius={direction === "outbound"}
					inboundToState={inboundToState}
					outboundToState={outboundToState}
					isPreviewMode={isPreviewMode}
					showUserOptions={asset?.type === AssetType.Endpoint}
				>
					{children}
				</DiffViewerDrawer>
			)}
		</Box>
	);
};

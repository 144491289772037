import { Scope, ScopeMetadata } from "modules/scope-metadata/types";
import { FacetGroupInfo } from "../core/types";

export enum FacetType {
	String = "string",
	Range = "range",
}

export interface FacetOption {
	label: string;
	name: string;
	count: number;
}

export interface FacetSuggestion {
	label: string;
	name: string;
}

export interface ServerFacet {
	field: string;
	hasMore: boolean;
	values: { [key: string]: number };
	isSearchField?: boolean; //added by client
}
export interface Facet {
	label: string;
	name: string;
	qualifier: Scope;
	options: Array<FacetOption>;
	suggestions?: Array<FacetSuggestion>;
	order?: number;
	type: FacetType;
	searchable?: boolean;
	facetable?: boolean;
	advancedOperators?: boolean;
}

export type FacetGroup = Array<Facet>;

export type FacetContainer = {
	displayName: string;
	Icon: JSX.Element | null;
	facets: FacetGroup;
	isCustomIcon?: boolean;
};

export type FacetConfig = Array<FacetContainer>;

export enum Operator {
	EQUAL = "=",
	EXCLUDES = "!~",
	NOT_EQUAL = "!=",
	CONTAINS = "~",
	STARTS_WITH = "=~",
	ENDS_WITH = "~=",
	GREATER_THAN = ">",
	LESS_THAN_OR_EQUAL = "<=",
}

export type FacetOptionState = Map<
	string,
	{
		isSelected: boolean;
		operator: Operator;
	}
>;

export type FacetState = Map<string, FacetOptionState> | undefined;
export interface FacetChange {
	facetName: string;
	optionName: string;
	value: boolean;
	operator?: Operator;
}

export interface FacetCommit {
	facetName: string;
	options: FacetOptionState;
}

export type onFacetChange = (change: FacetChange) => void;
export type onFacetCommit = (commit: FacetCommit) => void;

export interface SortOrderMap {
	[key: string]: number;
}

export type UpdateFacet = (change: FacetCommit) => void;
export interface FacetStore {
	name?: string;
	facetGroupInfo: FacetGroupInfo | undefined;
	setFacetGroupInfo: (order: FacetGroupInfo) => void;
	scope: Scope | undefined;
	useQualifierScope?: boolean;
	setScope: (scope: Scope) => void;
	currentSearchCriteria: string;
	updateCurrentSearchCriteria: (criteria: string) => void;
	clear: (keepTags?: boolean, keepMetadataAndOtherInfo?: boolean) => void;
	facets: FacetState;
	setFacets: (
		facetState: FacetState | ((prev: FacetState) => FacetState)
	) => void;
	updateFacet: UpdateFacet;
	updateFacetOption: (change: FacetChange) => void;
	facetConfig: FacetConfig;
	serverfacetsConfig: any;
	setFacetConfig: (serverFacets: Array<any>) => void;
	updateServerFacetOptions: (
		serverFacet: ServerFacet,
		considerHasMore: boolean
	) => void;
	metadata: ScopeMetadata | undefined;
	updateMetadata: (metadata: ScopeMetadata | undefined) => void;
	lastInteractedFacetName: string | null;
	clearLastInteractedFacet: () => void;
	openFacets: Map<string, boolean>;
	setOpenFacets: (openFacets: Array<string>) => void;
	toggleFacetOpen: (name: string) => void;
	closeAllFacets: (forceClose?: boolean) => void;
	externalCriteria?: string;
	setExternalCriteria: (c: string | undefined) => void;
}

export enum FacetGroups {
	Tags = "Tags",
	Security = "Security",
	Assets = "Assets",
	Ports = "Ports",
	Paths = "Paths",
	Network = "Network",
	Templates = "Templates",
	Agents = "Agents",
	Connectors = "Connectors",
	Containers = "Containers",
	Gatekeepers = "Gatekeepers",
	Users = "Users",
	Groups = "Groups",
	AuditLog = "Logs",
	Logs = "Logs",
	Event = "Event",
	Alert = "Alerts",
	Alerts = "Alerts",
	AlertRule = "Alerts",
	Firewall = "Firewall",
	Others = "Properties",
	PolicyAutomation = "Policy Automation",
	Custom = "Custom",
	WorkRequest = "Background Processes",
	Devices = "Devices",
	Reports = "Reports",
	IdP = "IdP",
	HostGroups = "Host Groups",
}

import { Box, Tooltip } from "@mui/material";
import { useCommonStore } from "common/store";
import { PathColumnRenderProps } from "pages/paths/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function DestinationProcess({
	path,
	viewOnly,
	useFacetStore = useCommonStore,
}: PathColumnRenderProps) {
	const typeFacetName = "dstprocess";
	const typeOptionName = path?.destinationProcess?.toString();
	const processTitle = path?.destinationProcess ?? "-";
	if (!path.channelHash) {
		return <Box style={{ width: 70 }} />;
	}

	return (
		<Box style={{ width: "fit-content" }}>
			<Tooltip title={processTitle === "-" ? "" : processTitle}>
				<CTDatagridButton
					asText={processTitle === "-" ? true : viewOnly}
					isDisabled={viewOnly === true}
					useFacetStore={useFacetStore}
					facetName={typeFacetName}
					facetOption={typeOptionName ?? ""}
					sx={{ width: "fit-content" }}
				>
					{processTitle}
				</CTDatagridButton>
			</Tooltip>
		</Box>
	);
}

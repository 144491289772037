import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Button, IconButton, Paper, Stack, Typography } from "@mui/material";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { PathForm } from "pages/templates/components/template-form-drawer/components/path-form";
import {
	baseRowStyle,
	rulesTitleWrapper,
} from "pages/templates/components/template-form-drawer/components/template-form/constants";
import {
	PathFormInt,
	TemplateFormMode,
	defaultInboundPathObj,
	defaultOutboundPathObj,
} from "pages/templates/components/template-form-drawer/components/template-form/types";
import { useCallback } from "react";
import { InboundPathsProps } from "./type";

export const AddInboundOrOutboundPaths = ({
	mode,
	pathList,
	setPathList,
	formData,
	direction,
}: InboundPathsProps) => {
	const addPath = () => {
		const newPathList = [...pathList];
		if (direction === Direction.Inbound) {
			newPathList.push({ ...defaultInboundPathObj });
		} else {
			newPathList.push({ ...defaultOutboundPathObj });
		}
		setPathList(newPathList);
	};

	const removePath = (index: number) => {
		if (index || index === 0) {
			const newPathList = [...pathList];
			newPathList.splice(index, 1);
			setPathList(newPathList);
		}
	};

	const updatePathData = useCallback(
		(path: PathFormInt, index: number) => {
			if (index || index === 0) {
				setPathList(prev => {
					let newPathList = [...prev];
					newPathList[index] = { ...path };
					return newPathList;
				});
			}
		},
		[setPathList]
	);

	return (
		<Paper sx={{ minWidth: "100%" }}>
			<Stack alignItems="flex-start" justifyContent="flex-start" spacing={0}>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent={"space-between"}
					sx={{
						...rulesTitleWrapper,
						width: "100%",
						background: theme =>
							theme.palette.mode === "dark"
								? theme.palette.background.paper
								: theme.palette.custom.lightGreyBg,
					}}
				>
					<Stack alignItems="center">
						<Typography variant="body1">
							{window.getCTTranslatedText(
								direction === Direction.Inbound
									? "Inbound Paths"
									: "Outbound Paths"
							)}
						</Typography>
					</Stack>
					<Stack alignItems="center">
						<Button
							variant="text"
							startIcon={<AddCircleOutlineIcon />}
							color="primary"
							onClick={() => addPath()}
						>
							{window.getCTTranslatedText(
								mode === TemplateFormMode.ADD ? "Add" : "Assign"
							)}{" "}
							{window.getCTTranslatedText("Paths")}
						</Button>
					</Stack>
				</Stack>

				<Stack sx={{ width: "100%", px: 3 }}>
					<Stack>
						{pathList &&
							pathList.length > 0 &&
							pathList.map((item, index, values) => {
								item = item as PathFormInt;
								const showLabel = Boolean(index === 0);
								return (
									<Stack
										key={`path-${index}`}
										direction="row"
										alignItems="center"
										sx={{
											...baseRowStyle,
											mt: index === 0 ? 3 : 0,
											mb: index === values?.length - 1 ? 2 : 0,
										}}
									>
										<PathForm
											path={item}
											updatePathData={(path: PathFormInt) =>
												updatePathData(path, index)
											}
											showLabel={showLabel}
											templateType={formData.templateType}
										/>
										<Stack sx={{ pl: 5 }} alignItems="center">
											<IconButton
												sx={{ my: 2 }}
												size="medium"
												aria-label="remove path"
												onClick={() => removePath(index)}
											>
												<RemoveCircleOutlineIcon fontSize="small" />
											</IconButton>
										</Stack>
									</Stack>
								);
							})}
					</Stack>
				</Stack>
			</Stack>
		</Paper>
	);
};

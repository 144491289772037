export const getFacetOptionDisplayText = (option: string) => {
	const isExcluded = option.startsWith("!_") && option.endsWith("_!");

	if (!isExcluded) {
		return option;
	}

	const optionLabel = option.slice(2, -2);

	if (optionLabel === "null") {
		return "No Value (Excluded)";
	}

	return option.slice(2, -2) + " (Excluded)";
};

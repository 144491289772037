import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Stack, Tooltip } from "@mui/material";
import {
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GridCellCheckboxRenderer,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { AutoUpgradeStatus } from "pages/agents/components/agent-drawers/helpers/constants";
import { HaRole } from "pages/appliances/types";
import { AgentLanIp } from "../../agent-lanip";
import { AgentLocation } from "../../agent-location";
import { AgentMode } from "../../agent-mode";
import { AgentName } from "../../agent-name";
import { AgentStatus } from "../../agent-status";
import { AgentVersion } from "../../agent-version";
import { AgentWanIp } from "../../agent-wanip";
import {
	isDiagnosticsAllowed,
	isRestartEnabled,
	isUpgradeEnabled,
} from "../../appliance-data-grid/helpers/columnHelpers";
import { DeviceCount } from "../../device-count";
import { UnmanagedDeviceCount } from "../../unmanaged-device-count";
import {
	renderToolTipDrawerAutoUpgradeColumns,
	renderToolTipDrawerDiagnosticsColumns,
	renderToolTipDrawerRestartColumns,
	renderToolTipDrawerUpgradeColumns,
} from "./drawerColumnHelpers";

export const APPLIANCE_COLUMNS: GridColDef[] = [
	{
		field: "agentname",
		headerName: "gatekeeperName",
		minWidth: 180,
		flex: 1,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentName agent={params.row} />;
		},
	},
	{
		field: "agentstatus",
		headerName: "Status",
		minWidth: 140,
		flex: 0.5,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentStatus agent={params.row} />;
		},
	},
	{
		field: "currentversion",
		headerName: "Version",
		minWidth: 150,
		flex: 0.3,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentVersion agent={params.row} />;
		},
	},
	{
		field: "agentlocation",
		headerName: "Location",
		minWidth: 180,
		flex: 0.4,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentLocation agent={params.row} />;
		},
	},
	{
		field: "managedDeviceCount",
		headerName: "managedDevices",
		width: 120,
		minWidth: 100,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				params?.row?.haRole !== HaRole.Standby && (
					<DeviceCount agent={params.row} />
				)
			);
		},
	},
	{
		field: "unmanagedDeviceCount",
		headerName: "UnmanagedDevices",
		width: 120,
		minWidth: 100,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				params?.row?.haRole !== HaRole.Standby && (
					<UnmanagedDeviceCount agent={params.row} />
				)
			);
		},
	},
	{
		field: "mode",
		headerName: "DHCP Mode",
		minWidth: 120,
		flex: 0.3,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentMode agent={params.row} viewOnly={true} />;
		},
	},
	{
		field: "wanip",
		headerName: "WAN IP",
		minWidth: 140,
		flex: 0.3,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentWanIp agent={params.row} viewOnly={true} />;
		},
	},
	{
		field: "lanip",
		headerName: "LAN IP",
		minWidth: 140,
		flex: 0.3,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentLanIp agent={params.row} viewOnly={true} />;
		},
	},
];

export const APPLIANCE_UPGRADE_COLUMNS: GridColDef[] = [
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		renderCell: params => {
			params.row.isUpgradeEnabled = isUpgradeEnabled(params.row);

			return (
				<Tooltip
					PopperProps={{ disablePortal: true }}
					placement={"right"}
					title={renderToolTipDrawerUpgradeColumns(params.row)}
					followCursor={false}
				>
					{params.row.isUpgradeEnabled ? (
						<GridCellCheckboxRenderer {...params} />
					) : (
						<Stack>
							<CheckBoxOutlineBlankIcon
								sx={{ width: "20px" }}
								color="disabled"
							></CheckBoxOutlineBlankIcon>
						</Stack>
					)}
				</Tooltip>
			);
		},
		type: "singleSelect",
	},
	...APPLIANCE_COLUMNS,
];

export const APPLIANCE_RESTART_COLUMNS: GridColDef[] = [
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		renderCell: params => {
			params.row.isRestartEnabled = isRestartEnabled(params.row);

			return (
				<Tooltip
					PopperProps={{ disablePortal: true }}
					placement={"right"}
					title={renderToolTipDrawerRestartColumns(params.row)}
					followCursor={false}
				>
					{params.row.isRestartEnabled ? (
						<GridCellCheckboxRenderer {...params} />
					) : (
						<Stack>
							<CheckBoxOutlineBlankIcon
								sx={{ width: "20px" }}
								color="disabled"
							></CheckBoxOutlineBlankIcon>
						</Stack>
					)}
				</Tooltip>
			);
		},
		type: "singleSelect",
	},
	...APPLIANCE_COLUMNS,
];

export const APPLIANCE_AUTO_UPGRADE_COLUMNS = (
	action: string
): GridColDef[] => {
	return [
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			renderCell: params => {
				let selectedRows =
					action === AutoUpgradeStatus.Disable
						? params.row.autoUpgradeEnabled
						: !params.row.autoUpgradeEnabled;

				return (
					<Tooltip
						PopperProps={{ disablePortal: true }}
						placement={"right"}
						title={renderToolTipDrawerAutoUpgradeColumns(params.row, action)}
						followCursor={false}
					>
						{selectedRows ? (
							<GridCellCheckboxRenderer {...params} />
						) : (
							<Stack>
								<CheckBoxOutlineBlankIcon
									sx={{ width: "20px" }}
									color="disabled"
								></CheckBoxOutlineBlankIcon>
							</Stack>
						)}
					</Tooltip>
				);
			},
			type: "singleSelect",
		},
		...APPLIANCE_COLUMNS,
	];
};

export const APPLIANCE_DIAGNOSTICS_COLUMNS: GridColDef[] = [
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		renderCell: params => {
			return (
				<Tooltip
					PopperProps={{ disablePortal: true }}
					placement={"right"}
					title={renderToolTipDrawerDiagnosticsColumns(params.row)}
					followCursor={false}
				>
					{!params.row.isDiagnosticsRunning &&
					isDiagnosticsAllowed(params?.row) ? (
						<GridCellCheckboxRenderer {...params} />
					) : (
						<Stack>
							<CheckBoxOutlineBlankIcon
								sx={{ width: "20px" }}
								color="disabled"
							></CheckBoxOutlineBlankIcon>
						</Stack>
					)}
				</Tooltip>
			);
		},
		type: "singleSelect",
	},
	...APPLIANCE_COLUMNS,
];

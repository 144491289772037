import { LinearProgress, Stack, Typography } from "@mui/material";

type TinyLoaderProps = {
	title?: string;
};

export const Loader = ({ title }: TinyLoaderProps) => (
	<Stack
		height="100vh"
		width="100%"
		px="20vw"
		justifyContent="center"
		alignItems="center"
	>
		{title && (
			<Typography
				sx={{ position: "absolute", top: "42.5vh" }}
				variant="h5"
				color="primary"
			>
				{title}
			</Typography>
		)}
		<LinearProgress sx={{ width: "100%" }} variant="indeterminate" />
	</Stack>
);

import { useMutation } from "@tanstack/react-query";

export function useHostGroupsAPI() {
	return useMutation<any, Error, any>([
		"crowdstrike-edr",
		"edrs/crowdstrike/host-groups/search?computeTotal=true",
	]);
}

export function useHostGroupsStatusAPI() {
	return useMutation<any, Error, any>([
		"crowdstrike-edr-action",
		"edrs/crowdstrike/host-groups",
		"put",
	]);
}

export const formatTime = (date: Date) => {
	const year = date.getUTCFullYear();
	const month = String(date.getUTCMonth() + 1).padStart(2, "0");
	const day = String(date.getUTCDate()).padStart(2, "0");
	const hours = String(date.getUTCHours()).padStart(2, "0");
	const minutes = String(date.getUTCMinutes()).padStart(2, "0");
	const seconds = String(date.getUTCSeconds()).padStart(2, "0");
	const milliseconds = String(date.getUTCMilliseconds()).padStart(6, "0");

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}+00`;
};

export const formatCreationTime = (time: string) => {
	const currentTime = new Date();

	const [startHours, endHours] = time.split(" - ").map(Number);

	const endTime = new Date(currentTime);
	endTime.setHours(currentTime.getHours() - startHours);

	const startTime = new Date(currentTime);
	startTime.setHours(currentTime.getHours() - endHours);
	const startTimeString = formatTime(startTime);
	const endTimeString = formatTime(endTime);
	return { startTime: startTimeString, endTime: endTimeString };
};

import { Paper, Stack } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { PendingNamedNetworkProps } from "pages/assets/types";
import { useMemo } from "react";

interface PendingNamedNetworkDataGridProps {
	rows?: Array<PendingNamedNetworkProps> | null;
}

export const PendingNamedNetworkDataGrid = ({
	rows = [],
}: PendingNamedNetworkDataGridProps) => {
	const columns = useMemo(() => {
		const ASSET_DETAILS_PATH_COLUMNS: GridColDef[] = [
			{
				field: "namedNetworkName",
				headerName: "Named Network Name",
				sortingOrder: ["desc", "asc"],
				flex: 1,
				sortable: false,
				renderCell: (params: GridRenderCellParams<any, any, any>) => {
					return params.row.namedNetworkName;
				},
			},
		];

		return ASSET_DETAILS_PATH_COLUMNS;
	}, []);

	return (
		<Stack
			alignItems="flex-start"
			spacing={0}
			sx={{ height: "100%", width: "100%" }}
		>
			<Paper
				sx={{
					width: "100%",
					mt: 3,
					flex: 1,
					overflow: "hidden",
				}}
			>
				<DataGrid
					rowHeight={64}
					columns={columns}
					getRowId={agg => agg.namedNetworkId}
					paginationMode="client"
					sortingMode="client"
					pagination
					rows={rows ?? []}
					rowCount={rows?.length ?? 0}
				/>
			</Paper>
		</Stack>
	);
};

import { getDate } from "common/utils";
import { Agent, AgentCommands } from "pages/agents/types";
import { formatCoreTagsAsset } from "pages/paths/components/path-data-grid/helpers/columnHelpers";
import prettyBytes from "pretty-bytes";
import {
	getAgentStatus,
	getAssetType,
	isDebugLogEnabled,
	isDebugLogRunning,
	isDebugLogSupported,
	isDecommissionEnabled,
	isDecommissionSupported,
	isDiagnosticsRunning,
	isDiagnosticsSupported,
	isNorthSouthTrafficConfigurationSupported,
	isPolicyTamperingEnabled,
	isPolicyTamperingRunning,
	isPolicyTamperingSupported,
	isRestartEnabled,
	isTrafficConfigurationSupported,
	isUpgradeEnabled,
} from "./columnHelpers";

export const agentDataMapper = (
	agent: Agent,
	isAgentDevVersionEnabled?: boolean
) => {
	agent.agentCreated = getDate(agent?.agentCreated || "");
	agent.lastCheckIn = getDate(agent?.lastCheckIn || "");
	agent.cpuUtilizationStr = agent?.cpuUtilization
		? `${agent?.cpuUtilization} ${
				agent?.cpuUtilization && agent?.cpuUtilization > 0 ? "%" : ""
			}`
		: "";
	agent.memoryUtilizationStr = agent?.memoryUtilization
		? prettyBytes(agent?.memoryUtilization * 1000 * 1000) // converting it to bytes and formatting
		: "";
	agent.rowId = `${agent.agentId}-${agent.asset?.assetId}`;
	agent.agentUpgradeStatus = getAgentStatus({
		agent,
		agentCommand: AgentCommands.UPGRADE,
	});
	agent.agentRestartStatus = getAgentStatus({
		agent,
		agentCommand: AgentCommands.RESTART,
	});
	agent.agentDiagnosticsStatus = getAgentStatus({
		agent,
		agentCommand: AgentCommands.COLLECT_DIAGNOSTICS,
	});
	agent.agentDecommissionStatus = getAgentStatus({
		agent,
		agentCommand: AgentCommands.DECOMMISSION,
	});
	agent.agentDebugLogStatus = getAgentStatus({
		agent,
		agentCommand: AgentCommands.ENABLE_DEBUG_LOG,
	});
	agent.isUpgradeEnabled = isUpgradeEnabled(agent);
	agent.isRestartEnabled = isRestartEnabled(agent);
	agent.isDecommissionEnabled = isDecommissionEnabled(agent);
	agent.isDecommissionSupported = isDecommissionSupported(agent);
	agent.assetType = getAssetType(agent?.asset);
	agent.isDiagnosticsRunning = isDiagnosticsRunning(agent);
	agent.isDiagnosticsSupported = isDiagnosticsSupported(agent);
	agent.isDebugLogEnabled = isDebugLogEnabled(agent);
	agent.isPolicyTamperingEnabled = isPolicyTamperingEnabled(agent);
	agent.isDebugLogRunning = isDebugLogRunning(agent);
	agent.isPolicyTamperingRunning = isPolicyTamperingRunning(agent);
	agent.isPolicyTamperingSupported = isPolicyTamperingSupported(
		agent,
		isAgentDevVersionEnabled
	);
	agent.isDebugLogSupported = isDebugLogSupported(agent);
	agent.isTrafficConfigurationSupported =
		isTrafficConfigurationSupported(agent);
	agent.isNorthSouthTrafficConfigurationSupported =
		isNorthSouthTrafficConfigurationSupported(agent);
	if (agent?.asset) {
		agent.asset.coreTags = formatCoreTagsAsset(agent.asset);
	}
	return agent;
};

import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { CTTooltip } from "common/atoms/ct-tooltip";
import { getCustomMenuIcon } from "modules/NavigationLayout/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "routes";
import { Route, SECONDARY_TITLE_TO_PATH_MAP } from "routes/routes";
import { StyledNavListItem } from "./styles";

interface NavListItemProps {
	id: string;
	route: Route;
	isMenuExpanded: boolean;
}

export const NavListItem = ({
	id,
	route,
	isMenuExpanded,
}: NavListItemProps) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();

	const routesPaths = location?.pathname
		?.split("/")
		?.filter(route => route !== "");

	const activeSidebarTab = routesPaths?.[0]?.toLowerCase();
	const subRoute = routesPaths?.[1]?.toLowerCase() ?? undefined;

	const onListItemClick = (route: string) => {
		const pathname = ROUTES[route].pathname + (ROUTES[route].queryParams ?? "");
		const currentRoute = pathname.replace("/", "");
		if (activeSidebarTab !== currentRoute || subRoute) {
			navigate(pathname);
		}
	};

	const activeRoute = route?.pathname?.split("/")?.filter(url => url !== "")[0];

	const active =
		location.pathname === route.pathname ||
		location.pathname === route.pathname + "/" ||
		activeRoute === activeSidebarTab ||
		SECONDARY_TITLE_TO_PATH_MAP[id] === activeSidebarTab;

	const menuIcon = route.sidebar?.icon ?? null;

	const darkModeColor =
		theme.palette.mode === "dark"
			? alpha(theme.palette.common.white, 0.6)
			: alpha(theme.palette.common.black, 0.6);

	const color = active ? theme.palette.text.primary : darkModeColor;

	return (
		<CTTooltip
			title={
				isMenuExpanded ? "" : window.getCTTranslatedText(route?.title ?? "")
			}
			placement="right"
		>
			<StyledNavListItem
				disablePadding
				onClick={() => {
					onListItemClick(id);
				}}
				isActive={active}
			>
				<ListItemButton>
					<ListItemIcon
						sx={{
							pl: active ? 0 : "4px",
							minWidth: 0,
							justifyContent: "center",
							color: color,
						}}
					>
						{route?.sidebar?.isCustomIcon
							? getCustomMenuIcon(id, color)
							: menuIcon}
					</ListItemIcon>
					<ListItemText>
						{window.getCTTranslatedText(route?.title ?? "")}
					</ListItemText>
				</ListItemButton>
			</StyledNavListItem>
		</CTTooltip>
	);
};

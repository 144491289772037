import { PLATFORMS } from "../constants";

export function processPlatformDisplayName(platform: string) {
	switch (platform) {
		case PLATFORMS.RPM:
			return `Linux${" "}(${PLATFORMS.RPM})`;
		case PLATFORMS.SUSE:
			return `SUSE`;
		case PLATFORMS.DEBIAN:
			return `Linux${" "}(${PLATFORMS.DEBIAN})`;
		case PLATFORMS.WINDOWS:
			return "Windows";
		case PLATFORMS.DARWIN:
			return "Mac";
		case PLATFORMS.DOCKER:
			return "Kubernetes Containers";
		case PLATFORMS.AIX:
			return "AIX";
		case PLATFORMS.RPM_LEGACY:
			return `RHEL 6`;
		case PLATFORMS.SOLARIS:
			return `Solaris`;
		case PLATFORMS.OPENSHIFT:
			return "OpenShift Containers";
	}
}

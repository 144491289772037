import { Box, Stack, Typography, styled } from "@mui/material";
import { AgentActionStatusInfo } from "pages/agents/components/agent-data-grid/constants";
import { ApplianceColumnRenderProps } from "pages/appliances/types";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import UpdateIcon from "@mui/icons-material/Update";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { AgentStatusType } from "pages/agents/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

const AgentStatusInfo = styled(Stack)(({ theme }) => ({
	color: theme.palette.text.disabled,
	display: "flex",
	alignItems: "left",
	justifyContent: "center",
	paddingLeft: 1,
}));

const AgentUpgradeStatus = ({ agent }: ApplianceColumnRenderProps) => {
	const isUpgradeAvailable =
		agent?.agentId &&
		agent?.agentUpgradeAvailable &&
		agent?.agentUpgradeStatus === AgentActionStatusInfo.Synchronized;
	const isUptoDate =
		agent?.agentId &&
		!agent?.agentUpgradeAvailable &&
		agent?.agentUpgradeStatus === AgentActionStatusInfo.Synchronized;
	const isUpgradePending =
		agent?.agentId &&
		(agent?.agentStatus === AgentStatusType.Absent ||
			agent?.agentStatus === AgentStatusType.Unknown) &&
		agent?.agentUpgradeStatus === AgentActionStatusInfo.Pending;
	const isUpgradeInProgress =
		agent?.agentId &&
		agent?.agentStatus === AgentStatusType.Active &&
		agent?.agentUpgradeStatus === AgentActionStatusInfo.Pending;

	if (isUpgradeAvailable) {
		return (
			<AgentStatusInfo direction={"row"}>
				<UpgradeIcon
					fontSize="small"
					sx={{ color: theme => theme.palette.text.disabled }}
				/>
				<Typography variant="caption" ml={1}>
					{window.getCTTranslatedText("Upgrade available")}
				</Typography>
			</AgentStatusInfo>
		);
	} else if (isUptoDate) {
		return (
			<AgentStatusInfo direction={"row"}>
				<CheckCircleOutlineIcon fontSize="small" />
				<Typography variant="caption" ml={1}>
					{window.getCTTranslatedText("Up to date")}
				</Typography>
			</AgentStatusInfo>
		);
	} else if (isUpgradeInProgress) {
		return (
			<AgentStatusInfo direction={"row"}>
				<UpdateIcon fontSize="small" />
				<Typography variant="caption" ml={1}>
					{window.getCTTranslatedText("Upgrade in progress...")}
				</Typography>
			</AgentStatusInfo>
		);
	} else if (isUpgradePending) {
		return (
			<AgentStatusInfo direction={"row"}>
				<UpdateIcon fontSize="small" />
				<Typography variant="caption" ml={1}>
					{window.getCTTranslatedText("Upgrade pending...")}
				</Typography>
			</AgentStatusInfo>
		);
	} else return null;
};

const AgentRestartStatus = ({ agent }: ApplianceColumnRenderProps) => {
	const isAgentRestartProgress =
		agent?.agentId &&
		agent?.agentStatus === AgentStatusType.Active &&
		agent?.agentRestartStatus === AgentActionStatusInfo.Pending;

	const isAgentRestartPending =
		agent?.agentId &&
		(agent?.agentStatus === AgentStatusType.Absent ||
			agent?.agentStatus === AgentStatusType.Unknown) &&
		agent?.agentRestartStatus === AgentActionStatusInfo.Pending;

	if (isAgentRestartPending || isAgentRestartProgress) {
		return (
			<AgentStatusInfo direction="row">
				<RestartAltIcon
					fontSize="small"
					sx={{ color: theme => theme.palette.text.disabled }}
				/>
				<Typography variant="caption" ml={1}>
					{isAgentRestartProgress
						? "Restart in progress..."
						: "Restart Pending..."}
				</Typography>
			</AgentStatusInfo>
		);
	} else {
		return null;
	}
};

export function AgentVersion({ agent, viewOnly }: ApplianceColumnRenderProps) {
	if (!agent?.agentId) {
		return <Box style={{ minWidth: 120 }} />;
	}

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
			<CTDatagridButton
				asText={viewOnly === true}
				isDisabled={viewOnly === true}
				facetName={"currentversion"}
				facetOption={agent.currentVersion ?? ""}
			>
				{agent?.currentVersion || ""}
			</CTDatagridButton>
			{agent?.agentRestartStatus === AgentActionStatusInfo.Synchronized && (
				<AgentUpgradeStatus agent={agent} />
			)}
			{agent?.agentUpgradeStatus === AgentActionStatusInfo.Synchronized && (
				<AgentRestartStatus agent={agent} />
			)}
			{agent?.agentUpgradeStatus === AgentActionStatusInfo.Pending &&
				agent?.agentRestartStatus === AgentActionStatusInfo.Pending && (
					<AgentUpgradeStatus agent={agent} />
				)}
		</Stack>
	);
}

import { SecurityLevels } from "pages/assets/types";

export function getSecurityLevelText(value: number): SecurityLevels {
	if (value <= 25) {
		return SecurityLevels.Low;
	} else if (value <= 50) {
		return SecurityLevels.Medium;
	} else if (value <= 75) {
		return SecurityLevels.High;
	} else {
		return SecurityLevels.Critical;
	}
}

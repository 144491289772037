import { FacetOptionState, Operator } from "modules/facets/types";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { useSourceFacetStore, useVisxStore } from "../store";

export const useDirectionChange = () => {
	const updateFacet = useSourceFacetStore(state => state.updateFacet);
	const setSelectedDirection = useVisxStore(
		state => state.setSelectedDirection
	);
	const setIsDrawerOpen = useVisxStore(state => state.setIsDrawerOpen);
	const setSelectedNode = useVisxStore(state => state.setSelectedNode);

	const onDirectionChange = (direction: Direction) => {
		setSelectedDirection(direction);
		setIsDrawerOpen(false);
		setSelectedNode(undefined);

		let options: FacetOptionState = new Map();
		options.set(direction === Direction.Inbound ? "inbound" : "outbound", {
			isSelected: true,
			operator: Operator.EQUAL,
		});

		updateFacet({
			facetName: "direction",
			options,
		});
	};

	return {
		onDirectionChange,
	};
};

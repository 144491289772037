import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useAuditLogStore } from "pages/monitoring/store";
import { AuditLog } from "pages/monitoring/types";

export interface AuditLogToolbarProps extends Omit<ToolbarProps, "children"> {
	selection: Array<AuditLog> | undefined;
}

export function AuditLogToolBar(props: AuditLogToolbarProps) {
	const requestAPIRefresh = useAuditLogStore(store => store.requestAPIRefresh);

	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}></GridToolbar>
	);
}

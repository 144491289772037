import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import {
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { CustomDetailPanelToggle } from "modules/data-grid/components/data-grid";
import { StatusOptionsText } from "pages/paths/constants";
import { PortStatus } from "pages/ports/types";

export const PORT_STATE_ORDER_MAP = {
	[PortStatus.DeniedByTemplate]: 1,
	[PortStatus.PathRestrictedByTemplate]: 2,
	[PortStatus.AllowIntranetByTemplate]: 3,
	[PortStatus.AllowAnyByTemplate]: 4,
	[PortStatus.AllowedByProgressive]: 5,
	[PortStatus.Deny]: 6,
	[PortStatus.PathRestricted]: 7,
	[PortStatus.AllowIntranet]: 8,
	[PortStatus.AllowAny]: 9,
	[PortStatus.Unreviewed]: 10,
};

export const TEMPLATE_GROUP_TABLE_COLUMNS: GridColDef[] = [
	{
		field: "showWarning",
		headerName: "",
		width: 50,
		minWidth: 50,
		sortable: false,
		pinnable: false,
		disableColumnMenu: true,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return params?.row?.showWarning ? (
				<Tooltip title="There are conflicting matching rules">
					<span style={{ display: "flex", alignItems: "center" }}>
						<ErrorOutlineRoundedIcon fontSize="small" color="warning" />
					</span>
				</Tooltip>
			) : (
				<></>
			);
		},
	},
	{
		field: "port",
		headerName: "Port",
		width: 200,
		minWidth: 140,
		flex: 1,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			const splitPortGroup = params?.row?.port?.split(" ");
			const protocol = splitPortGroup?.[0];
			const port = splitPortGroup?.[1];
			return (
				<Tooltip title={params?.row?.templateName || ""}>
					<Stack direction={"row"}>
						<Typography
							variant="body2"
							noWrap
							sx={{ textTransform: "capitalize" }}
						>
							{protocol || ""}
						</Typography>
						<Typography variant="body2" noWrap sx={{ ml: 2 }}>
							{port || ""}
						</Typography>
					</Stack>
				</Tooltip>
			);
		},
	},
	{
		field: "status",
		headerName: "Status",
		width: 280,
		minWidth: 280,
		flex: 2.4,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Box>
					{window.getCTTranslatedText(
						StatusOptionsText[params.row.status] ?? "–"
					)}
				</Box>
			);
		},
	},
	{
		...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
		renderCell: (params: GridRenderCellParams<any, any, any>) => (
			<CustomDetailPanelToggle id={params.id} value={params.value} />
		),
	},
];

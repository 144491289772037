import { CircularProgress, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useQuery } from "@tanstack/react-query";
import { TenantSwitchButton } from "modules/AppBar/components/TenantSwitchButton";
import { getProductTenants } from "modules/AppBar/helpers";
import { TenantListResponse } from "modules/AppBar/types";

function useTenantListAPI() {
	return useQuery<TenantListResponse, Error>(["tenants", `auth/tenants`], {
		refetchOnWindowFocus: false,
	});
}

export const SwitchTenantMenu = () => {
	const tenantListAPI = useTenantListAPI();
	const tenants = getProductTenants(tenantListAPI?.data?.tenants || []);

	return (
		<Stack>
			<Typography variant="caption" color={grey[700]} sx={{ px: 3 }}>
				{window.getCTTranslatedText("Organization")}
			</Typography>
			{tenantListAPI.isLoading && <CircularProgress size={24} />}
			<Stack alignItems={"flex-start"}>
				{tenantListAPI.data &&
					[...tenants]
						?.sort((a, b) => {
							const current = tenantListAPI.data.current.tenantID;
							if (a.tenantID === current) {
								return -1;
							} else if (b.tenantID === current) {
								return 1;
							}
							return a?.name && b?.name ? a.name.localeCompare(b.name) : -1;
						})
						.map(tenant => {
							return (
								<TenantSwitchButton
									key={tenant.tenantID}
									active={
										tenant.tenantID === tenantListAPI.data.current.tenantID
									}
									tenant={tenant}
								/>
							);
						})}
			</Stack>
		</Stack>
	);
};

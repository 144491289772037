import { parse, stringify } from "devalue";
import { getCurrentTenantID } from "hooks/useComplianceController/helper";
import { FacetOptionState, Operator, UpdateFacet } from "modules/facets/types";
import { PersistStorage } from "zustand/middleware";

export function getStorageKey({
	namespace,
	keyName,
	isGlobal,
}: {
	namespace: string;
	isGlobal?: boolean;
	keyName?: string;
}) {
	let currentTenant = isGlobal ? "tenant" : (getCurrentTenantID() ?? "tenant");
	return `${namespace}::${currentTenant}::${keyName ?? "state"}`;
}

export const stateStorage: PersistStorage<any> = {
	getItem: name => {
		const str = localStorage.getItem(name);
		if (!str) return null;
		return parse(str);
	},
	setItem: (name, value) => {
		try {
			localStorage.setItem(name, stringify(value));
		} catch (error) {
			console.error(error);
		}
	},
	removeItem: name => localStorage.removeItem(name),
};

interface UpdateTimeFilterFacetOptions {
	updateFacet: UpdateFacet;
	facetName: string;
	time: string;
}

export const updateTimeFilterFacet = ({
	updateFacet,
	facetName,
	time,
}: UpdateTimeFilterFacetOptions) => {
	let options: FacetOptionState = new Map();
	options.set(time, { isSelected: true, operator: Operator.EQUAL });
	updateFacet({
		facetName,
		options,
	});
};

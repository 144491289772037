import { TagDataGrid } from "../tag-data-grid";
import { useTagValueList } from "./hooks";

export const TagValueList = () => {
	const { isLoading, tagValueList } = useTagValueList();

	return (
		<>
			<TagDataGrid rows={tagValueList} loading={isLoading} />
		</>
	);
};

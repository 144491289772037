import { Button, Stack } from "@mui/material";
import { Loader } from "common/atoms/loader";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { AgentDownloadSections } from "pages/agents/components/agent-download-page/AgentDownloadSectionProps";
import {
	filterServerUserPlatforms,
	getAgentVersions,
	getAvailableContainerPlatforms,
	getSectionsData,
} from "pages/agents/components/agent-download-page/helpers";
import { useCallback, useState } from "react";
import { AgentActionType } from "../agent-data-grid-toolbar/Toolbar";
import { useVersionAPI } from "../agent-downlaod-version";
import { SupportedEnvironments } from "../agent-drawers/SupportedEnvironments";
import { SupportedPlatforms } from "../agent-drawers/SupportedPlatforms";
import { AGENT_TYPES } from "./constants";
import { useDepKey } from "./hooks/useDepKey";
import { AgentDefaultType } from "./type";

export function AgentDownloadPage(props: AgentDefaultType) {
	const [openOsSupportedMenu, setOpenOsSupportedMenu] = useState(false);
	const { data: AgentPlatformData, isLoading } = useVersionAPI();
	const { isFeatureEnabled: isFilteringServerUserPlatformEnabled } =
		useFeatureFlagControl(FEATURES.SERVER_USER_PLATFORMS);
	const { depKeyData, depKey } = useDepKey();
	const handleButtonChange = useCallback(
		() => setOpenOsSupportedMenu(prev => !prev),
		[]
	);
	const onClose = useCallback(() => setOpenOsSupportedMenu(false), []);

	const platformAgentData = getAgentVersions(AgentPlatformData);
	const availableServerUsersPlatforms = filterServerUserPlatforms(
		platformAgentData,
		props,
		isFilteringServerUserPlatformEnabled
	);
	const availableContainerPlatforms =
		getAvailableContainerPlatforms(platformAgentData);

	const renderServerUserAgentActions = useCallback(() => {
		if (
			props?.page !== AgentActionType.UPGRADE &&
			props?.agentType !== AGENT_TYPES.CT_CONTAINER_AGENT
		) {
			return (
				<Stack sx={{ width: 300 }}>
					<Button
						color="primary"
						variant="outlined"
						onClick={handleButtonChange}
					>
						{window.getCTTranslatedText("Supported OS Versions")}
					</Button>
					<SupportedPlatforms
						isOpen={openOsSupportedMenu}
						title={window.getCTTranslatedText("Supported OS Versions")}
						onCancel={onClose}
					/>
				</Stack>
			);
		}
		if (props?.agentType === AGENT_TYPES.CT_CONTAINER_AGENT) {
			return (
				<Stack sx={{ width: 300 }}>
					<Button
						color="primary"
						variant="outlined"
						onClick={handleButtonChange}
					>
						{window.getCTTranslatedText("Supported Versions")}
					</Button>
					<SupportedEnvironments
						isOpen={openOsSupportedMenu}
						title={window.getCTTranslatedText("Supported Versions")}
						onCancel={onClose}
					/>
				</Stack>
			);
		}
	}, [
		handleButtonChange,
		onClose,
		openOsSupportedMenu,
		props?.page,
		props?.agentType,
	]);

	const sectionsData = getSectionsData({
		availableServerUsersPlatforms,
		availableContainerPlatforms,
		renderServerUserAgentActions,
		shouldShowContainerAgentSection:
			props.page !== AgentActionType.UPGRADE &&
			availableContainerPlatforms.length > 0 &&
			props?.agentType === AGENT_TYPES.CT_CONTAINER_AGENT,
		AgentPlatformData,
		page: props?.page,
		agentsGroupByPlatforms: props?.agentsGroupByPlatforms,
		setAgentsGroupByPlatforms: props?.setAgentsGroupByPlatforms,
		agentType: props?.agentType,
	});

	if (isLoading || depKeyData === undefined) {
		return <Loader />;
	}

	return (
		<Stack sx={{ alignItems: "center" }}>
			<AgentDownloadSections
				sections={sectionsData}
				latestDepKey={depKey}
				page={props?.page}
			/>
		</Stack>
	);
}

import { Button, Paper, Stack, Tab } from "@mui/material";
import { CtTabs, TabPanel } from "common/atoms/ct-tabs";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useSearchStore } from "modules/search/store";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TagList } from "./components/tag-list";
import { TagRulesList } from "./components/tag-rules-list";
import { TagValueList } from "./components/tag-value-list";

export enum TagTabs {
	Tags = 0,
	Rules = 1,
}

const TagNameColWidth = "420px";

export const Tags = () => {
	const navigate = useNavigate();
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const tabValue = searchParams.get("tab");
	const [selectedTab, setTab] = useState(TagTabs.Tags);

	useEffect(() => {
		let tabName = 0;
		switch (tabValue) {
			case "tags":
				tabName = TagTabs.Tags;
				break;
			case "rules":
				tabName = TagTabs.Rules;
				break;
		}

		setTab(tabName);
	}, [tabValue]);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
		let tabName = "";
		switch (newValue) {
			case TagTabs.Tags:
				tabName = "tags";
				break;
			case TagTabs.Rules:
				tabName = "rules";
				break;
		}
		navigate(`/tags?tab=${tabName}`);
	};

	const setSearch = useSearchStore(state => state.setSearch);

	useEffect(() => {
		setSearch("");
		return () => {
			setSearch("");
		};
	}, [setSearch]);

	useEffect(() => {
		setSearch("");
		const urlParams = new URLSearchParams(window.location.search);
		const tabParam = urlParams.get("tab");
		let initialTab = TagTabs.Tags;
		if (tabParam === "rules") {
			initialTab = TagTabs.Rules;
		}

		setTab(initialTab);
		return () => {
			setSearch("");
		};
	}, [setSearch]);

	return (
		<Stack
			direction={"column"}
			spacing={2}
			sx={{ height: "100%", width: "100%" }}
		>
			<Stack sx={{ width: "100%" }}>
				<Stack direction="row" sx={{ width: "100%" }}>
					<Stack flex={1}>
						<CtTabs value={selectedTab} onChange={handleTabChange}>
							<Tab label={window.getCTTranslatedText("tagNames")} />
							<Tab label={window.getCTTranslatedText("tagLabelRules")} />
						</CtTabs>
					</Stack>
					<Stack
						alignItems="flex-end"
						justifyItems={"flex-end"}
						sx={{ mt: 0.5 }}
					>
						<Stack
							alignItems="center"
							direction={"row"}
							justifyContent={"center"}
						>
							{selectedTab === 1 && userPermissions.has("CREATE_TAGRULE") && (
								<Link to="/tag-rules/create?tab=rules">
									<Button
										variant="contained"
										color="primary"
										onClick={() => {}}
										sx={{
											ml: 2,
										}}
									>
										{window.getCTTranslatedText("Create tag label rule")}
									</Button>
								</Link>
							)}
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<Stack
				sx={{ width: "100%", flex: 1, overflow: "hidden" }}
				alignItems="flex-start"
			>
				<TabPanel
					value={selectedTab}
					index={0}
					style={{ flex: 1, overflow: "hidden" }}
				>
					<Stack
						direction={"row"}
						alignItems="flex-start"
						spacing={3}
						sx={{
							width: "100%",
							height: "100%",
						}}
					>
						<Stack
							sx={{
								width: TagNameColWidth,
								height: "100%",
								overflow: "scroll",
							}}
						>
							<TagList />
						</Stack>

						<Stack sx={{ height: "100%", width: "100%" }}>
							<Paper sx={{ flex: 1, overflow: "hidden" }}>
								<TagValueList />
							</Paper>
						</Stack>
					</Stack>
				</TabPanel>
				<TabPanel value={selectedTab} index={1} style={{ height: "100%" }}>
					<TagRulesList />
				</TabPanel>
			</Stack>
		</Stack>
	);
};

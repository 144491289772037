import numeral from "numeral";
import { useLayoutEffect } from "react";

interface UseUpdateTotalCountOptions {
	isLoading?: boolean;
	maxRowCount?: number;
	originalRowCount?: number;
	id: string;
}

export const useUpdateTotalCount = ({
	isLoading,
	maxRowCount,
	originalRowCount,
	id,
}: UseUpdateTotalCountOptions) => {
	const updateRowCount = () => {
		if (isLoading) {
			return;
		}

		const pathsDataGridElement = document.getElementById(id);

		if (!pathsDataGridElement) {
			return;
		}

		const paginationDisplayText = pathsDataGridElement.getElementsByClassName(
			"MuiTablePagination-displayedRows"
		)[0];

		if (!paginationDisplayText) {
			requestAnimationFrame(updateRowCount);
			return;
		}

		const displayText = paginationDisplayText.textContent;

		if (!displayText) {
			return;
		}

		const maxCount = maxRowCount ?? 10001;

		if (originalRowCount && originalRowCount >= maxCount) {
			paginationDisplayText.textContent =
				displayText.split("of")[0] +
				`of ${numeral(maxCount - 1).format("0a")}+`;
		}
	};

	useLayoutEffect(() => {
		updateRowCount();
	});
};

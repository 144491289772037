import { Box, Stack } from "@mui/material";
import { useCommonStore } from "common/store";
import { DataGridStatusButton } from "modules/data-grid-status-button";
import { IconColor } from "modules/data-grid-status-button/DataGridStatusButton";
import {
	HostGroupColumnRenderProps,
	HostGroupStatusLabel,
} from "pages/crowdstrike-groups/host-groups/types";
import { HostAndUserGroupStatusState } from "pages/users-segmentation/components/user-groups/types";

export function HostGroupStatus({
	hostGroup,
	viewOnly,
}: HostGroupColumnRenderProps) {
	const facetState = useCommonStore(state => state.facets);
	const updateFacetOption = useCommonStore(state => state.updateFacetOption);
	const typeFacetName = "hostgroupstatus";
	const typeOptionName = hostGroup?.hostgroupstatus?.toString();

	const isSelected =
		!viewOnly &&
		hostGroup?.hostgroupstatus &&
		facetState?.get(typeFacetName)?.get(typeOptionName)?.isSelected;

	if (!hostGroup?.id) {
		return <Box style={{ width: 120 }} />;
	}

	const handleButtonClick = (
		facetName: string,
		optionName: string,
		isSelected: boolean | undefined
	) => {
		if (!viewOnly) {
			updateFacetOption({
				facetName,
				optionName,
				value: !isSelected,
			});
		}
	};

	return (
		<Stack
			sx={{ maxWidth: "100%" }}
			alignItems="center"
			justifyContent="center"
			direction="row"
		>
			<DataGridStatusButton
				tooltipText={HostGroupStatusLabel[hostGroup.hostgroupstatus]}
				displayText={HostGroupStatusLabel[hostGroup.hostgroupstatus]}
				viewOnly={viewOnly}
				color={getColor(hostGroup.hostgroupstatus)}
				isSelected={isSelected}
				onClick={() =>
					handleButtonClick(typeFacetName, typeOptionName, isSelected)
				}
			/>
		</Stack>
	);
}

const getColor = (status: HostAndUserGroupStatusState) => {
	switch (status) {
		case HostAndUserGroupStatusState.Active:
			return IconColor.Success;
		case HostAndUserGroupStatusState.ActivePending:
			return IconColor.Disabled;
		case HostAndUserGroupStatusState.InActivePending:
			return IconColor.Disabled;
		case HostAndUserGroupStatusState.InActive:
			return IconColor.Disabled;
	}
};

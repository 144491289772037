import { useCallback, useEffect, useRef } from "react";

export const usePolling = (
	fetchDataFunction: () => void,
	defaultPollingInterval: number
) => {
	const intervalId = useRef<NodeJS.Timeout | null>(null);
	const prevPollingInterval = useRef<number>(defaultPollingInterval);

	const stopPolling = useCallback(() => {
		if (intervalId.current) {
			clearInterval(intervalId.current);
			intervalId.current = null;
		}
	}, []);

	const startPolling = useCallback(
		(pollingInterval: number = defaultPollingInterval) => {
			stopPolling();
			prevPollingInterval.current = pollingInterval;
			fetchDataFunction();
			intervalId.current = setInterval(fetchDataFunction, pollingInterval);
		},
		[fetchDataFunction, defaultPollingInterval, stopPolling]
	);

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				startPolling(prevPollingInterval.current);
			} else {
				stopPolling();
			}
		};

		startPolling(prevPollingInterval.current);
		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			stopPolling();
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, [startPolling, stopPolling]);

	return { stopPolling, startPolling };
};

import { Template } from "pages/templates/types";
import { create } from "zustand";

interface MatchedByTemplatesStore {
	selectedTemplateId: string;
	setSelectedTemplateId: (templateId: Template["templateId"]) => void;
}

export const useMatchedByTemplatesStore = create<MatchedByTemplatesStore>(
	set => ({
		selectedTemplateId: "",
		setSelectedTemplateId: (templateId: Template["templateId"]) =>
			set({ selectedTemplateId: templateId }),
	})
);

import { getColor } from "common/constants/colors";
import { PieChartDatum as ChartDatum } from "@colortokens/ng-ui";

export function getChartColor(datum: ChartDatum) {
	return getColor(datum.name);
}

export function getLink(
	data: { name: string; label: string; count: number },
	type: string
) {
	let link = "";
	let queryParam = encodeURI(`${type}=${data.name}`);
	link = `/assets?filters=${queryParam}`;
	return link;
}

export enum DHCPModes {
	Server = "server",
	Relay = "relay",
}

export enum ConfigStatusMap {
	Syncronized = "completed",
	Failed = "failed",
	Inprogress = "inprogress",
	Pending = "pending",
	ForceUpdate = "force-update",
}

export enum DHCPOptions {
	DHCP = "dhcp",
	Mixed = "mixed",
	Static = "static",
}

export enum HAModes {
	StandAlone = "standalone",
	Primary = "primary",
	Standby = "backup",
}

export enum NetworkInterfaces {
	WAN = "WAN",
	LAN = "LAN",
	VLAN = "VLAN",
}

export enum OptInOptions {
	Yes = "Opt-in",
	No = "Opt-out",
}

export enum ProxyOptions {
	Yes = "Enable Proxy",
	No = "Disable Proxy",
}

export enum AuthenticationOptions {
	Yes = "Enable Authentication",
	No = "Disable Authentication",
}

export enum AsymmetricModeOptions {
	Yes = "Enable Asymmetric Mode",
	No = "Disable Asymmetric Mode",
}

export enum FailOpenOptions {
	Yes = "Enable Fail Open",
	No = "Disable Fail Open",
}

export enum LoggingLevelOptions {
	Info = "info",
	Debug = "debug",
	Warn = "warn",
	Error = "error",
}
export enum AuthModeKeys {
	"PASS" = "Simple/Password",
	"AH" = "Authentication Header",
}

export enum AuthModeValues {
	"Simple/Password" = "PASS",
	"Authentication Header" = "AH",
}

export enum NetworkFieldKeys {
	Name = "namedNetworkName",
	Description = "namedNetworkDescription",
	Domain = "domain",
	IPRange = "iprange",
}

export enum BooleanFlags {
	True = "true",
	False = "false",
}

export interface IPRangeInt {
	id?: string;
	value: string;
	error?: boolean;
	count?: string;
}

export interface VLAN {
	id: string;
	ip: string;
	gatewayIP?: string;
	startIP: string;
	endIP: string;
	virtualIP?: string;
	peerIP?: string;
	gatewayAddress?: string;
	mtu: string;
}

interface AgentConfig {
	type?: string;
	domain?: string;
	deploymentKey?: string;
	autoUpgrade?: string;
	hostname?: string;
	proxy?: ProxyConfig;
	loggerLevel?: string;
	asymmetricMode?: string;
	failOpen?: string;
	pathBasedDiscovery?: string;
	peerApplianceIDs?: string[];
}

export interface ProxyConfig {
	url?: string;
	username?: string;
	password?: string;
}
export interface DHCPConfig {
	enabled: BooleanFlags.True | BooleanFlags.False;
	optInSupport?: string;
	optInDevices?: Array<string>;
	mode?: DHCPModes.Server | DHCPModes.Relay;
	serverIp?: string;
	interface?: string;
	leaseTime?: string;
	leaseFile?: string;
	dnsServers?: Array<string>;
	relayIp?: string;
	routerOverrides?: string;
}

export interface NetworkInterfaceConfig {
	name?: string;
	type: NetworkInterfaces.WAN | NetworkInterfaces.LAN | NetworkInterfaces.VLAN;
	ipAddress?: string;
	dnsServers?: Array<string>;
	gatewayAddress?: string;
	dhcp?: LANDHCPConfig;
	vrrp?: WANVrrpConfig;
	vlans?: Array<VLANConfig>;
	mtu?: string;
}

export interface WANVrrpConfig {
	virtualIp?: string;
	peerIps?: Array<string>;
}

export interface LANDHCPConfig {
	gatewayIp?: string;
	rangeStart: string;
	rangeEnd: string;
	existingGatewayIp?: string;
}

export interface VLANConfig {
	id: string;
	ipAddress: string;
	vrrp?: WANVrrpConfig;
	dhcp?: {
		gatewayIp?: string;
		rangeStart: string;
		rangeEnd: string;
		existingGatewayIp?: string;
	};
	mtu?: string;
}

export interface VRRPConfig {
	enabled: BooleanFlags.True | BooleanFlags.False;
	virtualId?: string;
	routerId: string;
	advertInt?: string;
	password: string;
	srcIp?: string;
	peerIp?: string;
	authMode?: string;
}

export interface ApplianceConfig {
	agent?: AgentConfig;
	dhcp?: DHCPConfig;
	interfaces?: Array<NetworkInterfaceConfig>;
	vrrp?: VRRPConfig;
	ipConfiguration?: DHCPOptions.DHCP | DHCPOptions.Mixed | DHCPOptions.Static;
	deviceIdentifier?: 0 | 1;
}

export interface ApplianceConfigPayload {
	gatekeeperConfig: ApplianceConfig;
	peerApplianceIDs?: Array<string>;
	agentLocation?: string;
}

export interface ConfigResult {
	gatekeeperConfig?: ApplianceConfig;
	status?: string;
}

export interface ProxySettings {
	proxyEnabled?: string;
	authenticationEnabled?: string;
}

export interface PeerDetails {
	peerEnabled: boolean;
	peerApplianceIDs?: string[];
}

export const MTUOptions = ["1500", "9000"];

export const ProxyProtocolOptions = ["http", "https"];

export const AuthModeOptions = ["Simple/Password", "Authentication Header"];

export const DiscoveryModeOptions = ["ARP", "Path"];

export const DeviceIdentifierOptions = ["MAC Address", "MAC & IP Address"];

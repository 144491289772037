import { IconButton, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { PathStatus } from "pages/paths/types";
import { ReactElement, useState } from "react";
import {
	Edge,
	EdgeProps,
	ReactFlowState,
	getSmoothStepPath,
	getStraightPath,
	useEdges,
	useStore,
} from "reactflow";
import { PathStatusMap, TrafficInfo } from "../types";
import { getPathStatusBreakdown } from "../visx-utils";
import PathDistributionByStatusPopper from "./PathDistributionByStatusPopper";

const foreignObjectSize = 30;

export type GetSpecialPathParams = {
	sourceX: number;
	sourceY: number;
	targetX: number;
	targetY: number;
};

export const getSpecialPath = (
	{ sourceX, sourceY, targetX, targetY }: GetSpecialPathParams,
	offsetX: number,
	offsetY: number
) => {
	const centerX = (sourceX + targetX) / 2;
	const centerY = (sourceY + targetY) / 2;

	return `M ${sourceX} ${sourceY} Q ${centerX + offsetX} ${
		centerY + offsetY
	} ${targetX} ${targetY}`;
};

export interface EdgeDataType {
	onButtonClick: (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		edge: Edge<any>
	) => void;
	icon: ReactElement;
	statusMap: PathStatusMap;
	trafficInfo: TrafficInfo;
	interactive?: boolean;
}

const noAnimatedStyles = {};

export default function EdgeWithButton({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	style = {},
	markerEnd,
	data,
	source,
	target,
	animated,
}: EdgeProps<EdgeDataType>) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const isBiDirectionEdge = useStore((s: ReactFlowState) => {
		const edgeExists = s.edges.some(
			e =>
				e.target !== e.source &&
				((e.source === target && e.target === source) ||
					(e.target === source && e.source === target))
		);

		return edgeExists;
	});

	const offsetX = sourceX < targetX ? 25 : -25;
	const offsetY = sourceY < targetY ? 25 : -25;

	const targetXAdjustment = offsetX < 0 ? 36 : -36;
	const targetYAdjustment = offsetY < 0 ? 36 : -36;

	const bidirectionalPath = getSpecialPath(
		{
			sourceX: sourceX + targetYAdjustment,
			sourceY: sourceY,
			targetX: targetX + targetXAdjustment,
			targetY: targetY,
		},
		offsetX,
		offsetY
	);

	const [smoothPath, labelXB, labelYB] = getSmoothStepPath({
		sourceX: sourceX - 30,
		sourceY: sourceY,
		targetX: targetX,
		targetY: targetY - 34,
	});

	const edgePath = isBiDirectionEdge ? bidirectionalPath : smoothPath;

	const [straightPath, labelXS, labelYS] = getStraightPath({
		sourceX,
		sourceY,
		targetX,
		targetY,
	});

	let labelX = source === target ? labelXB : labelXS;
	let labelY = source === target ? labelYB : labelYS;

	if (isBiDirectionEdge) {
		let iconXAdjustment = foreignObjectSize / 2;
		let iconYAdjustment = foreignObjectSize / 2;

		if (offsetX > 0) {
			iconXAdjustment = -iconXAdjustment;
		}
		if (offsetY > 0) {
			iconYAdjustment = -iconYAdjustment;
		}

		labelY = labelY + (offsetY + iconYAdjustment);
		labelX = labelX + (offsetX + iconXAdjustment);
	}

	const edges = useEdges<any>();
	const theme = useTheme();

	const [showInfoButton, setShowInfoButton] = useState(false);

	const onMouseEnter = () => {
		setShowInfoButton(true);
	};

	const onMouseLeave = () => {
		setShowInfoButton(false);
	};

	const animatedStyles = animated
		? {
				strokeDasharray: 5,
				animation: `dashdraw 0.5s linear infinite`,
			}
		: noAnimatedStyles;

	const renderInfoButton = () => {
		if (!showInfoButton) {
			return null;
		}
		return (
			<foreignObject
				onMouseEnter={data?.interactive === false ? undefined : onMouseEnter}
				onMouseLeave={onMouseLeave}
				width={foreignObjectSize}
				height={foreignObjectSize}
				x={labelX - foreignObjectSize / 2}
				y={labelY - foreignObjectSize / 2}
				className="edgebutton-foreignobject"
				requiredExtensions="http://www.w3.org/1999/xhtml"
			>
				<div
					style={{
						visibility: showInfoButton ? "visible" : "hidden",
						backgroundColor:
							theme.palette.mode === "dark"
								? "inherit"
								: theme.palette.background.default,
						background:
							theme.palette.mode === "dark"
								? `linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%), ${theme.palette.background.paper}`
								: "unset",
						zIndex: 2,
						borderRadius: "50%",
						position: "relative",
					}}
				>
					<IconButton
						sx={{
							"& svg": {
								fontSize: 20,
							},
							color: anchorEl
								? theme.palette.primary.main
								: theme.palette.mode === "dark"
									? grey[300]
									: grey[800],
							"&:hover": { backgroundColor: "rgba(0,172,193, 0.15)" },
						}}
						onMouseEnter={e => {
							if (data?.interactive === false) {
								return;
							}
							setAnchorEl(e.currentTarget);
						}}
						onMouseLeave={() => {
							setAnchorEl(null);
						}}
						className="edgebutton"
						onClick={event => {
							if (data?.interactive === false) {
								return;
							}
							data?.onButtonClick(event, edges.find(edge => edge.id === id)!);
						}}
					>
						{data?.icon}
					</IconButton>
					<PathDistributionByStatusPopper
						id={id}
						anchorEl={anchorEl}
						data={data}
					></PathDistributionByStatusPopper>
				</div>
			</foreignObject>
		);
	};
	const map = data?.statusMap;
	let markerStart = `url(#markerMid)`;
	let breakdown = getPathStatusBreakdown(map);
	if (map) {
		if (breakdown.hasMixed) {
			markerStart = `url(#markerMid-Mixed)`;
		} else if (
			map.get(PathStatus.Deny) ||
			map.get(PathStatus.DeniedByTemplate)
		) {
			markerStart = `url(#markerMid-denied)`;
		} else if (
			map.get(PathStatus.Allow) ||
			map.get(PathStatus.AllowedByProgressive) ||
			map.get(PathStatus.AllowedByTemplate)
		) {
			markerStart = `url(#markerMid-allowed)`;
		} else if (
			map.get(PathStatus.AllowTestDenied) ||
			map.get(PathStatus.AllowTestDeniedViolation)
		) {
			markerStart = `url(#markerMid-allowed-by-test)`;
		} else {
			markerStart = `url(#markerMid)`;
		}
	}

	return (
		<>
			{source === target || isBiDirectionEdge ? (
				<>
					<path
						id={id}
						style={style}
						className="react-flow__edge-path"
						d={edgePath}
						markerEnd={markerEnd}
					/>

					<path
						onMouseEnter={
							data?.interactive === false ? undefined : onMouseEnter
						}
						onMouseLeave={onMouseLeave}
						id={id + "-selector"}
						className="react-flow__edge-path-selector"
						d={edgePath}
					/>
				</>
			) : (
				<>
					<line
						id={id}
						x1={sourceX}
						y1={sourceY}
						x2={targetX}
						y2={targetY}
						style={{ ...style, ...animatedStyles }}
						opacity="1"
						className="react-flow__edge-path"
						markerStart={markerStart}
					/>
					<path
						onMouseEnter={
							data?.interactive === false ? undefined : onMouseEnter
						}
						onMouseLeave={onMouseLeave}
						id={id + "-selector"}
						className="react-flow__edge-path-selector"
						d={straightPath}
					/>
				</>
			)}

			{renderInfoButton()}
		</>
	);
}

import { SecuritySliderLabel } from "modules/security-slider/SecuritySliderLabel";
import { SecurityStatus } from "pages/asset/components/asset-detail/constants";
import { AssetSliderLabelProps } from "../../types";
import { AssetStatusIcon } from "../asset-status-icon";

export const AssetSliderLabel = ({
	assetStatus,
	currentStatus,
	isSimulateStatus,
	label,
	isMinimum,
}: AssetSliderLabelProps) => {
	const isActive = assetStatus === currentStatus;

	const alignLabel = () => {
		let alignment = "center";
		if (currentStatus === SecurityStatus.Unsecure) {
			alignment = "flex-start";
		} else if (currentStatus === SecurityStatus.All) {
			alignment = "flex-end";
		}
		return alignment;
	};

	return (
		<SecuritySliderLabel
			securityStatus={assetStatus}
			currentSecurityStatus={currentStatus}
			label={label}
			isSimulateStatus={
				isSimulateStatus && currentStatus !== SecurityStatus.Unsecure
			}
			isMinimum={isMinimum}
			renderIcon={color =>
				AssetStatusIcon({ status: currentStatus, color: color })
			}
			justifyContent={alignLabel()}
			ml={currentStatus === SecurityStatus.Unsecure ? (isActive ? 10 : 5.5) : 0}
			mr={currentStatus === SecurityStatus.All ? (isActive ? 10 : 5.5) : 0}
		/>
	);
};

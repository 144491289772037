import { ChartDatum as BarStackChartDatum } from "../../../../../modules/charts/bar-stack/BarStackChart";

export const barChartDataMapper = ({
	items,
	metricId,
	valueKeys,
	statisticsId,
}: any) => {
	const timeSeries = Object.keys(items || []);

	const timeSeriesData = timeSeries.map((timestamp: string) => {
		const item = items[timestamp];
		const dateStamp = new Date(timestamp);

		let timeSeriesObj: BarStackChartDatum = {
			x: dateStamp,
			values: {},
		};

		valueKeys.forEach((valKey: string) => {
			if (metricId) {
				timeSeriesObj.values[valKey] = {
					count: item[metricId][valKey]?.statistics[statisticsId] || 0,
				};
			} else {
				timeSeriesObj.values[valKey] = {
					count: item.statistics[statisticsId] || 0,
				};
			}
		});

		return timeSeriesObj;
	});
	return timeSeriesData;
};

export const avgDataMapper = ({ items, statisticsId }: any) => {
	return (
		(statisticsId && items?.statistics && items?.statistics[statisticsId]) || 0
	);
};

export const sparkLineData = ({
	items,
	metricId,
	valueKeys,
	statisticsId,
}: any) => {
	const timeSeries = Object.keys(items || []);

	let sparkLineObj: any = {};

	valueKeys.forEach((valKey: string) => {
		if (!sparkLineObj[valKey]) {
			sparkLineObj[valKey] = [];
		}

		timeSeries.forEach((timestamp: string) => {
			const item = items[timestamp];

			if (metricId) {
				sparkLineObj[valKey].push(
					item[metricId][valKey]?.statistics[statisticsId] || 0
				);
			} else {
				sparkLineObj[valKey].push(item.statistics[statisticsId] || 0);
			}
		});
	});

	return sparkLineObj;
};

import { Link, Tooltip, Typography } from "@mui/material";
import { useMatchedByTemplatesStore } from "modules/matched-by-templates-drawer/hooks/useMatchedByTemplatesStore";
import { Template } from "pages/templates/types";

interface TemplateNameWithDrawerProps
	extends Pick<Template, "templateName" | "templateId"> {}

export function TemplateNameWithDrawer({
	templateName,
	templateId,
}: TemplateNameWithDrawerProps) {
	const { setSelectedTemplateId } = useMatchedByTemplatesStore();

	if (!templateId) {
		return <></>;
	}

	return (
		<Tooltip title="View template details">
			<Typography
				component={Link}
				typography={"body2"}
				sx={{ textTransform: "capitalize", cursor: "pointer" }}
				onClick={() => setSelectedTemplateId(templateId)}
			>
				{templateName}
			</Typography>
		</Tooltip>
	);
}

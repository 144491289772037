import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	DialogContent,
	Drawer,
	FormControlLabel,
	FormGroup,
	IconButton,
	LinearProgress,
	Popper,
	Stack,
	Switch,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { CircularCenteredLoader } from "common/atoms/loader";
import { Toolbar } from "common/atoms/toolbar";
import { useEffect, useMemo, useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-virtualized";
import { UserMenu } from "./UserMenu";
import { Endpoint } from "./constants";
export interface AssetPolicyDiffViewerProps extends PolicyDiffBody {
	assetId: string;
	children?: React.ReactElement;
}

export interface PolicyDiffBody {
	attackSurface: boolean;
	blastRadius: boolean;
}
export interface PolicyDiffZeroTrustBody {
	outboundToState?: string;
	inboundToState?: string;
	useremail?: string;
}

export interface PolicyDiffResponse {
	candidateRules: string;
	currentRules: string;
}

export function usePolicyDiffAPI({ assetId }: { assetId: string }) {
	const path = `policysimulation/${assetId}/synchronize`;
	return useMutation<PolicyDiffResponse, Error, PolicyDiffBody>([
		"asset",
		path,
	]);
}

export function usePolicyDiffZeroTrustAPI({ assetId }: { assetId: string }) {
	const path = `policysimulation/${assetId}/zerotrust`;
	return useMutation<PolicyDiffResponse, Error, PolicyDiffZeroTrustBody>([
		"asset",
		path,
	]);
}
interface DiffViewerDrawerProps extends AssetPolicyDiffViewerProps {
	isOpen: boolean;
	onClose: () => void;
	outboundToState?: string;
	inboundToState?: string;
	isPreviewMode?: boolean;
	showUserOptions: boolean;
}

export const DiffViewerDrawer = ({
	isOpen,
	onClose,
	assetId,
	attackSurface,
	blastRadius,
	children,
	outboundToState,
	inboundToState,
	isPreviewMode,
	showUserOptions,
}: DiffViewerDrawerProps) => {
	// const theme = useTheme();
	const policyDiffMutation = usePolicyDiffAPI({ assetId });
	const policyDiffZeroTrustMutation = usePolicyDiffZeroTrustAPI({ assetId });
	const policyDiffZeroTrustMutate = policyDiffZeroTrustMutation.mutate;
	const policyDiffMutate = policyDiffMutation.mutate;
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [hideAll, setHideAll] = useState<boolean>(false);

	const [computing, setComputing] = useState(true);

	const [current, setCurrent] = useState("");
	const [candidate, setCandidate] = useState("");
	const [selectedOption, setSelectedOption] = useState(Endpoint);

	let usersTemplate = null;
	if (showUserOptions) {
		usersTemplate = (
			<UserMenu
				selectedOption={selectedOption}
				assetId={assetId}
				setSelectedOption={setSelectedOption}
			/>
		);
	}

	useEffect(() => {
		if (isPreviewMode) {
			policyDiffZeroTrustMutate({
				inboundToState,
				outboundToState,
				useremail: selectedOption === Endpoint ? "" : selectedOption,
			});
		} else {
			policyDiffMutate({
				attackSurface,
				blastRadius,
			});
		}
	}, [
		attackSurface,
		blastRadius,
		inboundToState,
		outboundToState,
		isPreviewMode,
		policyDiffZeroTrustMutate,
		policyDiffMutate,
		selectedOption,
	]);

	const data = policyDiffMutation.data ?? policyDiffZeroTrustMutation?.data;

	useEffect(() => {
		setCurrent(window.atob(data?.currentRules ?? ""));
		setCandidate(window.atob(data?.candidateRules ?? ""));
	}, [data]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHideAll(event.target.checked);
	};

	const isAPILoading =
		policyDiffMutation.isLoading || policyDiffZeroTrustMutation.isLoading;
	const isLoading = isAPILoading || computing;

	const diffRenderer = useMemo(() => {
		return (
			// @ts-ignore:// custom lib
			<ReactDiffViewer
				compareMethod={DiffMethod.WORDS}
				oldValue={current}
				newValue={candidate}
				splitView={true}
				showDiffOnly={true}
				leftTitle={"Current rules"}
				rightTitle={"Candidate rules"}
				extraLinesSurroundingDiff={hideAll ? 0 : 3}
				onComputing={computing => {
					setComputing(computing);
				}}
			/>
		);
	}, [candidate, current, hideAll]);

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					padding: "0px",
					width: "80%",
					minWidth: "1000px",
					height: "100%",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			></Stack>
			<Stack
				direction="row"
				justifyContent={"space-between"}
				alignItems={"center"}
				spacing={2}
				sx={{ mt: 4, mb: 0, mx: 4 }}
			>
				<Typography variant="h5">
					<b>Policy Changes</b>
				</Typography>

				<Stack direction="row" alignItems={"center"}>
					{usersTemplate}
					<FormGroup sx={{ mr: 2 }}>
						<FormControlLabel
							sx={{
								"&.MuiFormControlLabel-root": { marginRight: 1 },
								"& .MuiFormControlLabel-label": {
									fontSize: theme => theme.typography.body2.fontSize,
									color: theme => theme.palette.text.secondary,
								},
							}}
							control={
								<Switch
									checked={hideAll}
									onChange={handleChange}
									name="config"
									size="small"
								/>
							}
							label="Hide all non-modified"
						/>
					</FormGroup>

					<Button
						variant="text"
						onMouseEnter={e => {
							setAnchorEl(e.currentTarget);
						}}
						onMouseLeave={() => {
							setAnchorEl(null);
						}}
						disableRipple={true}
					>
						legends
						<DiffLegendsPopper anchorEl={anchorEl} />
					</Button>

					<Tooltip title={"Close Drawer"}>
						<IconButton
							size="medium"
							aria-label="close drawer"
							onClick={onClose}
							sx={{ ml: 3 }}
						>
							<CloseIcon fontSize="medium" />
						</IconButton>
					</Tooltip>
				</Stack>
			</Stack>
			<DialogContent sx={{ display: "flex", flexDirection: "column" }}>
				{isLoading && <CircularCenteredLoader />}
				<Stack sx={{ flex: 1, overflowY: "auto" }}>
					<>{!isAPILoading && diffRenderer}</>
				</Stack>
				{!isLoading && (Boolean(current) || Boolean(candidate)) && (
					<Stack direction={"row"}>
						<Stack
							direction={"row"}
							width={"100%"}
							pt={1}
							justifyContent={"flex-end"}
						>
							<Button
								onClick={() =>
									handleDownload(current, `current_rules_${assetId}`)
								}
								variant="outlined"
							>
								Download Current
							</Button>
						</Stack>
						<Stack
							direction={"row"}
							width={"100%"}
							pt={1}
							justifyContent={"flex-end"}
						>
							<Button
								onClick={() =>
									handleDownload(candidate, `candidate_rules_${assetId}`)
								}
								variant="outlined"
							>
								Download Candidate
							</Button>
						</Stack>
					</Stack>
				)}
				{children && <Box>{children}</Box>}
			</DialogContent>
		</Drawer>
	);
};

const handleDownload = (str: string, name: string) => {
	const blob = new Blob([str], { type: "text/plain" });

	const url = URL.createObjectURL(blob);

	const a = document.createElement("a");
	a.href = url;
	a.download = `${name}.text`;

	a.click();

	URL.revokeObjectURL(url);
};

interface PathDistributionByStatusPopperProps {
	anchorEl: HTMLElement | null;
}

export enum DiffViewerLegends {
	ADD = "Add (+)",
	REMOVE = "Remove (-)",
	UNMODIFIED = "Unmodified",
}
export default function DiffLegendsPopper({
	anchorEl,
}: PathDistributionByStatusPopperProps) {
	const theme = useTheme();
	const TOOLTIP_STYLES = {
		background: theme.palette.common.black,
		borderRadius: "4px",
		width: "100%",
		p: 2,
	};

	const getPathColor = (status: string) => {
		switch (status) {
			case DiffViewerLegends.REMOVE:
				return "error";
			case DiffViewerLegends.ADD:
				return "success";
			case DiffViewerLegends.UNMODIFIED:
				return "inherit";
			default:
				return "info";
		}
	};

	return (
		<>
			<Popper
				id="diffLegendsPopper"
				sx={{ zIndex: "9999 !important" }}
				open={!!anchorEl}
				anchorEl={anchorEl}
			>
				<Stack sx={{ ...TOOLTIP_STYLES }} spacing={1}>
					{[
						DiffViewerLegends.ADD,
						DiffViewerLegends.REMOVE,
						DiffViewerLegends.UNMODIFIED,
					].map(status => {
						return (
							<Stack
								direction="row"
								spacing={1}
								alignItems={"center"}
								key={status}
							>
								<LinearProgress
									sx={{
										background: "none",
										width: "8px",
										height: "8px",
									}}
									variant="determinate"
									value={100}
									color={getPathColor(status)}
								/>
								<Typography
									variant={"body2"}
									sx={{
										color: theme.palette.common.white,
										width: "100%",
										textTransform: "capitalize",
									}}
								>
									{status}
								</Typography>
							</Stack>
						);
					})}
				</Stack>
			</Popper>
		</>
	);
}

import { ExpandCircleDownOutlined } from "@mui/icons-material";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { MatchedByTemplateDrawer } from "modules/matched-by-templates-drawer";
import { MatchedByTemplates, Path } from "pages/paths/types";
import { Port } from "pages/ports/types";
import { MouseEventHandler, useState } from "react";
import { MatchedByTemplateType } from "./constants";

interface MatchedByTemplateProps {
	matchedByTemplates: MatchedByTemplates[];
	showDeleteIcon: boolean;
	id?: string;
	assetId?: string;
	type: MatchedByTemplateType;
	port?: Port;
	path?: Path;
}

export function MatchedByTemplate({
	id,
	matchedByTemplates,
	showDeleteIcon,
	assetId,
	type,
	port,
	path,
}: MatchedByTemplateProps) {
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

	const handleDrawerOpen: MouseEventHandler<HTMLButtonElement> = event => {
		if (event) {
			event.stopPropagation();
		}
		setIsDrawerOpen(true);
	};

	if (!id) {
		return <Box style={{ width: 70 }} />;
	}

	const renderContent = () => {
		if (matchedByTemplates && matchedByTemplates.length > 0) {
			return (
				<Stack direction="row" alignItems="center">
					<Tooltip title={matchedByTemplates[0].templateName ?? ""}>
						<Typography
							variant="body2"
							sx={{
								maxWidth: "100%",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							{matchedByTemplates[0].templateName}
						</Typography>
					</Tooltip>

					<Tooltip title={window.getCTTranslatedText("Status Summary")}>
						<IconButton
							sx={{ "& svg": { transform: "rotate(218deg)" } }}
							aria-label="Status Summary"
							onClick={handleDrawerOpen}
						>
							<ExpandCircleDownOutlined
								sx={{ opacity: 0.6 }}
								fontSize="small"
								color="info"
							/>
						</IconButton>
					</Tooltip>
				</Stack>
			);
		}

		return (
			<Typography
				sx={{ display: "flex", alignItems: "center" }}
				variant="body2"
			>
				{showDeleteIcon ? (
					<Tooltip title={window.getCTTranslatedText("AwaitingPushToFirewall")}>
						<AutoDeleteIcon />
					</Tooltip>
				) : (
					"-"
				)}
			</Typography>
		);
	};

	return (
		<Box
			sx={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
		>
			{renderContent()}

			{isDrawerOpen && (
				<MatchedByTemplateDrawer
					isOpen={isDrawerOpen}
					onClose={() => setIsDrawerOpen(false)}
					winningTemplates={matchedByTemplates}
					id={id}
					assetId={assetId}
					type={type}
					port={port}
					path={path}
				/>
			)}
		</Box>
	);
}

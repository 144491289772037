import App from "App";
import { CTThemeProvider } from "assets/ctTheme/CTThemeProvider";
import { CTControllerProvider } from "context/useCTController";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import "./setupI18n";

type TranslateFunction = (key: string, vars?: { [key: string]: any }) => string;
declare global {
	interface Window {
		getCTTranslatedText: TranslateFunction;
		changeLanguage: (langCode: string) => void;
		CTLangCode: string;
		wordMap: { [key: string]: string };
	}
}

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<CTControllerProvider>
				<CTThemeProvider>
					<QueryParamProvider adapter={ReactRouter6Adapter}>
						<App />
					</QueryParamProvider>
				</CTThemeProvider>
			</CTControllerProvider>
		</BrowserRouter>
	</React.StrictMode>
);

import {
	AGENT_TYPES,
	PLATFORMS,
} from "pages/agents/components/agent-download-page/constants";
import { AgentVersionDetails } from "pages/agents/components/agent-download-page/type";
import {
	AgentVersion,
	AgentVersions,
} from "../../agent-downlaod-version/useVersionAPI";

export function getAgentVersions(data: AgentVersions | undefined) {
	let agentVersionMap: AgentVersionDetails = {
		rpm: { versionsInfo: [] },
		rpmLegacy: { versionsInfo: [] },
		suse: { versionsInfo: [] },
		darwin: { versionsInfo: [] },
		windows: { versionsInfo: [] },
		aix: { versionsInfo: [] },
		solaris: { versionsInfo: [] },
		debian: { versionsInfo: [] },
		linux: { versionsInfo: [] },
		powershell: { versionsInfo: [] },
		docker: { versionsInfo: [] },
		openShift: { versionsInfo: [] },
	};

	let filteredAgentData = data?.versions?.filter(
		(ele: AgentVersion) =>
			ele?.agentType === AGENT_TYPES.CT_AGENT ||
			ele?.agentType === AGENT_TYPES.INSTALL_SCRIPT ||
			ele?.agentType === AGENT_TYPES.CT_USER_AGENT ||
			ele?.agentType === AGENT_TYPES.CT_REMOTE_CONNECTOR ||
			ele?.agentType === AGENT_TYPES.CT_CONTAINER_AGENT ||
			ele?.agentType === AGENT_TYPES.CT_LEGACY_AGENT
	);

	filteredAgentData?.forEach((agentVersion: AgentVersion) => {
		if (
			agentVersion.targetPlatform === PLATFORMS.RPM &&
			agentVersion.agentType === AGENT_TYPES.CT_LEGACY_AGENT
		) {
			agentVersionMap[PLATFORMS.RPM_LEGACY]?.versionsInfo.push({
				version: agentVersion?.version,
				downloadLink: agentVersion?.downloadLink,
				fileName: agentVersion?.fileName,
				agentType: agentVersion?.agentType,
				architecture: agentVersion?.architecture,
				targetPlatform: agentVersion?.targetPlatform,
				recommended: agentVersion?.recommended,
			});
		} else if (
			agentVersion.targetPlatform === PLATFORMS.RPM &&
			agentVersion.agentType === AGENT_TYPES.CT_AGENT
		) {
			agentVersionMap[PLATFORMS.SUSE]?.versionsInfo.push({
				version: agentVersion?.version,
				downloadLink: agentVersion?.downloadLink,
				fileName: agentVersion?.fileName,
				agentType: agentVersion?.agentType,
				architecture: agentVersion?.architecture,
				targetPlatform: agentVersion?.targetPlatform,
				recommended: agentVersion?.recommended,
			});
			agentVersionMap[
				agentVersion?.targetPlatform as keyof AgentVersionDetails
			]?.versionsInfo.push({
				version: agentVersion?.version,
				downloadLink: agentVersion?.downloadLink,
				fileName: agentVersion?.fileName,
				agentType: agentVersion?.agentType,
				architecture: agentVersion?.architecture,
				targetPlatform: agentVersion?.targetPlatform,
				recommended: agentVersion?.recommended,
			});
		} else if (agentVersion?.targetPlatform === PLATFORMS.DOCKER) {
			agentVersionMap[
				agentVersion?.targetPlatform as keyof AgentVersionDetails
			]?.versionsInfo.push({
				version: agentVersion?.version,
				downloadLink: agentVersion?.downloadLink,
				fileName: agentVersion?.fileName,
				agentType: agentVersion?.agentType,
				architecture: agentVersion?.architecture,
				targetPlatform: agentVersion?.targetPlatform,
				recommended: agentVersion?.recommended,
			});
			agentVersionMap[
				PLATFORMS.OPENSHIFT as keyof AgentVersionDetails
			]?.versionsInfo.push({
				version: agentVersion?.version,
				downloadLink: agentVersion?.downloadLink,
				fileName: agentVersion?.fileName,
				agentType: agentVersion?.agentType,
				architecture: agentVersion?.architecture,
				targetPlatform: agentVersion?.targetPlatform,
				recommended: agentVersion?.recommended,
			});
		} else {
			agentVersionMap[
				agentVersion?.targetPlatform as keyof AgentVersionDetails
			]?.versionsInfo.push({
				version: agentVersion?.version,
				downloadLink: agentVersion?.downloadLink,
				fileName: agentVersion?.fileName,
				agentType: agentVersion?.agentType,
				architecture: agentVersion?.architecture,
				targetPlatform: agentVersion?.targetPlatform,
				recommended: agentVersion?.recommended,
			});
		}
	});

	return agentVersionMap;
}

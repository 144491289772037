export enum NAMED_NETWORK_ASSIGNMENT_ID {
	ASSIGN_TO_ASSETS = "Assign to Assets",
	ASSIGN_TO_SEGMENT = "Assign to Segments",
	ADD_TO_NAMED_NETWORK = "Add to Named Network",
	CREATE_NEW_NAMED_NETWORK = "Create new Named Network",
}

export const PUBLIC_PRIVATE_IP_OPTIONS = () => [
	{
		id: NAMED_NETWORK_ASSIGNMENT_ID.ADD_TO_NAMED_NETWORK,
		label: window.getCTTranslatedText(
			NAMED_NETWORK_ASSIGNMENT_ID.ADD_TO_NAMED_NETWORK
		),
		permissions: ["UPDATE_NAMED_NETWORK"],
	},
	{
		id: NAMED_NETWORK_ASSIGNMENT_ID.CREATE_NEW_NAMED_NETWORK,
		label: window.getCTTranslatedText(
			NAMED_NETWORK_ASSIGNMENT_ID.CREATE_NEW_NAMED_NETWORK
		),
		permissions: ["CREATE_NAMED_NETWORK"],
	},
];

export const NAMED_NETWORK_ASSIGNMENT_OPTIONS = () => [
	{
		id: NAMED_NETWORK_ASSIGNMENT_ID.ASSIGN_TO_ASSETS,
		label: window.getCTTranslatedText(
			NAMED_NETWORK_ASSIGNMENT_ID.ASSIGN_TO_ASSETS
		),
		permissions: ["UPDATE_ASSET", "UPDATE_NAMED_NETWORK"],
	},
	{
		id: NAMED_NETWORK_ASSIGNMENT_ID.ASSIGN_TO_SEGMENT,
		label: window.getCTTranslatedText(
			NAMED_NETWORK_ASSIGNMENT_ID.ASSIGN_TO_SEGMENT
		),
		// Used CREATE instead of UPDATE cause we auto create policy if policy does not exist
		permissions: ["UPDATE_NAMED_NETWORK", "CREATE_TAGBASEDPOLICY"],
	},
];

import { Paper, Stack, Typography } from "@mui/material";
import {
	DataGridProProps,
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GridColDef,
	GridRenderCellParams,
	GridRowId,
} from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import { DataGridButton } from "common/atoms/data-grid-button";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import {
	CustomDetailPanelToggle,
	DataGrid,
} from "modules/data-grid/components/data-grid";
import { useExportCSV } from "modules/export-csv/hooks";
import { PathAgg } from "modules/recommendation-workflows/hooks/usePathRecommendations";
import { Scope } from "modules/scope-metadata/types";
import numeral from "numeral";
import { NetworkDetail } from "pages/networks/components/network-detail/NetworkDetail";

import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useState } from "react";

export function useAggregateAPI() {
	return useMutation<any, Error, any>(["asset", "aggregate"]);
}

interface PathRecommendationProps {
	setPortSelection: (selectionModel: PathAgg[]) => void;
	data: PathAgg[];
	isLoading: boolean;
	onClickSuggestion: VoidFunction;
}

const PATH_RECOMMENDATION_COLUMNS: GridColDef<PathAgg>[] = [
	{
		field: "portProtocol",
		headerName: "Port Details",
		flex: 1,
		valueGetter: params => `${params.row?.protocol} ${params.row?.port}`,
		renderCell: params => {
			return (
				<Stack
					direction={"row"}
					alignItems="center"
					justifyContent="flex-start"
					sx={{ maxWidth: "100%" }}
				>
					{params.row?.protocol && (
						<DataGridButton
							asText={true}
							disabled={true}
							variant={"text"}
							color={"inherit"}
							onClick={() => null}
						>
							{params.row?.protocol}
						</DataGridButton>
					)}

					<DataGridButton
						asText={true}
						disabled={true}
						variant={"text"}
						color={"inherit"}
						onClick={() => null}
					>
						{params.row?.port}
					</DataGridButton>
				</Stack>
			);
		},
	},
	{
		field: "nameNetwork",
		headerName: "Named Network",
		flex: 1,
		valueGetter: params => params.row.namedNetwork?.namedNetworkName,
		renderCell: params => {
			return (
				<Typography variant="body2">
					{params.row.namedNetwork?.namedNetworkName}
				</Typography>
			);
		},
	},
	{
		field: "assetWithObservedActivityTrafficWithLastMonth",
		headerName: "Observed activity in last month",
		flex: 1,
		sortable: true,
		renderCell: params => {
			return (
				<Typography variant="body2">
					{`${numeral(
						params.row.assetWithObservedActivityTrafficWithLastMonth
					).format("0a")} ${window.getCTTranslatedText(
						pluralize(
							"asset",
							params.row.assetWithObservedActivityTrafficWithLastMonth
						)
					)}`}
				</Typography>
			);
		},
	},
	{
		field: "usedByAssets",
		headerName: "Used by",
		flex: 1,
		renderCell: params => {
			return (
				<Stack>
					<Typography variant="body2">
						{`${numeral(params.row.usedByAssets).format(
							"0a"
						)} ${window.getCTTranslatedText(
							pluralize("asset", params.row.usedByAssets)
						)}`}
					</Typography>

					<Typography
						variant="caption"
						color={() => {
							if (params.row.coverage >= 0.5 && params.row.coverage < 0.7) {
								return "info.main";
							}
							if (params.row.coverage >= 0.7) {
								return "success.main";
							}
							return undefined;
						}}
					>
						{`${numeral(params.row.coverage).format(
							"0%"
						)} ${window.getCTTranslatedText("coverage")}`}
					</Typography>
				</Stack>
			);
		},
	},
	{
		...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
		renderCell: (params: GridRenderCellParams<any, any, any>) => (
			<CustomDetailPanelToggle id={params.id} value={params.value} />
		),
	},
];

function getRowId(agg: PathAgg) {
	return `${agg.protocol}-${agg.port}-${agg.namedNetwork?.namedNetworkId}`;
}

export const PathRecommendations = ({
	setPortSelection,
	data,
	isLoading,
	onClickSuggestion,
}: PathRecommendationProps) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const [selection, setSelection] = useState<Array<GridRowId>>([]);
	const [recommendationList, setRecommendationList] = useState(data ?? []);

	useEffect(() => {
		setRecommendationList(data);
		setSelection(data?.filter(r => r.coverage >= 0.5).map(getRowId) ?? []);
	}, [data]);

	const updateSelections = (selectedPortIds: GridRowId[]) => {
		setSelection(selectedPortIds);
	};

	useEffect(() => {
		if (selection && selection?.length > 0) {
			const map = new Map(selection.map(id => [id, true]));

			setPortSelection(
				recommendationList.filter(port => map.get(getRowId(port)))
			);
		} else {
			setPortSelection([]);
		}
	}, [selection, recommendationList, setPortSelection]);

	const {
		triggerExportAsCsv,
		getExportStatus,
		getUrlToDownload,
		resetDownloadUrl,
	} = useExportCSV({
		useApi: useAggregateAPI,
		searchCriteria: "",
		page: 0,
		sort: [],
		sourceCriteria: "",
		destinationCriteria: "",
		scope: Scope.Path,
		frontendOnly: true,
	});

	const selectedRawData: Array<PathAgg> | undefined = useMemo(() => {
		let result = (recommendationList ?? [])?.filter((row: PathAgg) => {
			return selection.indexOf(getRowId(row)) !== -1;
		});
		return result?.length > 0 ? result : recommendationList;
	}, [selection, recommendationList]);

	const getDetailPanelContent = useCallback<
		NonNullable<DataGridProProps["getDetailPanelContent"]>
	>(({ row }) => {
		return (
			<NetworkDetail
				namedNetworkId={row?.namedNetwork?.namedNetworkId}
				viewOnly={true}
				hideMetadata={true}
			/>
		);
	}, []);

	const getDetailPanelHeight = useCallback<
		NonNullable<DataGridProProps["getDetailPanelHeight"]>
	>(() => "auto" as const, []);

	return (
		<Stack
			alignItems="flex-start"
			spacing={0}
			direction={"column"}
			sx={{ width: "100%", flex: 1, overflow: "hidden" }}
		>
			<Paper
				sx={{
					width: "100%",
					flex: 1,
					overflow: "hidden",
				}}
			>
				<DataGrid<PathAgg>
					slots={{
						noRowsOverlay: () => {
							return (
								<Stack
									alignItems={"center"}
									justifyContent="center"
									sx={{
										zIndex: 100,
										width: "100%",
										height: "100%",
										position: "relative",
									}}
								>
									<Typography variant="body2">
										{window.getCTTranslatedText(
											"No results. Please assign named networks first."
										)}
									</Typography>
									{/* <Button onClick={onClickSuggestion}>
                    Suggest Named Networks
                  </Button> */}
								</Stack>
							);
						},
					}}
					checkboxSelection={userPermissions.has("UPDATE_TEMPLATE")}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						updateSelections(selectionModel);
					}}
					initialState={{
						sorting: {
							sortModel: [{ field: "usedByAssets", sort: "desc" }],
						},
					}}
					rowHeight={64}
					columns={PATH_RECOMMENDATION_COLUMNS}
					getRowId={(agg: PathAgg) => getRowId(agg)}
					getDetailPanelContent={getDetailPanelContent}
					getDetailPanelHeight={getDetailPanelHeight}
					paginationMode="client"
					sortingMode="client"
					pagination
					isLoading={isLoading}
					rows={recommendationList || []}
					rowCount={recommendationList.length}
					triggerExportAsCsv={triggerExportAsCsv}
					getExportStatus={getExportStatus}
					getUrlToDownload={getUrlToDownload}
					resetDownloadUrl={resetDownloadUrl}
					frontendOnly={true}
					selectedRawData={selectedRawData}
				/>
			</Paper>
		</Stack>
	);
};

import { Stack, Collapse, Typography, IconButton, Box } from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { API_URL } from "hooks/useNetworking/constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LazyLog } from "react-lazylog";

interface LogsProps {
	agentId?: string;
	scrollToLogs: () => void;
}

interface ExpandMoreProps extends IconButtonProps {
	expand: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotatev (180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

function LogsContainer({ agentId }: { agentId?: string }) {
	return (
		<Box minHeight={410} mb={2} mt={2} flex={1}>
			<LazyLog
				fetchOptions={{ credentials: "include" }}
				selectableLines
				enableSearch
				url={`${API_URL}/agents/${agentId}/config/gatekeeper/logs`}
			/>
		</Box>
	);
}

export function Logs({ agentId, scrollToLogs }: LogsProps) {
	const [expandedLogs, setExpandedLogs] = useState(false);

	const handleExpandLogs = () => {
		setExpandedLogs(!expandedLogs);
		if (!expandedLogs) {
			setTimeout(() => {
				scrollToLogs();
			}, 300);
		}
	};

	return (
		<Stack
			direction="column"
			width="100%"
			justifyContent="space-between"
			id="appliance-config-logs"
			px={2}
			mt={8}
			sx={{
				background: theme =>
					theme.palette.mode === "dark"
						? theme.palette.background.paper
						: theme.palette.custom.lightGreyBg,
			}}
		>
			<Stack
				direction="row"
				alignItems="center"
				sx={{
					cursor: "pointer",
				}}
				py={1}
				onClick={handleExpandLogs}
			>
				<Typography variant="body1">
					{window.getCTTranslatedText("Logs")}
				</Typography>
				<ExpandMore
					expand={expandedLogs}
					aria-expanded={expandedLogs}
					aria-label="show more"
				>
					<ExpandMoreIcon />
				</ExpandMore>
			</Stack>
			<Collapse in={expandedLogs} timeout="auto" unmountOnExit>
				<LogsContainer agentId={agentId} />
			</Collapse>
		</Stack>
	);
}

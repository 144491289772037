import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SxProps,
	Theme,
} from "@mui/material";

import { Dimension } from "modules/hierarchy-vis/types";

export interface DimensionPickerProps {
	selectedDimension: Dimension | undefined;
	setSelectedDimension: (d: Dimension) => void;
	options: Array<Dimension>;
	pickerLabel?: string;
	showLabel?: boolean;
	sx?: SxProps<Theme>;
	isDisabled?: boolean;
}

export function DimensionPicker({
	pickerLabel = "Dimension",
	selectedDimension,
	setSelectedDimension,
	options,
	showLabel = false,
	sx,
	isDisabled,
}: DimensionPickerProps) {
	return (
		<FormControl
			variant="standard"
			sx={{
				width: "fit-content",
				minWidth: 160,
				pointerEvents: "all",
				m: 0,
				mt: 1,
				...sx,
			}}
			disabled={isDisabled}
		>
			{showLabel && <InputLabel id="dimension-label">{pickerLabel}</InputLabel>}
			<Select
				size="small"
				labelId="dimension-label"
				label={showLabel ? window.getCTTranslatedText(pickerLabel) : undefined}
				placeholder={`${window.getCTTranslatedText("Select Dimension")}`}
				value={selectedDimension?.name || ""}
				onChange={e => {
					let dimension = options.find(d => d.name === e.target.value);
					if (dimension) {
						setSelectedDimension(dimension);
					}
				}}
			>
				{options.map(d => {
					return (
						<MenuItem
							sx={{ display: d.isHidden ? "none" : undefined }}
							key={d.name}
							value={d.name}
						>
							{window.getCTTranslatedText(d.label)}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}

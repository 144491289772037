import { nil } from "modules/facets/FacetUtils";
import { Scope } from "modules/scope-metadata/types";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { NamedNetwork } from "pages/paths/types";
import { useAggregateAPI } from "pages/tags/hooks/useAggregateAPI";
import { useEffect, useMemo } from "react";

const EMPTY: PathAgg[] = [];
export interface PathAgg {
	port: string;
	protocol: string;
	namedNetwork: NamedNetwork;
	assetWithObservedActivityTrafficWithLastMonth: number;
	usedByAssets: number;
	coverage: number;
}

const HAS_BW_CRITERIA = "('pathlastobserved' BETWEEN 0 AND 720)";

function getId(agg: PathAgg) {
	return `${agg.protocol}-${agg.port}`;
}

export function usePathRecommendationsWithDataVolumeGroup({
	criteria,
	sourceCriteria,
	destinationCriteria,
	enabled,
	direction,
	total,
}: {
	criteria?: string;
	sourceCriteria?: string;
	destinationCriteria?: string;
	enabled: boolean;
	direction: Direction;
	total: number;
}) {
	const { aggregates, isLoading } = usePathRecommendations({
		criteria,
		sourceCriteria,
		destinationCriteria,
		enabled,
		direction,
		total,
	});

	let bwCriteria = criteria;
	if (criteria === "*") {
		bwCriteria = HAS_BW_CRITERIA;
	} else {
		bwCriteria = `${bwCriteria} AND ${HAS_BW_CRITERIA}`;
	}

	const { aggregates: withBwAggs, isLoading: isLoadingWithBw } =
		usePathRecommendations({
			criteria: bwCriteria,
			sourceCriteria,
			destinationCriteria,
			enabled,
			direction,
			total,
		});

	const bwStats = useMemo(() => {
		if (!withBwAggs) {
			return undefined;
		}
		let map: { [key: string]: number } = {};
		withBwAggs.forEach(agg => {
			map[getId(agg)] = agg.usedByAssets;
		});
		return map;
	}, [withBwAggs]);

	const splitAggregates = useMemo(() => {
		if (!bwStats && !isLoadingWithBw) {
			return EMPTY;
		}
		if (!aggregates?.length && !isLoading) {
			return EMPTY;
		}

		aggregates.forEach(agg => {
			agg.assetWithObservedActivityTrafficWithLastMonth =
				bwStats?.[getId(agg)] ?? 0;
		});
		return aggregates;
	}, [bwStats, isLoadingWithBw, aggregates, isLoading]);

	return {
		aggregates: splitAggregates,
		isLoading: isLoading || isLoadingWithBw,
	};
}

export function usePathRecommendations({
	criteria,
	sourceCriteria,
	destinationCriteria,
	enabled,
	direction,
	total,
}: {
	criteria?: string;
	sourceCriteria?: string;
	destinationCriteria?: string;
	enabled: boolean;
	direction: Direction;
	total: number;
}) {
	const { mutate, data, isLoading } = useAggregateAPI();

	useEffect(() => {
		let hasSourceDest = Boolean(
			sourceCriteria &&
				sourceCriteria !== "*" &&
				destinationCriteria &&
				destinationCriteria !== "*"
		);

		if (!hasSourceDest && !criteria) {
			return;
		}

		if (!enabled) {
			return;
		}

		mutate({
			criteria: criteria ?? "*",
			sourceCriteria,
			destinationCriteria,
			scope: Scope.Path,
			groupBy: [
				"protocol",
				"port",
				`${direction === Direction.Inbound ? "source." : ""}namednetworkname`,
				`${direction === Direction.Inbound ? "source." : ""}namednetworkid`,
			],
			statistics: [
				// "sum(bandwidthinbytes)",
				"distinctcount(source.assetname)",
				"distinctcount(destination.assetname)",
			],
		});
	}, [
		criteria,
		destinationCriteria,
		enabled,
		mutate,
		sourceCriteria,
		direction,
	]);

	let { aggregates } = useMemo<{
		aggregates: PathAgg[];
	}>(() => {
		if (!data) {
			return { aggregates: EMPTY, total: 0 };
		}
		let agg: PathAgg[] = [];
		let protocols = Object.keys(data?.items ?? {});

		protocols.forEach(protocol => {
			let portsData = data?.items?.[protocol]?.port;
			let ports = Object.keys(portsData ?? {});
			ports.forEach(port => {
				let namedNetworksData = portsData?.[port]?.namednetworkname;
				let namedNetworkNames = Object.keys(namedNetworksData);
				namedNetworkNames.forEach(namedNetworkName => {
					if (namedNetworkName === nil) {
						return;
					}
					let namedNetworkIds = Object.keys(
						namedNetworksData?.[namedNetworkName]?.namednetworkid
					);
					namedNetworkIds.forEach(id => {
						if (id === nil) {
							return;
						}

						let stats =
							namedNetworksData?.[namedNetworkName]?.namednetworkid?.[id]
								?.statistics;
						let bwByteAgg = stats?.bandwidthinbytessum ?? 0;
						let assetCount =
							(stats?.sourceassetnamedistinctcount ||
								stats?.destinationassetnamedistinctcount) ??
							0;
						let namedNetwork: NamedNetwork = {
							namedNetworkId: id,
							namedNetworkName: namedNetworkName,
						};

						agg.push({
							port,
							protocol,
							assetWithObservedActivityTrafficWithLastMonth: bwByteAgg,
							usedByAssets: assetCount,
							coverage: 0,
							namedNetwork,
						});
					});
				});
			});
		});
		agg.forEach(aggValue => {
			if (aggValue.usedByAssets && total) {
				aggValue.coverage = Number((aggValue.usedByAssets / total).toFixed(2));
			}
		});
		agg.sort((a, b) => b.coverage - a.coverage);
		return { aggregates: agg };
	}, [data, total]);

	return { aggregates, isLoading: isLoading };
}

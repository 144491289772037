import CloseIcon from "@mui/icons-material/Close";
import {
	Alert,
	Box,
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { GridRowId } from "@mui/x-data-grid-pro";
import { Toolbar } from "common/atoms/toolbar";
import { DataGrid } from "modules/data-grid/components/data-grid";
import {
	AlertRuleConfig,
	AlertNotificationStatus,
	AlertRuleStatus,
} from "../../types";
import { BASE_ALERTCONFIG_COLUMNS } from "../alert-config-data-grid/constants";
import { NotificationStatusChangeActionBar } from "../notification-status-change-action-bar/NotificationStatusChangeActionBar";

interface NotificationStatusChangeDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	selectedData: Array<AlertRuleConfig> | undefined;
	selectedStatus: AlertNotificationStatus | undefined;
	confirm: (data: any) => void;
	title?: string;
}

export const NotificationStatusChangeDrawer = ({
	isOpen,
	onClose,
	selectedStatus,
	selectedData,
	title,
	confirm,
}: NotificationStatusChangeDrawerProps) => {
	const [selection, setSelection] = useState<Array<GridRowId>>([]);

	useEffect(() => {
		if (selectedData?.length) {
			const selectedGroupMap: string[] = selectedData
				.filter(
					(row: AlertRuleConfig) =>
						selectedStatus !== row.notificationStatus &&
						row.alertRuleStatus !== AlertRuleStatus.Disabled
				)
				.map((row: AlertRuleConfig) => row.alertRuleId);

			setSelection(selectedGroupMap);
		}
	}, [selectedData, selectedStatus]);

	const confirmAction = (data: Array<GridRowId>) => {
		const selectedGroup: AlertRuleConfig[] =
			selectedData?.filter((row: AlertRuleConfig) =>
				data.includes(row.alertRuleId)
			) ?? [];
		confirm(selectedGroup);
	};

	const showNotificationWarning = selectedData?.some(
		(ele: AlertRuleConfig) => ele.alertRuleStatus === AlertRuleStatus.Disabled
	);

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					padding: "0px",
					width: "80%",
					minWidth: "1000px",
					height: "100%",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "16px", top: "16px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<Stack direction="row" spacing={2} sx={{ mt: 4, mb: 0, mx: 4 }}>
				<Typography variant="h5">
					<b>{window.getCTTranslatedText(title ?? "")}</b>
				</Typography>
				<Typography variant="h5">{`: ${window.getCTTranslatedText(
					selectedStatus ?? ""
				)}`}</Typography>
			</Stack>
			<DialogContent
				sx={{ display: "flex", height: "100%", flexDirection: "column" }}
			>
				{showNotificationWarning && (
					<Alert sx={{ width: "100%", mb: 2 }} severity={"warning"}>
						{`${window.getCTTranslatedText("notificationsStatusChange")}`}
					</Alert>
				)}
				<Box flex={1} overflow="hidden">
					<DataGrid
						rowHeight={64}
						columns={BASE_ALERTCONFIG_COLUMNS}
						pagination={true}
						getRowId={(alert: AlertRuleConfig) => `${alert.alertRuleId}`}
						paginationMode="client"
						sortingMode="client"
						checkboxSelection
						isRowSelectable={data =>
							selectedStatus !== data.row.notificationStatus &&
							data.row.alertRuleStatus !== AlertRuleStatus.Disabled
						}
						rowSelectionModel={selection}
						onRowSelectionModelChange={selectionModel => {
							setSelection(selectionModel);
						}}
						rows={selectedData}
						rowCount={selectedData?.length ?? 0}
						selectedRawData={selectedData}
					/>
				</Box>
			</DialogContent>

			<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
				<NotificationStatusChangeActionBar
					selectedStatus={selectedStatus}
					selection={selection}
					update={confirmAction}
					cancel={onClose}
				/>
			</DialogActions>
		</Drawer>
	);
};

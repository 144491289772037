import {
	Divider,
	FormControlLabel,
	FormGroup,
	Grid,
	ListItemIcon,
	Stack,
	Switch,
	Tooltip,
	Typography,
	capitalize,
	styled,
	useTheme,
} from "@mui/material";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton, {
	ListItemButtonProps,
} from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { usePrevious } from "common/hooks/usePrevious";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "common/molecules/accordion";
import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FacetUtils } from "./FacetUtils";
import { FacetSuggestions } from "./components/facet-search";
import { useCommitAndStageSync, useFacetSuggestionInput } from "./hooks";
import {
	Facet,
	FacetChange,
	FacetCommit,
	FacetConfig,
	FacetContainer,
	FacetGroup,
	FacetGroups,
	FacetOption,
	FacetOptionState,
	FacetState,
	Operator,
	onFacetChange,
	onFacetCommit,
} from "./types";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CTInputField } from "common/atoms/ct-input-field";
import { FacetMenuWidthMaxHeight } from "common/atoms/facet-open-close-controller/constants";
import { useCommonStore } from "common/store";
import { CustomCommonStoreType } from "common/types/types";
import produce from "immer";
import { getCustomMenuIcon } from "modules/NavigationLayout/helpers";
import { getFacetOptionDisplayText } from "modules/facets/helpers";
import numeral from "numeral";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { CoreTagsKeys } from "pages/assets/types";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import FacetSkeleton from "./components/FacetSkeleton";
import { FacetSearch } from "./components/facet-search/FacetSearch";
import { FilterActionButton } from "./components/filter-action-button";
import { ADVANCED_OPERATORS, ADVANCED_OPERATORS_LIST } from "./constants";

const MAX_FACETS_VISIBLE = 10;
const CTListCheckBox = styled(Checkbox)<CheckboxProps>`
	padding-top: 0;
	padding-bottom: 0;
`;

const CTListItemButton = styled(ListItemButton)<ListItemButtonProps>`
	padding-left: 2px;
	padding-right: 2px;
`;
interface FacetControllerProps {
	config: FacetConfig | undefined;
	value: FacetState;
	onChange: onFacetCommit;
	useFacetStore?: CustomCommonStoreType;
	isSelection?: boolean;
	dialogTitle?: string;
	readOnlyDimensions?: Array<string>;
	disabledOperators?: Array<Operator>;
	showSelectionToggle?: boolean;
}

interface FacetGroupProps {
	facetGroupHeader: string;
	facetGroup: FacetGroup;
	value: FacetState;
	onChange: onFacetCommit;
	FacetGroupIconClassName: JSX.Element | null;
	useFacetStore?: CustomCommonStoreType;
	isSelection?: boolean;
	isActiveFacetGroup?: boolean;
	updateActiveGroup: (activeFacetGroup: string) => void;
	isCustomIcon?: boolean;
	readOnlyDimensions?: Array<string>;
}

function FacetController({
	facetGroupHeader,
	facetGroup,
	value,
	onChange,
	FacetGroupIconClassName,
	useFacetStore,
	isSelection,
	isActiveFacetGroup,
	updateActiveGroup,
	isCustomIcon,
	readOnlyDimensions,
}: FacetGroupProps) {
	const theme = useTheme();

	const onListItemClick = (facetGroupHeader: string) => {
		updateActiveGroup(facetGroupHeader);
	};

	const appliedCount = useMemo(() => {
		let count = 0;

		const facetsInGroup: { [key: string]: boolean } = {};
		facetGroup?.forEach(f => (facetsInGroup[f.name] = true));

		Array.from(value?.entries() ?? []).forEach(([k, v]) => {
			if (readOnlyDimensions?.includes(k) || !facetsInGroup[k]) {
				return;
			}
			let keys = Array.from(v.keys() ?? []).length;
			count += keys;
		});

		return count;
	}, [value, readOnlyDimensions, facetGroup]);

	const IconComponent = FacetGroupIconClassName ?? null;

	const color = isActiveFacetGroup
		? theme.palette.primary.contrastText
		: theme.palette.text.secondary;

	return (
		<>
			<ListItem
				disablePadding
				key={facetGroupHeader}
				onClick={() => {
					onListItemClick(facetGroupHeader);
				}}
				sx={{
					backgroundColor: isActiveFacetGroup
						? theme.palette.primary.main
						: "transparent",
				}}
			>
				<ListItemButton
					sx={{
						px: 4,
						py: 2,
						justifyContent: "flex-start",
						width: "100%",
					}}
				>
					<Stack direction="row" spacing={2} alignItems="center" width="100%">
						<ListItemIcon
							sx={{
								minWidth: 0,
								justifyContent: "center",
								color: color,
							}}
						>
							{isCustomIcon
								? getCustomMenuIcon(facetGroupHeader, color)
								: IconComponent}
						</ListItemIcon>

						<ListItemText
							disableTypography
							primary={
								<Stack direction="row" justifyContent="space-between">
									<Typography
										variant="body2"
										sx={{
											color: color,
											fontWeight: theme.typography.fontWeightLight,
											whiteSpace: "nowrap",
										}}
									>
										{window.getCTTranslatedText(facetGroupHeader)}
									</Typography>

									{(appliedCount ?? 0) > 0 && (
										<Typography
											variant="body2"
											sx={{
												color: color,
												fontWeight: theme.typography.fontWeightLight,
											}}
										>
											{appliedCount}
										</Typography>
									)}
								</Stack>
							}
						/>
					</Stack>
				</ListItemButton>
			</ListItem>
		</>
	);
}

const filterGrpWidth = 230;

export function FacetControllerGroup({
	config,
	value,
	onChange,
	useFacetStore,
	isSelection,
	dialogTitle,
	readOnlyDimensions,
	disabledOperators = [],
	showSelectionToggle = false,
}: FacetControllerProps) {
	const theme = useTheme();
	const [activeFacetGroup, setActiveFacetGroup] = useState<string | undefined>(
		undefined
	);
	const [stagedValue, setStagedValue] = useState<FacetState>(new Map());
	const [selection, setSelection] = useState(!isSelection);

	useEffect(() => {
		if (config) {
			if (activeFacetGroup) {
				const currentConfig = config?.find((group: FacetContainer) => {
					return group.displayName === activeFacetGroup;
				});
				if (!currentConfig) {
					setActiveFacetGroup(config[0]?.displayName);
				}
			}
			if (!activeFacetGroup) {
				setActiveFacetGroup(config[0]?.displayName);
			}
		}
	}, [config, activeFacetGroup]);

	const updateActiveGroup = (activeFacetGroup: string) => {
		if (activeFacetGroup) {
			setActiveFacetGroup(activeFacetGroup);
		}
	};

	const onStageChange = useCallback(
		(change: FacetChange) => {
			setStagedValue(FacetUtils.handleFacetOptionChange(stagedValue, change));
		},
		[stagedValue]
	);

	const onCommitFacet = useCallback(
		(commit: FacetCommit) => {
			const newStagedValue = FacetUtils.updateFacet(stagedValue, commit);
			setStagedValue(newStagedValue);
			onChange(commit);
		},
		[onChange, stagedValue]
	);

	const sortedFacets = useMemo(() => {
		if (!activeFacetGroup) {
			return;
		}

		const currentConfig = config?.find((group: FacetContainer) => {
			return group.displayName === activeFacetGroup;
		});

		return currentConfig?.facets;
	}, [activeFacetGroup, config]);

	const previousCommited = usePrevious(value);
	let clearedCommitedFromOutside = false;

	if (
		previousCommited &&
		previousCommited?.size > 0 &&
		(!value || value?.size === 0)
	) {
		clearedCommitedFromOutside = true;
	}

	useEffect(() => {
		if (clearedCommitedFromOutside) {
			setStagedValue(new Map());
		}
	}, [clearedCommitedFromOutside]);

	return (
		<Stack
			direction="row"
			alignItems="flex-start"
			sx={{ width: "100%", height: "100%" }}
		>
			<Stack sx={{ width: filterGrpWidth, height: "100%" }}>
				<Stack px={4} py={1}>
					<Typography
						variant="overline"
						sx={{
							fontWeight: theme.typography.fontWeightLight,
						}}
						pr={2}
						flex={1}
					>
						{window.getCTTranslatedText(dialogTitle ?? "Facets")}
					</Typography>
				</Stack>
				<Divider />

				<Stack
					sx={{
						height: "100%",
						overflowY: "auto",
						maxHeight: "100%",
					}}
					flex={1}
				>
					<List
						sx={{
							paddingTop: 0,
							width: "100%",
							height: "100%",
						}}
					>
						{config?.map(group => {
							return (
								<FacetController
									key={group.displayName}
									facetGroupHeader={group.displayName}
									facetGroup={group.facets}
									FacetGroupIconClassName={group.Icon}
									isCustomIcon={group.isCustomIcon}
									value={value}
									onChange={onChange}
									useFacetStore={useFacetStore}
									isSelection={selection}
									isActiveFacetGroup={activeFacetGroup === group.displayName}
									updateActiveGroup={updateActiveGroup}
									readOnlyDimensions={readOnlyDimensions}
								/>
							);
						})}
					</List>
				</Stack>
			</Stack>
			<Divider
				orientation="vertical"
				sx={{ height: FacetMenuWidthMaxHeight, m: 0 }}
				variant="middle"
				flexItem
			/>
			<Stack flex={1} height="100%" sx={{ overflow: "hidden" }}>
				<Stack
					px={3}
					py={1}
					direction={"row"}
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<Typography
						variant="overline"
						sx={{
							fontWeight: theme.typography.fontWeightLight,
						}}
					>
						{window.getCTTranslatedText("Filters")}
					</Typography>
					{showSelectionToggle && (
						<Stack>
							<FormControlLabel
								sx={{
									mr: 0,
								}}
								control={
									<Checkbox
										size="small"
										checked={selection}
										onChange={() => setSelection(!selection)}
									/>
								}
								label={
									<Typography variant="body2" color="textSecondary">
										{window.getCTTranslatedText("SelectionBasedFilters")}
									</Typography>
								}
							/>
						</Stack>
					)}
				</Stack>
				<Divider />
				<Stack
					sx={{
						height: "100%",
						overflowY: "auto",
						maxHeight: "100%",
					}}
					flex={1}
				>
					<List
						sx={{
							paddingTop: 0,
							width: "100%",
							height: "100%",
						}}
					>
						{(sortedFacets ?? [])
							.filter(facet => !readOnlyDimensions?.includes(facet.name))
							.map(facet => {
								const onClearStaged = () => {
									if (
										!stagedValue?.get(facet.name) ||
										stagedValue?.get(facet.name)?.size === 0
									) {
										return;
									}
									setStagedValue(
										FacetUtils.removeFacetFromState(stagedValue, facet.name)
									);
								};
								return (
									<ListItem
										sx={{ display: "block" }}
										key={facet.name}
										disablePadding
									>
										<FacetItem
											facet={facet}
											facetGroup={activeFacetGroup}
											committedValue={value?.get(facet.name)}
											stagedValue={stagedValue?.get(facet.name)}
											onChange={onStageChange}
											onCommit={onCommitFacet}
											onClearStaged={onClearStaged}
											useFacetStore={useFacetStore}
											isSelection={!selection}
											disabledOperators={disabledOperators}
										/>
										<Divider />
									</ListItem>
								);
							})}
					</List>
				</Stack>
			</Stack>
		</Stack>
	);
}
interface FacetItemProps {
	facet: Facet;
	facetGroup?: string;
	committedValue: FacetOptionState | undefined;
	stagedValue: FacetOptionState | undefined;
	onChange: onFacetChange;
	onCommit?: onFacetCommit;
	onClearStaged?: Function;
	useFacetStore?: CustomCommonStoreType;
	isSelection?: boolean;
	disabledOperators?: Array<Operator>;
}

function FacetItem({
	facet,
	facetGroup,
	stagedValue,
	committedValue,
	onChange,
	onCommit,
	onClearStaged,
	useFacetStore = useCommonStore,
	isSelection,
	disabledOperators,
}: FacetItemProps) {
	const theme = useTheme();

	const open = useFacetStore(
		state => state.openFacets.get(facet.name) || false
	);
	const toggleFacet = useFacetStore(state => state.toggleFacetOpen);
	const closeAllFacets = useFacetStore(state => state.closeAllFacets);

	const toggleFacetOpen = () => {
		if (open) {
			toggleFacet(facet.name);
		} else {
			closeAllFacets();
			toggleFacet(facet.name);
		}
	};
	const [toRemove, setToRemoveValues] = useState<Array<string>>([]);

	const hasCommittedFacet =
		(committedValue && committedValue.size > 0) || false;
	const hasStagedFacet = stagedValue && stagedValue.size > 0;

	let operator = Operator.EQUAL;
	if (hasCommittedFacet) {
		committedValue?.forEach((optionValue, optionKey) => {
			if (optionValue?.operator !== Operator.EQUAL) {
				operator = optionValue?.operator;
			}
		});
	}

	const commitFacet = (operator: Operator, value?: FacetOptionState) => {
		setToRemoveValues([]);
		const updatedValue = value ?? stagedValue;
		if (operator !== Operator.EQUAL) {
			stagedValue = produce(updatedValue, d => {
				d?.forEach(v => {
					v.operator = operator;
				});
			});
		}

		onCommit &&
			onCommit({
				facetName: facet.name,
				options: stagedValue!,
			});
		closeAllFacets();
	};

	const clearCommittedFacet = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onClearStaged && onClearStaged();
		setToRemoveValues([]);

		onCommit &&
			onCommit({
				facetName: facet.name,
				options: new Map(),
			});
		closeAllFacets();
	};

	const inverseCommitedFacets = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();

		let inverseCommit = produce(committedValue, draft => {
			if (!draft) {
				return;
			}
			let committedKeys = Array.from(draft?.keys() || []);
			committedKeys.forEach(key => {
				let inverseCommittedValue = draft.get(key);
				if (inverseCommittedValue) {
					if (inverseCommittedValue.operator === Operator.EQUAL) {
						inverseCommittedValue.operator = Operator.NOT_EQUAL;
					} else {
						inverseCommittedValue.operator = Operator.EQUAL;
					}
					draft.set(key, { ...inverseCommittedValue });
				}
			});
			return draft;
		});

		onCommit &&
			inverseCommit &&
			onCommit({
				facetName: facet.name,
				options: inverseCommit,
			});

		closeAllFacets();
	};

	const isStageAndCommittedEqual = isEqual(stagedValue, committedValue);

	const onChangeOption = (change: FacetChange) => {
		if (committedValue?.get(change.optionName) && !change.value) {
			setToRemoveValues(old => [...old, change.optionName]);
		} else {
			setToRemoveValues(old =>
				old.filter(entry => entry !== change.optionName)
			);
		}
		onChange(change);
	};

	const getFacetLabel = (facet: Facet) => {
		if (
			facetGroup?.toLowerCase() === FacetGroups.Others.toLowerCase() ||
			facetGroup?.toLowerCase() === FacetGroups.IdP.toLowerCase()
		) {
			return window.getCTTranslatedText(facet.label);
		}

		if (facetGroup?.toLowerCase() === FacetGroups.Tags.toLowerCase()) {
			let displayName = facet.label;

			if (Object.values(CoreTagsKeys).includes(facet.name as CoreTagsKeys)) {
				displayName = capitalize(facet.label);
			}

			return window.getCTTranslatedText(displayName);
		}

		return window.getCTTranslatedText(capitalizeFirstLetter(facet.label));
	};

	useCommitAndStageSync({
		committedValue,
		facet,
		isStageAndCommittedEqual,
		onChange,
		stagedValue,
		onCommit,
		toRemove,
	});

	const showApplied =
		(hasStagedFacet && !isStageAndCommittedEqual) || toRemove.length > 0;

	const includeAction = !disabledOperators?.includes(Operator.EQUAL) && (
		<FilterActionButton
			onClick={e => {
				e.stopPropagation();
				commitFacet(Operator.EQUAL);
			}}
		>
			{window.getCTTranslatedText("include")}
		</FilterActionButton>
	);

	const excludeAction = !disabledOperators?.includes(Operator.NOT_EQUAL) && (
		<FilterActionButton
			sx={{ ml: 1 }}
			onClick={e => {
				e.stopPropagation();
				commitFacet(Operator.NOT_EQUAL);
			}}
			color={"error"}
		>
			{window.getCTTranslatedText("Exclude")}
		</FilterActionButton>
	);

	const FacetButtons =
		showApplied &&
		(hasCommittedFacet ? (
			<FilterActionButton
				sx={{ ml: 1 }}
				onClick={e => {
					e.stopPropagation();
					commitFacet(operator);
				}}
			>
				{window.getCTTranslatedText("Apply")}
			</FilterActionButton>
		) : (
			<>
				{includeAction}
				{excludeAction}
			</>
		));

	const shouldShowToggleAction =
		!ADVANCED_OPERATORS[operator] &&
		!showApplied &&
		!disabledOperators?.includes(Operator.EQUAL) &&
		!disabledOperators?.includes(Operator.NOT_EQUAL);

	return (
		<Accordion
			sx={{
				display: "inline-flex",
				flexDirection: "column",
				width: "100%",
				minWidth: "100%",
			}}
			id={facet.name}
			expanded={open}
			onChange={toggleFacetOpen}
		>
			<AccordionSummary
				expandIcon={
					<ArrowDropDownIcon
						fontSize="small"
						sx={{ color: theme.palette.primary.main }}
					/>
				}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography
					sx={{ flex: 1, py: 1, textOverflow: "ellipsis" }}
					variant="body2"
					noWrap
				>
					{getFacetLabel(facet)}
				</Typography>
				{hasCommittedFacet && (
					<>
						{open && ADVANCED_OPERATORS[operator] ? null : (
							<FilterActionButton onClick={clearCommittedFacet}>
								{window.getCTTranslatedText("Clear")}
							</FilterActionButton>
						)}

						{shouldShowToggleAction && (
							<FilterActionButton
								sx={{ ml: 1 }}
								onClick={inverseCommitedFacets}
							>
								{window.getCTTranslatedText("Toggle")}
							</FilterActionButton>
						)}
					</>
				)}
				{!ADVANCED_OPERATORS[operator] && FacetButtons}
			</AccordionSummary>
			<AccordionDetails>
				{open ? (
					<FacetOptions
						facet={facet}
						stagedValue={stagedValue}
						committedValue={committedValue}
						onCommit={onCommit}
						onChange={onChangeOption}
						onClearStaged={onClearStaged}
						removeList={toRemove}
						useFacetStore={useFacetStore}
						operator={operator}
						isSelection={isSelection}
						commitFacet={commitFacet}
						clearCommittedFacet={clearCommittedFacet}
					/>
				) : null}
			</AccordionDetails>
		</Accordion>
	);
}

interface FacetOptionListProps extends FacetItemProps {
	removeList: Array<string>;
	operator: Operator;
	isSelection?: boolean;
	commitFacet: (operator: Operator, value?: FacetOptionState) => void;
	clearCommittedFacet: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

function FacetOptions({
	facet,
	stagedValue,
	committedValue,
	onChange,
	onClearStaged,
	removeList,
	useFacetStore,
	operator,
	isSelection,
	commitFacet,
	clearCommittedFacet,
}: FacetOptionListProps) {
	const {
		search: eqSearch,
		setSearch: setEqSearch,
		readOnly,
	} = useFacetSuggestionInput({
		onClearStaged,
		committedValue,
		facet,
		onChange,
		enabled: facet.options.length > MAX_FACETS_VISIBLE || !facet.options.length,
	});

	const theme = useTheme();

	const [advancedMode, setAdvancedMode] = useState(false);
	const [advanceModeStatus, setAdvanceModeStatus] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (operator) {
			if (ADVANCED_OPERATORS[operator]) {
				setAdvancedMode(true);
			} else if (committedValue && committedValue.size > 0) {
				setAdvanceModeStatus(true);
			}
		}
	}, [committedValue, operator]);

	const toggleAdvancedMode = (advancedMode: boolean) => {
		setAdvancedMode(advancedMode);
	};

	return (
		<List disablePadding sx={{ paddingLeft: theme.spacing(1) }}>
			{!facet.options.length &&
			(facet.suggestions?.length || !facet.facetable) ? (
				<FacetSuggestions
					placeholder={`${facet.label}`}
					search={eqSearch}
					readOnly={readOnly}
					suggestions={facet.suggestions?.map(suggestion => suggestion.label)}
					setSearch={setEqSearch}
				/>
			) : null}
			{facet.advancedOperators && (
				<FacetAdvancedSwitch
					advancedMode={advancedMode}
					toggleAdvancedMode={toggleAdvancedMode}
					isDisabled={advanceModeStatus}
				/>
			)}
			{!advancedMode &&
			facet.searchable &&
			facet.facetable &&
			!facet.suggestions?.length ? (
				<FacetSearch
					facet={facet}
					initialLoadAllowed={true}
					useFacetStore={useFacetStore}
					isSelection={isSelection}
					setIsGroupLoading={setIsLoading}
				/>
			) : null}
			{isLoading && facet?.options.length === 0 ? (
				<FacetSkeleton />
			) : (
				!advancedMode &&
				facet.options.map(option => {
					return (
						<React.Fragment key={option.name}>
							{option?.name && (
								<FacetOptionControl
									operator={operator}
									key={option.name}
									option={option}
									facet={facet}
									stagedValue={stagedValue}
									committedValue={committedValue}
									onChange={onChange}
									removeList={removeList}
									isSelection={isSelection}
									commitFacet={commitFacet}
									clearCommittedFacet={clearCommittedFacet}
								/>
							)}
						</React.Fragment>
					);
				})
			)}
			{advancedMode && (
				<FacetAdvancedOptionControl
					operator={operator}
					facet={facet}
					stagedValue={stagedValue}
					committedValue={committedValue}
					onChange={onChange}
					removeList={removeList}
					isSelection={isSelection}
					commitFacet={commitFacet}
					clearCommittedFacet={clearCommittedFacet}
				/>
			)}
		</List>
	);
}

interface FacetOptionProps extends FacetOptionListProps {
	option: FacetOption;
}

function FacetOptionControl({
	option,
	facet,
	stagedValue,
	committedValue,
	onChange,
	operator,
	removeList,
	isSelection,
}: FacetOptionProps) {
	const isStaged = stagedValue?.get(option.name)?.isSelected || false;
	const isCommitted = committedValue?.get(option.name)?.isSelected || false;

	const isCheckedInState = isStaged || isCommitted || false;

	const [checked, setChecked] = useState(isCheckedInState);
	useEffect(() => {
		setChecked(isCheckedInState);
	}, [isCheckedInState]);

	const labelId = `facet-list-label-${option.name}`;

	const toggleChecked = () => {
		setChecked(!checked);
		onChange({
			facetName: facet.name,
			optionName: option.name,
			value: !checked,
			operator,
		});
	};

	let color: CheckboxProps["color"] = "default";
	if (removeList.includes(option.name)) {
		color = "error";
	} else if (isCommitted) {
		color = "primary";
	}

	return (
		<ListItem disableGutters key={option.name} disablePadding>
			<CTListItemButton role={undefined} onClick={toggleChecked}>
				<CTListCheckBox
					indeterminate={
						operator === Operator.NOT_EQUAL && isCheckedInState
							? true
							: undefined
					}
					checked={isCheckedInState}
					color={color}
					tabIndex={-1}
					disableRipple
					onChange={toggleChecked}
					inputProps={{ "aria-labelledby": labelId }}
				/>
				<ListItemText
					sx={{ "& > span": { textOverflow: "ellipsis", overflow: "hidden" } }}
					id={labelId}
					primary={window.getCTTranslatedText(
						getFacetOptionDisplayText(option.label)
					)}
				/>

				{option.count >= 0 && !isSelection && (
					<Typography variant="caption" sx={{ pr: 1 }}>
						{numeral(option.count).format("0a")}
					</Typography>
				)}
			</CTListItemButton>
		</ListItem>
	);
}

function FacetAdvancedOptionControl({
	stagedValue,
	committedValue,
	operator,
	commitFacet,
	clearCommittedFacet,
}: FacetOptionListProps) {
	const [selectedOperator, setSelectedOperator] = useState<Operator>(
		ADVANCED_OPERATORS_LIST[0]
	);
	const [criteria, setCriteria] = useState<string>("");

	const stagedKey = stagedValue ? stagedValue.keys().next().value : undefined;
	const committedKey = committedValue
		? committedValue.keys().next().value
		: undefined;

	useEffect(() => {
		if (committedKey || stagedKey) {
			setCriteria(committedKey || stagedKey);
		}
	}, [committedKey, stagedKey]);

	useEffect(() => {
		if (operator && ADVANCED_OPERATORS[operator]) {
			setSelectedOperator(operator);
		}
	}, [operator]);

	const apply = () => {
		const newValue: FacetOptionState = new Map();
		newValue.set(criteria, {
			operator: selectedOperator,
			isSelected: true,
		});
		commitFacet(selectedOperator, newValue);
	};

	const handleOperatorUpdate = ({
		field,
		value,
	}: {
		field: string;
		value: Operator;
	}) => {
		if (field) {
			setSelectedOperator(value);
		}
	};

	const handleCriteriaUpdate = ({
		field,
		value,
	}: {
		field: string;
		value: string;
	}) => {
		if (field) {
			setCriteria(value);
		}
	};

	return (
		<Stack>
			<Stack>
				<Grid container columnSpacing={3} sx={{ width: "100%", pl: 2 }}>
					<Grid item xs={5}>
						<CTSelectDropDown
							field={"operator"}
							displayName={"Operator"}
							selectedValue={selectedOperator}
							handleUpdate={handleOperatorUpdate}
							data={ADVANCED_OPERATORS_LIST}
							required={false}
							showLabel={true}
							size="medium"
							showSymbols={true}
						/>
					</Grid>
					<Grid item xs={7}>
						<CTInputField
							field="criteria"
							displayName="Criteria"
							placeholder={"Enter Criteria"}
							value={criteria}
							handleUpdate={handleCriteriaUpdate}
							type="text"
							showLabel={true}
							required={false}
							autoFocus={false}
							showClear={true}
							size="medium"
						/>
					</Grid>
				</Grid>
			</Stack>

			<Stack
				pt={2}
				pr={2}
				spacing={2}
				direction="row"
				alignItems={"flex-end"}
				justifyContent={"flex-end"}
			>
				{committedKey && (
					<FilterActionButton onClick={clearCommittedFacet}>
						Clear
					</FilterActionButton>
				)}
				{criteria && committedKey !== criteria && (
					<FilterActionButton
						sx={{ ml: 1 }}
						onClick={e => {
							e.stopPropagation();
							apply();
						}}
						disabled={!criteria || committedKey === criteria}
					>
						Apply
					</FilterActionButton>
				)}
			</Stack>
		</Stack>
	);
}

interface FacetAdvancedSwitchProps {
	advancedMode: boolean;
	toggleAdvancedMode: (state: boolean) => void;
	isDisabled: boolean;
}

const FacetAdvancedSwitch = ({
	advancedMode,
	toggleAdvancedMode,
	isDisabled = false,
}: FacetAdvancedSwitchProps) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		toggleAdvancedMode(event.target.checked);
	};

	return (
		<Stack alignItems="flex-end" justifyContent="flex-end" pb={2}>
			<Tooltip title={isDisabled ? "Please clear the basic filter" : ""}>
				<FormGroup>
					<FormControlLabel
						sx={{
							"&.MuiFormControlLabel-root": { marginRight: 1 },
							"& .MuiFormControlLabel-label": {
								fontSize: theme => theme.typography.body2.fontSize,
							},
						}}
						control={
							<Switch
								checked={advancedMode}
								onChange={handleChange}
								name="advanced"
								size="small"
							/>
						}
						disabled={isDisabled}
						label={window.getCTTranslatedText("Advanced")}
					/>
				</FormGroup>
			</Tooltip>
		</Stack>
	);
};

import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	Radio,
	RadioGroup,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { parseErrorMessage } from "common/utils";
import { Annotation } from "modules/annotation";
import { AnnotationDrawer } from "modules/annotation-drawer";
import { EntityType } from "modules/annotation-drawer/type";
import { DiffViewerButton } from "modules/policy-diff-viewer/DiffViewerButton";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useDebouncedSnackbar } from "modules/snackbar/store/useDebouncedSnackbar";
import numeral from "numeral";
import { AssetStatus } from "pages/assets/types";
import pluralize from "pluralize";
import { useState } from "react";
import {
	AssetStatusMap,
	AssetStatusReverseMap,
	Direction,
	SecurityStatus,
} from "../../constants";
import { useZeroTrustAPI } from "../../hooks/use-update-status";
import { ConfirmDeployment } from "../confirm-deployment";
import {
	ActionBarModes,
	AssetStatusActionProps,
	DeployStatusChangeProps,
	StatusChangeProps,
	StatusChangeReqBodyProps,
} from "./types";

export const AssetStatusAction = ({
	asset,
	direction,
	currentStatus,
	selectedStatus,
	statusChangeCallback,
	disabled,
	criteria,
	mode = ActionBarModes.DEFAULT,
	comment,
	minValue,
	setComment,
	setIsCommentDrawerOpen,
	isCommentDrawerOpen,
	useAssetStore,
	isPreviewMode = false,
	isCommentsVisible = false,
	isTestMode,
	onChangeTestMode,
	hasPermission = true,
}: AssetStatusActionProps) => {
	const theme = useTheme();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const { setDebouncedSnackbar } = useDebouncedSnackbar();
	const updateDeployMutation = useZeroTrustAPI();
	const updateTestModeMutation = useZeroTrustAPI();
	const [isDiffViewerDrawerOpen, setIsDiffViewerDrawerOpen] = useState(false);

	const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

	let assetStatus: SecurityStatus;
	let assetStatusValue: AssetStatus = AssetStatusReverseMap[selectedStatus];
	let assetCurrentStatusValue: AssetStatus | undefined;
	let simulateStatus: boolean = false;

	switch (direction) {
		case Direction.Inbound:
			if (currentStatus !== undefined) {
				assetStatus = currentStatus;
				assetCurrentStatusValue = AssetStatusReverseMap[currentStatus];
			} else {
				assetStatus = AssetStatusMap[asset?.inboundAssetStatus as AssetStatus];
				assetCurrentStatusValue = asset?.inboundAssetStatus;
			}
			simulateStatus = Boolean(asset?.simulateInboundAssetStatus);
			break;
		case Direction.Outbound:
			if (currentStatus !== undefined) {
				assetStatus = currentStatus;
				assetCurrentStatusValue = AssetStatusReverseMap[currentStatus];
			} else {
				assetStatus = AssetStatusMap[asset?.outboundAssetStatus as AssetStatus];
				assetCurrentStatusValue = asset?.outboundAssetStatus;
			}
			simulateStatus = Boolean(asset?.simulateOutboundAssetStatus);
			break;
	}

	const triggerDeployMode = ({
		direction,
		status,
		force,
	}: DeployStatusChangeProps) => {
		if (status) {
			if (
				!force &&
				selectedStatus !== SecurityStatus.Unsecure &&
				assetCurrentStatusValue !== `simulate-${assetStatusValue}`
			) {
				setShowConfirmDialog(true);
			} else {
				confirmDeployMode({ direction, status });
			}
		}
	};

	const triggerTestMode = ({ direction, status }: StatusChangeProps) => {
		if (status) {
			confirmTestMode({ direction, status });
		}
	};

	const buildReqBody = ({
		direction,
		status,
		simulationMode,
	}: StatusChangeReqBodyProps) => {
		if (simulationMode) {
			status = `simulate-${status}` as AssetStatus;
		}

		interface BodyProps {
			criteria: string;
			inboundToState?: AssetStatus;
			outboundToState?: AssetStatus;
			comment: string | null;
		}

		const body: BodyProps = {
			criteria: criteria || `assetId in ('${asset?.assetId}')`,
			comment: comment ?? null,
		};

		if (direction === Direction.Inbound) {
			body["inboundToState"] = status;
		} else if (direction === Direction.Outbound) {
			body["outboundToState"] = status;
		}
		return body;
	};

	const confirmTestMode = async ({ direction, status }: StatusChangeProps) => {
		const body = buildReqBody({ direction, status, simulationMode: true });

		setDebouncedSnackbar(
			true,
			SnackBarSeverity.Info,
			window.getCTTranslatedText("debouncedSnackbarText")
		);

		await updateTestModeMutation.mutateAsync(body, {
			onSuccess: response => {
				setDebouncedSnackbar.cancel();
				setComment?.(undefined);
				statusChangeCallback();
				setIsDiffViewerDrawerOpen(false);
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"UpdatedAssetStatusToSimulateStatusSuccessfully",
					{ status: window.getCTTranslatedText(`simulate-${status}`) }
				);
			},
			onError: error => {
				setDebouncedSnackbar.cancel();
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const confirmDeployMode = async ({
		direction,
		status,
	}: StatusChangeProps) => {
		const body = buildReqBody({ direction, status, simulationMode: false });

		setDebouncedSnackbar(
			true,
			SnackBarSeverity.Info,
			window.getCTTranslatedText("debouncedSnackbarText")
		);

		await updateDeployMutation.mutateAsync(body, {
			onSuccess: response => {
				setDebouncedSnackbar.cancel();
				setComment?.(undefined);
				statusChangeCallback();
				setShowConfirmDialog(false);
				setIsDiffViewerDrawerOpen(false);
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"UpdatedAssetStatusSuccessfully",
					{ status: window.getCTTranslatedText(status) }
				);
			},
			onError: error => {
				setDebouncedSnackbar.cancel();
				setShowConfirmDialog(false);
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const handleConfirmDialog = (dialogVisibility: boolean) => {
		setShowConfirmDialog(dialogVisibility);
	};

	const totalComments =
		direction === Direction.Inbound
			? asset?.totalInboundComments
			: asset?.totalOutboundComments;

	const params = `securityStatus=${
		direction === Direction.Inbound ? "attacksurface" : "blastradius"
	}`;

	const renderTestModeButton = () => {
		return (
			<LoadingButton
				loading={updateTestModeMutation.isLoading}
				disabled={
					mode === ActionBarModes.DEFAULT &&
					(disabled || (simulateStatus && selectedStatus === assetStatus))
				}
				sx={{ color: theme.palette.primary.contrastText }}
				color="warning"
				variant="contained"
				onClick={() => {
					triggerTestMode({ direction, status: assetStatusValue });
				}}
			>
				{window.getCTTranslatedText("Deploy")}
			</LoadingButton>
		);
	};

	const renderDeployButton = () => {
		return (
			<LoadingButton
				loading={updateDeployMutation.isLoading}
				disabled={
					mode === ActionBarModes.DEFAULT &&
					(disabled || assetStatusValue === assetCurrentStatusValue)
				}
				color="primary"
				variant="contained"
				onClick={() => {
					triggerDeployMode({
						direction,
						status: assetStatusValue,
						force: false,
					});
				}}
			>
				{window.getCTTranslatedText("Deploy")}
			</LoadingButton>
		);
	};

	const renderComments = () => {
		return (
			<Stack width="100%" display={"block"}>
				<Stack
					sx={{
						maxWidth: "100%",
						cursor: "pointer",
						mt: 1.5,
					}}
					alignItems="center"
					justifyContent="flex-start"
					direction="row"
					onClick={() => setIsCommentDrawerOpen?.(true)}
				>
					<CommentOutlinedIcon fontSize="small" />
					<Typography sx={{ mx: 1 }} variant="subtitle1">
						{totalComments
							? `${numeral(totalComments).format("0a")} ${pluralize(
									window.getCTTranslatedText("Comment"),
									totalComments || 0
								)}`
							: window.getCTTranslatedText("Comment")}
					</Typography>
				</Stack>
				{isCommentDrawerOpen && useAssetStore && (
					<AnnotationDrawer
						isOpen={isCommentDrawerOpen}
						onClose={() => setIsCommentDrawerOpen?.(false)}
						entity={EntityType.Assets}
						entityId={asset?.assetId ?? ""}
						useStore={useAssetStore}
						params={params}
						hasPermission={hasPermission}
					/>
				)}
				{hasPermission && setComment && (
					<Annotation
						hideTitle={true}
						comment={comment ?? ""}
						setComment={setComment}
					/>
				)}
			</Stack>
		);
	};

	const renderTestAction = () => {
		return (
			selectedStatus !== SecurityStatus.Unsecure &&
			selectedStatus >= (minValue ?? 0) &&
			(isPreviewMode ? (
				<>
					<DiffViewerButton
						buttonProps={{
							color: "warning",
							sx: { px: 2 },
							size: "medium",
							disabled:
								mode === ActionBarModes.DEFAULT &&
								(disabled ||
									(simulateStatus && selectedStatus === assetStatus)),
						}}
						handlerCloseMenu={() => {
							setIsDiffViewerDrawerOpen(false);
						}}
						isOpen={isDiffViewerDrawerOpen}
						handlerDiffViewDrawerOpen={() => setIsDiffViewerDrawerOpen(true)}
						buttonTitle="Preview"
						asset={asset}
						direction={direction === Direction.Inbound ? "inbound" : "outbound"}
						variant="outlined"
						isPreviewMode={isPreviewMode}
						inboundToState={
							direction === Direction.Inbound
								? `simulate-${assetStatusValue}`
								: undefined
						}
						outboundToState={
							direction === Direction.Outbound
								? `simulate-${assetStatusValue}`
								: undefined
						}
					>
						<>
							{renderComments()}
							<Stack
								pt={2}
								width={"100%"}
								direction={"row"}
								justifyContent={"flex-end"}
							>
								{renderTestModeButton()}
							</Stack>
						</>
					</DiffViewerButton>
					{renderTestModeButton()}
				</>
			) : (
				renderTestModeButton()
			))
		);
	};

	const renderDeployAction = () => {
		return isPreviewMode ? (
			<>
				<DiffViewerButton
					buttonProps={{
						sx: { px: 2 },
						size: "medium",
						disabled:
							mode === ActionBarModes.DEFAULT &&
							(disabled || assetStatusValue === assetCurrentStatusValue),
					}}
					handlerCloseMenu={() => {
						setIsDiffViewerDrawerOpen(false);
					}}
					isOpen={isDiffViewerDrawerOpen}
					buttonTitle="Preview"
					asset={asset}
					handlerDiffViewDrawerOpen={() => setIsDiffViewerDrawerOpen(true)}
					direction={direction === Direction.Inbound ? "inbound" : "outbound"}
					variant="outlined"
					isPreviewMode={isPreviewMode}
					inboundToState={
						direction === Direction.Inbound ? `${assetStatusValue}` : undefined
					}
					outboundToState={
						direction === Direction.Outbound ? `${assetStatusValue}` : undefined
					}
				>
					<>
						{renderComments()}
						<Stack
							pt={2}
							width={"100%"}
							direction={"row"}
							justifyContent={"flex-end"}
						>
							{renderDeployButton()}
						</Stack>
					</>
				</DiffViewerButton>
				{renderDeployButton()}
			</>
		) : (
			renderDeployButton()
		);
	};

	const handleChange = (isTestChecked: boolean) => {
		onChangeTestMode?.(isTestChecked);
	};

	return (
		<>
			{isCommentsVisible && renderComments()}

			{hasPermission && (
				<Stack
					justifyContent={"space-between"}
					sx={{ width: "100%" }}
					alignContent={"flex-end"}
					alignItems="center"
					direction="row"
					spacing={3}
					pt={4}
				>
					<Stack>
						{selectedStatus !== SecurityStatus.Unsecure && (
							<FormGroup sx={{ mr: 2 }}>
								<FormControl
									sx={{
										margin: 0,
										"&.MuiFormControlLabel-root": { marginRight: 1 },
										"& .MuiFormControlLabel-label": {
											fontSize: theme => theme.typography.body2.fontSize,
											color: theme => theme.palette.text.secondary,
										},
									}}
								>
									<RadioGroup
										row
										aria-labelledby="enforcement mode"
										value={isTestMode ? "test" : "enforce"}
										onChange={e => {
											handleChange(e.target.value === "test");
										}}
										name="enforcement-mode"
									>
										<FormControlLabel
											value="test"
											control={<Radio />}
											label={window.getCTTranslatedText("Test")}
										/>
										<FormControlLabel
											value="enforce"
											control={<Radio />}
											label={window.getCTTranslatedText("Enforce")}
										/>
									</RadioGroup>
								</FormControl>
							</FormGroup>
						)}
					</Stack>

					<Stack direction={"row"} spacing={1}>
						{isTestMode ? renderTestAction() : renderDeployAction()}
					</Stack>
				</Stack>
			)}

			{showConfirmDialog && (
				<ConfirmDeployment
					showDialog={showConfirmDialog}
					loading={updateDeployMutation.isLoading}
					confirm={() => {
						triggerDeployMode({
							direction,
							status: assetStatusValue,
							force: true,
						});
					}}
					cancel={() => handleConfirmDialog(false)}
				/>
			)}
		</>
	);
};

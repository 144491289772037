import CloseIcon from "@mui/icons-material/Close";
import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import {
	Box,
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Tab,
	Tooltip,
	Typography,
} from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import { CtTabs, TabPanel } from "common/atoms/ct-tabs/CtTabs";
import { Toolbar } from "common/atoms/toolbar";
import { TimeFilter } from "common/molecules/TimeFilter";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { DiffViewerButton } from "modules/policy-diff-viewer/DiffViewerButton";
import { PathsReview } from "modules/ports-paths-review/components/paths-review";
import { PathStatusActionBar } from "modules/ports-paths-review/components/paths-review/components/path-status-actionbar";
import { PendingNamedNetworkDataGrid } from "modules/ports-paths-review/components/pending-named-network-dataGrid";
import { PendingTemplatesDataGrid } from "modules/ports-paths-review/components/pending-templates-dataGrid";
import { PortsReview } from "modules/ports-paths-review/components/ports-review";
import { PortStatusActionBar } from "modules/ports-paths-review/components/ports-review/components/port-status-actionbar";
import {
	LogType,
	ViewFirewallDecisionLogsButton,
} from "pages/asset/components/asset-metadata-wrapper/components/asset-firewall-status/ViewFirewallDecisionLogsButton";
import { Asset, AssetSecurityCategory, AssetType } from "pages/assets/types";
import { PATH_TIME_FILTER_OPTIONS } from "pages/paths/constants";
import { PathStatus } from "pages/paths/types";
import { PORT_TIME_FILTER_OPTIONS } from "pages/ports/constants";
import { PortStatus } from "pages/ports/types";
import { useState } from "react";
import { AssetReviewModeType } from "../../AssetDetail";
import { Direction } from "../../constants";
import { useAssetDetailCommonStore as useFacetStore } from "./store/common/useAssetDetailCommonStore";
import { usePathAssetStore } from "./store/path-store/usePathAssetStore";
import { usePortAssetStore } from "./store/port-store/usePortAssetStore";
import { FacetState } from "modules/facets/types";

export function useAssetsSynchronizeDiffPaths(assetId: string) {
	const path = `assets/${assetId}/firewallchanges/paths?computeTotal=true`;
	return useMutation<any, Error, any>(["asset-synchronize-path", path]);
}

export function useAssetsSynchronizeDiffPorts(assetId: string) {
	const path = `assets/${assetId}/firewallchanges/openports?computeTotal=true`;
	return useMutation<any, Error, any>(["asset-synchronize-ports", path]);
}

export function useFirewallLogs(assetId: string) {
	const path = `assets/${assetId}/firewalllogs?computeTotal=true`;
	return useMutation<any, Error, any>(["firewall-logs", path]);
}

interface AssetReviewDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	asset?: Asset;
	direction: string;
	activeTab?: Direction | number;
	title: string;
	pathFacetState?: FacetState;
	portFacetState?: FacetState;
	mode: AssetReviewModeType;
	showUserGroupPaths?: boolean;
	isPreviewMode?: boolean;
	readOnlyDimensions?: string[];
}

const determineReviewDirection = (
	mode: AssetReviewModeType,
	direction: string
) => {
	if (mode === AssetReviewModeType.FireWallReview) {
		return undefined;
	}

	return direction === "inbound" ? Direction.Inbound : Direction.Outbound;
};

export const AssetReviewDrawer = ({
	isOpen,
	onClose,
	asset,
	direction,
	title,
	showUserGroupPaths = false,
	activeTab = Direction.Inbound,
	portFacetState,
	pathFacetState,
	mode,
	isPreviewMode,
	readOnlyDimensions,
}: AssetReviewDrawerProps) => {
	const [selectedPortStatus, setSelectedPortStatus] = useState<
		PortStatus | undefined
	>(undefined);
	const [selectedPathStatus, setSelectedPathStatus] = useState<
		PathStatus | undefined
	>(undefined);
	const [comment, setComment] = useState<string | undefined>(undefined);
	const [isDiffViewerDrawerOpen, setIsDiffViewerDrawerOpen] = useState<boolean>(
		isPreviewMode ?? false
	);

	const selectedTime = useUserPreferencesStore(state => state.timeFilter);
	const setSelectedTime = useUserPreferencesStore(state => state.setTimeFilter);
	const [selectedTab, setTab] = useState(activeTab);
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	// ports table config
	const [portsSelection, setPortsSelection] = useState<Array<GridRowId>>([]);
	const assetsSynchronizeDiffPorts = useAssetsSynchronizeDiffPorts.bind(
		useAssetsSynchronizeDiffPorts,
		asset!.assetId
	);

	// paths table config
	const [pathsSelection, setPathsSelection] = useState<Array<GridRowId>>([]);
	const assetsSynchronizeDiffPathsAPI = useAssetsSynchronizeDiffPaths.bind(
		useAssetsSynchronizeDiffPaths,
		asset!.assetId
	);

	const useFirewallLogsAPI = useFirewallLogs.bind(
		useFirewallLogs,
		asset!.assetId
	);

	const confirmPortAction = () => {
		setPortsSelection([]);
		setSelectedPortStatus(undefined);
		setComment(undefined);
		handlerOnClose();
	};

	const onSelectPortStatus = (status: PortStatus | undefined) => {
		setSelectedPortStatus(status);
	};

	const onSelectPathStatus = (status: PathStatus | undefined) => {
		setSelectedPathStatus(status);
	};

	const confirmPathAction = () => {
		setPathsSelection([]);
		setSelectedPathStatus(undefined);
		setComment(undefined);
		handlerOnClose();
	};

	const portFilter = asset?.assetId ? `assetId in ('${asset?.assetId}')` : "";
	const inboundPathFilter = asset?.assetId
		? `assetId in ('${asset?.assetId}') and direction = 'inbound'`
		: "";
	const outboundPathFilter = asset?.assetId
		? `assetId in ('${asset?.assetId}') and direction = 'outbound' `
		: "";
	const getFilterCriteria = () => {
		if (mode === AssetReviewModeType.FireWallReview) {
			return "";
		}

		return mode === AssetReviewModeType.Review
			? `assetId in ('${asset?.assetId}')`
			: (outboundPathFilter ?? "");
	};
	const filterCriteria =
		mode === AssetReviewModeType.Review && showUserGroupPaths
			? `assetId in (${asset?.usergroups
					.map(group => `'${group.groupid}'`)
					.join(",")}) `
			: getFilterCriteria();

	const setFacetState = useFacetStore(state => state.setFacets);

	const handlerOnClose = () => {
		let newFacets = new Map();
		setFacetState(newFacets);
		onClose();
	};

	const getApi = () => {
		switch (mode) {
			case AssetReviewModeType.ViewFirewallPendingChanges:
				return assetsSynchronizeDiffPathsAPI;
			case AssetReviewModeType.FireWallReview:
				return useFirewallLogsAPI;
			default:
				return undefined;
		}
	};

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={handlerOnClose}
			PaperProps={{
				sx: {
					p: 0,
					width: "80%",
					minWidth: "1000px",
					height: "100%",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<DialogContent sx={{ width: "100%", flex: 1 }}>
				<Stack
					alignItems="flex-start"
					sx={{ position: "relative", width: "100%", height: "100%" }}
				>
					<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
						<IconButton
							size="medium"
							aria-label="close drawer"
							onClick={handlerOnClose}
							sx={{
								position: "absolute",
								right: "0px",
								top: "0px",
								zIndex: 2,
							}}
						>
							<CloseIcon fontSize="medium" />
						</IconButton>
					</Tooltip>

					<Stack
						alignItems={"flex-start"}
						spacing={0}
						sx={{ width: "100%", height: "100%" }}
					>
						<Stack sx={{ pt: 1 }}>
							<Typography variant="h5">
								<b>{window.getCTTranslatedText(title)}</b>
							</Typography>
						</Stack>
						<Stack
							direction="row"
							justifyContent={"space-between"}
							alignItems={"center"}
							width={"100%"}
						>
							<Box sx={{ borderBottom: 1, borderColor: "divider", pt: 3 }}>
								<CtTabs value={selectedTab} onChange={handleTabChange}>
									<Tab
										icon={<InstallDesktopIcon />}
										iconPosition="start"
										label={window.getCTTranslatedText("Ports")}
										sx={{
											display: direction === "inbound" ? "flex" : "none",
										}}
									/>
									<Tab
										icon={<ForkRightIcon />}
										iconPosition="start"
										label={window.getCTTranslatedText("Paths")}
									/>

									<Tab
										icon={<DocumentScannerOutlinedIcon />}
										iconPosition="start"
										label={window.getCTTranslatedText("Templates")}
										sx={{
											display:
												mode === AssetReviewModeType.ViewFirewallPendingChanges
													? "flex"
													: "none",
										}}
									/>
									<Tab
										icon={<DeviceHubOutlinedIcon />}
										iconPosition="start"
										label={window.getCTTranslatedText("Named Networks")}
										sx={{
											display:
												mode === AssetReviewModeType.ViewFirewallPendingChanges
													? "flex"
													: "none",
										}}
									/>
								</CtTabs>
							</Box>
							<Stack direction="row" alignItems={"center"} spacing={1}>
								{mode === AssetReviewModeType.Review && (
									<TimeFilter
										selectedTime={selectedTime}
										setSelectedTime={setSelectedTime}
										options={
											direction === "inbound" && selectedTab === 0
												? PORT_TIME_FILTER_OPTIONS
												: PATH_TIME_FILTER_OPTIONS
										}
									/>
								)}
								{mode === AssetReviewModeType.ViewFirewallPendingChanges &&
									asset?.type !== AssetType.Endpoint &&
									asset?.type !== AssetType.Service && (
										<DiffViewerButton
											buttonTitle="Preview Policy"
											asset={asset}
											direction={direction}
											isOpen={isDiffViewerDrawerOpen}
											handlerCloseMenu={() => {
												setIsDiffViewerDrawerOpen(false);
												handlerOnClose();
											}}
										/>
									)}

								{mode === AssetReviewModeType.FireWallReview &&
									Boolean(asset?.agentId) && (
										<Box>
											<ViewFirewallDecisionLogsButton
												asset={asset}
												agentId={asset!.agentId}
												logType={LogType.Raw}
											/>
										</Box>
									)}
							</Stack>
						</Stack>

						<Stack
							direction="column"
							sx={{ width: "100%", flex: 1, overflow: "hidden" }}
						>
							<TabPanel
								style={{ flex: 1, overflow: "hidden" }}
								value={selectedTab}
								index={Direction.Inbound}
							>
								<PortsReview
									setPortSelection={setPortsSelection}
									portFilter={portFilter || ""}
									updatePortstatus={onSelectPortStatus}
									useFacetStore={useFacetStore}
									useStore={usePortAssetStore}
									updatePathstatus={onSelectPathStatus}
									setComment={setComment}
									facetState={portFacetState}
									comment={comment}
									mode={mode}
									api={
										mode === AssetReviewModeType.ViewFirewallPendingChanges
											? assetsSynchronizeDiffPorts
											: undefined
									}
									readOnlyDimensions={readOnlyDimensions}
								/>
							</TabPanel>
							<TabPanel
								style={{ flex: 1, overflow: "hidden" }}
								value={selectedTab}
								index={Direction.Outbound}
							>
								<PathsReview
									setPathSelection={setPathsSelection}
									pathFilter={{
										criteria:
											direction === AssetSecurityCategory.INBOUND
												? inboundPathFilter
												: (filterCriteria ?? ""),
									}}
									direction={determineReviewDirection(mode, direction)}
									facetState={pathFacetState}
									updatePathstatus={onSelectPathStatus}
									useFacetStore={useFacetStore}
									useStore={usePathAssetStore}
									setComment={setComment}
									comment={comment}
									mode={mode}
									api={getApi()}
									readOnlyDimensions={readOnlyDimensions}
								/>
							</TabPanel>

							<TabPanel
								style={{ flex: 1, overflow: "hidden" }}
								value={selectedTab}
								index={2}
							>
								<PendingTemplatesDataGrid rows={asset?.templateChanges} />
							</TabPanel>
							<TabPanel
								style={{ flex: 1, overflow: "hidden" }}
								value={selectedTab}
								index={3}
							>
								<PendingNamedNetworkDataGrid
									rows={asset?.namedNetworkChanges}
								/>
							</TabPanel>
						</Stack>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
				{selectedTab === Direction.Inbound && direction === "inbound" && (
					<PortStatusActionBar
						selectedStatus={selectedPortStatus}
						selection={portsSelection}
						update={confirmPortAction}
						cancel={handlerOnClose}
						useStore={usePortAssetStore}
						selectedPathStatus={selectedPathStatus}
						comment={comment}
					></PortStatusActionBar>
				)}

				{(selectedTab === Direction.Outbound || direction === "outbound") && (
					<PathStatusActionBar
						selectedStatus={selectedPathStatus}
						selection={pathsSelection}
						update={confirmPathAction}
						cancel={handlerOnClose}
						useStore={usePathAssetStore}
						comment={comment}
					></PathStatusActionBar>
				)}
			</DialogActions>
		</Drawer>
	);
};

import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Divider, Fab, Paper, Stack, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CompanyLogo } from "assets/svgs";
import { FileUploader } from "modules/file-uploader";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Tenant, useTenantProfileAPI } from "./useTenantProfileAPI";

const imageUploadConfig = {
	fileTypes: ["image/*"],
	maxFiles: 1,
	showImageEditor: true,
};

export function TenantProfileEditor() {
	const tenantProfileAPI = useTenantProfileAPI();

	return (
		<Stack
			sx={{ p: 4, minWidth: 350, overflowY: "auto" }}
			alignItems={"center"}
			direction={"column"}
			component={Paper}
			spacing={2}
		>
			<TenantLogoImageEditor />
			<Divider orientation="horizontal" sx={{ height: 12, width: 200 }} />
			{tenantProfileAPI.data && (
				<TenantProfileMetaEditor tenantProfile={tenantProfileAPI.data} />
			)}
		</Stack>
	);
}

function TenantLogoImageEditor() {
	const [showImageEditor, setShowImageEditor] = useState<boolean | undefined>(
		undefined
	);

	const queryClient = useQueryClient();

	useEffect(() => {
		if (showImageEditor === false) {
			queryClient.invalidateQueries({
				queryKey: ["tenant-logo"],
			});
		}
	}, [showImageEditor, queryClient]);

	return (
		<Stack
			sx={{ width: 200, height: 200 }}
			alignItems="center"
			justifyContent={"center"}
		>
			<Box sx={{ position: "relative" }}>
				<CompanyLogo width={200} height={200} isEditor={true} />
				<Fab
					color="primary"
					sx={{ position: "absolute", right: 0, bottom: 0, zIndex: 100 }}
					onClick={() => {
						setShowImageEditor(true);
					}}
				>
					<EditIcon />
				</Fab>
				<Stack
					alignItems={"center"}
					justifyContent={"center"}
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						margin: "auto",
					}}
				>
					<FileUploader
						config={imageUploadConfig}
						open={showImageEditor || false}
						onRequestClose={() => setShowImageEditor(false)}
					/>
				</Stack>
			</Box>
		</Stack>
	);
}

function TenantProfileMetaEditor({ tenantProfile }: { tenantProfile: Tenant }) {
	const changeTenantProfile = useMutation<any, Error, Partial<Tenant>>([
		"tenant-profile",
		"auth/admin/tenant-profile",
		"PUT",
	]);

	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const { handleSubmit, control } = useForm({
		defaultValues: {
			...tenantProfile,
		},
	});

	const queryClient = useQueryClient();
	useEffect(() => {
		if (changeTenantProfile.isSuccess) {
			queryClient.invalidateQueries({
				queryKey: ["tenant-profile"],
			});
			queryClient.invalidateQueries({
				queryKey: ["tenants"],
			});
		}
	}, [changeTenantProfile.isSuccess, queryClient]);

	const onSubmit = (values: Tenant) => {
		changeTenantProfile.mutate(values);
	};

	useEffect(() => {
		if (changeTenantProfile.error) {
			setSnackbar(
				true,
				SnackBarSeverity.Error,
				changeTenantProfile.error.message
			);
		}
	}, [changeTenantProfile.error, setSnackbar]);

	useEffect(() => {
		if (changeTenantProfile.isSuccess) {
			setSnackbar(true, SnackBarSeverity.Info, "UpdatedSuccessfully");
		}
	}, [changeTenantProfile.isSuccess, setSnackbar]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: "100%" }}>
			<Stack spacing={4}>
				<Stack spacing={2}>
					<Controller
						render={({ field: { name, value, onChange } }) => (
							<TextField
								// disabled={tenantProfileAPI.isLoading}
								fullWidth
								name={name}
								value={value}
								onChange={onChange}
								label={window.getCTTranslatedText("Organization Name")}
								variant="standard"
							/>
						)}
						name={"name"}
						control={control}
						rules={{ required: { value: true, message: "Invalid input" } }}
					/>
					<Controller
						render={({ field: { name, value, onChange } }) => (
							<TextField
								// disabled={tenantProfileAPI.isLoading}
								name={name}
								fullWidth
								value={value}
								onChange={onChange}
								label={window.getCTTranslatedText("Organization Email")}
								variant="standard"
							/>
						)}
						name={"email"}
						control={control}
					/>
					<Controller
						render={({ field: { name, value, onChange } }) => (
							<TextField
								// disabled={tenantProfileAPI.isLoading}
								name={name}
								fullWidth
								value={value || ""}
								onChange={onChange}
								label={window.getCTTranslatedText("Website")}
								variant="standard"
							/>
						)}
						name={"website"}
						control={control}
					/>
				</Stack>
				<Stack sx={{}}>
					<LoadingButton
						fullWidth
						loading={changeTenantProfile.isLoading}
						type="submit"
						color="primary"
						variant="contained"
						size="medium"
					>
						{window.getCTTranslatedText("Save")}
					</LoadingButton>
				</Stack>
			</Stack>
		</form>
	);
}

import {
	Box,
	Stack,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Grid,
} from "@mui/material";
import React from "react";
import { AuthModeOptions, HAModes } from "../types";
import { DisplayTextMap } from "common/constants/displayTextMap";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";

export interface HighAvailabilityProps {
	HAMode: string;
	onHAModeChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => void;
	password: string;
	setPassword: (value: string) => void;
	routerId: string;
	setRouterId: (value: string) => void;
	authMode: string;
	setAuthMode: (value: string) => void;
	keyListener: (event: any) => void;
	isFormDisabled: boolean;
}

export function HighAvailability({
	HAMode,
	onHAModeChange,
	password,
	setPassword,
	routerId,
	setRouterId,
	authMode,
	setAuthMode,
	keyListener,
	isFormDisabled,
}: HighAvailabilityProps) {
	function renderHAFields() {
		return (
			<Grid
				container
				columnGap={4}
				rowGap={2}
				sx={{ pl: 0 }}
				display="grid"
				gridTemplateColumns="1fr 1fr"
			>
				<Stack>
					<CTInputField
						field="password"
						displayName={window.getCTTranslatedText("VRRPPassword")}
						value={password}
						handleUpdate={(event: TextFieldUpdate) => setPassword(event?.value)}
						type="password"
						onKeyUp={keyListener}
						disabled={isFormDisabled}
						maxLength={8}
					/>
				</Stack>
				<Stack>
					<CTInputField
						field="vrrpRouterId"
						displayName={window.getCTTranslatedText("VRRPRouterID")}
						value={routerId}
						handleUpdate={(event: TextFieldUpdate) => setRouterId(event?.value)}
						onKeyUp={keyListener}
						disabled={isFormDisabled}
					/>
				</Stack>
				<Stack>
					<CTSelectDropDown
						field={"authMode"}
						displayName={window.getCTTranslatedText("VRRPAuthMode")}
						selectedValue={authMode}
						handleUpdate={(event: TextFieldUpdate) => setAuthMode(event.value)}
						data={AuthModeOptions}
						textTransform="none"
						showLabel={true}
					/>
				</Stack>
			</Grid>
		);
	}

	function renderContent() {
		if (HAMode === HAModes.StandAlone) {
			return null;
		}
		return renderHAFields();
	}

	return (
		<Box sx={{ minWidth: "100%" }}>
			<Stack>
				<FormControl>
					<Stack spacing={2}>
						<Stack justifyContent="center">
							<RadioGroup
								row
								aria-labelledby="select-source-destination-label"
								name="path-direction-radio-buttons-group"
								defaultValue={HAModes.StandAlone}
								value={HAMode}
								onChange={onHAModeChange}
							>
								<FormControlLabel
									value={HAModes.StandAlone}
									control={<Radio size="small" />}
									label={window.getCTTranslatedText(
										DisplayTextMap[HAModes.StandAlone]
									)}
								/>
								<FormControlLabel
									value={HAModes.Primary}
									control={<Radio size="small" />}
									label={window.getCTTranslatedText("highAvailability")}
								/>
							</RadioGroup>
						</Stack>
						{renderContent()}
					</Stack>
				</FormControl>
			</Stack>
		</Box>
	);
}

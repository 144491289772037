import { Stack, debounce } from "@mui/material";
import { useCommonStore } from "common/store";
import { ChartDatum as BarStackChartDatum } from "modules/charts/bar-stack/BarStackChart";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	PieChartDatum as ChartDatum,
	DashboardWidget,
} from "@colortokens/ng-ui";
import { AssetStatus } from "pages/assets/types";
import { ASSET_FIELDS_PRIORITY } from "pages/dashboard/constants";
import { SecurityStatusChartDialogProps } from "pages/dashboard/types";
import { useEffect, useMemo, useState } from "react";
import { BarStackChartGraph } from "../security-progress/SecurityProgress";
import {
	avgDataMapper,
	barChartRequestBody,
} from "../security-progress/helpers";
import { facetMetricsToChartData } from "../security-progress/helpers/facetMetricsToChartData";
import { useSummarizeAPI, useTimeSeriesAPI } from "../security-progress/hooks";
import { SummarizeReqParams } from "../security-progress/hooks/useSummarizeAPI";

export const SecurityStatusChartWrapper = ({
	direction,
	type,
}: SecurityStatusChartDialogProps) => {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const searchCriteria = useCommonStore(state => state.currentSearchCriteria);

	const [securityData, setSecurityData] = useState<ChartDatum[] | undefined>(
		undefined
	);

	const [totalAssetsCount, setTotalAssetsCount] = useState<number>(0);

	const keyMetricsMutation = useSummarizeAPI(`asset${direction}status`);
	const keyMetricsAvgMutation = useTimeSeriesAPI();

	const keyMetricsMutationObj = useMemo(
		() => debounce(keyMetricsMutation.mutate, 100),
		[keyMetricsMutation.mutate]
	);

	const keyMetricsAvgMutationObj = useMemo(
		() => debounce(keyMetricsAvgMutation.mutate, 100),
		[keyMetricsAvgMutation.mutate]
	);

	useEffect(() => {
		if (type && direction) {
			let criteria = searchCriteria ?? "*";

			const requestObj = barChartRequestBody({
				criteria,
				scope: Scope.Asset,
				groupBy: [],
				statistics: ["count(assetid)"],
			});

			keyMetricsAvgMutationObj(requestObj, {
				onSuccess(data) {
					if (data) {
						const responseData = data;
						const items = responseData?.items ?? {};

						const totalAssets = avgDataMapper({
							items,
							statisticsId: "assetidcount",
						});

						setTotalAssetsCount(totalAssets ?? 0);
					}
				},
				onError: error => {
					setSnackbar(
						true,
						SnackBarSeverity.Error,
						"ErrorMessageForSecurityStatus",
						{ type: window.getCTTranslatedText(type) }
					);
				},
			});
		}
	}, [direction, keyMetricsAvgMutationObj, searchCriteria, setSnackbar, type]);

	useEffect(() => {
		if (type && direction) {
			let criteria = searchCriteria ?? "*";

			let requestObj: SummarizeReqParams = {
				criteria: criteria,
				scope: Scope.Asset,
				facetField: `asset${direction}status`,
				facetFieldFilter: "",
			};

			keyMetricsMutationObj(requestObj, {
				onSuccess(data) {
					if (data) {
						const responseData = data;
						const facet = responseData?.Facet ?? {};

						const chartData = facetMetricsToChartData({
							facet: facet?.values ?? {},
							labelMapper: {},
							possibleValues: Object.values(AssetStatus),
						});
						setSecurityData(chartData);
					}
				},
				onError: error => {
					setSnackbar(
						true,
						SnackBarSeverity.Error,
						"ErrorMessageForSecurityStatus",
						{ type: window.getCTTranslatedText(type) }
					);
				},
			});
		}
	}, [direction, keyMetricsMutationObj, searchCriteria, setSnackbar, type]);

	const chartConfig = useMemo(() => {
		if (type && direction && securityData) {
			const statusKeys = [
				AssetStatus.Unsecured,
				AssetStatus.SecureInternet,
				AssetStatus.SecureAll,
			];

			const data = statusKeys.map((key: string) => {
				const statusObj = securityData.find((d: ChartDatum) => d.name === key);
				const simulateStatusObj = securityData.find(
					(d: ChartDatum) => d.name === `simulate-${key}`
				);

				const xKey = statusObj?.name ?? "";
				const xSimulatedKey = simulateStatusObj?.name ?? "";

				const entry: BarStackChartDatum = {
					x: xKey,
					values: {},
				};

				if (statusObj && xKey) {
					entry.values[xKey] = { count: statusObj?.count ?? 0 };
				}

				if (simulateStatusObj && xSimulatedKey) {
					entry.values[xSimulatedKey] = {
						count: simulateStatusObj?.count ?? 0,
					};
				}
				return entry;
			});

			return {
				total: totalAssetsCount ?? 0,
				data: data,
			};
		}
	}, [type, direction, securityData, totalAssetsCount]);

	return (
		<DashboardWidget
			title={`${window.getCTTranslatedText("SecurityStatusCharTitle", {
				type: window.getCTTranslatedText(type),
			})}`}
			isLoading={
				keyMetricsMutation?.isLoading || keyMetricsAvgMutation?.isLoading
			}
			children={
				(keyMetricsMutation?.isLoading || keyMetricsAvgMutation?.isLoading) &&
				!chartConfig ? null : (
					<Stack sx={{ width: "100%", height: "100%" }}>
						<BarStackChartGraph
							title={`${window.getCTTranslatedText("SecurityChartTitle", {
								type: window.getCTTranslatedText(type),
							})}`}
							data={chartConfig?.data}
							dataPoint={`${window.getCTTranslatedText("Assets")}`}
							category={`asset${direction}status`}
							total={chartConfig?.total}
							showAllLabels={true}
							isDialogWidget={false}
							tooltipTitle={`${window.getCTTranslatedText(
								type
							)} ${window.getCTTranslatedText("Status")}`}
							priorityMap={ASSET_FIELDS_PRIORITY}
						/>
					</Stack>
				)
			}
			showTrend={false}
		/>
	);
};

import { CoreStoreBuilder } from "modules/core/store";
import { create } from "zustand";
import { AssetStore, AssetDeviceStore } from "./types";

export const useAssetStore = create<AssetStore>()(set => ({
	...CoreStoreBuilder(set),
}));

export const useAssetDevicesStore = create<AssetDeviceStore>()(set => ({
	...CoreStoreBuilder(set),
}));

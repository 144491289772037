export interface UserGroupsColumnRenderProps {
	user: UserGroup;
	viewOnly?: boolean;
}

export enum HostAndUserGroupStatusState {
	Active = "active",
	ActivePending = "active-pending",
	InActive = "inactive",
	InActivePending = "inactive-pending",
}

export enum UserGroupStatusName {
	ACTIVE = "Active",
	INACTIVE = "Inactive",
	ACTIVATING = "Activating",
	DEACTIVATING = "Inactivating",
}

export interface UserGroup {
	groupID: string;
	groupName: string;
	users: number;
	userGroupStatus: HostAndUserGroupStatusState;
	templatesAssigned: number;
	unreviewedPaths: number;
	totalPaths: number;
	namednetworksAssigned: number;
	idpID: string;
	idpName: string;
	endpoints: number;
}

import { authAxiosInstance } from "hooks/useAuthState/axios";
import { API_URL } from "hooks/useNetworking/constants";
import "navigator.locks";
import { useCallback, useEffect, useRef, useState } from "react";
import { AuthState } from "./constants";

export function useAuthState() {
	const [authState, setAuthState] = useState(AuthState.Unknown);
	const currentLoginTask = useRef<Promise<AuthState> | null>(null);
	const currentLogoutTask = useRef<Promise<any> | null>(null);

	const checkAuthState = useCallback(async () => {
		try {
			if (!currentLoginTask.current) {
				currentLoginTask.current = navigator.locks.request(
					"auth_login_req",
					{ mode: "exclusive" },
					async () => {
						await authAxiosInstance.get(`${API_URL}/auth/token`);
						setAuthState(AuthState.LoggedIn);
					}
				);
			}
			await currentLoginTask.current;
			currentLoginTask.current = null;
			return AuthState.LoggedIn;
		} catch (e: any) {
			currentLoginTask.current = null;
			if (e.response.status === 401 || e.response.status === 403) {
				setAuthState(AuthState.LoggedOut);
				return AuthState.LoggedOut;
			} else {
				setAuthState(AuthState.Unknown);
				throw e;
			}
		}
	}, []);

	const logout = useCallback(async () => {
		try {
			if (!currentLogoutTask.current) {
				currentLogoutTask.current = authAxiosInstance.post(
					`${API_URL}/auth/user/logout`
				);
			}
			let response = await currentLogoutTask.current;
			window.sessionStorage.clear();
			window.localStorage.clear();
			setAuthState(AuthState.LoggedOut);
			currentLogoutTask.current = null;
			if (response?.data?.ssoLogoutURL) {
				window.location.href = response?.data?.ssoLogoutURL;
			}
			return AuthState.LoggedOut;
		} catch (e: any) {
			currentLogoutTask.current = null;
			if (e.response.status === 401 || e.response.status === 403) {
				setAuthState(AuthState.LoggedOut);
				window.sessionStorage.clear();
				window.localStorage.clear();
				return AuthState.LoggedOut;
			} else {
				setAuthState(AuthState.Unknown);
				throw e;
			}
		}
	}, []);

	useEffect(() => {
		checkAuthState();
	}, [checkAuthState]);

	return {
		authState,
		checkAuthState,
		logout,
	};
}

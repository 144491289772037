import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
	BASE_TAG_POLICY_COLS,
	TAG_POLICY_COLS,
	tabBasedPolicyColumnConfig,
} from "../constants";
import { useTagPolicyStore } from "../store";
import { TagPolicy } from "../types";
import { TagPolicyToolbar } from "./tag-policy-data-grid-toolbar";

export const TagPolicyDataGrid = (props: DataGridProps<TagPolicy>) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const setSelectedCriteria = useTagPolicyStore(
		state => state.setSelectedCriteria
	);
	const selectedCriteria = useTagPolicyStore(state => state.selectedCriteria);
	const [selection, setSelection] = useState<GridRowSelectionModel>([]);
	const { isFeatureEnabled } = useFeatureFlagControl(FEATURES.PROGRESSIVE);
	const isProgressiveEnabled = isFeatureEnabled;

	useEffect(() => {
		if (selectedCriteria.length === 0) {
			setSelection([]);
		}
	}, [selectedCriteria]);

	const columns = useMemo(() => {
		let columns = userPermissions.has("DELETE_TAGBASEDPOLICY")
			? TAG_POLICY_COLS()
			: BASE_TAG_POLICY_COLS();
		return columns.filter(col => {
			let include = !props.hiddenColumns?.includes(col.field);

			if (
				!isProgressiveEnabled &&
				col.field === "policyAutomation-progressivestatus"
			) {
				include = false;
			}

			return include;
		});
	}, [userPermissions, props.hiddenColumns, isProgressiveEnabled]);

	const selectedRawData: Array<TagPolicy> | undefined = useMemo(() => {
		return (props?.rawData ?? [])?.filter((row: TagPolicy) => {
			return selection.indexOf(row?.tagBasedPolicyId) !== -1;
		});
	}, [selection, props?.rawData]);
	const onClickClearSelection = () => {
		setSelection([]);
	};

	const selectedData: Array<TagPolicy> | undefined = useMemo(() => {
		return props.rows?.filter((row: TagPolicy) => {
			return selection.indexOf(row?.tagBasedPolicyId) !== -1;
		});
	}, [selection, props.rows]);

	const renderToolbar = useCallback(
		(children?: React.ReactNode) => (
			<TagPolicyToolbar
				show={true}
				hideToolbar={onClickClearSelection}
				selection={selectedData}
				hiddenColumns={children}
			/>
		),
		[selectedData]
	);

	return (
		<Stack
			sx={{
				width: "100%",
				height: "100%",
				"& .policyAutomationHeader .MuiDataGrid-columnHeaderTitleContainerContent":
					{
						flex: 1,
					},
			}}
		>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					initialState={{
						sorting: {
							sortModel: [{ field: "name", sort: "asc" }],
						},
					}}
					checkboxSelection={userPermissions.has("CREATE_TAGBASEDPOLICY")}
					defaultPinnedColumns={tabBasedPolicyColumnConfig.pinnedColumns}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						setSelection(selectionModel);
						setSelectedCriteria(
							props?.rows
								?.filter(row => selectionModel.includes(row.tagBasedPolicyId))
								.map(row => row.criteria ?? "") ?? []
						);
					}}
					slots={{
						noRowsOverlay: NoRowsOverlayComp,
						...props.slots,
						loadingOverlay: LinearProgress,
					}}
					rowHeight={64}
					columns={columns}
					pagination={true}
					getRowId={({ tagBasedPolicyId: id }: TagPolicy) => `${id}`}
					paginationMode="server"
					sortingMode="server"
					{...props}
					selectedRawData={selectedRawData}
					renderToolbar={renderToolbar}
				/>
			</Box>
		</Stack>
	);
};

const NoRowsOverlayComp = (props: DataGridProps<TagPolicy>) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	return (
		<Stack
			alignItems={"center"}
			justifyContent="center"
			sx={{
				zIndex: 100,
				width: "100%",
				height: "100%",
				position: "relative",
			}}
		>
			<Typography variant="body2">
				{window.getCTTranslatedText("No results")}
			</Typography>
			{userPermissions.has("CREATE_TAGBASEDPOLICY") && props.hasNoData && (
				<Link to={"/segment/create"}>
					<Button>{window.getCTTranslatedText("CreateSegment")}</Button>
				</Link>
			)}
		</Stack>
	);
};

import AssignmentIcon from "@mui/icons-material/Assignment";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import ForkRightOutlinedIcon from "@mui/icons-material/ForkRightOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import RememberMeOutlinedIcon from "@mui/icons-material/RememberMeOutlined";
import RouterOutlinedIcon from "@mui/icons-material/RouterOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ClusterOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import WifiTetheringOutlinedIcon from "@mui/icons-material/WifiTetheringOutlined";
import { FacetGroups } from "modules/facets/types";

interface GroupInfoProps {
	[key: string]: any;
}

export const GroupInfo: GroupInfoProps = {
	[FacetGroups.Tags]: {
		icon: <SellOutlinedIcon fontSize="medium" />,
		order: 1,
	},
	[FacetGroups.Security]: {
		icon: <LockOutlinedIcon fontSize="medium" />,
		order: 2,
	},
	[FacetGroups.PolicyAutomation]: {
		icon: <SettingsOutlinedIcon fontSize="medium" />,
		order: 3,
	},
	[FacetGroups.Assets]: {
		icon: <ListAltOutlinedIcon fontSize="medium" />,
		order: 3,
	},
	[FacetGroups.Ports]: {
		icon: null,
		order: 4,
		isCustomIcon: true,
	},
	[FacetGroups.Paths]: {
		icon: <ForkRightOutlinedIcon fontSize="medium" />,
		order: 5,
	},
	[FacetGroups.Firewall]: {
		icon: <SecurityOutlinedIcon fontSize="medium" />,
		order: 6,
	},
	[FacetGroups.Templates]: {
		icon: null,
		order: 7,
		isCustomIcon: true,
	},
	[FacetGroups.Network]: {
		icon: <DeviceHubOutlinedIcon fontSize="medium" />,
		order: 8,
	},
	[FacetGroups.Gatekeepers]: {
		icon: <RouterOutlinedIcon fontSize="medium" />,
		order: 9,
	},
	[FacetGroups.Agents]: {
		icon: <WifiTetheringOutlinedIcon fontSize="medium" />,
		order: 10,
	},
	[FacetGroups.AuditLog]: {
		icon: <EventNoteOutlinedIcon fontSize="medium" />,
		order: 11,
	},
	[FacetGroups.Alert]: {
		icon: <EventNoteOutlinedIcon fontSize="medium" />,
		order: 12,
	},
	[FacetGroups.Containers]: {
		icon: <ClusterOutlinedIcon fontSize="medium" />,
		order: 13,
	},
	[FacetGroups.Connectors]: {
		icon: <DeviceHubOutlinedIcon fontSize="medium" />,
		order: 14,
	},
	[FacetGroups.Users]: {
		icon: <PersonOutlinedIcon fontSize="medium" />,
		order: 15,
	},
	[FacetGroups.Groups]: {
		icon: <GroupsOutlinedIcon fontSize="medium" />,
		order: 16,
	},
	[FacetGroups.Others]: {
		icon: <MoreHorizOutlinedIcon fontSize="medium" />,
		order: 17,
	},
	[FacetGroups.Custom]: {
		icon: <SellOutlinedIcon fontSize="medium" />,
		order: 18,
	},
	[FacetGroups.WorkRequest]: {
		icon: <AssignmentIcon fontSize="medium" />,
		order: 19,
	},
	[FacetGroups.Devices]: {
		icon: <DevicesOutlinedIcon fontSize="medium" />,
		order: 20,
	},
	[FacetGroups.Reports]: {
		icon: <DescriptionOutlinedIcon fontSize="medium" />,
		order: 21,
	},
	[FacetGroups.IdP]: {
		icon: <RememberMeOutlinedIcon fontSize="medium" />,
		order: 22,
	},
};

import { StackedTrendChart } from "@colortokens/ng-ui";
import { Stack, useTheme } from "@mui/material";
import { ParentSize } from "@visx/responsive";
import { getColor } from "common/constants/colors";
import { DisplayTextMap } from "common/constants/displayTextMap";
import { BarChart, BarGroupChart, BarStackChart } from "modules/charts";
import { BarChartDatum } from "modules/charts/bar-chart/BarChart";
import { ChartDatum } from "modules/charts/bar-group/BarGroupChart";
import React from "react";

export interface ChartData {
	data: Array<ChartDatum> | undefined;
}
export interface SecurityProgressGraphsType {
	attackSurface: ChartData;
	attackSurfaceTrend: ChartData;
	blastRadius: ChartData;
	blastRadiusTrend: ChartData;
}

export interface ReviewProgress {
	reviewed: number;
	total: number;
}

export interface SecurityProgressProps extends SecurityProgressGraphsType {
	chartType: string;
}

const LABELS: { [key: string]: string } = {
	ports: "Ports Reviewed",
	paths: "Paths Reviewed",
	assets: "Assets Secured",
};

export function getLabel(key: string) {
	return DisplayTextMap[key] || LABELS[key];
}

const linePosition = {
	x1: "0",
	y1: "12",
	x2: "100",
	y2: "12",
};

interface BarChartStackGraphProps {
	title: string;
	preChartComponent?: React.ReactNode;
	data: Array<ChartDatum> | undefined;
	dataPoint?: string;
	lineChartData?: Array<ChartDatum> | undefined;
	lineChartDataPoint?: string;
	priorityMap: { [key: string]: number };
	category?: string;
	showAllLabels?: boolean;
	total?: number;
	isDialogWidget?: boolean;
	tooltipTitle?: string;
}

export function BarStackChartGraph(props: BarChartStackGraphProps) {
	const theme = useTheme();

	const strokeStyles = [
		{
			stroke: theme.palette.text.primary,
			strokeWidth: 3,
		},
	];

	const chartLabel = `${window.getCTTranslatedText("BarStackChartLabel", {
		lineChartDataPoint: window.getCTTranslatedText(
			props.lineChartDataPoint ?? ""
		),
	})}`;

	return (
		<StackedTrendChart
			{...props}
			strokeStyles={strokeStyles}
			linePosition={linePosition}
			chartLabel={chartLabel}
			renderChart={({ width, height, ...props }) => (
				<BarStackChart width={width} height={height} {...props} />
			)}
		/>
	);
}

interface BarGroupChartProps {
	title: string;
	preChartComponent?: React.ReactNode;
	data: Array<ChartDatum> | undefined;
	category?: string;
	total?: number;
}

export function BarGroupChartGraph({
	title,
	preChartComponent,
	data,
	total,
	category,
}: BarGroupChartProps) {
	return (
		<Stack
			spacing={0}
			sx={{ minWidth: 0, minHeight: 0, width: "100%", height: "100%" }}
		>
			<div
				style={{
					flex: 1,
					minWidth: 0,
					minHeight: 0,
				}}
			>
				<ParentSize>
					{parent => (
						<BarGroupChart
							data={data}
							width={parent.width}
							height={parent.height}
							getLabel={getLabel}
							getColor={getColor}
							category={category}
							total={total}
						/>
					)}
				</ParentSize>
			</div>
		</Stack>
	);
}

export interface BarChartGraphProps {
	title: string;
	preChartComponent?: React.ReactNode;
	data: BarChartDatum | undefined;
}

export function BarChartGraph({
	title,
	preChartComponent,
	data,
}: BarChartGraphProps) {
	return (
		<Stack
			spacing={0}
			sx={{ minWidth: 0, minHeight: 0, width: "100%", height: "100%" }}
		>
			<div
				style={{
					flex: 1,
					minWidth: 0,
					minHeight: 0,
				}}
			>
				<ParentSize>
					{parent => (
						<BarChart
							data={data}
							width={parent.width}
							height={parent.height}
							getLabel={getLabel}
							getColor={getColor}
						/>
					)}
				</ParentSize>
			</div>
		</Stack>
	);
}

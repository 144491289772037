import { Box } from "@mui/material";
import { ApplianceFormDrawer } from "pages/appliances/components/appliance-form-drawer";
import { useApplianceStore } from "pages/appliances/store";
import {
	ApplianceColumnRenderProps,
	ApplianceDrawerModes,
} from "pages/appliances/types";

export function RecoverApplianceDrawer({
	agent,
	isOpen,
	onClose,
}: Readonly<ApplianceColumnRenderProps>) {
	const requestAPIRefresh = useApplianceStore(state => state.requestAPIRefresh);

	const updateApplianceData = () => {
		requestAPIRefresh();
	};

	if (!agent?.agentId) {
		return <Box style={{ width: 100 }} />;
	}

	return (
		<Box>
			{isOpen && (
				<ApplianceFormDrawer
					appliance={agent}
					isOpen={isOpen}
					onClose={onClose}
					updateApplianceData={updateApplianceData}
					title={window.getCTTranslatedText("Recover gatekeeper")}
					btnTitle={window.getCTTranslatedText("recover")}
					mode={ApplianceDrawerModes.Recover}
				></ApplianceFormDrawer>
			)}
		</Box>
	);
}

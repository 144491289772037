import { Box, Stack, Tooltip } from "@mui/material";
import { ApplianceColumnRenderProps } from "pages/appliances/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function AgentLocation({ agent, viewOnly }: ApplianceColumnRenderProps) {
	if (!agent?.agentId) {
		return <Box style={{ minWidth: 120 }} />;
	}
	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
			<Tooltip title={agent?.agentLocation ?? ""}>
				<CTDatagridButton
					asText={viewOnly === true}
					isDisabled={viewOnly === true}
					facetName={"agentlocation"}
					facetOption={agent.agentLocation ?? ""}
				>
					{agent?.agentLocation || ""}
				</CTDatagridButton>
			</Tooltip>
		</Stack>
	);
}

import { VersionInfo } from "../type";

export function RenderDownloadLink(data: VersionInfo[], agentVersion: string) {
	return data.find(versionInfo => versionInfo?.version === agentVersion)
		?.downloadLink;
}

export function RenderDownloadFileName(
	data: VersionInfo[],
	agentVersion: string
) {
	return data.find(versionInfo => versionInfo?.version === agentVersion)
		?.fileName;
}

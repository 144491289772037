import { debounce } from "@mui/material";
import { useCommonStore } from "common/store";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { DashboardWidget } from "@colortokens/ng-ui";
import { RiskGaugeChart } from "pages/dashboard/components/risk-gauge-chart";
import { useEffect, useMemo, useState } from "react";
import {
	avgDataMapper,
	barChartRequestBody,
} from "../security-progress/helpers";
import { useTimeSeriesAPI } from "../security-progress/hooks";
import { RiskTrendChartDialog } from "./components/risk-trend-chart-dialog";

export const RiskChartWrapper = () => {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const searchCriteria = useCommonStore(state => state.currentSearchCriteria);

	const [avgRiskValue, setAvgRiskValue] = useState<number | undefined>(
		undefined
	);

	const keyMetricsAvgMutation = useTimeSeriesAPI();

	const keyMetricsAvgMutationObj = useMemo(
		() => debounce(keyMetricsAvgMutation.mutate, 500),
		[keyMetricsAvgMutation.mutate]
	);

	useEffect(() => {
		let criteria = searchCriteria ?? "*";

		const requestObj = barChartRequestBody({
			criteria,
			scope: Scope.Asset,
			groupBy: [],
			statistics: ["mean(assetriskscore)"],
		});

		keyMetricsAvgMutationObj(requestObj, {
			onSuccess(data) {
				if (data) {
					const responseData = data;
					const items = responseData?.items ?? {};
					const avgRiskVal = avgDataMapper({
						items,
						statisticsId: "assetriskscoremean",
					});
					setAvgRiskValue(avgRiskVal ?? 0);
				}
			},
			onError: error => {
				setAvgRiskValue(0);
				setSnackbar(true, SnackBarSeverity.Error, "FailedToFetchBreachImpact");
			},
		});
	}, [keyMetricsAvgMutationObj, searchCriteria, setSnackbar]);

	return (
		<DashboardWidget
			title={`${window.getCTTranslatedText("AssetBreachImpact")}`}
			isLoading={keyMetricsAvgMutation?.isLoading}
			children={
				keyMetricsAvgMutation?.isLoading ||
				avgRiskValue === undefined ? null : (
					<RiskGaugeChart value={avgRiskValue} />
				)
			}
			showTrend={true}
			trendComponent={<RiskTrendChartDialog />}
		/>
	);
};

import { Tooltip, Typography } from "@mui/material";
import { AlertsColumnRenderProps } from "pages/monitoring/types";

export const AlertCount = ({ alert, viewOnly }: AlertsColumnRenderProps) => {
	return (
		<Tooltip title={alert.count ?? ""}>
			<Typography variant="body2" noWrap>
				{alert.count}
			</Typography>
		</Tooltip>
	);
};

import { Box, Stack, Typography } from "@mui/material";
import { NnHitColumnRenderProps } from "../../types";

export function NNHitLastObserved({ nnHit }: NnHitColumnRenderProps) {
	if (!nnHit.lastHitObserved) {
		return <Box style={{ width: 70 }} />;
	}

	return (
		<Box style={{ width: "fit-content" }}>
			<Stack justifyContent="center">
				<Typography noWrap variant="body2">
					{nnHit.lastHitObserved}
				</Typography>
			</Stack>
		</Box>
	);
}

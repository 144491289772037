import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
	Button,
	ButtonGroup,
	ClickAwayListener,
	Divider,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Stack,
	Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { AlertNotificationStatus } from "../../types";

interface IUpdateActionsProps {
	notificationStatusDisabled: boolean;
	handleAlertRuleButtonChange: (btnActionType: AlertNotificationStatus) => void;
	handleNotificaitonStatusButtonChange: (
		btnActionType: AlertNotificationStatus
	) => void;
}

export const AlertConfigToolbarAction = ({
	notificationStatusDisabled,
	handleAlertRuleButtonChange,
	handleNotificaitonStatusButtonChange,
}: IUpdateActionsProps) => {
	const alertRuleStatusAnchorRef = useRef<HTMLDivElement>(null);
	const notificationStatusAnchorRef = useRef<HTMLDivElement>(null);
	const [openAlertRuleStatusMenu, setOpenAlertRuleStatusMenu] = useState(false);
	const [openNotificationStatusMenu, setOpenNotificationStatusMenu] =
		useState(false);
	const AlertRuleStatusOptionList = [
		AlertNotificationStatus.Enabled,
		AlertNotificationStatus.Disabled,
	];
	const NotificationStatusOptionList = [
		AlertNotificationStatus.On,
		AlertNotificationStatus.Off,
	];
	const handleMenuToggle = (
		ref: React.RefObject<HTMLDivElement> | undefined,
		status?: boolean
	) => {
		if (ref) {
			if (status) {
				setOpenNotificationStatusMenu(false);
				setOpenAlertRuleStatusMenu(prevOpen => !prevOpen);
			} else {
				setOpenAlertRuleStatusMenu(false);
				setOpenNotificationStatusMenu(prevOpen => !prevOpen);
			}
		}
	};

	const handleMenuClose = (event: Event) => {
		if (
			alertRuleStatusAnchorRef?.current?.contains(
				event.target as HTMLElement
			) ||
			notificationStatusAnchorRef?.current?.contains(
				event.target as HTMLElement
			)
		) {
			return;
		}
		setOpenAlertRuleStatusMenu(false);
		setOpenNotificationStatusMenu(false);
	};
	const handleAlertRuleStatusChange = (
		btnActionType: AlertNotificationStatus
	) => {
		setOpenAlertRuleStatusMenu(false);
		handleAlertRuleButtonChange(btnActionType);
	};
	const handleNotificationStatusChange = (
		btnActionType: AlertNotificationStatus
	) => {
		setOpenNotificationStatusMenu(false);
		handleNotificaitonStatusButtonChange(btnActionType);
	};
	const popperMenuContent = (
		id: string,
		options: string[],
		handleSelection: (option: AlertNotificationStatus) => void
	) => {
		return (
			<Paper>
				<ClickAwayListener onClickAway={handleMenuClose}>
					<MenuList id={id} autoFocusItem>
						{options.map((option, index, options) => (
							<Stack key={option} sx={{ mx: 2 }}>
								<MenuItem
									key={option}
									onClick={() =>
										handleSelection(option as AlertNotificationStatus)
									}
								>
									<Stack
										alignItems="flex-start"
										justifyContent="flex-start"
										justifyItems="flex-start"
										sx={{ width: "100%" }}
									>
										<Typography
											variant="body2"
											sx={{
												textAlign: "left",
											}}
										>
											{window.getCTTranslatedText(option)}
										</Typography>
									</Stack>
								</MenuItem>
								{index < options.length - 1 && <Divider />}
							</Stack>
						))}
					</MenuList>
				</ClickAwayListener>
			</Paper>
		);
	};

	return (
		<Stack
			direction="row"
			spacing={2}
			alignItems={"center"}
			sx={{ height: "100%" }}
		>
			<ButtonGroup
				variant="outlined"
				ref={alertRuleStatusAnchorRef}
				aria-label="split button"
			>
				<Button
					aria-controls={
						openAlertRuleStatusMenu ? "user-group-status-menu" : undefined
					}
					aria-expanded={openAlertRuleStatusMenu ? "true" : undefined}
					aria-label="Change Status"
					aria-haspopup="menu"
					onClick={() => handleMenuToggle(alertRuleStatusAnchorRef, true)}
					endIcon={<ArrowDropDownIcon />}
				>
					{window.getCTTranslatedText("changeAlertStatus")}
				</Button>
			</ButtonGroup>
			<ButtonGroup
				variant="outlined"
				ref={notificationStatusAnchorRef}
				aria-label="split button"
			>
				<Button
					aria-controls={
						openNotificationStatusMenu ? "notification-status-menu" : undefined
					}
					aria-expanded={openNotificationStatusMenu ? "true" : undefined}
					aria-label="Change Status"
					aria-haspopup="menu"
					disabled={notificationStatusDisabled}
					onClick={() => handleMenuToggle(notificationStatusAnchorRef)}
					endIcon={<ArrowDropDownIcon />}
				>
					{window.getCTTranslatedText("changeNotificationStatus")}
				</Button>
			</ButtonGroup>
			<Popper
				key="alert-config-status-menu"
				sx={{
					zIndex: 1,
					minWidth: "180px",
				}}
				open={openAlertRuleStatusMenu}
				anchorEl={alertRuleStatusAnchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom" ? "center top" : "center bottom",
						}}
					>
						{popperMenuContent(
							"alert-config-status-menu",
							AlertRuleStatusOptionList,
							handleAlertRuleStatusChange
						)}
					</Grow>
				)}
			</Popper>
			<Popper
				key="alert-config-status-menu"
				sx={{
					zIndex: 1,
					minWidth: "230px",
				}}
				open={openNotificationStatusMenu}
				anchorEl={notificationStatusAnchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom" ? "center top" : "center bottom",
						}}
					>
						{popperMenuContent(
							"notification-status-menu",
							NotificationStatusOptionList,
							handleNotificationStatusChange
						)}
					</Grow>
				)}
			</Popper>
		</Stack>
	);
};

import { Box, Link, TextField, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { API_URL } from "hooks/useNetworking/constants";
import React, { useMemo, useState } from "react";
import { Navigate, Link as RouterLink } from "react-router-dom";
import { InviteConfig } from "./types";
import { AuthForm, HiddenForm } from "modules/auth-form";
import { isValidEmail } from "common/utils/isEmailValid";

// TODO: cleanup separation of tenant and user invite
export function Invite() {
	const [agreement, setAgreement] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showRedirectMessage, setShowRedirectMessage] = useState(false);

	const handleSetAgreement = () => setAgreement(!agreement);

	const search = window.location.search;
	let invite: InviteConfig = useMemo(() => {
		let params = new URLSearchParams(search);
		if (params.has("invite")) {
			let inviteString = window.atob(params.get("invite") || "");
			try {
				return JSON.parse(inviteString);
			} catch (e) {
				return null;
			}
		} else {
			return null;
		}
	}, [search]);

	const createAccount = (e: React.FormEvent<HTMLFormElement>) => {
		setLoading(true);
	};

	if (!invite) {
		return <Navigate to="/auth/login" replace={true} />;
	}

	const visibleForm = () => {
		return (
			<>
				<Box sx={{ mb: 3 }}>
					<Typography variant="h6">
						{window.getCTTranslatedText("Hello")} {invite.name}!
					</Typography>

					{!invite.tenantID ? (
						<Typography variant="subtitle1">
							{window.getCTTranslatedText("LetsSetupTenantAccount", {
								tenantName: invite.tenantName,
							})}
						</Typography>
					) : (
						<Typography variant="subtitle1">
							{window.getCTTranslatedText("You have been invited to join")}{" "}
							{invite.tenantName}
						</Typography>
					)}
				</Box>

				<TextField
					type="email"
					label={window.getCTTranslatedText("Email")}
					name="email"
					variant="standard"
					fullWidth
					value={invite.email}
					InputProps={{
						readOnly: true,
					}}
				/>
				<input
					style={{ display: "none" }}
					readOnly={true}
					name="inviteCode"
					value={invite.inviteCode}
				/>
				{invite.tenantID ? (
					<input
						style={{ display: "none" }}
						readOnly={true}
						name="tenantID"
						value={invite.tenantID}
					/>
				) : null}
				<Box display="flex" alignItems="center">
					<Checkbox
						sx={{ p: 0, mr: 2 }}
						required={true}
						checked={agreement}
						onChange={handleSetAgreement}
					/>
					<Typography
						onClick={handleSetAgreement}
						sx={{ cursor: "pointer", userSelect: "none" }}
					>
						{window.getCTTranslatedText("I accept the")}&nbsp;
					</Typography>
					<Link
						target="_blank"
						href={`https://colortokens.com/terms-of-service`}
						underline="hover"
					>
						{window.getCTTranslatedText("Terms and conditions")}
					</Link>
				</Box>
			</>
		);
	};

	const hiddenForm = () => {
		return <HiddenForm email={invite.email} loading={showRedirectMessage} />;
	};

	return (
		<AuthForm
			buttonAction="Accept Invitation"
			action={
				!invite.tenantID ? `${API_URL}/auth/tenant` : `${API_URL}/auth/user`
			}
			onSubmit={createAccount}
			visibleForm={visibleForm()}
			hiddenForm={hiddenForm()}
			loading={loading}
			setLoading={setLoading}
			authConfiguration={{
				email: invite.email,
				tenantID: invite.tenantID,
				inviteCode: invite.inviteCode,
			}}
			setShowRedirectMessage={setShowRedirectMessage}
			validateForm={() => {
				return isValidEmail(invite.email) && agreement;
			}}
		>
			<>
				{!invite.tenantID && (
					<Box mt={3} textAlign="center">
						<Typography color="text">
							{window.getCTTranslatedText("existingAccountPrompt")}&nbsp;
							<Link component={RouterLink} to={`/auth/login`} underline="hover">
								{window.getCTTranslatedText("Sign In")}
							</Link>
						</Typography>
					</Box>
				)}
			</>
		</AuthForm>
	);
}

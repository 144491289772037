import { Option } from "pages/templates/components/template-form-drawer/components/ct-multi-select/CTMultiSelectDropDown";
import { TeamsChannel } from "../../../Integration/constants";

export interface NotificationFormProps {
	recipients: NotificationFormInt;
	index: number;
	updateNotificationData: Function;
	showLabel?: boolean;
}

export interface TeamsNotificationFormProps {
	selectedChannels: TeamsChannel[];
	setSelectedChannels: (selectedChannels: TeamsChannel[]) => void;
	teamsChannels: TeamsChannel[];
	showLabel?: boolean;
}

export enum FilterFormKeys {
	FilterCriteria = "criteria",
	AlertRuleName = "alertrulename",
}
export const FilterFormKeysDisplayText = {
	[FilterFormKeys.FilterCriteria]: "Filter Criteria",
	[FilterFormKeys.AlertRuleName]: "Alert Rule Name",
};
export interface FilterFormInt {
	criteria: string;
	alertrulename: string[];
}
export interface FilterFormProps {
	filter: FilterFormInt;
	alertRules?: string[];
	updateFilterData: Function;
	filterData: Option[];
	showLabel?: boolean;
}
export const defaultFilterObj: FilterFormInt = {
	[FilterFormKeys.FilterCriteria]: "alertrulename",
	[FilterFormKeys.AlertRuleName]: [],
};

export type RecipientType = {
	[key: string]: any;
};

export type RecipientsType = {
	[key: string]: RecipientType;
};

export type ActionType = {
	action: string;
	whomtonotify: string;
	recipients: string[];
};

export interface NotificationFormInt {
	identifier?: string;
	action: NotificationActionEnum;
	whomtonotify: string;
	recipients: string[] | TeamsChannel[];
}
export enum NotificationFormKeys {
	Action = "action",
	WhomtoNotify = "whomtonotify",
	Recipients = "recipients",
}

export const NotificationFormKeysDisplayText = {
	[NotificationFormKeys.Action]: "Action",
	[NotificationFormKeys.WhomtoNotify]: "Whom To Notify",
	[NotificationFormKeys.Recipients]: "Guest Email(s)",
};

export enum NotificationActionEnum {
	Emails = "emails",
	Slack = "slack",
	TeamChannels = "teamsChannels",
}

export const defaultNotificationObj: NotificationFormInt = {
	[NotificationFormKeys.Action]: NotificationActionEnum.Emails,
	[NotificationFormKeys.WhomtoNotify]: "guest",
	[NotificationFormKeys.Recipients]: [],
};
export const defaultTeamsNotificationObj: NotificationFormInt = {
	[NotificationFormKeys.Action]: NotificationActionEnum.TeamChannels,
	[NotificationFormKeys.WhomtoNotify]: "Teams Channels",
	[NotificationFormKeys.Recipients]: [],
};

export enum NotificationActionLabelsEnums {
	Emails = "emailGuestUsers",
	Slack = "slackChannelName",
}

export enum NotificationChannelsEnum {
	Guest = "guest",
	TenantUsers = "users",
}

export enum NotificationChannelsLabelEnum {
	Guest = "Guest",
	TenantUsers = "Tenant Users",
}

export enum TeamsNotificationActionLabelsEnums {
	Channels = "Message on Teams",
}
export enum TeamsNotificationActionEnum {
	Channels = "channels",
}

export const TeamsNotificationFormKeysDisplayText = {
	[NotificationFormKeys.Action]: "Action",
	[NotificationFormKeys.Recipients]: "Message on teams",
};

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { parseErrorMessage } from "common/utils";
import { CloneDataGridRowActionProps } from "modules/clone-datagrid-row/CloneDataGridRowAction";
import { FormFields } from "modules/form-fields";
import { TextFieldsValues } from "modules/form-fields/type";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useMemo, useState } from "react";

interface ConfirmationDialogProps extends CloneDataGridRowActionProps {
	onClose: VoidFunction;
}

export function CloneRowFormDialog({
	onClose,
	useAPI,
	generateBody,
	refreshAPI,
	title,
}: ConfirmationDialogProps) {
	const [textFieldsValues, setTextFieldsValues] = useState<TextFieldsValues>(
		{}
	);
	const useCloneAPIMutation = useAPI();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const formConfig = useMemo(() => {
		const body = generateBody();
		setTextFieldsValues(body);

		const fields = Object.entries(body ?? {}).map(item => ({
			displayName: formatString(item[0]) ?? "",
			key: item[0],
			type: "string",
			defaultValue: item[1],
		}));

		const filteredFields = fields.filter(
			field => !field.key.toLowerCase().includes("id")
		);

		return filteredFields;
	}, [generateBody]);

	const handlerCloneRow = async () => {
		const data = generateBody();
		const body = { ...data, ...textFieldsValues };

		useCloneAPIMutation.mutateAsync(body, {
			onSuccess: response => {
				setSnackbar(true, SnackBarSeverity.Success, "ClonedSuccessfully");

				refreshAPI();
				onClose();
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const drawerToolbar = () => (
		<Stack direction="row" justifyContent="flex-end" p={1}>
			<Button variant="outlined" color="primary" onClick={onClose}>
				{window.getCTTranslatedText("CANCEL")}
			</Button>
			<LoadingButton
				sx={{ ml: 1 }}
				variant="contained"
				color="primary"
				autoFocus
				type="submit"
				onClick={handlerCloneRow}
				loading={useCloneAPIMutation.isLoading}
			>
				{window.getCTTranslatedText("Create copy")}
			</LoadingButton>
		</Stack>
	);

	return (
		<FormFields
			textFieldsValues={textFieldsValues}
			setTextFieldsValues={setTextFieldsValues}
			formConfig={formConfig ?? []}
			dialogTitle={title}
			key={"name"}
			isDrawer={true}
			onClose={onClose}
			drawerToolbar={drawerToolbar}
			isSortable={false}
		/>
	);
}

function formatString(str: string) {
	const words = str.split(/(?=[A-Z])/);
	return words.pop();
}

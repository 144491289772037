import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	DialogActions,
	DialogContent,
	DialogTitle,
	Drawer,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Toolbar } from "common/atoms/toolbar";
import { MoreOptionsMenu } from "common/molecules/more-options-menu";
import { parseErrorMessage } from "common/utils";
import {
	base64ToText,
	curlProxyDecodeURIComponent,
	curlProxyEncodeURIComponent,
	textToBase64,
} from "common/utils/textEncoding";
import uniqBy from "lodash/uniqBy";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { Appliance, ApplianceFormProps } from "pages/appliances/types";
import { getPendingActionsTooltip } from "pages/appliances/utils";
import React, { useCallback, useEffect, useState } from "react";
import {
	useApplianceConfigExportAPI,
	useApplianceConfigGetAPI,
	useApplianceConfigPutAPI,
	useEditApplianceAPI,
} from "../../hooks";
import {
	Advanced,
	ConfigStatus,
	General,
	HighAvailability,
	Logs,
	PeerAppliances,
	ProtectedNetworkInterface,
	WAN,
} from "./components";
import {
	ApplianceConfig,
	ApplianceConfigPayload,
	AsymmetricModeOptions,
	AuthModeOptions,
	AuthModeValues,
	AuthenticationOptions,
	BooleanFlags,
	ConfigStatusMap,
	DHCPConfig,
	DHCPModes,
	DHCPOptions,
	DeviceIdentifierOptions,
	DiscoveryModeOptions,
	FailOpenOptions,
	HAModes,
	IPRangeInt,
	NetworkInterfaceConfig,
	NetworkInterfaces,
	OptInOptions,
	PeerDetails,
	ProxyOptions,
	ProxySettings,
	VLAN,
	VLANConfig,
	VRRPConfig,
} from "./types";
import {
	SToMin,
	exportConfig,
	getDefaultDNSServers,
	hasPrimaryAppliance,
	isConfigInvalid,
	isConfigurable,
	mapDNSServers,
	minToS,
} from "./utils";

const Defaults = {
	LeaseDuration: "120",
	RouterId: "57",
	WanMTU: "1500",
	LanMTU: "1500",
	OptIn: OptInOptions.Yes,
	Proxy: ProxyOptions.No,
	ProxyAuth: AuthenticationOptions.No,
	ProxyProtocol: "http",
	LoggingLevel: "info",
	AuthMode: AuthModeOptions[1],
	DiscoveryMode: DiscoveryModeOptions[0],
	AsymmetricMode: AsymmetricModeOptions.No,
	FailOpen: FailOpenOptions.No,
	DeviceIdentifier: DeviceIdentifierOptions[0],
};

export interface ApplianceEditFormProps {
	appliance?: Appliance;
	updateApplianceData?: Function;
	isOpen?: boolean;
	title?: string;
	cancel?: () => void;
	btnTitle?: string;
}

export function ApplianceConfigForm({
	appliance,
	title,
	updateApplianceData,
	isOpen,
	cancel,
}: ApplianceEditFormProps) {
	const [applianceName, setApplianceName] = useState<string | undefined>(
		appliance?.agentName
	);
	const [applianceLocation, setApplianceLocation] = useState<
		string | undefined
	>(appliance?.agentLocation);
	const [peerAppliances, setPeerAppliances] = useState<Array<string>>([]);
	const [DHCPMode, setDHCPMode] = useState(DHCPModes.Server);
	const [DHCPOption, setDHCPOption] = useState(DHCPOptions.Static);
	const [deviceIdentifier, setDeviceIdentifier] = useState(
		Defaults.DeviceIdentifier
	);
	const [HAMode, setHAMode] = useState(HAModes.StandAlone);
	const [networkInterface, setNetworkInterface] = useState(
		NetworkInterfaces.LAN
	);
	const [leaseDuration, setLeaseDuration] = useState(Defaults.LeaseDuration);
	const [serverIp, setServerIp] = useState("");
	const [WANDNSServers, setWANDNSServers] = useState<Array<IPRangeInt>>(
		getDefaultDNSServers()
	);
	const [MACAddresses, setMACAddresses] = useState<Array<IPRangeInt>>([]);

	const [routerId, setRouterId] = useState(Defaults.RouterId);
	const [password, setPassword] = useState("");
	const [wanIP, setWanIP] = useState("");
	const [gatewayAddress, setGatewayAddress] = useState("");
	const [wanVirtualIP, setWanVirtualIP] = useState("");
	const [wanPeerIP, setWanPeerIP] = useState("");
	const [wanMTU, setWanMTU] = useState(Defaults.WanMTU);

	const [lanIP, setLanIP] = useState("");
	const [lanStartIP, setLanStartIP] = useState("");
	const [lanEndIP, setLanEndIP] = useState("");
	const [lanVirtualIP, setLanVirtualIP] = useState("");
	const [lanPeerIP, setLanPeerIP] = useState("");
	const [lanMTU, setLanMTU] = useState(Defaults.LanMTU);
	const [lanGatewayAddress, setLanGatewayAddress] = useState("");
	const [VlanList, setVlanList] = useState<Array<VLAN>>([]);
	const [optIn, setOptIn] = useState<string>(Defaults.OptIn);
	const [selectedPeerAppliance, setSelectedPeerAppliance] = useState<
		Appliance | undefined
	>();
	const [addPeers, setAddPeers] = useState(false);
	const [disabledPeer, setDisabledPeer] = useState(false);

	const [configImport, setConfigImport] = useState<ApplianceConfig>();
	const [currentConfig, setCurrentConfig] = useState<ApplianceConfig>();
	const [peerApplianceIDs, setPeerApplianceIDs] = useState<
		Array<string> | undefined
	>([]);
	const [proxyEnabled, setProxyEnabled] = useState<string>(Defaults.Proxy);
	const [proxyProtocol, setProxyProtocol] = useState<string>(
		Defaults.ProxyProtocol
	);
	const [proxyIP, setProxyIP] = useState<string>("");
	const [proxyPort, setProxyPort] = useState<string>("");
	const [authenticationEnabled, setAuthenticationEnabled] = useState<string>(
		Defaults.ProxyAuth
	);
	const [authUsername, setAuthUsername] = useState<string>("");
	const [authPassword, setAuthPassword] = useState<string>("");
	const [loggingLevel, setLoggingLevel] = useState<string>(
		Defaults.LoggingLevel
	);
	const [discoveryMode, setDiscoveryMode] = useState<string>(
		Defaults.DiscoveryMode
	);
	const [authMode, setAuthMode] = useState<string>(Defaults.AuthMode);
	const [asymmetricModeEnabled, setAsymmetricModeEnabled] = useState<string>(
		Defaults.AsymmetricMode
	);
	const [failOpenEnabled, setFailOpenEnabled] = useState<string>(
		Defaults.FailOpen
	);
	const agentId = appliance?.agentId;
	const setSnackbar = useSnackbarStore(
		(state: { setSnackbar: any }) => state.setSnackbar
	);
	const exportApplianceAPI = useApplianceConfigExportAPI(agentId);
	const configAppliancesAPI = useApplianceConfigPutAPI(agentId);
	const editAppliancesAPI = useEditApplianceAPI(agentId);
	const queryClient = useQueryClient();
	const { data: config } = useApplianceConfigGetAPI(agentId);
	const currentGatekeeperConfig: ApplianceConfig = config?.gatekeeperConfig;
	const currentPeerApplianceIDs: string[] = config?.peerApplianceIDs;
	const location: string = config?.applianceLocation;
	const disabledActionsTooltip = getPendingActionsTooltip(appliance);

	const validateConfig = useCallback(
		(
			gatekeeperConfig: ApplianceConfig,
			peerApplianceDetails?: PeerDetails,
			proxySettings?: ProxySettings
		) => {
			let configInvalid = isConfigInvalid(
				gatekeeperConfig,
				peerApplianceDetails,
				proxySettings
			);
			if (configInvalid) {
				setSnackbar(true, SnackBarSeverity.Error, "ConfigInvalid", {
					configInvalid,
				});
				return false;
			}
			return true;
		},
		[setSnackbar]
	);

	useEffect(() => {
		let applianceConfig: ApplianceConfigPayload = {
			gatekeeperConfig: currentGatekeeperConfig,
			peerApplianceIDs: currentPeerApplianceIDs,
		};
		if (configImport) {
			if (!validateConfig(configImport)) {
				return;
			}
			applianceConfig.gatekeeperConfig = configImport;
			applianceConfig.peerApplianceIDs =
				configImport?.agent?.peerApplianceIDs ?? [];
		}
		setApplianceLocation(location);
		setCurrentConfig(applianceConfig?.gatekeeperConfig);
		setPeerApplianceIDs(applianceConfig?.peerApplianceIDs);
	}, [
		currentGatekeeperConfig,
		currentPeerApplianceIDs,
		configImport,
		location,
		validateConfig,
	]);

	useEffect(() => {
		if (currentConfig) {
			setApplianceName(currentConfig?.agent?.hostname);
			if (currentConfig?.agent) {
				setProxyEnabled(
					currentConfig?.agent?.proxy?.url ? ProxyOptions.Yes : ProxyOptions.No
				);
				let proxyURL = currentConfig?.agent?.proxy?.url?.split(":") ?? [];
				setProxyProtocol(proxyURL[0] ?? Defaults.ProxyProtocol);
				setProxyIP(proxyURL[1]?.slice(2) ?? "");
				setProxyPort(proxyURL[2] ?? "");
				setAuthenticationEnabled(
					currentConfig?.agent?.proxy?.username
						? AuthenticationOptions.Yes
						: AuthenticationOptions.No
				);
				setAuthUsername(currentConfig?.agent?.proxy?.username ?? "");
				setAuthPassword(
					curlProxyDecodeURIComponent(
						base64ToText(currentConfig?.agent?.proxy?.password ?? "")
					)
				);
			}
			setLoggingLevel(
				currentConfig?.agent?.loggerLevel ?? Defaults.LoggingLevel
			);
			setAsymmetricModeEnabled(
				currentConfig?.agent?.asymmetricMode === BooleanFlags.True
					? AsymmetricModeOptions.Yes
					: AsymmetricModeOptions.No
			);
			setDiscoveryMode(
				currentConfig?.agent?.pathBasedDiscovery === BooleanFlags.True
					? DiscoveryModeOptions[1]
					: DiscoveryModeOptions[0]
			);
			setFailOpenEnabled(
				currentConfig?.agent?.failOpen === BooleanFlags.True
					? FailOpenOptions.Yes
					: FailOpenOptions.No
			);

			if (currentConfig?.dhcp) {
				let dhcpOption = currentConfig?.ipConfiguration as DHCPOptions;
				if (!currentConfig?.ipConfiguration) {
					dhcpOption =
						currentConfig?.dhcp?.enabled === BooleanFlags.True
							? DHCPOptions.Mixed
							: DHCPOptions.Static;
				}
				setDHCPOption(dhcpOption);
				setDHCPMode(
					(currentConfig?.dhcp?.mode as DHCPModes) ?? DHCPModes.Server
				);
				setDeviceIdentifier(
					DeviceIdentifierOptions[currentConfig?.deviceIdentifier ?? 0]
				);
				setLeaseDuration(
					currentConfig?.dhcp?.leaseTime
						? SToMin(currentConfig?.dhcp?.leaseTime)
						: Defaults.LeaseDuration
				);
				setServerIp(currentConfig?.dhcp?.serverIp ?? "");
				setOptIn(
					currentConfig?.dhcp?.optInSupport === BooleanFlags.True
						? OptInOptions.Yes
						: OptInOptions.No
				);
				if (currentConfig?.dhcp?.optInSupport === BooleanFlags.True) {
					let macAddresses = [];
					for (let macAddress of currentConfig?.dhcp?.optInDevices ?? []) {
						let server: IPRangeInt = {
							id: "",
							value: macAddress,
							error: false,
							count: "",
						};
						macAddresses.push(server);
					}
					setMACAddresses(macAddresses);
				}
			}

			if (currentConfig?.interfaces) {
				for (let interf of currentConfig.interfaces) {
					if (interf?.type === NetworkInterfaces.WAN) {
						setWanIP(interf?.ipAddress ?? "");
						setGatewayAddress(interf?.gatewayAddress ?? "");
						setWanVirtualIP(interf?.vrrp?.virtualIp ?? "");
						if (interf?.vrrp?.peerIps?.length) {
							setWanPeerIP(interf?.vrrp?.peerIps[0] ?? "");
						}
						let dnsServers = [];
						for (let dnsServer of interf?.dnsServers ?? []) {
							let server: IPRangeInt = {
								id: "",
								value: dnsServer,
								error: false,
								count: "",
							};
							dnsServers.push(server);
						}
						setWANDNSServers(dnsServers ?? []);
						setWanMTU(interf?.mtu ?? Defaults.WanMTU);
					}
					if (interf.type !== NetworkInterfaces.WAN) {
						setNetworkInterface(interf?.type ?? NetworkInterfaces.LAN);
					}
					if (interf?.type === NetworkInterfaces.LAN) {
						setLanIP(interf?.ipAddress ?? "");
						setLanStartIP(interf?.dhcp?.rangeStart ?? "");
						setLanEndIP(interf?.dhcp?.rangeEnd ?? "");
						setLanGatewayAddress(interf?.dhcp?.existingGatewayIp ?? "");
						setLanVirtualIP(interf?.vrrp?.virtualIp ?? "");
						if (interf?.vrrp?.peerIps?.length) {
							setLanPeerIP(interf?.vrrp?.peerIps[0] ?? "");
						}
						setLanMTU(interf?.mtu ?? Defaults.LanMTU);
					}
					if (interf?.type === NetworkInterfaces.VLAN) {
						let vlans = [];
						let vlan: VLANConfig;
						for (vlan of interf?.vlans ?? []) {
							let vlanItem: VLAN = {
								id: vlan?.id ?? "",
								ip: vlan?.ipAddress ?? "",
								startIP: vlan?.dhcp?.rangeStart ?? "",
								endIP: vlan?.dhcp?.rangeEnd ?? "",
								gatewayAddress: vlan?.dhcp?.existingGatewayIp ?? "",
								virtualIP: vlan?.vrrp?.virtualIp ?? "",
								mtu: vlan?.mtu ?? Defaults.LanMTU,
							};
							if (vlan?.vrrp?.peerIps?.length) {
								vlanItem.peerIP = vlan?.vrrp?.peerIps[0] ?? "";
							}
							vlans.push(vlanItem);
						}
						setVlanList(vlans);
					}
				}
			}
			if (!currentConfig?.vrrp) {
				setHAMode(HAModes.StandAlone);
			}
			if (currentConfig?.vrrp as VRRPConfig) {
				if (currentConfig?.vrrp?.enabled === BooleanFlags.True) {
					setHAMode(HAModes.Primary);
					if (peerApplianceIDs?.length) {
						setDisabledPeer(true);
					}
					setRouterId(currentConfig?.vrrp?.routerId ?? Defaults.RouterId);
					setPassword(currentConfig?.vrrp?.password ?? "");
					setAuthMode(
						currentConfig?.vrrp?.authMode === AuthModeValues["Simple/Password"]
							? AuthModeOptions[0]
							: AuthModeOptions[1]
					);
				}
			}
		}
	}, [currentConfig, peerApplianceIDs]);

	useEffect(() => {
		if (peerApplianceIDs) {
			setPeerAppliances(peerApplianceIDs);
			setAddPeers(true);
		}
	}, [peerApplianceIDs]);

	const isFormDisabled = () => {
		return false;
	};

	const keyListener = (event: any) => {
		if (event?.key === "Enter" || event?.keyCode === 13) {
			applyConfig();
		}
	};

	const getConfig = () => {
		let config: ApplianceConfig = {
			agent: getAgentConfig(),
			dhcp: getDHCPConfig(),
			interfaces: getNWInterfaceConfig(),
		};
		config.ipConfiguration = DHCPOption;
		if (DHCPOption === DHCPOptions.Static) {
			config.deviceIdentifier =
				deviceIdentifier === DeviceIdentifierOptions[0] ? 0 : 1;
		}
		if (HAMode !== HAModes.StandAlone) {
			config.vrrp = getHAConfig();
		}
		let peerApplianceIDs = [];
		if (addPeers && selectedPeerAppliance?.agentId) {
			peerApplianceIDs.push(selectedPeerAppliance.agentId);
		}
		return {
			gatekeeperConfig: config,
			peerApplianceIDs,
			agentLocation: applianceLocation,
		};
	};

	const applyConfig = () => {
		if (!appliance?.gatekeeperConfigUpdateAllowed) {
			updateApplianceMeta();
			return;
		}
		let config: ApplianceConfigPayload = getConfig();
		if (
			!validateConfig(
				config.gatekeeperConfig,
				{
					peerEnabled: addPeers,
					peerApplianceIDs: config.peerApplianceIDs,
				},
				{
					proxyEnabled,
					authenticationEnabled,
				}
			)
		) {
			return;
		}
		configAppliancesAPI.mutate(config, {
			onSuccess: response => {
				queryClient.invalidateQueries({
					queryKey: ["gatekeeper"],
				});
				setTimeout(() => {
					cancel?.();
				}, 300);
				onApplianceUpdate();
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const updateApplianceMeta = () => {
		if (!applianceName?.trim().length) {
			setSnackbar(true, SnackBarSeverity.Error, "InvalidGatekeeperName");
		}
		const body: ApplianceFormProps = {
			agentName: (applianceName || "")?.trim(),
			agentLocation: (applianceLocation || "")?.trim(),
		};
		editAppliancesAPI.mutate(body, {
			onSuccess: response => {
				queryClient.invalidateQueries({
					queryKey: ["gateways"],
				});
				onApplianceUpdate();
				setTimeout(() => {
					cancel && cancel();
				}, 300);
			},
			onError: () => {
				cancel && cancel();
			},
		});
	};

	const onApplianceUpdate = () => {
		if (updateApplianceData) {
			updateApplianceData({ applianceName, applianceLocation });
		}
	};

	const getAgentConfig = () => {
		let agentConfig = {
			...currentConfig?.agent,
		};
		agentConfig.hostname = applianceName;
		agentConfig.proxy = {};
		if (proxyEnabled === ProxyOptions.Yes) {
			agentConfig.proxy.url = `${proxyProtocol}://${proxyIP}:${proxyPort}`;
			if (authenticationEnabled === AuthenticationOptions.Yes) {
				agentConfig.proxy.username = authUsername;
				agentConfig.proxy.password = textToBase64(
					curlProxyEncodeURIComponent(authPassword ?? "")
				);
			}
		}
		agentConfig.asymmetricMode =
			asymmetricModeEnabled === AsymmetricModeOptions.Yes
				? BooleanFlags.True
				: BooleanFlags.False;
		agentConfig.pathBasedDiscovery =
			discoveryMode === DiscoveryModeOptions[0]
				? BooleanFlags.False
				: BooleanFlags.True;
		agentConfig.loggerLevel = loggingLevel;
		agentConfig.failOpen =
			failOpenEnabled === FailOpenOptions.Yes
				? BooleanFlags.True
				: BooleanFlags.False;
		return agentConfig;
	};

	const getDHCPConfig = () => {
		let optInSettings = getOptInSettings();
		if (DHCPOption === DHCPOptions.Static) {
			return {
				enabled: BooleanFlags.False,
				...optInSettings,
			};
		}
		let DHCP: DHCPConfig = {
			enabled: BooleanFlags.True,
			mode: DHCPMode as DHCPModes,
			leaseTime: leaseDuration ? minToS(leaseDuration) : Defaults.LeaseDuration,
			dnsServers: mapDNSServers(WANDNSServers),
			...optInSettings,
		};
		if (DHCPMode === DHCPModes.Relay) {
			DHCP.serverIp = serverIp ?? "";
		}
		return {
			...currentConfig?.dhcp,
			...DHCP,
		};
	};

	const getOptInSettings = () => {
		const optInSettings = {
			optInSupport: optIn === OptInOptions.Yes ? "true" : "false",
			optInDevices: optIn === OptInOptions.Yes ? getOptInDevices() : [],
		};
		return optInSettings;
	};

	const getOptInDevices = () => {
		const macAddresses = uniqBy(MACAddresses, "value");
		return macAddresses?.map(address => {
			return address?.value?.trim()?.replaceAll("-", ":");
		});
	};

	const getNWInterfaceConfig = () => {
		return [
			getWANConfig(),
			networkInterface === NetworkInterfaces.LAN
				? getLANConfig()
				: getVLANConfig(),
		];
	};

	const getCurrentNWInterface = (interfaceType: string) => {
		const networkInterface = currentConfig?.interfaces?.find(
			(interf: NetworkInterfaceConfig) => {
				return interf?.type === interfaceType;
			}
		);
		return networkInterface;
	};

	const getHAConfig = () => {
		let HA: VRRPConfig = {
			enabled:
				HAMode === HAModes.StandAlone ? BooleanFlags.False : BooleanFlags.True,
			routerId: routerId ?? Defaults.RouterId,
			password: password ?? "",
			authMode:
				authMode === AuthModeOptions[0]
					? AuthModeValues["Simple/Password"]
					: AuthModeValues["Authentication Header"],
		};
		return {
			...currentConfig?.vrrp,
			...HA,
		};
	};

	const getWANConfig = () => {
		let WAN: NetworkInterfaceConfig = {
			type: NetworkInterfaces.WAN,
			ipAddress: wanIP ?? "",
			dnsServers: mapDNSServers(WANDNSServers),
			gatewayAddress: gatewayAddress ?? "",
			mtu: wanMTU ?? "",
		};
		if (HAMode !== HAModes.StandAlone) {
			WAN.vrrp = {
				virtualIp: wanVirtualIP ?? "",
				peerIps: addPeers && wanPeerIP ? [wanPeerIP] : [],
			};
		}
		let currentWANConfig = getCurrentNWInterface(NetworkInterfaces.WAN);
		WAN.name = currentWANConfig?.name;
		return {
			...WAN,
		};
	};

	const getLANConfig = () => {
		let LAN: NetworkInterfaceConfig = {
			type: NetworkInterfaces.LAN,
			ipAddress: lanIP ?? "",
			dhcp: {
				rangeStart: lanStartIP ?? "",
				rangeEnd: lanEndIP ?? "",
				existingGatewayIp: lanGatewayAddress ?? "",
			},
			mtu: lanMTU ?? "",
		};
		if (HAMode !== HAModes.StandAlone) {
			LAN.vrrp = {
				virtualIp: lanVirtualIP,
				peerIps: addPeers && lanPeerIP ? [lanPeerIP] : [],
			};
		}
		let currentLANConfig = getCurrentNWInterface(NetworkInterfaces.LAN);
		if (!currentLANConfig?.name) {
			currentLANConfig = getCurrentNWInterface(NetworkInterfaces.VLAN);
		}
		LAN.name = currentLANConfig?.name;
		return {
			...LAN,
		};
	};

	const getVLANConfig = () => {
		let vlans = [];
		for (let vlan of VlanList) {
			let updatedVlan: VLANConfig = {
				id: vlan?.id ?? "",
				ipAddress: vlan?.ip ?? "",
				mtu: vlan?.mtu ?? "",
				dhcp: {
					rangeStart: vlan?.startIP ?? "",
					rangeEnd: vlan?.endIP ?? "",
					existingGatewayIp: vlan?.gatewayAddress ?? "",
				},
			};
			if (HAMode !== HAModes.StandAlone) {
				updatedVlan.vrrp = {
					virtualIp: vlan?.virtualIP,
					peerIps: addPeers && vlan?.peerIP ? [vlan?.peerIP] : [],
				};
			}
			vlans.push(updatedVlan);
		}
		let currentVLANConfig = getCurrentNWInterface(NetworkInterfaces.VLAN);
		if (!currentVLANConfig?.name) {
			currentVLANConfig = getCurrentNWInterface(NetworkInterfaces.LAN);
		}
		return {
			name: currentVLANConfig?.name,
			type: NetworkInterfaces.VLAN,
			vlans,
		};
	};

	const onDHCPModeChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => {
		const mode: DHCPModes = value as DHCPModes;
		setDHCPMode(mode);
	};

	const onDHCPOptionChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => {
		const option: DHCPOptions = value as DHCPOptions;
		setDHCPOption(option);
	};

	const onHAModeChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => {
		const mode: HAModes = value as HAModes;
		setHAMode(mode);
	};

	const onNetworkInterfaceChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => {
		const mode: NetworkInterfaces = value as NetworkInterfaces;
		setNetworkInterface(mode);
	};

	const scrollToLogs = () => {
		const element = document.getElementById(`appliance-config-logs`);
		if (element) {
			element.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
	};

	const onExportConfig = () => {
		let config: ApplianceConfigPayload = getConfig();
		if (
			!validateConfig(
				config.gatekeeperConfig,
				{
					peerEnabled: addPeers,
					peerApplianceIDs: config.peerApplianceIDs,
				},
				{
					proxyEnabled,
					authenticationEnabled,
				}
			)
		) {
			return;
		}
		exportApplianceAPI.mutate(config, {
			onSuccess: response => {
				queryClient.invalidateQueries({
					queryKey: ["gatekeeper-config-export"],
				});
				setTimeout(() => {
					cancel && cancel();
				}, 300);
				exportConfig(response, appliance?.agentName);
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const onImportConfig = () => {
		document.getElementById("config-import")?.click();
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fileReader = new FileReader();
		if (e?.target?.files?.length) {
			fileReader.readAsText(e?.target?.files[0], "UTF-8");
			fileReader.onload = (e: any) => {
				try {
					setConfigImport(JSON.parse(e?.target?.result));
					setSnackbar(true, SnackBarSeverity.Success, `Config Imported`);
				} catch (e) {
					setSnackbar(true, SnackBarSeverity.Error, `Invalid Config`);
				}
			};
		}
	};

	const menuOptions = [
		{
			label: "Export current configuration",
			value: "dest",
			handler: () => {
				onExportConfig();
			},
		},
		{
			label: "Import existing configuration",
			value: "dest",
			handler: () => {
				onImportConfig();
			},
		},
	];

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={cancel}
			PaperProps={{
				sx: {
					p: 0,
					width: "70%",
					maxwidth: "1000px",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<DialogTitle sx={{ pb: 1, pt: 4 }}>
				<Stack direction="row" justifyContent="space-between">
					<Stack>
						<Stack direction="row" alignItems="center">
							<Typography variant="h6" mr={2}>
								{title}
							</Typography>
							<ConfigStatus status={config?.status} />
						</Stack>
					</Stack>
					{isConfigurable(appliance) ? (
						<Stack direction="row" mt={1}>
							<MoreOptionsMenu
								size="medium"
								menuOptions={menuOptions}
								disabled={isFormDisabled()}
							/>
							<input
								id="config-import"
								hidden
								type="file"
								accept=".json,application/json"
								onChange={handleChange}
							/>
							<Box>
								<IconButton
									size="medium"
									aria-label="close drawer"
									onClick={cancel}
									sx={{
										zIndex: 2,
									}}
								>
									<Tooltip title="Close Drawer">
										<CloseIcon fontSize="medium" />
									</Tooltip>
								</IconButton>
							</Box>
						</Stack>
					) : null}
				</Stack>
			</DialogTitle>
			<>
				<DialogContent id="appliance-config-dialog">
					<Stack
						alignItems={"flex-start"}
						style={{ position: "relative", height: "100%" }}
						mt={4}
					>
						<General
							applianceName={applianceName}
							setApplianceName={setApplianceName}
							applianceLocation={applianceLocation}
							setApplianceLocation={setApplianceLocation}
							keyListener={keyListener}
							isFormDisabled={isFormDisabled()}
						/>

						{HAMode !== HAModes.Standby && isConfigurable(appliance) ? (
							<HighAvailability
								HAMode={HAMode}
								onHAModeChange={onHAModeChange}
								password={password}
								setPassword={setPassword}
								routerId={routerId}
								setRouterId={setRouterId}
								authMode={authMode}
								setAuthMode={setAuthMode}
								keyListener={keyListener}
								isFormDisabled={isFormDisabled()}
							/>
						) : null}

						{hasPrimaryAppliance(HAMode) && isConfigurable(appliance) ? (
							<ProtectedNetworkInterface
								HAMode={HAMode}
								DHCPMode={DHCPMode}
								onDHCPModeChange={onDHCPModeChange}
								DHCPOption={DHCPOption}
								onDHCPOptionChange={onDHCPOptionChange}
								deviceIdentifier={deviceIdentifier}
								setDeviceIdentifier={setDeviceIdentifier}
								leaseDuration={leaseDuration}
								setLeaseDuration={setLeaseDuration}
								serverIp={serverIp}
								setServerIp={setServerIp}
								networkInterface={networkInterface}
								onNetworkInterfaceChange={onNetworkInterfaceChange}
								lanIP={lanIP}
								setLanIP={setLanIP}
								lanStartIP={lanStartIP}
								setLanStartIP={setLanStartIP}
								lanEndIP={lanEndIP}
								setLanEndIP={setLanEndIP}
								lanVirtualIP={lanVirtualIP}
								setLanVirtualIP={setLanVirtualIP}
								lanMTU={lanMTU}
								setLanMTU={setLanMTU}
								VlanList={VlanList}
								setVlanList={setVlanList}
								lanGatewayAddress={lanGatewayAddress}
								setLanGatewayAddress={setLanGatewayAddress}
								optIn={optIn}
								setOptIn={setOptIn}
								keyListener={keyListener}
								isFormDisabled={isFormDisabled()}
							/>
						) : null}

						{hasPrimaryAppliance(HAMode) && isConfigurable(appliance) ? (
							<WAN
								HAMode={HAMode}
								wanIP={wanIP}
								setWanIP={setWanIP}
								gatewayAddress={gatewayAddress}
								setGatewayAddress={setGatewayAddress}
								wanVirtualIP={wanVirtualIP}
								setWanVirtualIP={setWanVirtualIP}
								DNSServers={WANDNSServers}
								setDNSServers={setWANDNSServers}
								wanMTU={wanMTU}
								setWanMTU={setWanMTU}
								keyListener={keyListener}
								isFormDisabled={isFormDisabled()}
							/>
						) : null}

						{hasPrimaryAppliance(HAMode) &&
						(HAMode === HAModes.Primary || HAMode === HAModes.Standby) &&
						isConfigurable(appliance) ? (
							<PeerAppliances
								agentId={agentId}
								peerAppliances={peerAppliances}
								addPeers={addPeers}
								setAddPeers={setAddPeers}
								setSelectedPeerAppliance={setSelectedPeerAppliance}
								selectedPeerAppliance={selectedPeerAppliance}
								wanPeerIP={wanPeerIP}
								setWanPeerIP={setWanPeerIP}
								lanPeerIP={lanPeerIP}
								setLanPeerIP={setLanPeerIP}
								networkInterface={networkInterface}
								VlanList={VlanList}
								setVlanList={setVlanList}
								HAMode={HAMode}
								disabledPeer={disabledPeer}
								keyListener={keyListener}
								isFormDisabled={isFormDisabled()}
							/>
						) : null}

						{hasPrimaryAppliance(HAMode) && isConfigurable(appliance) ? (
							<Advanced
								proxyEnabled={proxyEnabled}
								setProxyEnabled={setProxyEnabled}
								proxyProtocol={proxyProtocol}
								setProxyProtocol={setProxyProtocol}
								proxyIP={proxyIP}
								setProxyIP={setProxyIP}
								proxyPort={proxyPort}
								setProxyPort={setProxyPort}
								authenticationEnabled={authenticationEnabled}
								setAuthenticationEnabled={setAuthenticationEnabled}
								authUsername={authUsername}
								setAuthUsername={setAuthUsername}
								authPassword={authPassword}
								setAuthPassword={setAuthPassword}
								loggingLevel={loggingLevel}
								setLoggingLevel={setLoggingLevel}
								discoveryMode={discoveryMode}
								setDiscoveryMode={setDiscoveryMode}
								asymmetricModeEnabled={asymmetricModeEnabled}
								setAsymmetricModeEnabled={setAsymmetricModeEnabled}
								failOpenEnabled={failOpenEnabled}
								setFailOpenEnabled={setFailOpenEnabled}
								isFormDisabled={isFormDisabled()}
							/>
						) : null}
						{hasPrimaryAppliance(HAMode) && isConfigurable(appliance) ? (
							<Logs agentId={agentId} scrollToLogs={scrollToLogs} />
						) : null}
					</Stack>
				</DialogContent>
				<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
					<ToolbarAction
						loading={editAppliancesAPI.isLoading}
						save={applyConfig}
						cancel={cancel}
						isValid={
							!(
								config?.status === ConfigStatusMap.Pending ||
								config?.status === ConfigStatusMap.Inprogress ||
								config?.status === ConfigStatusMap.ForceUpdate
							) && !Boolean(disabledActionsTooltip)
						}
						actionBtnTooltip={disabledActionsTooltip}
						actionBtnText="Apply"
					/>
				</DialogActions>
			</>
		</Drawer>
	);
}

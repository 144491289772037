import { TenantInt } from "./useComplianceController";

export const getComplianceReqCheckList = () => {
	const tenantCompliantList = window.localStorage.getItem(
		"tenantCompliantList"
	);
	let tenantCompliantListMap;
	if (tenantCompliantList) {
		tenantCompliantListMap = new Map(JSON.parse(tenantCompliantList));
	}
	return tenantCompliantListMap;
};

export const setComplianceReqCheck = (tenantList: Array<TenantInt>) => {
	const tenantCompliantList = getComplianceReqCheckList();

	let newTenantCompliantList = new Map(tenantCompliantList);

	tenantList.forEach((tenant: TenantInt) => {
		const tenantID = tenant?.tenantID;
		if (newTenantCompliantList && !newTenantCompliantList.has(tenantID)) {
			newTenantCompliantList.set(tenantID, true);
			try {
				window.localStorage.setItem(
					"tenantCompliantList",
					JSON.stringify(Array.from(newTenantCompliantList.entries()))
				);
			} catch (e) {
				console.error(e);
			}
		}
	});
};

export const acceptComplianceCheck = (tenantID: string) => {
	const tenantCompliantList = getComplianceReqCheckList();

	let newTenantCompliantList = new Map(tenantCompliantList);
	newTenantCompliantList.set(tenantID, false);
	try {
		window.localStorage.setItem(
			"tenantCompliantList",
			JSON.stringify(Array.from(newTenantCompliantList.entries()))
		);
	} catch (e) {
		console.error(e);
	}
};

export const setCurrentTenantID = (tenantID: string) => {
	try {
		window.localStorage.setItem("currentTenantID", tenantID);
	} catch (e) {
		console.error(e);
	}
};

export const getCurrentTenantID = () => {
	try {
		const currentTenantID = window.localStorage.getItem("currentTenantID");
		return currentTenantID;
	} catch (e) {
		console.error(e);
		return "";
	}
};

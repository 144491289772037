import { useTheme } from "@mui/material";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { AssetStatus } from "pages/assets/types";
import {
	AssetStatusMap,
	AssetStatusReverseMap,
	Direction,
	SecurityStatus,
	SecurityStatusLabels,
	assetSliderStyles,
} from "../../constants";
import { AssetSliderLabel } from "./components/asset-slider-label/AssetSliderLabel";
import { SliderMarks } from "./constants";
import { AssetSliderProps } from "./types";

import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { SecuritySlider } from "modules/security-slider";
import { ProgressiveEnforcementLevel } from "pages/ports/types";
import { useCallback } from "react";

export const AssetStatusSlider = ({
	asset,
	direction,
	currentStatus,
	selectedStatus,
	simulatedStatus,
	setSelectedStatus,
	disabled = false,
	minValue,
	selectedProgressiveEnforcementLevel,
}: AssetSliderProps) => {
	const { isFeatureEnabled } = useFeatureFlagControl(
		FEATURES.ASSET_STATUS_SLIDER_UI
	);

	const theme = useTheme();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	let assetStatus: SecurityStatus;
	let simulateStatus: boolean = false;

	switch (direction) {
		case Direction.Inbound:
			if (currentStatus !== undefined) {
				assetStatus = currentStatus;
			} else {
				assetStatus = AssetStatusMap[asset?.inboundAssetStatus as AssetStatus];
			}
			simulateStatus = Boolean(simulatedStatus);
			break;
		case Direction.Outbound:
			if (currentStatus !== undefined) {
				assetStatus = currentStatus;
			} else {
				assetStatus = AssetStatusMap[asset?.outboundAssetStatus as AssetStatus];
			}
			simulateStatus = Boolean(simulatedStatus);
			break;
	}

	const simulateCls = () => {
		let cls =
			(simulateStatus || selectedStatus > assetStatus) &&
			selectedStatus !== SecurityStatus.Unsecure
				? "asset-status-slider simulate"
				: "asset-status-slider";
		return cls;
	};

	const onChange = useCallback(
		(_: Event, v: number | number[]) => {
			let value = v as SecurityStatus;
			if (typeof minValue === "number" && value < minValue) {
				setSnackbar(
					true,
					SnackBarSeverity.Info,
					"CannotSetDirectionBelowStatus",
					{
						direction: window.getCTTranslatedText(
							direction === Direction.Inbound
								? "attack surface"
								: "blast radius"
						),
						status: window.getCTTranslatedText(AssetStatusReverseMap[minValue]),
					}
				);
				return;
			}
			setSelectedStatus(value);
		},
		[direction, minValue, setSelectedStatus, setSnackbar]
	);

	const getMarks = useCallback(() => {
		return SliderMarks.map(mark => {
			return {
				value: mark.value,
				label: (
					<AssetSliderLabel
						assetStatus={assetStatus}
						currentStatus={mark.key}
						isSimulateStatus={simulateStatus}
						label={SecurityStatusLabels[mark.key]}
						isMinimum={
							minValue === mark.key && minValue !== SecurityStatus.Unsecure
						}
					/>
				),
			};
		});
	}, [assetStatus, minValue, simulateStatus]);

	const isZeroTrustProgressive =
		selectedProgressiveEnforcementLevel ===
		ProgressiveEnforcementLevel.ZeroTrust;
	const isAnyProgressive =
		selectedProgressiveEnforcementLevel === ProgressiveEnforcementLevel.Any;

	const selectedStatusValue =
		disabled && (isZeroTrustProgressive || isAnyProgressive)
			? SecurityStatus.Unsecure
			: selectedStatus;

	return (
		<SecuritySlider
			minValue={minValue}
			className={simulatedStatus ? simulateCls() : "asset-status-slider"}
			selectedStatus={selectedStatusValue}
			disabled={disabled}
			labelFormatter={v => SecurityStatusLabels[v as SecurityStatus]}
			onChange={onChange}
			getMarks={getMarks}
			sliderSx={assetSliderStyles({
				theme,
				minValue,
				selectedStatus,
				isAlpha: isFeatureEnabled,
			})}
		/>
	);
};

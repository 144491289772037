import { Box, Stack, Tooltip, Typography } from "@mui/material";

import numeral from "numeral";
import { PathColumnRenderProps } from "pages/paths/types";
import pluralize from "pluralize";

export function PathConnectionCount({ path }: PathColumnRenderProps) {
	if (!path.channelHash || !path.connectionCount) {
		return <Box style={{ width: 100, minHeight: 45 }} />;
	}

	return (
		<Box style={{ width: 130 }}>
			<Tooltip
				title={window.getCTTranslatedText("Last 30 days")}
				arrow={false}
				placement="bottom-start"
			>
				<Stack alignItems="flex-start" justifyContent="center">
					<Typography noWrap variant="body2">
						{numeral(path?.connectionCount ?? 0).format("0a")}{" "}
						{window.getCTTranslatedText(
							pluralize("connection", path.connectionCount)
						)}
					</Typography>
				</Stack>
			</Tooltip>
		</Box>
	);
}

import { FacetGroupInfo } from "modules/core/types";

export const getFacetGroupInfo = (
	facetOrder: FacetGroupInfo,
	readOnlyDimensions: string[]
) => {
	let newOrder = { ...facetOrder };

	readOnlyDimensions.forEach(key => {
		delete newOrder[key];
	});
	return newOrder;
};

import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import { BusinessValue } from "pages/assets/types";
import { ControllerRenderProps, FieldValues } from "react-hook-form";

interface Props {
	value: string;
	onChange: ControllerRenderProps<FieldValues, string>["onChange"];
}

function BusinessValueSelect({ value, onChange }: Props) {
	const title = window.getCTTranslatedText("Business Value");

	const OPTIONS = [
		{
			icon: <KeyboardDoubleArrowUpIcon color="error" fontSize="small" />,
			value: BusinessValue.High,
			label: window.getCTTranslatedText(BusinessValue.High),
		},
		{
			icon: <KeyboardArrowUpIcon color="warning" fontSize="small" />,
			value: BusinessValue.Medium,
			label: window.getCTTranslatedText(BusinessValue.Medium),
		},
		{
			icon: <KeyboardArrowDown color="success" fontSize="small" />,
			value: BusinessValue.Low,
			label: window.getCTTranslatedText(BusinessValue.Low),
		},
	];

	return (
		<FormControl variant="standard" sx={{ width: "100%" }}>
			<InputLabel id="business-value-label">{title}</InputLabel>
			<Select
				label={title}
				labelId="business-value-label"
				id="business-value"
				value={value || "empty"}
				onChange={e => onChange(e)}
				displayEmpty
			>
				<MenuItem disabled value={"empty"}>
					{window.getCTTranslatedText("Select Business Value")}
				</MenuItem>
				{OPTIONS.map(({ icon, value, label }) => (
					<MenuItem key={value} value={value}>
						<Stack direction="row" spacing={1}>
							{icon}
							<Typography
								variant="caption"
								style={{ textTransform: "capitalize" }}
							>
								{label}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

export default BusinessValueSelect;

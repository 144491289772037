import { Tooltip, Typography } from "@mui/material";
import {
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GridColDef,
} from "@mui/x-data-grid-pro";
import { AssetStatus } from "pages/assets/types";
import { ProgressiveEnforcementStatus } from "pages/ports/types";
import { TagPolicyProgressiveStatus } from "pages/tags/components/tag-policy-list/components/tag-policy-progressive-status";
import { MatchedAssetCount } from "./components/matched-asset-count";
import { NetworkAssignments } from "./components/network-assignments";
import { PolicyCriteria } from "./components/policy-criteria";
import { TagPolicyActions } from "./components/tag-policy-actions";
import { TagPolicyAutomation } from "./components/tag-policy-automation";
import { TagPolicyName } from "./components/tag-policy-name";
import { TemplateAssignments } from "./components/template-assignments";
import { useTagPolicyFacetStore } from "./store";
import { TagPolicy } from "./types";

export const BASE_TAG_POLICY_COLS = (): GridColDef<TagPolicy>[] => [
	{
		field: "name",
		headerName: "Name",
		width: 220,
		minWidth: 200,
		sortable: true,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			return <TagPolicyName policy={params.row} />;
		},
	},
	{
		field: "criteria",
		headerName: "Criteria",
		width: 240,
		minWidth: 200,
		sortable: false,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			return (
				<PolicyCriteria
					policy={params.row}
					useFacetStore={useTagPolicyFacetStore}
				/>
			);
		},
	},
	{
		field: "description",
		headerName: "Description",
		width: 220,
		minWidth: 200,
		sortable: false,
		renderCell: params => params.row.description,
	},
	{
		field: "templatesAssigned",
		headerName: "Templates",
		width: 120,
		minWidth: 120,
		sortable: false,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			return <TemplateAssignments policy={params?.row} />;
		},
	},
	{
		field: "namednetworksAssigned",
		headerName: "Named Networks",
		width: 160,
		minWidth: 160,
		sortable: false,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			return <NetworkAssignments policy={params?.row} />;
		},
	},
	{
		field: "matchingAssets",
		headerName: "Assets",
		width: 100,
		minWidth: 100,
		sortable: false,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			return <MatchedAssetCount policy={params?.row} />;
		},
	},
	{
		field: "policyAutomation-attacksurface",
		headerName: "Min. Attack Surface",
		width: 160,
		minWidth: 160,
		sortable: false,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		renderCell: params => {
			return (
				<>
					<TagPolicyAutomation
						policy={params.row}
						children={() => {
							return (
								<Tooltip title="Attack Surface">
									<Typography variant="body2">
										{window.getCTTranslatedText(
											params.row.lowestInboundPolicyStatus ??
												AssetStatus.Unsecured
										)}
									</Typography>
								</Tooltip>
							);
						}}
						disabled={
							!params.row.policyAutomationConfigurable &&
							params.row?.lowestProgressiveInboundPolicyStatus ===
								ProgressiveEnforcementStatus.ZeroTrust
						}
					/>
				</>
			);
		},
	},
	{
		field: "policyAutomation-progressivestatus",
		headerName: "Min. Progressive Status",
		width: 180,
		minWidth: 180,
		sortable: false,
		renderCell: params => {
			return <TagPolicyProgressiveStatus policy={params.row} />;
		},
	},
	{
		field: "policyAutomation-blastradius",
		headerName: "Min. Blast Radius",
		width: 160,
		minWidth: 160,
		sortable: false,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		renderCell: params => {
			return (
				<>
					<TagPolicyAutomation
						policy={params.row}
						children={() => {
							return (
								<Typography variant="body2">
									{window.getCTTranslatedText(
										params.row.lowestOutboundPolicyStatus ??
											AssetStatus.Unsecured
									)}
								</Typography>
							);
						}}
					/>
				</>
			);
		},
	},
	{
		field: "policyAutomation-autoSync",
		headerName: "Auto push firewall",
		width: 160,
		minWidth: 160,
		sortable: false,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		renderCell: params => {
			return (
				<>
					<TagPolicyAutomation
						policy={params.row}
						children={() => {
							return (
								<Typography variant="body2">
									{window.getCTTranslatedText(
										params.row.autoSynchronizeEnabled ? "Enabled" : "Disabled"
									)}
								</Typography>
							);
						}}
					/>
				</>
			);
		},
	},
];

export const TAG_POLICY_COLS = (): GridColDef<TagPolicy>[] => [
	...BASE_TAG_POLICY_COLS(),
	{
		field: "actions",
		headerName: "",
		width: 80,
		minWidth: 80,
		sortable: false,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			return <TagPolicyActions policy={params.row} />;
		},
	},
];

const pinnedColumns = {
	left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "name", "criteria"],
	right: ["actions"],
};

export const tabBasedPolicyColumnConfig = {
	pinnedColumns: pinnedColumns,
};

import { Scope } from "modules/scope-metadata/types";
import { SaveQueryFieldKeys } from "./types";

export const RequiredSaveQueryFields: { [key: string]: boolean } = {
	[SaveQueryFieldKeys.Name]: true,
};

export const SaveQueryFieldDisplayText = {
	[SaveQueryFieldKeys.Name]: "Name",
	[SaveQueryFieldKeys.Description]: "Description",
};

export const SaveQueryFieldOptions: Array<SaveQueryFieldKeys> = [
	SaveQueryFieldKeys.Name,
	SaveQueryFieldKeys.Description,
];

export enum CommonFieldTypes {
	Duration = "Duration",
	Protocol = "Protocol",
	Port = "Port",
}

export const CommonFieldScopeMapping: { [key: string]: any } = {
	[Scope.Path]: {
		pathlastobserved: CommonFieldTypes.Duration,
		protocol: CommonFieldTypes.Protocol,
		port: CommonFieldTypes.Port,
	},
	[Scope.Port]: {
		listenportlastobserved: CommonFieldTypes.Duration,
		listenportprotocol: CommonFieldTypes.Protocol,
		listenport: CommonFieldTypes.Port,
	},
};

export type CommonScopes = Scope.Path | Scope.Port;

import { capitalize } from "@mui/material";
import { getCommonFacetType } from "modules/saved-query/helper";
import { ScopeMetadata } from "modules/scope-metadata/types";
import { CoreTagsKeys } from "pages/assets/types";

export const getFacetDisplayName = (
	facetName: string,
	metadata?: ScopeMetadata,
	useCommonFacetName?: boolean
) => {
	if (useCommonFacetName) {
		facetName = getCommonFacetType(facetName);
	}

	let displayName = metadata?.columns[facetName]?.displayName ?? facetName;
	const columnName = metadata?.columns[facetName] ?? "";

	if (Object.values(CoreTagsKeys).includes(columnName as CoreTagsKeys)) {
		return capitalize(displayName ?? "");
	} else {
		return displayName;
	}
};

import { Box, Stack } from "@mui/material";
import { UnmanagedDevicesColumnRenderProps } from "../../appliance-unmanaged-devices/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function UnmanagedDeviceIP({
	device,
	viewOnly,
}: UnmanagedDevicesColumnRenderProps) {
	if (!device?.deviceId) {
		return <Box style={{ width: 70 }} />;
	}

	return (
		<Stack
			sx={{ width: !viewOnly ? "100%" : undefined }}
			direction={viewOnly ? "row" : "column"}
			alignItems={viewOnly ? "center" : undefined}
		>
			<CTDatagridButton
				isDisabled={viewOnly}
				asText={viewOnly}
				facetName={"deviceip"}
				facetOption={device.deviceIp ?? ""}
			>
				{window.getCTTranslatedText(device?.deviceIp)}
			</CTDatagridButton>
		</Stack>
	);
}

import { useMutation } from "@tanstack/react-query";
import { IdP } from "./../types";

interface Metadata {
	facets: any;
	total: number;
}

export interface IDPsResponse {
	items: IdP[];
	metadata: Metadata;
}

export function useIDPListAPI() {
	return useMutation<IDPsResponse, Error, any>([
		"idplist",
		"scim/idps/actions/search?computeTotal=true",
		"post",
	]);
}

export const CORE_TAGS_ALPHA: { [key: string]: boolean } = {};

export const SERVICE_CORE_TAGS: { [key: string]: boolean } = {
	clusteridentifier: true,
	containernamespace: true,
};
export const NOT_SERVICE_CORE_TAGS: { [key: string]: boolean } = {
	department: true,
	owner: true,
	primarysubnet: true,
	usergroup: true,
};

export const Device_CORE_TAGS: { [key: string]: boolean } = {
	model: true,
	category: true,
	subcategory: true,
	manufacturer: true,
	kernelversion: true,
	serialnumber: true,
};

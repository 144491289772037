import { styled } from "@mui/material";

export const StyledDimensionsWrapper = styled("div")`
	display: grid;
	grid-auto-flow: column;
	gap: 1rem;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	margin-bottom: 6px;
	justify-items: center;

	& > * {
		&:first-of-type {
			justify-self: left;
		}
		&:last-of-type {
			justify-self: right;
		}
	}
`;

export const StyledSankeyChart = styled("svg")`
	g > path {
		transition: filter 400ms;
		will-change: filter;

		&:hover {
			filter: brightness(140%);
		}
	}
`;

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { AgentStatus } from "../agent-status";
import { ClusterIdentifier } from "../cluster-identifier/ClusterIdentifier";
import { AgentVersion } from "../agent-version";
import { AgentLogs } from "modules/agent-logs";
import { ServiceCount } from "../service-count";
export const BASE_CONTAINERS_COLUMNS: GridColDef[] = [
	{
		field: "agentStatus",
		headerName: "Agent Status",
		width: 140,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentStatus agent={params.row} />;
		},
	},
	{
		field: "clusterIdentifier",
		headerName: "Cluster Identifier",
		width: 140,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <ClusterIdentifier agent={params.row} />;
		},
	},
	{
		field: "currentVersion",
		headerName: "Agent Version",
		width: 140,
		flex: 0.4,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentVersion agent={params.row} />;
		},
	},
	{
		field: "agentCreated",
		headerName: "Agent Created",
		width: 140,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
	},
	{
		field: "serviceCount",
		headerName: "Service Count",
		width: 140,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <ServiceCount agent={params.row} />;
		},
	},
	{
		field: "logs",
		headerName: "Logs",
		width: 140,
		flex: 0.4,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentLogs agent={params.row} />;
		},
	},
];

import { Stack } from "@mui/system";
import { CTWidget } from "common/molecules/widget";
import { WidgetListItem } from "common/molecules/widget/components/widget-list-item";
import { ApplianceWidgetProps } from "pages/appliance-detail/types";
import { Appliance } from "pages/appliances/types";
import { PropertiesList } from "./utils";

interface WidgetProps {
	appliance?: Appliance;
}

const GeneralWidgetContent = ({ appliance }: WidgetProps) => {
	return (
		<Stack width="100%" spacing={2}>
			{PropertiesList.map(property => {
				return (
					<WidgetListItem
						key={property.name}
						keyText={window.getCTTranslatedText(property.name)}
						valueText={
							appliance
								? (appliance[property.valueProp as keyof Appliance] as string)
								: ""
						}
					/>
				);
			})}
		</Stack>
	);
};

export const ApplianceGeneral = ({
	appliance,
	isLoading,
}: ApplianceWidgetProps) => {
	return (
		<CTWidget
			title={window.getCTTranslatedText("general")}
			isLoading={isLoading}
			children={<GeneralWidgetContent appliance={appliance} />}
		/>
	);
};

import CriteriaChips from "modules/facets/components/criteria-chips/CriteriaChips";
import { useFirewallHostGroupFacetStore } from "../store";
import { FirewallHostGroupListItem } from "../types";

interface Props {
	data: FirewallHostGroupListItem;
}

function FirewallHostGroupCriteria({ data }: Props) {
	return (
		<CriteriaChips
			data={data}
			useFacetStore={useFirewallHostGroupFacetStore}
			isValid={!!data.crowdstrikeFirewallHostgroupId}
		/>
	);
}

export default FirewallHostGroupCriteria;

export interface GetVariantParams {
	asText: boolean;
	isFacetSelected?: boolean;
}

export interface GetColorParams {
	asText: boolean;
	color?: string;
	isFacetSelected?: boolean;
}

export const getVariant = ({
	asText,
	isFacetSelected,
}: GetVariantParams): "text" | "outlined" => {
	if (asText) {
		return "text";
	}

	if (isFacetSelected) {
		return "outlined";
	}

	return "text";
};

export const getColor = ({
	asText,
	color,
	isFacetSelected,
}: GetColorParams): "primary" | "inherit" => {
	if (asText) {
		return "inherit";
	}

	if (color || isFacetSelected) {
		return "primary";
	}

	return "inherit";
};

import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextUpdateHandler } from "common/atoms/ct-input-field/CTInputField";

const iconStyle = "rgba(0, 0, 0, 0.38)";
const IconStyleDarkMode = "rgba(255, 255, 255, 0.38)";

interface QuerySearchProps {
	searchText: string;
	handleUpdate: TextUpdateHandler;
}

export const QuerySearch = ({ searchText, handleUpdate }: QuerySearchProps) => {
	return (
		<Stack alignItems="flex-start" sx={{ width: "100%", px: 3 }}>
			<CTInputField
				field="query"
				displayName="Saved Query"
				placeholder="Lookup Saved Queries"
				value={searchText}
				handleUpdate={handleUpdate}
				type="search"
				showLabel={false}
				required={false}
				autoFocus={true}
				startIcon={
					<SearchIcon
						sx={{
							color: theme =>
								theme.palette.mode === "dark" ? IconStyleDarkMode : iconStyle,
						}}
					/>
				}
			/>
		</Stack>
	);
};

import { useMutation, useQuery } from "@tanstack/react-query";
import { AuthState } from "hooks/useAuthState";

export function useGetTnCAPI({ authState }: { authState: AuthState }) {
	return useQuery<any, Error>(["", "auth/user/eula"], {
		enabled: authState === AuthState.LoggedIn, // only call EULA when user is logged in
		refetchInterval: 600000, // polling every 10 mins
	});
}

export function useAcceptTnC() {
	const path = `auth/user/eula/accept `;
	return useMutation<any, Error, any>(["", path, "post"]);
}

import { useMutation, useQuery } from "@tanstack/react-query";
import noop from "lodash/noop";
import { Field } from "modules/form-fields/type";
import { Integration, IntegrationConfigType } from "../constants";

export function useIntegrationConfigAPI({
	staleTime,
	enabled = true,
	refetchOnWindowFocus,
	onSuccess = noop,
}: {
	staleTime: number;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
	onSuccess?: (data: IntegrationConfigType[]) => void;
}) {
	return useQuery<IntegrationConfigType[], Error, IntegrationConfigType[]>(
		["integration-config", "integrations"],
		{
			refetchOnWindowFocus,
			cacheTime: staleTime,
			enabled: enabled,
			onSuccess,
		}
	);
}

export function useIntegrationFieldsAPI(
	integrationType: string,
	integrationProvider: string
) {
	return useQuery<Field[], Error, any>([
		"integration-fields",
		`integrations/${integrationType}/${integrationProvider}/fields`,
	]);
}

export function useIntegrationGetAPI(
	integrationType: string,
	integrationProvider: string
) {
	return useQuery<Integration, Error, any>([
		"integration-detail",
		`integrations/${integrationType}/${integrationProvider}`,
	]);
}

export function useIntegrationUpdate(
	integrationType: string,
	integrationProvider: string,
	methodType: string = "post"
) {
	const path = `integrations/${integrationType}/${integrationProvider}`;
	return useMutation<Integration, Error, any>([
		"integration-update",
		path,
		methodType,
	]);
}

export function useTestIntegrationConnection(
	integrationType: string,
	integrationProvider: string
) {
	const path = `integrations/${integrationType}/${integrationProvider}/validate`;
	return useMutation<Integration, Error, any>([
		"integration-validate",
		path,
		"post",
	]);
}

export function useIntegrationActionConnection() {
	const path = `integrations/actions`;
	return useMutation<any, Error, any>(["integration-validate", path, "post"]);
}

export function useServicenowAttributeAPI(
	integrationType: string,
	integrationProvider: string
) {
	const path = `integrations/${integrationType}/${integrationProvider}/attributes`;
	return useMutation<any, Error, any>(["integration-update", path, "post"]);
}

export enum DRAWER_TYPE {
	APPLIANCE_RESTART = "Appliance restart",
	APPLIANCE_UPGRADE = "Appliance upgrade",
	APPLIANCE_AUTO_UPGRADE = "Appliance auto upgrade",
	APPLIANCE_CONNECT_DIAGNOSTIC = "Appliance connect diagnostic",
	AGENT_CONNECT_DIAGNOSTIC = "Agent connect diagnostic",
	AGENT_RESTART = "Agent restart",
	AGENT_UPGRADE = "Agent upgrade",
	AGENT_DECOMMISSION = "Agent decommission",
	AGENT_DEBUG_LOG = "Agent debug Log",
	AGENT_TRAFFIC_CONFIG = "Agent traffic config",
	AGENT_TRAFFIC_CONFIG_NORTH_SOUTH = "Agent traffic config north-south",
	AGENT_AUTO_UPGRADE = "Agent auto upgrade",
	AGENT_POLICY_TAMPERING = "Agent Policy tamper monitoring",
	AGENT_OFFLINE_PASSWORD = "Agent offline password",
}

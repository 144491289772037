import { useAssetTemplates } from "modules/asset-templates/hooks";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { AssignedTemplatesViewDrawer } from "modules/asset-templates/assigned-templates-view-drawer";
import { AssignTemplatesOption } from "pages/tags/components/tag-policy-list/components/tag-templates/components/assigned-templates-tags/AssignedTemplatesTags";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useUserGroupStore } from "pages/users-segmentation/components/user-groups/store";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";

interface UserGroupAssignedTemplatesDrawerProps {
	viewOnly?: boolean;
	criteria: string;
	multiselect?: boolean;
	onSelectCallback?: Function;
	showNonWindows?: boolean;
	showAssignedTemplatesDrawer: boolean;
	closeAssignedTemplatesDrawer: Function;
}
export function UserGroupAssignedTemplatesDrawer({
	criteria,
	multiselect = true,
	onSelectCallback,
	showNonWindows = false,
	showAssignedTemplatesDrawer,
	closeAssignedTemplatesDrawer,
}: Readonly<UserGroupAssignedTemplatesDrawerProps>) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const requestAPIRefresh = useUserGroupStore(store => store.requestAPIRefresh);

	const {
		showAddToTemplateDrawer,
		openAddToTemplateDrawer,
		closeAddToTemplateDrawer,
		templateList,
		templateListMutation,
		onSortChange,
		setPageSize,
		setPage,
		pageSize,
		page: pageCount,
		totalCount,
		onSearchCriteriaChange,
	} = useAssetTemplates({ criteria });

	const hasPermission =
		userPermissions.has("UPDATE_ASSET") &&
		userPermissions.has("UPDATE_TEMPLATE");

	return (
		<>
			{showAssignedTemplatesDrawer && (
				<>
					<AddToTemplateDrawer
						isOpen={showAddToTemplateDrawer}
						page={"assets"}
						title="Assign Template"
						rules={[]}
						onCancel={closeAddToTemplateDrawer}
						onConfirm={closeAddToTemplateDrawer}
						btnTitle={"assign"}
						actionType={TemplateActionType.assign}
						criteria={criteria}
						multiselect={multiselect}
						onSelectCallback={onSelectCallback}
						showNonWindows={showNonWindows}
						hasPermission={hasPermission}
					/>

					<AssignedTemplatesViewDrawer
						isOpen={showAssignedTemplatesDrawer}
						onClose={() => {
							closeAssignedTemplatesDrawer();
							requestAPIRefresh();
						}}
						templateList={templateList}
						templatesSuggestMutation={templateListMutation}
						page={"assets"}
						onSortChange={onSortChange}
						AssignTemplatesOption={() =>
							AssignTemplatesOption({
								openAddToTemplateDrawer,
								hasPermission,
							})
						}
						multiselect={multiselect}
						criteria={criteria}
						hasPermission={hasPermission}
						setPage={setPage}
						setPageSize={setPageSize}
						pageCount={pageCount}
						pageSize={pageSize}
						totalCount={totalCount}
						onSearchCriteriaChange={onSearchCriteriaChange}
					/>
				</>
			)}
		</>
	);
}

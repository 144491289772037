import { Box, Grid, Link, Stack, Tooltip, Typography } from "@mui/material";
import { AssetColumnRenderProps } from "pages/assets/types";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { SecurityPatchesDrawer } from "modules/security-patches-drawer";

export function AssetSecurityPatches({
	asset,
	viewOnly,
}: AssetColumnRenderProps) {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	if (!asset.assetId) {
		return <Box style={{ width: 150 }} />;
	}
	let missingValue = false;
	if (asset.securityPatches === null || asset.securityPatches === undefined) {
		missingValue = true;
	}
	const isClickable = !viewOnly && asset.securityPatches;

	return (
		<>
			<Stack alignItems="center" justifyContent="center">
				<Grid
					container
					direction="row"
					justifyContent="start"
					alignItems="center"
				>
					<Tooltip title={isClickable ? "View security patches" : ""}>
						{!isClickable ? (
							<Typography variant="body2" sx={{ color: "warning.main" }}>
								{missingValue ? "" : asset?.securityPatches}
							</Typography>
						) : (
							<Link
								typography={"body2"}
								component={RouterLink}
								to={`#`}
								onClick={() => setIsDrawerOpen(true)}
								sx={{ color: "warning.main" }}
							>
								{missingValue ? "" : asset?.securityPatches}
							</Link>
						)}
					</Tooltip>
				</Grid>
			</Stack>
			{isDrawerOpen && (
				<SecurityPatchesDrawer
					isOpen={isDrawerOpen}
					onClose={() => setIsDrawerOpen(false)}
					assetId={asset.assetId || ""}
					assetName={asset.assetName || ""}
				/>
			)}
		</>
	);
}

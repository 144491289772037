import { Box, styled, Tabs } from "@mui/material";

export const CtTabs = styled(Tabs)(({ theme }) => ({
	"& .MuiTab-root": {
		borderRadius: 1,
		opacity: 0.6,
	},
	"& .MuiTab-root.Mui-selected": {
		opacity: 1,
	},
}));

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	style?: React.CSSProperties;
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`asset-details-tabpanel-${index}`}
			aria-labelledby={`asset-details-tab-${index}`}
			{...other}
			style={{ width: "100%", ...props.style }}
		>
			{value === index && (
				<Box sx={{ width: "100%", height: "100%" }}>{children}</Box>
			)}
		</div>
	);
}

import {
	NOTIFY_ACTIONS,
	SubscriptionCallbackPayload,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useEffect } from "react";

export const WorkRequestToast = () => {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	useEffect(() => {
		const unsubscribe = useEventSubscriptionStore
			.getState()
			.subscribe(
				NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST,
				(payload?: SubscriptionCallbackPayload) => {
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						String(payload?.label),
						payload?.variables
					);
					return;
				}
			);

		return unsubscribe;
	}, [setSnackbar]);

	return null;
};

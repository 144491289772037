import {
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
} from "@mui/material";
import { AgentActionType } from "../agent-data-grid-toolbar/Toolbar";
import { AgentGroupByObj } from "../agent-drawers/hooks/useGroupByAgentData";
import { ARCHITECTURE_TYPE, PLATFORMS } from "./constants";

interface PlatformArchitecturesSelectionProps {
	page?: AgentActionType;
	platform: PLATFORMS;
	agentsGroupByPlatforms?: AgentGroupByObj;
	architecture: string;
	agentGrpByObj: AgentGroupByObj;
	setAgentsGroupByPlatforms?: (obj?: AgentGroupByObj) => void;
	setArchitecture: (str: string) => void;
	supportedPlatformArchitectures: ARCHITECTURE_TYPE[];
	index: number;
}

export const PlatformArchitecturesSelection = ({
	page,
	platform,
	agentsGroupByPlatforms,
	architecture,
	agentGrpByObj,
	setAgentsGroupByPlatforms,
	setArchitecture,
	supportedPlatformArchitectures,
	index,
}: PlatformArchitecturesSelectionProps) => {
	const updateGroupByPlatformsDataArch = (event: SelectChangeEvent) => {
		if (
			page === AgentActionType.UPGRADE &&
			agentsGroupByPlatforms &&
			platform &&
			agentsGroupByPlatforms[platform] &&
			event?.target?.value
		) {
			if (
				agentGrpByObj[platform][index].architecture !== event?.target?.value
			) {
				agentGrpByObj[platform][index].architecture = event?.target?.value;
				if (setAgentsGroupByPlatforms) {
					setAgentsGroupByPlatforms(agentGrpByObj);
				}
			}
		}
	};

	const handleChangeArch = (event: SelectChangeEvent) => {
		setArchitecture(event?.target?.value);
		updateGroupByPlatformsDataArch(event);
	};

	return (
		<FormControl>
			{platform === PLATFORMS.DOCKER || platform === PLATFORMS.OPENSHIFT ? (
				<Stack
					sx={{
						display: "flex",
						textAlign: "center",
						fontSize: "small",
						minWidth: 100,
					}}
				></Stack>
			) : (
				<Select
					displayEmpty
					sx={{
						display: "flex",
						textAlign: "center",
						fontSize: "small",
						minWidth: 100,
					}}
					value={architecture}
					inputProps={{ "aria-label": "Without label" }}
					onChange={handleChangeArch}
				>
					{supportedPlatformArchitectures?.map((arch: string, index: any) => (
						<MenuItem
							sx={{
								display: "flex",
								backgroundColor: "initial",
								fontSize: "small",
								justifyContent: "center",
							}}
							key={index}
							value={arch}
						>
							{window.getCTTranslatedText(arch)}
						</MenuItem>
					))}
				</Select>
			)}
		</FormControl>
	);
};

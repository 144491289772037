const BOM = "bom.colortokens.com";
const NG = "ng.colortokens.com";

export const getArtifactOrigin = () => {
	const { protocol, hostname } = window.location;

	if (hostname === BOM || hostname === NG) {
		return `${protocol}//artifacts.${hostname}`;
	}

	return `${protocol}//artifacts-${hostname}`;
};

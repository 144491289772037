import { Stack, Typography } from "@mui/material";
import numeral from "numeral";
import { TemplateTypeOptionsText } from "pages/templates/constants";
import {
	AddToTemplateListProps,
	Template,
	TemplateType,
} from "pages/templates/types";
import pluralize from "pluralize";

export interface SelectedTemplateItemProps {
	selectedTemplate: Template | AddToTemplateListProps;
	page: string;
}

export const SelectedTemplateItem = ({
	selectedTemplate,
	page,
}: SelectedTemplateItemProps) => {
	return (
		<Stack
			sx={{
				width: "100%",
				paddingLeft: "4px",
				mt: 5,
			}}
		>
			<Stack direction="column" style={{ width: "100%" }} spacing={3}>
				<Stack direction="row" spacing={2}>
					<Typography variant="subtitle2" style={{ width: "80px" }}>
						{window.getCTTranslatedText("Name")}:{" "}
					</Typography>
					<Typography noWrap variant="body2">
						{selectedTemplate?.templateName || ""}
					</Typography>
				</Stack>
				<Stack direction="row" spacing={2}>
					<Typography variant="subtitle2" style={{ width: "80px" }}>
						{window.getCTTranslatedText("Description")}:{" "}
					</Typography>
					<Typography noWrap variant="body2">
						{selectedTemplate?.templateDescription || ""}
					</Typography>
				</Stack>
				<Stack direction="row" spacing={2}>
					<Typography variant="subtitle2" style={{ width: "80px" }}>
						{window.getCTTranslatedText("Type")}:{" "}
					</Typography>
					<Typography noWrap variant="body2">
						{window.getCTTranslatedText(
							TemplateTypeOptionsText[
								selectedTemplate?.templateType ||
									TemplateType.ApplicationTemplate
							]
						)}
					</Typography>
				</Stack>
				{page !== "assets" && (
					<Stack direction="row" spacing={2}>
						<Typography variant="subtitle2" style={{ width: "80px" }}>
							{window.getCTTranslatedText("Impact")}:{" "}
						</Typography>
						<Typography noWrap variant="body2">
							{window.getCTTranslatedText("ImpactedAssets", {
								count: numeral(
									selectedTemplate?.templateAssignments || 0
								).format("0a"),
								type: window.getCTTranslatedText(
									pluralize("asset", selectedTemplate?.templateAssignments || 0)
								),
							})}
						</Typography>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

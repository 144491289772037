import { useTheme } from "@mui/material";

export const SamlIconSmall = () => {
	const theme = useTheme();
	return (
		<svg
			width="49"
			height="48"
			viewBox="0 0 49 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M23.7708 9.14258L24.8291 10.0798C25.6131 10.7746 26.3995 11.4672 27.1743 12.1713C32.1962 16.7314 36.4478 21.863 39.6479 27.7695C39.8125 28.0713 39.9611 28.3821 40.1097 28.6907L40.3337 29.1547L40.0868 29.285L39.9017 29.0221L39.4216 28.3387L39.0856 27.8495C36.7564 24.4688 34.4226 21.0836 31.2179 18.3749C30.2483 17.5541 29.1784 16.8597 28.0338 16.3085C25.716 15.1999 23.6542 15.6434 21.885 17.4629C20.2027 19.1932 19.3295 21.3419 18.5409 23.5271C18.438 23.8082 18.3306 24.0871 18.2232 24.366L18.0357 24.8574L17.6426 24.7317C17.8712 23.9362 18.0815 23.1362 18.2917 22.3339C18.7718 20.5167 19.2518 18.6995 19.9192 16.9486C20.6278 15.0834 21.5239 13.2844 22.4176 11.4855C22.8039 10.7061 23.1902 9.92661 23.5605 9.14258H23.7708Z"
				fill="#C22E33"
			/>
			<path
				d="M25.3568 31.5937C21.6401 33.3537 12.6478 35.1687 8.38251 34.7024C7.68534 31.0314 14.5267 13.8285 17.8869 11.2227L17.6697 11.721C17.5729 11.9555 17.4677 12.1865 17.3543 12.4136L17.2034 12.7084C15.4662 16.0503 13.7313 19.3967 12.8421 23.0631C12.5219 24.3259 12.347 25.6212 12.3209 26.9238C12.2981 29.9868 14.2044 32.0714 17.3885 32.2017C18.92 32.2634 20.4675 32.1034 22.0127 31.9411C22.6847 31.8725 23.3545 31.8017 24.0242 31.7491C24.2711 31.7308 24.5134 31.7011 24.8288 31.66L25.3568 31.596V31.5937Z"
				fill="#C22E33"
			/>
			<path
				d="M14.7909 38.3578C13.7737 38.4355 12.7565 38.5132 11.7439 38.6046C21.1088 39.4961 30.0988 37.9715 38.9677 34.8468C36.0762 29.7494 32.1789 25.7242 27.6485 22.2246C27.7079 22.4486 27.8405 22.6109 27.9708 22.7709L28.0805 22.9081C28.4691 23.4247 28.8759 23.9321 29.2851 24.4395C30.22 25.603 31.1549 26.7665 31.8589 28.0465C33.6372 31.2924 32.6726 34.0993 29.1891 35.5805C26.8987 36.5566 24.3958 37.2217 21.9203 37.6194C19.5659 37.9943 17.1772 38.1749 14.7909 38.3578Z"
				fill="#C22E33"
			/>
			<rect
				x="0.833252"
				y="0.5"
				width="47"
				height="47"
				rx="3.5"
				stroke={theme.palette.text.primary}
				strokeOpacity="0.12"
			/>
		</svg>
	);
};

import { SelectChangeEvent, Stack, useTheme } from "@mui/material";
import { AgentGroupByObj } from "../agent-drawers/hooks/useGroupByAgentData";
import { InstallationScript } from "./InstallationScript";
import { ManualInstallationScript } from "./ManualInstallationScript";
import { PlatformArchitecturesRadioGroup } from "./PlatformArchitecturesRadioGroup";
import { PlatformArchitecturesSelectCurrentVersion } from "./PlatformArchitecturesSelectCurrentVersion";
import { PlatformArchitecturesSelection } from "./PlatformArchitecturesSelection";
import {
	AGENT_TYPES,
	ARCHITECTURE_TYPE,
	PLATFORMS,
	agentTypeValues,
} from "./constants";
import {
	processPlatformDisplayName,
	renderProcessPlatformIcon,
} from "./helpers";
import { AgentVersion, IAgentTypes, VersionInfo } from "./type";

interface AgentDownloadItemContentProps extends AgentVersion {
	supportedPlatformArchitectures: ARCHITECTURE_TYPE[];
	selectedAgentType: (
		agentVersionTypeInfo: VersionInfo[],
		architecture: string,
		index: number
	) => string;
	highestCurrentAgentVersion: string;
	handleChange: (event: SelectChangeEvent, index: number) => void;
	architecture: string;
	setArchitecture: (str: string) => void;
	agentGrpByObj: AgentGroupByObj;
	selectedOption: AGENT_TYPES;
	agentVersionTypeInfo: VersionInfo[];
	setSelectedOption: (agent: AGENT_TYPES) => void;
	setHighestAgentVersion: (str: string) => void;
}

export const AgentDownloadItemContent = ({
	platform,
	supportedPlatformArchitectures,
	agentsGroupByPlatforms,
	selectedAgentType,
	highestCurrentAgentVersion,
	page,
	handleChange,
	selectedOption,
	architecture,
	agentGrpByObj,
	agentType,
	latestDepKey,
	setAgentsGroupByPlatforms,
	agentVersionTypeInfo,
	setArchitecture,
	latestLinuxScriptDetails,
	latestWindowsScriptDetails,
	setSelectedOption,
	setHighestAgentVersion,
}: AgentDownloadItemContentProps) => {
	const theme = useTheme();
	const selectedAgent = agentTypeValues[selectedOption as keyof IAgentTypes];
	const linuxScriptDownloadLink =
		latestLinuxScriptDetails?.versionsInfo[0]?.downloadLink;
	const windowsScriptDownloadLink =
		latestWindowsScriptDetails?.versionsInfo[0]?.downloadLink;

	const selectedAgentTypeValue = selectedAgentType(
		agentVersionTypeInfo,
		architecture,
		0
	);

	return (
		<>
			<Stack direction="row" gap={1.5} minWidth={225} alignItems={"center"}>
				{renderProcessPlatformIcon(platform, theme.palette.text.primary)}
				{processPlatformDisplayName(platform)}
			</Stack>

			<PlatformArchitecturesSelection
				page={page}
				platform={platform}
				agentsGroupByPlatforms={agentsGroupByPlatforms}
				architecture={architecture}
				agentGrpByObj={agentGrpByObj}
				setAgentsGroupByPlatforms={setAgentsGroupByPlatforms}
				setArchitecture={setArchitecture}
				supportedPlatformArchitectures={supportedPlatformArchitectures}
				index={0}
			/>

			{agentType !== AGENT_TYPES.CT_REMOTE_CONNECTOR && (
				<PlatformArchitecturesRadioGroup
					platform={platform}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
				/>
			)}

			<PlatformArchitecturesSelectCurrentVersion
				selectedAgentType={selectedAgentType}
				handleChange={handleChange}
				agentVersionTypeInfo={agentVersionTypeInfo}
				highestCurrentAgentVersion={highestCurrentAgentVersion}
				architecture={architecture}
				setHighestAgentVersion={setHighestAgentVersion}
				page={page}
				platform={platform}
			/>

			{(platform === PLATFORMS.DOCKER || platform === PLATFORMS.OPENSHIFT) && (
				<Stack sx={{ minWidth: 200 }} />
			)}

			{!(platform === PLATFORMS.DOCKER || platform === PLATFORMS.OPENSHIFT) && (
				<InstallationScript
					agentVersionTypeInfo={agentVersionTypeInfo}
					platform={platform}
					agentType={agentType}
					selectedOption={selectedOption}
					selectedAgent={selectedAgent}
					latestDepKey={latestDepKey}
					selectedAgentType={selectedAgentTypeValue}
					linuxScriptDownloadLink={linuxScriptDownloadLink}
					windowsScriptDownloadLink={windowsScriptDownloadLink}
				/>
			)}

			<ManualInstallationScript
				agentVersionTypeInfo={agentVersionTypeInfo}
				platform={platform}
				agentType={agentType}
				selectedOption={selectedOption}
				selectedAgent={selectedAgent}
				latestDepKey={latestDepKey}
				selectedAgentType={selectedAgentTypeValue}
			/>
		</>
	);
};

import { Button, Stack, Tooltip } from "@mui/material";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useState } from "react";
import { useAlertConfigStore } from "../../store";
import {
	AlertRuleConfig,
	AlertNotificationStatus,
	AlertRuleStatus,
} from "../../types";
import { AlertRuleChangeStatusDrawer } from "../alert-rule-change-status-drawer";
import { AlertConfigToolbarAction } from "./AlertConfigToolbarAction";
import { AlertRuleNotificationConfigureDrawer } from "../alert-rule-notification-configure-drawer";
import { NotificationStatusChangeDrawer } from "../notification-status-change-drawer/NotificationStatusChangeDrawer";
import { ConfigureRuleDialog } from "../alert-rule-actions/AlertRuleActions";
import { NotificationConfigDrawer } from "pages/settings/components/notification-config";

export interface UserGroupToolbarProps extends Omit<ToolbarProps, "children"> {
	selectedData: Array<AlertRuleConfig> | undefined;
	hideToolbar: () => void;
}

export function AlertConfigToolbar(props: Readonly<UserGroupToolbarProps>) {
	const requestAPIRefresh = useAlertConfigStore(
		store => store.requestAPIRefresh
	);
	const notificationStatusDisabled =
		props?.selectedData?.every(
			ele => ele.alertRuleStatus === AlertRuleStatus.Disabled
		) ?? false;
	const [data, setData] = useState<AlertRuleConfig[]>([]);
	const [showChangeStatusDrawer, setShowChangeStatusDrawer] = useState(false);
	const [
		showNotificationChangeStatusDrawer,
		setShowNotificationChangeStatusDrawer,
	] = useState(false);
	const [showConfigureNotificationDrawer, setShowConfigureNotificationDrawer] =
		useState(false);
	const [showConfigureDialog, setShowConfigureDialog] = useState(false);
	const [selectedEnabledAlertRuleIDs, setSelectedEnabledAlertRuleIDs] =
		useState<string[]>([]);
	const [selectedOperation, setSelectedOperation] = useState<
		AlertNotificationStatus | undefined
	>();
	const handleAlertRuleButtonChange = (
		btnActionType: AlertNotificationStatus
	) => {
		setSelectedOperation(btnActionType);
		setShowChangeStatusDrawer(true);
	};

	const handleNotificaitonStatusButtonChange = (
		btnActionType: AlertNotificationStatus
	) => {
		setSelectedOperation(btnActionType);
		setShowNotificationChangeStatusDrawer(true);
	};
	const handleConfirm = () => {
		setSelectedOperation(undefined);
		setShowChangeStatusDrawer(false);
		setShowNotificationChangeStatusDrawer(false);
		requestAPIRefresh();
		props.hideToolbar();
	};
	const handleNotificationConfirm = (data: AlertRuleConfig[]) => {
		setShowNotificationChangeStatusDrawer(false);
		if (selectedOperation === AlertNotificationStatus.On) {
			setData(data);
			setShowConfigureDialog(true);
		}
		setSelectedOperation(undefined);
		requestAPIRefresh();
		props.hideToolbar();
	};

	const handleConfigureNotification = () => {
		if (props.selectedData) {
			const areAllEnabled = props.selectedData?.every(
				item => item.alertRuleStatus !== AlertRuleStatus.Disabled
			);
			if (areAllEnabled) {
				setSelectedEnabledAlertRuleIDs(
					props.selectedData.map(data => data.alertRuleId)
				);
			} else {
				setShowConfigureNotificationDrawer(true);
			}
		}
	};

	let tooltipText = "";

	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}>
			<Stack
				direction="row"
				spacing={2}
				sx={{ width: "100%", p: 1 }}
				justifyItems="flex-end"
				alignItems={"flex-end"}
				justifyContent="flex-end"
			>
				<Stack direction="row" alignItems={"center"}>
					<Stack direction="row">
						{(props.selectedData?.length ?? 0) > 0 && (
							<>
								<AlertConfigToolbarAction
									{...{
										notificationStatusDisabled,
										handleAlertRuleButtonChange,
										handleNotificaitonStatusButtonChange,
									}}
								/>
								<Tooltip title={`${window.getCTTranslatedText(tooltipText)}`}>
									<Button
										variant="outlined"
										aria-label="dismiss"
										onClick={handleConfigureNotification}
										sx={{ ml: 2 }}
										disabled={props.selectedData?.every(
											item => item.alertRuleStatus === AlertRuleStatus.Disabled
										)}
									>
										{window.getCTTranslatedText("Configure Notifications")}
									</Button>
								</Tooltip>
							</>
						)}

						{showChangeStatusDrawer && (
							<AlertRuleChangeStatusDrawer
								isOpen={showChangeStatusDrawer}
								selectedStatus={selectedOperation}
								selectedData={props?.selectedData}
								title={`${window.getCTTranslatedText("changeAlertRuleStatus")}`}
								confirm={handleConfirm}
								onClose={() => {
									setShowChangeStatusDrawer(false);
									setSelectedOperation(undefined);
									props.hideToolbar();
								}}
							/>
						)}

						{showNotificationChangeStatusDrawer && (
							<NotificationStatusChangeDrawer
								isOpen={showNotificationChangeStatusDrawer}
								selectedStatus={selectedOperation}
								selectedData={props?.selectedData}
								title={`${window.getCTTranslatedText(
									"changeNotificationStatus"
								)}`}
								confirm={handleNotificationConfirm}
								onClose={() => {
									setShowNotificationChangeStatusDrawer(false);
									setSelectedOperation(undefined);
								}}
							/>
						)}
						{showConfigureNotificationDrawer && (
							<AlertRuleNotificationConfigureDrawer
								isOpen={showConfigureNotificationDrawer}
								selectedData={props?.selectedData}
								title={`${window.getCTTranslatedText(
									"Configure Notifications"
								)}`}
								confirm={setSelectedEnabledAlertRuleIDs}
								onClose={() => {
									setShowConfigureNotificationDrawer(false);
								}}
							/>
						)}
						{showConfigureDialog && (
							<ConfigureRuleDialog
								onConfirm={setSelectedEnabledAlertRuleIDs}
								onClose={() => setShowConfigureDialog(false)}
								data={data}
							/>
						)}

						<NotificationConfigDrawer
							isOpen={Boolean(selectedEnabledAlertRuleIDs.length)}
							onClose={() => setSelectedEnabledAlertRuleIDs([])}
							alertRules={selectedEnabledAlertRuleIDs}
						/>
					</Stack>
				</Stack>
			</Stack>
		</GridToolbar>
	);
}

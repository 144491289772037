import { useTheme } from "@mui/material";

export const ArmisIcon = () => {
	const theme = useTheme();
	return (
		<svg
			width="49"
			height="48"
			viewBox="0 0 49 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill="#41237b"
				opacity="1.000000"
				stroke="none"
				d="M 40.034 22.972 C 40.207 23.344 40.513 23.575 40.451 24.162 C 39.373 24.162 38.277 24.213 37.187 24.15 C 35.278 24.039 33.943 24.801 32.911 26.511 C 30.228 30.949 27.425 35.31 24.683 39.71 C 24.317 40.298 23.943 40.706 23.168 40.701 C 18.031 40.667 12.893 40.682 7.755 40.676 C 7.525 40.675 7.297 40.625 7.105 40.603 C 6.909 40.279 7.095 40.09 7.223 39.892 C 12.704 31.507 18.185 23.122 23.666 14.738 C 25.064 12.598 26.471 10.464 27.861 8.318 C 28.812 6.847 29.494 6.791 30.436 8.229 C 33.637 13.11 36.812 18.011 40.034 22.972 Z"
			></path>
			<rect
				x="0.833252"
				y="0.5"
				width="47"
				height="47"
				rx="3.5"
				stroke={theme.palette.text.primary}
				strokeOpacity="0.12"
			/>
		</svg>
	);
};

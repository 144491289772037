import { styled } from "@mui/material";
import Box from "@mui/material/Box";

const TerminalButtons = styled(Box)(() => ({
	height: "10px",
	width: "10px",
	position: "relative",
	borderRadius: "50%",
	display: "inline-block",
}));

const TerminalBody = styled(Box)(() => ({
	width: "100%",
	boxSizing: "border-box",
	margin: "0 auto",
}));

export const TerminalCross = styled(TerminalButtons)(() => ({
	border: "1px solid #9d252b",
	left: "6px",
	backgroundColor: "#ff3b47",
}));

export const TerminalMinimize = styled(TerminalButtons)(() => ({
	border: "1px solid #9d802c",
	left: "11px",
	backgroundColor: "#ffc100",
}));

export const TerminalZoom = styled(TerminalButtons)(() => ({
	border: "1px solid #049931",
	left: "16px",
	backgroundColor: "#00d742",
}));
export const TerminalMenu = styled(TerminalBody)(() => ({
	height: "25px",
	backgroundColor: "#bbb",
	borderTopLeftRadius: "5px",
	borderTopRightRadius: "5px",
}));

export const TerminalScreen = styled(TerminalBody)(() => ({
	backgroundColor: "rgba(0, 0, 0, 0.87)",
	padding: "20px",
	borderBottomLeftRadius: "5px",
	borderBottomRightRadius: "5px",
}));

export const TerminalParagraph = styled(Box)(() => ({
	color: "#d6d6d6",
}));

export function Terminal({ children }: { children: React.ReactElement }) {
	return (
		<>
			<TerminalMenu>
				<TerminalCross />
				<TerminalMinimize />
				<TerminalZoom />
			</TerminalMenu>
			<TerminalScreen>
				<TerminalParagraph>{children}</TerminalParagraph>
			</TerminalScreen>
		</>
	);
}

import { Box, Button, Tooltip } from "@mui/material";
import { dayjsWithPlugins } from "common/utils";
import { API_URL } from "hooks/useNetworking/constants";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { LogsDrawer } from "modules/logs-drawer";
import { compareVersions } from "pages/agents/components/agent-data-grid/helpers";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";
import { AssetReviewDrawer } from "pages/asset/components/asset-detail/components/asset-review-drawer";
import { FirewallLogs, LogOptions } from "pages/asset/types";
import { Asset, AssetType } from "pages/assets/types";
import { PathStatus } from "pages/paths/types";
import prettyBytes from "pretty-bytes";
import { useMemo, useState } from "react";
import { FirewallDecisionLogsFetcher } from "./FirewallDecisionLogsFetcher";
import { FacetState, Operator } from "modules/facets/types";

const readOnlyDimensions = ["enforced"];

export enum LogType {
	Path = 1,
	Raw = 2,
}
interface ViewFirewallDecisionLogsButtonProps {
	asset?: Asset;
	agentId: string;
	logType?: LogType;
}

export const ViewFirewallDecisionLogsButton = ({
	asset,
	agentId,
	logType,
}: ViewFirewallDecisionLogsButtonProps) => {
	const [isAssetReviewDrawerOpen, setIsAssetReviewDrawerOpen] = useState(false);
	const [isViewLogsDrawerOpen, setIsViewLogsDrawerOpen] = useState(false);
	const [optionsData, setOptionsData] = useState<FirewallLogs>();
	const [areOptionsLoading, setAreOptionsLoading] = useState(true);
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const logOptions: LogOptions = useMemo(
		() =>
			optionsData?.items?.map((item, index) => ({
				value: item.lastModified,
				label: `${index === 0 ? "Active (" : ""} ${dayjsWithPlugins(
					item.lastModified
				).format("DD-MMM-YY HH:mm:ss")} ${index === 0 ? ")" : ""}`,
				id: item.name,
				fileSize: prettyBytes(item.sizeInBytes),
			})),
		[optionsData]
	);

	const handlerAssetReviewDrawerOpen = () => setIsAssetReviewDrawerOpen(true);
	const handlerAssetReviewDrawerClose = () => setIsAssetReviewDrawerOpen(false);
	const handlerViewLogsDrawerOpen = () => setIsViewLogsDrawerOpen(true);
	const handlerViewLogsDrawerClosed = () => setIsViewLogsDrawerOpen(false);

	const handlerOnLogsClick = () => {
		let isPathLogsSupported =
			asset?.type === AssetType.Service ||
			asset?.type === AssetType.Device ||
			compareVersions(asset?.agentVersion ?? "0.0.0", "5.8.6");

		if (logType === LogType.Raw || !isPathLogsSupported) {
			return handlerViewLogsDrawerOpen();
		}

		return handlerAssetReviewDrawerOpen();
	};

	const pathFacetState: FacetState = new Map([
		[
			"enforced",
			new Map([
				[
					PathStatus.AllowTestDenied,
					{ isSelected: true, operator: Operator.EQUAL },
				],
				[
					PathStatus.AllowTestDeniedViolation,
					{ isSelected: true, operator: Operator.EQUAL },
				],
				[PathStatus.Deny, { isSelected: true, operator: Operator.EQUAL }],
				[
					PathStatus.DeniedByTemplate,
					{ isSelected: true, operator: Operator.EQUAL },
				],
			]),
		],
	]);

	return (
		<>
			{isViewLogsDrawerOpen && (
				<FirewallDecisionLogsFetcher
					agentId={agentId}
					setOptionsData={setOptionsData}
					setAreOptionsLoading={setAreOptionsLoading}
				/>
			)}
			{isAssetReviewDrawerOpen && (
				<AssetReviewDrawer
					isOpen={isAssetReviewDrawerOpen}
					onClose={handlerAssetReviewDrawerClose}
					asset={asset}
					title="Firewall Logs"
					mode={AssetReviewModeType.FireWallReview}
					direction={"outbound"}
					pathFacetState={pathFacetState}
					readOnlyDimensions={readOnlyDimensions}
					activeTab={1}
				/>
			)}
			<LogsDrawer
				isOpen={isViewLogsDrawerOpen}
				onClose={handlerViewLogsDrawerClosed}
				label="Firewall Logs"
				areOptionsLoading={areOptionsLoading}
				logOptions={logOptions}
				downloadPrefix={`${API_URL}/agents/${agentId}/firewall-decision-logs/`}
				hasPermission={userPermissions.has("READ_FIREWALL_LOG")}
			/>
			<Tooltip
				title={window.getCTTranslatedText(!agentId ? "Agent Unavailable" : "")}
			>
				<Box>
					<Button
						variant="outlined"
						color={logType === LogType.Raw ? "info" : "warning"}
						size={logType === LogType.Raw ? "small" : "medium"}
						disabled={!agentId}
						onClick={handlerOnLogsClick}
					>
						{window.getCTTranslatedText(
							logType === LogType.Raw ? "View Raw Log" : "Log"
						)}
					</Button>
				</Box>
			</Tooltip>
		</>
	);
};

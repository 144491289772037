import {
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Tooltip,
	Typography,
} from "@mui/material";
import { useEffect } from "react";
import { AgentActionType } from "../agent-data-grid-toolbar/Toolbar";
import { isHigherOrEqualVersions } from "../agent-data-grid/helpers/columnHelpers";
import { AgentGroupByObj } from "../agent-drawers/hooks/useGroupByAgentData";
import { PLATFORMS } from "./constants";
import { highestVersionAgentInSelection } from "./helpers";
import { VersionInfo } from "./type";

interface PlatformArchitecturesSelectCurrentVersionProps {
	selectedAgentType: (
		agentVersionTypeInfo: VersionInfo[],
		architecture: string,
		index: number
	) => string;
	agentVersionTypeInfo: VersionInfo[];
	highestCurrentAgentVersion: string;
	handleChange: (event: SelectChangeEvent, index: number) => void;
	index?: number;
	architecture: string;
	page?: AgentActionType;
	platform: PLATFORMS;
	agentsGroupByPlatforms?: AgentGroupByObj;
	setHighestAgentVersion: (str: string) => void;
}

export const PlatformArchitecturesSelectCurrentVersion = ({
	selectedAgentType,
	handleChange,
	agentVersionTypeInfo,
	highestCurrentAgentVersion,
	index = 0,
	architecture,
	page,
	platform,
	agentsGroupByPlatforms,
	setHighestAgentVersion,
}: PlatformArchitecturesSelectCurrentVersionProps) => {
	useEffect(() => {
		const setCurrentVersion = (currentVersion?: string) => {
			if (
				page === AgentActionType.UPGRADE &&
				agentsGroupByPlatforms &&
				platform &&
				agentsGroupByPlatforms[platform] &&
				currentVersion
			) {
				highestVersionAgentInSelection({
					page,
					platform,
					agentsGroupByPlatforms,
					highestCurrentAgentVersion,
					setHighestAgentVersion,
					index,
				});

				let agentGrpByObj = { ...agentsGroupByPlatforms };
				if (agentGrpByObj[platform][index].version !== currentVersion) {
					agentGrpByObj[platform][index].version = currentVersion;
				}
			}
		};

		if (selectedAgentType) {
			setCurrentVersion(
				selectedAgentType(agentVersionTypeInfo, architecture, index)
			);
		}
	}, [
		highestCurrentAgentVersion,
		page,
		agentsGroupByPlatforms,
		platform,
		selectedAgentType,
		agentVersionTypeInfo,
		architecture,
		setHighestAgentVersion,
		index,
	]);

	return (
		<FormControl>
			<Select
				displayEmpty
				sx={{
					display: "flex",
					textAlign: "left",
					fontSize: "small",
					minWidth: 220,
				}}
				value={selectedAgentType(agentVersionTypeInfo, architecture, index)}
				inputProps={{ "aria-label": "Without label" }}
				onChange={e => handleChange(e, index)}
			>
				{agentVersionTypeInfo?.map((vName: any, index: any) => (
					<MenuItem
						sx={{
							display: "flex",
							backgroundColor: "initial",
							fontSize: "small",
							justifyContent: "flex-start",
						}}
						key={index}
						value={vName?.version}
						disabled={isHigherOrEqualVersions(
							highestCurrentAgentVersion,
							vName?.version
						)}
					>
						<Tooltip
							placement="left"
							title={window.getCTTranslatedText(
								isHigherOrEqualVersions(
									highestCurrentAgentVersion,
									vName?.version
								)
									? `This version is lower than the current running version of agents selected`
									: ""
							)}
						>
							<Typography variant="body2" sx={{ pointerEvents: "all" }}>
								{vName?.version}
							</Typography>
						</Tooltip>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

import { Box, Stack } from "@mui/material";
import { useCommonStore } from "common/store";
import { AssetCoreTags } from "modules/asset-core-tags/components";
import { PathColumnRenderProps } from "pages/paths/types";

export function PathDestinationTags({
	path,
	viewOnly,
	useFacetStore = useCommonStore,
}: PathColumnRenderProps) {
	if (!path.channelHash) {
		return <Box style={{ width: 80, minHeight: 45 }} />;
	}

	const coreTags = path?.destinationAsset?.coreTags ?? {};

	const renderContent = () => {
		let canDisplayTags = false;

		if (
			path.destinationNamedNetwork &&
			path.destinationNamedNetwork.namedNetworkId &&
			path?.destinationNamedNetwork?.namedNetworkName !== ""
		) {
			canDisplayTags = false;
		} else if (
			path?.destinationAsset &&
			path.destinationAsset?.assetName?.length > 0
		) {
			canDisplayTags = true;
		}

		return (
			<AssetCoreTags
				coreTags={canDisplayTags ? coreTags : {}}
				useAssetCoreTagsStore={useFacetStore}
				viewOnly={viewOnly}
			/>
		);
	};
	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
			{renderContent()}
		</Stack>
	);
}

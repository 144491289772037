import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useCommonStore } from "common/store";
import { PortColumnRenderProps } from "pages/ports/types";
import { PortProcessDrawer } from "../port-process-drawer.tsx";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function PortProcess({
	port,
	viewOnly,
	useFacetStore = useCommonStore,
}: PortColumnRenderProps) {
	if (!port.lpId) {
		return <Box style={{ width: 160, minHeight: 45 }} />;
	}

	const firstProcessName = port?.listenProcessNames?.[0];

	return (
		<Stack
			sx={{ maxWidth: "100%" }}
			alignItems="left"
			justifyContent="left"
			direction="row"
		>
			<Typography
				variant="body2"
				style={{
					overflow: "hidden",
					textOverflow: "ellipsis",
				}}
			>
				{firstProcessName ? (
					<Stack direction={"row"}>
						<Tooltip title={firstProcessName}>
							<CTDatagridButton
								asText={viewOnly === true}
								isDisabled={viewOnly === true}
								useFacetStore={useFacetStore}
								facetName={"listenprocessnames"}
								facetOption={firstProcessName}
							>
								{firstProcessName || ""}
							</CTDatagridButton>
						</Tooltip>
						<PortProcessDrawer port={port} />
					</Stack>
				) : null}
			</Typography>
		</Stack>
	);
}

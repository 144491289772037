import { Box, Stack, Tooltip } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { UserGroup } from "pages/users-segmentation/components/user-groups/types";

const nameStyle = {
	width: "100%",
	maxWidth: "100%",
	overflow: "hidden",
};

export interface UserGroupColumnRenderProps {
	readonly userGroup: UserGroup;
	viewOnly?: boolean;
}

export function UserGroupName({
	userGroup,
	viewOnly,
}: UserGroupColumnRenderProps) {
	if (!userGroup.groupID) {
		return <Box style={{ minWidth: 160 }}></Box>;
	}

	const facetName = "groupname";
	const optionName = userGroup?.groupName?.toString();

	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing={1}
			sx={{ width: "100%" }}
		>
			<Stack sx={nameStyle}>
				<Tooltip
					title={userGroup.groupName}
					placement="bottom-start"
					arrow={false}
				>
					<CTDatagridButton
						asText={viewOnly === true}
						isDisabled={viewOnly === true}
						facetName={facetName}
						facetOption={optionName ?? ""}
					>
						{userGroup.groupName}
					</CTDatagridButton>
				</Tooltip>
			</Stack>
		</Stack>
	);
}

import { CTConfirmation } from "common/atoms/ct-confirmation";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useRebootApplianceAPI } from "pages/appliance-detail/hooks/useRebootApplianceAPI";
import { useApplianceStore } from "pages/appliances/store";
import { ApplianceRebootProps } from "../types";

export const ApplianceReboot = ({
	agent,
	showReboot,
	setShowReboot,
}: ApplianceRebootProps) => {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const applianceRebootMutation = useRebootApplianceAPI(agent?.agentId);
	const requestAPIRefresh = useApplianceStore(state => state.requestAPIRefresh);

	const rebootAppliance = () => {
		applianceRebootMutation.mutateAsync(
			{},
			{
				onSuccess(response) {
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						window.getCTTranslatedText("rebootGatekeeperSuccess")
					);
					setShowReboot(false);
					requestAPIRefresh();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			}
		);
	};
	return (
		<CTConfirmation
			open={showReboot}
			onClose={() => setShowReboot(false)}
			title={window.getCTTranslatedText("rebootGatekeeper")}
			primaryText={window.getCTTranslatedText("gatekeeperRebootConfirmation")}
			secondaryText={window.getCTTranslatedText("gatekeeperRebootNote")}
			primaryButtonText={window.getCTTranslatedText("rebootButton")}
			isLoading={applianceRebootMutation.isLoading}
			onSuccess={rebootAppliance}
		/>
	);
};

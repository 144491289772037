import { Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useApplianceStore } from "pages/appliances/store";
import { Appliance } from "pages/appliances/types";
import { getPendingActionsTooltip } from "pages/appliances/utils";
import { useMemo, useState } from "react";
import { AgentBaseDrawer } from "../agent-base-drawer";
import { DRAWER_TYPE } from "../agent-base-drawer/types";
import { ApplianceActivation } from "../appliance-activation";
import { isDiagnosticsAllowed } from "../appliance-data-grid/helpers/columnHelpers";
import { ApplianceDownload } from "../appliance-download";
import {
	AutoUpgradeActions,
	DiagnosticActions,
	RestartActions,
	UpgradeActions,
} from "./ToolbarActions";

export interface ApplianceToolbarProps extends Omit<ToolbarProps, "children"> {
	selectedData: Array<Appliance> | undefined;
	selection: Array<GridRowId>;
	hideToolbar: Function;
	showPortStatusOption?: boolean;
	recommendationId?: string;
}
export enum AgentActionType {
	RESTART = "restart",
	UPGRADE = "upgrade",
	AUTOUPGRADE = "AUTOUPGRADE",
	DIAGNOSTIC = "diagnostic",
}
export default function ApplianceToolbar(props: ApplianceToolbarProps) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const requestAPIRefresh = useApplianceStore(store => store.requestAPIRefresh);

	const upgradeAgentPerm = userPermissions.has("UPGRADE_AGENT");
	const createDepKeyPerm = userPermissions.has("CREATE_DEPLOYMENT_KEY");

	const [openAgentUpgradeMenu, setAgentUpgradeMenuOpen] = useState(false);
	const [openAgentRestartMenu, setAgentRestartMenuOpen] = useState(false);
	const [openAutoUpgradeMenu, setAutoUpgradeMenuOpen] = useState(false);
	const [autoUpgradeAction, setAutoUpgradeAction] = useState("");
	const [openAgentDiagnosticMenu, setAgentDiagnosticMenuOpen] = useState(false);

	const isUpgradeButtonDisabled: boolean = useMemo(() => {
		return !props?.selectedData?.some(
			obj =>
				obj.isUpgradeEnabled && !Boolean(getPendingActionsTooltip(obj)?.length)
		);
	}, [props?.selectedData]);

	const isRestartButtonDisabled: boolean = useMemo(() => {
		return !props?.selectedData?.some(obj => obj.isRestartEnabled);
	}, [props?.selectedData]);

	const isDiagnosticsButtonDisabled: boolean = useMemo(() => {
		return !props?.selectedData?.some(
			obj => !obj.isDiagnosticsRunning && isDiagnosticsAllowed(obj)
		);
	}, [props?.selectedData]);

	const onClose = () => {
		setAgentUpgradeMenuOpen(false);
		setAgentRestartMenuOpen(false);
		setAutoUpgradeMenuOpen(false);
		setAgentDiagnosticMenuOpen(false);
		props.hideToolbar();
		requestAPIRefresh();
	};

	const handleButtonChange = (btnAction: string, btnActionType: string) => {
		if (btnAction === AgentActionType.UPGRADE) {
			setAgentUpgradeMenuOpen(prevOpen => !prevOpen);
		} else if (btnAction === AgentActionType.RESTART) {
			setAgentRestartMenuOpen(prevOpen => !prevOpen);
		} else if (btnAction === AgentActionType.DIAGNOSTIC) {
			setAgentDiagnosticMenuOpen(prevOpen => !prevOpen);
		} else {
			setAutoUpgradeMenuOpen(prevOpen => !prevOpen);
			setAutoUpgradeAction(btnActionType);
		}
	};

	return (
		<>
			{openAgentUpgradeMenu && (
				<AgentBaseDrawer
					isOpen={openAgentUpgradeMenu}
					onCancel={onClose}
					data={props?.selectedData}
					selection={props?.selection}
					drawerType={DRAWER_TYPE.APPLIANCE_UPGRADE}
				/>
			)}

			{openAgentRestartMenu && (
				<AgentBaseDrawer
					isOpen={openAgentRestartMenu}
					onCancel={onClose}
					data={props?.selectedData}
					selection={props?.selection}
					drawerType={DRAWER_TYPE.APPLIANCE_RESTART}
				/>
			)}

			{openAgentDiagnosticMenu && (
				<AgentBaseDrawer
					isOpen={openAgentDiagnosticMenu}
					onCancel={onClose}
					data={props?.selectedData}
					selection={props?.selection}
					drawerType={DRAWER_TYPE.APPLIANCE_CONNECT_DIAGNOSTIC}
				/>
			)}

			{openAutoUpgradeMenu && (
				<AgentBaseDrawer
					isOpen={openAutoUpgradeMenu}
					onCancel={onClose}
					data={props?.selectedData}
					selection={props?.selection}
					action={autoUpgradeAction}
					drawerType={DRAWER_TYPE.APPLIANCE_AUTO_UPGRADE}
				/>
			)}

			{(upgradeAgentPerm || createDepKeyPerm) && (
				<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}>
					<Stack
						direction="row"
						spacing={3}
						sx={{ width: "100%" }}
						justifyItems="flex-end"
						alignItems={"flex-end"}
						justifyContent="flex-end"
					>
						{props?.selection.length > 0 && upgradeAgentPerm && (
							<>
								<RestartActions
									{...{ isRestartButtonDisabled, handleButtonChange }}
								/>
								<UpgradeActions
									{...{ isUpgradeButtonDisabled, handleButtonChange }}
								/>
								<AutoUpgradeActions {...{ handleButtonChange }} />
								<DiagnosticActions
									isDiagnosticsButtonDisabled={isDiagnosticsButtonDisabled}
									onClickDiagnostics={handleButtonChange}
								/>
							</>
						)}
						{createDepKeyPerm &&
							!openAgentUpgradeMenu &&
							props?.selection.length === 0 && (
								<>
									<ApplianceDownload />
									<ApplianceActivation />
								</>
							)}
					</Stack>
				</GridToolbar>
			)}
		</>
	);
}

import { useTheme } from "@mui/material";

export const ServiceNowIcon = () => {
	const theme = useTheme();
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.2269 17.207C19.5844 17.207 19.0337 17.4365 18.5748 17.8036V17.2988H17.0144V23.219H18.6207V19.4558C18.8501 19.1345 19.4008 18.7215 20.0892 18.7215C20.3187 18.7215 20.5481 18.7674 20.7317 18.8133V17.2988C20.5481 17.2529 20.3646 17.207 20.2269 17.207Z"
				fill={theme.palette.mode === "dark" ? "#ffffff" : "#293E40"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.41886 21.3823C6.83189 21.7494 7.47439 21.9789 8.0251 21.9789C8.43814 21.9789 8.80528 21.7494 8.80528 21.4741C8.80528 20.5562 5.91403 20.8775 5.91403 18.9959C5.91403 17.8485 7.01546 17.1602 8.16278 17.1602C8.94296 17.1602 9.76903 17.4355 10.1821 17.7568L9.44778 18.9041C9.12653 18.7205 8.75939 18.491 8.30046 18.491C7.84153 18.491 7.52028 18.6746 7.52028 18.9959C7.52028 19.776 10.4115 19.4548 10.4115 21.52C10.4115 22.6673 9.3101 23.3557 8.071 23.3557C7.24493 23.3557 6.37296 23.0803 5.68457 22.5755L6.41886 21.3823Z"
				fill={theme.palette.mode === "dark" ? "#ffffff" : "#293E40"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.3367 20.1891C16.3367 18.5369 15.1894 17.1602 13.5372 17.1602C11.7933 17.1602 10.646 18.6287 10.646 20.235C10.646 22.1166 11.9769 23.3098 13.7208 23.3098C14.6387 23.3098 15.5565 22.9426 16.1531 22.2543L15.2353 21.3364C14.9599 21.6576 14.4092 22.0248 13.7667 22.0248C12.9406 22.0248 12.2522 21.4282 12.1605 20.6021H16.2449C16.3367 20.5103 16.3367 20.3727 16.3367 20.1891ZM12.2981 19.5007C12.3899 18.95 12.9406 18.4452 13.5831 18.4452C14.2256 18.4452 14.6846 18.95 14.7763 19.5007H12.2981Z"
				fill={theme.palette.mode === "dark" ? "#ffffff" : "#293E40"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.7123 17.2998L25.0047 23.22H23.9032L21.1956 17.2998H22.8018L24.4539 20.9253L26.0602 17.2998H27.7123Z"
				fill={theme.palette.mode === "dark" ? "#ffffff" : "#293E40"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.0397 14.5C29.6363 14.5 30.0952 14.9589 30.0952 15.5096C30.0952 16.1062 29.6363 16.5193 29.0397 16.5193C28.4431 16.5193 28.03 16.0604 28.03 15.5096C28.03 14.913 28.4431 14.5 29.0397 14.5Z"
				fill={theme.palette.mode === "dark" ? "#ffffff" : "#293E40"}
			/>
			<path
				d="M29.866 17.2998H28.2598V23.22H29.866V17.2998Z"
				fill={theme.palette.mode === "dark" ? "#ffffff" : "#293E40"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M36.3848 21.9789C35.6964 22.8968 34.8244 23.3098 33.723 23.3098C31.8873 23.3098 30.5564 21.933 30.5564 20.235C30.5564 18.491 31.9332 17.1602 33.723 17.1602C34.7785 17.1602 35.6505 17.665 36.2012 18.3534L35.1457 19.3171C34.8244 18.9041 34.3655 18.6287 33.8148 18.6287C32.8969 18.6287 32.2085 19.363 32.2085 20.235C32.2085 21.1528 32.851 21.8412 33.8148 21.8412C34.4573 21.8412 34.9621 21.4741 35.2375 21.061L36.3848 21.9789Z"
				fill={theme.palette.mode === "dark" ? "#ffffff" : "#293E40"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M42.1321 22.2543C41.5355 22.9426 40.6177 23.3098 39.6998 23.3098C37.9559 23.3098 36.625 22.1166 36.625 20.235C36.625 18.5828 37.7264 17.1602 39.5162 17.1602C41.1225 17.1602 42.3157 18.5369 42.3157 20.1891C42.3157 20.3727 42.3157 20.5103 42.2698 20.648H38.1395C38.2312 21.4741 38.9196 22.0707 39.7457 22.0707C40.3882 22.0707 40.9389 21.7035 41.2143 21.3823L42.1321 22.2543ZM40.6636 19.5007C40.6177 18.9959 40.1587 18.4452 39.4704 18.4452C38.8279 18.4452 38.2771 18.95 38.1854 19.5007H40.6636Z"
				fill={theme.palette.mode === "dark" ? "#ffffff" : "#293E40"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.7158 33.3528V27.4326H14.2303V27.9374C14.6892 27.5703 15.2399 27.3408 15.8824 27.3408C16.7085 27.3408 17.4428 27.708 17.9476 28.3046C18.3147 28.7635 18.5901 29.3601 18.5901 30.3697V33.3987H16.9839V30.2321C16.9839 29.6355 16.8462 29.3142 16.6167 29.1306C16.3872 28.9012 16.1119 28.7635 15.6989 28.7635C15.0564 28.7635 14.5056 29.1765 14.2303 29.4978V33.3528H12.7158Z"
				fill={theme.palette.mode === "dark" ? "#ffffff" : "#293E40"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.6788 27.2949C20.7513 27.2949 19.2368 28.8094 19.2368 30.7369C19.2368 31.7465 19.6498 32.6644 20.2923 33.3069C20.5218 33.5363 20.9348 33.5363 21.2102 33.3528C21.5773 33.0315 22.1281 32.8479 22.6788 32.8479C23.2754 32.8479 23.7343 33.0315 24.1473 33.3528C24.4227 33.5822 24.7898 33.5363 25.0652 33.261C25.7077 32.6185 26.1207 31.7465 26.1207 30.7369C26.0748 28.8553 24.5604 27.2949 22.6788 27.2949ZM22.6329 32.5267C21.5773 32.5267 20.889 31.7465 20.889 30.7828C20.889 29.819 21.5773 29.0388 22.6329 29.0388C23.6884 29.0388 24.3768 29.819 24.3768 30.7828C24.3768 31.7465 23.6884 32.5267 22.6329 32.5267Z"
				fill="#81B5A1"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.7024 27.4336H27.2627L28.5477 30.8297L29.8327 27.4336H31.1636L32.4027 30.8297L33.6877 27.4336H35.294L32.9535 33.3538H31.7602L30.4752 30.0036L29.2361 33.3538H28.0429L25.7024 27.4336Z"
				fill={theme.palette.mode === "dark" ? "#ffffff" : "#293E40"}
			/>
			<rect
				x="0.5"
				y="0.5"
				width="47"
				height="47"
				rx="3.5"
				stroke={theme.palette.text.primary}
				strokeOpacity="0.12"
			/>
		</svg>
	);
};

import { Grid, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useDebouncedSnackbar } from "modules/snackbar/store/useDebouncedSnackbar";
import { AssetAttackSurfaceDetail } from "pages/asset/components/asset-detail/AssetAttackSurfaceDetail";
import { AssetBlastRadiusDetail } from "pages/asset/components/asset-detail/AssetBlastRadiusDetail";
import { useAssetStore } from "pages/assets/store";
import { Asset, AssetStatus } from "pages/assets/types";
import { useEffect, useState } from "react";
import { ReviewDetailsInt } from "./components/single-assets-classification-by-levels/SingleAssetClassificationByLevels";
import { AssetStatusMap, Direction, SecurityStatus } from "./constants";
import { useSynchronizePolicy } from "./hooks/use-update-status";

export interface SynchronizeAssetPolicyInt {
	direction: Direction;
}

interface AssetSecurityDetailWithoutAggregatedEndpointProps {
	asset: Asset;
	data: any;
	isLoading: boolean;
	reviewAttackSurface: ({ data, unreviewed }: ReviewDetailsInt) => void;
	reviewBlastRadius: ({ data, unreviewed }: ReviewDetailsInt) => void;
	reviewASDrawerVisibility: boolean;
	reviewBRDrawerVisibility: boolean;
	showUnreviewedFacet: boolean;
	activeTab: Direction;
	onCloseReviewDrawer: () => void;
	showUserGroupPaths: boolean;
}

export const AssetSecurityDetailWithoutAggregatedEndpoint = ({
	asset,
	data,
	isLoading,
	reviewAttackSurface,
	reviewBlastRadius,
	reviewASDrawerVisibility,
	reviewBRDrawerVisibility,
	showUnreviewedFacet,
	showUserGroupPaths,
	activeTab,
	onCloseReviewDrawer,
}: AssetSecurityDetailWithoutAggregatedEndpointProps) => {
	const queryClient = useQueryClient();
	const { setDebouncedSnackbar } = useDebouncedSnackbar();
	const synchronizePolicyMutation = useSynchronizePolicy();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const requestAssetAPIRefresh = useAssetStore(
		state => state.requestAPIRefresh
	);

	const [inBoundComment, setInBoundComment] = useState<string | undefined>(
		undefined
	);
	const [outBoundComment, setOutBoundComment] = useState<string | undefined>(
		undefined
	);
	const [selectedAttackSurfaceStatus, setSelectedAttackSurfaceStatus] =
		useState<SecurityStatus>(SecurityStatus.Unsecure);
	const [selectedBlastRadiusStatus, setSelectedBlastRadiusStatus] =
		useState<SecurityStatus>(SecurityStatus.Unsecure);
	const [isTestModeVisibleForInbound, setIsTestModeVisibleForInbound] =
		useState<boolean>(true);
	const [isTestModeVisibleForOutbound, setIsTestModeVisibleForOutbound] =
		useState<boolean>(true);
	const [activeOperation, setActiveOperation] = useState<
		Direction | undefined
	>();

	useEffect(() => {
		if (selectedAttackSurfaceStatus === SecurityStatus.Unsecure) {
			setIsTestModeVisibleForInbound(false);
		}
		if (selectedBlastRadiusStatus === SecurityStatus.Unsecure) {
			setIsTestModeVisibleForOutbound(false);
		}
	}, [
		selectedAttackSurfaceStatus,
		selectedBlastRadiusStatus,
		setIsTestModeVisibleForInbound,
		setIsTestModeVisibleForOutbound,
	]);

	useEffect(() => {
		if (asset?.assetId) {
			const inboundStatus: SecurityStatus =
				AssetStatusMap[asset.inboundAssetStatus as AssetStatus];
			const outboundStatus: SecurityStatus =
				AssetStatusMap[asset.outboundAssetStatus as AssetStatus];
			setSelectedAttackSurfaceStatus(inboundStatus);
			setSelectedBlastRadiusStatus(outboundStatus);
			setIsTestModeVisibleForInbound(asset.simulateInboundAssetStatus ?? true);
			setIsTestModeVisibleForOutbound(
				asset.simulateOutboundAssetStatus ?? true
			);
		}
	}, [asset]);

	interface SyncPolicyInt {
		criteria: string;
		attackSurface?: boolean;
		blastRadius?: boolean;
		comment?: string | null;
	}

	const SynchronizeAssetPolicy = async ({
		direction,
	}: SynchronizeAssetPolicyInt) => {
		setActiveOperation(direction);
		const body: SyncPolicyInt = {
			criteria: `assetId in ('${asset?.assetId}')`,
			comment:
				direction === Direction.Inbound ? inBoundComment : outBoundComment,
		};

		let type: string;

		if (direction === Direction.Inbound) {
			type = "Attack Surface";
			body["attackSurface"] = true;
		} else {
			type = "Blast Radius";
			body["blastRadius"] = true;
		}

		setDebouncedSnackbar(
			true,
			SnackBarSeverity.Info,
			window.getCTTranslatedText("debouncedSnackbarText")
		);

		await synchronizePolicyMutation.mutateAsync(body, {
			onSuccess: response => {
				setDebouncedSnackbar.cancel();
				setActiveOperation(undefined);
				setInBoundComment(undefined);
				setOutBoundComment(undefined);
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"AssetTypeStatusSynchronizedSuccessfully",
					{ type: window.getCTTranslatedText(type) }
				);
				queryClient.invalidateQueries({
					queryKey: ["asset"],
				});
				requestAssetAPIRefresh();
			},
			onError: error => {
				setDebouncedSnackbar.cancel();
				setActiveOperation(undefined);
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const statusChangeCallback = () => {
		setInBoundComment(undefined);
		setOutBoundComment(undefined);
		queryClient.invalidateQueries({ queryKey: ["asset"] });
		requestAssetAPIRefresh();
	};

	return (
		<Stack direction="row" sx={{ width: "100%", pb: 2 }}>
			<Grid container sx={{ width: "100%" }} rowSpacing={3} columnSpacing={3}>
				<Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
					<AssetAttackSurfaceDetail
						asset={asset}
						isLoading={isLoading}
						reviewAttackSurface={reviewAttackSurface}
						reviewASDrawerVisibility={reviewASDrawerVisibility}
						selectedAttackSurfaceStatus={selectedAttackSurfaceStatus}
						selectedBlastRadiusStatus={selectedBlastRadiusStatus}
						setSelectedAttackSurfaceStatus={(status: SecurityStatus) => {
							setSelectedAttackSurfaceStatus(status);
							setIsTestModeVisibleForInbound(
								status !== SecurityStatus.Unsecure
							);
						}}
						isTestModeVisibleForInbound={isTestModeVisibleForInbound}
						setIsTestModeVisibleForInbound={setIsTestModeVisibleForInbound}
						showUnreviewedFacet={showUnreviewedFacet}
						activeTab={activeTab}
						inBoundComment={inBoundComment}
						setInBoundComment={setInBoundComment}
						statusChangeCallback={statusChangeCallback}
						onCloseReviewDrawer={onCloseReviewDrawer}
						data={data}
						synchronizeAssetPolicy={SynchronizeAssetPolicy}
						activeOperation={activeOperation}
					/>
				</Grid>
				<Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
					<AssetBlastRadiusDetail
						asset={asset}
						isLoading={isLoading}
						reviewBlastRadius={reviewBlastRadius}
						reviewBRDrawerVisibility={reviewBRDrawerVisibility}
						selectedAttackSurfaceStatus={selectedAttackSurfaceStatus}
						selectedBlastRadiusStatus={selectedBlastRadiusStatus}
						setSelectedBlastRadiusStatus={(status: SecurityStatus) => {
							setSelectedBlastRadiusStatus(status);
							setIsTestModeVisibleForOutbound(
								status !== SecurityStatus.Unsecure
							);
						}}
						isTestModeVisibleForOutbound={isTestModeVisibleForOutbound}
						setIsTestModeVisibleForOutbound={setIsTestModeVisibleForOutbound}
						showUnreviewedFacet={showUnreviewedFacet}
						showUserGroupPaths={showUserGroupPaths}
						activeTab={activeTab}
						outBoundComment={outBoundComment}
						setOutBoundComment={setOutBoundComment}
						statusChangeCallback={statusChangeCallback}
						onCloseReviewDrawer={onCloseReviewDrawer}
						data={data}
						synchronizeAssetPolicy={SynchronizeAssetPolicy}
						activeOperation={activeOperation}
					/>
				</Grid>
			</Grid>
		</Stack>
	);
};

import { CircularProgress, Stack } from "@mui/material";
import { SecureAllIcon, SecureIcon, UnsecuredIcon } from "assets/svgs";
import { AssetsButtonWithBadge } from "modules/bulk-asset-status-control/components/AssetsButtonWithBadge";
import { AssetDistributionData } from "modules/bulk-asset-status-control/types";
import numeral from "numeral";
import {
	SecurityStatus,
	SecurityStatusLabels,
} from "pages/asset/components/asset-detail/constants";

const format = "0,0";

interface AssetBadgesProps {
	assetStats: {
		data: AssetDistributionData | undefined;
		isLoading: boolean;
		reload: Function;
	};
	selectedStatus: SecurityStatus;
}

export const AssetBadges = ({
	assetStats,
	selectedStatus,
}: AssetBadgesProps) => {
	const AssetsGroupButtonData = [
		{
			securityStatus: SecurityStatus.Unsecure,
			renderIcon: () => <UnsecuredIcon />,
			isInvisible:
				getBadgeContent(assetStats, SecurityStatus.Unsecure, selectedStatus) ===
				0,
			badgeContent: getBadgeContent(
				assetStats,
				SecurityStatus.Unsecure,
				selectedStatus
			),
			count: assetStats.data?.unsecured,
			test: 0,
		},
		{
			securityStatus: SecurityStatus.Internet,
			renderIcon: () => <SecureIcon />,
			isInvisible:
				Math.abs(
					getBadgeContent(
						assetStats,
						SecurityStatus.Internet,
						selectedStatus
					) ?? 0
				) === 0 || selectedStatus < SecurityStatus.Internet,
			badgeContent: getBadgeContent(
				assetStats,
				SecurityStatus.Internet,
				selectedStatus
			),
			count: assetStats.data?.["secure-internet"],
			test: assetStats.data?.["simulate-secure-internet"],
		},
		{
			securityStatus: SecurityStatus.All,
			renderIcon: () => <SecureAllIcon />,
			isInvisible:
				getBadgeContent(assetStats, SecurityStatus.All, selectedStatus) === 0,
			badgeContent: getBadgeContent(
				assetStats,
				SecurityStatus.All,
				selectedStatus
			),
			count: assetStats.data?.["secure-all"],
			test: assetStats.data?.["simulate-secure-all"],
		},
	];

	if (assetStats.isLoading) {
		return <CircularProgress size={24} />;
	}

	return (
		<Stack direction={"row"} columnGap={3}>
			{AssetsGroupButtonData.map((asset, index) => {
				let tooltipText = "";
				if (asset.count) {
					tooltipText += `${asset.count} ${window.getCTTranslatedText(
						SecurityStatusLabels[asset.securityStatus]
					)}`;
				}

				if (asset.test) {
					tooltipText += `${asset.count ? " | " : ""}${
						asset.test
					} ${window.getCTTranslatedText(
						"Simulate"
					)} ${window.getCTTranslatedText(
						SecurityStatusLabels[asset.securityStatus]
					)}`;
				}

				return (
					<AssetsButtonWithBadge
						key={asset.securityStatus}
						isButtonActive={false}
						isInvisible={asset.isInvisible ?? true}
						badgeContent={
							`${
								(asset.badgeContent || 0) > 0
									? `+${numeral(asset.badgeContent).format(format)}`
									: numeral(asset.badgeContent).format(format)
							}` ?? "0"
						}
						renderIcon={asset.renderIcon}
						counts={{ main: asset.count ?? 0, test: asset.test ?? 0 }}
						format={format}
						onClick={() => {}}
						disabled={true}
						tooltipText={tooltipText}
					/>
				);
			})}
		</Stack>
	);
};

function getBadgeContent(
	assetStats: { data: AssetDistributionData | undefined },
	securityStatus: SecurityStatus,
	selectedStatus: SecurityStatus
) {
	switch (securityStatus) {
		case SecurityStatus.Unsecure:
			return selectedStatus === SecurityStatus.Unsecure
				? 0
				: -(assetStats.data?.unsecured ?? 0);
		case SecurityStatus.Internet:
			return selectedStatus === SecurityStatus.Internet
				? assetStats.data?.unsecured
				: -(
						(assetStats.data?.["secure-internet"] ?? 0) +
						(assetStats.data?.["simulate-secure-internet"] ?? 0)
					);
		case SecurityStatus.All:
			return selectedStatus === SecurityStatus.All
				? +(
						(assetStats.data?.["secure-internet"] ?? 0) +
						(assetStats.data?.["simulate-secure-internet"] ?? 0) +
						(assetStats.data?.unsecured ?? 0)
					)
				: 0;
	}
}

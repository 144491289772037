import { Appliance } from "pages/appliances/types";

export enum ApplianceTabs {
	Summary = "summary",
	Devices = "managed",
	UnmanagedDevices = "unmanaged",
}

export enum ApplianceTabValues {
	Summary = 0,
	Devices = 1,
	UnmanagedDevices = 2,
}

export interface ApplianceWidgetProps {
	appliance?: Appliance;
	applianceName?: string;
	isLoading?: boolean;
	setDeviceType?: (value: string) => void;
	canUpdate?: boolean;
}

export enum DeviceSegmentationLevels {
	Active = "active",
	Connected = "connected",
	Disconnected = "disconnected",
}

export enum DeviceTypes {
	Managed = "managed",
	Unmanaged = "unmanaged",
}

export const SEGMENTATION_PRIORITY: { [key: string]: number } = {
	[DeviceSegmentationLevels.Active]: 0,
	[DeviceSegmentationLevels.Connected]: 1,
	[DeviceSegmentationLevels.Disconnected]: 2,
};

export enum FirewallStatus {
	Synchronized = "synchronized",
	Pending = "pending",
}

export enum ApplianceServicesStatus {
	UP = "UP",
	DOWN = "DOWN",
	NA = "NOT_APPLICABLE",
}

export enum RestartServicesTypes {
	DHCP = "coredhcp",
	HA = "hasync",
}

export enum ApplianceDevicesFilterKeys {
	ApplianceName = "agentname",
	Type = "type",
	Status = "assetavailability",
}

export enum ApplianceDevicesFilterValues {
	Device = "device",
}

export interface HealthChart {
	name: string;
	value: number;
}

import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { IDPInfo } from "pages/Integration/type";
import { IDPForm } from "./../IDP-Form";
import { Toolbar } from "common/atoms/toolbar";
import { IDP_MODE } from "../IDP-Form/IDPForm";

export interface IDPInfoDrawerProps {
	isOpen: boolean;
	title?: string;
	onCancel: VoidFunction;
	idpData?: IDPInfo;
	mode: IDP_MODE;
}

export const IDPInfoDrawer = ({
	isOpen,
	title,
	onCancel,
	idpData,
	mode,
}: IDPInfoDrawerProps) => {
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onCancel}
			PaperProps={{
				sx: {
					padding: "0px",
					width: "50%",
					height: "100%",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title="Close Drawer">
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onCancel}
						sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<Stack direction="row" spacing={2} sx={{ mt: 5, mb: 3, mx: 4 }}>
				<Typography variant="h5">
					<b>{window.getCTTranslatedText(title ?? "")}</b>
				</Typography>
			</Stack>
			<IDPForm mode={mode} idpInfo={idpData} cancel={onCancel} />
		</Drawer>
	);
};

import {
	Dialog,
	DialogTitle,
	IconButton,
	DialogContent,
	Stack,
	Box,
	CircularProgress,
} from "@mui/material";
import {
	DIALOG_WIDGET_CONTENT_WIDTH,
	DIALOG_WIDGET_CONTENT_HEIGHT,
	DIALOG_WIDGET_CHART_HEIGHT,
} from "pages/dashboard/constants";
import { DashboardWidgetDialogProps } from "pages/dashboard/types";
import CloseIcon from "@mui/icons-material/Close";

export const DashboardWidgetDialog = ({
	title,
	children,
	isLoading,
	open,
	handleClose,
	timeFilter,
}: DashboardWidgetDialogProps) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby={title}
			sx={{ width: "100%", height: "100%" }}
			maxWidth="lg"
		>
			<DialogTitle sx={{ m: 0, p: 2, px: 4 }} id="dialog-title">
				{title}
			</DialogTitle>
			<IconButton
				aria-label="close dialog"
				onClick={handleClose}
				size="medium"
				sx={{
					position: "absolute",
					right: "16px",
					top: "8px",
					zIndex: 2,
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent
				dividers={true}
				sx={{
					width: DIALOG_WIDGET_CONTENT_WIDTH,
					height: DIALOG_WIDGET_CONTENT_HEIGHT,
				}}
			>
				{timeFilter && (
					<Stack direction="row" justifyContent={"flex-end"} mb={3}>
						<Box sx={{ flexGrow: 1 }} />
						<Stack sx={{ maxWidth: "140px" }}>{timeFilter}</Stack>
					</Stack>
				)}
				<Stack
					alignItems="center"
					justifyContent="center"
					justifyItems="center"
					sx={{ width: "100%", height: DIALOG_WIDGET_CHART_HEIGHT }}
				>
					{isLoading ? <CircularProgress /> : children}
				</Stack>
			</DialogContent>
		</Dialog>
	);
};

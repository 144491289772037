import { Stack, Tooltip, Typography } from "@mui/material";
import { UserGroup } from "pages/users-segmentation/components/user-groups/types";
import numeral from "numeral";
import { useState } from "react";
import { UserGroupAssignedNamedNetworksViewDrawer } from "../user-group-assigned-named-networks-view-drawer";

export function UserGroupAssignedNamedNetworks({
	userGroup,
	criteria,
	viewOnly = false,
}: {
	readonly userGroup: UserGroup;
	readonly viewOnly?: boolean;
	readonly criteria: string;
}) {
	const [showAssignedTemplatesDrawer, setShowAssignedTemplatesDrawer] =
		useState<boolean>(false);

	const namednetworksAssigned = numeral(
		userGroup?.namednetworksAssigned || 0
	).format("0a");

	return (
		<>
			<Stack direction={"row"} spacing={1} flex={1} alignItems={"center"}>
				{viewOnly ? (
					<Typography variant="body2">{namednetworksAssigned} </Typography>
				) : (
					<Tooltip
						title={window.getCTTranslatedText("View Assigned Named Networks")}
					>
						<Typography
							variant="body2"
							onClick={() => {
								setShowAssignedTemplatesDrawer(true);
							}}
							sx={{ color: "primary.main" }}
						>
							{namednetworksAssigned}{" "}
						</Typography>
					</Tooltip>
				)}
			</Stack>
			{showAssignedTemplatesDrawer && (
				<UserGroupAssignedNamedNetworksViewDrawer
					criteria={criteria}
					onClose={() => setShowAssignedTemplatesDrawer(false)}
				/>
			)}
		</>
	);
}

import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Grid } from "@mui/material";
import { CTDrawer } from "common/molecules/drawer";
import { CTDrawerProps } from "common/molecules/drawer/CTDrawer";
import { Scope } from "modules/scope-metadata/types";
import { UnmanagedDevice } from "pages/appliance-detail/components/appliance-unmanaged-devices/types";
import { Asset } from "pages/assets/types";
import { FormBuilderElement } from "./types";
import useMetadataEditForm from "./useMetadataEditForm";

interface Props extends Pick<CTDrawerProps, "open"> {
	data: FormBuilderElement[];
	onClose: () => void;
	scope?: Scope;
	onSubmit: (data: Partial<Asset | UnmanagedDevice>) => void;
	isSubmitLoading?: boolean;
	infoText?: string;
	title: string;
}

function MetadataEdit({
	scope = Scope.Asset,
	data,
	open,
	onClose,
	onSubmit,
	isSubmitLoading,
	infoText = "",
	title,
}: Props) {
	const { formElements, handleSubmit } = useMetadataEditForm({ data, scope });

	const drawerProps: CTDrawerProps = {
		title,
		open: !!open,
		onClose,
		anchor: "right",
		drawerWidth: "786px",
		actions: (
			<LoadingButton
				loading={isSubmitLoading}
				autoFocus
				variant="contained"
				type="submit"
				onClick={handleSubmit(onSubmit)}
			>
				{window.getCTTranslatedText("Update")}
			</LoadingButton>
		),
	};

	return (
		<CTDrawer {...drawerProps}>
			{infoText && (
				<Alert severity="info" sx={{ mb: 2 }}>
					{window.getCTTranslatedText(infoText)}
				</Alert>
			)}
			<Grid container columnSpacing={6} rowSpacing={2}>
				{formElements}
			</Grid>
		</CTDrawer>
	);
}

export default MetadataEdit;

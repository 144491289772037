import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { IDPColumnRenderProps } from "./../types";
import { useState } from "react";
import { Stack, Tooltip, Box, IconButton } from "@mui/material";
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
import { useIDPStore } from "../store";
import { IDPInfoDrawer } from "../IDP-Info-Drawer";
import { IDP_MODE } from "../IDP-Form/IDPForm";

export function TokenGeneration({ idp }: IDPColumnRenderProps) {
	const [showIDPInfoDrawer, setIDPInfoDrawer] = useState(false);
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const requestAPIRefresh = useIDPStore(store => store.requestAPIRefresh);

	const handleClose = () => {
		setIDPInfoDrawer(false);
		requestAPIRefresh();
	};

	return (
		<Box>
			<Stack direction="row" spacing={1} alignItems="center">
				{userPermissions.has("READ_SCIM_TOKEN") && (
					<Stack direction="row" spacing={1}>
						<Tooltip
							title={window.getCTTranslatedText("RegenerateBearerToken")}
						>
							<IconButton size={"small"} onClick={() => setIDPInfoDrawer(true)}>
								<TokenOutlinedIcon color={"primary"} fontSize={"small"} />
							</IconButton>
						</Tooltip>
					</Stack>
				)}
			</Stack>

			{showIDPInfoDrawer && (
				<IDPInfoDrawer
					isOpen={showIDPInfoDrawer}
					title={"RegenerateBearerToken"}
					onCancel={handleClose}
					idpData={idp}
					mode={IDP_MODE.GENERATE_TOKEN}
				/>
			)}
		</Box>
	);
}

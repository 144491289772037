import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import numeral from "numeral";
import { TemplateColumnRenderProps } from "pages/templates/types";
import pluralize from "pluralize";
import { Link as RouterLink } from "react-router-dom";

export function UsedByPolicies({
	template,
	viewOnly,
}: TemplateColumnRenderProps) {
	if (!template.templateId) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="flex-start">
			<Stack alignItems="flex-start">
				{viewOnly || template?.templateTagBasedPolicyAssignments === 0 ? (
					<Typography variant="body2">
						{numeral(template?.templateTagBasedPolicyAssignments ?? 0).format(
							"0a"
						)}{" "}
						{`${window.getCTTranslatedText(
							pluralize(
								"segment",
								Number(template?.templateTagBasedPolicyAssignments ?? 0)
							)
						)}`}
					</Typography>
				) : (
					<Tooltip title={window.getCTTranslatedText("ViewSegment")}>
						<Link
							typography={"body2"}
							component={RouterLink}
							to={`/segment?filters=${encodeURIComponent(
								`templatename=${template.templateName}`
							)}`}
							sx={{ color: "primary.main" }}
						>
							{numeral(template?.templateTagBasedPolicyAssignments ?? 0).format(
								"0a"
							)}{" "}
							{`${window.getCTTranslatedText(
								pluralize(
									"segment",
									Number(template?.templateTagBasedPolicyAssignments ?? 0)
								)
							)}`}
						</Link>
					</Tooltip>
				)}
			</Stack>
		</Stack>
	);
}

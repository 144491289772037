import { Stack, Tooltip } from "@mui/material";
import { UsersColumnRenderProps } from "../../types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";

export const UserSourceIDP = ({ user, viewOnly }: UsersColumnRenderProps) => {
	const facetName = "idpname";
	const optionName = user?.idpName?.toString();
	const name = user.idpName;

	return (
		<Stack
			sx={{
				height: "100%",
				justifyContent: "space-evenly",
				width: "100%",
				overflow: "hidden",
				textOverflow: "ellipsis",
			}}
		>
			<Stack direction="row">
				<Tooltip title={`${name}`}>
					<CTDatagridButton
						asText={viewOnly === true}
						isDisabled={viewOnly === true}
						facetName={facetName}
						facetOption={optionName ?? ""}
					>
						{capitalizeFirstLetter(name)}
					</CTDatagridButton>
				</Tooltip>
			</Stack>
		</Stack>
	);
};

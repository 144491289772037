import {
	AlertColor,
	Box,
	Divider,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { FacetState, Operator } from "modules/facets/types";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";
import { SynchronizeAssetPolicyInt } from "pages/asset/components/asset-detail/AssetSecurityDetailWithoutAggregatedEndpoint";
import { BlastRadiusStatusOptionsText } from "pages/assets/constants";
import { useAssetStore } from "pages/assets/store";
import {
	Asset,
	AssetSecurityCategory,
	AssetStatus,
	AssetType,
	SecurityLevels,
} from "pages/assets/types";
import { SecurityTitleBlock } from "pages/dashboard/components/section-title-block";
import { PathStatus } from "pages/paths/types";
import { useState } from "react";
import { AssetReviewDrawer } from "./components/asset-review-drawer";
import { AssetStatusAction } from "./components/asset-status-action";
import { AssetStatusAlert } from "./components/asset-status-alert";
import { AssetStatusSlider } from "./components/asset-status-slider/AssetStatusSlider";
import { AssetSynchronizeAlert } from "./components/asset-synchronize-alert";
import { canDisplaySynchornizeAlert } from "./components/asset-synchronize-alert/helpers";
import { SingleAssetClassificationByLevels } from "./components/single-assets-classification-by-levels";
import { ReviewDetailsInt } from "./components/single-assets-classification-by-levels/SingleAssetClassificationByLevels";
import { AssetStatusMap, Direction, SecurityStatus } from "./constants";
import { useSynchronizePolicy } from "./hooks/use-update-status";

const UNREVIEWED_READ_ONLY_DIMENSIONS = [
	"listenportreviewed",
	"listenportlastobserved",
	"pathlastobserved",
	"reviewed",
];

const READ_ONLY_DIMENSIONS = ["listenportlastobserved", "pathlastobserved"];

interface AssetBlastRadiusDetailProps {
	asset: Asset;
	isLoading: boolean;
	reviewBlastRadius: ({ data, unreviewed }: ReviewDetailsInt) => void;
	reviewBRDrawerVisibility: boolean;
	selectedAttackSurfaceStatus: SecurityStatus;
	selectedBlastRadiusStatus: SecurityStatus;
	setSelectedBlastRadiusStatus: (value: SecurityStatus) => void;
	isTestModeVisibleForOutbound: boolean;
	setIsTestModeVisibleForOutbound: (value: boolean) => void;
	showUnreviewedFacet: boolean;
	showUserGroupPaths: boolean;
	activeTab: Direction;
	outBoundComment: string | undefined;
	setOutBoundComment: (value: string | undefined) => void;
	statusChangeCallback: () => void;
	onCloseReviewDrawer: () => void;
	data: any;
	synchronizeAssetPolicy: ({
		direction,
	}: SynchronizeAssetPolicyInt) => Promise<void>;
	activeOperation: Direction | undefined;
}

export const AssetBlastRadiusDetail = ({
	asset,
	isLoading,
	reviewBlastRadius,
	reviewBRDrawerVisibility,
	selectedAttackSurfaceStatus,
	selectedBlastRadiusStatus,
	setSelectedBlastRadiusStatus,
	isTestModeVisibleForOutbound,
	setIsTestModeVisibleForOutbound,
	showUnreviewedFacet,
	showUserGroupPaths,
	activeTab,
	outBoundComment,
	setOutBoundComment,
	statusChangeCallback,
	onCloseReviewDrawer,
	data,
	synchronizeAssetPolicy,
	activeOperation,
}: AssetBlastRadiusDetailProps) => {
	const { isFeatureEnabled: isProgressiveEnabled } = useFeatureFlagControl(
		FEATURES.PROGRESSIVE
	);
	const synchronizePolicyMutation = useSynchronizePolicy();
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const [isOutboundDrawerOpen, setIsOutboundDrawerOpen] = useState(false);

	const blastRadiusSyncAlert = canDisplaySynchornizeAlert({
		direction: Direction.Outbound,
		asset: asset,
	});

	const baseOptions = [
		{
			name: "paths",
			label: "Paths",
			unreviewed:
				(asset?.outboundInternetPaths?.unreviewed ?? 0) +
				(asset?.outboundIntranetPaths.unreviewed ?? 0),
			total:
				(asset?.outboundInternetPaths?.total ?? 0) +
				(asset?.outboundIntranetPaths.total ?? 0),
		},
	];
	const endpointOptions = [
		...baseOptions,
		{
			name: "userPath",
			label: "User Group Paths",
			unreviewed:
				(asset?.usergroupOutboundInternetPaths?.unreviewed ?? 0) +
				(asset?.usergroupOutboundIntranetPaths?.unreviewed ?? 0),
			total:
				(asset?.usergroupOutboundInternetPaths?.total ?? 0) +
				(asset?.usergroupOutboundIntranetPaths?.total ?? 0),
		},
	];
	const BROptions =
		asset.type === AssetType.Endpoint ? endpointOptions : baseOptions;

	const direction = AssetSecurityCategory.OUTBOUND;
	const portFacetState: FacetState = new Map();

	const pathFacetState: FacetState = new Map([
		[
			"direction",
			new Map([[direction, { isSelected: true, operator: Operator.EQUAL }]]),
		],
	]);

	if (showUnreviewedFacet) {
		const unreviewedOptions = new Map([
			[PathStatus.Unreviewed, { isSelected: true, operator: Operator.EQUAL }],
		]);
		pathFacetState.set("reviewed", unreviewedOptions);
		portFacetState.set("listenportreviewed", unreviewedOptions);
	}

	return (
		<Paper sx={{ width: "100%" }}>
			<Stack direction={"row"} alignItems={"center"} sx={{ width: "100%" }}>
				<Stack
					alignContent={"center"}
					justifyItems={"center"}
					justifyContent="space-between"
					sx={{
						width: "100%",
						background: theme =>
							theme.palette.mode === "dark"
								? "rgba(255, 255, 255, 0.04)"
								: "rgba(0, 0, 0, 0.04)",
						borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
					}}
					direction="row"
				>
					<Stack direction="row" alignItems="center" justifyContent={"center"}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent={"center"}
						>
							<SecurityTitleBlock
								title={"Blast Radius"}
								addBackground={false}
							/>
						</Stack>
						<Stack
							alignContent={"center"}
							justifyItems={"center"}
							justifyContent={"center"}
							sx={{ display: "none" }}
						>
							<Typography>
								{BlastRadiusStatusOptionsText[asset.outboundAssetStatus]}
							</Typography>
						</Stack>
					</Stack>
					<Stack
						alignContent={"center"}
						justifyItems={"center"}
						justifyContent={"center"}
						sx={{ pr: 3 }}
					>
						<Typography variant="overline">
							{window.getCTTranslatedText("Outbound")}
						</Typography>
					</Stack>
				</Stack>
			</Stack>
			<Stack>
				<SingleAssetClassificationByLevels
					data={{
						status: asset.blastRadius as SecurityLevels,
						loading: isLoading,
						data: BROptions,
					}}
					type="blastradius"
					category="Blast Radius"
					reviewDetails={reviewBlastRadius}
				/>
			</Stack>
			<Stack sx={{ px: 3 }}>
				<Divider sx={{ borderColor: "rgba(0, 0, 0, 0.05)" }} />
			</Stack>
			<Stack
				justifyContent={"flex-start"}
				alignContent={"center"}
				alignItems="flex-start"
				sx={{ px: 3, mt: 3 }}
			>
				{userPermissions.has("UPDATE_ASSET_ZEROTRUST") &&
				blastRadiusSyncAlert?.show &&
				AssetStatusMap[asset.outboundAssetStatus] ===
					selectedBlastRadiusStatus ? (
					<AssetSynchronizeAlert
						severity={"warning" as AlertColor}
						messageComp={blastRadiusSyncAlert?.messageComp}
						clickCallback={() =>
							synchronizeAssetPolicy({
								direction: Direction.Outbound,
							})
						}
						loading={
							activeOperation === Direction.Outbound &&
							synchronizePolicyMutation.isLoading
						}
						direction={Direction.Outbound}
						asset={asset}
						canViewChanges={Boolean(blastRadiusSyncAlert.totalPendingCount)}
						showInternetChangesOnly={false}
					/>
				) : (
					AssetStatusAlert({
						type: Direction.Outbound,
						status: asset.blastRadius as SecurityLevels,
						asset: asset,
						selectedAttackSurfaceStatus: selectedAttackSurfaceStatus,
						selectedBlastRadiusStatus: selectedBlastRadiusStatus,
					})
				)}
				{reviewBRDrawerVisibility && (
					<AssetReviewDrawer
						isOpen={reviewBRDrawerVisibility}
						onClose={onCloseReviewDrawer}
						asset={asset}
						direction={direction}
						title={
							showUserGroupPaths
								? "Review User Group Paths"
								: "Review Outbound Paths"
						}
						mode={AssetReviewModeType.Review}
						showUserGroupPaths={showUserGroupPaths}
						portFacetState={portFacetState}
						pathFacetState={pathFacetState}
						readOnlyDimensions={
							showUnreviewedFacet
								? UNREVIEWED_READ_ONLY_DIMENSIONS
								: READ_ONLY_DIMENSIONS
						}
						activeTab={activeTab}
					/>
				)}
			</Stack>
			<Stack sx={{ mt: 15 }}>
				<Box sx={{ width: "100%", px: 5 }}>
					<AssetStatusSlider
						direction={Direction.Outbound}
						asset={asset}
						selectedStatus={selectedBlastRadiusStatus}
						setSelectedStatus={setSelectedBlastRadiusStatus}
						simulatedStatus={isTestModeVisibleForOutbound}
						disabled={!userPermissions.get("UPDATE_ASSET_ZEROTRUST")}
						minValue={
							AssetStatusMap[data.lowestOutboundAssetStatus as AssetStatus]
						}
					/>

					{isProgressiveEnabled && <Stack height={"40px"}></Stack>}
				</Box>
			</Stack>

			<Stack sx={{ width: "100%", p: 4, pt: 0 }}>
				<AssetStatusAction
					asset={asset}
					direction={Direction.Outbound}
					selectedStatus={selectedBlastRadiusStatus}
					disabled={!userPermissions.get("UPDATE_ASSET_ZEROTRUST")}
					statusChangeCallback={statusChangeCallback}
					comment={outBoundComment}
					setComment={setOutBoundComment}
					minValue={
						AssetStatusMap[data.lowestOutboundAssetStatus as AssetStatus]
					}
					isTestMode={isTestModeVisibleForOutbound}
					onChangeTestMode={setIsTestModeVisibleForOutbound}
					isCommentDrawerOpen={isOutboundDrawerOpen}
					setIsCommentDrawerOpen={setIsOutboundDrawerOpen}
					useAssetStore={useAssetStore}
					isCommentsVisible={true}
					isPreviewMode={asset.type !== AssetType.Service}
					hasPermission={userPermissions.has("UPDATE_ASSET_ZEROTRUST")}
				/>
			</Stack>
		</Paper>
	);
};

import { Box, Stack, Typography, styled } from "@mui/material";
import { AgentColumnRenderProps, AgentStatusType } from "pages/agents/types";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import UpdateIcon from "@mui/icons-material/Update";
import { AgentActionStatusInfo } from "../agent-data-grid/constants";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import WifiTetheringOffOutlinedIcon from "@mui/icons-material/WifiTetheringOffOutlined";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

const AgentStatusInfo = styled(Stack)(({ theme }) => ({
	color: theme.palette.text.disabled,
	display: "flex",
	alignItems: "left",
	justifyContent: "center",
	paddingLeft: 1,
}));

const AgentUpgradeInfo = ({ text }: { text: string }) => {
	return (
		<Typography variant="caption" ml={1}>
			{window.getCTTranslatedText(text)}
		</Typography>
	);
};

const AgentUpgradeStatus = ({ agent }: AgentColumnRenderProps) => {
	const isUpgradeAvailable =
		agent?.agentId &&
		agent?.agentUpgradeAvailable &&
		agent?.agentUpgradeStatus === AgentActionStatusInfo.Synchronized;
	const isUptoDate =
		agent?.agentId &&
		!agent.agentUpgradeAvailable &&
		agent?.agentUpgradeStatus === AgentActionStatusInfo.Synchronized;
	const isUpgradePending =
		agent?.agentId &&
		(agent?.agentStatus === AgentStatusType.Absent ||
			agent?.agentStatus === AgentStatusType.Unknown) &&
		agent?.agentUpgradeStatus === AgentActionStatusInfo.Pending;
	const isUpgradeInProgress =
		agent?.agentId &&
		agent?.agentStatus === AgentStatusType.Active &&
		agent?.agentUpgradeStatus === AgentActionStatusInfo.Pending;

	if (isUpgradeAvailable) {
		return (
			<AgentStatusInfo direction={"row"}>
				<UpgradeIcon
					fontSize="small"
					sx={{ color: theme => theme.palette.text.disabled }}
				/>
				<AgentUpgradeInfo text={"Upgrade available"} />
			</AgentStatusInfo>
		);
	} else if (isUptoDate) {
		return (
			<AgentStatusInfo direction={"row"}>
				<CheckCircleOutlineIcon fontSize="small" />
				<AgentUpgradeInfo text={"Up to date"} />
			</AgentStatusInfo>
		);
	} else if (isUpgradeInProgress) {
		return (
			<AgentStatusInfo direction={"row"}>
				<UpdateIcon fontSize="small" />
				<AgentUpgradeInfo text={"Upgrade in progress..."} />
			</AgentStatusInfo>
		);
	} else if (isUpgradePending) {
		return (
			<AgentStatusInfo direction={"row"}>
				<UpdateIcon fontSize="small" />
				<AgentUpgradeInfo text={"Upgrade pending..."} />
			</AgentStatusInfo>
		);
	} else return null;
};

const AgentRestartStatus = ({ agent }: AgentColumnRenderProps) => {
	const isAgentRestartProgress =
		agent?.agentId &&
		agent?.agentStatus === AgentStatusType.Active &&
		agent?.agentRestartStatus === AgentActionStatusInfo.Pending;

	const isAgentRestartPending =
		agent?.agentId &&
		(agent?.agentStatus === AgentStatusType.Absent ||
			agent?.agentStatus === AgentStatusType.Unknown) &&
		agent?.agentRestartStatus === AgentActionStatusInfo.Pending;

	if (isAgentRestartPending || isAgentRestartProgress) {
		return (
			<AgentStatusInfo direction="row">
				<RestartAltIcon
					fontSize="small"
					sx={{ color: theme => theme.palette.text.disabled }}
				/>
				<AgentUpgradeInfo
					text={
						isAgentRestartProgress
							? "Restart in progress..."
							: "Restart Pending..."
					}
				/>
			</AgentStatusInfo>
		);
	} else {
		return null;
	}
};

const AgentDecommissionStatus = ({ agent }: AgentColumnRenderProps) => {
	const isAgentDecommissionProgress =
		agent?.agentId &&
		agent?.agentDecommissionStatus === AgentActionStatusInfo.Pending;

	if (isAgentDecommissionProgress) {
		return (
			<AgentStatusInfo direction="row">
				<WifiTetheringOffOutlinedIcon
					fontSize="small"
					sx={{ color: theme => theme.palette.text.disabled }}
				/>
				<AgentUpgradeInfo
					text={window.getCTTranslatedText("decommissionInProgress")}
				/>
			</AgentStatusInfo>
		);
	} else {
		return null;
	}
};

export function AgentVersion({ agent, viewOnly }: AgentColumnRenderProps) {
	if (!agent?.agentId) {
		return <Box style={{ minWidth: 120 }} />;
	}

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
			<CTDatagridButton
				asText={viewOnly === true}
				isDisabled={viewOnly === true}
				facetName={"currentversion"}
				facetOption={agent.currentVersion ?? ""}
			>
				{agent?.currentVersion}
			</CTDatagridButton>
			{agent?.agentDecommissionStatus === AgentActionStatusInfo.Pending ? (
				<AgentDecommissionStatus agent={agent} />
			) : (
				<>
					{agent.agentRestartStatus === AgentActionStatusInfo.Synchronized && (
						<AgentUpgradeStatus agent={agent} />
					)}
					{agent.agentUpgradeStatus === AgentActionStatusInfo.Synchronized && (
						<AgentRestartStatus agent={agent} />
					)}
					{agent.agentUpgradeStatus === AgentActionStatusInfo.Pending &&
						agent.agentRestartStatus === AgentActionStatusInfo.Pending && (
							<AgentUpgradeStatus agent={agent} />
						)}
				</>
			)}
		</Stack>
	);
}

import FolderSpecialOutlinedIcon from "@mui/icons-material/FolderSpecialOutlined";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import StarIcon from "@mui/icons-material/Star";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	Popover,
	Stack,
	Tab,
	Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { CtTabs } from "common/atoms/ct-tabs";
import { TabPanel } from "common/atoms/ct-tabs/CtTabs";
import { useCommonStore } from "common/store";
import { useCriteriaBuilder } from "modules/core/Core";
import { decodeThrowable } from "modules/facets/hooks/useFacetQueryConnector";
import { addRecentQuery, getRecentQueryList } from "modules/saved-query/helper";
import { useListSavedQueriesAPI } from "modules/saved-query/hooks/useListSavedQueriesAPI";
import { useSavedQueryStore } from "modules/saved-query/store";
import {
	FilterQuery,
	QueryType,
	SavedQuery,
	SavedQueryListProps,
} from "modules/saved-query/types";
import React, { useEffect, useMemo, useState } from "react";
import { QueryItem } from "../query-item";
import { QuerySearch } from "../query-search";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";

const noDataBlockStyle = {
	width: "100%",
	height: "300px",
	textAlign: "center",
};

const loaderBlockStyle = {
	position: "relative",
	width: "100%",
	height: "300px",
	textAlign: "center",
};

export const SavedQueryList = ({
	useFacetStore = useCommonStore,
	activeTab = FilterQuery.Saved,
	showButtonTrigger = false,
	disableSearch = false,
}: SavedQueryListProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const setSavedQuery = useSavedQueryStore(state => state.setSavedQuery);
	const savedQueryList = useSavedQueryStore(state => state.savedQueryList);
	const queryClient = useQueryClient();
	const queryString = window.location.search ?? "";
	const metadata = useFacetStore(state => state.metadata);
	let urlParams = new URLSearchParams(window.location.search);
	let facetString = urlParams.get("filters") ?? "";
	const facetState = decodeThrowable(facetString ?? "");
	let searchString = (disableSearch ? "" : urlParams.get("search")) ?? "";
	const searchCriteria = useCriteriaBuilder(searchString, facetState, metadata);

	const [searchText, setSearchText] = useState("");

	const onSearchTextChange = (searchText: string) => {
		setSearchText(searchText ?? "");
	};

	let { data, isLoading }: any = useListSavedQueriesAPI(searchText);

	const queryList: Array<SavedQuery> = useMemo(() => {
		if (!data) {
			return;
		}
		const items = data?.items ?? [];
		return items;
	}, [data]);

	const recentQueryList: Array<SavedQuery> = getRecentQueryList();

	useEffect(() => {
		const isValidRecentQuery = () => {
			return (
				searchCriteria &&
				searchCriteria !== "" &&
				searchCriteria !== "*" &&
				queryString &&
				queryString !== ""
			);
		};

		const saveRecentQuery = () => {
			if (isValidRecentQuery()) {
				const today = new Date();
				const query: SavedQuery = {
					criteria: searchCriteria ?? "*",
					criteriaAsParams: queryString,
					date: today,
				};
				addRecentQuery(query);
			}
		};
		const list = queryList ?? savedQueryList;
		if (queryString && list && list?.length > 0) {
			const queryExistInSavedList = (queryList ?? []).find(
				(item: SavedQuery) => {
					return queryString === item.criteriaAsParams;
				}
			);

			if (queryExistInSavedList) {
				setSavedQuery(queryExistInSavedList);
			} else {
				setSavedQuery(undefined);
			}
		} else {
			setSavedQuery(undefined);
		}
		saveRecentQuery();
	}, [
		facetString,
		searchString,
		queryList,
		setSavedQuery,
		savedQueryList,
		searchCriteria,
		queryString,
	]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	let icon = <FolderSpecialOutlinedIcon color="inherit" />;

	const handleUpdate = ({ field, value }: TextFieldUpdate) => {
		onSearchTextChange(value);
		queryClient.invalidateQueries({
			queryKey: ["savedsearch"],
		});
	};

	const loadQueryCallback = (query: any) => {
		setAnchorEl(null);
	};

	const [selectedTab, setTab] = useState(activeTab);
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	return (
		<Stack alignItems="flex-start">
			{showButtonTrigger ? (
				<Button
					variant="text"
					startIcon={icon}
					color="primary"
					onClick={handleClick}
				>
					{window.getCTTranslatedText("Saved Queries")}
				</Button>
			) : (
				<IconButton size="small" color={"inherit"} onClick={handleClick}>
					{icon}
				</IconButton>
			)}
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<Box sx={{ width: "500px" }}>
					<Stack
						sx={{
							p: 3,
							pb: 0,
						}}
					>
						<Typography variant="h6">
							{window.getCTTranslatedText("Saved Queries")}
						</Typography>
					</Stack>
					<Box sx={{ width: "100%", my: 2 }}>
						<Stack
							alignItems="flex-start"
							justifyContent="flex-start"
							spacing={2}
							sx={{
								width: "100%",
							}}
						>
							<Divider
								sx={{
									width: "100%",
									height: "2px",
								}}
							/>

							<Box sx={{ width: "100%", height: "100%" }}>
								<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
									<CtTabs value={selectedTab} onChange={handleTabChange}>
										<Tab
											icon={<StarIcon />}
											iconPosition="start"
											label={window.getCTTranslatedText("SAVED")}
										/>
										<Tab
											icon={<HistoryToggleOffOutlinedIcon />}
											iconPosition="start"
											label={window.getCTTranslatedText("RECENT")}
										/>
									</CtTabs>
								</Box>
								<TabPanel value={selectedTab} index={FilterQuery.Saved}>
									<Stack
										sx={{
											mt: 2,
										}}
									>
										<QuerySearch
											searchText={searchText}
											handleUpdate={handleUpdate}
										/>
									</Stack>
									{!isLoading && queryList && queryList?.length > 0 && (
										<Stack
											sx={{
												width: "100%",
												pb: 3,
												overflowY: "auto",
												minHeight: "300px",
												maxHeight: "300px",
											}}
										>
											{(queryList ?? []).map((query: SavedQuery) => {
												return (
													<React.Fragment key={query?.name}>
														<QueryItem
															query={query}
															useFacetStore={useFacetStore}
															loadQueryCallback={loadQueryCallback}
														/>
													</React.Fragment>
												);
											})}
										</Stack>
									)}
									{!isLoading && queryList && queryList?.length === 0 && (
										<NoDataBlock type={QueryType.Saved} />
									)}
									{isLoading && <DataLoaderBlock />}
								</TabPanel>
								<TabPanel value={selectedTab} index={FilterQuery.Recent}>
									{!isLoading &&
										recentQueryList &&
										recentQueryList?.length > 0 && (
											<Stack
												sx={{
													width: "100%",
													pb: 3,
													overflowY: "auto",
													minHeight: "300px",
													maxHeight: "300px",
												}}
											>
												{(recentQueryList ?? []).map((query: SavedQuery) => {
													return (
														<React.Fragment key={query?.criteria}>
															<QueryItem
																query={query}
																useFacetStore={useFacetStore}
																loadQueryCallback={loadQueryCallback}
																type={QueryType.Recent}
															/>
														</React.Fragment>
													);
												})}
											</Stack>
										)}
									{!isLoading &&
										recentQueryList &&
										recentQueryList?.length === 0 && (
											<NoDataBlock type={QueryType.Recent} />
										)}
									{isLoading && <DataLoaderBlock />}
								</TabPanel>
							</Box>
						</Stack>
					</Box>
				</Box>
			</Popover>
		</Stack>
	);
};

const NoDataBlock = ({ type = QueryType.Saved }: { type?: QueryType }) => {
	return (
		<Stack
			alignContent="center"
			justifyContent="center"
			justifyItems="center"
			sx={noDataBlockStyle}
		>
			<Typography variant="body1">{`No ${type} queries available`}</Typography>
		</Stack>
	);
};

const DataLoaderBlock = () => {
	return (
		<Stack
			alignContent="center"
			justifyContent="center"
			justifyItems="center"
			sx={loaderBlockStyle}
		>
			<CircularProgress size={28} sx={{ mx: "auto" }} />
		</Stack>
	);
};

import { Chip } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import { Loader } from "common/atoms/loader";
import { TermsAndConditionModal } from "common/molecules/TermsAndConditionModal";
import { AuthState, useAuthState } from "hooks/useAuthState";
import {
	getComplianceReqCheckList,
	getCurrentTenantID,
} from "hooks/useComplianceController/helper";
import { useDemoController } from "hooks/useDemoController";
import { useLocale } from "hooks/useLocale";
import { useNetworking } from "hooks/useNetworking";
import { useTncModal } from "hooks/useTnCModal";
import { enableMapSet } from "immer";
import { CTSnackbar } from "modules/snackbar";
import { SystemUseNotification } from "pages/login/components/system-use-notification";
import React from "react";
import { CTRoutes } from "routes";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);

// immer
enableMapSet();

function App() {
	const { authState, checkAuthState, logout } = useAuthState();
	const { networkingHOC } = useNetworking({ checkAuthState });
	useLocale();
	return networkingHOC(<Main authState={authState} logout={logout} />);
}

interface MainConfig {
	logout: Function;
	authState: AuthState;
}

function Main({ authState, logout }: MainConfig) {
	const { acceptTermsAndCondition, showAcceptTnCModal, loading } = useTncModal({
		authState: authState,
	});
	if (authState === AuthState.Unknown) {
		return <Loader />;
	}

	return (
		<>
			<TermsAndConditionModal
				acceptTermsAndCondition={acceptTermsAndCondition}
				showAcceptTnCModal={showAcceptTnCModal}
				loading={loading}
			/>
			<DemoWrapper logout={logout}>
				<CTRoutes authState={authState} onLogout={logout} />
			</DemoWrapper>
			<CTSnackbar />
		</>
	);
}

function DemoWrapper({
	children,
	logout,
}: {
	children: React.ReactElement;
	logout: Function;
}) {
	const { isDemo, handleHomeClick } = useDemoController();

	const currentTenantID = getCurrentTenantID();
	const tenantCompliantList = getComplianceReqCheckList();

	if (
		currentTenantID &&
		tenantCompliantList &&
		tenantCompliantList.get(currentTenantID) === true
	) {
		return (
			<>
				<SystemUseNotification
					currentTenantID={currentTenantID}
					cancelCallback={logout}
				/>
			</>
		);
	}

	if (!isDemo) {
		return <>{children}</>;
	}
	return (
		<>
			{children}
			<div
				style={{
					pointerEvents: "none",
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 10000,
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Chip
					sx={{ pointerEvents: "all", top: 2 }}
					color="secondary"
					onDelete={() => {
						handleHomeClick();
					}}
					label={window.getCTTranslatedText("Demo mode")}
				/>
			</div>
		</>
	);
}

export default App;

import { Paper, useTheme } from "@mui/material";
import { useCommonStore } from "common/store";
import { CustomCommonStoreType } from "common/types/types";
import { AppliedFacetsChips } from "modules/facets/components/applied-facets-chips";
import { useState } from "react";
import { ScopeMetadata } from "modules/scope-metadata/types";

export interface DisplayAppliedRuleProps {
	useFacetStore?: CustomCommonStoreType;
	readOnlyDimensions?: Array<string>;
	minRequired?: number;
	sortByName?: boolean;
	disableElevation?: boolean;
	viewOnly?: boolean;
	metadata?: ScopeMetadata;
}

const appliedFacetStyle = {
	width: "100%",
	mt: 2,
	px: 1,
	py: 1,
};

export const DisplayAppliedRule = ({
	useFacetStore = useCommonStore,
	readOnlyDimensions,
	minRequired,
	sortByName,
	disableElevation = false,
	viewOnly,
	metadata,
}: DisplayAppliedRuleProps) => {
	const theme = useTheme();
	const [appliedCount, setAppliedCount] = useState(0);

	return (
		<>
			<Paper
				sx={appliedCount > 0 ? appliedFacetStyle : {}}
				elevation={theme.palette.mode === "dark" && !disableElevation ? 3 : 0}
			>
				<AppliedFacetsChips
					minRequired={minRequired}
					appliedCount={appliedCount}
					onCountFilters={setAppliedCount}
					useFacetStore={useFacetStore}
					readOnlyDimensions={readOnlyDimensions}
					sortByName={sortByName}
					viewOnly={viewOnly}
					metadata={metadata}
				/>
			</Paper>
		</>
	);
};

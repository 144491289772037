import { useTagPolicyListAPI } from "pages/tags/components/tag-policy-list/TagPolicyList";
import { useEffect } from "react";

interface FindTagBasedPolicyIdOptions {
	criteria: string | undefined;
	onFindTagBasedPolicyId?: (id: string) => void;
	policyId?: string;
}

export const useFindTagBasedPolicyId = ({
	criteria,
	onFindTagBasedPolicyId,
	policyId,
}: FindTagBasedPolicyIdOptions) => {
	const findTagPolicyAPI = useTagPolicyListAPI();
	const findTagPolicyMutate = findTagPolicyAPI.mutateAsync;

	useEffect(() => {
		if (!criteria || criteria === "*" || policyId) {
			return;
		}

		let searchCriteria = criteria;
		findTagPolicyMutate(
			{
				criteria: searchCriteria,
				pagination: {
					offset: 0,
					limit: 1,
				},
			},
			{
				onSuccess: data => {
					if (data) {
						const id = data?.items?.[0]?.tagBasedPolicyId;
						onFindTagBasedPolicyId?.(id);
					}
				},
			}
		);
	}, [policyId, criteria, findTagPolicyMutate, onFindTagBasedPolicyId]);

	return findTagPolicyAPI;
};

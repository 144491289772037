import { Box } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { useCommonStore } from "common/store";
import { UnmanagedDevicesColumnRenderProps } from "../../appliance-unmanaged-devices/types";

export function UnmanagedDeviceVendor({
	device,
	viewOnly,
}: UnmanagedDevicesColumnRenderProps) {
	if (!device?.deviceId) {
		return <Box style={{ width: 70 }} />;
	}

	return (
		<CTDatagridButton
			asText={viewOnly === true}
			isDisabled={viewOnly === true}
			useFacetStore={useCommonStore}
			facetName={"devicevendor"}
			facetOption={device?.deviceVendor ?? ""}
		>
			{device?.deviceVendor}
		</CTDatagridButton>
	);
}

import {
	alpha,
	Button,
	ButtonProps,
	styled,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { IconsProps } from "assets/svgs/common";
import { CtBadge } from "common/atoms/ctbadge";
import numeral from "numeral";

export const CtButton = styled(Button)<ButtonProps>(({ theme }) => ({
	height: "42px",
	position: "relative",
	boxShadow: "none",
	"&:hover": {
		boxShadow: "none",
	},
}));

interface AssetsButtonWithBadgeProps {
	renderIcon: (props: IconsProps) => JSX.Element;
	badgeContent: string;
	isInvisible: boolean;
	isButtonActive: boolean;
	disabled?: boolean;
	counts: { main: number; test: number };
	onClick: () => void;
	format: string;
	tooltipText?: string;
}

export const AssetsButtonWithBadge = ({
	badgeContent,
	isInvisible,
	isButtonActive,
	renderIcon,
	onClick,
	counts,
	format,
	disabled,
	tooltipText = "",
}: AssetsButtonWithBadgeProps) => {
	const theme = useTheme();

	const count = counts.main + counts.test;

	let iconColor =
		theme.palette.mode === "dark"
			? theme.palette.text.secondary
			: alpha(theme.palette.text.primary, 0.6);

	if (!counts.main && !counts.test) {
		iconColor = theme.palette.text.disabled;
	} else if (isButtonActive) {
		iconColor = theme.palette.primary.main;
	}

	return (
		<CtBadge
			max={9999}
			badgeContent={badgeContent}
			invisible={isInvisible}
			backgroundColor={iconColor}
			sx={{
				flex: 1,
				color: theme => theme.palette.primary.contrastText,
			}}
		>
			<Tooltip title={tooltipText}>
				<span
					style={{
						flex: 1,
						pointerEvents: "all",
						cursor: !count || count === 0 ? "default" : "pointer",
					}}
				>
					<CtButton
						disabled={!count || disabled}
						variant="outlined"
						fullWidth
						color={isButtonActive ? "primary" : undefined}
						sx={{
							borderColor: isButtonActive ? "primary" : "divider",
							flex: 1,
						}}
						onClick={() => {
							onClick();
						}}
					>
						{renderIcon({ color: iconColor, width: "18", height: "18" })}
						<Typography
							variant="overline"
							sx={{
								fontSize: 15,
								color: iconColor,
								ml: "12px",
							}}
						>
							{numeral(count || 0).format(format)}
						</Typography>
					</CtButton>
				</span>
			</Tooltip>
		</CtBadge>
	);
};

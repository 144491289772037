import {
	Box,
	Stack,
	DialogActions,
	DialogContent,
	Dialog,
	Button,
	DialogTitle,
	Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { TagKeyInt } from "pages/tags/types";
import { useDeleteTag } from "./hooks/useDeleteTag";

const dialogMinWidth = 300;

export interface TagDeleteProps {
	tag: TagKeyInt | undefined;
	handleClose: () => void;
	allowDelete: boolean;
}

export function TagDelete({ tag, handleClose, allowDelete }: TagDeleteProps) {
	const { deleteTag, isLoading } = useDeleteTag({ tag });

	return (
		<>
			<Dialog open={allowDelete} onClose={handleClose}>
				<DialogTitle>{`Confirm`}</DialogTitle>
				<DialogContent>
					<Box sx={{ minWidth: dialogMinWidth }}>
						<Stack alignItems="center" justifyContent="center">
							<Typography variant="body1">
								{`${window.getCTTranslatedText("AreYouSureYouWantToDeleteTag", {
									displayName: window.getCTTranslatedText(
										tag?.displayName ?? ""
									),
								})}`}
							</Typography>
						</Stack>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color="inherit" onClick={handleClose}>
						{window.getCTTranslatedText("Cancel")}
					</Button>
					<LoadingButton
						loading={isLoading}
						onClick={deleteTag}
						autoFocus
						color={"error"}
						type="submit"
						variant="contained"
					>
						{window.getCTTranslatedText("Delete")}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
}

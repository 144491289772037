import { useMutation } from "@tanstack/react-query";

export const useSaveQueryAPI = () => {
	return useMutation<any, Error, any>(["savedsearch", "savedsearch"]);
};

export const useUpdateSavedQueryAPI = (savedSearchId: string | undefined) => {
	const path = `savedsearch/${savedSearchId}`;
	return useMutation<any, Error, any>(["savedsearch", path, "put"]);
};

export const useDeleteSavedQueryAPI = (savedSearchId: string | undefined) => {
	const path = `savedsearch/${savedSearchId}`;
	return useMutation<any, Error, any>(["savedsearch", path, "delete"]);
};

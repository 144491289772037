import { ApplianceInstall } from "./components/ApplianceInstall/ApplianceInstall";
import { ApplianceRegister } from "./components/ApplianceRegister/ApplianceRegister";

export function ApplianceDownloadPage() {
	return (
		<>
			<ApplianceInstall />
			<ApplianceRegister />
		</>
	);
}

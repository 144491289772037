import { AgentGroupByObj } from "../../agent-drawers/hooks/useGroupByAgentData";
import { PLATFORMS } from "../constants";
import { ArchMap } from "../type";
import { supportedPlatformArchitecturesDownloadPage } from "./agentTypeHelpers";

interface SupportedPlatformArchitecturesUpgradePageOptions {
	platform: PLATFORMS;
	agentsGroupByPlatforms?: AgentGroupByObj;
}

export function supportedPlatformArchitecturesUpgradePage({
	platform,
	agentsGroupByPlatforms,
}: SupportedPlatformArchitecturesUpgradePageOptions) {
	let availableArchPlatform = supportedPlatformArchitecturesDownloadPage({
		platform,
	});
	let filteredPlatform = { ...agentsGroupByPlatforms }?.[platform]
		?.map(agent => {
			const data = agent.data ?? [];
			const archMap: ArchMap = {};
			for (const element of data) {
				archMap[element.architecture] = true;
			}

			return availableArchPlatform.filter(element => archMap[element]);
		})
		.flat();

	return filteredPlatform ?? [];
}

import { AnalyticsAPIReq } from "../../../../../common/types/types";

export const barChartRequestBody = ({
	criteria,
	scope,
	groupBy,
	statistics,
	timeStart,
	timeEnd,
}: AnalyticsAPIReq) => {
	let body: AnalyticsAPIReq = {
		criteria: criteria,
		scope: scope,
		groupBy: groupBy,
		statistics: statistics,
	};

	if (timeStart && timeEnd) {
		body["timeStart"] = timeStart;
		body["timeEnd"] = timeEnd;
	}

	return body;
};

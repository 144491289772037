import { Stack, Typography } from "@mui/material";
import numeral from "numeral";
import pluralize from "pluralize";
import { AgentGroupByObj } from "../agent-drawers/hooks/useGroupByAgentData";
import { PLATFORMS } from "./constants";

interface UpgradeAgentCountProps {
	agentsGroupByPlatforms?: AgentGroupByObj;
	platform: PLATFORMS;
	architecture: string;
	index: number;
}

export const UpgradeAgentCount = ({
	agentsGroupByPlatforms,
	platform,
	architecture,
	index,
}: UpgradeAgentCountProps) => {
	const upgradeAgentCount =
		agentsGroupByPlatforms?.[platform][index]?.data?.filter(
			arch => arch.architecture === architecture
		)?.length ?? 0;

	return (
		<Stack sx={{ minWidth: 120 }}>
			<Typography
				variant="body2"
				noWrap
				color={theme => theme.palette.text.secondary}
			>
				{`${numeral(upgradeAgentCount).format("0,0")}${" "}
${window.getCTTranslatedText(pluralize("Agent", upgradeAgentCount ?? 0))}`}
			</Typography>
		</Stack>
	);
};

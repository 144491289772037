import LoadingButton from "@mui/lab/LoadingButton";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useCreateReportAPI } from "pages/reports/hooks";
import { CreateReportRequestBody } from "pages/reports/hooks/useCreateReportAPI";
import { useState } from "react";

interface CreateReportDialogProps {
	open: boolean;
	onClose: () => void;
	title?: string;
	primaryButtonText?: string;
	secondaryButtonText?: string;
	selectedCriteria: string[];
}

export function CreateReportDialog({
	open,
	onClose,
	title = "CreateReport",
	primaryButtonText = "Save",
	secondaryButtonText = "Cancel",
	selectedCriteria,
}: Readonly<CreateReportDialogProps>) {
	const [reportName, setReportName] = useState("");
	const createReportAPI = useCreateReportAPI();
	const createReportMutate = createReportAPI.mutate;
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const onClick = () => {
		const body: CreateReportRequestBody = {
			jobType: "executivereport",
			scheduleType: "now",
			data: {
				name: reportName,
				criterias: selectedCriteria.map(criteria => ({
					criteria,
					scope: Scope.Asset,
				})),
			},
		};

		createReportMutate(body, {
			onSuccess: () => {
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"CreateReportSubmittedSuccessfully"
				);
				onClose();
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, error.message);
			},
		});
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
			<DialogTitle>{window.getCTTranslatedText(title)}</DialogTitle>

			<DialogContent>
				<CTInputField
					field="reportName"
					displayName="Report Name"
					value={reportName ?? ""}
					handleUpdate={(event: TextFieldUpdate) => setReportName(event?.value)}
				/>
			</DialogContent>

			<DialogActions>
				<Button
					variant="outlined"
					onClick={() => {
						onClose();
					}}
				>
					{window.getCTTranslatedText(secondaryButtonText)}
				</Button>
				<LoadingButton
					loading={createReportAPI.isLoading}
					variant="contained"
					color="primary"
					disabled={!reportName.trim()}
					onClick={onClick}
				>
					{window.getCTTranslatedText(primaryButtonText)}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}

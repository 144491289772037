import { useState } from "react";

export function useTagTemplates() {
	const [showAddToTemplateDrawer, setShowAddToTemplateDrawer] = useState(false);

	const openAddToTemplateDrawer = () => {
		setShowAddToTemplateDrawer(true);
	};

	const closeAddToTemplateDrawer = () => {
		setShowAddToTemplateDrawer(false);
	};

	return {
		showAddToTemplateDrawer,
		openAddToTemplateDrawer,
		closeAddToTemplateDrawer,
	};
}

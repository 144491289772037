// Generated from src/parser/ctql.g4 by ANTLR 4.9.0-SNAPSHOT

import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { ctqlListener } from "./ctqlListener";
import { ctqlVisitor } from "./ctqlVisitor";

export class ctqlParser extends Parser {
	public static readonly OR = 1;
	public static readonly AND = 2;
	public static readonly NOT = 3;
	public static readonly LIKE = 4;
	public static readonly ILIKE = 5;
	public static readonly IN = 6;
	public static readonly BETWEEN = 7;
	public static readonly CIDRMATCH = 8;
	public static readonly NULL = 9;
	public static readonly EQ = 10;
	public static readonly NEQ = 11;
	public static readonly LT = 12;
	public static readonly LTE = 13;
	public static readonly GT = 14;
	public static readonly GTE = 15;
	public static readonly ASTERISK = 16;
	public static readonly LP = 17;
	public static readonly RP = 18;
	public static readonly COMMA = 19;
	public static readonly D_QUOTE = 20;
	public static readonly S_QUOTE = 21;
	public static readonly SINGLE_QUOTED_LITERAL = 22;
	public static readonly DOUBLE_QUOTED_LITERAL = 23;
	public static readonly UNQUOTED_LITERAL = 24;
	public static readonly NUMERIC_LITERAL = 25;
	public static readonly POSITIVE_NUMERAL = 26;
	public static readonly NEGATIVE_NUMERAL = 27;
	public static readonly WS = 28;
	public static readonly RULE_clause = 0;
	public static readonly RULE_logicalExpr = 1;
	public static readonly RULE_or_expr = 2;
	public static readonly RULE_and_expr = 3;
	public static readonly RULE_not_expr = 4;
	public static readonly RULE_sub_expr = 5;
	public static readonly RULE_expr = 6;
	public static readonly RULE_all_expr = 7;
	public static readonly RULE_equalityExpression = 8;
	public static readonly RULE_numericCompareExpression = 9;
	public static readonly RULE_inExpression = 10;
	public static readonly RULE_betweenExpression = 11;
	public static readonly RULE_likeExpression = 12;
	public static readonly RULE_boolFunction = 13;
	public static readonly RULE_cidrMatch = 14;
	public static readonly RULE_valueList = 15;
	public static readonly RULE_field = 16;
	public static readonly RULE_keywordExpression = 17;
	public static readonly RULE_value = 18;
	public static readonly RULE_statList = 19;
	public static readonly RULE_statFunc = 20;
	public static readonly RULE_nestedFunc = 21;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"clause",
		"logicalExpr",
		"or_expr",
		"and_expr",
		"not_expr",
		"sub_expr",
		"expr",
		"all_expr",
		"equalityExpression",
		"numericCompareExpression",
		"inExpression",
		"betweenExpression",
		"likeExpression",
		"boolFunction",
		"cidrMatch",
		"valueList",
		"field",
		"keywordExpression",
		"value",
		"statList",
		"statFunc",
		"nestedFunc",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		"'='",
		"'!='",
		"'<'",
		"'<='",
		"'>'",
		"'>='",
		"'*'",
		"'('",
		"')'",
		"','",
		"'\"'",
		"'''",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined,
		"OR",
		"AND",
		"NOT",
		"LIKE",
		"ILIKE",
		"IN",
		"BETWEEN",
		"CIDRMATCH",
		"NULL",
		"EQ",
		"NEQ",
		"LT",
		"LTE",
		"GT",
		"GTE",
		"ASTERISK",
		"LP",
		"RP",
		"COMMA",
		"D_QUOTE",
		"S_QUOTE",
		"SINGLE_QUOTED_LITERAL",
		"DOUBLE_QUOTED_LITERAL",
		"UNQUOTED_LITERAL",
		"NUMERIC_LITERAL",
		"POSITIVE_NUMERAL",
		"NEGATIVE_NUMERAL",
		"WS",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(
		ctqlParser._LITERAL_NAMES,
		ctqlParser._SYMBOLIC_NAMES,
		[]
	);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return ctqlParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string {
		return "ctql.g4";
	}

	// @Override
	public get ruleNames(): string[] {
		return ctqlParser.ruleNames;
	}

	// @Override
	public get serializedATN(): string {
		return ctqlParser._serializedATN;
	}

	protected createFailedPredicateException(
		predicate?: string,
		message?: string
	): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(ctqlParser._ATN, this);
	}
	// @RuleVersion(0)
	public clause(): ClauseContext {
		let _localctx: ClauseContext = new ClauseContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, ctqlParser.RULE_clause);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 46;
				this._errHandler.sync(this);
				switch (this._input.LA(1)) {
					case ctqlParser.ASTERISK:
						{
							this.state = 44;
							this.all_expr();
						}
						break;
					case ctqlParser.NOT:
					case ctqlParser.CIDRMATCH:
					case ctqlParser.LP:
					case ctqlParser.SINGLE_QUOTED_LITERAL:
					case ctqlParser.DOUBLE_QUOTED_LITERAL:
					case ctqlParser.UNQUOTED_LITERAL:
						{
							this.state = 45;
							this.logicalExpr();
						}
						break;
					default:
						throw new NoViableAltException(this);
				}
				this.state = 48;
				this.match(ctqlParser.EOF);
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public logicalExpr(): LogicalExprContext {
		let _localctx: LogicalExprContext = new LogicalExprContext(
			this._ctx,
			this.state
		);
		this.enterRule(_localctx, 2, ctqlParser.RULE_logicalExpr);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 50;
				this.or_expr();
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public or_expr(): Or_exprContext {
		let _localctx: Or_exprContext = new Or_exprContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, ctqlParser.RULE_or_expr);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 52;
				this.and_expr();
				this.state = 57;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === ctqlParser.OR) {
					{
						{
							this.state = 53;
							this.match(ctqlParser.OR);
							this.state = 54;
							this.and_expr();
						}
					}
					this.state = 59;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public and_expr(): And_exprContext {
		let _localctx: And_exprContext = new And_exprContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, ctqlParser.RULE_and_expr);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 60;
				this.expr();
				this.state = 67;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (
					(_la & ~0x1f) === 0 &&
					((1 << _la) &
						((1 << ctqlParser.AND) |
							(1 << ctqlParser.NOT) |
							(1 << ctqlParser.CIDRMATCH) |
							(1 << ctqlParser.LP) |
							(1 << ctqlParser.SINGLE_QUOTED_LITERAL) |
							(1 << ctqlParser.DOUBLE_QUOTED_LITERAL) |
							(1 << ctqlParser.UNQUOTED_LITERAL))) !==
						0
				) {
					{
						{
							this.state = 62;
							this._errHandler.sync(this);
							_la = this._input.LA(1);
							if (_la === ctqlParser.AND) {
								{
									this.state = 61;
									this.match(ctqlParser.AND);
								}
							}

							this.state = 64;
							this.expr();
						}
					}
					this.state = 69;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public not_expr(): Not_exprContext {
		let _localctx: Not_exprContext = new Not_exprContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, ctqlParser.RULE_not_expr);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 70;
				this.match(ctqlParser.NOT);
				this.state = 71;
				this.expr();
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public sub_expr(): Sub_exprContext {
		let _localctx: Sub_exprContext = new Sub_exprContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, ctqlParser.RULE_sub_expr);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 73;
				this.match(ctqlParser.LP);
				this.state = 74;
				this.logicalExpr();
				this.state = 75;
				this.match(ctqlParser.RP);
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public expr(): ExprContext {
		let _localctx: ExprContext = new ExprContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, ctqlParser.RULE_expr);
		try {
			this.state = 88;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
				case ctqlParser.CIDRMATCH:
				case ctqlParser.SINGLE_QUOTED_LITERAL:
				case ctqlParser.DOUBLE_QUOTED_LITERAL:
				case ctqlParser.UNQUOTED_LITERAL:
					this.enterOuterAlt(_localctx, 1);
					{
						this.state = 84;
						this._errHandler.sync(this);
						switch (
							this.interpreter.adaptivePredict(this._input, 4, this._ctx)
						) {
							case 1:
								{
									this.state = 77;
									this.keywordExpression();
								}
								break;

							case 2:
								{
									this.state = 78;
									this.equalityExpression();
								}
								break;

							case 3:
								{
									this.state = 79;
									this.numericCompareExpression();
								}
								break;

							case 4:
								{
									this.state = 80;
									this.inExpression();
								}
								break;

							case 5:
								{
									this.state = 81;
									this.betweenExpression();
								}
								break;

							case 6:
								{
									this.state = 82;
									this.likeExpression();
								}
								break;

							case 7:
								{
									this.state = 83;
									this.boolFunction();
								}
								break;
						}
					}
					break;
				case ctqlParser.NOT:
					this.enterOuterAlt(_localctx, 2);
					{
						this.state = 86;
						this.not_expr();
					}
					break;
				case ctqlParser.LP:
					this.enterOuterAlt(_localctx, 3);
					{
						this.state = 87;
						this.sub_expr();
					}
					break;
				default:
					throw new NoViableAltException(this);
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public all_expr(): All_exprContext {
		let _localctx: All_exprContext = new All_exprContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, ctqlParser.RULE_all_expr);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 90;
				this.match(ctqlParser.ASTERISK);
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public equalityExpression(): EqualityExpressionContext {
		let _localctx: EqualityExpressionContext = new EqualityExpressionContext(
			this._ctx,
			this.state
		);
		this.enterRule(_localctx, 16, ctqlParser.RULE_equalityExpression);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 92;
				this.field();
				this.state = 93;
				_la = this._input.LA(1);
				if (!(_la === ctqlParser.EQ || _la === ctqlParser.NEQ)) {
					this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				this.state = 94;
				this.value();
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public numericCompareExpression(): NumericCompareExpressionContext {
		let _localctx: NumericCompareExpressionContext =
			new NumericCompareExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 18, ctqlParser.RULE_numericCompareExpression);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 96;
				this.field();
				this.state = 97;
				_la = this._input.LA(1);
				if (
					!(
						(_la & ~0x1f) === 0 &&
						((1 << _la) &
							((1 << ctqlParser.LT) |
								(1 << ctqlParser.LTE) |
								(1 << ctqlParser.GT) |
								(1 << ctqlParser.GTE))) !==
							0
					)
				) {
					this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				this.state = 98;
				this.value();
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public inExpression(): InExpressionContext {
		let _localctx: InExpressionContext = new InExpressionContext(
			this._ctx,
			this.state
		);
		this.enterRule(_localctx, 20, ctqlParser.RULE_inExpression);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 100;
				this.field();
				this.state = 102;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === ctqlParser.NOT) {
					{
						this.state = 101;
						this.match(ctqlParser.NOT);
					}
				}

				this.state = 104;
				this.match(ctqlParser.IN);
				this.state = 105;
				this.valueList();
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public betweenExpression(): BetweenExpressionContext {
		let _localctx: BetweenExpressionContext = new BetweenExpressionContext(
			this._ctx,
			this.state
		);
		this.enterRule(_localctx, 22, ctqlParser.RULE_betweenExpression);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 107;
				this.field();
				this.state = 109;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === ctqlParser.NOT) {
					{
						this.state = 108;
						this.match(ctqlParser.NOT);
					}
				}

				this.state = 111;
				this.match(ctqlParser.BETWEEN);
				this.state = 112;
				this.value();
				this.state = 113;
				this.match(ctqlParser.AND);
				this.state = 114;
				this.value();
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public likeExpression(): LikeExpressionContext {
		let _localctx: LikeExpressionContext = new LikeExpressionContext(
			this._ctx,
			this.state
		);
		this.enterRule(_localctx, 24, ctqlParser.RULE_likeExpression);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 116;
				this.field();
				this.state = 118;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === ctqlParser.NOT) {
					{
						this.state = 117;
						this.match(ctqlParser.NOT);
					}
				}

				this.state = 125;
				this._errHandler.sync(this);
				switch (this.interpreter.adaptivePredict(this._input, 9, this._ctx)) {
					case 1:
						{
							this.state = 120;
							_la = this._input.LA(1);
							if (!(_la === ctqlParser.LIKE || _la === ctqlParser.ILIKE)) {
								this._errHandler.recoverInline(this);
							} else {
								if (this._input.LA(1) === Token.EOF) {
									this.matchedEOF = true;
								}

								this._errHandler.reportMatch(this);
								this.consume();
							}
							this.state = 121;
							this.value();
						}
						break;

					case 2:
						{
							this.state = 122;
							_la = this._input.LA(1);
							if (!(_la === ctqlParser.LIKE || _la === ctqlParser.ILIKE)) {
								this._errHandler.recoverInline(this);
							} else {
								if (this._input.LA(1) === Token.EOF) {
									this.matchedEOF = true;
								}

								this._errHandler.reportMatch(this);
								this.consume();
							}
							this.state = 123;
							this.match(ctqlParser.IN);
							this.state = 124;
							this.valueList();
						}
						break;
				}
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public boolFunction(): BoolFunctionContext {
		let _localctx: BoolFunctionContext = new BoolFunctionContext(
			this._ctx,
			this.state
		);
		this.enterRule(_localctx, 26, ctqlParser.RULE_boolFunction);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 127;
				this.cidrMatch();
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public cidrMatch(): CidrMatchContext {
		let _localctx: CidrMatchContext = new CidrMatchContext(
			this._ctx,
			this.state
		);
		this.enterRule(_localctx, 28, ctqlParser.RULE_cidrMatch);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 129;
				this.match(ctqlParser.CIDRMATCH);
				this.state = 130;
				this.match(ctqlParser.LP);
				this.state = 131;
				this.value();
				this.state = 132;
				this.match(ctqlParser.RP);
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public valueList(): ValueListContext {
		let _localctx: ValueListContext = new ValueListContext(
			this._ctx,
			this.state
		);
		this.enterRule(_localctx, 30, ctqlParser.RULE_valueList);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 134;
				this.match(ctqlParser.LP);
				this.state = 135;
				this.value();
				this.state = 140;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === ctqlParser.COMMA) {
					{
						{
							this.state = 136;
							this.match(ctqlParser.COMMA);
							this.state = 137;
							this.value();
						}
					}
					this.state = 142;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 143;
				this.match(ctqlParser.RP);
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public field(): FieldContext {
		let _localctx: FieldContext = new FieldContext(this._ctx, this.state);
		this.enterRule(_localctx, 32, ctqlParser.RULE_field);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 145;
				_la = this._input.LA(1);
				if (
					!(
						(_la & ~0x1f) === 0 &&
						((1 << _la) &
							((1 << ctqlParser.SINGLE_QUOTED_LITERAL) |
								(1 << ctqlParser.DOUBLE_QUOTED_LITERAL) |
								(1 << ctqlParser.UNQUOTED_LITERAL))) !==
							0
					)
				) {
					this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public keywordExpression(): KeywordExpressionContext {
		let _localctx: KeywordExpressionContext = new KeywordExpressionContext(
			this._ctx,
			this.state
		);
		this.enterRule(_localctx, 34, ctqlParser.RULE_keywordExpression);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 147;
				_la = this._input.LA(1);
				if (
					!(
						(_la & ~0x1f) === 0 &&
						((1 << _la) &
							((1 << ctqlParser.SINGLE_QUOTED_LITERAL) |
								(1 << ctqlParser.DOUBLE_QUOTED_LITERAL) |
								(1 << ctqlParser.UNQUOTED_LITERAL))) !==
							0
					)
				) {
					this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public value(): ValueContext {
		let _localctx: ValueContext = new ValueContext(this._ctx, this.state);
		this.enterRule(_localctx, 36, ctqlParser.RULE_value);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 149;
				_la = this._input.LA(1);
				if (
					!(
						(_la & ~0x1f) === 0 &&
						((1 << _la) &
							((1 << ctqlParser.NULL) |
								(1 << ctqlParser.SINGLE_QUOTED_LITERAL) |
								(1 << ctqlParser.DOUBLE_QUOTED_LITERAL) |
								(1 << ctqlParser.UNQUOTED_LITERAL) |
								(1 << ctqlParser.NUMERIC_LITERAL))) !==
							0
					)
				) {
					this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public statList(): StatListContext {
		let _localctx: StatListContext = new StatListContext(this._ctx, this.state);
		this.enterRule(_localctx, 38, ctqlParser.RULE_statList);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 153;
				this._errHandler.sync(this);
				switch (this.interpreter.adaptivePredict(this._input, 11, this._ctx)) {
					case 1:
						{
							this.state = 151;
							this.statFunc();
						}
						break;

					case 2:
						{
							this.state = 152;
							this.nestedFunc();
						}
						break;
				}
				this.state = 162;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === ctqlParser.COMMA) {
					{
						{
							this.state = 155;
							this.match(ctqlParser.COMMA);
							this.state = 158;
							this._errHandler.sync(this);
							switch (
								this.interpreter.adaptivePredict(this._input, 12, this._ctx)
							) {
								case 1:
									{
										this.state = 156;
										this.statFunc();
									}
									break;

								case 2:
									{
										this.state = 157;
										this.nestedFunc();
									}
									break;
							}
						}
					}
					this.state = 164;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public statFunc(): StatFuncContext {
		let _localctx: StatFuncContext = new StatFuncContext(this._ctx, this.state);
		this.enterRule(_localctx, 40, ctqlParser.RULE_statFunc);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 165;
				this.match(ctqlParser.UNQUOTED_LITERAL);
				this.state = 166;
				this.match(ctqlParser.LP);
				this.state = 167;
				this.field();
				this.state = 168;
				this.match(ctqlParser.RP);
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public nestedFunc(): NestedFuncContext {
		let _localctx: NestedFuncContext = new NestedFuncContext(
			this._ctx,
			this.state
		);
		this.enterRule(_localctx, 42, ctqlParser.RULE_nestedFunc);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 170;
				this.match(ctqlParser.UNQUOTED_LITERAL);
				this.state = 171;
				this.match(ctqlParser.LP);
				this.state = 172;
				this.statFunc();
				this.state = 173;
				this.match(ctqlParser.COMMA);
				this.state = 174;
				this.match(ctqlParser.NUMERIC_LITERAL);
				this.state = 175;
				this.match(ctqlParser.RP);
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03\x1E\xB4\x04\x02" +
		"\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07" +
		"\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r\x04" +
		"\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t\x12\x04" +
		"\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t\x17\x03" +
		"\x02\x03\x02\x05\x021\n\x02\x03\x02\x03\x02\x03\x03\x03\x03\x03\x04\x03" +
		"\x04\x03\x04\x07\x04:\n\x04\f\x04\x0E\x04=\v\x04\x03\x05\x03\x05\x05\x05" +
		"A\n\x05\x03\x05\x07\x05D\n\x05\f\x05\x0E\x05G\v\x05\x03\x06\x03\x06\x03" +
		"\x06\x03\x07\x03\x07\x03\x07\x03\x07\x03\b\x03\b\x03\b\x03\b\x03\b\x03" +
		"\b\x03\b\x05\bW\n\b\x03\b\x03\b\x05\b[\n\b\x03\t\x03\t\x03\n\x03\n\x03" +
		"\n\x03\n\x03\v\x03\v\x03\v\x03\v\x03\f\x03\f\x05\fi\n\f\x03\f\x03\f\x03" +
		"\f\x03\r\x03\r\x05\rp\n\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\x0E\x03\x0E" +
		"\x05\x0Ey\n\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x05\x0E\x80\n" +
		"\x0E\x03\x0F\x03\x0F\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x11\x03" +
		"\x11\x03\x11\x03\x11\x07\x11\x8D\n\x11\f\x11\x0E\x11\x90\v\x11\x03\x11" +
		"\x03\x11\x03\x12\x03\x12\x03\x13\x03\x13\x03\x14\x03\x14\x03\x15\x03\x15" +
		"\x05\x15\x9C\n\x15\x03\x15\x03\x15\x03\x15\x05\x15\xA1\n\x15\x07\x15\xA3" +
		"\n\x15\f\x15\x0E\x15\xA6\v\x15\x03\x16\x03\x16\x03\x16\x03\x16\x03\x16" +
		"\x03\x17\x03\x17\x03\x17\x03\x17\x03\x17\x03\x17\x03\x17\x03\x17\x02\x02" +
		"\x02\x18\x02\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x0E\x02\x10\x02\x12" +
		'\x02\x14\x02\x16\x02\x18\x02\x1A\x02\x1C\x02\x1E\x02 \x02"\x02$\x02&' +
		"\x02(\x02*\x02,\x02\x02\x07\x03\x02\f\r\x03\x02\x0E\x11\x03\x02\x06\x07" +
		"\x03\x02\x18\x1A\x04\x02\v\v\x18\x1B\x02\xB1\x020\x03\x02\x02\x02\x04" +
		"4\x03\x02\x02\x02\x066\x03\x02\x02\x02\b>\x03\x02\x02\x02\nH\x03\x02\x02" +
		"\x02\fK\x03\x02\x02\x02\x0EZ\x03\x02\x02\x02\x10\\\x03\x02\x02\x02\x12" +
		"^\x03\x02\x02\x02\x14b\x03\x02\x02\x02\x16f\x03\x02\x02\x02\x18m\x03\x02" +
		"\x02\x02\x1Av\x03\x02\x02\x02\x1C\x81\x03\x02\x02\x02\x1E\x83\x03\x02" +
		'\x02\x02 \x88\x03\x02\x02\x02"\x93\x03\x02\x02\x02$\x95\x03\x02\x02\x02' +
		"&\x97\x03\x02\x02\x02(\x9B\x03\x02\x02\x02*\xA7\x03\x02\x02\x02,\xAC\x03" +
		"\x02\x02\x02.1\x05\x10\t\x02/1\x05\x04\x03\x020.\x03\x02\x02\x020/\x03" +
		"\x02\x02\x0212\x03\x02\x02\x0223\x07\x02\x02\x033\x03\x03\x02\x02\x02" +
		"45\x05\x06\x04\x025\x05\x03\x02\x02\x026;\x05\b\x05\x0278\x07\x03\x02" +
		"\x028:\x05\b\x05\x0297\x03\x02\x02\x02:=\x03\x02\x02\x02;9\x03\x02\x02" +
		"\x02;<\x03\x02\x02\x02<\x07\x03\x02\x02\x02=;\x03\x02\x02\x02>E\x05\x0E" +
		"\b\x02?A\x07\x04\x02\x02@?\x03\x02\x02\x02@A\x03\x02\x02\x02AB\x03\x02" +
		"\x02\x02BD\x05\x0E\b\x02C@\x03\x02\x02\x02DG\x03\x02\x02\x02EC\x03\x02" +
		"\x02\x02EF\x03\x02\x02\x02F\t\x03\x02\x02\x02GE\x03\x02\x02\x02HI\x07" +
		"\x05\x02\x02IJ\x05\x0E\b\x02J\v\x03\x02\x02\x02KL\x07\x13\x02\x02LM\x05" +
		"\x04\x03\x02MN\x07\x14\x02\x02N\r\x03\x02\x02\x02OW\x05$\x13\x02PW\x05" +
		"\x12\n\x02QW\x05\x14\v\x02RW\x05\x16\f\x02SW\x05\x18\r\x02TW\x05\x1A\x0E" +
		"\x02UW\x05\x1C\x0F\x02VO\x03\x02\x02\x02VP\x03\x02\x02\x02VQ\x03\x02\x02" +
		"\x02VR\x03\x02\x02\x02VS\x03\x02\x02\x02VT\x03\x02\x02\x02VU\x03\x02\x02" +
		"\x02W[\x03\x02\x02\x02X[\x05\n\x06\x02Y[\x05\f\x07\x02ZV\x03\x02\x02\x02" +
		"ZX\x03\x02\x02\x02ZY\x03\x02\x02\x02[\x0F\x03\x02\x02\x02\\]\x07\x12\x02" +
		'\x02]\x11\x03\x02\x02\x02^_\x05"\x12\x02_`\t\x02\x02\x02`a\x05&\x14\x02' +
		'a\x13\x03\x02\x02\x02bc\x05"\x12\x02cd\t\x03\x02\x02de\x05&\x14\x02e' +
		'\x15\x03\x02\x02\x02fh\x05"\x12\x02gi\x07\x05\x02\x02hg\x03\x02\x02\x02' +
		"hi\x03\x02\x02\x02ij\x03\x02\x02\x02jk\x07\b\x02\x02kl\x05 \x11\x02l\x17" +
		'\x03\x02\x02\x02mo\x05"\x12\x02np\x07\x05\x02\x02on\x03\x02\x02\x02o' +
		"p\x03\x02\x02\x02pq\x03\x02\x02\x02qr\x07\t\x02\x02rs\x05&\x14\x02st\x07" +
		'\x04\x02\x02tu\x05&\x14\x02u\x19\x03\x02\x02\x02vx\x05"\x12\x02wy\x07' +
		"\x05\x02\x02xw\x03\x02\x02\x02xy\x03\x02\x02\x02y\x7F\x03\x02\x02\x02" +
		"z{\t\x04\x02\x02{\x80\x05&\x14\x02|}\t\x04\x02\x02}~\x07\b\x02\x02~\x80" +
		"\x05 \x11\x02\x7Fz\x03\x02\x02\x02\x7F|\x03\x02\x02\x02\x80\x1B\x03\x02" +
		"\x02\x02\x81\x82\x05\x1E\x10\x02\x82\x1D\x03\x02\x02\x02\x83\x84\x07\n" +
		"\x02\x02\x84\x85\x07\x13\x02\x02\x85\x86\x05&\x14\x02\x86\x87\x07\x14" +
		"\x02\x02\x87\x1F\x03\x02\x02\x02\x88\x89\x07\x13\x02\x02\x89\x8E\x05&" +
		"\x14\x02\x8A\x8B\x07\x15\x02\x02\x8B\x8D\x05&\x14\x02\x8C\x8A\x03\x02" +
		"\x02\x02\x8D\x90\x03\x02\x02\x02\x8E\x8C\x03\x02\x02\x02\x8E\x8F\x03\x02" +
		"\x02\x02\x8F\x91\x03\x02\x02\x02\x90\x8E\x03\x02\x02\x02\x91\x92\x07\x14" +
		"\x02\x02\x92!\x03\x02\x02\x02\x93\x94\t\x05\x02\x02\x94#\x03\x02\x02\x02" +
		"\x95\x96\t\x05\x02\x02\x96%\x03\x02\x02\x02\x97\x98\t\x06\x02\x02\x98" +
		"'\x03\x02\x02\x02\x99\x9C\x05*\x16\x02\x9A\x9C\x05,\x17\x02\x9B\x99\x03" +
		"\x02\x02\x02\x9B\x9A\x03\x02\x02\x02\x9C\xA4\x03\x02\x02\x02\x9D\xA0\x07" +
		"\x15\x02\x02\x9E\xA1\x05*\x16\x02\x9F\xA1\x05,\x17\x02\xA0\x9E\x03\x02" +
		"\x02\x02\xA0\x9F\x03\x02\x02\x02\xA1\xA3\x03\x02\x02\x02\xA2\x9D\x03\x02" +
		"\x02\x02\xA3\xA6\x03\x02\x02\x02\xA4\xA2\x03\x02\x02\x02\xA4\xA5\x03\x02" +
		"\x02\x02\xA5)\x03\x02\x02\x02\xA6\xA4\x03\x02\x02\x02\xA7\xA8\x07\x1A" +
		'\x02\x02\xA8\xA9\x07\x13\x02\x02\xA9\xAA\x05"\x12\x02\xAA\xAB\x07\x14' +
		"\x02\x02\xAB+\x03\x02\x02\x02\xAC\xAD\x07\x1A\x02\x02\xAD\xAE\x07\x13" +
		"\x02\x02\xAE\xAF\x05*\x16\x02\xAF\xB0\x07\x15\x02\x02\xB0\xB1\x07\x1B" +
		"\x02\x02\xB1\xB2\x07\x14\x02\x02\xB2-\x03\x02\x02\x02\x100;@EVZhox\x7F" +
		"\x8E\x9B\xA0\xA4";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!ctqlParser.__ATN) {
			ctqlParser.__ATN = new ATNDeserializer().deserialize(
				Utils.toCharArray(ctqlParser._serializedATN)
			);
		}

		return ctqlParser.__ATN;
	}
}

export class ClauseContext extends ParserRuleContext {
	public EOF(): TerminalNode {
		return this.getToken(ctqlParser.EOF, 0);
	}
	public all_expr(): All_exprContext | undefined {
		return this.tryGetRuleContext(0, All_exprContext);
	}
	public logicalExpr(): LogicalExprContext | undefined {
		return this.tryGetRuleContext(0, LogicalExprContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_clause;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterClause) {
			listener.enterClause(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitClause) {
			listener.exitClause(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitClause) {
			return visitor.visitClause(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class LogicalExprContext extends ParserRuleContext {
	public or_expr(): Or_exprContext {
		return this.getRuleContext(0, Or_exprContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_logicalExpr;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterLogicalExpr) {
			listener.enterLogicalExpr(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitLogicalExpr) {
			listener.exitLogicalExpr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitLogicalExpr) {
			return visitor.visitLogicalExpr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class Or_exprContext extends ParserRuleContext {
	public and_expr(): And_exprContext[];
	public and_expr(i: number): And_exprContext;
	public and_expr(i?: number): And_exprContext | And_exprContext[] {
		if (i === undefined) {
			return this.getRuleContexts(And_exprContext);
		} else {
			return this.getRuleContext(i, And_exprContext);
		}
	}
	public OR(): TerminalNode[];
	public OR(i: number): TerminalNode;
	public OR(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ctqlParser.OR);
		} else {
			return this.getToken(ctqlParser.OR, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_or_expr;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterOr_expr) {
			listener.enterOr_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitOr_expr) {
			listener.exitOr_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitOr_expr) {
			return visitor.visitOr_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class And_exprContext extends ParserRuleContext {
	public expr(): ExprContext[];
	public expr(i: number): ExprContext;
	public expr(i?: number): ExprContext | ExprContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExprContext);
		} else {
			return this.getRuleContext(i, ExprContext);
		}
	}
	public AND(): TerminalNode[];
	public AND(i: number): TerminalNode;
	public AND(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ctqlParser.AND);
		} else {
			return this.getToken(ctqlParser.AND, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_and_expr;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterAnd_expr) {
			listener.enterAnd_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitAnd_expr) {
			listener.exitAnd_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitAnd_expr) {
			return visitor.visitAnd_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class Not_exprContext extends ParserRuleContext {
	public NOT(): TerminalNode {
		return this.getToken(ctqlParser.NOT, 0);
	}
	public expr(): ExprContext {
		return this.getRuleContext(0, ExprContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_not_expr;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterNot_expr) {
			listener.enterNot_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitNot_expr) {
			listener.exitNot_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitNot_expr) {
			return visitor.visitNot_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class Sub_exprContext extends ParserRuleContext {
	public LP(): TerminalNode {
		return this.getToken(ctqlParser.LP, 0);
	}
	public logicalExpr(): LogicalExprContext {
		return this.getRuleContext(0, LogicalExprContext);
	}
	public RP(): TerminalNode {
		return this.getToken(ctqlParser.RP, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_sub_expr;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterSub_expr) {
			listener.enterSub_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitSub_expr) {
			listener.exitSub_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitSub_expr) {
			return visitor.visitSub_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class ExprContext extends ParserRuleContext {
	public keywordExpression(): KeywordExpressionContext | undefined {
		return this.tryGetRuleContext(0, KeywordExpressionContext);
	}
	public equalityExpression(): EqualityExpressionContext | undefined {
		return this.tryGetRuleContext(0, EqualityExpressionContext);
	}
	public numericCompareExpression():
		| NumericCompareExpressionContext
		| undefined {
		return this.tryGetRuleContext(0, NumericCompareExpressionContext);
	}
	public inExpression(): InExpressionContext | undefined {
		return this.tryGetRuleContext(0, InExpressionContext);
	}
	public betweenExpression(): BetweenExpressionContext | undefined {
		return this.tryGetRuleContext(0, BetweenExpressionContext);
	}
	public likeExpression(): LikeExpressionContext | undefined {
		return this.tryGetRuleContext(0, LikeExpressionContext);
	}
	public boolFunction(): BoolFunctionContext | undefined {
		return this.tryGetRuleContext(0, BoolFunctionContext);
	}
	public not_expr(): Not_exprContext | undefined {
		return this.tryGetRuleContext(0, Not_exprContext);
	}
	public sub_expr(): Sub_exprContext | undefined {
		return this.tryGetRuleContext(0, Sub_exprContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_expr;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterExpr) {
			listener.enterExpr(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitExpr) {
			listener.exitExpr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitExpr) {
			return visitor.visitExpr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class All_exprContext extends ParserRuleContext {
	public ASTERISK(): TerminalNode {
		return this.getToken(ctqlParser.ASTERISK, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_all_expr;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterAll_expr) {
			listener.enterAll_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitAll_expr) {
			listener.exitAll_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitAll_expr) {
			return visitor.visitAll_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class EqualityExpressionContext extends ParserRuleContext {
	public field(): FieldContext {
		return this.getRuleContext(0, FieldContext);
	}
	public value(): ValueContext {
		return this.getRuleContext(0, ValueContext);
	}
	public EQ(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.EQ, 0);
	}
	public NEQ(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.NEQ, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_equalityExpression;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterEqualityExpression) {
			listener.enterEqualityExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitEqualityExpression) {
			listener.exitEqualityExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitEqualityExpression) {
			return visitor.visitEqualityExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class NumericCompareExpressionContext extends ParserRuleContext {
	public field(): FieldContext {
		return this.getRuleContext(0, FieldContext);
	}
	public value(): ValueContext {
		return this.getRuleContext(0, ValueContext);
	}
	public LT(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.LT, 0);
	}
	public LTE(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.LTE, 0);
	}
	public GT(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.GT, 0);
	}
	public GTE(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.GTE, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_numericCompareExpression;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterNumericCompareExpression) {
			listener.enterNumericCompareExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitNumericCompareExpression) {
			listener.exitNumericCompareExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitNumericCompareExpression) {
			return visitor.visitNumericCompareExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class InExpressionContext extends ParserRuleContext {
	public field(): FieldContext {
		return this.getRuleContext(0, FieldContext);
	}
	public IN(): TerminalNode {
		return this.getToken(ctqlParser.IN, 0);
	}
	public valueList(): ValueListContext {
		return this.getRuleContext(0, ValueListContext);
	}
	public NOT(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.NOT, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_inExpression;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterInExpression) {
			listener.enterInExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitInExpression) {
			listener.exitInExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitInExpression) {
			return visitor.visitInExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class BetweenExpressionContext extends ParserRuleContext {
	public field(): FieldContext {
		return this.getRuleContext(0, FieldContext);
	}
	public BETWEEN(): TerminalNode {
		return this.getToken(ctqlParser.BETWEEN, 0);
	}
	public value(): ValueContext[];
	public value(i: number): ValueContext;
	public value(i?: number): ValueContext | ValueContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ValueContext);
		} else {
			return this.getRuleContext(i, ValueContext);
		}
	}
	public AND(): TerminalNode {
		return this.getToken(ctqlParser.AND, 0);
	}
	public NOT(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.NOT, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_betweenExpression;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterBetweenExpression) {
			listener.enterBetweenExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitBetweenExpression) {
			listener.exitBetweenExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitBetweenExpression) {
			return visitor.visitBetweenExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class LikeExpressionContext extends ParserRuleContext {
	public field(): FieldContext {
		return this.getRuleContext(0, FieldContext);
	}
	public value(): ValueContext | undefined {
		return this.tryGetRuleContext(0, ValueContext);
	}
	public IN(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.IN, 0);
	}
	public valueList(): ValueListContext | undefined {
		return this.tryGetRuleContext(0, ValueListContext);
	}
	public NOT(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.NOT, 0);
	}
	public LIKE(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.LIKE, 0);
	}
	public ILIKE(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.ILIKE, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_likeExpression;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterLikeExpression) {
			listener.enterLikeExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitLikeExpression) {
			listener.exitLikeExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitLikeExpression) {
			return visitor.visitLikeExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class BoolFunctionContext extends ParserRuleContext {
	public cidrMatch(): CidrMatchContext {
		return this.getRuleContext(0, CidrMatchContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_boolFunction;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterBoolFunction) {
			listener.enterBoolFunction(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitBoolFunction) {
			listener.exitBoolFunction(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitBoolFunction) {
			return visitor.visitBoolFunction(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class CidrMatchContext extends ParserRuleContext {
	public CIDRMATCH(): TerminalNode {
		return this.getToken(ctqlParser.CIDRMATCH, 0);
	}
	public LP(): TerminalNode {
		return this.getToken(ctqlParser.LP, 0);
	}
	public value(): ValueContext {
		return this.getRuleContext(0, ValueContext);
	}
	public RP(): TerminalNode {
		return this.getToken(ctqlParser.RP, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_cidrMatch;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterCidrMatch) {
			listener.enterCidrMatch(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitCidrMatch) {
			listener.exitCidrMatch(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitCidrMatch) {
			return visitor.visitCidrMatch(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class ValueListContext extends ParserRuleContext {
	public LP(): TerminalNode {
		return this.getToken(ctqlParser.LP, 0);
	}
	public value(): ValueContext[];
	public value(i: number): ValueContext;
	public value(i?: number): ValueContext | ValueContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ValueContext);
		} else {
			return this.getRuleContext(i, ValueContext);
		}
	}
	public RP(): TerminalNode {
		return this.getToken(ctqlParser.RP, 0);
	}
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ctqlParser.COMMA);
		} else {
			return this.getToken(ctqlParser.COMMA, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_valueList;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterValueList) {
			listener.enterValueList(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitValueList) {
			listener.exitValueList(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitValueList) {
			return visitor.visitValueList(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class FieldContext extends ParserRuleContext {
	public UNQUOTED_LITERAL(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.UNQUOTED_LITERAL, 0);
	}
	public SINGLE_QUOTED_LITERAL(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.SINGLE_QUOTED_LITERAL, 0);
	}
	public DOUBLE_QUOTED_LITERAL(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.DOUBLE_QUOTED_LITERAL, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_field;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterField) {
			listener.enterField(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitField) {
			listener.exitField(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitField) {
			return visitor.visitField(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class KeywordExpressionContext extends ParserRuleContext {
	public UNQUOTED_LITERAL(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.UNQUOTED_LITERAL, 0);
	}
	public SINGLE_QUOTED_LITERAL(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.SINGLE_QUOTED_LITERAL, 0);
	}
	public DOUBLE_QUOTED_LITERAL(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.DOUBLE_QUOTED_LITERAL, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_keywordExpression;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterKeywordExpression) {
			listener.enterKeywordExpression(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitKeywordExpression) {
			listener.exitKeywordExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitKeywordExpression) {
			return visitor.visitKeywordExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class ValueContext extends ParserRuleContext {
	public NULL(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.NULL, 0);
	}
	public SINGLE_QUOTED_LITERAL(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.SINGLE_QUOTED_LITERAL, 0);
	}
	public DOUBLE_QUOTED_LITERAL(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.DOUBLE_QUOTED_LITERAL, 0);
	}
	public NUMERIC_LITERAL(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.NUMERIC_LITERAL, 0);
	}
	public UNQUOTED_LITERAL(): TerminalNode | undefined {
		return this.tryGetToken(ctqlParser.UNQUOTED_LITERAL, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_value;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterValue) {
			listener.enterValue(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitValue) {
			listener.exitValue(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitValue) {
			return visitor.visitValue(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class StatListContext extends ParserRuleContext {
	public statFunc(): StatFuncContext[];
	public statFunc(i: number): StatFuncContext;
	public statFunc(i?: number): StatFuncContext | StatFuncContext[] {
		if (i === undefined) {
			return this.getRuleContexts(StatFuncContext);
		} else {
			return this.getRuleContext(i, StatFuncContext);
		}
	}
	public nestedFunc(): NestedFuncContext[];
	public nestedFunc(i: number): NestedFuncContext;
	public nestedFunc(i?: number): NestedFuncContext | NestedFuncContext[] {
		if (i === undefined) {
			return this.getRuleContexts(NestedFuncContext);
		} else {
			return this.getRuleContext(i, NestedFuncContext);
		}
	}
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ctqlParser.COMMA);
		} else {
			return this.getToken(ctqlParser.COMMA, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_statList;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterStatList) {
			listener.enterStatList(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitStatList) {
			listener.exitStatList(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitStatList) {
			return visitor.visitStatList(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class StatFuncContext extends ParserRuleContext {
	public UNQUOTED_LITERAL(): TerminalNode {
		return this.getToken(ctqlParser.UNQUOTED_LITERAL, 0);
	}
	public LP(): TerminalNode {
		return this.getToken(ctqlParser.LP, 0);
	}
	public field(): FieldContext {
		return this.getRuleContext(0, FieldContext);
	}
	public RP(): TerminalNode {
		return this.getToken(ctqlParser.RP, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_statFunc;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterStatFunc) {
			listener.enterStatFunc(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitStatFunc) {
			listener.exitStatFunc(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitStatFunc) {
			return visitor.visitStatFunc(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class NestedFuncContext extends ParserRuleContext {
	public UNQUOTED_LITERAL(): TerminalNode {
		return this.getToken(ctqlParser.UNQUOTED_LITERAL, 0);
	}
	public LP(): TerminalNode {
		return this.getToken(ctqlParser.LP, 0);
	}
	public statFunc(): StatFuncContext {
		return this.getRuleContext(0, StatFuncContext);
	}
	public COMMA(): TerminalNode {
		return this.getToken(ctqlParser.COMMA, 0);
	}
	public NUMERIC_LITERAL(): TerminalNode {
		return this.getToken(ctqlParser.NUMERIC_LITERAL, 0);
	}
	public RP(): TerminalNode {
		return this.getToken(ctqlParser.RP, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return ctqlParser.RULE_nestedFunc;
	}
	// @Override
	public enterRule(listener: ctqlListener): void {
		if (listener.enterNestedFunc) {
			listener.enterNestedFunc(this);
		}
	}
	// @Override
	public exitRule(listener: ctqlListener): void {
		if (listener.exitNestedFunc) {
			listener.exitNestedFunc(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ctqlVisitor<Result>): Result {
		if (visitor.visitNestedFunc) {
			return visitor.visitNestedFunc(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

import { makeStyles } from "@material-ui/core/styles";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
	Button,
	CircularProgress,
	IconButton,
	Link,
	Menu,
	MenuItem,
	Snackbar,
	Stack,
	Tooltip,
	useTheme,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { grey } from "@mui/material/colors";
import { MenuItemOption } from "common/molecules/more-options-menu/types";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { useSnackbarStore } from "modules/snackbar/store";
import {
	SnackBarPosition,
	SnackBarSeverity,
} from "modules/snackbar/store/types";
import numeral from "numeral";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { MAX_DATA_LIMIT } from "./constants";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
	function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	}
);

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		"& > * + *": {
			marginTop: theme.spacing(2),
		},
	},
}));

const formatter = "0.[0]a";

export function ExportCSV<DataType>(props: DataGridProps<DataType>) {
	const {
		rowCount,
		exportRowCount,
		checkboxSelection,
		selectedRawData,
		triggerExportAsCsv,
		getExportStatus,
		getUrlToDownload,
		resetDownloadUrl,
	} = props;
	const theme = useTheme();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const classes = useStyles();

	const id = useMemo(() => {
		return Date.now() + "";
	}, []);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const buttonRef = useRef<any>(null);
	const popupSeverity: SnackBarSeverity = SnackBarSeverity.Info;
	const popupMessage =
		"Your download should begin automatically, if not click download";

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		if (props.frontendOnly) {
			handleExportRows(true);
			return;
		}

		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const getExportAllRowsLabelCount = () => {
		if (rowCount > MAX_DATA_LIMIT) {
			return numeral(MAX_DATA_LIMIT).format(formatter);
		}

		if (exportRowCount && exportRowCount > rowCount) {
			return `${numeral(rowCount).format(formatter)}+`;
		}

		return numeral(rowCount).format(formatter);
	};

	const menuOptions: Array<MenuItemOption> = [
		{
			label: `Export all rows (${getExportAllRowsLabelCount()})`,
			handler: () => handleExportRows(),
			helpTooltipMessage:
				rowCount > MAX_DATA_LIMIT
					? `Export data as CSV (upto ${numeral(MAX_DATA_LIMIT).format(
							formatter
						)} rows)`
					: "",
		},
	];

	if (checkboxSelection) {
		menuOptions.push({
			label: `Export selected rows ${
				selectedRawData && selectedRawData?.length > 0
					? `(${selectedRawData?.length})`
					: ""
			}`,
			handler: () => handleExportRows(true),
			disabled: !selectedRawData || selectedRawData?.length === 0,
			tooltipMessage: "Please select rows to enable this option",
		});
	}

	const handleExportRows = (selected: boolean = false) => {
		if (triggerExportAsCsv) {
			triggerExportAsCsv(selected ? selectedRawData : undefined);
		}
	};

	const triggeredDownload = () => {
		setSnackbar(true, SnackBarSeverity.Success, "CSVDownloadedSuccessfully");
		cancelDownload();
	};

	const cancelDownload = () => {
		if (resetDownloadUrl) {
			resetDownloadUrl();
		}
	};

	useEffect(() => {
		const autotriggeredDownload = () => {
			if (buttonRef.current) {
				try {
					buttonRef.current.click();
				} catch (error) {
					console.log(error);
				}
			}
		};
		if (buttonRef && getUrlToDownload && Boolean(getUrlToDownload())) {
			autotriggeredDownload();
		}
	}, [buttonRef, getUrlToDownload, resetDownloadUrl]);

	const action = (
		<>
			{getUrlToDownload && Boolean(getUrlToDownload()) && (
				<Link
					underline="none"
					component={"a"}
					href={getUrlToDownload() ?? ""}
					download={`data.csv`}
					sx={{
						color: theme.palette.common.white,
					}}
					ref={buttonRef}
					onClick={triggeredDownload}
				>
					<Button
						variant="outlined"
						sx={{
							color: theme.palette.common.white,
							borderColor: theme.palette.common.white,
						}}
					>
						Download
					</Button>
				</Link>
			)}
		</>
	);

	return (
		<Stack
			sx={{
				backgroundColor:
					theme.palette.mode === "dark"
						? theme.palette.background.paper
						: grey[100],
				backgroundImage:
					theme.palette.mode === "light"
						? "unset"
						: `linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))`,
				position: "absolute",
				right: "1px",
				top: "1px",
				zIndex: 1,
				width: "49px",
				height: "55px",
			}}
		>
			{rowCount > 0 ? (
				<Stack
					sx={{
						position: "absolute",
						right: "8px",
						top: "10px",
						zIndex: 1,
					}}
				>
					<Tooltip
						title={
							getExportStatus && getExportStatus()
								? `CSV export is in progress`
								: `Export data as CSV ${
										rowCount > MAX_DATA_LIMIT
											? `(upto ${numeral(MAX_DATA_LIMIT).format(
													formatter
												)} rows)`
											: ""
									}`
						}
					>
						<span>
							<IconButton
								size="medium"
								onClick={handleClick}
								disabled={getExportStatus && getExportStatus()}
							>
								{getExportStatus && getExportStatus() ? (
									<CircularProgress
										size="20"
										sx={{ width: "20px", height: "20px" }}
									/>
								) : (
									<SaveAltIcon
										fontSize="small"
										sx={{ color: theme.palette.primary.main }}
									/>
								)}
							</IconButton>
						</span>
					</Tooltip>
					{!props.frontendOnly && (
						<Menu
							id={`${id}-export-menu`}
							aria-labelledby={`${id}-export-button`}
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							transformOrigin={{ horizontal: "right", vertical: "top" }}
							anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
						>
							{menuOptions.map((option, index) => {
								return (
									<Tooltip
										title={
											option?.disabled && option?.tooltipMessage
												? option?.tooltipMessage
												: option?.helpTooltipMessage
													? option?.helpTooltipMessage
													: ""
										}
										key={`${id}-export-menu-item-${index}`}
									>
										<span>
											<MenuItem
												onClick={() => {
													handleClose();
													option.handler();
												}}
												disabled={option?.disabled}
											>
												{option?.label}
											</MenuItem>
										</span>
									</Tooltip>
								);
							})}
						</Menu>
					)}

					{getUrlToDownload && Boolean(getUrlToDownload()) && (
						<div className={classes.root}>
							<Snackbar
								open={getUrlToDownload && Boolean(getUrlToDownload())}
								onClose={cancelDownload}
								anchorOrigin={{
									vertical: SnackBarPosition.Top,
									horizontal: SnackBarPosition.Center,
								}}
							>
								<Alert
									onClose={cancelDownload}
									severity={popupSeverity}
									sx={{ width: "100%", color: theme.palette.common.white }}
									action={action}
								>
									<Stack
										sx={{ width: "100%" }}
										spacing={4}
										direction="row"
										alignItems="center"
									>
										<Stack alignItems="center">{popupMessage}</Stack>
									</Stack>
								</Alert>
							</Snackbar>
						</div>
					)}
				</Stack>
			) : null}
		</Stack>
	);
}

import { MoreOptionsMenu } from "common/molecules/more-options-menu/MoreOptionsMenu";
import { MenuOption } from "common/molecules/more-options-menu/types";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { AssignNetworksDrawer } from "modules/assign-networks-to-assets/components/assign-networks-drawer";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useUserGroupsStatusAPI } from "pages/users-segmentation/components/user-groups/hooks/useUserGroupApi";
import { useUserGroupStore } from "pages/users-segmentation/components/user-groups/store";
import {
	UserGroup,
	HostAndUserGroupStatusState,
} from "pages/users-segmentation/components/user-groups/types";
import { useState } from "react";

export function UserGroupActions({
	userGroup,
	onSelectCallback,
}: {
	readonly userGroup: UserGroup;
	readonly onSelectCallback?: Function;
}) {
	const moreActionMenuOptions: Array<MenuOption> = [];
	const requestAPIRefresh = useUserGroupStore(store => store.requestAPIRefresh);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const userGroupsStatusAPI = useUserGroupsStatusAPI();

	const [showAddToTemplateDialog, setShowAddToTemplateDialog] = useState(false);
	const [showAddToNamedNetworkDialog, setShowAddToNamedNetworkDialog] =
		useState(false);
	const addToTemplate = () => {
		setShowAddToTemplateDialog(false);
		requestAPIRefresh();
	};

	const handleClose = () => {
		setShowAddToTemplateDialog(false);
	};
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const criteria = `groupId = '${userGroup.groupID}'`;

	const hasPermission =
		userPermissions.has("UPDATE_ASSET") &&
		userPermissions.has("UPDATE_TEMPLATE") &&
		userPermissions.has("UPDATE_NAMED_NETWORK");

	const handleStatusAction = (statusType: boolean) => {
		const body = {
			criteria: `groupid in ('${userGroup.groupID}')`,
			toState: statusType ? "active" : "inactive",
		};
		userGroupsStatusAPI.mutateAsync(body, {
			onSuccess: response => {
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"UserGroupStatusSetToStatus",
					{
						status: window.getCTTranslatedText(
							statusType ? "active" : "inactive"
						),
					}
				);
				requestAPIRefresh();
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	moreActionMenuOptions.push(
		{
			label: "Assign Template",
			handler: () => {
				setShowAddToTemplateDialog(true);
			},
		},
		{
			label: "Assign Named Networks",
			handler: () => {
				setShowAddToNamedNetworkDialog(true);
			},
		}
	);

	if (userGroup?.userGroupStatus === HostAndUserGroupStatusState.Active) {
		moreActionMenuOptions.push({
			label: "Inactive",
			handler: () => {
				handleStatusAction(false);
			},
		});
	} else {
		moreActionMenuOptions.push({
			label: "Active",
			handler: () => {
				handleStatusAction(true);
			},
		});
	}
	const closeAssignNetworksDrawer = () => {
		setShowAddToNamedNetworkDialog(false);
	};
	const handleAssignNamedNetworkConfirm = () => {
		setShowAddToNamedNetworkDialog(false);
		requestAPIRefresh();
	};

	return (
		<>
			{(userGroup?.userGroupStatus === HostAndUserGroupStatusState.Active ||
				userGroup?.userGroupStatus ===
					HostAndUserGroupStatusState.InActive) && (
				<MoreOptionsMenu menuOptions={moreActionMenuOptions} />
			)}
			{showAddToTemplateDialog && (
				<AddToTemplateDrawer
					isOpen={showAddToTemplateDialog}
					page={"assets"}
					title="Assign Template"
					rules={[]}
					onCancel={handleClose}
					onConfirm={addToTemplate}
					btnTitle={"assign"}
					actionType={TemplateActionType.assign}
					criteria={criteria}
					multiselect={true}
					onSelectCallback={onSelectCallback}
					hasPermission={hasPermission}
				/>
			)}
			{showAddToNamedNetworkDialog && (
				<AssignNetworksDrawer
					isOpen={showAddToNamedNetworkDialog}
					page={"assets"}
					title="Assign Named Network"
					criteria={criteria}
					rules={[]}
					onCancel={closeAssignNetworksDrawer}
					onConfirm={handleAssignNamedNetworkConfirm}
					btnTitle={"assign"}
					multiselect={true}
					onSelectCallback={onSelectCallback}
					hasPermission={hasPermission}
				/>
			)}
		</>
	);
}

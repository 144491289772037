import { SecurityStatus } from "pages/asset/components/asset-detail/constants";
import { SecureAllIcon, SecureIcon, UnsecuredIcon } from "assets/svgs";

export const AssetStatusIcon = ({
	status,
	color,
	x = 0,
	y = 0,
}: {
	status: SecurityStatus;
	color: string;
	x?: number;
	y?: number;
}) => {
	let icon = <></>;

	switch (status) {
		case SecurityStatus.Unsecure:
			icon = <UnsecuredIcon color={color} x={x} y={y} />;
			break;
		case SecurityStatus.Internet:
			icon = <SecureIcon color={color} x={x} y={y} />;
			break;
		case SecurityStatus.All:
			icon = <SecureAllIcon color={color} x={x} y={y} />;
			break;
	}

	return <>{icon}</>;
};

import { Box, Stack, Grid, Link, Tooltip, Typography } from "@mui/material";
import numeral from "numeral";
import { AssetColumnRenderProps } from "pages/assets/types";
import { Link as RouterLink } from "react-router-dom";

export function AssetPaths({ asset, viewOnly }: AssetColumnRenderProps) {
	if (!asset.assetId) {
		return <Box style={{ width: 70 }} />;
	}
	return (
		<Stack
			sx={{ maxWidth: "100%" }}
			alignItems="center"
			justifyContent="center"
		>
			<Grid
				container
				direction="row"
				justifyContent="start"
				alignItems="center"
			>
				<Tooltip title={!viewOnly ? "View unreviewed paths" : ""}>
					{viewOnly ? (
						<Typography variant="body2" sx={{ color: "warning.main" }}>
							{numeral(asset?.unreviewedPaths || 0).format("0a")}
						</Typography>
					) : (
						<Link
							typography={"body2"}
							component={RouterLink}
							to={`/paths?filters=${encodeURIComponent(
								`reviewed=unreviewed|assetname=${asset.assetName}`
							)}`} // TODO Path status null comparison
							sx={{ color: "warning.main" }}
						>
							{numeral(asset?.unreviewedPaths || 0).format("0a")}
						</Link>
					)}
				</Tooltip>

				<Stack style={{ margin: "0 4px" }}>/</Stack>
				<Tooltip title={!viewOnly ? "View all paths" : ""}>
					{viewOnly ? (
						<Typography variant="body2">
							{numeral(asset?.totalPaths || 0).format("0a")}
						</Typography>
					) : (
						<Link
							typography={"body2"}
							component={RouterLink}
							to={`/paths?filters=${encodeURIComponent(
								`assetname=${asset.assetName}`
							)}`}
							sx={{ color: "primary.main" }}
						>
							{numeral(asset?.totalPaths || 0).format("0a")}
						</Link>
					)}
				</Tooltip>
			</Grid>
		</Stack>
	);
}

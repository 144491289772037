import {
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { AlertCount } from "../alert-count";
import { AlertRulename } from "../alert-rulename";
import { AlertSeverity } from "../alert-severity";
import { AlertStatus } from "../alert-status";
import { AlertTime } from "../alert-time";
import { AlertType } from "../alert-type/AlertType";
import { AlertsAction } from "../alerts-action";
import { AuditMessage } from "../audit-message";
import { AuditAsset } from "../audit-asset";

export const BASE_ALERT_COLUMNS: GridColDef[] = [
	{
		field: "asset",
		headerName: "Asset",
		width: 200,
		minWidth: 120,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AuditAsset audit={params.row} />;
		},
	},
	{
		field: "alertseverity",
		headerName: "Severity",
		sortingOrder: ["asc", "desc"],
		width: 120,
		minWidth: 120,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<AlertSeverity alert={params.row} severity={params.row.severity} />
			);
		},
	},
	{
		field: "alerttype",
		headerName: "Category",
		sortingOrder: ["asc", "desc"],
		width: 140,
		minWidth: 140,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AlertType alert={params?.row} />;
		},
	},
	{
		field: "alertRuleName",
		headerName: "Alert Name",
		sortable: false,
		width: 240,
		minWidth: 140,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AlertRulename alert={params?.row} />;
		},
	},
	{
		field: "message",
		headerName: "Description",
		minWidth: 320,
		flex: 1,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AuditMessage audit={params.row} />;
		},
	},
	{
		field: "alertcount",
		headerName: "Occurrences",
		width: 140,
		minWidth: 140,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AlertCount alert={params?.row} />;
		},
	},
	{
		field: "alertlastseen",
		headerName: "Last Seen",
		width: 240,
		minWidth: 240,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AlertTime alert={params.row} lastSeen={true} />;
		},
	},
	{
		field: "alertstatus",
		headerName: "Status",
		sortingOrder: ["asc", "desc"],
		width: 120,
		minWidth: 120,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AlertStatus alert={params?.row} />;
		},
	},
	{
		field: "action",
		headerName: "",
		headerAlign: "left",
		align: "left",
		width: 50,
		minWidth: 40,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AlertsAction record={params.row} />;
		},
	},
];

export const ALERT_PINNED_COLUMNS = {
	left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "asset", "alertseverity"],
	right: ["action"],
};

import isEqual from "lodash/isEqual";
import { WorkRequestStatus } from "modules/AppBar/types";
import { useWorkRequestFacetStore } from "modules/PendingWorkRequestsDrawer/store/useWorkRequestStore";
import { mergeFacetStates } from "modules/facets/FacetUtils";
import { FacetOptionState, Operator } from "modules/facets/types";
import { useEffect, useRef } from "react";

interface UseHandleWRFacetApplicationOptions {
	resourceID?: string;
}

export const useHandleWRFacetApplication = ({
	resourceID,
}: UseHandleWRFacetApplicationOptions) => {
	const updateFacetOption = useWorkRequestFacetStore(
		state => state.updateFacetOption
	);
	const facets = useWorkRequestFacetStore(state => state.facets);
	const setFacets = useWorkRequestFacetStore(state => state.setFacets);

	const initialFacetsRef = useRef(facets);

	useEffect(() => {
		const facetOption: FacetOptionState = new Map([
			[
				WorkRequestStatus.Pending,
				{ isSelected: true, operator: Operator.EQUAL },
			],
			[
				WorkRequestStatus.InProgress,
				{ isSelected: true, operator: Operator.EQUAL },
			],
			[
				WorkRequestStatus.Cancelled,
				{ isSelected: true, operator: Operator.EQUAL },
			],
		]);
		const facets = new Map([["status", facetOption]]);

		setFacets(facets);
		initialFacetsRef.current = facets;

		return () => {
			setFacets(undefined);
			initialFacetsRef.current = undefined;
		};
	}, [setFacets]);

	useEffect(() => {
		if (resourceID) {
			updateFacetOption({
				facetName: "workrequestresourceid",
				optionName: resourceID,
				value: true,
			});
			const facetOption: FacetOptionState = new Map([
				[resourceID, { isSelected: true, operator: Operator.EQUAL }],
			]);
			const facets = new Map([["workrequestresourceid", facetOption]]);
			initialFacetsRef.current = mergeFacetStates(
				initialFacetsRef.current,
				facets
			);

			return () => {
				updateFacetOption({
					facetName: "workrequestresourceid",
					optionName: resourceID,
					value: false,
				});
				initialFacetsRef.current?.delete("workrequestresourceid");
			};
		}
	}, [resourceID, updateFacetOption]);

	return isEqual(facets, initialFacetsRef.current);
};

export function getStatus(statusText: string) {
	const regexB = /\((.*?)\)/;
	const matchB = regexB.exec(statusText);
	const description = matchB ? matchB[1] : "";
	const regexA = /(\w.+)\s\(/;
	const matchA = regexA.exec(statusText);
	const status = matchA ? matchA[1] : statusText;
	return {
		description,
		status,
	};
}

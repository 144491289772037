import { Box, Button, Stack, Typography } from "@mui/material";
import { AnnotationDrawer } from "modules/annotation-drawer";
import { EntityType } from "modules/annotation-drawer/type";
import { AssetMetaDataReadonlyProps } from "pages/assets/types";
import { useState } from "react";
import { useAssetStore } from "pages/assets/store";
import numeral from "numeral";

export function CommentsStatus({ asset }: AssetMetaDataReadonlyProps) {
	const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState(false);

	if (!asset?.assetId) {
		return <Box style={{ width: 42 }} />;
	}

	const count = numeral(asset?.totalComments ?? 0).format("0,0");

	return (
		<>
			<Stack
				direction={"row"}
				spacing={3}
				alignItems="center"
				justifyContent="flex-start"
				sx={{ width: "100%" }}
			>
				<Typography variant="subtitle1">
					<b>{window.getCTTranslatedText("Comments")}</b>
				</Typography>
				<Stack
					sx={{ mr: 1, flex: 1 }}
					direction="row"
					spacing={1}
					alignItems="center"
				>
					<Typography
						variant="button"
						sx={{
							color: theme =>
								count === "0" ? theme.palette.text.disabled : undefined,
						}}
					>
						{count}
					</Typography>
				</Stack>

				<Button onClick={() => setIsCommentDrawerOpen(true)} variant="outlined">
					{window.getCTTranslatedText("Add Comment")}
				</Button>
			</Stack>

			{isCommentDrawerOpen && (
				<AnnotationDrawer
					isOpen={isCommentDrawerOpen}
					onClose={() => setIsCommentDrawerOpen(false)}
					entity={EntityType.Assets}
					entityId={asset?.assetId ?? ""}
					useStore={useAssetStore}
					queryKey="asset"
				/>
			)}
		</>
	);
}

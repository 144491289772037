import { Path, PathDirection, PathRule } from "pages/paths/types";

export function PathRulesBody({
	rules,
	pathDirection,
}: {
	rules: Array<Path>;
	pathDirection: PathDirection;
}) {
	const PathRules: Array<Path> = rules as Array<Path>;
	const pathRules: Array<PathRule> | undefined = PathRules?.map(
		(rule: Path) => {
			const currentPathDirection: PathDirection =
				rule.direction as PathDirection;
			const overideDirection: boolean =
				rule?.sourceAsset?.assetId && rule?.destinationAsset?.assetId
					? true
					: false;
			const newPathDirection: PathDirection = overideDirection
				? pathDirection
				: currentPathDirection;

			const obj: PathRule = {
				port: rule.port,
				protocol: rule.protocol,
				portName: rule.portName,
				direction: newPathDirection,
				method: rule.method,
				uri: rule.uri,
			};

			if (newPathDirection === PathDirection.Outbound) {
				if (rule?.destinationAsset?.assetId && !overideDirection) {
					obj["srcIp"] = rule.srcIp;
					obj["direction"] = PathDirection.Inbound;
					if (rule?.sourceNamedNetwork) {
						obj["sourceNamedNetwork"] = rule.sourceNamedNetwork;
					}
					if (rule?.sourceTagBasedPolicy) {
						obj["sourceTagBasedPolicy"] = rule.sourceTagBasedPolicy;
					}
				} else {
					obj["dstIp"] = rule.dstIp;
					obj["domain"] = rule.domain;
					obj["destinationAssetId"] = rule?.destinationAsset?.assetId;
					if (rule?.destinationNamedNetwork) {
						obj["destinationNamedNetwork"] = rule.destinationNamedNetwork;
					}
					if (rule?.destinationTagBasedPolicy) {
						obj["destinationTagBasedPolicy"] = rule.destinationTagBasedPolicy;
					}
				}
			} else {
				if (rule?.sourceAsset?.assetId && !overideDirection) {
					obj["dstIp"] = rule.dstIp;
					obj["domain"] = rule.domain;
					obj["direction"] = PathDirection.Outbound;
					if (rule?.destinationNamedNetwork) {
						obj["destinationNamedNetwork"] = rule.destinationNamedNetwork;
					}
					if (rule?.destinationTagBasedPolicy) {
						obj["destinationTagBasedPolicy"] = rule.destinationTagBasedPolicy;
					}
				} else {
					obj["srcIp"] = rule.srcIp;
					obj["sourceAssetId"] = rule?.sourceAsset?.assetId;
					if (rule?.sourceNamedNetwork) {
						obj["sourceNamedNetwork"] = rule.sourceNamedNetwork;
					}
					if (rule?.sourceTagBasedPolicy) {
						obj["sourceTagBasedPolicy"] = rule.sourceTagBasedPolicy;
					}
				}
			}

			return obj;
		}
	);
	return pathRules;
}

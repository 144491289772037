import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useTemplateStore } from "pages/templates/store";

export interface TemplateToolbarProps
	extends Omit<ToolbarProps, "children" | "hideToolbar"> {
	hideToolbar: () => void;
}

export function TemplateToolBar(props: TemplateToolbarProps) {
	const requestAPIRefresh = useTemplateStore(store => store.requestAPIRefresh);

	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}></GridToolbar>
	);
}

import { InfoOutlined } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
	Button,
	Checkbox,
	DialogActions,
	DialogContent,
	FormControlLabel,
	FormGroup,
	IconButton,
	Paper,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { parseErrorMessage } from "common/utils";
import { index } from "d3-array";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSearchStore } from "modules/search/store";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	INTEGRATIONS,
	INTEGRATION_PROVIDERS,
	INTEGRATION_TYPES,
	TeamsChannel,
	getIntegrationEnum,
} from "pages/Integration/constants";
import { useIntegrationGetAPI } from "pages/Integration/hooks";
import { Option } from "pages/templates/components/template-form-drawer/components/ct-multi-select/CTMultiSelectDropDown";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAlertConfigAPI } from "../alert-config/hooks/useAlertConfig";
import { useAlertConfigStore } from "../alert-config/store";
import { AlertRuleConfig } from "../alert-config/types";
import { FilterForm } from "./FilterForm";
import { NotificationForm } from "./NotiificationForm";
import { TeamsNotificationForm } from "./TeamsNotificationForm";
import { useUpdateNotificationAPI } from "./hooks/useNotificationConfig";
import { useNotificationAPI } from "./hooks/useNotificationConfig/useNotificationAPI";
import {
	FilterFormInt,
	FilterFormKeys,
	NotificationActionEnum,
	NotificationFormInt,
	RecipientsType,
	defaultFilterObj,
	defaultNotificationObj,
	defaultTeamsNotificationObj,
} from "./types";

export interface NotificationConfigResult {
	criteria: string;
	recipients?: any;
	sendDismissedNotification?: boolean;
}
interface NotificationConfigProps {
	alertRules: string[];
	onClose: () => void;
}

const rulesTitleWrapper = {
	py: 3,
	px: 4,
	borderRadius: 1,
};
const baseRowStyle = {
	py: 3,
	px: 2,
};

export const NotificationConfig = ({
	alertRules,
	onClose,
}: NotificationConfigProps) => {
	const theme = useTheme();
	const [notificationMedium, setNotificationMedium] = useState<
		NotificationFormInt[]
	>([]);
	const { isFeatureEnabled: isNotificationTeamChannelsEnabled } =
		useFeatureFlagControl(FEATURES.NOTIFICATION_MEDIUM_TYPE);

	const [criteriaFilterList, setCriteriaFilterList] = useState<FilterFormInt[]>(
		[defaultFilterObj]
	);
	const [filterData, setFilterData] = useState<Option[]>([]);
	const [selectedAlertRules, setSelectedAlertRules] = useState(
		alertRules ?? []
	);
	const [ruleNames, setRuleNames] = useState<string[]>([]);
	const [isNotificationDisabled, setIsNotificationDisabled] = useState(false);
	const [isDismissedNotificationChecked, setIsDismissedNotificationChecked] =
		useState(false);
	const [showNotifyTooltipText, setShowNotifyTooltipText] = useState(false);
	const updateNotificationConfigAPI = useUpdateNotificationAPI();
	const { mutate: getNotificationMutate } = useNotificationAPI();
	const { mutate: getAlertMutate } = useAlertConfigAPI();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const setSearch = useSearchStore(state => state.setSearch);
	const requestAPIRefresh = useAlertConfigStore(
		store => store.requestAPIRefresh
	);
	const { data: teamsChannelConfig = {} } = useIntegrationGetAPI(
		INTEGRATION_TYPES.COLLABORATION,
		INTEGRATION_PROVIDERS.MSTEAMS
	);

	const channelConfigList = useMemo(() => {
		if (!teamsChannelConfig) {
			return [];
		}
		if (teamsChannelConfig.integrationParameters) {
			if (
				getIntegrationEnum(teamsChannelConfig) ===
				INTEGRATIONS.MSTEAMS_COLLABORATION
			) {
				return teamsChannelConfig.integrationParameters.channels;
			}
		}
		return [];
	}, [teamsChannelConfig]);

	useEffect(() => {
		setSearch("");
		return () => {
			setSearch("");
		};
	}, [setSearch]);

	const updateNotificationData = (
		notificationRow: NotificationFormInt,
		index: number
	) => {
		let newRecipientList = [...notificationMedium];
		newRecipientList[index] = notificationRow;
		setNotificationMedium(newRecipientList);
		setIsNotificationDisabled(true);
	};

	const addNotificationMedium = () => {
		if (notificationMedium.length) {
			let hasEmail = notificationMedium.find(
				n => n.action === NotificationActionEnum.Emails
			);

			if (hasEmail) {
				setNotificationMedium([
					...notificationMedium,
					defaultTeamsNotificationObj,
				]);
			} else {
				setNotificationMedium([...notificationMedium, defaultNotificationObj]);
			}
		} else {
			setNotificationMedium([...notificationMedium, defaultNotificationObj]);
		}
	};

	const removeNotificationMediums = (index: number) => {
		if (index || index === 0) {
			const newNotificationList = [...notificationMedium];
			newNotificationList.splice(index, 1);
			setNotificationMedium(newNotificationList);
			setIsNotificationDisabled(true);
		}
	};
	const memoizedCriteriaFilterList = useMemo(() => {
		if (selectedAlertRules.length) {
			const criteriaListbody: FilterFormInt = {
				[FilterFormKeys.FilterCriteria]:
					criteriaFilterList?.[0]?.[FilterFormKeys.FilterCriteria],
				[FilterFormKeys.AlertRuleName]: ruleNames,
			};
			return [criteriaListbody];
		} else {
			return [defaultFilterObj];
		}
	}, [criteriaFilterList, selectedAlertRules, ruleNames]);

	const buildRequestBody = (getRequest?: boolean) => {
		let criteria = "";
		if (!memoizedCriteriaFilterList.length) {
			setCriteriaFilterList([defaultFilterObj]);
		}
		memoizedCriteriaFilterList.forEach(obj => {
			const createCriteria = `'${obj[FilterFormKeys.FilterCriteria]}' in (${obj[
				FilterFormKeys.AlertRuleName
			]
				.map(obj => `'${obj}'`)
				.join(",")})`;
			if (criteria) {
				criteria = criteria + `and ${createCriteria}`;
			} else {
				criteria = createCriteria;
			}
		});

		const recipientsObj: RecipientsType = notificationMedium.reduce(
			(acc, item) => {
				const { action, whomtonotify, recipients } = item;

				if (action === NotificationActionEnum.Emails) {
					acc[action] = acc[action] || {};
					acc[action][whomtonotify] = (acc[action][whomtonotify] || []).concat(
						recipients
					);
				} else if (action === NotificationActionEnum.TeamChannels) {
					acc[action] = recipients;
				}

				return acc;
			},
			{} as RecipientsType
		);

		let body: NotificationConfigResult = {
			criteria: criteria,
			recipients: recipientsObj,
			sendDismissedNotification: isDismissedNotificationChecked,
		};
		if (getRequest) {
			return {
				criteria: criteria,
			};
		}
		return body;
	};

	const updateNotificationConfig = () => {
		const body: NotificationConfigResult = buildRequestBody();

		updateNotificationConfigAPI.mutate(body, {
			onSuccess: (response: any) => {
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"Notification configuration saved"
				);
				setIsNotificationDisabled(false);
				requestAPIRefresh();
				onClose();
			},
			onError: (error: any) => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const transformtoFormdata = useCallback(
		(response: any) => {
			const output: NotificationFormInt[] = [];
			for (const action in response) {
				if (action === NotificationActionEnum.Emails) {
					for (const whomtonotify in response[action]) {
						const recipients = response?.[action]?.[whomtonotify] ?? [];
						if (recipients.length) {
							output.push({
								action,
								whomtonotify,
								recipients,
							});
						}
					}
				} else if (action === NotificationActionEnum.TeamChannels) {
					const recipients = response?.[action] ?? [];
					if (recipients.length && isNotificationTeamChannelsEnabled) {
						output.push({
							action,
							whomtonotify: "",
							recipients,
						});
					}
				}
			}
			setNotificationMedium(output);
			setIsNotificationDisabled(false);
		},
		[
			isNotificationTeamChannelsEnabled,
			setNotificationMedium,
			setIsNotificationDisabled,
		]
	);

	const updateFilterData = (filter: FilterFormInt) => {
		setCriteriaFilterList([filter]);
		setSelectedAlertRules(filter.alertrulename);
	};

	useEffect(() => {
		const body = {
			criteria: `'alertrulestatus' in ('enabled', 'snoozed')`,
			pagination: {
				offset: 0,
				limit: 100,
			},
		};

		getAlertMutate(body, {
			onSuccess: (response: { items: AlertRuleConfig[] }) => {
				const data: Option[] = response?.items?.map((ele: AlertRuleConfig) => {
					const rule: Option = {
						columnLabel: ele.alertRuleName,
						columnName: ele.alertRuleId,
					};
					return rule;
				});
				const ruleNames = response?.items
					?.filter((ele: AlertRuleConfig) =>
						selectedAlertRules.includes(ele.alertRuleId)
					)
					.map((ele: AlertRuleConfig) => ele.alertRuleName);
				setFilterData(data ?? []);
				setRuleNames(ruleNames ?? []);
			},
			onError: (error: any) => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	}, [getAlertMutate, setSnackbar, selectedAlertRules]);

	useEffect(() => {
		if (selectedAlertRules.length) {
			const createCriteria: string = `'alertruleid' in (${selectedAlertRules
				.map((obj: any) => `'${obj}'`)
				.join(",")})`;
			const body = {
				criteria: createCriteria,
			};

			getNotificationMutate(body, {
				onSuccess: (response: {
					recipients: any;
					sendDismissedNotification: any;
					hasConflict: any;
				}) => {
					transformtoFormdata(response.recipients);
					setIsDismissedNotificationChecked(
						response?.sendDismissedNotification ?? false
					);
					setShowNotifyTooltipText(response?.hasConflict ?? false);
				},
				onError: (error: any) => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		} else {
			setNotificationMedium([]);
			setIsNotificationDisabled(false);
		}
	}, [
		getNotificationMutate,
		setSnackbar,
		selectedAlertRules,
		transformtoFormdata,
	]);

	const renderNotificationMedium = (
		item: NotificationFormInt,
		index: number,
		showLabel: boolean
	) => {
		if (item.action === NotificationActionEnum.Emails) {
			return (
				<NotificationForm
					recipients={item}
					index={index}
					updateNotificationData={updateNotificationData}
					showLabel={showLabel}
				/>
			);
		} else if (item.action === NotificationActionEnum.TeamChannels) {
			return (
				<TeamsNotificationForm
					selectedChannels={item.recipients as TeamsChannel[]}
					setSelectedChannels={selectedChannels => {
						if (selectedChannels && selectedChannels.length) {
							setIsNotificationDisabled(true);
						}
						updateNotificationData(
							{ ...item, recipients: selectedChannels },
							index
						);
					}}
					teamsChannels={channelConfigList}
					showLabel={true}
				/>
			);
		}
	};

	return (
		<>
			<DialogContent
				sx={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
					overflowY: "auto",
				}}
			>
				<Paper
					sx={{
						transition: theme.transitions.create(["width", "margin"], {
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.leavingScreen,
						}),
						mb: 2,
					}}
				>
					<Stack
						alignItems="flex-start"
						justifyContent="flex-start"
						spacing={0}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent={"space-between"}
							sx={{
								...rulesTitleWrapper,
								width: "100%",
								background: theme =>
									theme.palette.mode === "dark"
										? theme.palette.background.default
										: theme.palette.custom.lightGreyBg,
							}}
						>
							<Stack>
								<Typography variant="body1">
									{window.getCTTranslatedText("Filter Criteria")}
								</Typography>
								<Typography variant="body2">
									{window.getCTTranslatedText(
										"Select filter criteria to view the list of notification mediums configured for the chosen parameters."
									)}
								</Typography>
							</Stack>
						</Stack>

						{memoizedCriteriaFilterList?.map(item => (
							<Stack key={`filter-${index}`} sx={{ width: "100%", px: 3 }}>
								<Stack
									direction="row"
									alignItems="center"
									sx={{
										...baseRowStyle,
									}}
									className={`filter-${index}`}
								>
									<FilterForm
										filter={item}
										filterData={filterData}
										alertRules={alertRules}
										updateFilterData={updateFilterData}
									/>
								</Stack>
							</Stack>
						))}
					</Stack>
				</Paper>

				<Paper
					sx={{
						transition: theme.transitions.create(["margin"], {
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.leavingScreen,
						}),
						width: "100%",
					}}
				>
					<Stack
						alignItems="flex-start"
						justifyContent="flex-start"
						spacing={0}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent={"space-between"}
							sx={{
								...rulesTitleWrapper,
								width: "100%",
								background: theme =>
									theme.palette.mode === "dark"
										? theme.palette.background.default
										: theme.palette.custom.lightGreyBg,
							}}
						>
							<Stack>
								<Typography variant="body1">
									{window.getCTTranslatedText("Notification Mediums")}
								</Typography>
								<Typography variant="body2">
									{window.getCTTranslatedText(
										"Below is the list of notification mediums configured for the selected filter criteria."
									)}
								</Typography>
								<Typography variant="body2">
									{window.getCTTranslatedText("AlertHelperText")}
								</Typography>
							</Stack>
							<Stack alignItems="center">
								<Button
									variant="text"
									startIcon={<AddCircleOutlineIcon />}
									color="primary"
									onClick={() => addNotificationMedium()}
									disabled={
										notificationMedium.length ===
											(isNotificationTeamChannelsEnabled ? 2 : 1) ||
										!selectedAlertRules.length
									}
								>
									{window.getCTTranslatedText("Add Action")}
								</Button>
							</Stack>
						</Stack>

						{notificationMedium.length > 0 &&
							notificationMedium.map((item, index, values) => {
								item = item as NotificationFormInt;
								const showLabel = Boolean(index === 0);
								return (
									<Stack
										key={`notification-${index}`}
										sx={{ width: "100%", px: 3 }}
									>
										<Stack
											direction="row"
											alignItems="center"
											sx={{
												...baseRowStyle,
											}}
											className={`notification-${index}`}
										>
											{renderNotificationMedium(item, index, showLabel)}
											<Stack sx={{ pl: 5 }} alignItems="center">
												<IconButton
													size="medium"
													aria-label="remove notification"
													onClick={() => removeNotificationMediums(index)}
												>
													<Tooltip
														title={window.getCTTranslatedText(
															"Remove notification"
														)}
													>
														<RemoveCircleOutlineIcon fontSize="small" />
													</Tooltip>
												</IconButton>
											</Stack>
										</Stack>
									</Stack>
								);
							})}
					</Stack>
				</Paper>

				<Stack direction={"row"} mt={3}>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={isDismissedNotificationChecked}
									onChange={() => {
										setIsDismissedNotificationChecked(prev => !prev);
										setIsNotificationDisabled(true);
									}}
									inputProps={{ "aria-label": "controlled" }}
								/>
							}
							label={window.getCTTranslatedText("NotifyWhenAlertAutoDismissed")}
						/>
					</FormGroup>
					{showNotifyTooltipText && (
						<Tooltip
							title={window.getCTTranslatedText(
								"SelectedAlertRulesDifferingDismissedAlertsStatuses"
							)}
						>
							<IconButton>
								<InfoOutlined fontSize="small" />
							</IconButton>
						</Tooltip>
					)}
				</Stack>
			</DialogContent>

			<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
				<ToolbarAction
					loading={updateNotificationConfigAPI.isLoading}
					isValid={isNotificationDisabled}
					actionBtnText={"Save"}
					save={updateNotificationConfig}
					cancel={onClose}
				/>
			</DialogActions>
		</>
	);
};

import { useMutation } from "@tanstack/react-query";
import { DevicesRequestProps } from "../../appliance-unmanaged-devices/types";

export function useManageDevicesAPI() {
	const path = `gateways/managedevices`;
	return useMutation<undefined, Error, DevicesRequestProps>([
		"manage-device",
		path,
		"put",
	]);
}

import { isFieldError } from "modules/form-fields/helper";
import { Field, FieldType, TextFieldsValues } from "modules/form-fields/type";

export const checkValid = (
	iState: {
		[key: string]: {
			value: string;
			field: Field;
			required?: boolean;
		};
	},
	key: string
) => {
	if (typeof iState[key].value === "object") {
		if (!Object.keys(iState[key].value ?? {}).length) {
			return true;
		}
	} else {
		const isRequired = iState[key].required;
		const isEmpty =
			!iState[key].value?.toString()?.length ||
			iState[key].value?.toString()?.length === 0;
		const isTeamsNotification =
			iState[key].field.identifier === "teamsNotifications";

		if (
			(isRequired && isEmpty) ||
			(isRequired &&
				!isEmpty &&
				isTeamsNotification &&
				isFieldError(iState[key].value, iState[key].field)) ||
			(!isRequired &&
				!isEmpty &&
				isTeamsNotification &&
				isFieldError(iState[key].value, iState[key].field))
		) {
			return true;
		}
	}

	return false;
};

export const hasErrors = (
	textFieldsValues: TextFieldsValues,
	formConfig?: Array<Field>
) => {
	const iState: {
		[key: string]: {
			value: string;
			field: Field;
			required?: boolean;
		};
	} = {};
	let ddChilsElements: Field[] = [];
	let checkboxFieldsWithErrors = false;
	const processField = (field: Field) => {
		if (field.type !== FieldType.MAPPING && field.type !== FieldType.STATIC) {
			if (field.type !== FieldType.SECTION) {
				iState[field.key] = {
					value: textFieldsValues[field.key] ?? "",
					field: field,
					required: field.required,
				};
			}
			field?.children?.forEach(processField);
		}

		if (field.type === FieldType.DROPDOWN && field.required) {
			field?.possibleValues?.forEach(possibleValue => {
				if (possibleValue?.children?.length) {
					const filterVal = field?.possibleValues?.filter(
						obj => obj.key === iState[field.key].value
					);
					if (filterVal?.length) {
						filterVal[0].children?.forEach(child => {
							if (child.children?.length) {
								ddChilsElements = child.children;
							}
						});
					}
				}
				possibleValue?.children?.forEach(processField);
			});
		}

		if (field.type === FieldType.DROPDOWN && !field.required) {
			field?.possibleValues?.forEach(possibleValue => {
				if (possibleValue?.children?.length) {
					const selectedValue =
						possibleValue.key === textFieldsValues[field.key];
					if (selectedValue) {
						possibleValue.children?.forEach(child => {
							if (child.children?.length) {
								ddChilsElements.push(...child.children);
							}
						});
					}
				}
				possibleValue?.children?.forEach(processField);
			});
		}

		if (field.type === FieldType.CHECKBOX && field.required) {
			if (!iState[field.key]) {
				checkboxFieldsWithErrors = true;
			}
		}
	};

	formConfig?.forEach(processField);
	if (ddChilsElements.length) {
		for (const field of ddChilsElements) {
			if (checkValid(iState, field.key) && field.required) {
				return true;
			}
		}
	} else {
		for (const key in iState) {
			if (checkValid(iState, key)) {
				return true;
			}
		}
	}
	return checkboxFieldsWithErrors;
};

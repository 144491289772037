import { MoreOptionsMenu } from "common/molecules/more-options-menu";
import { MenuOption } from "common/molecules/more-options-menu/types";
import { CTUser } from "pages/organization/types";
import { useState } from "react";
import { DeleteUser } from "../delete-user";

interface UserActionsMenuProps {
	user: CTUser;
}

export function UserActionsMenu({ user }: UserActionsMenuProps) {
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const moreActionMenuOptions: Array<MenuOption> = [
		{
			label: "Delete",
			handler: () => handleDeleteUser(true),
		},
	];

	const handleDeleteUser = (visibility: boolean) => {
		setShowDeleteConfirmation(visibility);
	};

	const cancelCallback = () => {
		setShowDeleteConfirmation(false);
	};

	return (
		<>
			<MoreOptionsMenu menuOptions={moreActionMenuOptions} />
			<DeleteUser
				user={user}
				allowDelete={showDeleteConfirmation}
				handleClose={cancelCallback}
			/>
		</>
	);
}

import * as semver from "semver";
import { AgentActionType } from "../../agent-data-grid-toolbar/Toolbar";
import { isHigherVersion } from "../../agent-data-grid/helpers/columnHelpers";
import { AgentGroupByObj } from "../../agent-drawers/hooks/useGroupByAgentData";
import { PLATFORMS } from "../constants";

interface HighestVersionAgentInSelectionOptions {
	page?: AgentActionType;
	agentsGroupByPlatforms?: AgentGroupByObj;
	platform: PLATFORMS;
	highestCurrentAgentVersion: string;
	setHighestAgentVersion: (str: string) => void;
	index: number;
}
export const highestVersionAgentInSelection = ({
	page,
	agentsGroupByPlatforms,
	platform,
	highestCurrentAgentVersion,
	setHighestAgentVersion,
	index,
}: HighestVersionAgentInSelectionOptions) => {
	if (
		page === AgentActionType.UPGRADE &&
		agentsGroupByPlatforms &&
		platform &&
		agentsGroupByPlatforms[platform]
	) {
		let agentData = agentsGroupByPlatforms[platform][index];
		if (agentData?.data?.length > 0) {
			let highestVersion = agentData.data[0]?.currentVersion ?? "0.0.0";
			for (let i = 0; i < agentData.data.length; i++) {
				let newVersion = agentData.data[i]?.currentVersion;
				if (isHigherVersion(highestVersion, newVersion)) {
					highestVersion = newVersion;
				}
			}
			if (semver.neq(highestVersion, highestCurrentAgentVersion)) {
				setHighestAgentVersion(highestVersion);
			}
		}
	}
};

import { TIME_FILTER_OPTIONS } from "common/molecules/TimeFilter/TimeFilter";
import { FacetGroupInfo } from "modules/core/types";
import { ALL_TIME, COMMON_FACETS } from "modules/facets/constants";
import { FacetGroups } from "../../modules/facets/types";
export const FACET_ORDER: FacetGroupInfo = {
	...COMMON_FACETS,
	assetname: FacetGroups.Assets,
	action: FacetGroups.AuditLog,
	eventtype: FacetGroups.AuditLog,
	eventcategory: FacetGroups.AuditLog,
	actionby: FacetGroups.AuditLog,
	alerttype: FacetGroups.Alert,
	alertseverity: FacetGroups.Alert,
	alertstatus: FacetGroups.Alert,
	alertrulename: FacetGroups.Alert,
};

export const ALERT_TIME_FILTER_OPTIONS = [...TIME_FILTER_OPTIONS, ALL_TIME];

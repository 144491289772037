import { Grid } from "@mui/material";
import { HostGroups } from "pages/crowdstrike-groups/host-groups";
import { FirewallHostGroups } from "./firewall-host-groups/FirewallHostGroups";

export enum CrowdStrikeGroupsTabValue {
	HostGroups = 0,
	FirewallHostGroups = 1,
}

export const CROWDSTRIKE_GROUPS_TABS = {
	[CrowdStrikeGroupsTabValue.HostGroups]: {
		label: "Host Groups",
		urlQuery: "host-groups",
		component: (
			<Grid item xs={12}>
				<HostGroups />
			</Grid>
		),
	},
	[CrowdStrikeGroupsTabValue.FirewallHostGroups]: {
		label: "Firewall Host Groups",
		urlQuery: "firewall-host-groups",
		component: <FirewallHostGroups />,
	},
};

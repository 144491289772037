import { useTheme } from "@mui/material";

export const SplunkIcon = () => {
	const theme = useTheme();
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.5041 18.0591H15.9878C15.97 18.0591 15.9556 18.0735 15.9556 18.0913V26.8711C15.9556 26.8889 15.97 26.9034 15.9878 26.9034H17.5041C17.5219 26.9034 17.5363 26.8889 17.5363 26.8711V18.0913C17.5363 18.0735 17.5219 18.0591 17.5041 18.0591Z"
				fill={theme.palette.text.primary}
			/>

			<path
				d="M33.885 23.83V26.8339C33.885 26.8786 33.8631 26.9005 33.8192 26.8997H32.3638C32.3224 26.8997 32.3018 26.8786 32.3018 26.8364L32.3042 18.1211C32.3042 18.0798 32.3245 18.0591 32.365 18.0591H33.8217C33.8622 18.0591 33.8825 18.0793 33.8825 18.1199L33.8887 23.1885C33.889 23.2092 33.8956 23.2294 33.9075 23.2464C33.9195 23.2633 33.9363 23.2763 33.9558 23.2835C33.9752 23.2908 33.9964 23.292 34.0165 23.287C34.0367 23.282 34.0548 23.271 34.0686 23.2555L36.1594 20.8409C36.1891 20.807 36.2247 20.7987 36.2661 20.8161L37.3344 21.2777C37.3848 21.2992 37.3915 21.3302 37.3542 21.3707L35.4012 23.4627C35.3706 23.4958 35.3694 23.5297 35.3975 23.5644L37.755 26.4778C37.7591 26.483 37.762 26.4892 37.7633 26.4957C37.7646 26.5023 37.7643 26.509 37.7625 26.5154C37.7607 26.5218 37.7574 26.5277 37.7528 26.5326C37.7483 26.5375 37.7427 26.5413 37.7364 26.5436L36.3715 27.0225C36.3244 27.039 36.2863 27.0271 36.2574 26.9865L33.9719 23.8027C33.914 23.7216 33.885 23.7307 33.885 23.83Z"
				fill={theme.palette.text.primary}
			/>

			<path
				d="M5.8879 22.1137C5.80724 22.3891 5.94125 22.6013 6.16335 22.785C6.76265 23.2813 7.62871 23.8247 8.03817 24.4563C8.44267 25.0829 8.2094 25.9825 7.69572 26.4738C6.749 27.3796 5.09504 27.1376 4.06767 26.4738C3.99487 26.4258 3.98081 26.3646 4.02548 26.2902L4.45603 25.5631C4.48333 25.5176 4.51559 25.5134 4.55281 25.5507C4.92257 25.9204 5.33078 26.1119 5.77747 26.1251C6.55668 26.1475 6.98723 25.2628 6.34574 24.7541C5.77747 24.3024 5.07394 23.8868 4.63098 23.3098C3.86294 22.311 4.55157 21.1111 5.66952 20.832C6.45783 20.6359 7.2449 20.7575 8.03073 21.1967C8.07457 21.2216 8.08284 21.2551 8.05554 21.2973L7.63864 21.9449C7.61217 21.9855 7.5795 21.9917 7.54062 21.9636C7.1262 21.6645 6.08642 21.4263 5.8879 22.1137Z"
				fill={theme.palette.text.primary}
			/>

			<path
				d="M10.5159 26.2435L10.5221 29.8455C10.5221 29.9092 10.4902 29.9411 10.4266 29.9411L9.053 29.9398C9.00172 29.9398 8.97607 29.9138 8.97607 29.8617V20.9442C8.97607 20.934 8.98013 20.9242 8.98734 20.917C8.99455 20.9097 9.00434 20.9057 9.01454 20.9057L10.4551 20.9032C10.4696 20.9032 10.4835 20.909 10.4937 20.9192C10.5039 20.9294 10.5097 20.9433 10.5097 20.9578L10.5208 21.6651C10.5217 21.7254 10.5444 21.7349 10.5891 21.6936C10.9725 21.3362 11.2852 20.9863 11.8572 20.835C13.4367 20.4193 14.7953 21.3573 15.1539 22.8959C15.6354 24.9606 14.3325 27.3404 11.975 27.0352C11.3968 26.9607 10.9762 26.6307 10.5816 26.2175C10.5378 26.1712 10.5159 26.1799 10.5159 26.2435ZM12.1363 26.1021C13.6253 26.0475 13.7159 23.7322 13.4119 22.7743C13.0185 21.536 11.5718 21.3201 10.8658 22.4219C10.1995 23.4605 10.3099 26.1703 12.1363 26.1021Z"
				fill={theme.palette.text.primary}
			/>

			<path
				d="M27.1291 21.6067C27.9141 20.796 28.8331 20.5454 29.8861 20.8547C30.9545 21.1687 31.1567 22.072 31.1741 23.149C31.1939 24.3848 31.196 25.6214 31.1803 26.8589C31.1803 26.8887 31.1654 26.9036 31.1356 26.9036H29.6566C29.6227 26.9036 29.6057 26.8866 29.6057 26.8527C29.6032 25.6475 29.6032 24.4431 29.6057 23.2395C29.6082 21.258 27.139 21.4255 27.0584 23.2507C27.0088 24.3699 27.0021 25.5395 27.0385 26.7596C27.041 26.8647 26.9897 26.9164 26.8847 26.9147L25.6377 26.8936C25.4962 26.8912 25.4259 26.8196 25.4268 26.679L25.4565 20.9354C25.4565 20.9252 25.4606 20.9154 25.4678 20.9082C25.475 20.901 25.4848 20.8969 25.495 20.8969L26.9926 20.9193C27.0249 20.9201 27.0406 20.9366 27.0398 20.9689L27.0261 21.5632C27.0237 21.6666 27.058 21.6811 27.1291 21.6067Z"
				fill={theme.palette.text.primary}
			/>

			<path
				d="M22.6718 26.185C22.0126 26.8914 21.1887 27.1701 20.2002 27.0212C19.045 26.8463 18.6591 26.0807 18.6207 24.9752C18.5743 23.6393 18.5702 22.3021 18.6083 20.9637C18.6091 20.924 18.6294 20.9042 18.6691 20.9042H20.1195C20.1526 20.9042 20.1696 20.9207 20.1704 20.9538C20.1787 22.092 20.1795 23.2257 20.1729 24.3548C20.1692 24.8573 20.2238 25.4082 20.5873 25.7172C21.0154 26.082 21.7152 26.0348 22.147 25.7408C22.5589 25.4616 22.7537 24.897 22.7512 24.4181C22.7438 23.2625 22.7442 22.1069 22.7525 20.9513C22.7533 20.9149 22.7715 20.8971 22.8071 20.898L24.2687 20.9191C24.3109 20.9199 24.332 20.941 24.332 20.9824L24.3295 26.8537C24.3295 26.905 24.3039 26.9307 24.2526 26.9307H22.8108C22.7694 26.9307 22.7488 26.91 22.7488 26.8686V26.216C22.7488 26.1399 22.7231 26.1295 22.6718 26.185Z"
				fill={theme.palette.text.primary}
			/>

			<path
				d="M42.8187 23.8124L39.2105 22.0802C39.1699 22.0604 39.1501 22.0281 39.1509 21.9835L39.1819 21.0219C39.1836 20.9615 39.2117 20.9449 39.2663 20.9722L43.9391 23.3384C43.9821 23.3607 44.0024 23.3963 43.9999 23.4451L43.9577 24.3211C43.956 24.3616 43.937 24.3906 43.9006 24.4079L39.2092 26.717C39.1712 26.7352 39.153 26.7233 39.1546 26.6811L39.1782 25.7306C39.1799 25.6851 39.2014 25.652 39.2427 25.6314L42.8187 23.9042C42.8824 23.8736 42.8824 23.843 42.8187 23.8124Z"
				fill="#72AF35"
			/>

			<path
				d="M13.4122 22.7741C13.7162 23.732 13.6256 26.0473 12.1367 26.1019C10.3103 26.1702 10.1998 23.4603 10.8661 22.4217C11.5721 21.3199 13.0189 21.5358 13.4122 22.7741Z"
				fill={theme.palette.background.default}
			/>

			<rect
				x="0.5"
				y="0.5"
				width="47"
				height="47"
				rx="3.5"
				stroke={theme.palette.text.primary}
				strokeOpacity="0.12"
			/>
		</svg>
	);
};

import { CustomCommonStoreType } from "common/types/types";
import { CoreStoreBuilder } from "modules/core/store";
import { CoreStore } from "modules/core/types";
import { FacetStoreBuilder } from "modules/facets";
import { FacetStore } from "modules/facets/types";
import { create } from "zustand";
import { FirewallHostGroupListItem } from "./types";

export interface FirewallHostGroupStore extends CoreStore {
	selectedCriteria: string[];
	setSelectedCriteria: (selectedCriteria: string[]) => void;
	idForDeletion:
		| FirewallHostGroupListItem["crowdstrikeFirewallHostgroupId"]
		| null;
	setIdForDeletion: (
		selectedIdForDeletion:
			| FirewallHostGroupListItem["crowdstrikeFirewallHostgroupId"]
			| null
	) => void;
}

export const useFirewallHostGroupStore = create<FirewallHostGroupStore>()(
	set => ({
		...CoreStoreBuilder(set),
		selectedCriteria: [],
		setSelectedCriteria: (selectedCriteria: string[]) =>
			set({ selectedCriteria }),
		idForDeletion: null,
		setIdForDeletion: idForDeletion => set({ idForDeletion }),
	})
);

export const useFirewallHostGroupFacetStore: CustomCommonStoreType =
	create<FacetStore>()(set => ({
		...FacetStoreBuilder(set),
	}));

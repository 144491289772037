import { useMemo } from "react";
import { NodeProps } from "reactflow";
import { useNodeDataLoadApis } from "../hooks/useNodeDataLoadApis";
import { useVisxStore } from "../store";
import { CTNodeType, GroupNodeDataType } from "../types";
import {
	DEFAULT_PRIVATE_NETWORK_NODE_NAME,
	DEFAULT_PUBLIC_NETWORK_NODE_NAME,
	OTHERS,
} from "../visx-utils";
import { CTGroup } from "./CTGroup";
import { CTNode } from "./CTNode";
import { DataMergerController } from "./ExpansionDataLoadManager";

export function CTNodeManager({ data, id }: NodeProps<GroupNodeDataType>) {
	let mainDimension = useVisxStore(state => state.selectedDimension);

	const expansions = useVisxStore(state => state.expansions);
	const trafficCriteria = useVisxStore(state => state.trafficCriteria);
	const selectedDirection = useVisxStore(state => state.selectedDirection);

	const childNodeIdsMap = useVisxStore(state => state.childNodeIds);
	const isPrivateNodeExpanded = useVisxStore(
		state => state.isPrivateNodeExpanded
	);
	const isPublicNodeExpanded = useVisxStore(
		state => state.isPublicNodeExpanded
	);

	let isGroup =
		data.childrenExpandedBy ||
		(data.trafficData?.children?.length ||
			childNodeIdsMap.get(data.label)?.size ||
			0) > 0;

	if (data.id === DEFAULT_PRIVATE_NETWORK_NODE_NAME) {
		isGroup = isPrivateNodeExpanded;
	} else if (data.id === DEFAULT_PUBLIC_NETWORK_NODE_NAME) {
		isGroup = isPublicNodeExpanded;
	}

	const selectedDimension = useMemo(() => {
		let expansion = expansions?.find(
			e =>
				e.parent.name === data.label &&
				e.parent.dimension.name === data.dimension?.name
		);
		if (expansion) {
			return expansion.dimension;
		} else {
			return undefined;
		}
	}, [expansions, data]);

	const { isLoading } = useNodeDataLoadApis({
		data,
		mainDimension,
		selectedDimension,
		nodeType: data.type,
	});

	const isContainer = !data.type;

	return (
		<>
			{data.label === OTHERS && (
				<svg
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						margin: "auto",
						width: "88%",
						height: "88%",
					}}
				>
					<rect
						width={"100%"}
						height={"100%"}
						fillRule={"evenodd"}
						strokeWidth={2}
						fill={"url(#diagonal-stripe-1)"}
					/>
				</svg>
			)}

			{isGroup || isContainer ? (
				<CTGroup
					isLoading={isLoading}
					id={id}
					data={data}
					isContainer={isContainer}
				/>
			) : (
				<CTNode
					data={data}
					selectedDimension={selectedDimension}
					isLoading={isLoading}
				/>
			)}
			{data.type === CTNodeType.HUB && selectedDimension && (
				<DataMergerController
					selectedDimension={selectedDimension}
					mainDimension={mainDimension}
					loaderProps={{
						destinationCriteria: "*",
						sourceCriteria: "*",
						trafficCriteria,
					}}
					selectedDirection={selectedDirection}
					nodeData={data}
				/>
			)}
		</>
	);
}

import { useQuery } from "@tanstack/react-query";
import { AnnotationsListResult } from "../type";

export function useAnnotationsListAPI(
	entityType: string,
	entityId: string,
	params?: string
) {
	return useQuery<AnnotationsListResult, Error>([
		"annotation-list",
		params
			? `${entityType}/${entityId}/comments?${params}`
			: `${entityType}/${entityId}/comments`,
	]);
}

import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { SelectionType } from "modules/recommendation-workflows/PathRecommendationDrawer";

interface PortRecommendationActionsProps {
	isValid: boolean;
	isLoading: boolean;
	update: (buttonSelection?: string) => void;
	cancel: VoidFunction;
	selection: SelectionType;
	renderActionButtons?: () => React.ReactNode;
}

export const PortRecommendationActions = ({
	isValid,
	update,
	cancel,
	selection,
	isLoading,
	renderActionButtons,
}: PortRecommendationActionsProps) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const confirmAction = (_: React.SyntheticEvent, buttonSelection?: string) => {
		if (isValid) {
			update(buttonSelection);
		}
	};

	const actionBtnText = () => {
		switch (selection) {
			case SelectionType.NamedNetwork:
				return "Assign";
			case SelectionType.Template:
				return "Add to template";
			case SelectionType.PrivateIPs:
			case SelectionType.PublicIPs:
				return "Create";
		}
	};

	const permissionName = () => {
		switch (selection) {
			case SelectionType.NamedNetwork:
				return ["UPDATE_ASSET", "UPDATE_NAMED_NETWORK"];
			case SelectionType.Template:
				return ["UPDATE_TEMPLATE"];
			case SelectionType.PrivateIPs:
			case SelectionType.PublicIPs:
				return ["CREATE_NAMED_NETWORK"];
		}
	};

	const hasPermission = permissionName().every(permission =>
		userPermissions.has(permission)
	);

	return (
		<>
			{hasPermission && (
				<ToolbarAction
					loading={isLoading}
					isValid={isValid}
					actionBtnText={actionBtnText()}
					save={confirmAction}
					cancel={cancel}
					renderActionButtons={renderActionButtons}
				/>
			)}
		</>
	);
};

import { Alert, Stack } from "@mui/material";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import { AgentDownloadPage } from "pages/agents/components/agent-download-page/AgentDownloadPage";
import { AgentBaseDrawer } from "pages/appliances/components/agent-base-drawer";
import { DRAWER_TYPE } from "pages/appliances/components/agent-base-drawer/types";
import { AgentActionType } from "../agent-data-grid-toolbar/Toolbar";
import { AgentOtpResponse } from "../agent-data-grid-toolbar/hooks/useAgentOtpAPI";
import { AgentGroupByObj } from "./hooks/useGroupByAgentData";

export interface AgentDrawerProps {
	isOpen: boolean;
	data?: any;
	selection: GridRowId[];
	columns?: GridColDef[];
	onCancel: () => void;
	btnTitle?: string;
}

export interface RenderContentProps {
	agentsGroupByPlatforms?: AgentGroupByObj;
	agentOtpData?: AgentOtpResponse;
	setAgentsGroupByPlatforms?: (obj?: AgentGroupByObj) => void;
	isLoading?: boolean;
}

const renderContent = ({
	agentsGroupByPlatforms,
	setAgentsGroupByPlatforms,
}: RenderContentProps) => (
	<>
		<Stack sx={{ mb: 3, width: "100%" }} spacing={1}>
			<Alert severity="warning">
				{window.getCTTranslatedText(
					`Please review all selected agent platform versions, Latest version is selected by default.`
				)}
			</Alert>
		</Stack>
		<AgentDownloadPage
			page={AgentActionType.UPGRADE}
			agentsGroupByPlatforms={agentsGroupByPlatforms}
			setAgentsGroupByPlatforms={setAgentsGroupByPlatforms}
		/>
	</>
);

export const AgentUpgradeDrawer = (props: AgentDrawerProps) => {
	return (
		<AgentBaseDrawer
			drawerType={DRAWER_TYPE.AGENT_UPGRADE}
			{...props}
			renderContent={renderContent}
			drawerWidth="40%"
		/>
	);
};

// Generated from src/parser/ctql.g4 by ANTLR 4.9.0-SNAPSHOT

import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

export class ctqlLexer extends Lexer {
	public static readonly OR = 1;
	public static readonly AND = 2;
	public static readonly NOT = 3;
	public static readonly LIKE = 4;
	public static readonly ILIKE = 5;
	public static readonly IN = 6;
	public static readonly BETWEEN = 7;
	public static readonly CIDRMATCH = 8;
	public static readonly NULL = 9;
	public static readonly EQ = 10;
	public static readonly NEQ = 11;
	public static readonly LT = 12;
	public static readonly LTE = 13;
	public static readonly GT = 14;
	public static readonly GTE = 15;
	public static readonly ASTERISK = 16;
	public static readonly LP = 17;
	public static readonly RP = 18;
	public static readonly COMMA = 19;
	public static readonly D_QUOTE = 20;
	public static readonly S_QUOTE = 21;
	public static readonly SINGLE_QUOTED_LITERAL = 22;
	public static readonly DOUBLE_QUOTED_LITERAL = 23;
	public static readonly UNQUOTED_LITERAL = 24;
	public static readonly NUMERIC_LITERAL = 25;
	public static readonly POSITIVE_NUMERAL = 26;
	public static readonly NEGATIVE_NUMERAL = 27;
	public static readonly WS = 28;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL",
		"HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = ["DEFAULT_MODE"];

	public static readonly ruleNames: string[] = [
		"OR",
		"AND",
		"NOT",
		"LIKE",
		"ILIKE",
		"IN",
		"BETWEEN",
		"CIDRMATCH",
		"NULL",
		"EQ",
		"NEQ",
		"LT",
		"LTE",
		"GT",
		"GTE",
		"ASTERISK",
		"LP",
		"RP",
		"COMMA",
		"D_QUOTE",
		"S_QUOTE",
		"SINGLE_QUOTED_LITERAL",
		"DOUBLE_QUOTED_LITERAL",
		"UNQUOTED_LITERAL",
		"NUMERIC_LITERAL",
		"POSITIVE_NUMERAL",
		"NEGATIVE_NUMERAL",
		"WS",
		"A",
		"B",
		"C",
		"D",
		"E",
		"H",
		"I",
		"K",
		"L",
		"M",
		"N",
		"O",
		"R",
		"T",
		"U",
		"W",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		"'='",
		"'!='",
		"'<'",
		"'<='",
		"'>'",
		"'>='",
		"'*'",
		"'('",
		"')'",
		"','",
		"'\"'",
		"'''",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined,
		"OR",
		"AND",
		"NOT",
		"LIKE",
		"ILIKE",
		"IN",
		"BETWEEN",
		"CIDRMATCH",
		"NULL",
		"EQ",
		"NEQ",
		"LT",
		"LTE",
		"GT",
		"GTE",
		"ASTERISK",
		"LP",
		"RP",
		"COMMA",
		"D_QUOTE",
		"S_QUOTE",
		"SINGLE_QUOTED_LITERAL",
		"DOUBLE_QUOTED_LITERAL",
		"UNQUOTED_LITERAL",
		"NUMERIC_LITERAL",
		"POSITIVE_NUMERAL",
		"NEGATIVE_NUMERAL",
		"WS",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(
		ctqlLexer._LITERAL_NAMES,
		ctqlLexer._SYMBOLIC_NAMES,
		[]
	);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return ctqlLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(ctqlLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string {
		return "ctql.g4";
	}

	// @Override
	public get ruleNames(): string[] {
		return ctqlLexer.ruleNames;
	}

	// @Override
	public get serializedATN(): string {
		return ctqlLexer._serializedATN;
	}

	// @Override
	public get channelNames(): string[] {
		return ctqlLexer.channelNames;
	}

	// @Override
	public get modeNames(): string[] {
		return ctqlLexer.modeNames;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\x1E\xFD\b\x01" +
		"\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06" +
		"\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r" +
		"\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t" +
		"\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t" +
		"\x17\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t" +
		'\x1C\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04"\t' +
		"\"\x04#\t#\x04$\t$\x04%\t%\x04&\t&\x04'\t'\x04(\t(\x04)\t)\x04*\t*\x04" +
		"+\t+\x04,\t,\x04-\t-\x03\x02\x03\x02\x03\x02\x03\x03\x03\x03\x03\x03\x03" +
		"\x03\x03\x04\x03\x04\x03\x04\x03\x04\x03\x05\x03\x05\x03\x05\x03\x05\x03" +
		"\x05\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x07\x03\x07\x03" +
		"\x07\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\t\x03\t\x03\t" +
		"\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\n\x03\n\x03\n\x03\n\x03" +
		"\n\x03\v\x03\v\x03\f\x03\f\x03\f\x03\r\x03\r\x03\x0E\x03\x0E\x03\x0E\x03" +
		"\x0F\x03\x0F\x03\x10\x03\x10\x03\x10\x03\x11\x03\x11\x03\x12\x03\x12\x03" +
		"\x13\x03\x13\x03\x14\x03\x14\x03\x15\x03\x15\x03\x16\x03\x16\x03\x17\x03" +
		"\x17\x07\x17\xA9\n\x17\f\x17\x0E\x17\xAC\v\x17\x03\x17\x03\x17\x03\x18" +
		"\x03\x18\x07\x18\xB2\n\x18\f\x18\x0E\x18\xB5\v\x18\x03\x18\x03\x18\x03" +
		"\x19\x03\x19\x07\x19\xBB\n\x19\f\x19\x0E\x19\xBE\v\x19\x03\x1A\x03\x1A" +
		"\x05\x1A\xC2\n\x1A\x03\x1B\x06\x1B\xC5\n\x1B\r\x1B\x0E\x1B\xC6\x03\x1B" +
		"\x03\x1B\x06\x1B\xCB\n\x1B\r\x1B\x0E\x1B\xCC\x07\x1B\xCF\n\x1B\f\x1B\x0E" +
		"\x1B\xD2\v\x1B\x03\x1C\x03\x1C\x03\x1C\x03\x1D\x06\x1D\xD8\n\x1D\r\x1D" +
		"\x0E\x1D\xD9\x03\x1D\x03\x1D\x03\x1E\x03\x1E\x03\x1F\x03\x1F\x03 \x03" +
		' \x03!\x03!\x03"\x03"\x03#\x03#\x03$\x03$\x03%\x03%\x03&\x03&\x03\'' +
		"\x03'\x03(\x03(\x03)\x03)\x03*\x03*\x03+\x03+\x03,\x03,\x03-\x03-\x02" +
		"\x02\x02.\x03\x02\x03\x05\x02\x04\x07\x02\x05\t\x02\x06\v\x02\x07\r\x02" +
		"\b\x0F\x02\t\x11\x02\n\x13\x02\v\x15\x02\f\x17\x02\r\x19\x02\x0E\x1B\x02" +
		"\x0F\x1D\x02\x10\x1F\x02\x11!\x02\x12#\x02\x13%\x02\x14'\x02\x15)\x02" +
		"\x16+\x02\x17-\x02\x18/\x02\x191\x02\x1A3\x02\x1B5\x02\x1C7\x02\x1D9\x02" +
		"\x1E;\x02\x02=\x02\x02?\x02\x02A\x02\x02C\x02\x02E\x02\x02G\x02\x02I\x02" +
		"\x02K\x02\x02M\x02\x02O\x02\x02Q\x02\x02S\x02\x02U\x02\x02W\x02\x02Y\x02" +
		"\x02\x03\x02\x18\x03\x02))\x03\x02$$\x05\x02C\\aac|\x06\x020;C\\aac|\x03" +
		'\x022;\x05\x02\v\f\x0F\x0F""\x04\x02CCcc\x04\x02DDdd\x04\x02EEee\x04' +
		"\x02FFff\x04\x02GGgg\x04\x02JJjj\x04\x02KKkk\x04\x02MMmm\x04\x02NNnn\x04" +
		"\x02OOoo\x04\x02PPpp\x04\x02QQqq\x04\x02TTtt\x04\x02VVvv\x04\x02WWww\x04" +
		"\x02YYyy\x02\xF4\x02\x03\x03\x02\x02\x02\x02\x05\x03\x02\x02\x02\x02\x07" +
		"\x03\x02\x02\x02\x02\t\x03\x02\x02\x02\x02\v\x03\x02\x02\x02\x02\r\x03" +
		"\x02\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02\x02\x02\x02\x13\x03" +
		"\x02\x02\x02\x02\x15\x03\x02\x02\x02\x02\x17\x03\x02\x02\x02\x02\x19\x03" +
		"\x02\x02\x02\x02\x1B\x03\x02\x02\x02\x02\x1D\x03\x02\x02\x02\x02\x1F\x03" +
		"\x02\x02\x02\x02!\x03\x02\x02\x02\x02#\x03\x02\x02\x02\x02%\x03\x02\x02" +
		"\x02\x02'\x03\x02\x02\x02\x02)\x03\x02\x02\x02\x02+\x03\x02\x02\x02\x02" +
		"-\x03\x02\x02\x02\x02/\x03\x02\x02\x02\x021\x03\x02\x02\x02\x023\x03\x02" +
		"\x02\x02\x025\x03\x02\x02\x02\x027\x03\x02\x02\x02\x029\x03\x02\x02\x02" +
		"\x03[\x03\x02\x02\x02\x05^\x03\x02\x02\x02\x07b\x03\x02\x02\x02\tf\x03" +
		"\x02\x02\x02\vk\x03\x02\x02\x02\rq\x03\x02\x02\x02\x0Ft\x03\x02\x02\x02" +
		"\x11|\x03\x02\x02\x02\x13\x86\x03\x02\x02\x02\x15\x8B\x03\x02\x02\x02" +
		"\x17\x8D\x03\x02\x02\x02\x19\x90\x03\x02\x02\x02\x1B\x92\x03\x02\x02\x02" +
		"\x1D\x95\x03\x02\x02\x02\x1F\x97\x03\x02\x02\x02!\x9A\x03\x02\x02\x02" +
		"#\x9C\x03\x02\x02\x02%\x9E\x03\x02\x02\x02'\xA0\x03\x02\x02\x02)\xA2" +
		"\x03\x02\x02\x02+\xA4\x03\x02\x02\x02-\xA6\x03\x02\x02\x02/\xAF\x03\x02" +
		"\x02\x021\xB8\x03\x02\x02\x023\xC1\x03\x02\x02\x025\xC4\x03\x02\x02\x02" +
		"7\xD3\x03\x02\x02\x029\xD7\x03\x02\x02\x02;\xDD\x03\x02\x02\x02=\xDF\x03" +
		"\x02\x02\x02?\xE1\x03\x02\x02\x02A\xE3\x03\x02\x02\x02C\xE5\x03\x02\x02" +
		"\x02E\xE7\x03\x02\x02\x02G\xE9\x03\x02\x02\x02I\xEB\x03\x02\x02\x02K\xED" +
		"\x03\x02\x02\x02M\xEF\x03\x02\x02\x02O\xF1\x03\x02\x02\x02Q\xF3\x03\x02" +
		"\x02\x02S\xF5\x03\x02\x02\x02U\xF7\x03\x02\x02\x02W\xF9\x03\x02\x02\x02" +
		"Y\xFB\x03\x02\x02\x02[\\\x05Q)\x02\\]\x05S*\x02]\x04\x03\x02\x02\x02^" +
		"_\x05;\x1E\x02_`\x05O(\x02`a\x05A!\x02a\x06\x03\x02\x02\x02bc\x05O(\x02" +
		"cd\x05Q)\x02de\x05U+\x02e\b\x03\x02\x02\x02fg\x05K&\x02gh\x05G$\x02hi" +
		'\x05I%\x02ij\x05C"\x02j\n\x03\x02\x02\x02kl\x05G$\x02lm\x05K&\x02mn\x05' +
		'G$\x02no\x05I%\x02op\x05C"\x02p\f\x03\x02\x02\x02qr\x05G$\x02rs\x05O' +
		'(\x02s\x0E\x03\x02\x02\x02tu\x05=\x1F\x02uv\x05C"\x02vw\x05U+\x02wx\x05' +
		'Y-\x02xy\x05C"\x02yz\x05C"\x02z{\x05O(\x02{\x10\x03\x02\x02\x02|}\x05' +
		"? \x02}~\x05G$\x02~\x7F\x05A!\x02\x7F\x80\x05S*\x02\x80\x81\x05M'\x02" +
		"\x81\x82\x05;\x1E\x02\x82\x83\x05U+\x02\x83\x84\x05? \x02\x84\x85\x05" +
		"E#\x02\x85\x12\x03\x02\x02\x02\x86\x87\x05O(\x02\x87\x88\x05W,\x02\x88" +
		"\x89\x05K&\x02\x89\x8A\x05K&\x02\x8A\x14\x03\x02\x02\x02\x8B\x8C\x07?" +
		"\x02\x02\x8C\x16\x03\x02\x02\x02\x8D\x8E\x07#\x02\x02\x8E\x8F\x07?\x02" +
		"\x02\x8F\x18\x03\x02\x02\x02\x90\x91\x07>\x02\x02\x91\x1A\x03\x02\x02" +
		"\x02\x92\x93\x07>\x02\x02\x93\x94\x07?\x02\x02\x94\x1C\x03\x02\x02\x02" +
		"\x95\x96\x07@\x02\x02\x96\x1E\x03\x02\x02\x02\x97\x98\x07@\x02\x02\x98" +
		'\x99\x07?\x02\x02\x99 \x03\x02\x02\x02\x9A\x9B\x07,\x02\x02\x9B"\x03' +
		"\x02\x02\x02\x9C\x9D\x07*\x02\x02\x9D$\x03\x02\x02\x02\x9E\x9F\x07+\x02" +
		"\x02\x9F&\x03\x02\x02\x02\xA0\xA1\x07.\x02\x02\xA1(\x03\x02\x02\x02\xA2" +
		"\xA3\x07$\x02\x02\xA3*\x03\x02\x02\x02\xA4\xA5\x07)\x02\x02\xA5,\x03\x02" +
		"\x02\x02\xA6\xAA\x05+\x16\x02\xA7\xA9\n\x02\x02\x02\xA8\xA7\x03\x02\x02" +
		"\x02\xA9\xAC\x03\x02\x02\x02\xAA\xA8\x03\x02\x02\x02\xAA\xAB\x03\x02\x02" +
		"\x02\xAB\xAD\x03\x02\x02\x02\xAC\xAA\x03\x02\x02\x02\xAD\xAE\x05+\x16" +
		"\x02\xAE.\x03\x02\x02\x02\xAF\xB3\x05)\x15\x02\xB0\xB2\n\x03\x02\x02\xB1" +
		"\xB0\x03\x02\x02\x02\xB2\xB5\x03\x02\x02\x02\xB3\xB1\x03\x02\x02\x02\xB3" +
		"\xB4\x03\x02\x02\x02\xB4\xB6\x03\x02\x02\x02\xB5\xB3\x03\x02\x02\x02\xB6" +
		"\xB7\x05)\x15\x02\xB70\x03\x02\x02\x02\xB8\xBC\t\x04\x02\x02\xB9\xBB\t" +
		"\x05\x02\x02\xBA\xB9\x03\x02\x02\x02\xBB\xBE\x03\x02\x02\x02\xBC\xBA\x03" +
		"\x02\x02\x02\xBC\xBD\x03\x02\x02\x02\xBD2\x03\x02\x02\x02\xBE\xBC\x03" +
		"\x02\x02\x02\xBF\xC2\x055\x1B\x02\xC0\xC2\x057\x1C\x02\xC1\xBF\x03\x02" +
		"\x02\x02\xC1\xC0\x03\x02\x02\x02\xC24\x03\x02\x02\x02\xC3\xC5\t\x06\x02" +
		"\x02\xC4\xC3\x03\x02\x02\x02\xC5\xC6\x03\x02\x02\x02\xC6\xC4\x03\x02\x02" +
		"\x02\xC6\xC7\x03\x02\x02\x02\xC7\xD0\x03\x02\x02\x02\xC8\xCA\x070\x02" +
		"\x02\xC9\xCB\t\x06\x02\x02\xCA\xC9\x03\x02\x02\x02\xCB\xCC\x03\x02\x02" +
		"\x02\xCC\xCA\x03\x02\x02\x02\xCC\xCD\x03\x02\x02\x02\xCD\xCF\x03\x02\x02" +
		"\x02\xCE\xC8\x03\x02\x02\x02\xCF\xD2\x03\x02\x02\x02\xD0\xCE\x03\x02\x02" +
		"\x02\xD0\xD1\x03\x02\x02\x02\xD16\x03\x02\x02\x02\xD2\xD0\x03\x02\x02" +
		"\x02\xD3\xD4\x07/\x02\x02\xD4\xD5\x055\x1B\x02\xD58\x03\x02\x02\x02\xD6" +
		"\xD8\t\x07\x02\x02\xD7\xD6\x03\x02\x02\x02\xD8\xD9\x03\x02\x02\x02\xD9" +
		"\xD7\x03\x02\x02\x02\xD9\xDA\x03\x02\x02\x02\xDA\xDB\x03\x02\x02\x02\xDB" +
		"\xDC\b\x1D\x02\x02\xDC:\x03\x02\x02\x02\xDD\xDE\t\b\x02\x02\xDE<\x03\x02" +
		"\x02\x02\xDF\xE0\t\t\x02\x02\xE0>\x03\x02\x02\x02\xE1\xE2\t\n\x02\x02" +
		"\xE2@\x03\x02\x02\x02\xE3\xE4\t\v\x02\x02\xE4B\x03\x02\x02\x02\xE5\xE6" +
		"\t\f\x02\x02\xE6D\x03\x02\x02\x02\xE7\xE8\t\r\x02\x02\xE8F\x03\x02\x02" +
		"\x02\xE9\xEA\t\x0E\x02\x02\xEAH\x03\x02\x02\x02\xEB\xEC\t\x0F\x02\x02" +
		"\xECJ\x03\x02\x02\x02\xED\xEE\t\x10\x02\x02\xEEL\x03\x02\x02\x02\xEF\xF0" +
		"\t\x11\x02\x02\xF0N\x03\x02\x02\x02\xF1\xF2\t\x12\x02\x02\xF2P\x03\x02" +
		"\x02\x02\xF3\xF4\t\x13\x02\x02\xF4R\x03\x02\x02\x02\xF5\xF6\t\x14\x02" +
		"\x02\xF6T\x03\x02\x02\x02\xF7\xF8\t\x15\x02\x02\xF8V\x03\x02\x02\x02\xF9" +
		"\xFA\t\x16\x02\x02\xFAX\x03\x02\x02\x02\xFB\xFC\t\x17\x02\x02\xFCZ\x03" +
		"\x02\x02\x02\v\x02\xAA\xB3\xBC\xC1\xC6\xCC\xD0\xD9\x03\b\x02\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!ctqlLexer.__ATN) {
			ctqlLexer.__ATN = new ATNDeserializer().deserialize(
				Utils.toCharArray(ctqlLexer._serializedATN)
			);
		}

		return ctqlLexer.__ATN;
	}
}

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
	Alert,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	IconButton,
	Paper,
	PaperProps,
	Stack,
	Tooltip,
	Typography,
	styled,
	useTheme,
} from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { AnalyticsAPIReq, AnalyticsResponse } from "common/types/types";
import { getSegmentsNameFromCriteria } from "common/utils/getSegmentsNameFromCriteria";
import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { ListTable as TemplateListTable } from "modules/add-to-templates/components/list-table";
import { ListTable as NetworkListTable } from "modules/assign-networks-to-assets/components/list-table";
import { useCore } from "modules/core";
import { useCriteriaBuilder } from "modules/core/Core";
import { FacetGroupInfo, SortOrder } from "modules/core/types";
import { decodeThrowable } from "modules/facets/hooks/useFacetQueryConnector";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import numeral from "numeral";
import { NETWORK_LIST_COLUMNS } from "pages/networks/components/network-data-grid/constants";
import { Network } from "pages/networks/types";
import { useTagPolicyListAPI } from "pages/tags/components/tag-policy-list/TagPolicyList";
import { BreachImpactMetrics } from "pages/tags/components/tag-policy-list/components/breach-impact-metrics/BreachImpactMetrics";
import { TagNetworks } from "pages/tags/components/tag-policy-list/components/tag-networks";
import { TagTemplates } from "pages/tags/components/tag-policy-list/components/tag-templates";
import {
	useTagPolicyFacetStore,
	useTagPolicyStore,
} from "pages/tags/components/tag-policy-list/store";
import {
	TagPolicy,
	TagPolicyInput,
} from "pages/tags/components/tag-policy-list/types";
import { TAG_TEMPLATES_COLUMNS } from "pages/templates/components/template-data-grid/constants";
import { Template } from "pages/templates/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { PolicySelectionForm } from "./PolicySelectionForm";

export enum SegmentModeType {
	Clone = "Clone",
	Edit = "Edit",
	Create = "Create",
}

export function useTagPolicyApi({ tagBasedPolicyId }: any) {
	return useQuery<TagPolicy, Error>(
		["tag=policy", `tagbasedpolicies/${tagBasedPolicyId}`],
		{
			staleTime: 10000,
			enabled: Boolean(tagBasedPolicyId),
		}
	);
}

export function useSaveTagPolicyAPI() {
	return useMutation<
		TagPolicy,
		Error,
		Omit<TagPolicy, "tagBasedPolicyId">,
		any
	>(["tag-policy", "tagbasedpolicies"]);
}

export function useSaveTagPolicyInputAPI() {
	return useMutation<
		TagPolicyInput,
		Error,
		Omit<TagPolicyInput, "tagBasedPolicyId">,
		any
	>(["tag-policy", "tagbasedpolicies"]);
}

export function useEditTagPolicyAPI(savedPolicyId: string | undefined) {
	return useMutation<
		any,
		Error,
		Omit<TagPolicyInput, "tagBasedPolicyId" | "criteria">
	>(["tag-policy", `tagbasedpolicies/${savedPolicyId}`, "PUT"]);
}
export function useCloneTagPolicyAPI() {
	return useMutation<any, Error, Omit<TagPolicyInput, "tagBasedPolicyId">>([
		"tag-policy-clone",
		"tagbasedpolicies/clone",
	]);
}

export const FormSectionWrapper = styled((props: PaperProps) => (
	<Paper {...props} />
))(({ theme }) => ({
	"&": {
		backgroundColor:
			theme.palette.mode === "dark"
				? theme.palette.common.black
				: theme.palette.background.default,
		minWidth: "100%",
		padding: "12px 18px",
	},
}));

function useAssetAggregateAPI() {
	return useMutation<AnalyticsResponse, Error, AnalyticsAPIReq>([
		"asset-aggregate",
		"aggregate",
	]);
}

export function PolicyForm({ facetOrder }: { facetOrder: FacetGroupInfo }) {
	const { isFeatureEnabled } = useFeatureFlagControl(FEATURES.PROGRESSIVE);
	const isProgressiveEnabled = isFeatureEnabled;
	const { tagBasedPolicyId } = useParams();
	const { pathname } = useLocation();
	let { data: savedPolicy } = useTagPolicyApi({ tagBasedPolicyId });
	const notify = useEventSubscriptionStore(state => state.notify);
	const [urlParams, setURLParams] = useSearchParams();
	const [description, setDescription] = useState<string>("");
	const [name, setName] = useState<string>("");
	const [selectedTemplateList, setSelectedTemplateList] = useState<
		Array<Template>
	>([]);
	const [selectedNetworkList, setSelectedNetworkList] = useState<
		Array<Network>
	>([]);
	const setQuery = useRef(setURLParams);
	setQuery.current = setURLParams;
	const setFilters = useTagPolicyFacetStore(state => state.setFacets);
	const [isTemplateCloneChecked, setIsTemplateCloneChecked] =
		useState<boolean>(false);
	const [isNNCloneChecked, setIsNNCloneChecked] = useState<boolean>(false);
	const [isProgressiveCloneChecked, setIsProgressiveCloneChecked] =
		useState<boolean>(false);
	const [isAccessPoliciesChecked, setIsAccessPoliciesChecked] =
		useState<boolean>(false);
	const [isPolicyAutomationCloneChecked, setIsPolicyAutomationCloneChecked] =
		useState<boolean>(false);
	const [breachImpactTargetScore, setBreachImpactTargetScore] = useState<
		number | undefined
	>();
	const [timeline, setTimeline] = useState<number>(90);
	const [breachImpactScoreValue, setBreachImpactScoreValue] = useState<
		number | undefined
	>();

	let segmentMode = SegmentModeType.Create;

	if (pathname.includes(SegmentModeType.Clone.toLowerCase())) {
		segmentMode = SegmentModeType.Clone;
	} else if (pathname.includes(SegmentModeType.Edit.toLowerCase())) {
		segmentMode = SegmentModeType.Edit;
	}

	useEffect(() => {
		setFilters(undefined);
		return () => {
			setFilters(undefined);
		};
	}, [setFilters]);

	useEffect(() => {
		// append existing search params with tab=policy
		const criteriaAsParams = new URLSearchParams(urlParams);
		if (criteriaAsParams.get("tab") === "policy") {
			return;
		}
		setURLParams(criteriaAsParams);
	}, [setURLParams, urlParams]);

	useEffect(() => {
		if (!savedPolicy) {
			return;
		}

		if (savedPolicy?.criteriaAsParams) {
			setQuery.current(savedPolicy?.criteriaAsParams);
		}

		if (savedPolicy?.description) {
			setDescription(
				segmentMode === SegmentModeType.Clone
					? `${savedPolicy?.description} ${window.getCTTranslatedText(
							"(copy)"
						)}`
					: savedPolicy?.description
			);
		}

		if (savedPolicy?.targetBreachImpactScore) {
			setBreachImpactTargetScore(savedPolicy?.targetBreachImpactScore);
		}

		if (savedPolicy?.timeline) {
			setTimeline(savedPolicy?.timeline);
		}

		if (savedPolicy?.tagBasedPolicyName) {
			setName(
				segmentMode === SegmentModeType.Clone
					? `${savedPolicy?.tagBasedPolicyName} ${window.getCTTranslatedText(
							"(copy)"
						)}`
					: savedPolicy?.tagBasedPolicyName
			);
		}

		const urlParams = new URLSearchParams(savedPolicy?.criteriaAsParams);

		const filters = decodeThrowable(urlParams.get("filters"));
		setFilters(filters);
	}, [savedPolicy, setFilters, segmentMode]);

	useEffect(() => {
		if (!isAccessPoliciesChecked && !isTemplateCloneChecked) {
			setSelectedTemplateList([]);
		} else if (isAccessPoliciesChecked && isTemplateCloneChecked) {
			setSelectedTemplateList(savedPolicy?.templates ?? []);
		} else if (isTemplateCloneChecked) {
			setSelectedTemplateList(
				savedPolicy?.templates?.filter(temp => !temp.accessPolicyTemplate) ?? []
			);
		} else if (isAccessPoliciesChecked) {
			setSelectedTemplateList(
				savedPolicy?.templates?.filter(temp => temp.accessPolicyTemplate) ?? []
			);
		}

		if (isNNCloneChecked) {
			setSelectedNetworkList(savedPolicy?.namednetworks ?? []);
		} else {
			setSelectedNetworkList([]);
		}
	}, [
		savedPolicy,
		isNNCloneChecked,
		isTemplateCloneChecked,
		isAccessPoliciesChecked,
	]);

	const saveMutation = useSaveTagPolicyInputAPI();
	const editMutation = useEditTagPolicyAPI(savedPolicy?.tagBasedPolicyId);
	const cloneMutation = useCloneTagPolicyAPI();

	const facetState = useTagPolicyFacetStore(state => state.facets);

	const metadata = useTagPolicyFacetStore(state => state.metadata);

	const coreResponse = useCore<Scope.TagPolicy>({
		useStore: useTagPolicyStore,
		facetGroupInfo: facetOrder,
		scope: Scope.TagPolicy,
		useFacetStore: useTagPolicyFacetStore,
		useApi: useTagPolicyListAPI,
		pageSize: 1,
	});

	let searchCriteria = useCriteriaBuilder("", facetState, metadata);

	if (searchCriteria === "*") {
		searchCriteria = "";
	}

	const assetAggMutation = useAssetAggregateAPI();
	const assetAggMutate = assetAggMutation.mutate;
	const {
		data: assetAgg,
		isLoading: isLoadingAssetAgg,
		reset: resetAggCount,
	} = assetAggMutation;

	const assetAggCount: number = useMemo<number>(() => {
		return Number(assetAgg?.items?.statistics?.assetidcount ?? 0);
	}, [assetAgg]);

	useEffect(() => {
		const criteria = searchCriteria;
		if (!criteria) {
			resetAggCount();
			return;
		}
		assetAggMutate({
			criteria,
			scope: Scope.Asset,
			statistics: ["count(assetid)"],
		});
	}, [assetAggMutate, resetAggCount, searchCriteria]);

	const navigate = useNavigate();

	const reset = () => {
		setName("");
		setDescription("");
		setSelectedTemplateList([]);
		setSelectedNetworkList([]);
	};

	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const saveTagPolicy = () => {
		if (isFormInvalid()) {
			return;
		}

		let criteriaAsParams = new URLSearchParams(urlParams);
		criteriaAsParams.delete("tab");

		let body: Omit<any, "tagBasedPolicyId"> = {
			criteria: searchCriteria ?? "",
			criteriaAsParams: criteriaAsParams.toString(),
			templates:
				selectedTemplateList.map((template: Template) => template.templateId) ??
				[],
			namednetworks:
				selectedNetworkList.map((network: Network) => network.namedNetworkId) ??
				[],
			description: description,
			tagBasedPolicyName: name,
			targetBreachImpactScore: breachImpactTargetScore,
			timeline: timeline,
		};

		if (segmentMode === SegmentModeType.Edit) {
			delete body["criteria"];
			delete body["criteriaAsParams"];
			delete body["templates"];
			delete body["namednetworks"];
		}

		if (segmentMode === SegmentModeType.Clone) {
			delete body["templates"];
			delete body["namednetworks"];
			delete body["criteria"];
			delete body["criteriaAsParams"];
			body["cloneOptions"] = {
				namednetworks: isNNCloneChecked,
				templates: isTemplateCloneChecked,
				automation: isPolicyAutomationCloneChecked,
				accessPolicies: isAccessPoliciesChecked,
				progressive: isProgressiveEnabled
					? isProgressiveCloneChecked
					: undefined,
			};
			body["srcTagBasedPolicyID"] = tagBasedPolicyId;
			body["revisedCriteria"] = searchCriteria ?? "";
			body["revisedCriteriaAsParams"] = criteriaAsParams.toString();
		}

		let mutation = saveMutation;

		if (segmentMode === SegmentModeType.Clone) {
			mutation = cloneMutation;
		}

		if (segmentMode === SegmentModeType.Edit) {
			mutation = editMutation;
		}

		mutation.mutateAsync(body, {
			onSuccess: () => {
				notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
					label: "SegmentRequestSubmittedSuccessfully",
					variables: {
						action: window.getCTTranslatedText(
							segmentMode === SegmentModeType.Edit ? "Update" : "Create"
						),
					},
				});
				setTimeout(() => {
					reset();
					navigate("/segment");
				}, 200);
			},
			onError: error => {
				let message = "SomethingWentWrong";
				// @ts-ignore: Axios error type
				if (error?.response?.status === 409) {
					message = "ASimilarPolicyAlreadyExists";
				}
				setSnackbar(true, SnackBarSeverity.Error, message);
			},
		});
	};

	useEffect(() => {
		if (isTemplateCloneChecked === false) {
			setIsPolicyAutomationCloneChecked(false);
		}
	}, [isTemplateCloneChecked]);

	const handlerSetTemplateClone = () => {
		setIsTemplateCloneChecked(!isTemplateCloneChecked);
	};

	const handlerSetNamedNetwork = () => {
		setIsNNCloneChecked(!isNNCloneChecked);
	};

	const handlerSetProgressiveClone = () => {
		setIsProgressiveCloneChecked(!isProgressiveCloneChecked);
	};

	const handlerSetPolicyAutomation = () => {
		if (isTemplateCloneChecked) {
			setIsPolicyAutomationCloneChecked(!isPolicyAutomationCloneChecked);
		}
	};

	const handlerSetAccessPolicy = () => {
		setIsAccessPoliciesChecked(!isAccessPoliciesChecked);
	};

	const urlSearchParams = new URLSearchParams(savedPolicy?.criteriaAsParams);
	const filters = decodeThrowable(urlSearchParams.get("filters"));
	let searchCriteriaURLParams = useCriteriaBuilder("", filters, metadata);

	const isFormInvalid = () => {
		const searchStr = searchCriteria?.trim();
		const isTargetInvalid =
			typeof breachImpactTargetScore !== "number" ||
			breachImpactTargetScore < 0 ||
			breachImpactTargetScore > 100;

		if (
			!searchStr ||
			searchStr === "*" ||
			searchStr === "" ||
			(segmentMode === SegmentModeType.Clone &&
				searchStr === searchCriteriaURLParams) ||
			!timeline ||
			isTargetInvalid
		) {
			return true;
		}
		return false;
	};

	const onSelectTemplates = useCallback(
		(templates: Array<Template>) => {
			let newTemplateList = getUniqueListBy(
				[...selectedTemplateList, ...templates],
				"templateId"
			);
			setSelectedTemplateList(newTemplateList);
		},
		[selectedTemplateList]
	);

	const onSelectNetworks = useCallback(
		(networks: Array<Network>) => {
			let newNetworkList = getUniqueListBy(
				[...selectedNetworkList, ...networks],
				"namedNetworkId"
			);
			setSelectedNetworkList(newNetworkList);
		},
		[selectedNetworkList]
	);

	const removeTemplate = (selTemplate: any) => {
		const selectedTemplate = selTemplate as Template;
		let newTemplateList = [...selectedTemplateList];
		newTemplateList = newTemplateList.filter((template: Template) => {
			return template.templateId !== selectedTemplate?.templateId;
		});
		setSelectedTemplateList(newTemplateList);
	};

	const removeNetwork = (selNetwork: any) => {
		const selectedNetwork = selNetwork as Network;
		let newNetworkList = [...selectedNetworkList];
		newNetworkList = newNetworkList.filter((network: Network) => {
			return network.namedNetworkId !== selectedNetwork.namedNetworkId;
		});
		setSelectedNetworkList(newNetworkList);
	};

	const [isNameFieldDirty, setIsNameFieldDirty] = useState(false);

	useEffect(() => {
		if (facetState && !isNameFieldDirty && !savedPolicy?.tagBasedPolicyName) {
			const name = getSegmentsNameFromCriteria(facetState);
			setName(name);
		}
	}, [facetState, isNameFieldDirty, savedPolicy?.tagBasedPolicyName]);

	const updateName = useCallback((event: TextFieldUpdate) => {
		setIsNameFieldDirty(true);
		setName(event?.value);
	}, []);

	const onSortChange = useCallback((sort: Array<SortOrder>) => {}, []);
	return (
		<Stack
			component={Paper}
			p={3}
			spacing={4}
			alignItems={"flex-end"}
			sx={{ width: "100%" }}
		>
			<Stack alignItems={"flex-start"} sx={{ width: "100%" }}>
				<Typography variant="h6">
					{`${window.getCTTranslatedText(
						segmentMode
					)} ${window.getCTTranslatedText("Segment")}`}
				</Typography>
			</Stack>
			<Stack direction={"column"} spacing={4} sx={{ width: "100%" }}>
				<Stack spacing={6} direction="row">
					<Stack sx={{ width: "50%" }}>
						<CTInputField
							field="name"
							displayName="Name"
							value={name ?? ""}
							handleUpdate={updateName}
						/>
					</Stack>
					<Stack sx={{ width: "50%" }}>
						<CTInputField
							field="description"
							displayName="Description"
							value={description ?? ""}
							handleUpdate={(event: TextFieldUpdate) =>
								setDescription(event?.value)
							}
						/>
					</Stack>
				</Stack>

				<FormSectionWrapper>
					<PolicySelectionForm
						coreResponse={coreResponse}
						useFacetStore={useTagPolicyFacetStore}
						viewOnly={segmentMode === SegmentModeType.Edit}
						hideSavedQuery
						title="Criteria"
						addCriteriaBtnTitle="Add criteria"
						addCriteriaDialogTitle="Create Criteria"
					/>
					<Stack
						alignItems={"flex-end"}
						sx={{
							marginTop: 1,
							display: Boolean(searchCriteria) ? undefined : "none",
						}}
					>
						{isLoadingAssetAgg ? (
							<CircularProgress size={18} />
						) : (
							<Typography variant="overline">{`${numeral(assetAggCount).format(
								"0a"
							)} ${window.getCTTranslatedText("matching assets")}`}</Typography>
						)}
					</Stack>
				</FormSectionWrapper>

				{(isTemplateCloneChecked ||
					isAccessPoliciesChecked ||
					segmentMode === SegmentModeType.Create) && (
					<FormSectionWrapper>
						<TagTemplates
							criteria={searchCriteria ?? "*"}
							multiselect={true}
							onSelectCallback={onSelectTemplates}
							btnTitle="Assign Templates"
							canAssign={segmentMode === SegmentModeType.Create}
						/>

						<SelectedList
							selectedList={selectedTemplateList}
							removeCallback={removeTemplate}
							columns={TAG_TEMPLATES_COLUMNS}
							type={"template"}
							onSortChange={onSortChange}
							isActionDisabled={segmentMode === SegmentModeType.Clone}
						/>
					</FormSectionWrapper>
				)}

				{(isNNCloneChecked || segmentMode === SegmentModeType.Create) && (
					<FormSectionWrapper>
						<TagNetworks
							criteria={searchCriteria ?? "*"}
							multiselect={true}
							onSelectCallback={onSelectNetworks}
							btnTitle="Assign Named Networks"
							canAssign={segmentMode === SegmentModeType.Create}
						/>

						<SelectedList
							selectedList={selectedNetworkList}
							removeCallback={removeNetwork}
							columns={NETWORK_LIST_COLUMNS}
							type={"named network"}
							onSortChange={onSortChange}
							isActionDisabled={segmentMode === SegmentModeType.Clone}
						/>
					</FormSectionWrapper>
				)}

				<BreachImpactMetrics
					target={breachImpactTargetScore}
					criteria={searchCriteria}
					timeline={timeline}
					baseline={breachImpactScoreValue}
					setTarget={setBreachImpactTargetScore}
					setBaseline={setBreachImpactScoreValue}
					setTimeline={setTimeline}
					savedPolicy={savedPolicy}
					mode={segmentMode}
				/>

				{segmentMode === SegmentModeType.Clone && (
					<>
						<Typography sx={{ mt: 2 }} variant="subtitle2">
							{" "}
							{window.getCTTranslatedText("Options")}
						</Typography>
						<FormGroup sx={{ mt: "8px !important" }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={isTemplateCloneChecked}
										onChange={handlerSetTemplateClone}
									/>
								}
								label={window.getCTTranslatedText("Templates")}
							/>

							<FormControlLabel
								control={
									<Checkbox
										checked={isAccessPoliciesChecked}
										onChange={handlerSetAccessPolicy}
									/>
								}
								label={window.getCTTranslatedText("Access policies")}
							/>

							<FormControlLabel
								control={
									<Checkbox
										checked={isNNCloneChecked}
										onChange={handlerSetNamedNetwork}
									/>
								}
								label={window.getCTTranslatedText("Named networks")}
							/>
							{isProgressiveEnabled && (
								<FormControlLabel
									control={
										<Checkbox
											checked={isProgressiveCloneChecked}
											onChange={handlerSetProgressiveClone}
										/>
									}
									label={window.getCTTranslatedText("progressive")}
								/>
							)}

							<FormControlLabel
								disabled={!isTemplateCloneChecked}
								control={
									<Checkbox
										checked={isPolicyAutomationCloneChecked}
										onChange={handlerSetPolicyAutomation}
									/>
								}
								label={window.getCTTranslatedText("Policy automation")}
							/>
						</FormGroup>
					</>
				)}
			</Stack>

			<Stack
				direction={"row"}
				alignItems={"center"}
				justifyContent={"flex-end"}
				sx={{ width: "100%" }}
			>
				{searchCriteria?.trim() === searchCriteriaURLParams &&
					segmentMode === SegmentModeType.Clone && (
						<Alert sx={{ flex: 1, mr: 2 }} severity={"warning"}>
							{window.getCTTranslatedText("CloningSegmentAlertMessage")}
						</Alert>
					)}

				<Button
					variant="contained"
					color="primary"
					disabled={isFormInvalid()}
					onClick={() => saveTagPolicy()}
				>
					{window.getCTTranslatedText(segmentMode ? "Save" : "Create")}
				</Button>
			</Stack>
		</Stack>
	);
}

interface SelectedListProps {
	selectedList: Array<Template | Network>;
	removeCallback: (item: Template | Network) => void;
	columns: GridColDef[];
	type: string;
	onSortChange: (sort: Array<SortOrder>) => void;
	isActionDisabled: boolean;
}

const SelectedList = ({
	selectedList,
	removeCallback,
	columns,
	type,
	onSortChange,
	isActionDisabled,
}: SelectedListProps) => {
	const theme = useTheme();
	const tableColumns = useMemo(() => {
		return isActionDisabled
			? columns
			: [
					...columns,
					{
						field: "action",
						headerName: "",
						sortable: false,
						width: 60,
						renderCell: (params: GridRenderCellParams<any, any, any>) => {
							return (
								<>
									<IconButton
										size="medium"
										aria-label={type}
										onClick={() => removeCallback(params?.row)}
									>
										<Tooltip title={window.getCTTranslatedText(type)}>
											<RemoveCircleOutlineIcon fontSize="small" />
										</Tooltip>
									</IconButton>
								</>
							);
						},
					},
				];
	}, [columns, isActionDisabled, removeCallback, type]);

	return (
		<>
			{Boolean(selectedList?.length > 0) && (
				<Paper
					sx={{ mt: 2, width: "100%" }}
					elevation={theme.palette.mode === "dark" ? 3 : 0}
				>
					<Stack alignItems="flex-start" sx={{ width: "100%" }}>
						{type === "template" ? (
							<TemplateListTable
								list={selectedList as Array<Template>}
								columns={tableColumns}
								mutation={undefined}
								rowClickCallback={() => {}}
								multiselect={false}
								expanderPosition="start"
								onSortChange={onSortChange}
								sorting={false}
								autoHeight={true}
							/>
						) : (
							<NetworkListTable
								list={selectedList as Array<Network>}
								columns={tableColumns}
								mutation={undefined}
								rowClickCallback={() => {}}
								multiselect={false}
								expanderPosition="start"
								onSortChange={onSortChange}
								sorting={false}
								autoHeight={true}
							/>
						)}
					</Stack>
				</Paper>
			)}
		</>
	);
};

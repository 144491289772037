import { Box, Link, Stack, Tooltip } from "@mui/material";
import { OVERWRITE_FACETS } from "modules/facets/constants";
import numeral from "numeral";
import { Link as RouterLink } from "react-router-dom";
import { TagPolicy } from "../../types";

export function MatchedAssetCount({ policy }: { policy: TagPolicy }) {
	if (!policy.tagBasedPolicyId) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="flex-start">
			<Stack alignItems="flex-start">
				<Tooltip title={window.getCTTranslatedText("View assets")}>
					<Link
						typography={"body2"}
						component={RouterLink}
						to={`/assets?${policy.criteriaAsParams}&${OVERWRITE_FACETS}`}
						sx={{
							color: "primary.main",
							textDecoration: "none",
						}}
					>
						{numeral(policy?.matchingAssets ?? 0).format("0a")}
					</Link>
				</Tooltip>
			</Stack>
		</Stack>
	);
}

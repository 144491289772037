import { Box, Stack, SvgIconProps, Tooltip, useTheme } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import { ApplianceColumnRenderProps, HaRole } from "pages/appliances/types";
import { AgentStatusType } from "pages/agents/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function AgentStatus({ agent, viewOnly }: ApplianceColumnRenderProps) {
	const theme = useTheme();

	if (!agent?.agentId) {
		return <Box style={{ width: 120 }} />;
	}

	let agentStatusTooltipText: string = "";

	if (agent?.haRole) {
		switch (agent?.haRole) {
			case HaRole.Standby:
				agentStatusTooltipText = window.getCTTranslatedText(HaRole.Standby);
				break;
			case HaRole.Primary:
				agentStatusTooltipText = window.getCTTranslatedText(HaRole.Primary);
				break;
		}
	}

	let icon: React.ReactElement<SvgIconProps> | undefined = (
		<Tooltip title={agentStatusTooltipText} placement="top">
			<CircleIcon color={"disabled"} sx={{ mr: 1 }} />
		</Tooltip>
	);

	const autoUpgradeOnIcon: React.ReactElement<SvgIconProps> | undefined =
		agent?.autoUpgradeEnabled &&
		(agent?.agentStatus === AgentStatusType.Active ||
			agent?.agentStatus === AgentStatusType.Absent) ? (
			<Tooltip
				title={window.getCTTranslatedText("Autoupgrade On")}
				placement="top"
			>
				<AutoModeIcon
					color={
						agent.agentStatus === AgentStatusType.Active
							? "success"
							: "disabled"
					}
					sx={{ ml: 1 }}
				/>
			</Tooltip>
		) : undefined;

	let text = window.getCTTranslatedText(AgentStatusType.Absent);

	if (agent?.agentStatus === AgentStatusType.Active) {
		icon = (
			<Tooltip title={agentStatusTooltipText} placement="top">
				{agent?.haRole === HaRole.Standby ? (
					<CircleIcon
						sx={{
							color: theme.palette.custom.lightGreen,
							mr: 1,
						}}
					/>
				) : (
					<CircleIcon color="success" sx={{ mr: 1 }} />
				)}
			</Tooltip>
		);
		text =
			agent?.haRole === HaRole.Standby
				? window.getCTTranslatedText(HaRole.Standby)
				: window.getCTTranslatedText(AgentStatusType.Active);
	}

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
			<CTDatagridButton
				startIcon={icon}
				endIcon={autoUpgradeOnIcon}
				asText={viewOnly === true}
				isDisabled={viewOnly === true}
				facetName={"agentstatus"}
				facetOption={agent.agentStatus ?? ""}
			>
				{text || ""}
			</CTDatagridButton>
		</Stack>
	);
}

import { getLastOneHourCriteria } from "modules/AppBar/helpers/getLastOneHourCriteria";
import { EXCLUDED_WORK_REQUEST_ACTIONS } from "modules/PendingWorkRequestsDrawer/types";

export const getWRAdditionalCriteria = () => {
	return (
		getLastOneHourCriteria() +
		` AND 'workrequestaction' NOT IN ('${EXCLUDED_WORK_REQUEST_ACTIONS.join(
			"', '"
		)}') AND 'actoremail' NOT IN ('System', 'NULL')`
	);
};

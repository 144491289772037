import { Box, Stack } from "@mui/system";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { Container } from "pages/containers/types";
import React, { useCallback } from "react";
import { ContainersToolbar } from "../containers-data-grid-toolbar";
import { BASE_CONTAINERS_COLUMNS } from "./constants";

export const ContainerDataGrid = (props: DataGridProps<Container>) => {
	const hideToolbar = (): boolean => false;

	const renderToolbar = useCallback(
		(children?: React.ReactNode) => (
			<ContainersToolbar
				show={true}
				hideToolbar={hideToolbar}
				hiddenColumns={children}
			/>
		),
		[]
	);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					rowHeight={64}
					columns={BASE_CONTAINERS_COLUMNS}
					pagination
					getRowId={({ agentId }: Container) => `${agentId}`}
					paginationMode="server"
					sortingMode="server"
					{...props}
					renderToolbar={renderToolbar}
				/>
			</Box>
		</Stack>
	);
};

import { Link, Stack, Tooltip, Typography } from "@mui/material";
import numeral from "numeral";
import { Link as RouterLink } from "react-router-dom";
import { UsersColumnRenderProps } from "../../types";

export const UserAssetCount = ({
	user,
	viewOnly = false,
}: UsersColumnRenderProps) => {
	const assetCount = numeral(user?.assets ?? 0).format("0a");
	return (
		<Stack direction={"row"} spacing={1} flex={1} alignItems={"center"}>
			{viewOnly || user?.assets === 0 ? (
				<Typography variant="body2">{assetCount} </Typography>
			) : (
				<Tooltip title={window.getCTTranslatedText("View assets")}>
					<Link
						typography="body2"
						component={RouterLink}
						to={`/assets?filters=${encodeURIComponent(
							`useremail=${user.userEmail}`
						)}`}
						sx={{ color: "primary.main" }}
					>
						{assetCount}{" "}
					</Link>
				</Tooltip>
			)}
		</Stack>
	);
};

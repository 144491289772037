import { Box, Link, Stack, Typography } from "@mui/material";
import { TryDemo } from "assets/svgs";
import { InstallAgent } from "../../../../assets/svgs/InstallAgent";
import Button from "@mui/material/Button";
import { useDemoController } from "hooks/useDemoController";
import { Link as RouterLink } from "react-router-dom";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { MouseEvent } from "react";

export function WelcomeScreen() {
	const { handleDemoClick } = useDemoController();
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const createDepKeyPerm = userPermissions.has("CREATE_DEPLOYMENT_KEY");

	const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
		if (!createDepKeyPerm) {
			event.preventDefault();
		}
	};

	return (
		<Stack
			spacing={12}
			alignItems={"center"}
			justifyContent="center"
			sx={{ height: "100%", width: "100%" }}
		>
			<Typography
				variant="h4"
				sx={{
					fontWeight: 700,
				}}
			>
				{window.getCTTranslatedText("Welcome")}
			</Typography>

			<Stack
				direction={"row"}
				sx={{ width: "100%" }}
				justifyContent="space-evenly"
			>
				<Box sx={{ textAlign: "center" }}>
					<Box>
						<TryDemo width={"300px"} height={"220px"} />
					</Box>
					<Button variant="outlined" color="error" onClick={handleDemoClick}>
						{window.getCTTranslatedText("Try Demo")}
					</Button>
				</Box>

				<Box sx={{ textAlign: "center" }}>
					<Box>
						<InstallAgent width={"300px"} height={"220px"} />
					</Box>
					<Link
						to={"/agents/install-agents"}
						component={RouterLink}
						underline="none"
						onClick={handleLinkClick}
						sx={{ pointerEvents: !createDepKeyPerm ? "none" : undefined }}
					>
						<Button
							variant="contained"
							color="error"
							disabled={!createDepKeyPerm}
						>
							{window.getCTTranslatedText("Install Agent")}
						</Button>
					</Link>
				</Box>
			</Stack>
		</Stack>
	);
}

import { Grid, Tab } from "@mui/material";
import { CtTabs } from "common/atoms/ct-tabs";
import { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	CROWDSTRIKE_GROUPS_TABS,
	CrowdStrikeGroupsTabValue,
} from "./constants";

export function CrowdStrikeGroups() {
	const TAB_ENTRIES = Object.entries(CROWDSTRIKE_GROUPS_TABS);

	const navigate = useNavigate();
	const location = useLocation();
	const [selectedTab, setSelectedTab] = useState(
		CrowdStrikeGroupsTabValue.HostGroups
	);

	useLayoutEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const tabParam = urlParams.get("tab");
		let tab = CrowdStrikeGroupsTabValue.HostGroups;

		if (
			tabParam ===
			CROWDSTRIKE_GROUPS_TABS[CrowdStrikeGroupsTabValue.FirewallHostGroups]
				.urlQuery
		) {
			tab = CrowdStrikeGroupsTabValue.FirewallHostGroups;
		}

		setSelectedTab(tab);
	}, [location.search, setSelectedTab]);

	const handleTabChange = (
		_: React.SyntheticEvent,
		newValue: CrowdStrikeGroupsTabValue
	) => {
		setSelectedTab(newValue);
		const relativePath = `?tab=${CROWDSTRIKE_GROUPS_TABS[newValue].urlQuery}`;
		navigate(relativePath, { relative: "route" });
	};

	return (
		<Grid
			container
			rowSpacing={4}
			sx={{
				height: "100%",
				position: "relative",
				"& > .MuiGrid-item.MuiGrid-grid-xs-6": { height: "max-content" },
				"& > .MuiGrid-item.MuiGrid-grid-xs-12": { height: "100%" },
			}}
		>
			<Grid item xs={6}>
				<CtTabs value={selectedTab} onChange={handleTabChange}>
					{TAB_ENTRIES.map(([key, tab]) => (
						<Tab key={key} label={window.getCTTranslatedText(tab.label)} />
					))}
				</CtTabs>
			</Grid>
			{CROWDSTRIKE_GROUPS_TABS[selectedTab].component}
		</Grid>
	);
}

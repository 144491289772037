import { ARCHITECTURE_TYPE } from "pages/agents/components/agent-download-page/constants";
import { AgentVersionDetails } from "pages/agents/components/agent-download-page/type";

export function getLatestVersion(
	agentVersionDetails: AgentVersionDetails,
	platform: string,
	agentType: string
) {
	let agentVdetails = agentVersionDetails[
		platform as keyof AgentVersionDetails
	]?.versionsInfo.filter(d => d.agentType === agentType);

	let arm64LatestVersion = agentVdetails?.filter(
		arch => arch.architecture === ARCHITECTURE_TYPE.ARM64
	);
	let x8664LatestVersion = agentVdetails?.filter(
		arch => arch.architecture === ARCHITECTURE_TYPE.X86_64
	);
	let ppcLatestVersion = agentVdetails?.filter(
		arch => arch.architecture === ARCHITECTURE_TYPE.PPC
	);

	if (
		arm64LatestVersion[0]?.version &&
		!arm64LatestVersion[0]?.version.includes(
			`${window.getCTTranslatedText("Latest Version")} (${
				arm64LatestVersion[0]?.version
			})`
		)
	) {
		arm64LatestVersion[0].version = `${window.getCTTranslatedText(
			"Latest Version"
		)} (${arm64LatestVersion[0]?.version})`;
	}

	if (
		x8664LatestVersion[0]?.version &&
		!x8664LatestVersion[0]?.version.includes(
			`${window.getCTTranslatedText("Latest Version")} (${
				x8664LatestVersion[0]?.version
			})`
		)
	) {
		x8664LatestVersion[0].version = `${window.getCTTranslatedText(
			"Latest Version"
		)} (${x8664LatestVersion[0]?.version})`;
	}

	if (
		ppcLatestVersion[0]?.version &&
		!ppcLatestVersion[0]?.version.includes(
			`${window.getCTTranslatedText("Latest Version")} (${
				ppcLatestVersion[0]?.version
			})`
		)
	) {
		ppcLatestVersion[0].version = `${window.getCTTranslatedText(
			"Latest Version"
		)} (${ppcLatestVersion[0]?.version})`;
	}

	let agentDetails = [
		...arm64LatestVersion,
		...x8664LatestVersion,
		...ppcLatestVersion,
	];
	return agentDetails;
}

import { useMutation } from "@tanstack/react-query";
import { FirewallHostGroupListItem } from "../types";

interface Props
	extends Pick<FirewallHostGroupListItem, "crowdstrikeFirewallHostgroupId"> {}

function useDeleteFirewallHostGroup({ crowdstrikeFirewallHostgroupId }: Props) {
	return useMutation([
		"delete-firewall-host-group",
		`edrs/crowdstrike/firewall-host-groups/${crowdstrikeFirewallHostgroupId}`,
		"delete",
	]);
}

export default useDeleteFirewallHostGroup;

import CloseIcon from "@mui/icons-material/Close";
import {
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { Port, PortStatus } from "pages/ports/types";
import { useEffect, useState } from "react";
import { StatusOptionsText } from "pages/ports/constants";
import { useSearchParams } from "react-router-dom";

import { usePortListCommonStore as useFacetStore } from "./store/common/usePortListCommonStore";
import { PortsReview } from "modules/ports-paths-review/components/ports-review";
import { PortStatusActionBar } from "modules/ports-paths-review/components/ports-review/components/port-status-actionbar";
import { usePortListStore } from "./store/port-store";
import { PathStatus } from "pages/paths/types";
import { Toolbar } from "common/atoms/toolbar";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";

interface PortsViewDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	selectedData: Port[] | undefined;
	selectedStatus: PortStatus | undefined;
	confirm: Function;
	title?: string;
}

export const PortsViewDrawer = ({
	isOpen,
	onClose,
	selectedStatus,
	selectedData,
	title,
	confirm,
}: PortsViewDrawerProps) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [selectedPortStatus, setSelectedPortStatus] = useState<
		PortStatus | undefined
	>(selectedStatus || undefined);

	const [selectedPathStatus, setSelectedPathStatus] = useState<
		PathStatus | undefined
	>(undefined);

	// ports table config
	const [preSelectedPorts, setPreSelectedPorts] = useState<Array<GridRowId>>(
		[]
	);
	const [portsSelection, setPortsSelection] = useState<Array<GridRowId>>([]);

	const [comment, setComment] = useState<string | undefined>(undefined);

	const onSelectPortStatus = (status: PortStatus | undefined) => {
		setSelectedPortStatus(status);
	};

	const onSelectPathStatus = (status: PathStatus | undefined) => {
		setSelectedPathStatus(status);
	};

	const confirmPortAction = () => {
		if (portsSelection?.length > 0) {
			confirm(portsSelection);
		}
		setPortsSelection([]);
		setComment(undefined);
		setSelectedPortStatus(undefined);
	};

	useEffect(() => {
		if (selectedData && selectedData?.length) {
			const selectedPortIds: GridRowId[] = selectedData.map(
				(port: Port) => port.lpId
			);
			setPreSelectedPorts(selectedPortIds);

			if (searchParams.has("recommendation")) {
				searchParams.delete("recommendation");
				setSearchParams(searchParams);
			}
		}
	}, [selectedData, setSearchParams, searchParams]);

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					padding: "0px",
					width: "80%",
					minWidth: "1000px",
					height: "100%",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title="Close Drawer">
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "16px", top: "16px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<Stack direction="row" spacing={2} sx={{ mt: 4, mb: 0, mx: 4 }}>
				<Typography variant="h5">
					<b>{title}</b>
				</Typography>
				<Typography variant="h5">
					{!!selectedPortStatus &&
						StatusOptionsText[selectedPortStatus] &&
						`: ${StatusOptionsText[selectedPortStatus]}`}
				</Typography>
			</Stack>
			<DialogContent>
				<PortsReview
					preSelectedPorts={preSelectedPorts}
					preSelectedPortStatus={selectedStatus}
					setPortSelection={setPortsSelection}
					portFilter={`lpId in (${preSelectedPorts
						.map(id => `'${id}'`)
						.join(",")})`}
					updatePortstatus={onSelectPortStatus}
					useFacetStore={useFacetStore}
					useStore={usePortListStore}
					includeCommonFacet={true}
					updatePathstatus={onSelectPathStatus}
					setComment={setComment}
					comment={comment}
					mode={AssetReviewModeType.EdgeReview}
				></PortsReview>
			</DialogContent>
			<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
				<PortStatusActionBar
					selectedStatus={selectedPortStatus}
					selectedPathStatus={selectedPathStatus}
					selection={portsSelection}
					update={confirmPortAction}
					cancel={onClose}
					comment={comment}
				></PortStatusActionBar>
			</DialogActions>
		</Drawer>
	);
};

import { EditOutlined } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useUnmanagedDevicesStore } from "pages/appliance-detail/store";
import { UnmanagedDevice } from "../../appliance-unmanaged-devices/types";

interface UnmanagedDeviceEditTagProps {
	asset: UnmanagedDevice | undefined;
}

function UnmanagedDeviceEditTag({ asset }: UnmanagedDeviceEditTagProps) {
	const { handleEdit } = useUnmanagedDevicesStore();
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const isButtonDisabled = !userPermissions.has("UPDATE_UNMANAGED_DEVICE");

	const onIconButtonClick = () => {
		handleEdit(asset);
	};

	return (
		<Tooltip title={window.getCTTranslatedText("EditTags")} placement="top">
			<Box>
				<IconButton
					color="primary"
					size="small"
					onClick={onIconButtonClick}
					disabled={isButtonDisabled}
				>
					<EditOutlined />
				</IconButton>
			</Box>
		</Tooltip>
	);
}

export default UnmanagedDeviceEditTag;

import { dayjsWithPlugins } from "./dayjsWithPlugins";

export const getDate = (date: string, format?: string) => {
	if (!date) {
		return "";
	}

	return dayjsWithPlugins(date).format(format || "lll");
};

export const getLocalDate = (date: string) => {
	if (!date) {
		return "";
	}

	return getDate(date, "LL LTS");
};

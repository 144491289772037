import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { useCore } from "modules/core";
import { Scope } from "modules/scope-metadata/types";
import { User } from "pages/assets/types";
import { FACET_ORDER } from "pages/templates/constants";
import { FACET_OPTION_MAPPING } from "pages/users-segmentation/components/users/constants";
import { useUsersAPI } from "pages/users-segmentation/components/users/hooks";
import {
	useAssetCustomUserStore,
	useAssetUserStore,
} from "pages/users-segmentation/components/users/store/useUserStore";
import { SetStateAction, useMemo } from "react";
import { Endpoint } from "./constants";

interface UserMenuProps {
	selectedOption: string;
	assetId: string;
	setSelectedOption: (value: SetStateAction<string>) => void;
}

export const UserMenu = ({
	selectedOption,
	assetId,
	setSelectedOption,
}: UserMenuProps) => {
	const users = useCore<User>({
		useStore: useAssetUserStore,
		facetGroupInfo: FACET_ORDER,
		scope: Scope.SCIM_USER_DEPARTMENT,
		facetOptionsDisplayMapping: FACET_OPTION_MAPPING,
		additionalCriteria: `assetid in ('${assetId}')`,
		useApi: useUsersAPI,
		pageSize: 100,
		skipSearch: true,
		useFacetStore: useAssetCustomUserStore,
		skipUseFacetQueryConnector: true,
	});
	const onChange = (event: SelectChangeEvent) => {
		setSelectedOption(
			userOptions.find(option => option === event.target.value) || Endpoint
		);
	};
	const userOptions: string[] = useMemo(() => {
		return [Endpoint].concat(users?.rows?.map(usr => usr.userEmail) || []);
	}, [users]);
	return (
		<FormControl sx={{ minWidth: 180, marginRight: 6 }}>
			<InputLabel id={"Policy View"}>
				{window.getCTTranslatedText("Policy View")}
			</InputLabel>
			<Select
				labelId={"Policy View"}
				value={selectedOption ?? ""}
				label={window.getCTTranslatedText("Policy View")}
				onChange={onChange}
			>
				{userOptions?.map(option => (
					<MenuItem key={option} value={option}>
						{option}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

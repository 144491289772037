import { Box, Skeleton, Stack } from "@mui/material";

function FacetSkeleton() {
	const ROW_COUNT = 3;

	return (
		<Stack spacing={2.66} py={1.33}>
			{[...Array(ROW_COUNT)].map((_, index) => {
				return (
					<Stack
						key={`facetSkeleton-${index}`}
						spacing={1.5}
						direction={"row"}
						pl={2}
						pr={1}
					>
						<Skeleton variant="rounded" width={20} height={20} />
						<Box width={"100%"}>
							<Skeleton variant="rounded" width={"50%"} height={20} />
						</Box>
						<Skeleton variant="rounded" width={32} height={20} />
					</Stack>
				);
			})}
		</Stack>
	);
}

export default FacetSkeleton;

import { useTheme } from "@mui/material";

export const OthersIcon = () => {
	const theme = useTheme();
	return (
		<svg
			width="49"
			height="48"
			viewBox="0 0 49 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.453 16.9253C16.1554 12.4293 19.8636 9 24.3333 9C28.803 9 32.5111 12.4293 33.2136 16.9253C36.646 17.2599 39.3333 20.2996 39.3333 24C39.3333 24.2627 39.3198 24.5222 39.2934 24.7776C37.5595 21.9355 34.5303 20.0526 31.0833 20.0526C25.6984 20.0526 21.3333 24.6475 21.3333 30.3158C21.3333 30.5814 21.3429 30.8447 21.3616 31.1053H16.0833C12.3553 31.1053 9.33325 27.9242 9.33325 24C9.33325 20.2996 12.0206 17.2599 15.453 16.9253ZM31.0833 39C35.6397 39 39.3333 35.112 39.3333 30.3158C39.3333 25.5196 35.6397 21.6316 31.0833 21.6316C26.5269 21.6316 22.8333 25.5196 22.8333 30.3158C22.8333 35.112 26.5269 39 31.0833 39ZM34.0837 27.9474C33.3987 26.9874 32.3094 26.3684 31.0833 26.3684C29.9728 26.3684 28.9758 26.8756 28.288 27.6845C28.0117 28.0093 27.5376 28.0369 27.229 27.7462C26.9203 27.4554 26.8941 26.9563 27.1702 26.6313C28.1305 25.5021 29.5284 24.7895 31.0833 24.7895C32.553 24.7895 33.8809 25.4253 34.8333 26.4483V25.5789C34.8333 25.143 35.1691 24.7895 35.5833 24.7895C35.9974 24.7895 36.3333 25.143 36.3333 25.5789V28.7368C36.3333 29.1728 35.9974 29.5263 35.5833 29.5263H32.5833C32.1691 29.5263 31.8333 29.1728 31.8333 28.7368C31.8333 28.3009 32.1691 27.9474 32.5833 27.9474H34.0837ZM25.8333 31.8947C25.8333 31.4588 26.1691 31.1053 26.5833 31.1053H29.5833C29.9974 31.1053 30.3333 31.4588 30.3333 31.8947C30.3333 32.3307 29.9974 32.6842 29.5833 32.6842H28.0828C28.7679 33.6442 29.8572 34.2632 31.0833 34.2632C32.1937 34.2632 33.1908 33.756 33.8785 32.9471C34.1548 32.6223 34.629 32.5947 34.9375 32.8854C35.2462 33.1762 35.2725 33.6753 34.9963 34.0003C34.036 35.1295 32.6382 35.8421 31.0833 35.8421C29.6136 35.8421 28.2856 35.2063 27.3333 34.1833V35.0526C27.3333 35.4886 26.9974 35.8421 26.5833 35.8421C26.1691 35.8421 25.8333 35.4886 25.8333 35.0526V31.8947Z"
				fill="#FB7A13"
			/>
			<rect
				x="0.833252"
				y="0.5"
				width="47"
				height="47"
				rx="3.5"
				stroke={theme.palette.text.primary}
				strokeOpacity="0.12"
			/>
		</svg>
	);
};

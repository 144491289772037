import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "hooks/useNetworking/axiosInstance";
import { API_URL } from "hooks/useNetworking/constants";
import { Scope } from "modules/scope-metadata/types";
import { NO_VALUE } from "pages/asset/constants";

interface UseTagSuggestionsProps {
	tagKey: string;
	value: string;
	open: boolean;
	scope: Scope;
}

type Pagination = {
	limit: number;
	offset: number;
	hasMore: boolean;
};

type SuggestionObject = {
	data: string[];
	pagination: Pagination;
	dataType: string;
	valueSuggestEnabled: boolean;
	qualifier: string;
	publicName: string;
};

type Response = {
	data: Record<string, SuggestionObject>;
};

function useTagSuggestions({
	tagKey,
	value,
	open,
	scope,
}: UseTagSuggestionsProps) {
	const transformResponse = (response: Response) => {
		const arr = response?.data?.[tagKey]?.data ?? [];

		const firstSuggestion = Object.values(response?.data ?? {})[0];

		if (firstSuggestion.publicName === tagKey) {
			return [NO_VALUE, ...firstSuggestion.data];
		}

		if (arr.length === 0) {
			return [NO_VALUE];
		}

		const validData = arr?.filter((val: string) => !!val);

		return [NO_VALUE, ...validData];
	};

	return useQuery(["suggestions", tagKey, value, open], {
		queryFn: async () => {
			const body = {
				key: tagKey,
				value: value?.trim() ?? "",
				condition: "contains",
				offset: 0,
				limit: 100,
				...(scope === Scope.UnmanagedDevice && {
					scope: Scope.UnmanagedDevice,
				}),
			};

			const response = await axiosInstance.post(`${API_URL}/suggestions`, body);
			return response.data;
		},
		enabled: open,
		select: transformResponse,
		staleTime: 30000, // Cached for 30s
	});
}

export default useTagSuggestions;

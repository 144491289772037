import { QueryClientProvider } from "@tanstack/react-query";
import React, { useCallback, useMemo } from "react";
import { queryClient } from "./queryClient";
interface NetworkingOptions {
	checkAuthState: Function;
}

export function useNetworking({ checkAuthState }: NetworkingOptions) {
	const newQueryClient = useMemo(
		() => queryClient(checkAuthState),
		[checkAuthState]
	);

	const networkingHOC = useCallback(
		(children: React.ReactNode) => {
			return (
				<QueryClientProvider client={newQueryClient}>
					{children}
				</QueryClientProvider>
			);
		},
		[newQueryClient]
	);

	return { queryClient: newQueryClient, networkingHOC };
}

import { create } from "zustand";

export enum NOTIFY_ACTIONS {
	SHOW_BACKGROUND_PROCESS_TOAST = "SHOW_BACKGROUND_PROCESS_TOAST",
}

export interface SubscriptionCallbackPayload {
	label: string | React.ReactNode;
	variables?: {
		[key: string]: string | number;
	};
}

type SubscriptionCallback = (payload?: SubscriptionCallbackPayload) => void;

type Subscription = {
	[key in NOTIFY_ACTIONS]: SubscriptionCallback[];
};

interface EventSubscriptionStore {
	subscriptions: Subscription;
	subscribe: (
		action: NOTIFY_ACTIONS,
		callback: SubscriptionCallback
	) => () => void;
	notify: (
		action: NOTIFY_ACTIONS,
		payload?: SubscriptionCallbackPayload
	) => void;
}

export const useEventSubscriptionStore = create<EventSubscriptionStore>(
	(set, get) => ({
		subscriptions: {
			[NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST]: [],
		},
		subscribe: (action, callback) => {
			const { subscriptions } = get();
			subscriptions[action].push(callback);
			set({ subscriptions });

			return () => {
				const { subscriptions } = get();
				const index = subscriptions[action]?.indexOf(callback);
				if (index !== undefined && index > -1) {
					subscriptions[action].splice(index, 1);
					set({ subscriptions });
				}
			};
		},
		notify: (action, payload) => {
			const { subscriptions } = get();
			subscriptions[action]?.forEach(callback => callback(payload));
		},
	})
);

import { useTheme } from "@mui/material";

export const VsphereIcon = () => {
	const theme = useTheme();
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.5"
				y="0.5"
				width="47"
				height="47"
				rx="3.5"
				stroke={theme.palette.text.primary}
				strokeOpacity="0.12"
			/>
			<path
				d="M34.5538 8H25.9236L24.6436 9.28H34.5538C35.6584 9.28125 36.7173 9.72059 37.4984 10.5016C38.2794 11.2827 38.7188 12.3417 38.72 13.4462V34.5538C38.7188 35.6583 38.2794 36.7173 37.4984 37.4984C36.7173 38.2794 35.6584 38.7188 34.5538 38.72H26.1872L24.9072 40H34.5538C35.9977 39.9983 37.382 39.424 38.403 38.403C39.4241 37.382 39.9984 35.9977 40 34.5538V13.4462C39.9984 12.0023 39.4241 10.618 38.403 9.59699C37.382 8.57598 35.9977 8.00165 34.5538 8Z"
				fill="#78BE20"
			/>
			<path
				d="M23.355 38.72H13.4462C12.3417 38.7188 11.2827 38.2794 10.5016 37.4984C9.72059 36.7173 9.28125 35.6583 9.28 34.5538V13.4462C9.28125 12.3417 9.72059 11.2827 10.5016 10.5016C11.2827 9.72059 12.3417 9.28125 13.4462 9.28H21.8114L23.0914 8H13.4462C12.0023 8.00165 10.618 8.57598 9.59699 9.59699C8.57598 10.618 8.00165 12.0023 8 13.4462L8 34.5538C8.00165 35.9977 8.57598 37.382 9.59699 38.403C10.618 39.424 12.0023 39.9983 13.4462 40H22.075L23.355 38.72Z"
				fill="#00C1D5"
			/>
			<path
				d="M33.6611 36.2115H19.804C19.1371 36.2107 18.4978 35.9455 18.0262 35.474C17.5547 35.0024 17.2894 34.3631 17.2887 33.6963V19.4938C17.2894 18.8269 17.5547 18.1876 18.0262 17.716C18.4978 17.2445 19.1371 16.9792 19.804 16.9785H33.6611C34.3279 16.9793 34.9673 17.2445 35.4388 17.7161C35.9103 18.1876 36.1755 18.8269 36.1763 19.4938V33.6959C36.1756 34.3628 35.9104 35.0022 35.4389 35.4738C34.9673 35.9454 34.328 36.2107 33.6611 36.2115ZM19.804 18.2585C19.4765 18.2589 19.1625 18.3891 18.9309 18.6207C18.6993 18.8523 18.5691 19.1663 18.5687 19.4938V33.6959C18.5691 34.0234 18.6993 34.3374 18.9309 34.569C19.1625 34.8005 19.4765 34.9308 19.804 34.9311H33.6611C33.9885 34.9307 34.3024 34.8004 34.5339 34.5689C34.7655 34.3373 34.8957 34.0234 34.896 33.6959V19.4938C34.8957 19.1663 34.7654 18.8523 34.5339 18.6207C34.3023 18.3891 33.9883 18.2589 33.6608 18.2585H19.804ZM16.0493 29.6993H14.2628C13.9353 29.6989 13.6213 29.5686 13.3897 29.337C13.1581 29.1054 13.0279 28.7914 13.0276 28.4639V14.2615C13.0279 13.9341 13.1582 13.6201 13.3897 13.3885C13.6213 13.1569 13.9353 13.0267 14.2628 13.0263H28.12C28.4475 13.0267 28.7615 13.1569 28.9931 13.3885C29.2246 13.6201 29.3549 13.9341 29.3552 14.2615V15.7059H30.6352V14.2615C30.6345 13.5947 30.3692 12.9554 29.8977 12.4839C29.4262 12.0123 28.7869 11.7471 28.12 11.7463H14.2628C13.5959 11.7471 12.9566 12.0123 12.4851 12.4839C12.0136 12.9554 11.7483 13.5947 11.7476 14.2615V28.4639C11.7483 29.1308 12.0135 29.7701 12.4851 30.2417C12.9566 30.7132 13.5959 30.9785 14.2628 30.9792H16.0493V29.6993Z"
				fill="#0091DA"
			/>
		</svg>
	);
};

import { AssetStatus, SecurityLevels } from "pages/assets/types";
import { TIMELINES } from "./types";

export const WIDGET_HEIGHT = "350px";
export const WIDGET_CONTENT_HEIGHT = "298px";
export const DIALOG_WIDGET_CONTENT_WIDTH = "1000px";
export const DIALOG_WIDGET_CONTENT_HEIGHT = "600px";
export const DIALOG_WIDGET_CHART_HEIGHT = "450px";

export const ASSET_FIELDS_PRIORITY: { [key: string]: number } = {
	[SecurityLevels.Low]: 0,
	[SecurityLevels.Medium]: 1,
	[SecurityLevels.High]: 2,
	[SecurityLevels.Critical]: 3,
};

export const ASSET_STATUS_PRIORITY: { [key: string]: number } = {
	[AssetStatus.Unsecured]: 0,
	[AssetStatus.SimulateSecureInternet]: 1,
	[AssetStatus.SecureInternet]: 2,
	[AssetStatus.SimulateSecureAll]: 3,
	[AssetStatus.SecureAll]: 4,
};

export const TIMELINE_LIST: Array<TIMELINES> = [
	TIMELINES.WEEK,
	TIMELINES.MONTH,
];

import { SortOrder } from "modules/core/types";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { Asset } from "pages/assets/types";
import {
	NamedNetwork,
	Path,
	PathDraftConfigStatus,
	Protocol,
} from "pages/paths/types";
import { PortStatus } from "pages/ports/types";

export function getFilterNameFromColumnName(name: string): string {
	switch (name) {
		case "source":
			return "srcip";
		case "domain":
			return "destip";
		case "listenPathReviewed":
			return "reviewed";
	}
	return name;
}

export function mapFieldToSort(sortOrder: Array<SortOrder>): Array<SortOrder> {
	if (!sortOrder.length) {
		return sortOrder;
	}

	let field = sortOrder[0].field;
	let order = sortOrder[0].order;

	if (field === "api") {
		return [
			{ field: "method", order },
			{ field: "uri", order },
		];
	} else if (field === "source") {
		return [{ field: "assetname", order }];
	} else if (field === "destination") {
		return [{ field: "domain", order }];
	}
	return sortOrder;
}

export const isRowSelectable = (path: Path) => {
	if (
		path.reviewed === PathDraftConfigStatus.AllowedByTemplate ||
		path.reviewed === PathDraftConfigStatus.DeniedByTemplate
	) {
		return false;
	}

	if (
		[Protocol.ICMP, Protocol.ICMP, Protocol["IPV6-ICMP"]].includes(
			path.protocol
		)
	) {
		return true;
	}

	if (path.srcIp === "0.0.0.0") {
		return false;
	}

	if (
		path.listenPortReviewed !== null &&
		path.listenPortReviewed !== PortStatus.PathRestricted &&
		path.listenPortReviewed !== PortStatus.Unreviewed
	) {
		return false;
	}

	return true;
};

export const getAssetName = (asset: Asset) => {
	if (asset) {
		return capitalizeFirstLetter(asset?.assetName) ?? "";
	}
	return "";
};

export const getNamedNetworkName = (network: NamedNetwork) => {
	if (network) {
		return network?.namedNetworkName ?? "";
	}
	return "";
};

export const getIPAddress = (ip: string) => {
	if (ip) {
		if (Array.isArray(ip)) {
			return ip?.join(", ");
		} else {
			return ip;
		}
	}
	return "";
};

export const formatCoreTagsAsset = (asset: Asset) => {
	if (asset?.containerNamespace || asset?.clusterIdentifier) {
		if (!asset?.coreTags) {
			asset.coreTags = {};
		}
		if (asset?.containerNamespace) {
			asset.coreTags.containerNamespace = asset?.containerNamespace;
		}
		if (asset?.clusterIdentifier) {
			asset.coreTags.clusterIdentifier = asset?.clusterIdentifier;
		}
	}
	return asset.coreTags;
};

import { Box, Button, Tooltip } from "@mui/material";
import { dayjsWithPlugins } from "common/utils";
import { API_URL } from "hooks/useNetworking/constants";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { LogsDrawer } from "modules/logs-drawer/LogsDrawer";
import { Agent, Logs } from "pages/agents/types";
import { LogOptions } from "pages/asset/types";
import { Asset } from "pages/assets/types";
import prettyBytes from "pretty-bytes";
import { useMemo, useState } from "react";
import { LogsFetcher } from "./LogsFetcher";

interface ViewLogsButtonProps {
	asset?: Asset;
	agent?: Agent;
	agentId: string;
}

export const ViewLogsButton = ({
	asset,
	agent,
	agentId,
}: ViewLogsButtonProps) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [optionsData, setOptionsData] = useState<Logs>();
	const [areOptionsLoading, setAreOptionsLoading] = useState(true);
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const onViewLogsClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (event) {
			event.stopPropagation();
		}
		if (agentId) {
			setIsDrawerOpen(true);
		}
	};
	const onDrawerClick = () => setIsDrawerOpen(false);

	const logOptions: LogOptions = useMemo(
		() =>
			optionsData?.items.map((item, index) => ({
				value: item.lastModified,
				label:
					index === 0
						? window.getCTTranslatedText("latestAgentLogDownload", {
								date: dayjsWithPlugins(item.lastModified).format(
									"DD-MMM-YY HH:mm:ss"
								),
							})
						: dayjsWithPlugins(item.lastModified).format("DD-MMM-YY HH:mm:ss"),
				id: item.name,
				fileSize: prettyBytes(item.sizeInBytes),
			})),
		[optionsData]
	);

	return (
		<>
			{isDrawerOpen && (
				<LogsFetcher
					agentId={agentId}
					setOptionsData={setOptionsData}
					setAreOptionsLoading={setAreOptionsLoading}
				/>
			)}
			<LogsDrawer
				assetType={asset?.type}
				agentType={agent?.agentType}
				isOpen={isDrawerOpen}
				onClose={onDrawerClick}
				label="Logs"
				areOptionsLoading={areOptionsLoading}
				logOptions={logOptions}
				downloadPrefix={`${API_URL}/agents/${agentId}/logs/`}
				hasPermission={userPermissions.has("READ_AGENT_LOG")}
			/>
			<Tooltip title={!agentId ? "Agent Unavailable" : ""}>
				<Box>
					<Button
						variant="outlined"
						disabled={!agentId}
						onClick={event => onViewLogsClick(event)}
					>
						{window.getCTTranslatedText("Logs")}
					</Button>
				</Box>
			</Tooltip>
		</>
	);
};

import {
	Card,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
	capitalize,
	useTheme,
} from "@mui/material";
import { MoreOptionsMenu } from "common/molecules/more-options-menu";
import { MenuOption } from "common/molecules/more-options-menu/types";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useTagStore } from "pages/tags/store";
import { TagItemProps } from "pages/tags/types";
import { CreateTag } from "../create-tag";
import { Modes } from "../create-tag/CreateTag";
import { TagDelete } from "../tag-delete";
import { useTagItem } from "./hooks/useTagItem";

export const TagItem = ({ tag }: TagItemProps) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const theme = useTheme();
	const currentTag = useTagStore(state => state.currentTag);
	const setCurrentTag = useTagStore(state => state.setCurrentTag);

	const { handleEditTags, handleDeleteTags, editTag, deleteTag } = useTagItem({
		tag,
	});

	const moreActionMenuOptions: Array<MenuOption> = [];

	if (userPermissions.has("CREATE_FIELD")) {
		moreActionMenuOptions.push({
			label: window.getCTTranslatedText("Edit"),
			handler: () => handleEditTags(true),
		});
	}

	if (userPermissions.has("DELETE_FIELD")) {
		// User cannot delete a Tag which is assigned to asset
		moreActionMenuOptions.push({
			label: window.getCTTranslatedText("Delete"),
			handler: () => handleDeleteTags(true),
			disabled: Number(tag?.count ?? 0) > 0,
			tooltipMessage: window.getCTTranslatedText(
				"Tag name cannot be deleted as it is assigned to 1 or more assets"
			),
		});
	}

	const cancelCallback = () => {
		handleEditTags(false);
		handleDeleteTags(false);
	};

	const isActive = tag?.key === currentTag;
	const displayName = tag?.isCoreTag
		? window.getCTTranslatedText(capitalize(tag?.displayName))
		: tag?.displayName;

	return (
		<>
			{userPermissions.has("CREATE_FIELD") && editTag ? (
				<>
					<CreateTag
						tag={tag}
						mode={Modes.Edit}
						cancelCallback={cancelCallback}
					/>
				</>
			) : (
				<Card sx={{ mb: 2 }}>
					<ListItem
						disablePadding
						key={`${tag?.key}`}
						secondaryAction={
							<>
								{!tag?.isCoreTag &&
									(userPermissions.has("CREATE_FIELD") ||
										userPermissions.has("DELETE_FIELD")) && (
										<MoreOptionsMenu
											menuOptions={moreActionMenuOptions}
											color={
												isActive
													? theme.palette.primary.contrastText
													: "inherit"
											}
										/>
									)}
							</>
						}
						sx={{
							"& .MuiListItemSecondaryAction-root": {
								right: "4px",
							},
						}}
					>
						<ListItemButton
							role={undefined}
							onClick={() => setCurrentTag(tag?.key)}
							selected={isActive}
							sx={{
								pr: 0,
								borderRadius: 1,
								"&.Mui-selected": {
									backgroundColor: theme.palette.primary.main,
									color: theme.palette.primary.contrastText,
									"&:hover": {
										backgroundColor: theme.palette.primary.main,
									},
								},
							}}
						>
							<ListItemText
								disableTypography
								primary={
									<Typography variant="subtitle1">{displayName}</Typography>
								}
								secondary={
									<Typography
										variant="caption"
										sx={{
											color: isActive
												? theme.palette.primary.contrastText
												: theme.palette.text.disabled,
											textTransform: "uppercase",
										}}
									>
										{window.getCTTranslatedText(
											tag?.isCoreTag ? "core" : "custom"
										)}
									</Typography>
								}
							/>
							<ListItemText
								disableTypography
								primary={
									<Typography
										variant="h6"
										sx={{ textTransform: "uppercase", textAlign: "end" }}
									>
										{tag?.count}
									</Typography>
								}
								secondary={
									<Typography
										variant="caption"
										sx={{
											color: isActive
												? theme.palette.primary.contrastText
												: theme.palette.text.disabled,
											textTransform: "uppercase",
											textAlign: "end",
											display: "block",
										}}
									>
										{window.getCTTranslatedText("values")}
									</Typography>
								}
							/>
						</ListItemButton>
					</ListItem>
				</Card>
			)}
			<TagDelete
				tag={tag}
				handleClose={cancelCallback}
				allowDelete={deleteTag}
			/>
		</>
	);
};

export enum PLATFORMS {
	ISO = "iso",
	OVA = "ova",
	DEBIAN = "debian",
}

export enum InstallationLinks {
	iso = "https://docs.ng.colortokens.com/en/article/deployment-guide",
	ova = "https://docs.ng.colortokens.com/en/article/deployment-guide",
	debian = "https://docs.ng.colortokens.com/en/article/deployment-guide",
}

export enum ProtocolTypes {
	http = "http",
	https = "https",
}

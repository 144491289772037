import { WorkRequestAction } from "modules/PendingWorkRequestsDrawer/types";

export const getWorkRequestRedirectRoute = (
	resourceId?: string,
	action?: string
) => {
	switch (action) {
		case WorkRequestAction.AddScheduledJob:
			return "/reports";

		case WorkRequestAction.EvaluateTemplatesRuleEngine:
		case WorkRequestAction.AssetAdded:
		case WorkRequestAction.AssetUpdated:
			return `/assets/${resourceId}`;

		case WorkRequestAction.TemplateEdit:
		case WorkRequestAction.EvaluateTemplates:
			return `/templates?templateId=${resourceId}`;

		case WorkRequestAction.NamedNetworkAssigned:
		case WorkRequestAction.NamedNetworkUnassigned:
		case WorkRequestAction.NamedNetworkRangeAdd:
		case WorkRequestAction.NamedNetworkRangeRemove:
		case WorkRequestAction.NamedNetworkProgramAsIntranet:
			return `/networks?networkId=${resourceId}`;

		case WorkRequestAction.TagUpdated:
			return "/tags";

		case WorkRequestAction.TagRuleAdded:
		case WorkRequestAction.TagRuleModified:
			return "/tags?tab=rules";

		case WorkRequestAction.TagBasedPolicyAdded:
		case WorkRequestAction.TagBasedPolicyMemberChange:
		case WorkRequestAction.TagBasedPolicyAssignmentsModified:
		case WorkRequestAction.TagBasedPolicyProgressiveInboundRefresh:
			return `/segment`;

		case WorkRequestAction.ScimPullData:
			return "/users";

		case WorkRequestAction.EnableUserGroups:
		case WorkRequestAction.DisableUserGroups:
			return `/users?tab=groups&filters=groupid=${resourceId}`;

		case WorkRequestAction.TagBasedPolicyDeleted:
		case WorkRequestAction.TagDeleted:
		case WorkRequestAction.NamedNetworkDeleted:
		case WorkRequestAction.AgentDeleted:
		case WorkRequestAction.UpdateNetworkMap:
		default:
			return undefined;
	}
};

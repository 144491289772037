import { CoreStoreBuilder } from "modules/core/store";
import { create } from "zustand";
import { UnmanagedDevicesStore } from "./types";

export const useUnmanagedDevicesStore = create<UnmanagedDevicesStore>(set => ({
	...CoreStoreBuilder(set),
	allowEdit: false,
	editAsset: undefined,
	handleEdit: asset =>
		set({
			allowEdit: !!asset,
			editAsset: asset,
		}),
}));

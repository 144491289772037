import { capitalize } from "@mui/material";
import { CTConfirmation } from "common/atoms/ct-confirmation";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import useDeleteFirewallHostGroup from "../hooks/useDeleteFirewallHostGroup";
import { useFirewallHostGroupStore } from "../store";

function DeleteFirewallHostGroupModal() {
	const title = window.getCTTranslatedText("Delete Firewall Host Group");
	const { idForDeletion, setIdForDeletion, requestAPIRefresh } =
		useFirewallHostGroupStore();
	const { mutate, isLoading } = useDeleteFirewallHostGroup({
		crowdstrikeFirewallHostgroupId: idForDeletion ?? "",
	});
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const onClose = () => {
		setIdForDeletion(null);
	};

	const onDelete = () => {
		mutate(undefined, {
			onSuccess: () => {
				notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
					label: "DeleteFirewallHostGroupSuccess",
				});
				setIdForDeletion(null);
				requestAPIRefresh();
			},
			onError: error => {
				// @ts-ignore: Axios error type
				let message = error?.response?.data?.message ?? "SomethingWentWrong";
				const capitalizedMessage = capitalize(message);

				setSnackbar(true, SnackBarSeverity.Error, capitalizedMessage);
			},
		});
	};

	return (
		<CTConfirmation
			open={!!idForDeletion}
			onClose={onClose}
			title={title}
			primaryText={
				<>
					{window.getCTTranslatedText("DeleteSelectedFirewallHostGroupText")}
					<br />
					<br />
					{window.getCTTranslatedText("DeleteConfirmation")}
				</>
			}
			isLoading={isLoading}
			primaryButtonText={window.getCTTranslatedText("Delete")}
			onSuccess={onDelete}
		/>
	);
}

export default DeleteFirewallHostGroupModal;

import { AssetSecurityCategory, SecurityType } from "pages/assets/types";

export enum TIMELINES {
	WEEK = "7",
	MONTH = "30",
}

export interface DashboardDialogProps {
	open: boolean;
	handleClose: () => void;
}

export interface DashboardWidgetDialogProps extends DashboardDialogProps {
	title: string;
	children: React.ReactElement | null;
	isLoading?: boolean;
	timeFilter?: React.ReactElement | null;
}

export interface DashboardTimeFilterProps {
	selectedTimeline: TIMELINES;
	handleTimeLineUpdate: (timeline: TIMELINES) => void;
}

export interface SecurityChartDialogProps {
	type: SecurityType;
	totalAssetsCount?: number;
}

export interface SecurityStatusChartDialogProps
	extends SecurityChartDialogProps {
	direction: AssetSecurityCategory;
}

export interface SegmentationProgressChartDialogProps {
	type: string;
	totalAssetsCount?: number;
}

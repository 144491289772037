import { Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { Scope } from "modules/scope-metadata/types";
import { ReportsDataGrid } from "./components/report-data-grid";
import { REPORT_FACET_ORDER } from "./constants";
import { useReportsApi } from "./hooks";
import { useReportStore } from "./store";
import { Report } from "./types";

export const Reports = () => {
	const theme = useTheme();

	const coreResponse = useCore<Report>({
		useStore: useReportStore,
		facetGroupInfo: REPORT_FACET_ORDER,
		scope: Scope.SchedulerHistory,
		defaultSortOrder: [{ field: "createdat", order: "desc" }],
		useApi: useReportsApi,
		additionalCriteria: "'jobtype' in ('executivereport')",
	});

	return (
		<Stack
			direction={"column"}
			spacing={2}
			sx={{ height: "100%", width: "100%" }}
		>
			<Stack direction="row">
				<Stack sx={{ flex: 1 }}>
					<FacetOpenCloseController
						facetsOpen={coreResponse.facetsOpen}
						setFacetsOpen={coreResponse.setFacetsOpen}
					>
						<FacetControllerGroup
							config={coreResponse.facetConfig}
							value={coreResponse.facetState}
							onChange={coreResponse.updateFacet}
						/>
					</FacetOpenCloseController>
				</Stack>
			</Stack>

			<Paper
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					flex: 1,
					overflow: "hidden",
				}}
			>
				<ReportsDataGrid
					metadata={coreResponse.metadata}
					rows={coreResponse.rows}
					rowCount={coreResponse.rowCount}
					mutation={coreResponse.mutation}
					onPageChange={coreResponse.onPageChange}
					page={coreResponse.page}
					pageSize={coreResponse.pageSize}
					onPageSizeChange={coreResponse.onPageSizeChange}
					onSortChange={coreResponse.onSortChange}
				/>
			</Paper>
		</Stack>
	);
};

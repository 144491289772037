import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useUserStore } from "../users/store";
import { User } from "pages/users-segmentation/components/users/types";

export interface UserToolbarProps extends Omit<ToolbarProps, "children"> {
	selection: Array<User> | undefined;
}

export function UserToolBar(props: UserToolbarProps) {
	const requestAPIRefresh = useUserStore(store => store.requestAPIRefresh);

	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}></GridToolbar>
	);
}

import { KeyboardArrowRight, NotificationsRounded } from "@mui/icons-material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
	Badge,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemText,
	Menu,
	Stack,
	Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { DEFAULT_TIME_FILTER } from "common/molecules/TimeFilter/TimeFilter";
import { useCommonStore } from "common/store";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { usePolling } from "hooks/usePolling";
import { useAlertCountAPI } from "pages/monitoring/components/alerts-action/hooks/useAlertsAPI";
import { InviteResult } from "pages/organization/types";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	AlertFilter,
	AlertResponse,
	AlertStatus,
	AlertsCountReq,
} from "./types";

function useInvitesAPI(isEnabled: boolean) {
	return useQuery<any, Error, InviteResult>(
		["invites", "auth/tenant-invites"],
		{
			enabled: isEnabled,
		}
	);
}
const PollingInterval = 60000;

export function Notifications() {
	const navigate = useNavigate();
	const alertsEnabled = true;
	const [alertsCount, setAlertsCount] = useState<number>(0);

	const { mutate: getAlertMutate } = useAlertCountAPI();
	const setSelectedTime = useUserPreferencesStore(state => state.setTimeFilter);

	const fetchData = useCallback(() => {
		const currentDate = new Date();
		const past2Weeks = new Date();
		past2Weeks.setDate(currentDate.getDate() - 14);
		const currentDateFormat = currentDate.toISOString().slice(0, 23) + "Z";
		const past2WeeksFormat = past2Weeks.toISOString().slice(0, 23) + "Z";
		const body: AlertsCountReq = {
			criteria: `${AlertFilter.filters} in ('${AlertStatus.PENDING}') AND alertlastseen > '${past2WeeksFormat}' AND alertlastseen < '${currentDateFormat}'`,
			groupBy: [],
			statistics: ['count("alerthash")'],
			scope: "alert",
		};

		getAlertMutate(body, {
			onSuccess: (response: AlertResponse) => {
				setAlertsCount(response?.items?.statistics?.alerthashcount || 0);
			},
		});
	}, [getAlertMutate]);

	usePolling(fetchData, PollingInterval);

	const updateFacetOption = useCommonStore(state => state.updateFacetOption);
	const onBellIconClick = () => {
		updateFacetOption({
			facetName: AlertFilter.filters,
			optionName: AlertStatus.PENDING,
			value: Boolean(AlertStatus.PENDING),
		});
		setSelectedTime(DEFAULT_TIME_FILTER);
		navigate(`/monitoring?tab=${AlertFilter.tab}`);
	};

	const invitesAPI = useInvitesAPI(!alertsEnabled);
	const invites = invitesAPI.data;

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorEl);

	const handleNotificationsOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			id={"notifications-menu"}
			keepMounted
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<Stack>
				<Typography variant="body2" sx={{ mx: 3, mt: 2 }}>
					You have been invited to join these organizations
				</Typography>
				<List>
					{invites?.map(invite => (
						<ListItem
							key={invite.inviteCode}
							component={Link}
							href={invite.inviteLink}
							color="inherit"
							secondaryAction={
								<IconButton edge="end" aria-label="view">
									<KeyboardArrowRight />
								</IconButton>
							}
						>
							<ListItemText primary={invite.tenantName} />
						</ListItem>
					))}
				</List>
			</Stack>
		</Menu>
	);

	return (
		<>
			{!alertsEnabled && invites?.length && (
				<Badge
					color="error"
					badgeContent=" "
					overlap="circular"
					sx={{
						"& .MuiBadge-badge": {
							width: 12,
							minWidth: 12,
							height: 12,
						},
					}}
				>
					<IconButton
						size="large"
						edge="end"
						aria-label="notifications of current user"
						aria-haspopup="true"
						onClick={handleNotificationsOpen}
						color="inherit"
					>
						<NotificationsRounded fontSize="medium" />
					</IconButton>
					{renderMenu}
				</Badge>
			)}

			{
				<IconButton size="medium" color="inherit" onClick={onBellIconClick}>
					<Badge badgeContent={alertsCount} color="error">
						<NotificationsIcon />
					</Badge>
				</IconButton>
			}
		</>
	);
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useSnackbarStore } from "./store";
import { snackbarDefaultConfig } from "./store/types";

// Usage:

// imports
// import { useSnackbarStore } from "modules/snackbar/store";
// import { SnackBarSeverity } from "modules/snackbar/store/types";

// initialization
// const setSnackbar = useSnackbarStore(state => state.setSnackbar);

// trigger
// setSnackbar(true, SnackBarSeverity.Warning, "success api response");

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
	function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	}
);

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		"& > * + *": {
			marginTop: theme.spacing(2),
		},
	},
}));

export const CTSnackbar = () => {
	const classes = useStyles();

	const snackbarOpen = useSnackbarStore(state => state.snackbarOpen);
	const snackbarType = useSnackbarStore(state => state.snackbarType);
	const snackbarMessage = useSnackbarStore(state => state.snackbarMessage);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const handleClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackbar(false, snackbarType, snackbarMessage);
	};

	return (
		<div className={classes.root}>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={snackbarDefaultConfig.autoHideDuration}
				onClose={handleClose}
				anchorOrigin={{
					vertical: snackbarDefaultConfig.vertical,
					horizontal: snackbarDefaultConfig.horizontal,
				}}
			>
				<Alert
					onClose={handleClose}
					severity={snackbarType}
					sx={{ width: "100%", color: "#fff" }}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</div>
	);
};

import { ExpandCircleDownOutlined } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { CTDrawer } from "common/molecules/drawer";
import { Asset } from "pages/assets/types";
import { NamedNetwork, PathDirection } from "pages/paths/types";
import { useState } from "react";
import { PathNnHitsDataGrid } from "./PathNnHitsDataGrid";

interface PathNnHitsProps {
	nn: NamedNetwork;
	asset: Asset;
	port: string;
	protocol: string;
	direction: PathDirection;
}

export function PathNnHits({
	nn,
	asset,
	port,
	protocol,
	direction,
}: PathNnHitsProps) {
	const [showDetails, setShowDetails] = useState(false);

	return (
		<>
			<Tooltip title={window.getCTTranslatedText("Show named network hits")}>
				<IconButton
					sx={{
						"& svg": {
							transform: "rotate(218deg)",
						},
					}}
					aria-label="Show named network hits"
					onClick={() => {
						setShowDetails(true);
					}}
				>
					<ExpandCircleDownOutlined
						sx={{ opacity: 0.6 }}
						fontSize="small"
						color="info"
					/>
				</IconButton>
			</Tooltip>

			<CTDrawer
				title={`${nn.namedNetworkName} named network hits`}
				open={showDetails}
				onClose={() => {
					setShowDetails(false);
				}}
				sx={{ height: "100%", overflow: "hidden" }}
			>
				<Stack sx={{ width: "100%", height: "100%" }}>
					{showDetails && (
						<PathNnHitsDataGrid
							port={port}
							protocol={protocol}
							nn={nn}
							asset={asset}
							direction={direction}
						/>
					)}
				</Stack>
			</CTDrawer>
		</>
	);
}

import { useTheme } from "@mui/material";

interface Props {
	variant?: "default" | "minimal";
}

const CrowdStrikeIconDefault = () => {
	const theme = useTheme();

	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_4381_51713)">
				<path
					d="M40.0705 32.4955C39.301 32.4198 37.9377 32.2286 36.2319 33.0893C34.5261 33.95 33.8567 33.9855 33.0183 33.8966C33.2651 34.348 33.7633 34.9641 35.3312 35.0753C36.8991 35.1865 37.6486 35.2332 36.8235 37.1859C36.8435 36.5965 36.7034 35.4556 35.1488 35.6557C33.592 35.8559 33.2295 37.257 34.8975 37.9554C34.3549 38.0643 33.2051 38.131 32.3844 35.9805C31.8151 36.2273 30.9366 36.7233 29.3442 35.4956C29.7149 35.6291 30.1286 35.7002 30.5852 35.7091C29.173 35.0353 27.823 33.7809 26.9601 32.5955C27.6451 33.1071 28.4013 33.6141 29.1663 33.7098C28.2611 32.6089 26.1728 30.4027 23.6152 28.1387C25.2588 29.2129 27.2426 30.9075 30.4896 30.5272C33.7366 30.1447 35.9183 29.4019 40.0705 32.4955Z"
					fill="#FF0000"
				/>
				<path
					d="M26.173 31.7305C24.1381 30.8476 23.7022 30.6719 21.0845 30.0069C18.4669 29.3419 15.8915 27.9564 14.1702 25.7969C15.3845 26.6865 17.862 28.4746 20.4084 28.2811C20.0215 27.714 19.3076 27.2736 18.4558 26.8288C19.4188 27.0601 22.33 27.8051 26.173 31.7305Z"
					fill="#FF0000"
				/>
				<path
					d="M14.5215 18.7109C7.79167 14.5053 6.45505 11.265 6.15259 10.0195C10.3804 14.6432 13.5918 16.4535 15.778 17.8991C20.2705 20.7636 21.2179 22.6896 21.7427 24.1952C19.5877 21.1528 16.7343 20.221 14.5215 18.7109Z"
					fill="#FF0000"
				/>
				<path
					d="M15.3577 22.0442C9.1995 18.7883 7.76948 16.1817 7.48926 14.9941C10.5717 18.2812 13.9989 20.0115 16.0071 21.1346C20.3572 23.4675 21.467 25.1044 22.0186 26.3787C19.9458 23.8634 17.0635 22.9827 15.3577 22.0442Z"
					fill="#FF0000"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M35.2891 26.2611C34.7175 27.6199 35.3758 27.7712 35.6427 27.8735C36.9971 28.3916 40.58 29.0566 40.3553 30.5712C40.7734 30.9893 42.3213 32.655 41.6742 33.8115C41.1182 32.9041 38.5628 30.5489 35.4715 30.0641C32.3801 29.5793 29.5623 30.6245 27.2338 29.0789C25.2567 27.6555 24.9387 27.1173 22.9482 23.8569C22.0541 22.3936 21.8807 21.0614 18.8449 18.5839C15.8092 16.1063 12.9692 14.7853 11.7771 11.0957C14.3858 14.3027 17.7596 17.3651 26.2397 19.7915C36.8614 22.7538 36.0586 24.384 35.2891 26.2611ZM40.024 30.9114C40.0062 30.3243 40.0862 29.984 38.4783 29.7394C39.0521 30.0226 39.5673 30.4133 40.024 30.9114Z"
					fill="#FF0000"
				/>
			</g>
			<rect
				x="0.5"
				y="0.5"
				width="47"
				height="47"
				rx="3.5"
				stroke={theme.palette.text.primary}
				strokeOpacity="0.12"
			/>
			<defs>
				<clipPath id="clip0_4381_51713">
					<rect
						width="35.695"
						height="28"
						fill="white"
						transform="translate(6.15259 10)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

const CrowdStrikeIconMinimal = () => {
	const stroke = "currentColor";
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 32 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_10668_61237)">
				<path
					d="M27.0605 18.6408C26.7548 18.7368 26.4342 18.8643 26.1016 19.0321C25.3995 19.3864 24.9636 19.543 24.6481 19.6115C24.3543 19.6753 24.1309 19.6707 23.8196 19.6377L22.2053 19.4664L22.9842 20.8907C23.0534 21.0173 23.1432 21.1614 23.265 21.3087L22.8485 21.4893C22.8223 21.5006 22.7974 21.5115 22.7735 21.5219C22.5314 21.6276 22.4006 21.6847 22.2102 21.6853C22.1401 21.6855 22.0453 21.6766 21.9227 21.6407L21.9858 21.3741L22.4398 21.4308L21.0849 19.783C20.7997 19.4362 20.3893 18.9734 19.8805 18.4338C20.4287 18.5043 21.021 18.5142 21.6614 18.4391L21.662 18.4391C22.004 18.3988 22.3206 18.3563 22.6192 18.3163C23.6176 18.1824 24.415 18.0754 25.2896 18.1666C25.8295 18.2228 26.4058 18.3589 27.0605 18.6408ZM23.9487 21.882L23.9479 21.8797C23.9485 21.8801 23.9491 21.8804 23.9497 21.8808C23.9494 21.8812 23.9491 21.8816 23.9487 21.882Z"
					stroke={stroke}
					strokeWidth="1.7"
				/>
				<mask id="path-2-inside-1_10668_61237" fill="white">
					<path d="M17.8624 18.6262C16.1182 17.8694 15.7446 17.7188 13.5009 17.1489C11.2572 16.5789 9.04974 15.3913 7.57428 13.5403C8.61511 14.3028 10.7387 15.8354 12.9214 15.6696C12.5897 15.1835 11.9778 14.8061 11.2477 14.4248C12.0731 14.623 14.5684 15.2616 17.8624 18.6262Z" />
				</mask>
				<path
					d="M17.8624 18.6262L17.1858 20.1858L19.0772 17.4369L17.8624 18.6262ZM7.57428 13.5403L8.57894 12.1689L6.24493 14.5999L7.57428 13.5403ZM12.9214 15.6696L13.0502 17.3647L15.984 17.1418L14.3256 14.7114L12.9214 15.6696ZM11.2477 14.4248L11.6447 12.7718L10.4608 15.9317L11.2477 14.4248ZM18.5391 17.0667C16.7157 16.2756 16.2559 16.0947 13.9195 15.5012L13.0823 18.7965C15.2333 19.3429 15.5207 19.4633 17.1858 20.1858L18.5391 17.0667ZM13.9195 15.5012C11.9358 14.9973 10.091 13.9703 8.90362 12.4806L6.24493 14.5999C8.00843 16.8123 10.5786 18.1605 13.0823 18.7965L13.9195 15.5012ZM6.56961 14.9117C7.09297 15.2951 7.98991 15.9494 9.07139 16.4795C10.1418 17.0042 11.5352 17.4798 13.0502 17.3647L12.7926 13.9745C12.1249 14.0252 11.3652 13.8174 10.5679 13.4266C9.78168 13.0412 9.09642 12.548 8.57894 12.1689L6.56961 14.9117ZM14.3256 14.7114C13.724 13.8298 12.7369 13.2846 12.0346 12.9179L10.4608 15.9317C11.2187 16.3275 11.4553 16.5372 11.5171 16.6278L14.3256 14.7114ZM10.8506 16.0778C11.4314 16.2173 13.6204 16.7234 16.6477 19.8155L19.0772 17.4369C15.5164 13.7999 12.7148 13.0288 11.6447 12.7718L10.8506 16.0778Z"
					fill={stroke}
					mask="url(#path-2-inside-1_10668_61237)"
				/>
				<mask id="path-4-inside-2_10668_61237" fill="white">
					<path d="M7.87546 7.46687C2.10707 3.86211 0.961402 1.08466 0.702148 0.0171509C4.32598 3.9803 7.07864 5.532 8.95251 6.77108C12.8032 9.22637 13.6153 10.8772 14.0651 12.1677C12.218 9.55996 9.7722 8.76123 7.87546 7.46687Z" />
				</mask>
				<path
					d="M7.87546 7.46687L8.8337 6.06267L8.80542 6.04337L8.77637 6.02522L7.87546 7.46687ZM0.702148 0.0171509L1.95674 -1.13002L-0.949833 0.418346L0.702148 0.0171509ZM8.95251 6.77108L8.01485 8.18911L8.02663 8.1969L8.03853 8.20449L8.95251 6.77108ZM14.0651 12.1677L12.6779 13.1504L15.6704 11.6082L14.0651 12.1677ZM8.77637 6.02522C3.25666 2.57586 2.47765 0.12456 2.35413 -0.384045L-0.949833 0.418346C-0.554844 2.04477 0.957484 5.14836 6.97455 8.90852L8.77637 6.02522ZM-0.55244 1.16432C3.23593 5.30742 6.16821 6.96804 8.01485 8.18911L9.89016 5.35306C7.98907 4.09597 5.41602 2.65317 1.95674 -1.13002L-0.55244 1.16432ZM8.03853 8.20449C9.87117 9.37302 10.8876 10.2921 11.4829 11.0001C12.0524 11.6776 12.2803 12.2122 12.4599 12.7273L15.6704 11.6082C15.4001 10.8327 14.997 9.89662 14.0854 8.81224C13.1995 7.75845 11.8845 6.62443 9.86648 5.33768L8.03853 8.20449ZM15.4524 11.1851C14.3608 9.64406 13.0968 8.65178 11.9021 7.89088C11.3173 7.51844 10.7322 7.19151 10.2216 6.90194C9.69418 6.60285 9.24255 6.34168 8.8337 6.06267L6.91722 8.87107C7.45674 9.23925 8.02211 9.5633 8.54445 9.8595C9.08358 10.1652 9.58106 10.4437 10.0757 10.7587C11.0398 11.3727 11.9223 12.0837 12.6779 13.1504L15.4524 11.1851Z"
					fill={stroke}
					mask="url(#path-4-inside-2_10668_61237)"
				/>
				<mask id="path-6-inside-3_10668_61237" fill="white">
					<path d="M8.59224 10.3244C3.31376 7.5336 2.08803 5.29944 1.84784 4.28149C4.48994 7.09897 7.4275 8.58205 9.14887 9.54472C12.8775 11.5444 13.8288 12.9474 14.3015 14.0397C12.5249 11.8837 10.0543 11.1288 8.59224 10.3244Z" />
				</mask>
				<path
					d="M8.59224 10.3244L9.41172 8.83494L9.39933 8.82812L9.38682 8.82151L8.59224 10.3244ZM1.84784 4.28149L3.0879 3.11863L-1.26967 -1.52818L0.193274 4.6719L1.84784 4.28149ZM9.14887 9.54472L8.31909 11.0285L8.33219 11.0358L8.34541 11.0429L9.14887 9.54472ZM14.3015 14.0397L12.9896 15.1208L15.8617 13.3645L14.3015 14.0397ZM9.38682 8.82151C4.2755 6.1191 3.57544 4.2006 3.5024 3.89109L0.193274 4.6719C0.600624 6.39829 2.35203 8.9481 7.79765 11.8273L9.38682 8.82151ZM0.607783 5.44436C3.46319 8.4893 6.64992 10.095 8.31909 11.0285L9.97865 8.06098C8.20509 7.06912 5.51669 5.70864 3.0879 3.11863L0.607783 5.44436ZM8.34541 11.0429C11.8717 12.934 12.4724 14.0934 12.7414 14.715L15.8617 13.3645C15.1852 11.8014 13.8834 10.1548 9.95233 8.04657L8.34541 11.0429ZM15.6135 12.9586C14.5383 11.6539 13.275 10.8014 12.1442 10.1834C11.5818 9.8761 11.0376 9.61929 10.5718 9.40133C10.0848 9.17345 9.71682 9.0028 9.41172 8.83494L7.77275 11.8138C8.19872 12.0482 8.68782 12.2736 9.13079 12.4809C9.59498 12.6981 10.0536 12.9156 10.5137 13.167C11.4278 13.6665 12.2881 14.2696 12.9896 15.1208L15.6135 12.9586Z"
					fill={stroke}
					mask="url(#path-6-inside-3_10668_61237)"
				/>
				<path
					d="M28.1873 16.9444C28.2108 16.9539 28.234 16.9634 28.2568 16.973L28.2275 17.0661C27.5379 16.744 26.7747 16.4858 25.9643 16.3587C24.888 16.1899 23.8154 16.2215 22.8849 16.2489C22.5918 16.2575 22.3129 16.2657 22.0524 16.2672C20.9138 16.2733 20.0302 16.1638 19.2546 15.6542C18.4446 15.0703 18.0251 14.7035 17.6033 14.1757C17.1532 13.6125 16.6927 12.8583 15.8304 11.4458L15.8238 11.4351L15.8237 11.4348C15.6706 11.1842 15.5453 10.9393 15.3999 10.6553C15.3829 10.6219 15.3655 10.5879 15.3478 10.5534C15.183 10.232 14.9952 9.87365 14.7417 9.4821C14.3832 8.92843 13.9097 8.33327 13.1969 7.64891C14.493 8.199 15.9744 8.72086 17.6858 9.21053L17.6858 9.21055L17.6913 9.21208C19.9498 9.84197 21.5746 10.3939 22.7323 10.8868C23.9024 11.385 24.5346 11.7979 24.8638 12.1213C25.1577 12.4099 25.1783 12.5907 25.1735 12.7184C25.1654 12.9321 25.074 13.1669 24.8911 13.613C24.6173 14.2655 24.5099 14.9143 24.8211 15.4566C25.1048 15.9511 25.639 16.1041 25.6734 16.1139C25.6744 16.1142 25.675 16.1144 25.6751 16.1144L25.6756 16.1146C25.9491 16.2192 26.3115 16.3285 26.6711 16.4369C26.7411 16.458 26.8111 16.4791 26.8802 16.5001C27.3269 16.6359 27.7815 16.7799 28.1873 16.9444Z"
					stroke={stroke}
					strokeWidth="1.7"
				/>
			</g>
			<defs>
				<clipPath id="clip0_10668_61237">
					<rect
						width="30.5957"
						height="24"
						fill="white"
						transform="translate(0.702148)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const CrowdStrikeIcon = ({ variant = "default" }: Props) => {
	const variantMap = {
		default: <CrowdStrikeIconDefault />,
		minimal: <CrowdStrikeIconMinimal />,
	};

	return variantMap[variant];
};

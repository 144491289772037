import { FirewallLogs } from "pages/asset/types";
import { Asset } from "pages/assets/types";
import { useEffect } from "react";
import { useFirewallDecisionLogsAPI } from "../../hooks";

export interface FirewallDecisionLogsFetcherProps
	extends Pick<Asset, "agentId"> {
	setOptionsData: React.Dispatch<
		React.SetStateAction<FirewallLogs | undefined>
	>;
	setAreOptionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FirewallDecisionLogsFetcher = ({
	agentId,
	setOptionsData,
	setAreOptionsLoading,
}: FirewallDecisionLogsFetcherProps) => {
	const { data, isLoading }: any = useFirewallDecisionLogsAPI({ agentId });

	useEffect(() => {
		setAreOptionsLoading(isLoading);
	}, [isLoading, setAreOptionsLoading]);

	useEffect(() => {
		setOptionsData(data);
	}, [data, setOptionsData]);

	return null;
};

import { AgentActionType } from "pages/agents/components/agent-data-grid-toolbar/Toolbar";
import { AgentVersions } from "pages/agents/components/agent-downlaod-version/useVersionAPI";
import { AGENT_TYPES } from "pages/agents/components/agent-download-page/constants";
import { AgentDownloadSection } from "pages/agents/components/agent-download-page/type";
import { AgentGroupByObj } from "pages/agents/components/agent-drawers/hooks/useGroupByAgentData";

interface AgentDownloadSectionOptions {
	availableServerUsersPlatforms: string[];
	availableContainerPlatforms: string[];
	renderServerUserAgentActions: () => React.ReactNode;
	shouldShowContainerAgentSection: boolean;
	AgentPlatformData?: AgentVersions;
	page?: string;
	agentsGroupByPlatforms?: AgentGroupByObj;
	setAgentsGroupByPlatforms?: (obj?: AgentGroupByObj) => void;
	agentType?: AGENT_TYPES;
}

export function getSectionsData({
	availableServerUsersPlatforms,
	availableContainerPlatforms,
	renderServerUserAgentActions,
	shouldShowContainerAgentSection,
	AgentPlatformData,
	page,
	agentsGroupByPlatforms,
	setAgentsGroupByPlatforms,
	agentType,
}: AgentDownloadSectionOptions): AgentDownloadSection[] {
	const sections: AgentDownloadSection[] = [];

	if (!shouldShowContainerAgentSection) {
		sections.push({
			header: {
				title: "Server/User Agents",
				renderActions: renderServerUserAgentActions,
			},
			list: availableServerUsersPlatforms,
			shouldShowSection: true,
			shouldShowList: AgentPlatformData != null,
			shouldShowListItem: (platform?: string) =>
				Boolean(
					agentsGroupByPlatforms?.[platform ?? ""]?.some(
						agentsGroupBy => agentsGroupBy?.data?.length > 0
					)
				) || page !== AgentActionType.UPGRADE,
			agentType,
			agentsGroupByPlatforms,
			setAgentsGroupByPlatforms,
		});
	}

	if (shouldShowContainerAgentSection) {
		sections.push({
			header: {
				title: "Containers",
				renderActions: renderServerUserAgentActions,
			},
			list: availableContainerPlatforms,
			shouldShowSection: shouldShowContainerAgentSection,
			shouldShowList: AgentPlatformData != null,
			shouldShowListItem: () => shouldShowContainerAgentSection,
			agentType: AGENT_TYPES.CT_CONTAINER_AGENT,
			agentsGroupByPlatforms,
			setAgentsGroupByPlatforms,
		});
	}

	return sections;
}

import {
	Box,
	DialogActions,
	DialogContent,
	FormControl,
	Stack,
	TextField,
	Typography,
} from "@mui/material";

import { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { parseErrorMessage } from "common/utils";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { CopyInviteButton } from "pages/organization/components/copy-invite-button";
import { CTRole, Invite } from "pages/organization/types";
import { RolesDropdown } from "../roles-dropdown";

export interface InviteMemberFormProps {
	cancel: () => void;
	onSuccess: () => void;
	rolesList: Array<CTRole> | undefined;
	title?: string;
	btnTitle?: string;
}

export interface InviteFormProps {
	name: string;
	email: string;
	role?: number | string;
}

const useInviteMembers = () => {
	return useMutation<any, Error, any>([
		"userInvites",
		"auth/admin/invite/user",
	]);
};

export function InviteMemberForm({
	title,
	onSuccess,
	cancel,
	rolesList,
	btnTitle = "invite",
}: InviteMemberFormProps) {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [role, setRole] = useState<CTRole | undefined>(undefined);
	const [inviteResult, setInviteResults] = useState<Invite | null>(null);

	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const inviteMembersAPI = useInviteMembers();

	const [loading, setLoading] = useState(false);

	const queryClient = useQueryClient();

	const isValidMember = () => {
		let isValid = false;

		const isNameValid = name?.trim().length > 0;
		const isEmailValid = email?.trim().length > 0;
		const isRoleValid = !!role?.roleID;

		if (isNameValid && isEmailValid && isRoleValid) {
			isValid = true;
		} else {
			isValid = false;
		}

		return isValid;
	};

	const updateMemberDetails = () => {
		if (isValidMember()) {
			setLoading(true);

			const body: Array<InviteFormProps> = [
				{
					name: name,
					email: email?.trim(),
					role: role?.roleID,
				},
			];

			inviteMembers(body);
		}
	};

	const inviteMembers = (body: Array<InviteFormProps>) => {
		inviteMembersAPI.mutate(body, {
			onSuccess: response => {
				queryClient.invalidateQueries({
					queryKey: ["teamMembers"],
				});
				onSuccess();
				setInviteResults(response[0]);
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"MemberInvitedSuccessfully"
				);
				setTimeout(() => {
					cancel();
					setLoading(false);
				}, 300);
			},
			onError: error => {
				setLoading(false);
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const handleNameChange = (e: any) => {
		if (e?.target?.value) {
			setName(e.target.value);
		} else {
			setName("");
		}
	};

	const handleEmailChange = (e: any) => {
		if (e?.target?.value) {
			setEmail(e.target.value.toLowerCase());
		} else {
			setEmail("");
		}
	};

	const handleRoleChange = (role: CTRole) => {
		if (role) {
			setRole(role);
		} else {
			setRole(undefined);
		}
	};

	const keyListener = (event: any) => {
		if (event?.key === "Enter" || event?.keyCode === 13) {
			updateMemberDetails();
		}
	};

	const InviteResult = () => {
		return (
			<Box>
				<DialogContent>
					<Stack alignItems="center" sx={{ py: 4 }}>
						<Typography>
							An email containting invite code has been sent to{" "}
							{inviteResult?.email}
						</Typography>
						<CopyInviteButton
							buttonProps={{
								sx: {
									my: 4,
								},
							}}
							code={inviteResult?.inviteLink || ""}
						/>
					</Stack>
				</DialogContent>
				<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
					<ToolbarAction
						loading={false}
						actionBtnText={"close"}
						save={cancel}
						cancel={cancel}
						hideSecondaryBtn={true}
					/>
				</DialogActions>
			</Box>
		);
	};

	return (
		<>
			{inviteResult ? (
				<InviteResult />
			) : (
				<>
					<DialogContent>
						<Stack
							alignItems={"flex-start"}
							sx={{ position: "relative", height: "100%" }}
						>
							<Typography variant="h6" sx={{ my: 3, mx: 4 }}>
								{window.getCTTranslatedText(title ?? "")}
							</Typography>
							<Box
								sx={{
									minWidth: "100%",
									px: 4,
									mt: 3,
								}}
							>
								<Stack
									alignItems="flex-start"
									justifyContent="flex-start"
									spacing={4}
								>
									<FormControl sx={{ m: 0, minWidth: "100%" }} size="small">
										<Stack direction={"column"} spacing={7}>
											<Stack spacing={4}>
												<TextField
													required
													name="name"
													value={name}
													type="text"
													placeholder={window.getCTTranslatedText("Enter name")}
													fullWidth
													label={window.getCTTranslatedText("Name")}
													onChange={handleNameChange}
													inputProps={{ autoComplete: "off" }}
													onKeyUp={keyListener}
													variant="standard"
													InputLabelProps={{ shrink: true }}
												/>

												<TextField
													required
													name="email"
													value={email}
													type="text"
													placeholder={window.getCTTranslatedText(
														"Enter email"
													)}
													fullWidth
													label={window.getCTTranslatedText("Email")}
													onChange={handleEmailChange}
													inputProps={{ autoComplete: "off" }}
													onKeyUp={keyListener}
													variant="standard"
													InputLabelProps={{ shrink: true }}
												/>
											</Stack>
											<RolesDropdown
												rolesList={rolesList as Array<CTRole> | undefined}
												onSelect={handleRoleChange}
												invite={true}
											/>
										</Stack>
									</FormControl>
								</Stack>
							</Box>
						</Stack>
					</DialogContent>
					<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
						<ToolbarAction
							loading={inviteMembersAPI.isLoading || loading}
							isValid={isValidMember()}
							actionBtnText={btnTitle ?? "Invite"}
							save={updateMemberDetails}
							cancel={cancel}
						/>
					</DialogActions>
				</>
			)}
		</>
	);
}

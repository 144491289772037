import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { DownloadItem } from "./components/DownloadItem";
import { RegisterInstructionsDrawer } from "./components/RegisterIntructionsDrawer";

export function ApplianceRegister() {
	const [showInstructions, setShowInstructions] = useState(false);

	return (
		<Stack sx={{ alignItems: "center", pb: 2 }}>
			<Stack spacing={2} width="95%">
				<Typography variant="h6" sx={{ mb: 2 }}>
					{window.getCTTranslatedText("step")} 2:{" "}
					{window.getCTTranslatedText("registerGatekeeper")}
				</Typography>
				<DownloadItem onInstallInstructions={() => setShowInstructions(true)} />
			</Stack>
			<RegisterInstructionsDrawer
				drawerOpen={showInstructions}
				onClose={() => setShowInstructions(false)}
			/>
		</Stack>
	);
}

import { usePrevious } from "common/hooks/usePrevious";
import produce from "immer";
import difference from "lodash/difference";
import { useEffect } from "react";

import {
	Facet,
	FacetOptionState,
	Operator,
	onFacetChange,
	onFacetCommit,
} from "../types";

export interface CommitSyncProps {
	committedValue: FacetOptionState | undefined;
	facet: Facet;
	isStageAndCommittedEqual: boolean;
	onChange: onFacetChange;
	onCommit: onFacetCommit | undefined;
	stagedValue: FacetOptionState | undefined;
	toRemove: Array<string>;
}

export default function useCommitAndStageSync({
	committedValue,
	facet,
	isStageAndCommittedEqual,
	onChange,
	stagedValue,
	onCommit,
	toRemove,
}: CommitSyncProps) {
	const previousCommited = usePrevious(committedValue);

	useEffect(() => {
		if (!isStageAndCommittedEqual) {
			let needsSync = false;
			let emptyKeys: Array<string> = difference(
				Array.from(previousCommited?.keys() || []),
				Array.from(committedValue?.keys() || [])
			);
			let newKeys: Array<string> = [];

			committedValue?.forEach((value, key) => {
				const stagedValueForOption = stagedValue?.get(key)?.isSelected;
				const isCommittedButNotStaged =
					!stagedValueForOption &&
					value.isSelected === true &&
					toRemove.includes(key) === false &&
					facet.facetable !== false;

				if (isCommittedButNotStaged) {
					newKeys.push(key);
					needsSync = true;
				}
			});

			needsSync = needsSync || emptyKeys.length > 0;
			if (!needsSync) {
				return;
			}

			if (emptyKeys.length) {
				let newCommittedValue = produce(committedValue, draft => {
					emptyKeys.forEach(key => {
						draft?.delete(key);
					});
				});

				onCommit &&
					onCommit({
						facetName: facet.name,
						options: newCommittedValue || new Map(),
					});
			} else if (newKeys.length) {
				newKeys.forEach(newOption => {
					onChange({
						facetName: facet.name,
						optionName: newOption,
						value: true,
						operator:
							committedValue?.get(newOption)?.operator ?? Operator.EQUAL,
					});
				});
			}
		}
	}, [
		previousCommited,
		committedValue,
		facet.name,
		isStageAndCommittedEqual,
		onChange,
		stagedValue,
		onCommit,
		toRemove,
		facet.facetable,
	]);
}

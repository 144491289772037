import LoadingButton from "@mui/lab/LoadingButton";
import {
	Checkbox,
	Link,
	Modal,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useState } from "react";

export const TermsAndConditionModal = ({
	acceptTermsAndCondition,
	showAcceptTnCModal,
	loading,
}: {
	acceptTermsAndCondition: () => void;
	showAcceptTnCModal: boolean;
	loading: boolean;
}) => {
	const theme = useTheme();
	const style = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 600,
		bgcolor: theme.palette.mode === "light" ? "#fafafa" : "#222",
		boxShadow: 24,
		p: 5,
	};

	const [selection, setSelection] = useState(false);

	return (
		<Modal open={showAcceptTnCModal} disableAutoFocus={true}>
			<Stack sx={style}>
				<Typography variant="h6" component="h2">
					{window.getCTTranslatedText("updatedTnC")}
				</Typography>
				<Typography variant="body1" sx={{ mt: 2 }}>
					{window.getCTTranslatedText("updatedTnCText")}
				</Typography>
				<Stack flexDirection="row" alignItems="center" sx={{ mt: 2 }}>
					<Checkbox
						size="small"
						checked={selection}
						onChange={() => setSelection(!selection)}
					/>
					<Typography variant="body1" sx={{ ml: 1, mr: 0.5 }}>
						{window.getCTTranslatedText("TnCRead")}
					</Typography>{" "}
					<Link
						href="https://colortokens.com/terms-of-service/"
						target="_blank"
					>
						{window.getCTTranslatedText("Terms and conditions")}
					</Link>
				</Stack>
				<Stack alignItems="flex-end" sx={{ mt: 2 }}>
					<LoadingButton
						disabled={!selection}
						variant="contained"
						sx={{ width: "fit-content" }}
						onClick={acceptTermsAndCondition}
						loading={loading}
					>
						{window.getCTTranslatedText("Accept & Continue")}
					</LoadingButton>
				</Stack>
			</Stack>
		</Modal>
	);
};

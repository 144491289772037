import {
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { DataGridButton } from "common/atoms/data-grid-button";

import { getStatus } from "common/utils/statusUtils";
import { CustomDetailPanelToggle } from "modules/data-grid/components/data-grid";
import { PathDetails } from "pages/paths/components/path-details";
import { StatusText } from "pages/paths/constants";
import { PortDetails } from "pages/ports/components/port-details";

export const PORT_STATUS_COLUMNS: GridColDef[] = [
	{
		field: "listenport",
		headerName: "Port",
		minWidth: 160,
		flex: 0.8,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			if (params.row.listenPort !== undefined) {
				return (
					<PortDetails
						viewOnly
						port={params.row}
						showInternetFacingComp={false}
					/>
				);
			}

			return <PathDetails viewOnly path={params.row} />;
		},
	},
	{
		field: "reviewed",
		headerName: "Preview Status",
		minWidth: 160,
		flex: 1.2,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			const path = params.row;
			const status = StatusText[path.previewStatus];
			const statusDetails = status
				? getStatus(status)
				: {
						status: path.previewStatus,
					};

			return (
				<DataGridButton asText disabled variant={"text"} color={"inherit"}>
					{window.getCTTranslatedText(statusDetails.status)}
				</DataGridButton>
			);
		},
	},
];

export const PORT_STATUS_DETAIL_COLUMNS: GridColDef[] = [
	...PORT_STATUS_COLUMNS,
	{
		...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
		renderCell: (params: GridRenderCellParams<any, any, any>) => (
			<CustomDetailPanelToggle id={params.id} value={params.value} />
		),
	},
];

import {
	ButtonProps,
	Link,
	SvgIconProps,
	SxProps,
	Typography,
} from "@mui/material";
import { DataGridButton } from "common/atoms/data-grid-button";
import { useCommonStore } from "common/store";
import { CustomCommonStoreType } from "common/types/types";
import { MouseEvent, ReactNode } from "react";
import { getColor, getVariant } from "./helpers";

interface CTDatagridButtonProps {
	asText?: boolean;
	linkTo?: string;
	asLink?: boolean;
	isDisabled?: boolean;
	useFacetStore?: CustomCommonStoreType;
	facetName: string;
	facetOption: string;
	sx?: SxProps;
	children?: ReactNode;
	startIcon?: React.ReactElement<SvgIconProps>;
	endIcon?: React.ReactElement<SvgIconProps>;
	stopPropagation?: boolean;
	color?: ButtonProps["color"];
}

export function CTDatagridButton({
	asText = false,
	linkTo,
	isDisabled,
	useFacetStore = useCommonStore,
	facetName,
	facetOption,
	sx,
	children,
	startIcon,
	endIcon,
	stopPropagation = true,
	color,
}: CTDatagridButtonProps) {
	const facetState = useFacetStore(state => state.facets);
	const updateFacetOption = useFacetStore(state => state.updateFacetOption);

	const isFacetSelected = facetState
		?.get(facetName)
		?.get(facetOption)?.isSelected;

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		if (stopPropagation) {
			e.stopPropagation();
		}
		updateFacetOption({
			facetName: facetName,
			optionName: facetOption,
			value: !isFacetSelected,
		});
	};

	if (linkTo) {
		return (
			<Typography component={Link} href={linkTo} sx={{ ...sx }} variant="body2">
				{children ? children : facetOption}
			</Typography>
		);
	}

	return (
		<DataGridButton
			asText={asText}
			disabled={isDisabled}
			sx={sx}
			variant={getVariant({ asText, isFacetSelected })}
			color={getColor({ isFacetSelected, color, asText })}
			startIcon={startIcon}
			endIcon={endIcon}
			onClick={handleClick}
		>
			{children || (
				<Typography sx={sx} variant="body2">
					{facetOption}
				</Typography>
			)}
		</DataGridButton>
	);
}

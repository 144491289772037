import { IconsProps } from "./common";

export const DockerIcon = ({
	width = "16px",
	height = "16px",
	color = "#000",
}: IconsProps) => {
	return (
		<svg
			width={width}
			height={height}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
		>
			<g
				fill="none"
				stroke={color || "currentColor"}
				strokeLinecap="round"
				strokeLinejoin="round"
				data-name="&lt;Group&gt;"
			>
				<circle cx="5.04" cy="16" r=".5" data-name="&lt;Path&gt;" />
				<path
					d="M1.5 9.5h3v3h-3zM4.5 9.5h3v3h-3zM7.5 9.5h3v3h-3zM10.5 9.5h3v3h-3zM4.5 6.5h3v3h-3zM7.5 6.5h3v3h-3zM10.5 6.5h3v3h-3zM10.5 3.5h3v3h-3zM13.5 9.5h3v3h-3z"
					data-name="&lt;Rectangle&gt;"
				/>
				<path
					d="M23.5 11.5s-1.75-1.12-3-.5A3.45 3.45 0 0 0 19 8.5a3.64 3.64 0 0 0-.58 2.88 1 1 0 0 1-1 1.12H.5c0 6.25 3.83 8 7.5 8a13.76 13.76 0 0 0 12.06-7 4.68 4.68 0 0 0 3.44-2Z"
					data-name="&lt;Path&gt;"
				/>
			</g>
		</svg>
	);
};

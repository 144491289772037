import { CoreStoreBuilder } from "modules/core/store";
import { create } from "zustand";
import { UserStore } from "./types";
import { CustomCommonStoreType } from "common/types/types";
import { FacetStoreBuilder } from "modules/facets";
import { CustomCommonStore } from "pages/paths/components/paths-review-drawer/store/common/usePathListCommonStore";

export const useAssetCustomUserStore: CustomCommonStoreType =
	create<CustomCommonStore>()(set => ({
		...FacetStoreBuilder(set),
	}));

export const useUserStore = create<UserStore>()(set => ({
	...CoreStoreBuilder(set),
}));
export const useAssetUserStore = create<UserStore>()(set => ({
	...CoreStoreBuilder(set),
}));

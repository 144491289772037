export enum FormBuilderElementType {
	Tag = "tag",
	Text = "text",
	BusinessValue = "businessValue",
}

export type FormBuilderElement = {
	key: string;
	value: string;
	type: FormBuilderElementType;
	columns: number;
	label: string;
};

import { Asset } from "pages/assets/types";

export function AssetNameInternal({ asset }: { asset?: Asset }) {
	let name = asset?.assetName;
	if (asset?.assetId && !name) {
		name = "Decommissioned Asset";
	}

	return name;
}

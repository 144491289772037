import { GridRowId } from "@mui/x-data-grid";
import { parseErrorMessage } from "common/utils";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useAlertConfigStatusAPI } from "../../hooks/useAlertConfig/useAlertConfigAPI";
import { AlertNotificationStatus } from "../../types";

interface NotificationStatusChangeActionBarProps {
	selectedStatus: AlertNotificationStatus | undefined;
	selection: Array<GridRowId>;
	cancel: () => void;
	update: (data: Array<GridRowId>) => void;
}

export const NotificationStatusChangeActionBar = ({
	selectedStatus,
	selection,
	cancel,
	update,
}: NotificationStatusChangeActionBarProps) => {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const alertRuleStatusAPI = useAlertConfigStatusAPI();

	const updateStatus = async (
		selection: Array<GridRowId>,
		selectedStatus: AlertNotificationStatus | undefined
	) => {
		if (selection?.length > 0 && selectedStatus) {
			const getAllAlertIds = selection.map(obj => `'${obj}'`);
			const body = {
				criteria: `alertruleid in (${getAllAlertIds.join(", ")})`,
				notificationStatus: selectedStatus,
			};

			alertRuleStatusAPI.mutateAsync(body, {
				onSuccess: response => {
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						"NotificationTurnedStatusSuccessfully",
						{ status: window.getCTTranslatedText(selectedStatus) }
					);
					update(selection);
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		}
	};

	const confirmAction = () => {
		if (selection?.length > 0) {
			updateStatus(selection, selectedStatus);
		}
	};
	return (
		<ToolbarAction
			loading={alertRuleStatusAPI?.isLoading}
			isValid={selection?.length > 0}
			actionBtnText={"Confirm"}
			save={confirmAction}
			cancel={cancel}
		></ToolbarAction>
	);
};

import { Box, Stack, Tooltip } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { ReportColumnRenderProps } from "pages/reports/types";

export function ReportName({
	report,
	viewOnly = false,
}: ReportColumnRenderProps) {
	const reportName = report.historyName;

	if (!reportName) {
		return <Box style={{ minWidth: 310, minHeight: 45 }} />;
	}

	return (
		<Stack
			sx={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
			alignItems="left"
			justifyContent="center"
		>
			<Tooltip
				title={
					viewOnly
						? ""
						: `${window.getCTTranslatedText("Show only for")} ${reportName}`
				}
			>
				<CTDatagridButton
					asText={viewOnly}
					isDisabled={viewOnly}
					facetName={"historyname"}
					facetOption={reportName!}
				>
					{reportName}
				</CTDatagridButton>
			</Tooltip>
		</Stack>
	);
}

import { ANTLRInputStream, CommonTokenStream } from "antlr4ts";
import { ctqlLexer } from "./parser/ctqlLexer";
import { ctqlParser } from "./parser/ctqlParser";

export function grammar(input: string) {
	let inputStream = new ANTLRInputStream(input);
	let lexer = new ctqlLexer(inputStream);
	let tokenStream = new CommonTokenStream(lexer);
	let parser = new ctqlParser(tokenStream);

	let tree = parser.clause();
	return tree;
}

import { useQuery } from "@tanstack/react-query";

export interface AgentOtpResponse {
	otp: string;
	expirationTime: string;
}

interface AgentOtpAPIProps {
	enabled: boolean;
}

export function useAgentOtpAPI({ enabled }: AgentOtpAPIProps) {
	return useQuery<AgentOtpResponse, Error>(["otp", `agents/otp`], {
		enabled: enabled,
	});
}

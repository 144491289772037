import { Stack } from "@mui/material";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { HostAndUserGroupChangeStatusDrawer } from "modules/host-and-user-group-change-status-drawer";
import { useState } from "react";
import { useHostGroupStore } from "../../store";
import { HostGroup } from "../../types";
import {
	DrawerType,
	HostAndUserGroupStatusEnum,
} from "modules/host-and-user-group-change-status-drawer/HostAndUserGroupChangeStatusDrawer";
import { UserGroupUpdateAction } from "pages/users-segmentation/components/user-groups/components/user-group-data-grid-toolbar/UserGroupToolbarAction";

export interface HostGroupToolbarProps extends Omit<ToolbarProps, "children"> {
	selectedData: Array<HostGroup> | undefined;
	hideToolbar: () => void;
	onSelectCallback?: Function;
	multiselect?: boolean;
}

export enum UserGroupStatus {
	Enable = "Active",
	Disable = "Inactive",
}

export function HostGroupToolbar(props: Readonly<HostGroupToolbarProps>) {
	const requestAPIRefresh = useHostGroupStore(store => store.requestAPIRefresh);
	const [showChangeStatusDrawer, setShowChangeStatusDrawer] = useState(false);
	const [selectedOperation, setSelectedOperation] = useState<
		HostAndUserGroupStatusEnum | undefined
	>();

	const handleButtonChange = (btnActionType: string) => {
		setSelectedOperation(btnActionType as HostAndUserGroupStatusEnum);
		setShowChangeStatusDrawer(true);
	};

	const handleConfirm = () => {
		setSelectedOperation(undefined);
		setShowChangeStatusDrawer(false);
		requestAPIRefresh();
		props.hideToolbar();
	};
	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}>
			<Stack
				direction="row"
				spacing={2}
				sx={{ width: "100%", p: 1 }}
				justifyItems="flex-end"
				alignItems={"flex-end"}
				justifyContent="flex-end"
			>
				<Stack direction="row" alignItems={"center"}>
					<Stack direction="row">
						{(props.selectedData?.length ?? 0) > 0 && (
							<UserGroupUpdateAction {...{ handleButtonChange }} />
						)}

						{showChangeStatusDrawer && (
							<HostAndUserGroupChangeStatusDrawer
								isOpen={showChangeStatusDrawer}
								selectedStatus={selectedOperation}
								selectedData={props?.selectedData}
								confirm={handleConfirm}
								onClose={() => {
									setShowChangeStatusDrawer(false);
									setSelectedOperation(undefined);
									props.hideToolbar();
								}}
								drawerType={DrawerType.HostGroup}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>
		</GridToolbar>
	);
}

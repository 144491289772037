import { Box, Typography } from "@mui/material";

export const NoDataFound = ({ text }: { text: string }) => {
	return (
		<Box
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, 50%)",
			}}
		>
			<Typography variant="subtitle1">
				{window.getCTTranslatedText(text)}
			</Typography>
		</Box>
	);
};

import { TemplateColumnRenderProps } from "../../types";
import { Stack, Tooltip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function TemplateTypeIcon({
	template,
	viewOnly,
}: TemplateColumnRenderProps) {
	const facetName = "oobtemplate";
	const optionName = template.oobTemplate ? "True" : "False";

	const iconProps = { sx: { width: 24, height: 24, mt: 1.25 } };
	let icon = <PersonIcon {...iconProps}></PersonIcon>;
	let text = "User defined template";
	if (template?.oobTemplate) {
		icon = <SettingsIcon {...iconProps}></SettingsIcon>;
		text = "System defined template";
	}

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
			<Tooltip title={window.getCTTranslatedText(text)}>
				<CTDatagridButton
					asText={viewOnly}
					isDisabled={viewOnly}
					facetName={facetName}
					facetOption={optionName}
				>
					{icon}
				</CTDatagridButton>
			</Tooltip>
		</Stack>
	);
}

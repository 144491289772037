import {
	Button,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Link,
	Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
	getRecommendationRoutes,
	getRecomnmendationPermission,
	getRecomnmendationText,
} from "./helpers";
import {
	Recommendation,
	RecommendationProps,
	RecommendationCategoryText,
	RecommendationCategory,
} from "./types";
import { CircularCenteredLoader } from "common/atoms/loader";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";

const cellStyle = {
	py: 1.5,
};
const cellStyleTop = {
	py: 1.5,
};
const btnStyle = {
	minWidth: "130px",
};
const noDataBlockStyle = {
	width: "100%",
	minHeight: "250px",
	textAlign: "center",
};

const loaderBlockStyle = {
	position: "relative",
	width: "100%",
	minHeight: "200px",
	textAlign: "center",
};

export function RecommendedActions({
	data: recommendationList,
	type: recommendationCategory,
	isLoading,
}: RecommendationProps) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	return (
		<>
			{!isLoading && recommendationList && recommendationList?.length > 0 && (
				<Stack>
					<TableContainer>
						<Table size="medium">
							<TableHead>
								<TableRow>
									<TableCell colSpan={3}>{`${window.getCTTranslatedText(
										"Description"
									)}`}</TableCell>
									<TableCell colSpan={3}>{`${window.getCTTranslatedText(
										"Category"
									)}`}</TableCell>
									<TableCell colSpan={2}>{`${window.getCTTranslatedText(
										"Action"
									)}`}</TableCell>
								</TableRow>
							</TableHead>

							{(recommendationList || []).map(
								(recommendation: Recommendation, index: number) => {
									return (
										<TableBody key={recommendation?.name}>
											<TableRow>
												<TableCell
													component="td"
													scope="row"
													colSpan={3}
													sx={index === 0 ? cellStyleTop : cellStyle}
												>
													{getRecomnmendationText(recommendation)}
												</TableCell>
												<TableCell
													component="td"
													scope="row"
													colSpan={3}
													sx={index === 0 ? cellStyleTop : cellStyle}
													style={{ textTransform: "capitalize" }}
												>
													{window.getCTTranslatedText(
														RecommendationCategoryText[
															recommendation?.category as RecommendationCategory
														]
													)}
												</TableCell>
												<TableCell
													colSpan={2}
													sx={index === 0 ? cellStyleTop : cellStyle}
												>
													{" "}
													{userPermissions.has(
														getRecomnmendationPermission(recommendation)
													) && (
														<Link
															to={getRecommendationRoutes(
																recommendation,
																userPermissions.has("UPDATE_ASSET_ZEROTRUST")
															)}
															component={RouterLink}
															underline="none"
														>
															<Button variant="outlined" sx={btnStyle}>
																{getRecomnmendationText(
																	recommendation,
																	"btnTitle",
																	userPermissions.has("UPDATE_ASSET_ZEROTRUST")
																)}
															</Button>
														</Link>
													)}
												</TableCell>
											</TableRow>
										</TableBody>
									);
								}
							)}
						</Table>
					</TableContainer>
				</Stack>
			)}
			{!isLoading && recommendationList && recommendationList?.length === 0 && (
				<Stack
					alignContent="center"
					justifyContent="center"
					justifyItems="center"
					sx={noDataBlockStyle}
				>
					<Typography variant="body1">
						{window.getCTTranslatedText("No recommendations available")}
					</Typography>
				</Stack>
			)}
			{isLoading && (
				<Stack
					alignContent="center"
					justifyContent="center"
					justifyItems="center"
					sx={loaderBlockStyle}
				>
					<CircularCenteredLoader></CircularCenteredLoader>
				</Stack>
			)}
		</>
	);
}

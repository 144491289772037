import Uppy from "@uppy/core";
import ImageEditor from "@uppy/image-editor";
import { DashboardModal } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";
import React from "react";
import { FileUploaderConfig, FileUploaderProps } from "./types";

import { CircularProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/image-editor/dist/style.css";
import { useEffect } from "react";
import "./uploader-styles.css";

interface UploadURLResponse {
	url: string;
	expires_in: number;
}

function useTenantLogoUploadAPI() {
	return useMutation<UploadURLResponse, Error>([
		"tenant-logo-upload",
		"auth/admin/tenant-logo",
	]);
}

export function FileUploader({
	config,
	onRequestClose,
	open,
}: FileUploaderProps) {
	const api = useTenantLogoUploadAPI();
	const mutate = api.mutate;
	const reset = api.reset;

	useEffect(() => {
		if (open) {
			mutate();
		}
	}, [mutate, open]);

	useEffect(() => {
		if (!api.data?.expires_in) {
			return;
		}
		let timer = setTimeout(() => {
			mutate();
			reset();
		}, api.data?.expires_in * 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [mutate, reset, api.data?.expires_in]);

	if (!open) {
		return null;
	}
	if (!api.data) {
		return <CircularProgress size={24} />;
	}
	return (
		<UploadDashboard
			open={open}
			onRequestClose={onRequestClose}
			config={config}
			uploadURL={api.data.url}
		/>
	);
}

interface UploadDashboardProps extends FileUploaderProps {
	config: FileUploaderConfig;
	uploadURL: string;
}

function UploadDashboardInternal({
	config,
	uploadURL,
	open,
	onRequestClose,
}: UploadDashboardProps) {
	const uppy = React.useMemo(() => {
		let instance = new Uppy({
			restrictions: {
				minNumberOfFiles: 1,
				maxFileSize: config.maxFileSize,
				minFileSize: config.minFileSize,
				allowedFileTypes: config.fileTypes,
				maxNumberOfFiles: config.maxFiles,
			},
		});

		if (config.showImageEditor) {
			instance.use(ImageEditor, {
				actions: {
					revert: true,
					rotate: true,
					granularRotate: false,
					flip: true,
					zoomIn: true,
					zoomOut: true,
					cropSquare: true,
					cropWidescreen: false,
					cropWidescreenVertical: false,
				},
				cropperOptions: {
					aspectRatio: 1 / 1,
					croppedCanvasOptions: {},
				},
			});

			instance.on("file-added", file => {
				const dashboard = instance.getPlugin("react:DashboardModal");
				if (!dashboard) {
					return;
				}
				requestAnimationFrame(() => {
					// @ts-ignore
					dashboard.openFileEditor(file);
				});
			});
		}
		return instance;
	}, [config]);

	useEffect(() => {
		let oldUploadPluginInstance = uppy.getPlugin("XHRUpload");
		if (oldUploadPluginInstance) {
			uppy.removePlugin(oldUploadPluginInstance);
		}
		uppy.use(XHRUpload, {
			formData: false,
			endpoint: uploadURL,
			method: "PUT",
			headers: {
				"x-ms-blob-type": "BlockBlob",
			},
		});
	}, [uppy, uploadURL]);

	React.useEffect(() => {
		return () => {
			// uppy.close({ reason: "unmount" });
		};
	}, [uppy]);

	return (
		<DashboardModal
			open={open}
			onRequestClose={onRequestClose}
			proudlyDisplayPoweredByUppy={false}
			uppy={uppy}
			plugins={config.showImageEditor ? ["ImageEditor"] : []}
		/>
	);
}

const UploadDashboard = React.memo(UploadDashboardInternal);

import { Paper, styled } from "@mui/material";

export const CreateFirewallHostGroupWrapper = styled(Paper)`
	& {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: ${({ theme }) => theme.spacing(4)};
	}

	.title,
	.criteria,
	.actions {
		grid-column: span 2;
	}
`;

import { FacetDisplayOptions, FacetGroupInfo } from "modules/core/types";
import { FacetGroups } from "modules/facets/types";
export const FACET_ORDER: FacetGroupInfo = {
	active: FacetGroups.Users,
	useremail: FacetGroups.Users,
	displayname: FacetGroups.Users,
	signedin: FacetGroups.Users,
	groupname: FacetGroups.Groups,
	usergroupstatus: FacetGroups.Groups,
	assetname: FacetGroups.Assets,
	idpname: FacetGroups.IdP,
};

export const FACET_OPTION_MAPPING: FacetDisplayOptions = {
	active: {
		valuesDisplayOptions: {
			true: { displayName: "Active" },
			false: { displayName: "Inactive" },
		},
	},
	signedin: {
		valuesDisplayOptions: {
			true: { displayName: "loggedIn" },
			false: { displayName: "loggedOut" },
			"<nil>": { displayName: "No Value" },
		},
	},
};

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, alpha, useTheme } from "@mui/material";
import { InputBase } from "common/atoms/input-base";
import { usePrevious } from "common/hooks/usePrevious";
import debounce from "lodash/debounce";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { StringParam } from "serialize-query-params";
import { useQueryParam } from "use-query-params";
import { SearchProps } from "../types";

const searchBarCollapsedWidth = "40px";
const searchBarExpandedWidth = "360px";

function CTSearch({
	search,
	setSearch,
	placeholder,
	crossIconColor,
}: SearchProps) {
	const theme = useTheme();
	const [querySearch, setQuerySearch] = useQueryParam("search", StringParam);
	const [inputValue, setInputValue] = useState(querySearch || "");

	const queryRef = useRef(querySearch);
	queryRef.current = querySearch;

	const searchRef = useRef(search);
	searchRef.current = search;

	const updateSearch = useMemo(
		() =>
			debounce((value: string) => {
				setQuerySearch(value || undefined);
				setSearch(value || "");
			}, 300),
		[setQuerySearch, setSearch]
	);

	useEffect(() => {
		updateSearch(inputValue || "");
	}, [inputValue, updateSearch]);

	let location = useLocation();
	useEffect(() => {
		const searchValue = queryRef.current || searchRef.current;
		setQuerySearch(searchValue || undefined);
		setInputValue(searchValue || "");
	}, [location.pathname, setQuerySearch, setSearch]);

	const previousSearch = usePrevious(search);
	useEffect(() => {
		if (!search && previousSearch) {
			// clearing search
			setInputValue("");
			return;
		}
		if (
			search &&
			previousSearch !== search &&
			(!previousSearch ||
				(false === search.startsWith(previousSearch) &&
					false === previousSearch.includes(search)))
		) {
			// adding new search from data grid
			setInputValue(search);
		}
	}, [previousSearch, search]);

	const searchDisplay = inputValue || "";

	const onClearSearch = () => {
		setSearch("");
		setInputValue("");
	};

	const onInputChange = (value: string) => {
		setInputValue(value);
	};

	const searchStyles = {
		borderRadius: 30,
		backgroundColor:
			searchDisplay?.length > 0
				? alpha(theme.palette.common.white, 0.1)
				: "unset",
		width:
			searchDisplay?.length > 0
				? searchBarExpandedWidth
				: searchBarCollapsedWidth,
		minHeight: 40,
		transition: theme.transitions.create(["width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		"&:hover": {
			backgroundColor: alpha(theme.palette.common.white, 0.08),
		},
		"&.Mui-focused": {
			width: searchBarExpandedWidth,
			backgroundColor: alpha(theme.palette.common.white, 0.1),
		},
	};

	return (
		<InputBase
			placeholder={placeholder || "Search…"}
			inputProps={{ "aria-label": "search" }}
			onChange={e => onInputChange(e.target.value)}
			value={searchDisplay}
			sx={{ ...searchStyles }}
			startAdornment={
				<InputAdornment position="end">
					<SearchIcon
						sx={{
							color: theme.palette.common.white,
							pointerEvents: "none",
						}}
					/>
				</InputAdornment>
			}
			endAdornment={
				<InputAdornment position="end" sx={{ paddingRight: "6px" }}>
					<IconButton
						color="inherit"
						aria-label="cross icon"
						onClick={onClearSearch}
						onMouseDown={() => {}}
						edge="end"
					>
						{searchDisplay.length > 0 && (
							<ClearIcon
								fontSize="small"
								sx={{ color: crossIconColor ?? "inherit" }}
							/>
						)}
					</IconButton>
				</InputAdornment>
			}
		/>
	);
}

export const Search = CTSearch;

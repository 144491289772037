import AutoModeIcon from "@mui/icons-material/AutoMode";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import { MoreOptionsMenu } from "common/molecules/more-options-menu";
import { MenuOption } from "common/molecules/more-options-menu/types";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { AgentStatusType } from "pages/agents/types";
import {
	ApplianceActionsMap,
	ApplianceColumnRenderProps,
} from "pages/appliances/types";
import { getPendingActionsTooltip } from "pages/appliances/utils";
import { useState } from "react";
import { applianceDiagnosticSupportedMinVersion } from "../appliance-data-grid/constants";
import { ApplianceDiagnostic } from "../appliance-diagnostic";
import { ConfigApplianceDrawer } from "../config-appliance-drawer";
import { RecoverApplianceDrawer } from "../recover-appliance-drawer";
import { ApplianceDecommission } from "./components/ApplianceDecommission";
import { ApplianceReboot } from "./components/ApplianceReboot";

export function ApplianceActionsMenu({
	agent,
	triggerButtonText,
}: Readonly<ApplianceColumnRenderProps>) {
	const [showDiagnostics, setShowDiagnostics] = useState(false);
	const [showConfigure, setShowConfigure] = useState(false);
	const [showRecover, setShowRecover] = useState(false);
	const [showReboot, setShowReboot] = useState<boolean>(false);
	const [showDecommission, setShowDecommission] = useState<boolean>(false);

	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const canUpdate = userPermissions.has("UPGRADE_AGENT");

	let diagnosticsTooltipText = window.getCTTranslatedText("viewDiagnostics");
	const disabledActionsTooltip = getPendingActionsTooltip(agent);

	if (!agent?.agentId) {
		diagnosticsTooltipText = window.getCTTranslatedText("agentUnavailable");
	} else if (agent?.currentVersion === "") {
		diagnosticsTooltipText = window.getCTTranslatedText(
			"agentVersionUnavailable"
		);
	} else if (
		agent?.isDiagnosticsRunning &&
		(agent.agentStatus === AgentStatusType.Active ||
			agent.agentStatus === AgentStatusType.Absent ||
			agent.agentStatus === AgentStatusType.Unknown)
	) {
		diagnosticsTooltipText =
			agent.agentStatus === AgentStatusType.Active
				? window.getCTTranslatedText("diagnosticsRequestInProgress")
				: window.getCTTranslatedText("diagnosticsRequestPending");
	} else if (agent?.currentVersion && !agent?.isDiagnosticsSupported) {
		diagnosticsTooltipText = window.getCTTranslatedText(
			"agentVersionMustBeHigher",
			{
				version: applianceDiagnosticSupportedMinVersion,
			}
		);
	}

	const isDiagnosticDisabled = () =>
		Boolean(
			!agent?.agentId &&
				(agent?.isDiagnosticsRunning || !agent?.isDiagnosticsSupported)
		);

	const isRecoveryEnabled = () =>
		Boolean(
			agent?.gatekeeperConfigUpdateAllowed &&
				agent?.agentStatus !== AgentStatusType.Active
		);

	const menuOptions: MenuOption[] = [
		{
			label: window.getCTTranslatedText("Configure"),
			handler: () => setShowConfigure(true),
			icon: <SettingsOutlined />,
		},
		...(agent?.gatekeeperConfigUpdateAllowed && canUpdate
			? [
					{
						label: window.getCTTranslatedText("rebootButton"),
						handler: () => setShowReboot(true),
						disabled: Boolean(disabledActionsTooltip),
						tooltipMessage: disabledActionsTooltip,
						icon: <RestartAltIcon />,
					},
				]
			: []),
		{
			label: window.getCTTranslatedText("viewDiagnostics"),
			handler: () => setShowDiagnostics(true),
			disabled: isDiagnosticDisabled(),
			tooltipMessage: diagnosticsTooltipText,
			icon: <TroubleshootIcon />,
		},
		...(isRecoveryEnabled()
			? [
					{
						label: window.getCTTranslatedText("Recover"),
						handler: () => setShowRecover(true),
						disabled: Boolean(
							disabledActionsTooltip.includes(ApplianceActionsMap.CONFIG_UPDATE)
						),
						tooltipMessage: disabledActionsTooltip,
						icon: <AutoModeIcon />,
					},
				]
			: []),
		{
			label: window.getCTTranslatedText("decommissionButton"),
			handler: () => setShowDecommission(true),
			disabled: Boolean(
				disabledActionsTooltip.includes(ApplianceActionsMap.CONFIG_UPDATE) ||
					disabledActionsTooltip.includes(ApplianceActionsMap.DECOMMISSION)
			),
			tooltipMessage: disabledActionsTooltip,
			icon: <DeleteOutlineIcon />,
		},
	];

	return (
		<>
			{menuOptions?.length ? (
				<MoreOptionsMenu
					size="medium"
					menuOptions={menuOptions}
					triggerButtonText={triggerButtonText}
				/>
			) : null}

			<ApplianceDiagnostic
				agent={agent}
				isOpen={showDiagnostics}
				onClose={() => setShowDiagnostics(false)}
			/>

			<ConfigApplianceDrawer
				agent={agent}
				isOpen={showConfigure}
				onClose={() => setShowConfigure(false)}
			/>

			{isRecoveryEnabled() && (
				<RecoverApplianceDrawer
					agent={agent}
					isOpen={showRecover}
					onClose={() => setShowRecover(false)}
				/>
			)}

			<ApplianceReboot
				agent={agent}
				showReboot={showReboot}
				setShowReboot={setShowReboot}
			/>

			<ApplianceDecommission
				agent={agent}
				showDecommission={showDecommission}
				setShowDecommission={setShowDecommission}
			/>
		</>
	);
}

import differenceBy from "lodash/differenceBy";
import find from "lodash/find";
import unionBy from "lodash/unionBy";
import { WorkRequestStatus } from "modules/AppBar/types";
import { PendingWorkRequestData } from "modules/PendingWorkRequestsDrawer/types";

export const getOptimisticDataGridData = (
	oldData: PendingWorkRequestData,
	newData: PendingWorkRequestData
): PendingWorkRequestData => {
	const updatedOldRows = oldData.rows?.map(row => {
		const match = find(newData.rows, ["id", row.id]);
		if (match) {
			return match;
		} else {
			return { ...row, status: WorkRequestStatus.Completed };
		}
	});

	const newlyAddedRows = differenceBy(newData.rows, updatedOldRows ?? [], "id");

	const mergedRows = unionBy(newlyAddedRows, updatedOldRows, "id").sort(
		(a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
	);

	return {
		rows: mergedRows,
		rowCount: mergedRows.length,
		page: newData.page,
		pageSize: newData.pageSize,
	};
};

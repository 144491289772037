import { Stack, Typography } from "@mui/material";

export interface SecurityTitleBlockProps {
	title: string;
	description?: string;
	variant?: string;
	addBackground?: boolean;
}

const subTitleStyles = {
	px: 3,
	py: 2,
	width: "100%",
};

export const SecurityTitleBlock = ({
	title,
	description,
}: SecurityTitleBlockProps) => {
	return (
		<Stack sx={subTitleStyles}>
			<Stack alignItems={"left"}>
				<Typography variant={"h6"} sx={{ textTransform: "capitalize" }}>
					{window.getCTTranslatedText(title)}
				</Typography>
			</Stack>
			{description && (
				<Typography
					variant="caption"
					sx={{
						textTransform: "uppercase",
					}}
				>
					{window.getCTTranslatedText(description)}
				</Typography>
			)}
		</Stack>
	);
};

import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { Badge, BadgeProps, IconButton, Stack, styled } from "@mui/material";
import numeral from "numeral";

const BadgeCount = styled(Badge)<BadgeProps>(({ theme }) => ({
	"& .MuiBadge-badge": {
		right: -3,
		top: -4,
	},
}));

interface AnnotationCountProps {
	count: number;
	clickCallback: (event: any) => void;
}

export function AnnotationCount({
	count,
	clickCallback,
}: AnnotationCountProps) {
	return (
		<>
			<Stack
				sx={{
					maxWidth: "100%",
					cursor: "pointer",
				}}
				alignItems="center"
				justifyContent="center"
				direction="row"
				onClick={clickCallback}
			>
				<IconButton size="small">
					<BadgeCount
						invisible={!count || count === 0}
						badgeContent={count ? numeral(count).format("0a") : ""}
						color="primary"
					>
						<CommentOutlinedIcon
							sx={{ color: theme => theme.palette.primary.main }}
							fontSize="small"
						/>
					</BadgeCount>
				</IconButton>
			</Stack>
		</>
	);
}

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { UserAPIKey } from "../types";

function useDeleteAPIKey(apiKey: string) {
	const path = `auth/user/api-key/${apiKey}`;
	return useMutation<any, Error>(["apiKey", path, "delete"]);
}

export function DeleteAPIKeyButton({ apiKey }: { apiKey: UserAPIKey }) {
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const deleteKeyMutation = useDeleteAPIKey(apiKey.fingerprint);

	const queryClient = useQueryClient();

	const deleteAPIKey = async () => {
		await deleteKeyMutation.mutateAsync();
		setShowDeleteConfirmation(false);
		queryClient.invalidateQueries({
			queryKey: ["apiKey"],
		});
	};

	return (
		<>
			<IconButton
				aria-label="Delete"
				onClick={$event => setShowDeleteConfirmation(true)}
			>
				<DeleteOutlineIcon />
			</IconButton>

			<Dialog
				open={showDeleteConfirmation}
				onClose={() => {
					setShowDeleteConfirmation(false);
				}}
				aria-labelledby="delete-api-key"
			>
				<DialogTitle id="delete-api-key">Delete API Key</DialogTitle>
				<DialogContent>
					Are you sure you want to delete this key{" "}
					<code>{apiKey.fingerprint} </code>?
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						color="secondary"
						onClick={() => {
							setShowDeleteConfirmation(false);
						}}
					>
						Cancel
					</Button>
					<LoadingButton
						loading={deleteKeyMutation.isLoading}
						variant="contained"
						color="error"
						onClick={() => {
							deleteAPIKey();
						}}
					>
						Delete
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
}

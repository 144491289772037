import { CoreStoreBuilder } from "modules/core/store";
import { create } from "zustand";
import { TagStore } from "./types";

export const useTagStore = create<TagStore>()(set => ({
	...CoreStoreBuilder(set),
	currentTag: undefined,
	setCurrentTag: (currentTag: string | undefined) => {
		set({
			currentTag: currentTag,
		});
	},
}));

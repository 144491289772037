import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { CORE_TAGS_ALPHA } from "./constants";
import {
	ScopeMetadata,
	ScopeMetadataColumn,
	ScopeMetadataParams,
} from "./types";
//

export function useScopeMetadata({ scope }: ScopeMetadataParams) {
	let query = useQuery<any, Error, ScopeMetadata | undefined>(
		[`${scope}Metadata`, `fields?scope=${scope}`],
		{
			enabled: Boolean(scope),
			staleTime: 10000, // don't refetch fields for at-least 10 seconds
			refetchOnWindowFocus: false,
		}
	);

	let mappedData = useMemo<ScopeMetadata | undefined>(() => {
		if (!query.data) {
			return query.data;
		}

		let namesToColumn: { [key: string]: ScopeMetadataColumn | undefined } = {};

		if (query.data?.columns) {
			const columns = Object.keys(query.data?.columns ?? []);
			if (columns?.length > 0) {
				columns.forEach((column: string) => {
					if (CORE_TAGS_ALPHA[column]) {
						delete query.data?.columns[column];
					}
				});
			}
		}

		let keys = Object.keys(query.data?.columns || {});
		keys.forEach(key => {
			namesToColumn[query.data?.columns?.[key]?.displayName || ""] =
				query.data?.columns?.[key];
		});

		return {
			...query.data,
			namesToColumn,
			scope,
		};
	}, [query.data, scope]);
	return { ...query, data: mappedData };
}

export const SessionRefreshLimitOptions = [
	{
		displayName: "15 mins",
		value: 15,
	},
	{
		displayName: "30 mins",
		value: 30,
	},
	{
		displayName: "1 hour",
		value: 60,
	},
	{
		displayName: "4 hours",
		value: 240,
	},
	{
		displayName: "1 day",
		value: 1440,
	},
	{
		displayName: "1 week",
		value: 10080,
	},
	{
		displayName: "1 month",
		value: 43200,
	},
];

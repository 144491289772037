import { WorkRequestStatus } from "modules/AppBar/types";

export const getWorkRequestStatusDisplayText = (status: WorkRequestStatus) => {
	switch (status) {
		case WorkRequestStatus.Pending:
		case WorkRequestStatus.InProgress:
			return "inProgress";
		case WorkRequestStatus.Completed:
			return "workRequestCompleted";
		case WorkRequestStatus.Cancelled:
			return "cancelled";
		case WorkRequestStatus.Superseded:
			return "superseded";
		default:
			return "";
	}
};

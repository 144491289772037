import { Stack } from "@mui/material";
import { AgentDownloadList } from "pages/agents/components/agent-download-page/AgentDownloadList";
import { AgentDownloadListHeader } from "pages/agents/components/agent-download-page/AgentDownloadListHeader";
import {
	AgentDownloadSection,
	AgentVersion as DownloadAgentVersion,
} from "./type";

interface AgentDownloadSectionProps
	extends Pick<DownloadAgentVersion, "latestDepKey" | "page"> {
	sections: AgentDownloadSection[];
}

export const AgentDownloadSections = ({
	sections,
	latestDepKey,
	page,
}: AgentDownloadSectionProps) => {
	return (
		<>
			{sections.map(
				(
					{
						header,
						list,
						shouldShowSection,
						shouldShowList,
						shouldShowListItem,
						agentsGroupByPlatforms,
						setAgentsGroupByPlatforms,
						agentType,
					},
					index
				) => {
					if (!shouldShowSection) {
						return null;
					}

					return (
						<Stack key={header.title} spacing={2} width="100%">
							<AgentDownloadListHeader
								title={header.title}
								renderActions={header.renderActions}
							/>
							{shouldShowList && (
								<AgentDownloadList
									agents={list}
									agentType={agentType}
									latestDepKey={latestDepKey}
									page={page}
									agentsGroupByPlatforms={agentsGroupByPlatforms}
									setAgentsGroupByPlatforms={setAgentsGroupByPlatforms}
									shouldShowListItem={shouldShowListItem}
								/>
							)}
						</Stack>
					);
				}
			)}
		</>
	);
};

import { useMutation } from "@tanstack/react-query";
import { TagBasedPolicyAutoPushBody } from "./useTagBasedPolicyAutoPushAPI";

export function useAddAutoPolicyConfigAPI(id: string) {
	let path = `tagbasedpolicies/${id}/automation`;
	return useMutation<TagBasedPolicyAutoPushBody, Error, any>([
		"auto-policy-config",
		path,
		"put",
	]);
}

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";

import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useNetworkStore } from "pages/networks/store";
import { Network } from "pages/networks/types";

export interface NetworkDeleteProps {
	network: Network | undefined;
	handleAllowDelete: Function;
	allowDelete: boolean;
}

const useDeleteNetworksAPI = (namedNetworkId: string | undefined) => {
	return useMutation<any, Error, any>([
		"networks",
		`namednetworks/${namedNetworkId}`,
		"delete",
	]);
};

export function NetworkDelete({
	network,
	handleAllowDelete,
	allowDelete,
}: NetworkDeleteProps) {
	const notify = useEventSubscriptionStore(state => state.notify);
	const deleteNetworksAPI = useDeleteNetworksAPI(network?.namedNetworkId);
	const requestAPIRefresh = useNetworkStore(state => state.requestAPIRefresh);
	const [loading, setLoading] = useState(false);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const queryClient = useQueryClient();

	const updateNetworkDetails = () => {
		setLoading(true);

		deleteNetworksAPI.mutate(
			{},
			{
				onSuccess: response => {
					queryClient.invalidateQueries({
						queryKey: ["networks"],
					});
					setTimeout(() => {
						requestAPIRefresh();
						handleAllowDelete(false);
						setLoading(false);
						notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
							label: "DeleteNamedNetworkRequestSubmittedSuccessfully",
						});
					}, 500);
				},
				onError: error => {
					setLoading(false);
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			}
		);
	};

	return (
		<Box onClick={e => e.stopPropagation()}>
			<Dialog
				open={allowDelete}
				onClose={() => handleAllowDelete(false)}
				aria-labelledby="delete-named-network"
			>
				<DialogTitle id="delete-named-network">
					{window.getCTTranslatedText(`Delete Named Network`)}
				</DialogTitle>
				<DialogContent>
					{`${window.getCTTranslatedText(
						"AreYouSureYouWantToDeleteNamedNetwork",
						{ namedNetworkName: network?.namedNetworkName }
					)}`}
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						color="inherit"
						onClick={() => handleAllowDelete(false)}
					>
						{window.getCTTranslatedText("Cancel")}
					</Button>
					<LoadingButton
						variant="contained"
						loading={deleteNetworksAPI.isLoading || loading}
						onClick={updateNetworkDetails}
						autoFocus
						color={"error"}
						type="submit"
					>
						{window.getCTTranslatedText("Delete")}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</Box>
	);
}

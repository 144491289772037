import { useMutation } from "@tanstack/react-query";
import {
	AlertResponse,
	AlertsCountReq,
} from "modules/NavigationLayout/components/notifications/types";

export function useAlertsAPI() {
	return useMutation<any, Error, any>(["alerts", "alerts?computeTotal=true"]);
}

export function useAlertCountAPI() {
	return useMutation<AlertResponse, Error, AlertsCountReq>([
		"alerts",
		"aggregate",
	]);
}

export function useAlertStatusUpdateAPI() {
	const path = `alerts`;
	return useMutation<any, Error, any>(["integration-validate", path, "put"]);
}

export function useAuditLogsAPI() {
	return useMutation<any, Error, any>([
		"audit-logs",
		"events?computeTotal=true",
	]);
}

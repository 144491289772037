import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Button, Stack } from "@mui/material";

import { TextFieldsValues } from "modules/form-fields/type";
import { hasErrors } from "pages/Integration/components/integration-toolbar-action/helper";

interface IntegrationToolbarActionProps {
	textFieldsValues: TextFieldsValues;
	handlerOnCloseDrawer: VoidFunction;
	updateConfig: VoidFunction;
	loading: boolean;
	formConfig: any;
}

export const IntegrationToolbarAction = ({
	textFieldsValues,
	handlerOnCloseDrawer,
	updateConfig,
	loading,
	formConfig,
}: IntegrationToolbarActionProps) => {
	return (
		<Stack direction="row" justifyContent="flex-end">
			<Button size={"medium"} variant="outlined" onClick={handlerOnCloseDrawer}>
				{window.getCTTranslatedText("Cancel")}
			</Button>
			<LoadingButton
				loading={loading}
				variant="contained"
				type="submit"
				disabled={hasErrors(textFieldsValues, formConfig)}
				onClick={updateConfig}
				sx={{ ml: 1 }}
			>
				{window.getCTTranslatedText("Save")}
			</LoadingButton>
		</Stack>
	);
};

import { DataGrid } from "modules/data-grid/components/data-grid";
import { BASE_INBOUND_PATH_COLUMNS } from "pages/templates/components/template-data-grid/constants";
import { TemplatePath } from "pages/templates/types";
import { useMemo, useState } from "react";

interface TemplateInboundPathsDataGridProps {
	rows: TemplatePath[];
}

export const TemplateInboundPathsDataGrid = ({
	rows,
}: TemplateInboundPathsDataGridProps) => {
	const [pageSize, setPageSize] = useState(100);
	const [inboundPathsPage, setInboundPathsPage] = useState(0);

	const hasServiceInboundPaths = useMemo(() => {
		return rows?.some((row: TemplatePath) => {
			return Boolean(row?.method) || Boolean(row?.uri);
		});
	}, [rows]);

	const columns = useMemo(() => {
		const filteredCols = BASE_INBOUND_PATH_COLUMNS().filter(column => {
			const removeColumns = [];

			if (!hasServiceInboundPaths) {
				removeColumns.push("api");
			}

			return !removeColumns.includes(column.field);
		});

		return filteredCols;
	}, [hasServiceInboundPaths]);

	return (
		<DataGrid
			rows={rows ?? []}
			rowCount={rows?.length ?? 0}
			columns={columns}
			getRowId={({ channelHash }: TemplatePath) => channelHash}
			pageSize={pageSize}
			onPageSizeChange={setPageSize}
			page={inboundPathsPage}
			onPageChange={setInboundPathsPage}
			pagination
			paginationMode="client"
			disableColumnMenu
		/>
	);
};

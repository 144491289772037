import { StoreApi, UseBoundStore } from "zustand";

export interface SubnetOrAllObservedIPsData {
	rowID: string;
	selectionType: CheckboxSelectionType;
	isChecked: boolean;
	selectedIPs?: string[];
}

export interface PartialIPData {
	rowID: string;
	selectionType: CheckboxSelectionType;
	isChecked: boolean;
	selectedIP: string;
	availableIPs: string[];
}

export interface CheckboxSelectionInterface {
	selection: Selection;
	setPartialIPs: (partialIPData: PartialIPData) => void;
	setSubnetOrAllObservedIPs: (
		subnetOrAllObservedIPsData: SubnetOrAllObservedIPsData
	) => void;
	resetSelection: () => void;
}

export type CheckboxSelectionStore = UseBoundStore<
	StoreApi<CheckboxSelectionInterface>
>;

export enum CheckboxSelectionType {
	ALL_IPS = "ALL_IPS",
	PARTIAL_IPS = "PARTIAL_IPS",
	SUBNET = "SUBNET",
}

export type Selection = {
	[rowID: string]:
		| {
				selectedIPs?: {
					[IP: string]: boolean;
				};
				subnet: string;
				selectionType: CheckboxSelectionType;
		  }
		| undefined;
};

import { Button, ButtonProps, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface DisabledTooltipButtonProps
	extends Pick<ButtonProps, "variant" | "disabled"> {
	link: string;
	text: string;
}
function DisabledTooltipButton({
	link,
	text,
	variant,
	disabled,
}: DisabledTooltipButtonProps) {
	const navigate = useNavigate();
	const tooltipText = window.getCTTranslatedText("NoPermissionForAction");

	const button = (
		<Button
			variant={variant}
			onClick={() => navigate(link, { relative: "route" })}
			disabled={disabled}
		>
			{window.getCTTranslatedText(text)}
		</Button>
	);

	if (disabled) {
		return (
			<Tooltip title={tooltipText}>
				<span>{button}</span>
			</Tooltip>
		);
	}

	return button;
}

export default DisabledTooltipButton;

import CircleIcon from "@mui/icons-material/Circle";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { ViewLogsButton } from "modules/agent-logs/ViewLogsButton";
import { AssetMetaDataReadonlyProps, AssetType } from "pages/assets/types";
// import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import { DataGridButton } from "common/atoms/data-grid-button";
import { useNavigate } from "react-router-dom";

// const iconStyle = {
//   color: "rgba(0, 0, 0, 0.38)",
// };

interface BtnLinkProps {
	route: string;
	key: string;
	value: string;
}

function getBtnLink({ route, key, value }: BtnLinkProps) {
	let link = "";
	link = `/${route}`;
	if (key && value) {
		let queryParam = encodeURI(`${key}=${value}`);

		link = `${link}?filters=${queryParam}`;
	}

	return link;
}

export function AssetAgentStatus({ asset }: AssetMetaDataReadonlyProps) {
	const navigate = useNavigate();

	if (!asset?.assetId) {
		return <Box style={{ width: 42 }} />;
	}

	// TODO: Confirm how to fetch fireball status
	const status = asset.agentStatus;

	let statusIcon;
	let text;

	// const icon = <TrackChangesIcon width="24" height="24" sx={iconStyle} />;

	switch (status) {
		case "active":
			statusIcon = <CircleIcon color={"success"} sx={{ fontSize: 12 }} />;
			text = "Active";
			break;
		default:
			statusIcon = <CircleIcon color={"disabled"} sx={{ fontSize: 12 }} />;
			text = "Absent";
	}

	const isDevice = () => {
		return asset?.type === AssetType.Device;
	};

	const processBtnClickCallback = () => {
		let route = "agents";
		let key = "assetname";
		let value = asset?.assetName;
		if (isDevice()) {
			route = "gatekeepers";
			key = "agentname";
			value = asset?.agentName || "";
		}

		const link = getBtnLink({ route, key, value });
		if (link) {
			navigate(link);
		}
	};

	const truncateTextStyles = {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		maxWidth: "100%",
	};

	return (
		<Stack
			direction={"row"}
			spacing={3}
			alignItems="center"
			justifyContent="flex-start"
			sx={{ width: "100%", minHeight: "36.5px" }}
		>
			<Typography variant="subtitle1">
				<b>{window.getCTTranslatedText(isDevice() ? "Gatekeeper" : "Agent")}</b>
			</Typography>
			<Stack
				sx={{ overflow: "hidden", flex: 1 }}
				direction="row"
				spacing={1}
				alignItems="center"
			>
				{isDevice() && (
					<Typography variant="body2" sx={truncateTextStyles}>
						{asset?.agentName}
					</Typography>
				)}
				<Tooltip
					title={window.getCTTranslatedText(
						isDevice() ? "viewGatekeeperDetails" : "View Agent details"
					)}
				>
					<DataGridButton
						onClick={() => processBtnClickCallback()}
						color="inherit"
						sx={{
							display: "flex",
						}}
					>
						<Stack direction="row" alignItems="center" spacing={1}>
							{statusIcon}
							<Typography variant="body2">
								{window.getCTTranslatedText(text)}
							</Typography>
						</Stack>
					</DataGridButton>
				</Tooltip>
			</Stack>
			<ViewLogsButton asset={asset} agentId={asset.agentId} />
		</Stack>
	);
}

import { Agent } from "pages/agents/types";
import { useEffect, useMemo, useState } from "react";
import { AGENT_TYPES, PLATFORMS } from "../../agent-download-page/constants";

export interface AgentGroupByObj {
	[key: string]: {
		data: Array<Agent>;
		version?: string;
		architecture?: string;
	}[];
}

export function useGroupByAgentData(updatedData: Agent[]) {
	const [agentsGroupByPlatforms, setAgentsGroupByPlatforms] =
		useState<AgentGroupByObj>();

	const agentsGroupByPlatformsData = useMemo(() => {
		let groupByObj: AgentGroupByObj = {};

		updatedData.forEach((agent: Agent) => {
			if (agent.isUpgradeEnabled && agent?.asset?.assetId) {
				let platformKey = agent.platform;

				if (
					agent.agentType === AGENT_TYPES.CT_LEGACY_AGENT &&
					agent.platform === PLATFORMS.RPM
				) {
					platformKey = PLATFORMS.RPM_LEGACY;
				}

				const index =
					groupByObj[platformKey]?.findIndex(
						obj => obj.architecture === agent.architecture
					) ?? 0;

				if (!groupByObj[platformKey]) {
					groupByObj[platformKey] = [
						{
							data: [agent],
							version: undefined,
							architecture: agent.architecture,
						},
					];
				} else if (!groupByObj[platformKey][index]) {
					groupByObj[platformKey].push({
						data: [agent],
						version: undefined,
						architecture: agent.architecture,
					});
				} else {
					groupByObj[platformKey][index].data = [
						...(groupByObj[platformKey][index]?.data ?? []),
						agent,
					];
				}
			}
		});

		return groupByObj;
	}, [updatedData]);

	useEffect(() => {
		if (agentsGroupByPlatformsData) {
			setAgentsGroupByPlatforms(agentsGroupByPlatformsData);
		}
	}, [agentsGroupByPlatformsData]);

	return { agentsGroupByPlatforms, setAgentsGroupByPlatforms };
}

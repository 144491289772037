import { Alert, AlertColor } from "@mui/material";
import { Asset, SecurityLevels } from "pages/assets/types";
import {
	AlertMessages,
	AssetStatusMap,
	AssetStatusReverseMap,
	Direction,
	SecurityStatus,
} from "../../constants";
import { capitalizeFirstLetter } from "../../helpers";

interface AssetStatusAlertProps {
	type: Direction;
	status: SecurityLevels;
	asset: Asset;
	selectedAttackSurfaceStatus: SecurityStatus;
	selectedBlastRadiusStatus: SecurityStatus;
}

export const AssetStatusAlert = ({
	type,
	status,
	asset,
	selectedAttackSurfaceStatus,
	selectedBlastRadiusStatus,
}: AssetStatusAlertProps) => {
	let severity = "info" as AlertColor;
	let message: string = "";
	let statusChange: boolean = false;
	let simulate: boolean = false;
	let showAlert: boolean = false;

	if (type === Direction.Inbound) {
		statusChange =
			AssetStatusMap[asset.inboundAssetStatus] !== selectedAttackSurfaceStatus;
		simulate =
			selectedAttackSurfaceStatus > AssetStatusMap[asset?.inboundAssetStatus] &&
			selectedAttackSurfaceStatus !== SecurityStatus.Unsecure;

		showAlert = !!(
			asset?.unreviewedPorts > 0 ||
			(asset?.inboundInternetPaths?.unreviewed &&
				asset?.inboundInternetPaths?.unreviewed > 0) ||
			(asset?.inboundIntranetPaths?.unreviewed &&
				asset?.inboundIntranetPaths?.unreviewed > 0)
		);

		if (!statusChange) {
			message = AlertMessages[type][selectedAttackSurfaceStatus]?.message?.info;
			if (selectedAttackSurfaceStatus === SecurityStatus.All) {
				if (asset?.simulateInboundAssetStatus) {
					message =
						AlertMessages[type][selectedAttackSurfaceStatus]?.message
							?.simulateInfo;
					severity = "info";
				} else {
					message = message.replaceAll("v(a)", capitalizeFirstLetter(status));
					severity = "success";
				}
			}
		} else {
			if (showAlert) {
				severity = "warning";
				message =
					AlertMessages[type][selectedAttackSurfaceStatus]?.message?.warning;
			} else {
				// confirmation
				severity = "info";
				if (simulate) {
					message =
						AlertMessages[type][selectedAttackSurfaceStatus]?.message?.simulate;
				} else {
					message =
						AlertMessages[type][selectedAttackSurfaceStatus]?.message
							?.confirmation;
				}
			}
		}
	} else if (type === Direction.Outbound) {
		statusChange =
			AssetStatusReverseMap[selectedBlastRadiusStatus] !==
			asset.outboundAssetStatus;
		simulate =
			selectedBlastRadiusStatus > AssetStatusMap[asset?.outboundAssetStatus] &&
			selectedBlastRadiusStatus !== SecurityStatus.Unsecure;
		showAlert = !!(
			(asset?.outboundInternetPaths?.unreviewed &&
				asset?.outboundInternetPaths?.unreviewed > 0) ||
			(asset?.outboundIntranetPaths?.unreviewed &&
				asset?.outboundIntranetPaths?.unreviewed > 0)
		);

		if (!statusChange) {
			message = AlertMessages[type][selectedBlastRadiusStatus]?.message?.info;
			if (selectedBlastRadiusStatus === SecurityStatus.All) {
				if (asset?.simulateOutboundAssetStatus) {
					message =
						AlertMessages[type][selectedBlastRadiusStatus]?.message
							?.simulateInfo;
					severity = "info";
				} else {
					message = message.replaceAll("v(b)", capitalizeFirstLetter(status));
					severity = "success";
				}
			}
		} else {
			if (showAlert) {
				severity = "warning";
				message =
					AlertMessages[type][selectedBlastRadiusStatus]?.message?.warning;
			} else {
				// confirmation
				severity = "info";
				if (simulate) {
					message =
						AlertMessages[type][selectedBlastRadiusStatus]?.message?.simulate;
				} else {
					message =
						AlertMessages[type][selectedBlastRadiusStatus]?.message
							?.confirmation;
				}
			}
		}
	} else {
		// invalid option
		return null;
	}

	return (
		<Alert sx={{ width: "100%" }} severity={severity}>
			{window.getCTTranslatedText(message)}
		</Alert>
	);
};

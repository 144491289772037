import { useMutation } from "@tanstack/react-query";
import { MatchedByTemplateType } from "modules/matched-by-templates/constants";
import { PathDirection } from "pages/paths/types";

interface PathRuleMatchesAPIProps {
	assetId?: string;
	id?: string;
	type: MatchedByTemplateType;
	direction?: PathDirection;
}

export function usePathRuleMatchesAPI({
	type,
	assetId,
	id,
	direction = PathDirection.Inbound,
}: PathRuleMatchesAPIProps) {
	let path = `assets/${assetId}/paths/${id}/direction/${direction}/rule-matches`;
	if (type !== MatchedByTemplateType.PATH) {
		path = `assets/${assetId}/openports/${id}/rule-matches`;
	}

	return useMutation<any, Error, any>(["rule-matches", path, "post"], {});
}

import { Logs } from "pages/agents/types";
import { Asset } from "pages/assets/types";
import { useEffect } from "react";
import { useLogsAPI } from "./hooks";

export interface LogsFetcherProps extends Pick<Asset, "agentId"> {
	setOptionsData: React.Dispatch<React.SetStateAction<Logs | undefined>>;
	setAreOptionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LogsFetcher = ({
	agentId,
	setOptionsData,
	setAreOptionsLoading,
}: LogsFetcherProps) => {
	const { data, isLoading } = useLogsAPI({ agentId });

	useEffect(() => {
		setAreOptionsLoading(isLoading);
	}, [isLoading, setAreOptionsLoading]);

	useEffect(() => {
		setOptionsData(data);
	}, [data, setOptionsData]);

	return null;
};

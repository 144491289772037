import { Grid, Link, Stack, Typography } from "@mui/material";
import { CopyButton } from "common/atoms/copy-button";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { API_URL } from "hooks/useNetworking/constants";
import { FieldsOption, TextFieldsValues } from "modules/form-fields/type";
import { INTEGRATION_PROVIDERS } from "pages/Integration/constants";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import { SessionRefreshLimitOptions } from "./constants";

const DialogContentPadding = 0;
const rowGap = 2;
const columnGap = 4;

interface FormExtrasAfterProps {
	selectedValue?: FieldsOption | null;
	setTextFieldsValues: React.Dispatch<React.SetStateAction<TextFieldsValues>>;
	textFieldsValues?: TextFieldsValues;
}

export const FormExtrasAfter = ({
	selectedValue,
	setTextFieldsValues,
	textFieldsValues,
}: FormExtrasAfterProps) => {
	const redirectURLvalue =
		selectedValue?.integrationProvider === INTEGRATION_PROVIDERS.OAUTH
			? `${window.location.origin}/api/auth/callback-oauth`
			: `${window.location.origin}/api/auth/callback-saml`;

	const refreshTokenTTLValue = SessionRefreshLimitOptions.find(
		option => option.value === textFieldsValues?.refreshTokenTTL
	)?.displayName;

	return (
		<>
			<Stack>
				<Typography
					mt={6.5}
					mb={2}
					variant="subtitle1"
					sx={{
						fontFamily: theme => theme.typography.body2.fontFamily,
					}}
				>
					{window.getCTTranslatedText("SessionIdleTimeoutConfiguration")}
				</Typography>
				<Grid
					container
					columnGap={columnGap}
					rowGap={rowGap}
					sx={{ pl: DialogContentPadding }}
					display="grid"
					gridTemplateColumns="1fr 1fr"
				>
					<Grid item xs={12}>
						<CTSelectDropDown
							field={"refreshTokenTTL"}
							displayName={window.getCTTranslatedText("SessionIdleTimeout")}
							selectedValue={refreshTokenTTLValue}
							key={refreshTokenTTLValue}
							handleUpdate={(event: TextFieldUpdate) => {
								const refreshTokenValue = SessionRefreshLimitOptions.find(
									option => option.displayName === event.value
								)?.value;

								setTextFieldsValues(prev => ({
									...prev,
									refreshTokenTTL: refreshTokenValue,
									refreshTokenRollingEnabled: true,
									accessTokenTTL: refreshTokenValue === 15 ? 10 : 15,
								}));
							}}
							data={SessionRefreshLimitOptions.map(
								option => option.displayName
							)}
							textTransform="none"
							showLabel={true}
						/>
					</Grid>
				</Grid>
			</Stack>

			<Stack mt={6.5}>
				<Stack>
					<Typography variant="subtitle2">
						{window.getCTTranslatedText("Audience URL (Entity Id)")}
					</Typography>

					<Stack direction={"row"} spacing={1} alignItems="center">
						<Typography
							sx={{
								overflow: "hidden",
								maxHeight: 20,
								lineBreak: "anywhere",
							}}
							variant="body2"
						>
							<code>{window.location.origin}</code>
						</Typography>
						<CopyButton text={window.location.origin} />
					</Stack>
				</Stack>
				<Stack mt={4}>
					<Typography variant="subtitle2">
						{window.getCTTranslatedText("SSO URL/Redirect URL")}
					</Typography>

					<Stack direction={"row"} spacing={1} alignItems="center">
						<Typography
							sx={{
								overflow: "hidden",
								maxHeight: 20,
								lineBreak: "anywhere",
							}}
							variant="body2"
						>
							<code>{redirectURLvalue}</code>
						</Typography>
						<CopyButton text={redirectURLvalue} />
					</Stack>
				</Stack>

				{selectedValue?.integrationProvider === INTEGRATION_PROVIDERS.SAML && (
					<Stack mt={4}>
						<Link
							href={`${API_URL}/auth/saml/metadata`}
							target="_blank"
							rel="noopener noreferrer"
							variant="subtitle2"
						>
							{window.getCTTranslatedText("Download Metadata")}
						</Link>
					</Stack>
				)}
			</Stack>
		</>
	);
};

import { ExpandCircleDownOutlined } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { CTDrawer } from "common/molecules/drawer";
import { Path } from "pages/paths/types";
import { useState } from "react";
import { DestinationIPDataGrid } from "./DestinationIPDataGrid";

export interface DestinationIPDrawerProps {
	path: Path;
}

export const DestinationIPDrawer = ({ path }: DestinationIPDrawerProps) => {
	const [showIPDetails, setShowIPDetails] = useState(false);

	const handleShowIPDetails = () => {
		setShowIPDetails(false);
	};

	if (!path?.dstIp?.length) {
		return null;
	}

	return (
		<>
			<Tooltip title={window.getCTTranslatedText("showIPDetails")}>
				<IconButton
					sx={{
						"& svg": {
							transform: "rotate(218deg)",
						},
					}}
					aria-label={window.getCTTranslatedText("showIPDetails")}
					onClick={() => {
						setShowIPDetails(true);
					}}
				>
					<ExpandCircleDownOutlined
						sx={{ opacity: 0.6 }}
						fontSize="small"
						color="info"
					/>
				</IconButton>
			</Tooltip>

			<CTDrawer
				open={showIPDetails}
				onClose={handleShowIPDetails}
				title={window.getCTTranslatedText("Destination IPs")}
			>
				<Stack sx={{ width: "100%", height: "100%" }}>
					{showIPDetails && <DestinationIPDataGrid data={path} />}
				</Stack>
			</CTDrawer>
		</>
	);
};

import {
	DataGrid,
	DataGridStyles,
} from "modules/data-grid/components/data-grid/DataGrid";
import { useState } from "react";
import { TAG_COLUMNS } from "./constants";

export const TagDataGrid = (props: any) => {
	const [pageSize, setPageSize] = useState(100);

	return (
		<DataGrid
			sx={{
				...DataGridStyles,
				"& .MuiDataGrid-row:hover": {
					background: "transparent",
				},
			}}
			isLoading={props?.loading || !props?.rows}
			rows={props?.rows || []}
			rowCount={props?.rows?.length}
			rowHeight={64}
			columns={TAG_COLUMNS}
			getRowId={({ key, value }: any) => `${key}='${value}'`}
			pageSize={pageSize}
			onPageSizeChange={setPageSize}
			pagination
			paginationMode="client"
			disableColumnMenu
			disableRowSelectionOnClick
			slots={{
				...props.slots,
			}}
		/>
	);
};

import { Scope } from "modules/scope-metadata/types";

export const timelineFilter: any = {
	1: "24 - 168,168 - 336,336+",
	7: "168 - 336,336+",
};

export const routesToScopeMap: any = {
	[Scope.Port]: "ports",
	[Scope.Asset]: "assets",
	[Scope.Path]: "paths",
};

// TODO: Use status constants

interface PortRecommendationConfig {
	count: number;
	scope: string;
	durationCount?: number;
	durationLabel?: string;
}

interface AssetRecommendationConfig {
	count: number;
	scope: string;
}

interface PathRecommendationConfig {
	count: number;
	scope: string;
}

type RecommendationConfig =
	| PortRecommendationConfig
	| AssetRecommendationConfig
	| PathRecommendationConfig;

interface RecommendationConfigControl {
	title?: string;
	btnTitle: string;
	url: string;
	permission: string;
}

export type RecommendationScope = Scope.Port | Scope.Path | Scope.Asset;

export type ConfigControlHolder = {
	[scope in RecommendationScope]: {
		[key: string]: RecommendationConfigControl;
	};
};

export const recommendationConfig = (args?: RecommendationConfig) => {
	const defaultArgs = { count: 0, scope: "" };
	const safeArgs = args ?? defaultArgs;

	const recConfig: ConfigControlHolder = {
		[Scope.Port]: {
			portsunusednotraffic: {
				//deny
				title: getTitle(safeArgs, "denyPortRecommendation"),
				btnTitle: "Review",
				url: encodeURI(
					`agentstatus=active|listenportreviewed=unreviewed|bandwidthinbytes=0|listenportlastobserved=v(t)`
				),
				permission: "UPDATE_PORT",
			},
			portsusednointernettraffic: {
				//allow intranet
				title: getTitle(safeArgs, "allowIntranetRecommendation"),
				btnTitle: "Review",
				url: encodeURI(
					`agentstatus=active|listenportreviewed=unreviewed,allow-any|internetpathcount=0|pathcount>0|listenportlastobserved=v(t)`
				),
				permission: "UPDATE_PORT",
			},
			sensitiveports: {
				// path sensitive
				title: window.getCTTranslatedText("sensitivePathRecommendation", {
					count: args?.count,
					scope: args?.scope,
				}),
				btnTitle: "Review",
				url: encodeURI(
					`agentstatus=active|listenportreviewed=unreviewed,allow-any,allow-intranet|listenport=21,22,23,3389`
				),
				permission: "UPDATE_PORT",
			},
			pathrestrictedfewpaths: {
				// path restricted
				title: window.getCTTranslatedText("restrictedPathRecommendation", {
					count: args?.count,
					scope: args?.scope,
				}),
				btnTitle: "Review",
				url: encodeURI(
					`agentstatus=active|listenportreviewed=allow-intranet|internetpathcount=0|bandwidthinbytes=1 - 100000|pathcount=0 - 5`
				),
				permission: "UPDATE_PORT",
			},
		},
		[Scope.Asset]: {
			// secure internet paths - blast radius
			secureinternetoutboundcandidates: {
				title: window.getCTTranslatedText(
					"assetSecureInternetPathRecommendation",
					{
						count: args?.count,
						scope: args?.scope,
					}
				),
				btnTitle: "Secure",
				url: encodeURI(
					`agentstatus=active|internetoutboundenforcementready=true|assetoutboundstatus=unsecured`
				),
				permission: "UPDATE_ASSET",
			},
			secureintranetoutboundcandidates: {
				// secure intranet paths - blast radius
				title: window.getCTTranslatedText(
					"assetSecureIntranetPathRecommendation",
					{
						count: args?.count,
						scope: args?.scope,
					}
				),
				btnTitle: "Secure",
				url: encodeURI(
					`agentstatus=active|intranetoutboundenforcementready=true|assetoutboundstatus=unsecured,simulate-secure-internet,secure-internet`
				),
				permission: "UPDATE_ASSET",
			},
		},
		[Scope.Path]: {
			// add to template drawer
			templatizecoreinfraservices: {
				title: window.getCTTranslatedText("pathRecommendation", {
					count: args?.count,
					scope: args?.scope,
				}),
				btnTitle: "Add to Template",
				url: encodeURI(
					`agentstatus=active|direction=outbound|port=68,53,389,636`
				),
				permission: "UPDATE_TEMPLATE",
			},
		},
	};
	return recConfig;
};

const isPortRecommendationConfig = (
	arg: RecommendationConfig
): arg is PortRecommendationConfig => {
	return "durationCount" in arg;
};

const getTitle = (arg: RecommendationConfig, title: string) => {
	if (isPortRecommendationConfig(arg)) {
		return window.getCTTranslatedText(title, {
			count: arg.count,
			scope: arg.scope,
			durationCount: arg.durationCount,
			durationLabel: arg.durationLabel,
		});
	}
};

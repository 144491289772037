function DragRowIcon() {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_11798_8085)">
				<path
					d="M16.0015 5.5L12 9.59613L12.9013 10.5L16.0015 7.35611L19.1222 10.5L20 9.59613L16.0015 5.5Z"
					fill="currentColor"
					fillOpacity="0.6"
				/>
				<path
					d="M16.0015 5.5L12 9.59613L12.9013 10.5L16.0015 7.35611L19.1222 10.5L20 9.59613L16.0015 5.5Z"
					fill="currentColor"
					fillOpacity="0.2"
				/>
				<path
					d="M16.1088 5.39522L16.0015 5.2853L15.8942 5.39518L11.8927 9.49131L11.7892 9.59721L11.8938 9.70204L12.7951 10.6059L12.9019 10.713L13.0081 10.6053L16.0018 7.56938L19.0157 10.6057L19.1234 10.7141L19.2298 10.6045L20.1076 9.70063L20.2094 9.59586L20.1073 9.49135L16.1088 5.39522Z"
					stroke="currentColor"
					strokeOpacity="0.6"
					strokeWidth="0.3"
				/>
				<path
					d="M16.1088 5.39522L16.0015 5.2853L15.8942 5.39518L11.8927 9.49131L11.7892 9.59721L11.8938 9.70204L12.7951 10.6059L12.9019 10.713L13.0081 10.6053L16.0018 7.56938L19.0157 10.6057L19.1234 10.7141L19.2298 10.6045L20.1076 9.70063L20.2094 9.59586L20.1073 9.49135L16.1088 5.39522Z"
					stroke="currentColor"
					strokeOpacity="0.2"
					strokeWidth="0.3"
				/>
				<path
					d="M7 13.5H15.25H16.75H25V14.5H7V13.5Z"
					fill="currentColor"
					fillOpacity="0.6"
				/>
				<path
					d="M7 13.5H15.25H16.75H25V14.5H7V13.5Z"
					fill="currentColor"
					fillOpacity="0.2"
				/>
				<path
					d="M7 13.35H6.85V13.5V14.5V14.65H7H25H25.15V14.5V13.5V13.35H25H16.75H15.25H7Z"
					stroke="currentColor"
					strokeOpacity="0.6"
					strokeWidth="0.3"
				/>
				<path
					d="M7 13.35H6.85V13.5V14.5V14.65H7H25H25.15V14.5V13.5V13.35H25H16.75H15.25H7Z"
					stroke="currentColor"
					strokeOpacity="0.2"
					strokeWidth="0.3"
				/>
				<path
					d="M7 17.5H15.25H16.75H25V18.5H7V17.5Z"
					fill="currentColor"
					fillOpacity="0.6"
				/>
				<path
					d="M7 17.5H15.25H16.75H25V18.5H7V17.5Z"
					fill="currentColor"
					fillOpacity="0.2"
				/>
				<path
					d="M7 17.35H6.85V17.5V18.5V18.65H7H25H25.15V18.5V17.5V17.35H25H16.75H15.25H7Z"
					stroke="currentColor"
					strokeOpacity="0.6"
					strokeWidth="0.3"
				/>
				<path
					d="M7 17.35H6.85V17.5V18.5V18.65H7H25H25.15V18.5V17.5V17.35H25H16.75H15.25H7Z"
					stroke="currentColor"
					strokeOpacity="0.2"
					strokeWidth="0.3"
				/>
				<path
					d="M16.0015 26.5L12 22.4039L12.9013 21.5L16.0015 24.6439L19.1222 21.5L20 22.4039L16.0015 26.5Z"
					fill="currentColor"
					fillOpacity="0.6"
				/>
				<path
					d="M16.0015 26.5L12 22.4039L12.9013 21.5L16.0015 24.6439L19.1222 21.5L20 22.4039L16.0015 26.5Z"
					fill="currentColor"
					fillOpacity="0.2"
				/>
				<path
					d="M16.1088 26.6048L16.0015 26.7147L15.8942 26.6048L11.8927 22.5087L11.7892 22.4028L11.8938 22.298L12.7951 21.3941L12.9019 21.287L13.0081 21.3947L16.0018 24.4306L19.0157 21.3943L19.1234 21.2859L19.2298 21.3955L20.1076 22.2994L20.2094 22.4041L20.1073 22.5086L16.1088 26.6048Z"
					stroke="currentColor"
					strokeOpacity="0.6"
					strokeWidth="0.3"
				/>
				<path
					d="M16.1088 26.6048L16.0015 26.7147L15.8942 26.6048L11.8927 22.5087L11.7892 22.4028L11.8938 22.298L12.7951 21.3941L12.9019 21.287L13.0081 21.3947L16.0018 24.4306L19.0157 21.3943L19.1234 21.2859L19.2298 21.3955L20.1076 22.2994L20.2094 22.4041L20.1073 22.5086L16.1088 26.6048Z"
					stroke="currentColor"
					strokeOpacity="0.2"
					strokeWidth="0.3"
				/>
			</g>
			<defs>
				<clipPath id="clip0_11798_8085">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default DragRowIcon;

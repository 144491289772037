import { PendingCommands } from "pages/agents/types";

export interface ApplianceColumnRenderProps {
	agent?: Appliance;
	viewOnly?: boolean;
	isOpen?: boolean;
	page?: string;
	triggerButtonText?: string;
	onClose?: () => void;
}

export enum AgentModes {
	Server = "Server",
	Relay = "Relay",
	Static = "N/A",
}

export enum HaRole {
	Primary = "primary",
	Standby = "standby",
}

export enum ApplianceDrawerModes {
	Edit = "edit",
	Config = "config",
	Recover = "recover",
}

export interface ApplianceResponse {
	items: Array<Appliance>;
	metadata: {
		total: number;
	};
}

export interface ApplianceRequest {
	criteria: string;
	pagination: {
		offset: number;
		limit: number;
		sort: never[];
	};
	facetFields: never[];
}

export interface Appliance {
	rowId?: string;
	agentId: string;
	agentType: string;
	agentStatus: string;
	currentVersion: string;
	platform: string;
	architecture: string;
	agentLocation: string;
	agentName: string;
	lanIp: string;
	lastCheckIn: string;
	managedDeviceCount: number;
	unmanagedDeviceCount: number;
	mode: AgentModes;
	wanIp: string;
	installedOnId: string;
	isRowSelectable?: boolean;
	autoUpgradeEnabled?: boolean;
	agentUpgradeAvailable?: boolean;
	agentUpgradeStatus?: string;
	agentRestartStatus?: string;
	agentDiagnosticsStatus?: string;
	isUpgradeEnabled?: boolean;
	pendingCommands?: Array<PendingCommands>;
	isDiagnosticsRunning?: boolean;
	isDiagnosticsSupported?: boolean;
	policySynced?: string;
	haSyncStatus?: string;
	dhcpStatus?: string;
	isRestartEnabled?: boolean;
	haRole?: HaRole;
	applianceName?: Array<string> | null;
	virtualId?: string | null;
	cpuUtilization?: number;
	memoryUtilization?: number;
	cpuUtilizationStr?: string;
	wanUtilization?: number;
	lanUtilization?: number;
	diskUtilization?: number;
	memoryUtilizationStr?: string;
	agentCreated?: string;
	gatekeeperConfigUpdateAllowed?: boolean;
	gatekeeperConfigUpdateStatus?: string;
	vlans?: Array<VLAN> | null;
	cpuTemperature?: number;
}

export interface VLAN {
	id?: string;
	ipAddress?: string;
}

export interface ApplianceFormProps {
	agentId?: string | undefined;
	agentName?: string | undefined;
	agentLocation?: string | undefined;
	gatekeeperConfigUpdateAllowed?: boolean;
}

export interface OnlineRecoveryProps {
	appliance?: ApplianceFormProps | Appliance;
	initalActiveAppliances: string[];
	selectedAppliance?: Appliance;
	setSelectedAppliance: (appliance: Appliance) => void;
}

export interface OfflineRecoveryProps {
	appliance?: ApplianceFormProps | Appliance;
}

export interface RecoveryStepProps {
	index: number;
	title: string;
	children: React.ReactElement;
	isOptional?: boolean;
}

export interface ApplianceCertificate {
	clientCertificate: string;
	hostname: string;
	ipAddress: string;
	messageName: string;
}

export enum RecoveryModes {
	Online = "Online",
	Offline = "Offline",
}

export enum ActivationStatus {
	PendingActivation = "Pending Activation",
	Activating = "Activating",
	Activated = "Activated",
	Failed = "Activation Failed",
}

export const AGENT_MODE_TEXT = {
	[AgentModes.Server]: "Server",
	[AgentModes.Relay]: "Relay",
};

export enum QUERY_ACTIONS {
	ACTIVATE = "activate",
}

export enum ApplianceActionsMap {
	UPGRADE = "Upgrade",
	RESTART = "Restart",
	DECOMMISSION = "Decommission",
	REBOOT = "Reboot",
	RESTART_DHCP = "Restart DHCP",
	RESTART_HASYNC = "Restart HA Sync",
	COLLECT_DIAGNOSTICS = "Collect diagniostics",
	ENABLE_DEBUG_LOG = "Enable debug log",
	POLICY_CHANGE = "Policy change",
	CONFIG_UPDATE = "Config update",
}

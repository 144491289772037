import { PersonOutlineOutlined } from "@mui/icons-material";
import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import { AssetColumnRenderProps, AssetType } from "pages/assets/types";
import { Link as RouterLink } from "react-router-dom";
import { AssetNameInternal } from "../asset-name-internal";
const nameStyle = {
	width: "100%",
	maxWidth: "100%",
	overflow: "hidden",
};

interface AssetNameAndTagsProps extends AssetColumnRenderProps {
	showSyncStatus?: boolean;
	page?: string;
}

export function AssetNameAndTags({
	asset,
	viewOnly,
	showSyncStatus,
	page = "assets",
}: AssetNameAndTagsProps) {
	if (!asset.assetId) {
		return <Box style={{ minWidth: 160 }}></Box>;
	}

	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing={1}
			sx={{ width: "100%" }}
		>
			<Stack sx={nameStyle}>
				<Stack flex={1}>
					{viewOnly ? (
						<Typography
							variant="body2"
							noWrap
							title={AssetNameInternal({ asset })}
						>
							{AssetNameInternal({ asset })}
						</Typography>
					) : (
						<Stack flex={1} flexDirection={"row"}>
							{asset.type === AssetType.Endpoint && asset?.loggedinUser && (
								<Stack mr={2}>
									<Tooltip
										title={window.getCTTranslatedText(
											window.getCTTranslatedText("loggedInUser", {
												email: asset.loggedinUser,
											})
										)}
										placement="bottom-start"
										arrow={false}
									>
										<PersonOutlineOutlined fontSize="small" color="success" />
									</Tooltip>
								</Stack>
							)}
							<Tooltip
								title={window.getCTTranslatedText(
									window.getCTTranslatedText("View asset details")
								)}
								placement="bottom-start"
								arrow={false}
							>
								<Stack>
									<Link
										typography={"body2"}
										underline="hover"
										color="inherit"
										component={RouterLink}
										to={`/${page}/${asset.assetId}`}
										noWrap
										title={AssetNameInternal({ asset })}
									>
										{AssetNameInternal({ asset })}
									</Link>
								</Stack>
							</Tooltip>
						</Stack>
					)}
					{/* <Stack>
            <AssetCoreTags
              viewOnly={viewOnly}
              coreTags={asset.coreTags}
              useAssetCoreTagsStore={useCommonStore}
            />
          </Stack> */}
				</Stack>
			</Stack>
		</Stack>
	);
}

import { useMutation } from "@tanstack/react-query";
import { Scope } from "modules/scope-metadata/types";
import { UnmanagedDevice } from "pages/appliance-detail/components/appliance-unmanaged-devices/types";

export function useUpdateAssetAnnotations(
	assetId: string | undefined,
	mode: "single" | "bulk"
) {
	const path = `assets/${mode === "single" ? assetId + "/" : ""}annotations`;
	return useMutation<any, Error, any>(["asset", path, "put"]);
}

export function useUpdateUnmanagedDevice(
	deviceId: UnmanagedDevice["deviceId"]
) {
	const base = Scope.UnmanagedDevice + "s";
	const path = `${base}/${deviceId}`;
	return useMutation<any, Error, any>(["asset", path, "put"]);
}

import { useTheme } from "@mui/material";
import { IconsProps } from "./common";

export const AIXIcon = ({
	width = "16px",
	height = "16px",
	color = "#000",
}: IconsProps) => {
	const theme = useTheme();

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill={color || "currentColor"}
			viewBox="0 0 18 18"
		>
			<path fill={color || "currentColor"} d="M0 0H18V18H0z"></path>
			<path
				fill={theme.palette.mode === "dark" ? "#000" : "#FFF"}
				d="M5.998 11.488l-.384-1.26H3.82l-.385 1.26H2.25l1.77-5.3h1.447l1.748 5.3H5.998zm-1.27-4.252H4.69l-.593 2.012h1.231l-.6-2.012zM7.754 11.488v-.919h.692V7.106h-.692v-.918h2.555v.918h-.693v3.463h.693v.92H7.754zM15.75 11.488h-1.355l-1.162-1.951h-.023l-1.132 1.951h-1.262l1.724-2.741-1.64-2.56h1.363L13.31 7.98h.023l1.062-1.793h1.262L14.002 8.77l1.748 2.718z"
			></path>
		</svg>
	);
};

import { Box, SvgIconProps } from "@mui/material";
import { AssetColumnRenderProps, SecurityLevels } from "pages/assets/types";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import KeyboardDoubleArrowDown from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { getColor } from "common/constants/colors";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function AssetBusinessValue({
	asset,
	viewOnly,
}: AssetColumnRenderProps) {
	if (!asset?.assetId) {
		return <Box style={{ width: 70 }} />;
	}

	let icon: React.ReactElement<SvgIconProps> | undefined = (
		<ChangeHistoryIcon
			color="inherit"
			style={{ fontSize: 14, marginLeft: 2 }}
		/>
	);

	switch (asset?.businessValue) {
		case "high":
			icon = (
				<KeyboardDoubleArrowUpIcon
					style={{ color: getColor(SecurityLevels.High) }}
				/>
			);
			break;
		case "medium":
			icon = (
				<WarningAmberOutlinedIcon
					style={{ color: getColor(SecurityLevels.Medium) }}
				/>
			);
			break;
		case "low":
			icon = (
				<KeyboardDoubleArrowDown
					style={{ color: getColor(SecurityLevels.Low) }}
				/>
			);
			break;
		default:
			icon = undefined;
	}

	return (
		<CTDatagridButton
			isDisabled={viewOnly === true || !asset?.businessValue}
			asText={viewOnly === true || !asset?.businessValue}
			facetName={"businessvalue"}
			facetOption={asset?.businessValue ?? ""}
			startIcon={asset?.businessValue ? icon : undefined}
		>
			{window.getCTTranslatedText(asset?.businessValue ?? "")}
		</CTDatagridButton>
	);
}

import { Stack, Tooltip } from "@mui/material";
import { useCommonStore } from "common/store";

import { AuditLogColumnRenderProps } from "pages/monitoring/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export const AuditAction = ({ audit, viewOnly }: AuditLogColumnRenderProps) => {
	const facetState = useCommonStore(state => state.facets);

	const actionFacetName = "action";
	const actionOptionName = audit?.action?.toString();
	const isActionSelected =
		!viewOnly &&
		facetState?.get(actionFacetName)?.get(actionOptionName)?.isSelected;

	const actionTitle = audit.action;

	return (
		<Stack
			sx={{
				height: "100%",
				justifyContent: "space-evenly",
				width: "100%",
				overflow: "hidden",
				textOverflow: "ellipsis",
			}}
		>
			<Tooltip
				title={`${window.getCTTranslatedText(
					"Log Name"
				)}: ${window.getCTTranslatedText(actionTitle)}`}
			>
				<CTDatagridButton
					sx={{
						p: isActionSelected ? undefined : 0,
					}}
					asText={viewOnly}
					isDisabled={viewOnly}
					facetName={actionFacetName}
					facetOption={actionOptionName}
					stopPropagation={true}
				>
					{window.getCTTranslatedText(actionTitle)}
				</CTDatagridButton>
			</Tooltip>
		</Stack>
	);
};

import {
	Box,
	Stack,
	DialogActions,
	DialogContent,
	Dialog,
	Button,
	DialogTitle,
	Typography,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { AlertMessages } from "../../constants";

const dialogMinWidth = 300;

export interface ConfirmDeploymentProps {
	showDialog: boolean;
	loading: boolean;
	confirm: () => void;
	cancel: () => void;
}

export function ConfirmDeployment({
	showDialog,
	loading,
	confirm,
	cancel,
}: ConfirmDeploymentProps) {
	return (
		<>
			<Dialog open={showDialog} onClose={() => cancel()}>
				<DialogTitle>{`Deploy Policies`}</DialogTitle>
				<DialogContent>
					<Box style={{ minWidth: dialogMinWidth }}>
						<Stack alignItems="center" justifyContent="center" spacing={4}>
							<Typography variant="body1">{AlertMessages.deploy}</Typography>
						</Stack>
					</Box>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						variant="outlined"
						loading={loading}
						onClick={() => confirm()}
					>
						Yes, Deploy
					</LoadingButton>
					<Button variant="contained" onClick={() => cancel()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

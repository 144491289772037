import {
	// Alert,
	Stack,
} from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { usePathStore } from "pages/paths/store";
import { PathStoreType } from "pages/paths/store/types";
import { Path, PathStatus } from "pages/paths/types";
import { PathStatusDropdown } from "../path-status-dropdown";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";
// import {
//   StatusOptionsText as PathStatusOptionText,
//   StatusText,
// } from "pages/paths/constants";

interface PathStatusToolbarProps {
	updatePathstatus: ((status: PathStatus | undefined) => void) | undefined;
	useStore?: PathStoreType;
	preSelectedPathStatus?: PathStatus | undefined;
	selection?: Array<Path>;
	mode: AssetReviewModeType;
}

export const PathStatusToolbar = ({
	updatePathstatus,
	useStore = usePathStore,
	preSelectedPathStatus,
	mode,
	selection,
}: PathStatusToolbarProps) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const onSelectPathStatus = (status: PathStatus) => {
		if (updatePathstatus) {
			updatePathstatus(status);
		}
	};

	const disabled =
		mode !== AssetReviewModeType.Selection
			? Boolean(selection?.some(row => !row.isRowSelectable))
			: false;

	return (
		<>
			<Stack
				direction={"row"}
				alignItems="space-between"
				sx={{ width: "100%" }}
			>
				<Stack alignItems="flex-end">
					{userPermissions.has("UPDATE_PATH") && (
						<PathStatusDropdown
							onSelect={onSelectPathStatus}
							preSelectedPathStatus={preSelectedPathStatus}
							useStore={useStore}
							disabled={disabled}
						></PathStatusDropdown>
					)}
				</Stack>
			</Stack>
			{/* {selectedPathStatus && (
        <Stack sx={{ mb: 2.5, width: "100%" }}>
          <Alert sx={{ width: "100%" }} severity={"info"}>
            {`You are changing path status to ${PathStatusOptionText[selectedPathStatus]}. Changes will take effect after you click on confirm`}
          </Alert>
        </Stack>
      )} */}
		</>
	);
};

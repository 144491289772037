import { HostAndUserGroupStatusState } from "pages/users-segmentation/components/user-groups/types";

export const HostGroupStatusLabel = {
	[HostAndUserGroupStatusState.InActive]: "Inactive",
	[HostAndUserGroupStatusState.InActivePending]: "Inactivating",
	[HostAndUserGroupStatusState.ActivePending]: "Activating",
	[HostAndUserGroupStatusState.Active]: "Active",
};

export interface HostGroup {
	id: string;
	hostgroupname: string;
	description?: string;
	grouptype: string;
	assignmentrule: string;
	updatedat: string;
	hostgroupstatus: HostAndUserGroupStatusState;
	agentid: string;
	isdeleted: boolean;
	importedhostcount?: number;
}

export interface HostGroupColumnRenderProps {
	hostGroup: HostGroup;
	viewOnly?: boolean;
}

import { Button, Stack } from "@mui/material";
import { useApplianceStore } from "pages/appliances/store";
import { QUERY_ACTIONS } from "pages/appliances/types";
import { useEffect, useState } from "react";
import { ApplianceActivationDrawer } from "../appliance-activation-drawer";

export function ApplianceActivation() {
	const [showActivateDrawer, setShowActivateDrawer] = useState(false);
	const requestAPIRefresh = useApplianceStore(store => store.requestAPIRefresh);
	const urlParams = new URLSearchParams(window.location.search);
	const action = urlParams.get("action") || "";
	const onClose = () => {
		setShowActivateDrawer(false);
		requestAPIRefresh();
	};

	useEffect(() => {
		if (action === QUERY_ACTIONS.ACTIVATE) {
			setShowActivateDrawer(true);
		}
	}, [action]);

	const renderActivateButton = () => {
		return (
			<Button
				variant="contained"
				color="primary"
				onClick={() => setShowActivateDrawer(true)}
			>
				{window.getCTTranslatedText("Activate")}
			</Button>
		);
	};

	return (
		<Stack
			direction={"row-reverse"}
			alignItems="flex-start"
			justifyItems="flex-end"
		>
			{renderActivateButton()}
			<ApplianceActivationDrawer
				isOpen={showActivateDrawer}
				cancel={onClose}
				title={window.getCTTranslatedText("gatekeeperAppliance")}
			/>
		</Stack>
	);
}

import { Box, FormControl, Grid, Stack } from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";

export interface GeneralProps {
	applianceName?: string;
	setApplianceName: (value: string) => void;
	applianceLocation?: string;
	setApplianceLocation: (value: string) => void;
	keyListener: (event: any) => void;
	isFormDisabled: boolean;
}

export function General({
	applianceName,
	setApplianceName,
	applianceLocation,
	setApplianceLocation,
	keyListener,
	isFormDisabled,
}: Readonly<GeneralProps>) {
	function renderContent() {
		return (
			<Grid
				container
				columnGap={4}
				rowGap={2}
				sx={{ pl: 0 }}
				display="grid"
				gridTemplateColumns="1fr 1fr"
			>
				<Stack>
					<CTInputField
						field="applianceName"
						displayName={window.getCTTranslatedText("gatekeeperName")}
						value={applianceName}
						handleUpdate={(event: { field: string; value: string }) =>
							setApplianceName(event?.value)
						}
						onKeyUp={keyListener}
						disabled={isFormDisabled}
					/>
				</Stack>
				<Stack>
					<CTInputField
						field="applianceLocation"
						displayName={window.getCTTranslatedText("gatekeeperLocation")}
						value={applianceLocation}
						handleUpdate={(event: { field: string; value: string }) =>
							setApplianceLocation(event?.value)
						}
						onKeyUp={keyListener}
						disabled={isFormDisabled}
					/>
				</Stack>
			</Grid>
		);
	}

	return (
		<Box sx={{ minWidth: "100%", mb: 1 }}>
			<Stack>
				<FormControl>
					<Stack spacing={2}>{renderContent()}</Stack>
				</FormControl>
			</Stack>
		</Box>
	);
}

import { Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import {
	FEATURES,
	useFeatureFlagControl,
} from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { Scope } from "modules/scope-metadata/types";
import { AgentDataGrid } from "./components/agent-data-grid";
import { agentDataMapper } from "./components/agent-data-grid/helpers";
import { useAgentsAPI } from "./components/agent-data-grid/hooks";
import { useAgentsFacetsOrder } from "./constants";
import { useAgentStore } from "./store";
import { Agent } from "./types";

export const Agents = () => {
	const theme = useTheme();
	const { isFeatureEnabled: isAgentDevVersionEnabled } = useFeatureFlagControl(
		FEATURES.EXPERIMENTAL_AGENT_VERSION
	);
	const coreResponse = useCore<Agent>({
		useStore: useAgentStore,
		facetGroupInfo: useAgentsFacetsOrder(),
		scope: Scope.Agent,
		dataMapper: agent => agentDataMapper(agent, isAgentDevVersionEnabled),
		defaultSortOrder: [{ field: "agentstatus", order: "desc" }],
		useApi: useAgentsAPI,
		isNewCsvAPIVersion: true,
	});
	return (
		<Stack
			direction={"column"}
			spacing={2}
			sx={{ height: "100%", width: "100%" }}
		>
			<Stack direction="row">
				<Stack sx={{ flex: 1 }}>
					<FacetOpenCloseController
						facetsOpen={coreResponse.facetsOpen}
						setFacetsOpen={coreResponse.setFacetsOpen}
					>
						<FacetControllerGroup
							config={coreResponse.facetConfig}
							value={coreResponse.facetState}
							onChange={coreResponse.updateFacet}
						/>
					</FacetOpenCloseController>
				</Stack>
			</Stack>

			<Paper
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					flex: 1,
					overflow: "hidden",
				}}
			>
				<AgentDataGrid
					initialState={{
						sorting: {
							sortModel: [{ field: "agentstatus", sort: "desc" }],
						},
					}}
					metadata={coreResponse.metadata}
					rows={coreResponse.rows}
					rowCount={coreResponse.rowCount}
					mutation={coreResponse.mutation}
					onPageChange={coreResponse.onPageChange}
					page={coreResponse.page}
					pageSize={coreResponse.pageSize}
					onPageSizeChange={coreResponse.onPageSizeChange}
					onSortChange={coreResponse.onSortChange}
					triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
					getExportStatus={coreResponse?.getExportStatus}
					getUrlToDownload={coreResponse?.getUrlToDownload}
					resetDownloadUrl={coreResponse?.resetDownloadUrl}
					rawData={coreResponse?.rawData}
				/>
			</Paper>
		</Stack>
	);
};

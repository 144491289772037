import { Paper, Stack, Typography } from "@mui/material";
import {
	DataGridProProps,
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GridColDef,
	GridRenderCellParams,
	GridRowId,
} from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useFindTagBasedPolicyId } from "modules/create-tag-based-policy-drawer/hooks";
import {
	CustomDetailPanelToggle,
	DataGrid,
} from "modules/data-grid/components/data-grid";
import { useExportCSV } from "modules/export-csv/hooks";
import { MergedNNRecommendationsForInboundAndOutbound } from "modules/recommendation-workflows/PathRecommendationDrawer";
import { NAMED_NETWORK_ASSIGNMENT_OPTIONS } from "modules/recommendation-workflows/components/port-recommendation-status-action-bar/constants";
import { Scope } from "modules/scope-metadata/types";
import numeral from "numeral";
import { NetworkDetail } from "pages/networks/components/network-detail/NetworkDetail";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAggregateAPI } from "../path-recommendations/PathRecommendations";

interface PathNamedNetworkRecommendationsProps {
	setPathSelection: (
		selectionModel: MergedNNRecommendationsForInboundAndOutbound[]
	) => void;
	data: MergedNNRecommendationsForInboundAndOutbound[];
	isLoading: boolean;
	policyId?: string;
	onFindTagBasedPolicyId: (id: string) => void;
	criteria?: string;
}

const PATH_NAMED_NETWORK_RECOMMENDATION_COLUMNS: GridColDef<MergedNNRecommendationsForInboundAndOutbound>[] =
	[
		{
			field: "nameNetwork",
			headerName: "Named Network",
			flex: 1,
			valueGetter: params => params.row.namedNetwork?.namedNetworkName,
			renderCell: params => {
				return (
					<Typography variant="body2">
						{params.row.namedNetwork?.namedNetworkName}
					</Typography>
				);
			},
		},
		{
			field: "inboundCoverage",
			headerName: "IPs with inbound traffic",
			flex: 1,
			renderCell: params => {
				return (
					<Stack>
						<Typography variant="body2">
							{`${numeral(params.row.inboundPeerCount).format("0a")}`}
						</Typography>

						<Typography
							variant="caption"
							color={() => {
								if (
									params.row.inboundCoverage >= 0.01 &&
									params.row.inboundCoverage < 0.7
								) {
									return "info.main";
								}
								if (params.row.inboundCoverage >= 0.7) {
									return "success.main";
								}
								return undefined;
							}}
						>
							{`${numeral(params.row.inboundCoverage).format(
								params.row.inboundCoverage > 0.1 ? "0%" : "0.00%"
							)} ${window.getCTTranslatedText("coverage")}`}
						</Typography>
					</Stack>
				);
			},
		},
		{
			field: "outboundCoverage",
			headerName: "IPs with outbound traffic",
			flex: 1,
			renderCell: params => {
				return (
					<Stack>
						<Typography variant="body2">
							{`${numeral(params.row.outboundPeerCount).format("0a")}`}
						</Typography>

						<Typography
							variant="caption"
							color={() => {
								if (
									params.row.outboundCoverage >= 0.01 &&
									params.row.outboundCoverage < 0.7
								) {
									return "info.main";
								}
								if (params.row.outboundCoverage >= 0.7) {
									return "success.main";
								}
								return undefined;
							}}
						>
							{`${numeral(params.row.outboundCoverage).format(
								params.row.outboundCoverage > 0.1 ? "0%" : "0.00%"
							)} ${window.getCTTranslatedText("coverage")}`}
						</Typography>
					</Stack>
				);
			},
		},
		{
			field: "ipCount",
			headerName: "IP count",
			flex: 1,
			valueGetter: params => params.row.totalAddressCount,
			renderCell: params => {
				return (
					<Typography variant="body2">
						{`${numeral(params.row.totalAddressCount).format("0a")}`}
					</Typography>
				);
			},
		},
		{
			...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
			renderCell: (params: GridRenderCellParams<any, any, any>) => (
				<CustomDetailPanelToggle id={params.id} value={params.value} />
			),
		},
	];

function getRowId(agg: MergedNNRecommendationsForInboundAndOutbound) {
	return `${agg.namedNetwork?.namedNetworkId}`;
}

export const PathNamedNetworkRecommendations = ({
	setPathSelection,
	data,
	isLoading,
	policyId,
	onFindTagBasedPolicyId,
	criteria,
}: PathNamedNetworkRecommendationsProps) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const [selection, setSelection] = useState<Array<GridRowId>>([]);
	const [recommendationList, setRecommendationList] = useState(data ?? []);

	useFindTagBasedPolicyId({
		criteria,
		onFindTagBasedPolicyId,
		policyId,
	});

	useEffect(() => {
		setRecommendationList(data);
		setSelection(
			data
				?.filter(r => r.outboundCoverage >= 0.01 || r.inboundCoverage >= 0.01)
				.map(getRowId) ?? []
		);
	}, [data]);

	const updateSelections = (selectedPathIds: GridRowId[]) => {
		setSelection(selectedPathIds);
	};

	useEffect(() => {
		if (selection && selection?.length > 0) {
			const map = new Map(selection.map(id => [id, true]));

			setPathSelection(
				recommendationList.filter((path, index) => map.get(getRowId(path)))
			);
		} else {
			setPathSelection([]);
		}
	}, [selection, recommendationList, setPathSelection]);

	const {
		triggerExportAsCsv,
		getExportStatus,
		getUrlToDownload,
		resetDownloadUrl,
	} = useExportCSV({
		useApi: useAggregateAPI,
		searchCriteria: "",
		page: 0,
		sort: [],
		sourceCriteria: "",
		destinationCriteria: "",
		scope: Scope.Path,
		frontendOnly: true,
	});

	const selectedRawData: Array<MergedNNRecommendationsForInboundAndOutbound> =
		useMemo(() => {
			let result = (recommendationList ?? [])?.filter(
				(row: MergedNNRecommendationsForInboundAndOutbound) => {
					return selection.indexOf(getRowId(row)) !== -1;
				}
			);
			return result?.length > 0 ? result : recommendationList;
		}, [selection, recommendationList]);

	const getDetailPanelContent = useCallback<
		NonNullable<DataGridProProps["getDetailPanelContent"]>
	>(({ row }) => {
		return (
			<NetworkDetail
				namedNetworkId={row?.namedNetwork?.namedNetworkId}
				viewOnly={true}
				hideMetadata={true}
			/>
		);
	}, []);

	const getDetailPanelHeight = useCallback<
		NonNullable<DataGridProProps["getDetailPanelHeight"]>
	>(() => "auto" as const, []);

	const hasPermission = NAMED_NETWORK_ASSIGNMENT_OPTIONS().some(
		buttonOption => {
			return buttonOption.permissions.every(permission =>
				userPermissions.has(permission)
			);
		}
	);

	return (
		<Stack
			alignItems="flex-start"
			spacing={0}
			direction={"column"}
			sx={{ width: "100%", flex: 1, overflow: "hidden" }}
		>
			<Paper
				sx={{
					width: "100%",
					flex: 1,
					overflow: "hidden",
				}}
			>
				<DataGrid<MergedNNRecommendationsForInboundAndOutbound>
					checkboxSelection={hasPermission}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						updateSelections(selectionModel);
					}}
					rowHeight={64}
					columns={PATH_NAMED_NETWORK_RECOMMENDATION_COLUMNS}
					getRowId={(agg: MergedNNRecommendationsForInboundAndOutbound) =>
						getRowId(agg)
					}
					getDetailPanelContent={getDetailPanelContent}
					getDetailPanelHeight={getDetailPanelHeight}
					paginationMode="client"
					sortingMode="client"
					pagination
					isLoading={isLoading}
					rows={recommendationList || []}
					rowCount={recommendationList.length}
					triggerExportAsCsv={triggerExportAsCsv}
					getExportStatus={getExportStatus}
					getUrlToDownload={getUrlToDownload}
					resetDownloadUrl={resetDownloadUrl}
					frontendOnly={true}
					selectedRawData={selectedRawData}
				/>
			</Paper>
		</Stack>
	);
};

import debounce from "lodash/debounce";
import { useEffect, useMemo, useState } from "react";

const useDebouncedValue: <ValueType>(
	value: ValueType,
	delay: number
) => ValueType = (value, delay = 500) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	// Create a debounced version of the setter function
	const handler = useMemo(
		() =>
			debounce(nextValue => {
				setDebouncedValue(nextValue);
			}, delay),
		[delay]
	);

	useEffect(() => {
		// Call the debounced function
		handler(value);

		// Cleanup on unmount or when value changes
		return () => {
			handler.cancel(); // Cancel any pending debounced calls
		};
	}, [value, delay, handler]); // Re-run effect if value or delay changes

	return debouncedValue;
};

export default useDebouncedValue;

import { Stack } from "@mui/material";
import { DataGridButton } from "common/atoms/data-grid-button";

import numeral from "numeral";
import { PathColumnRenderProps } from "pages/paths/types";
import pluralize from "pluralize";

export const PathFirewallCount = ({ path }: PathColumnRenderProps) => {
	if (!path.firewallDenyCount && !path.firewallAllowCount) {
		return (
			<DataGridButton asText disabled variant={"text"} color={"inherit"}>
				0
			</DataGridButton>
		);
	}

	return (
		<Stack direction="row" alignItems="center" justifyContent="center">
			{path.firewallDenyCount ? (
				<DataGridButton asText disabled variant={"text"} color={"inherit"}>
					{numeral(path.firewallDenyCount).format("0a")}{" "}
					{window.getCTTranslatedText(
						pluralize("deny", path.firewallDenyCount)
					)}
				</DataGridButton>
			) : null}
			{path.firewallAllowCount ? (
				<DataGridButton asText disabled variant={"text"} color={"inherit"}>
					{numeral(path.firewallAllowCount).format("0a")}{" "}
					{window.getCTTranslatedText(
						pluralize("allow", path.firewallAllowCount)
					)}
				</DataGridButton>
			) : null}
		</Stack>
	);
};

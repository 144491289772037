import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTheme } from "@mui/material";
import { getColor } from "common/constants/colors";

import { reduceMaps } from "common/utils/mapUtils";
import { HierarchyNode, hierarchy, pack } from "d3-hierarchy";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import {
	Direction,
	SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import { AssetStatus } from "pages/assets/types";
import { CompassDirection, PathStatus } from "pages/paths/types";
import React, { useEffect, useMemo, useRef } from "react";
import { Edge, MarkerType, Node, Position } from "reactflow";
import { EdgeDataType } from "../components/EdgeWithButton";
import { useVisxStore } from "../store";
import {
	AssetStatusAggResult,
	CTNodeType,
	EnabledStatusMap,
	GroupNodeDataType,
	PathReviewStatus,
	PathStatusMap,
	PathTypes,
	StatisticAggregate,
	StatisticType,
	TrafficData,
	TrafficDatum,
	TrafficType,
	UserGroupDimension,
} from "../types";
import {
	DEFAULT_INTERNET_NN_NAME,
	DEFAULT_INTRANET_NN_NAME,
	DEFAULT_PRIVATE_NETWORK_NODE_NAME,
	DEFAULT_PUBLIC_NETWORK_NODE_NAME,
	NN_TITLE,
	getChildrenFromNode,
	getNodeId,
	getPathStatusBreakdown,
	getSubnetField,
	isSubnet,
	removeSubnetNodePrefix,
} from "../visx-utils";
import { DEFAULT_NETWORK_NAME, HUB_PARENT } from "./useTrafficData";

const EDGE_STROKE_SIZE = "5";

const reservedIds: { [key: string]: string } = {
	root: "root",
	universe: "universe",
	internet: DEFAULT_PUBLIC_NETWORK_NODE_NAME,
	intranet: DEFAULT_PRIVATE_NETWORK_NODE_NAME,
	managed: "managed",
};

const filterEdges = (
	edges: Edge<EdgeDataType>[],
	includedNodes: Map<string, Node<GroupNodeDataType>>
) => {
	edges = edges.filter(edge => {
		let source = includedNodes.get(edge.source);
		let target = includedNodes.get(edge.target);
		if (!source || !source.type || !target || !target.type) {
			return false;
		}
		const allowedSourceNodeTypesForEdges = ["sourcenode", "ctNode"];
		let allowed =
			(allowedSourceNodeTypesForEdges.includes(source.type) &&
				target.type === "ctNode") ||
			(allowedSourceNodeTypesForEdges.includes(target.type) &&
				source.type === "ctNode");

		return allowed;
	});
	return { edges };
};

interface NodeAndEdgeManagerProps {
	setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
	setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
	enabledStatus: EnabledStatusMap;
	onEdgeClick: (e: any, edge: Edge) => void;
	trafficData: TrafficData | undefined;
}

export function useNodesAndEdges({
	setNodes,
	setEdges,
	enabledStatus,
	onEdgeClick,
	trafficData,
}: NodeAndEdgeManagerProps) {
	const theme = useTheme();
	const { isFeatureEnabled } = useFeatureFlagControl(
		FEATURES.VIZ_MAKE_TEST_DENIED
	);

	const selectedNode = useVisxStore(state => state.selectedNode);
	const _selectedDirection = useVisxStore(state => state.selectedDirection);

	let _selectedSecurityStatus = useVisxStore(
		state => state.selectedSecurityStatus
	);

	const selectedAssetToSecureStatus = useVisxStore(
		state => state.selectedSourceNodeStatus
	);

	const selectedPathStatus = useVisxStore(state => state.selectedPathStatus);
	if (
		_selectedSecurityStatus === SecurityStatus.None &&
		selectedPathStatus === PathReviewStatus.DIFF
	) {
		_selectedSecurityStatus = SecurityStatus.All;
	}

	const isTestMode = useVisxStore(state => state.isTestMode);

	const parentIds = useVisxStore(state => state.parentIds);
	const expansions = useVisxStore(state => state.expansions);

	const selectedDirectionRef = useRef(_selectedDirection);
	selectedDirectionRef.current = _selectedDirection;
	const onEdgeClickRef = useRef(onEdgeClick);
	onEdgeClickRef.current = onEdgeClick;

	const storedPositions = useVisxStore(state => state.positions);
	const storedPositionsRef = useRef(storedPositions);
	storedPositionsRef.current = storedPositions;

	const isPrivateNodeExpanded = useVisxStore(
		state => state.isPrivateNodeExpanded
	);
	const isPublicNodeExpanded = useVisxStore(
		state => state.isPublicNodeExpanded
	);

	const mainSelectedDimension = useVisxStore(state => state.selectedDimension);

	const {
		sourceNodes,
		internetNodes,
		internetEdges,
		intranetNodes,
		intranetEdges,
		managedNodes,
	} = useMemo(() => {
		let sourceNodes: Node<GroupNodeDataType>[] = [];
		let internetNodes: Node<GroupNodeDataType>[] = [];
		let intranetNodes: Node<GroupNodeDataType>[] = [];
		let managedNodes: Node<GroupNodeDataType>[] = [];
		let internetEdges: Edge<EdgeDataType>[] = [];
		let intranetEdges: Edge<EdgeDataType>[] = [];

		if (!trafficData) {
			return {};
		}

		const maxManagedAssetsCounts: {
			[key: string]: StatisticAggregate | undefined;
		} = {};

		const defaultEdgeColor = theme.palette.info.main;

		let includedNodes = new Map<string, Node<GroupNodeDataType>>();

		const handleTrafficData = ({
			sourceNodeName,
			trafficDatum,
		}: {
			sourceNodeName: string;
			trafficDatum: TrafficDatum;
		}) => {
			let selectedStatus =
				selectedPathStatus === PathReviewStatus.Enforced ||
				selectedPathStatus === PathReviewStatus.DIFF
					? StatisticType.Enforced
					: StatisticType.Candidate;

			let selectedSecurityStatus = _selectedSecurityStatus;

			let suggestedPathStatusData = trafficDatum?.data?.[selectedStatus];

			if (!suggestedPathStatusData || !suggestedPathStatusData.stats) {
				let alternate = selectedStatus;
				if (selectedStatus === StatisticType.Enforced) {
					alternate = StatisticType.Candidate;
				} else {
					alternate = StatisticType.Enforced;
				}

				if (alternate === StatisticType.Enforced) {
					selectedSecurityStatus = SecurityStatus.None;
				}

				suggestedPathStatusData = trafficDatum?.data?.[alternate];
			}

			const pathStatusData = suggestedPathStatusData;
			if (!pathStatusData) {
				return;
			}

			const edgeUI: Partial<Edge> = {
				markerEnd: {
					type: MarkerType.ArrowClosed,
					width: 20,
					height: 20,
					color: defaultEdgeColor,
				},
				animated: false,
			};

			let dimensions = new Map(pathStatusData?.dimensionStats);
			let impactedDimensions = new Map<string, boolean>();

			if (pathStatusData.dimensionStats) {
				pathStatusData.dimensionStats.forEach(
					(originalDimensionData, dimensionName) => {
						let statuses = Object.keys(originalDimensionData.statusData);
						statuses.forEach(status => {
							const counts = new Map(
								originalDimensionData.statusData[status].pathStatusMap
							);

							const unreviewedCount = counts.get(PathStatus.Unreviewed) || 0;
							const deniedCount = counts.get(PathStatus.Deny) || 0;

							let compareWith =
								trafficDatum?.data?.enforced?.dimensionStats?.get(dimensionName)
									?.statusData?.[status]?.pathStatusMap;
							const makeUnsecure = () => {
								let total = getTotal(counts);
								let templateDenied =
									counts.get(PathStatus.DeniedByTemplate) ?? 0;
								total = total - templateDenied;

								counts.clear();
								if (templateDenied) {
									counts.set(PathStatus.DeniedByTemplate, templateDenied);
								}
								if (total) {
									counts.set(PathStatus.Unreviewed, total);
								}

								let impacted = !isEqual(counts, compareWith);
								impactedDimensions.set(dimensionName, impacted);
							};
							const makeUnreviewedDenied = () => {
								counts.delete(PathStatus.Unreviewed);
								if (unreviewedCount) {
									counts.set(
										PathStatus.Deny,
										(counts.get(PathStatus.Deny) || 0) + unreviewedCount
									);
								}
								let impacted = !isEqual(counts, compareWith);
								impactedDimensions.set(dimensionName, impacted);
							};
							const makeTestDenied = () => {
								counts.delete(PathStatus.Unreviewed);
								counts.delete(PathStatus.Deny);
								if (unreviewedCount) {
									counts.set(
										PathStatus.AllowTestDenied,
										(counts.get(PathStatus.AllowTestDenied) || 0) +
											unreviewedCount
									);
								}
								if (deniedCount) {
									counts.set(
										PathStatus.AllowTestDenied,
										(counts.get(PathStatus.AllowTestDenied) || 0) + deniedCount
									);
								}
							};

							let runSimulation = false;
							if (selectedAssetToSecureStatus !== SecurityStatus.None) {
								if (selectedAssetToSecureStatus === SecurityStatus.All) {
									runSimulation =
										status === AssetStatus.SimulateSecureAll ||
										status === AssetStatus.SecureAll;
								} else if (
									selectedAssetToSecureStatus === SecurityStatus.Internet
								) {
									runSimulation =
										status === AssetStatus.SimulateSecureInternet ||
										status === AssetStatus.SecureInternet;
								} else if (
									selectedAssetToSecureStatus === SecurityStatus.Unsecure
								) {
									runSimulation = status === AssetStatus.Unsecured;
								}
							}

							if (runSimulation) {
								if (
									selectedNode?.label === sourceNodeName ||
									!selectedNode ||
									getChildrenFromNode(selectedNode, trafficData)?.has(
										sourceNodeName
									)
								) {
									if (selectedSecurityStatus === SecurityStatus.All) {
										makeUnreviewedDenied();
										if (isTestMode) {
											makeTestDenied();
										}
									} else if (
										selectedSecurityStatus <= SecurityStatus.Internet &&
										selectedSecurityStatus !== SecurityStatus.None
									) {
										makeUnsecure();
									}
								}
							}

							let presentData =
								dimensions.get(dimensionName) || originalDimensionData;

							let newData = {
								...presentData,
								statusData: {
									...presentData.statusData,
									[status]: {
										...presentData.statusData[status],
										pathStatusMap: counts,
									},
								},
							};

							dimensions.set(dimensionName, newData);
						});
					}
				);
			}

			if (dimensions.size > 0) {
				dimensions.forEach((dimensionData, key) => {
					const sourceHandle =
						selectedDirectionRef.current === Direction.Outbound ? "sb" : "st";
					const handle =
						selectedDirectionRef.current === Direction.Outbound ? "tt" : "st";

					const type = "ctNode";

					let filteredTrafficDatum: TrafficDatum = {
						...trafficDatum,
						children: undefined,
						name: key,
					};

					const isImpacted = impactedDimensions?.get(key);
					const opacity =
						selectedPathStatus === PathReviewStatus.Enforced
							? undefined
							: isImpacted
								? 1
								: 0.3;

					if (!includedNodes.has(key)) {
						const nodeExpansion = expansions?.find(e => e.parent.name === key);

						let id = getNodeId(key);

						const parentNode =
							trafficData?.parent?.name ||
							parentIds.get(key) ||
							reservedIds["managed"];
						const node: Node<GroupNodeDataType> = {
							id: key,
							type: type,
							data: {
								label: key,
								id,
								title: "Managed Asset",
								handle,
								dimension: dimensionData?.dimension,
								trafficData: filteredTrafficDatum,
								canExpand:
									mainSelectedDimension?.name !== UserGroupDimension.name,
								type: CTNodeType.CONNECTIONS,
								childrenExpandedBy: nodeExpansion?.dimension,
							},
							draggable: true,
							parentNode,
							dragHandle: nodeExpansion ? ".ct-drag-handle" : undefined,
							// extent: "parent",
							expandParent: true,
							position: { x: 0, y: 0 },
							targetPosition: Position.Left,
							sourcePosition: Position.Left,
							style: {
								opacity,
							},
						};
						managedNodes.push(node);
						includedNodes.set(key, node);
					} else {
						let previousNode = includedNodes.get(key);
						if (previousNode && previousNode?.data.trafficData) {
							filteredTrafficDatum = previousNode.data.trafficData;
						}
						if (isImpacted && includedNodes.get(key)) {
							let node = includedNodes.get(key);
							if (node) {
								node.style = { ...node.style, opacity };
							}
						}
					}

					filteredTrafficDatum.data = merge({}, filteredTrafficDatum.data);

					let previousMax = maxManagedAssetsCounts[key];

					Object.values(StatisticType).forEach(statType => {
						let statHolder = filteredTrafficDatum.data?.[statType];
						if (statHolder) {
							statHolder.aggregates.assetAggregate = Math.max(
								previousMax?.[statType] || 0,
								statHolder.aggregates.assetAggregate || 0
							);

							if (!previousMax) {
								previousMax = {};
							}
							previousMax[statType] = statHolder.aggregates.assetAggregate;
						}
					});

					maxManagedAssetsCounts[key] = previousMax;

					addEdges({
						edgesHolder: intranetEdges,
						trafficData: dimensionData,
						nodeId: key,
						sourceHandle,
						targetHandle:
							selectedDirectionRef.current === Direction.Outbound ? "tt" : "tt",
						data: {
							trafficInfo: {
								compassDirection: CompassDirection.EastWest,
								type: TrafficType.Managed,
							},
							// interactive: PathReviewStatus.Enforced
							//   ? true
							//   : isImpacted === true,
							onButtonClick: onEdgeClickRef.current,
							icon: <InfoOutlinedIcon />,
						},
						selectedDirection: selectedDirectionRef.current,
						defaultEdgeColor,
						sourceNodeName,
						edgeUI: getEdgeUI({
							edgeUI,
							animated: isImpacted,
							dashed: isImpacted,
							opacity,
						}),
						enabledStatus,
					});
				});
			}

			const {
				computed: internetNetworkMap,
				impactedBySimulation: impactedInternetSimulation,
			} = createNetworkMap({
				sourceNodeName,
				selectedNode,
				direction: CompassDirection.NorthSouth,
				trafficAggs: pathStatusData?.northSouthNetworkStats || new Map(),
				compareTrafficAgs: trafficDatum?.data.enforced?.northSouthNetworkStats,
				selectedSecurityStatus,
				enabledStatus,
				selectedPathStatus,
				isExpanded: isPublicNodeExpanded,
				selectedAssetToSecureStatus,
				isTestMode,
				isFeatureEnabled,
				trafficData,
			});
			const {
				computed: intranetNetworkMap,
				impactedBySimulation: impactedIntranetSimulation,
			} = createNetworkMap({
				sourceNodeName,
				selectedNode,
				direction: CompassDirection.EastWest,
				trafficAggs: pathStatusData?.eastWestNetworkStats || new Map(),
				compareTrafficAgs: trafficDatum?.data.enforced?.eastWestNetworkStats,
				selectedSecurityStatus,
				enabledStatus,
				selectedPathStatus,
				isExpanded: isPrivateNodeExpanded,
				selectedAssetToSecureStatus,
				isTestMode,
				isFeatureEnabled,
				trafficData,
			});

			const filteredTrafficDatum: TrafficDatum = {
				...trafficDatum,
				parent: undefined,
				children: undefined,
			};
			filteredTrafficDatum.data = merge({}, trafficDatum.data);

			Object.values(StatisticType).forEach(type => {
				let statHolder = filteredTrafficDatum.data[type];
				if (statHolder) {
					statHolder.aggregates.assetAggregate = 0;
				}
			});

			const statHolder = { ...filteredTrafficDatum.data?.[selectedStatus] };
			if (statHolder) {
				statHolder.eastWestNetworkStats = intranetNetworkMap;
				statHolder.northSouthNetworkStats = internetNetworkMap;
			}

			if (internetNetworkMap.size > 0) {
				internetNetworkMap.forEach((trafficData, key) => {
					const sourceHandle =
						selectedDirectionRef.current === Direction.Outbound ? "st" : "sb";
					const handle =
						selectedDirectionRef.current === Direction.Outbound ? "tt" : "sb";

					let label =
						key === DEFAULT_NETWORK_NAME ? DEFAULT_INTERNET_NN_NAME : key;

					let id = getNodeId(key);
					id = key === DEFAULT_NETWORK_NAME ? label : id;

					let trafficType: TrafficType =
						key === DEFAULT_NETWORK_NAME
							? TrafficType.Unknown
							: TrafficType.NamedNetwork;

					const isImpacted = impactedInternetSimulation?.get(key);
					const opacity =
						selectedPathStatus === PathReviewStatus.Enforced
							? undefined
							: isImpacted
								? 1
								: 0.3;

					addEdges({
						edgesHolder: internetEdges,
						trafficData,
						nodeId: label,
						sourceHandle,
						targetHandle:
							selectedDirectionRef.current === Direction.Outbound ? "tt" : "tt",
						data: {
							trafficInfo: {
								compassDirection: CompassDirection.NorthSouth,
								type: trafficType,
							},
							onButtonClick: onEdgeClickRef.current,
							icon: <InfoOutlinedIcon />,
							// interactive: PathReviewStatus.Enforced
							//   ? true
							//   : isImpacted === true,
						},
						selectedDirection: selectedDirectionRef.current,
						defaultEdgeColor,
						sourceNodeName,
						edgeUI: getEdgeUI({
							edgeUI,
							animated: isImpacted,
							dashed: isImpacted,
							opacity,
						}),
						enabledStatus,
					});

					if (!includedNodes.has(id)) {
						const node: Node<GroupNodeDataType> = {
							id,
							type: "ctNode",
							data: {
								label,
								id,
								title: NN_TITLE,
								handle,
								canExpand: id === DEFAULT_PUBLIC_NETWORK_NODE_NAME,
								trafficData: filteredTrafficDatum,
								type: CTNodeType.CONNECTIONS,
							},
							parentNode:
								id === DEFAULT_PUBLIC_NETWORK_NODE_NAME
									? undefined
									: DEFAULT_PUBLIC_NETWORK_NODE_NAME,
							// extent: "parent",
							draggable: true,
							expandParent:
								id !== DEFAULT_PUBLIC_NETWORK_NODE_NAME ? true : undefined,
							position: { x: 0, y: 0 },
							targetPosition: Position.Right,
							sourcePosition: Position.Right,
							style: {
								opacity,
							},
						};
						internetNodes.push(node);
						includedNodes.set(id, node);
					} else if (isImpacted && includedNodes.get(label)) {
						let node = includedNodes.get(label);
						if (node) {
							node.style = { ...node.style, opacity };
						}
					}
				});
			}

			if (intranetNetworkMap.size > 0) {
				intranetNetworkMap.forEach((trafficData, key) => {
					const sourceHandle =
						selectedDirectionRef.current === Direction.Outbound ? "sb" : "st";
					const handle =
						selectedDirectionRef.current === Direction.Outbound ? "tt" : "st";

					let id = getNodeId(key);

					let label =
						key === DEFAULT_NETWORK_NAME ? DEFAULT_INTRANET_NN_NAME : key;
					id = key === DEFAULT_NETWORK_NAME ? label : id;

					let trafficType =
						key === DEFAULT_NETWORK_NAME
							? TrafficType.Unknown
							: TrafficType.NamedNetwork;

					const isImpacted = impactedIntranetSimulation?.get(key);
					const opacity =
						selectedPathStatus === PathReviewStatus.Enforced
							? undefined
							: isImpacted
								? 1
								: 0.3;

					addEdges({
						edgesHolder: intranetEdges,
						trafficData,
						nodeId: label,
						sourceHandle,
						targetHandle:
							selectedDirectionRef.current === Direction.Outbound ? "tt" : "tt",
						data: {
							trafficInfo: {
								compassDirection: CompassDirection.EastWest,
								type: trafficType,
							},
							onButtonClick: onEdgeClickRef.current,
							icon: <InfoOutlinedIcon />,
							// interactive: PathReviewStatus.Enforced
							//   ? true
							//   : isImpacted === true,
						},
						selectedDirection: selectedDirectionRef.current,
						defaultEdgeColor,
						sourceNodeName,
						edgeUI: getEdgeUI({
							edgeUI,
							animated: isImpacted,
							dashed: isImpacted,
							opacity,
						}),
						enabledStatus,
					});

					const isSubnetNode = isSubnet(label);

					let networkOrSubnetName = removeSubnetNodePrefix(label);

					let dimensionName;
					if (isSubnetNode) {
						dimensionName = getSubnetField(label);
					} else if (id !== DEFAULT_PRIVATE_NETWORK_NODE_NAME) {
						dimensionName = "namednetworkname";
					}

					if (!includedNodes.has(label)) {
						const nodeExpansion = expansions?.find(
							e => e.parent.name === networkOrSubnetName
						);

						const parentNode =
							filteredTrafficDatum?.parent?.name ||
							parentIds.get(key) ||
							DEFAULT_PRIVATE_NETWORK_NODE_NAME;

						const node: Node<GroupNodeDataType> = {
							id: label,
							type: "ctNode",
							data: {
								id,
								label: networkOrSubnetName,
								title: NN_TITLE,
								handle,
								canExpand:
									id === DEFAULT_PRIVATE_NETWORK_NODE_NAME ||
									id === DEFAULT_INTRANET_NN_NAME,
								trafficData: filteredTrafficDatum,
								type: CTNodeType.CONNECTIONS,
								dimension: dimensionName
									? {
											dataType: "string",
											label: "",
											name: dimensionName,
										}
									: undefined,
								childrenExpandedBy: nodeExpansion?.dimension,
							},
							draggable: true,
							position: { x: 0, y: 0 },
							targetPosition: Position.Left,
							sourcePosition: Position.Left,
							parentNode:
								id === DEFAULT_PRIVATE_NETWORK_NODE_NAME
									? undefined
									: parentNode,
							// extent: "parent",
							expandParent: id !== DEFAULT_PRIVATE_NETWORK_NODE_NAME,
							style: {
								opacity,
							},
						};

						intranetNodes.push(node);
						includedNodes.set(id, node);
					} else if (isImpacted && includedNodes.get(label)) {
						let node = includedNodes.get(label);
						if (node) {
							node.style = { ...node.style, opacity };
						}
					}
				});
			}
		};

		const sourceNodesMap = new Map();
		const sourceDimensions = Object.keys(trafficData || {});

		sourceDimensions.forEach(sourceDimensionName => {
			let sourceNodeName = sourceDimensionName;

			const handle = "all";

			const sourceTrafficData = trafficData[sourceDimensionName];
			const parentNode =
				sourceTrafficData?.parent?.name || reservedIds["universe"];

			const sourceNodeId = `${sourceNodeName}`;

			const nodeExpansion = expansions?.find(
				e => e.parent.name === sourceNodeId
			);

			let id = getNodeId(sourceDimensionName);

			const node: Node<GroupNodeDataType> = {
				id: sourceNodeId,
				type: "ctNode",
				data: {
					id,
					label: sourceNodeName,
					title: "Managed Asset",
					handle,
					trafficData: sourceTrafficData,
					dimension: sourceTrafficData.sourceDimension,
					type: CTNodeType.HUB,
					canExpand: mainSelectedDimension?.name !== UserGroupDimension.name,
					childrenExpandedBy: nodeExpansion?.dimension,
				},
				draggable: true,
				parentNode,
				dragHandle: nodeExpansion ? ".ct-drag-handle" : undefined,
				// extent: "parent",
				expandParent: true,
				position: { x: 0, y: 0 },
				targetPosition: Position.Right,
				sourcePosition: Position.Right,
			};
			sourceNodesMap.set(sourceNodeId, node);
			includedNodes.set(sourceNodeId, node);
		});

		sourceDimensions.forEach(sourceNodeId => {
			const data = trafficData[sourceNodeId];

			handleTrafficData({
				sourceNodeName: sourceNodeId,
				trafficDatum: data,
			});
		});

		sourceNodes = [...Array.from(sourceNodesMap.values() || [])];

		let nodesWithoutChildren = new Map(includedNodes);
		includedNodes.forEach((node, id) => {
			if (node.parentNode) {
				nodesWithoutChildren.delete(node.parentNode);
			}
		});

		let { edges: filteredInternedEdges } = filterEdges(
			internetEdges,
			nodesWithoutChildren
		);

		internetEdges = filteredInternedEdges;

		let { edges: filteredIntranetEdges } = filterEdges(
			intranetEdges,
			nodesWithoutChildren
		);

		intranetEdges = filteredIntranetEdges;

		return {
			sourceNodes,
			internetNodes,
			intranetNodes,
			internetEdges,
			intranetEdges,
			managedNodes,
		};
	}, [
		trafficData,
		theme.palette.info.main,
		selectedPathStatus,
		_selectedSecurityStatus,
		enabledStatus,
		expansions,
		parentIds,
		selectedNode,
		isPrivateNodeExpanded,
		isPublicNodeExpanded,
		selectedAssetToSecureStatus,
		isTestMode,
		isFeatureEnabled,
		mainSelectedDimension,
	]);

	useEffect(() => {
		if (!sourceNodes) {
			setNodes([]);
			setEdges([]);
			return;
		}

		const mapToData = (
			nodes: Node<GroupNodeDataType>[],
			groupedInfo: { [key: string]: Node<GroupNodeDataType>[] },
			filter = false
		): Node<GroupNodeDataType>[] | undefined => {
			if (!nodes) {
				return;
			}
			let mappedNodes: any = nodes.map(n => {
				const children = groupedInfo[n.id];
				return {
					...n,
					value: 1,
					name: n.id,
					children: mapToData(children, groupedInfo),
				};
			});

			if (!filter) {
				return mappedNodes;
			}

			return mappedNodes.filter((node: Node<GroupNodeDataType>) => {
				let parent = node.data?.trafficData?.parent;
				if (
					parent?.name.startsWith(HUB_PARENT.label) &&
					!node.data.label.startsWith(HUB_PARENT.label)
				) {
					return true;
				}
				return !parent;
			});
		};

		const coordinateMap = new Map();

		const hierarchicalData = {
			name: reservedIds.root,
			children: [
				{
					name: reservedIds.universe,
					children: mapToData(
						sourceNodes,
						groupBy(sourceNodes, d => d.parentNode),
						true
					),
				},
				{
					name: reservedIds.internet,
					children: mapToData(
						internetNodes,
						groupBy(internetNodes, d => d.parentNode),
						true
					)?.filter(node => node.id !== DEFAULT_PUBLIC_NETWORK_NODE_NAME),
				},
				{
					name: reservedIds.intranet,
					children: mapToData(
						intranetNodes,
						groupBy(intranetNodes, d => d.parentNode),
						true
					)?.filter(node => node.id !== DEFAULT_PRIVATE_NETWORK_NODE_NAME),
				},
				{
					name: reservedIds.managed,
					children: mapToData(
						managedNodes,
						groupBy(managedNodes, d => d.parentNode),
						true
					)?.filter(node => node.parentNode === reservedIds.managed),
				},
			],
			value: 0,
		};

		const root = hierarchy(hierarchicalData).sum(d => {
			return d.value || 0;
		});

		root.sort((a, b) => (b.value || 0) - (a.value || 0));

		const parentDiv = document.querySelector("#visualizer-container");
		const width = parentDiv?.clientWidth || 1000;
		const height = parentDiv?.clientHeight || 1000;

		const partition = pack<{ name: string }>()
			.size([width, height])
			.padding(node => {
				if (node.children?.length) {
					if (
						[
							reservedIds["internet"],
							reservedIds["intranet"],
							reservedIds["managed"],
						].includes(node?.data?.name)
					) {
						return 30;
					}
					return 50;
				}
				return 50;
			})
			.radius(d => {
				let r = (d.value || 1) * 35;
				return r;
			});

		const getPosition = (n: HierarchyNode<any>) => {
			// @ts-ignore
			// let id = n.data.name || n.data.id;
			// if (reservedIds[id]) {
			//   return;
			// }
			return {
				// @ts-ignore
				x: n.x,
				// @ts-ignore
				y: n.y,
				// @ts-ignore
				r: n.r,
				zIndex: n.depth,
			};
		};
		partition(root);
		root.descendants().forEach(n => {
			let position = getPosition(n);
			if (!position) {
				return;
			}
			// @ts-ignore
			let id = n.data.name || n.data.id;

			if (n.parent) {
				let parentPosition = getPosition(n.parent);
				if (parentPosition) {
					position.x = position.x - parentPosition.x + parentPosition.r;
					position.y = position.y - parentPosition.y + parentPosition.r;
				}
			}
			coordinateMap.set(id, position);
		});

		const setPositions = (nodes: Node<any>[]) => {
			nodes.forEach(node => {
				const calculatedPosition = coordinateMap.get(node.id);
				node.position = calculatedPosition || node.position;
				const diameter = (calculatedPosition?.r || 1) * 2;

				let multiple = 1;
				let storedPosition = storedPositionsRef.current?.get(node.id);
				const defaultStyles = {
					width: diameter * multiple,
					height: diameter * multiple,
				};
				let styles = storedPosition?.styles || defaultStyles;

				node.style = { ...node.style, ...styles };

				node.width = +(styles.width || defaultStyles.width);
				node.height = +(styles.height || defaultStyles.height);

				node.zIndex = calculatedPosition?.zIndex;

				if (storedPosition?.position) {
					node.position = storedPosition.position;
				} else {
					node.position = {
						x: node.position.x - diameter / 2,
						y: node.position.y - diameter / 2,
					};
				}
			});
		};

		const containers: Array<Node> = [];

		if (sourceNodes.length) {
			containers.push({
				data: { label: window.getCTTranslatedText("Selected Assets") },
				id: reservedIds["universe"],
				position: { x: 0, y: 0 },
				draggable: true,
				dragHandle: ".ct-drag-handle",
				type: "ctNode",
			});
		}

		if (managedNodes.length) {
			containers.push({
				data: { label: window.getCTTranslatedText("Managed Assets") },
				id: reservedIds["managed"],
				position: { x: 0, y: 0 },
				draggable: true,
				dragHandle: ".ct-drag-handle",
				type: "ctNode",
			});
		}

		setPositions(containers);
		setPositions(sourceNodes);
		setPositions(internetNodes);
		setPositions(intranetNodes);
		setPositions(managedNodes);

		const selectedAssetsContainer = containers[0];
		const managedAssetsContainer = containers[1];
		const internetContainer = internetNodes[0];
		const intranetContainer = intranetNodes[0];

		const padding = 50;

		if (internetContainer) {
			intranetContainer.position = storedPositionsRef.current?.get(
				intranetContainer.id
			)?.position ?? {
				x:
					numberOrZero(selectedAssetsContainer.position.x) -
					numberOrZero(intranetContainer?.width) -
					padding,
				y:
					selectedAssetsContainer.position.y +
					numberOrZero(selectedAssetsContainer.height) / 2 -
					numberOrZero(intranetContainer.height) / 2,
			};
		}

		if (internetContainer) {
			internetContainer.position = storedPositionsRef.current?.get(
				internetContainer.id
			)?.position ?? {
				x:
					numberOrZero(selectedAssetsContainer.position.x) +
					numberOrZero(selectedAssetsContainer?.width) +
					padding,
				y:
					selectedAssetsContainer.position.y +
					numberOrZero(selectedAssetsContainer.height) / 2 -
					numberOrZero(internetContainer.height) / 2,
			};
		}

		if (managedAssetsContainer) {
			managedAssetsContainer.position = storedPositionsRef.current?.get(
				managedAssetsContainer.id
			)?.position ?? {
				x:
					selectedAssetsContainer.position.x +
					numberOrZero(selectedAssetsContainer.width) / 2 -
					numberOrZero(managedAssetsContainer.height) / 2,
				y:
					numberOrZero(selectedAssetsContainer.position.y) +
					numberOrZero(selectedAssetsContainer?.height) +
					padding,
			};
		}

		setNodes([
			...containers,
			...sourceNodes,
			...internetNodes,
			...intranetNodes,
			...managedNodes,
		]);
		setEdges([...internetEdges, ...intranetEdges]);
	}, [
		internetEdges,
		internetNodes,
		intranetEdges,
		intranetNodes,
		setEdges,
		setNodes,
		sourceNodes,
		managedNodes,
	]);
}

const numberOrZero = (value?: number | null | undefined) => {
	return value ?? 0;
};

const filterMap = (map: PathStatusMap, enabledStatus: EnabledStatusMap) => {
	[
		PathStatus.Allow,
		PathStatus.Deny,
		PathStatus.Unreviewed,
		PathStatus.DeniedByTemplate,
		PathStatus.AllowedByTemplate,
		PathStatus.AllowedByProgressive,
		PathStatus.AllowTestDenied,
		PathStatus.AllowTestDeniedViolation,
		PathStatus.AllowedByTestUIOnly,
		"Mixed",
	].forEach(status => {
		if (!map.get(status as PathStatus)) {
			map.delete(status as PathStatus);
		}
		if (
			!enabledStatus.has(status as PathTypes) ||
			enabledStatus.get(status as PathTypes) === false
		) {
			if (status === "Mixed" && getPathStatusBreakdown(map).hasMixed) {
				map.clear();
			} else {
				map.delete(status as PathStatus);
				if (status === PathStatus.AllowedByTestUIOnly) {
					map.delete(PathStatus.AllowTestDenied);
					map.delete(PathStatus.AllowTestDeniedViolation);
				}
				if (status === PathStatus.Allow) {
					map.delete(PathStatus.AllowedByTemplate);
					map.delete(PathStatus.AllowedByProgressive);
				} else if (status === PathStatus.Deny) {
					map.delete(PathStatus.DeniedByTemplate);
				}
			}
		}
	});
};

interface CreateNetworkMapProps {
	sourceNodeName: string;
	selectedNode: GroupNodeDataType | undefined;
	direction: CompassDirection;
	trafficAggs: Map<string, AssetStatusAggResult>;
	compareTrafficAgs?: Map<string, AssetStatusAggResult> | undefined;
	selectedPathStatus: PathReviewStatus;
	enabledStatus: EnabledStatusMap;
	selectedSecurityStatus: SecurityStatus;
	isExpanded?: boolean;
	selectedAssetToSecureStatus: SecurityStatus;
	isTestMode: boolean;
	isFeatureEnabled: boolean;
	trafficData: TrafficData | undefined;
}

function createNetworkMap({
	sourceNodeName,
	selectedNode,
	direction,
	trafficAggs,
	compareTrafficAgs,
	selectedSecurityStatus,
	enabledStatus,
	selectedPathStatus,
	isExpanded,
	selectedAssetToSecureStatus,
	isTestMode,
	isFeatureEnabled,
	trafficData,
}: CreateNetworkMapProps): {
	computed: Map<string, AssetStatusAggResult>;
	impactedBySimulation?: Map<string, boolean>;
} {
	let networkMap = new Map<string, AssetStatusAggResult>();
	if (!trafficAggs) {
		return { computed: networkMap };
	}

	const impactedBySimulation = new Map<string, boolean>();

	trafficAggs.forEach((assetAgg, networkName) => {
		let assetStatuses = Object.keys(assetAgg.statusData);
		assetStatuses.forEach(assetStatus => {
			let agg = assetAgg.statusData[assetStatus];
			let counts = new Map<PathStatus, number>(agg.pathStatusMap);

			let unreviewedCount = counts.get(PathStatus.Unreviewed) || 0;
			let deniedCount = counts.get(PathStatus.Deny) || 0;

			let compareWith =
				compareTrafficAgs?.get(networkName)?.statusData?.[assetStatus]
					?.pathStatusMap;

			const makeUnsecure = () => {
				let total = getTotal(counts);

				let templateDenied = counts.get(PathStatus.DeniedByTemplate) ?? 0;
				total = total - templateDenied;

				counts.clear();
				if (templateDenied) {
					counts.set(PathStatus.DeniedByTemplate, templateDenied);
				}

				if (total) {
					counts.set(PathStatus.Unreviewed, total);
				}
				let impacted = !isEqual(counts, compareWith);
				if (impacted) {
					impactedBySimulation.set(networkName, true);
				}
			};
			const makeUnreviewedDenied = () => {
				counts.delete(PathStatus.Unreviewed);
				if (unreviewedCount) {
					counts.set(
						PathStatus.Deny,
						(counts.get(PathStatus.Deny) || 0) + unreviewedCount
					);
				}
				let impacted = !isEqual(counts, compareWith);
				if (impacted) {
					impactedBySimulation.set(networkName, true);
				}
			};
			const makeTestDenied = () => {
				counts.delete(PathStatus.Unreviewed);
				counts.delete(PathStatus.Deny);
				if (unreviewedCount) {
					counts.set(
						PathStatus.AllowTestDenied,
						(counts.get(PathStatus.AllowTestDenied) || 0) + unreviewedCount
					);
				}
				if (deniedCount) {
					counts.set(
						PathStatus.AllowTestDenied,
						(counts.get(PathStatus.AllowTestDenied) || 0) + deniedCount
					);
				}
				let impacted = !isEqual(counts, compareWith);
				if (impacted) {
					impactedBySimulation.set(networkName, true);
				}
			};

			let runSimulation = false;
			if (selectedAssetToSecureStatus !== SecurityStatus.None) {
				if (selectedAssetToSecureStatus === SecurityStatus.All) {
					runSimulation =
						assetStatus === AssetStatus.SimulateSecureAll ||
						assetStatus === AssetStatus.SecureAll;
				} else if (selectedAssetToSecureStatus === SecurityStatus.Internet) {
					runSimulation =
						assetStatus === AssetStatus.SimulateSecureInternet ||
						assetStatus === AssetStatus.SecureInternet;
				} else if (selectedAssetToSecureStatus === SecurityStatus.Unsecure) {
					runSimulation = assetStatus === AssetStatus.Unsecured;
				}
			}

			if (runSimulation) {
				if (
					selectedNode?.label === sourceNodeName ||
					!selectedNode ||
					getChildrenFromNode(selectedNode, trafficData)?.has(sourceNodeName)
				) {
					if (direction === CompassDirection.EastWest) {
						if (selectedSecurityStatus === SecurityStatus.Unsecure) {
							makeUnsecure();
						} else if (selectedSecurityStatus === SecurityStatus.Internet) {
							makeUnsecure();
						} else if (selectedSecurityStatus === SecurityStatus.All) {
							if (isTestMode) {
								makeTestDenied();
							} else {
								makeUnreviewedDenied();
							}
						}
					} else if (direction === CompassDirection.NorthSouth) {
						if (selectedSecurityStatus === SecurityStatus.Unsecure) {
							makeUnsecure();
						} else if (selectedSecurityStatus >= SecurityStatus.Internet) {
							if (isTestMode) {
								if (
									(isFeatureEnabled &&
										selectedSecurityStatus >= SecurityStatus.Internet) ||
									selectedSecurityStatus === SecurityStatus.Internet
								) {
									makeTestDenied();
								} else {
									makeUnreviewedDenied();
								}
							} else {
								makeUnreviewedDenied();
							}
						}
					}
				}
			}

			let oldData = networkMap.get(networkName) || { statusData: undefined };
			let newData = {
				...oldData,
				statusData: {
					...oldData?.statusData,
					[assetStatus]: {
						...oldData?.statusData?.[assetStatus],
						pathStatusMap: counts,
					},
				},
			};

			networkMap.set(networkName, newData);
		});
	});

	let combinedMap = new Map<string, AssetStatusAggResult>();
	let name =
		direction === CompassDirection.EastWest
			? DEFAULT_PRIVATE_NETWORK_NODE_NAME
			: DEFAULT_PUBLIC_NETWORK_NODE_NAME;
	let maps: { [key: string]: Array<Map<string, number>> } = {};

	let combinedPathStatusMap: {
		[key: string]: { pathStatusMap: Map<PathStatus, number> };
	} = {};

	networkMap.forEach(v => {
		Object.keys(v.statusData)?.forEach(assetStatus => {
			if (!maps[assetStatus]) {
				maps[assetStatus] = [];
			}
			maps[assetStatus].push(v.statusData[assetStatus].pathStatusMap);
		});
	});

	Object.keys(maps)?.forEach(assetStatus => {
		combinedPathStatusMap[assetStatus] = {
			pathStatusMap: reduceMaps(maps[assetStatus]),
		};
	});

	if (impactedBySimulation.size) {
		impactedBySimulation.set(name, true);
	}

	if (!isExpanded) {
		combinedMap.set(name, { statusData: combinedPathStatusMap });
		return { computed: combinedMap, impactedBySimulation };
	}

	let mergedMaps = new Map<string, AssetStatusAggResult>();
	mergedMaps.set(name, { statusData: combinedPathStatusMap });

	mergedMaps = new Map([...mergedMaps, ...networkMap]);
	return { computed: mergedMaps, impactedBySimulation };
}

function addEdges({
	edgesHolder,
	enabledStatus,
	trafficData,
	nodeId,
	sourceHandle,
	targetHandle,
	data,
	selectedDirection,
	defaultEdgeColor,
	sourceNodeName,
	edgeUI,
}: {
	edgesHolder: Array<Edge>;
	trafficData: AssetStatusAggResult;
	nodeId: string;
	sourceHandle: string | undefined;
	targetHandle: string | undefined;
	data: Omit<EdgeDataType, "statusMap">;
	selectedDirection: Direction;
	defaultEdgeColor: string;
	sourceNodeName: string;
	edgeUI: any;
	enabledStatus: EnabledStatusMap;
}) {
	let map: PathStatusMap = new Map();

	Object.keys(trafficData?.statusData)?.forEach(assetStatus => {
		const assetStatusMap = trafficData.statusData[assetStatus].pathStatusMap;
		map = reduceMaps([map, assetStatusMap]);
	});

	filterMap(map, enabledStatus);

	if (!map.size) {
		return;
	}

	let color = defaultEdgeColor;
	if (getPathStatusBreakdown(map).hasMixed) {
		color = getColor("Mixed");
	} else {
		let item = map.keys().next()?.value;
		color = getColor(item);
	}

	if (edgeUI?.markerEnd) {
		edgeUI = { ...edgeUI };
		edgeUI.markerEnd = { ...edgeUI.markerEnd };
		edgeUI.markerEnd.color = color;
	}

	let edgeData: EdgeDataType = {
		...data,
		statusMap: new Map(),
		...trafficData,
	};
	edgeData.statusMap = map;

	const parentNode = sourceNodeName;

	const pushEdges = (parentNodeName: string) => {
		edgesHolder.push({
			id: `${parentNodeName}-${nodeId}`,
			source: selectedDirection === Direction.Inbound ? nodeId : parentNodeName,
			target:
				selectedDirection === Direction.Outbound ? nodeId : parentNodeName,
			...edgeUI,
			style: {
				stroke: color,
				...edgeUI.style,
			},
			sourceHandle,
			targetHandle,
			data: edgeData,
			type: "edgeWithButton",
		});
	};

	pushEdges(parentNode);
}

export function findDirectAncestorsAndChildren<T>(
	edges: Edge<T>[],
	nodeId: string
) {
	const ancestors: string[] = [];
	const children: string[] = [];

	edges.forEach(edge => {
		if (edge.target === nodeId) {
			ancestors.push(edge.source);
		} else if (edge.source === nodeId) {
			children.push(edge.target);
		}
	});

	return { ancestors, children };
}

function getEdgeUI({
	edgeUI,
	dashed,
	animated,
	opacity,
}: {
	edgeUI: any;
	dashed: boolean | undefined;
	animated: boolean | undefined;
	opacity: number | undefined;
}) {
	return {
		...edgeUI,
		animated,
		style: {
			opacity,
			strokeDasharray: dashed ? EDGE_STROKE_SIZE : undefined,
		},
	};
}

function getTotal(pathStatusMap: PathStatusMap) {
	return Array.from(pathStatusMap.values() || []).reduce(
		(prev, current) => prev + current,
		0
	);
}
export { DEFAULT_PRIVATE_NETWORK_NODE_NAME };

import { WorkRequest } from "modules/AppBar/types";
import { DataLoaderResponse } from "modules/core/types";

export interface PendingWorkRequestsColumnRenderProps {
	pendingItems: WorkRequest;
}

export enum WorkRequestAction {
	AssetAdded = "AssetAdded",
	AssetUpdated = "AssetUpdated",
	TagRuleAdded = "TagRuleAdded",
	TagRuleModified = "TagRuleModified",
	TagBasedPolicyAdded = "TagBasedPolicyAdded",
	TagBasedPolicyAssignmentsModified = "TagBasedPolicyAssignmentsModified",
	TagBasedPolicyDeleted = "TagBasedPolicyDeleted",
	TagUpdated = "TagUpdated",
	TagDeleted = "TagDeleted",
	NamedNetworkAssigned = "NamedNetworkAssigned",
	NamedNetworkUnassigned = "NamedNetworkUnassigned",
	NamedNetworkDeleted = "NamedNetworkDeleted",
	NamedNetworkRangeAdd = "NamedNetworkRangeAdd",
	NamedNetworkRangeRemove = "NamedNetworkRangeRemove",
	NamedNetworkProgramAsIntranet = "NamedNetworkProgramAsIntranet",
	ScimPullData = "ScimPullData",
	TagBasedPolicyMemberChange = "TagBasedPolicyMemberChange",
	EvaluateTemplates = "EvaluateTemplates",
	TemplateEdit = "TemplateEdit",
	UpdateNetworkMap = "UpdateNetworkMap",
	EnableUserGroups = "EnableUserGroups",
	DisableUserGroups = "DisableUserGroups",
	AgentDeleted = "AgentDeleted",
	TagBasedPolicyProgressiveInboundRefresh = "TagBasedPolicyProgressiveInboundRefresh",
	AddScheduledJob = "AddScheduledJob",
	EvaluateTemplatesRuleEngine = "EvaluateTemplatesRuleEngine",
}

export const EXCLUDED_WORK_REQUEST_ACTIONS = ["UpdateNetworkMap"];

export type PendingWorkRequestData = Pick<
	DataLoaderResponse<WorkRequest>,
	"rowCount" | "rows" | "page" | "pageSize"
>;

import { Settings } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";

import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import React, { useState } from "react";
import { useTagPolicyStore } from "../../store";
import { TagPolicy } from "../../types";
import { PolicyAutomationDrawer } from "../policy-automation-drawer";
import { DataGridButton } from "common/atoms/data-grid-button";

const getToolTipText = (
	missingPermissions: boolean,
	isPolicyAutomationConfigurable?: boolean
) => {
	if (missingPermissions) {
		return "";
	}

	return isPolicyAutomationConfigurable
		? "configurePolicyAutomation"
		: "configurePolicyAutomationDetail";
};

export const TagPolicyAutomation = ({
	policy,
	disabled,
	children,
}: {
	policy: TagPolicy;
	disabled?: boolean;
	children?: () => React.ReactElement;
}) => {
	const [editDrawerVisibility, setEditDrawerVisibility] = useState(false);
	const requestAPIRefresh = useTagPolicyStore(state => state.requestAPIRefresh);

	const onCloseEditDrawer = (refresh: boolean) => {
		setEditDrawerVisibility(false);
		refresh && requestAPIRefresh();
	};

	const openEditDrawer = () => {
		setEditDrawerVisibility(true);
	};

	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const missingPermissions = !userPermissions.has("UPDATE_POLICY_AUTOMATION");

	const tooltipTitle = window.getCTTranslatedText(
		getToolTipText(missingPermissions, policy?.policyAutomationConfigurable)
	);

	const isDisabled =
		(disabled !== undefined
			? disabled
			: !policy.policyAutomationConfigurable) || missingPermissions;

	return (
		<>
			<Tooltip title={tooltipTitle}>
				<Stack>
					{children ? (
						<DataGridButton
							disabled={isDisabled}
							size="medium"
							variant="text"
							color="inherit"
							onClick={() => openEditDrawer()}
						>
							{children()}
						</DataGridButton>
					) : (
						<IconButton
							disabled={isDisabled}
							aria-label="Configure Automation"
							onClick={() => openEditDrawer()}
						>
							<Settings />
						</IconButton>
					)}
				</Stack>
			</Tooltip>

			{editDrawerVisibility && (
				<PolicyAutomationDrawer
					isOpen={editDrawerVisibility}
					onClose={onCloseEditDrawer}
					id={policy.tagBasedPolicyId}
					criteria={policy?.criteria}
					isZeroTrustAutomationEditable={policy?.policyAutomationConfigurable}
				/>
			)}
		</>
	);
};

import { useMutation } from "@tanstack/react-query";
import { FirewallHostGroupEditPrecedenceRequest } from "../types";

function useEditPrecedenceMutation() {
	return useMutation<any, Error, FirewallHostGroupEditPrecedenceRequest>([
		"crowdstrike-host-groups-edit-precedence",
		"edrs/crowdstrike/firewall-host-groups/precedence",
	]);
}

export default useEditPrecedenceMutation;

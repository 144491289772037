import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { Loader } from "common/atoms/loader";
import { usePrevious } from "common/hooks/usePrevious";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { TextFieldsValues } from "modules/form-fields/type";
import { useIntegrationUpdateConfigFormExtraAfter } from "pages/Integration/components/integration-detail-drawer/hooks/useIntegrationUpdateConfigFormExtraAfter";
import { SessionRefreshLimitOptions } from "pages/Integration/tabs/authentication/components/form-extras-after/constants";
import { useAuthConfigAPI } from "pages/Integration/tabs/authentication/hooks";
import { ConfiguredDataResult } from "pages/Integration/tabs/authentication/hooks/useAuthConfigAPI";
import { CONFIG_WIDGET_WIDTH } from "pages/settings/constants";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import React, { useEffect, useRef, useState } from "react";

export const SessionTimeoutConfig = React.memo(() => {
	const isMountRef = useRef(true);
	const { data: authConfigData, isLoading: isAuthConfigDataLoading } =
		useAuthConfigAPI({
			enabled: isMountRef.current,
			refetchOnWindowFocus: false,
		});

	useEffect(() => {
		isMountRef.current = false;
	}, []);

	if (isAuthConfigDataLoading) {
		return <Loader />;
	}

	return <SessionTimeoutConfigWrapper authConfigData={authConfigData} />;
});

const SessionTimeoutConfigWrapper = React.memo(
	({ authConfigData }: { authConfigData?: ConfiguredDataResult[] }) => {
		const [textFieldsValues, setTextFieldsValues] =
			useState<TextFieldsValues>();

		const userPermissions = useUserPermissionsStore(
			state => state.userPermissions
		);

		const refreshTokenTTLPreviousValue = usePrevious(
			textFieldsValues?.refreshTokenTTL ??
				authConfigData?.[0]?.config?.refreshTokenTTL
		);

		const configID = authConfigData?.[0]?.configID;

		const selectedValue =
			SessionRefreshLimitOptions.find(
				option => option.value === textFieldsValues?.refreshTokenTTL
			)?.displayName ??
			SessionRefreshLimitOptions[SessionRefreshLimitOptions.length - 1]
				.displayName;

		const { isLoading, updateSamlOrOauthIntegrationConfig } =
			useIntegrationUpdateConfigFormExtraAfter(configID);

		const callUpdateConfig = () => {
			if (textFieldsValues) {
				updateSamlOrOauthIntegrationConfig(textFieldsValues);
			}
		};

		useEffect(() => {
			if (configID) {
				setTextFieldsValues(authConfigData?.[0]?.config);
			}
		}, [configID, setTextFieldsValues, authConfigData]);

		return (
			<Paper
				elevation={1}
				sx={{
					width: CONFIG_WIDGET_WIDTH,
					height: "auto",
					borderRadius: 1,
					overflow: "hidden",
				}}
			>
				<Typography variant="subtitle1" px={3} pt={2}>
					{window.getCTTranslatedText("SessionIdleTimeoutConfiguration")}
				</Typography>

				<Divider sx={{ my: 1.5 }} />

				<Stack px={3} pb={2}>
					<CTSelectDropDown
						field={"refreshTokenTTL"}
						displayName={window.getCTTranslatedText("SessionIdleTimeout")}
						selectedValue={selectedValue}
						key={selectedValue}
						handleUpdate={(event: TextFieldUpdate) => {
							const refreshTokenValue = SessionRefreshLimitOptions.find(
								option => option.displayName === event?.value
							)?.value;

							setTextFieldsValues(prev => ({
								...prev,
								refreshTokenTTL: refreshTokenValue,
								refreshTokenRollingEnabled: true,
								accessTokenTTL: refreshTokenValue === 15 ? 10 : 15,
							}));
						}}
						data={SessionRefreshLimitOptions.map(option => option.displayName)}
						textTransform="none"
						showLabel={true}
					/>
				</Stack>

				<Stack direction={"row"} justifyContent={"flex-end"} px={3} pb={3}>
					<LoadingButton
						loading={isLoading}
						disabled={
							!textFieldsValues?.refreshTokenTTL ||
							refreshTokenTTLPreviousValue ===
								textFieldsValues?.refreshTokenTTL ||
							!userPermissions.has("UPDATE_INTEGRATION")
						}
						color="primary"
						variant="contained"
						onClick={callUpdateConfig}
					>
						{window.getCTTranslatedText("Save")}
					</LoadingButton>
				</Stack>
			</Paper>
		);
	}
);

import { useMutation } from "@tanstack/react-query";
import { getRelativeLastObserved } from "common/utils";
import { useDataLoader } from "modules/core";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { NamedNetwork, Path } from "pages/paths/types";
import { PATH_USERGROUP_COLS } from "./data-grid-constants";
import { useUsergroupStore } from "./store";
import { Usergroup } from "./types";
import { Scope } from "modules/scope-metadata/types";

export function useUsergroupsAPI() {
	return useMutation<any, Error, any>([
		"usergrouphits",
		"usergroups/hits?computeTotal=true",
	]);
}

interface PathUserGroupsDataGridProps {
	path: Path;
	userGroupType: string;
	usergrouphitusergroupid: string | NamedNetwork | string[];
}

export function PathUserGroupsDataGrid({
	path,
	userGroupType,
	usergrouphitusergroupid,
}: PathUserGroupsDataGridProps) {
	let addCriteria = "";
	const assetId =
		userGroupType === "source"
			? path.destinationAsset?.assetId
			: path.sourceAsset?.assetId;
	const namedNetwork =
		userGroupType === "source"
			? path.destinationNamedNetwork?.namedNetworkId
			: path.sourceNamedNetwork?.namedNetworkId;
	const ip = userGroupType === "source" ? path.dstIp : path.srcIp;

	if (assetId) {
		addCriteria = `usergrouphitpeerassetid='${assetId}'`;
	} else if (namedNetwork) {
		addCriteria = `usergrouphitpeernamednetworkid='${namedNetwork}'`;
	} else if (path.domain) {
		addCriteria = `usergrouphitpeerdomain='${path.domain}'`;
	} else if (ip.length) {
		addCriteria = `usergrouphitpeerips='${ip}'`;
	}
	const coreResponse = useDataLoader<Usergroup>({
		useStore: useUsergroupStore,
		dataMapper(item) {
			item.lastHitObserved = getRelativeLastObserved(item.lastHitObserved);
			item.id = `${item.userName}-${item.userGroup.assetId}`;
			return item;
		},
		useApi: useUsergroupsAPI,
		pageSize: 100,
		criteria: `usergrouphitusergroupid='${usergrouphitusergroupid}' and ${addCriteria} and (usergrouphitmatchport=${path.port}) and usergrouphitmatchprotocol='${path.protocol}' and usergrouphitmatchdirection=${path.direction}`,
		scope: Scope.UserGroupHits,
	});

	return (
		<DataGrid<Usergroup>
			rowHeight={64}
			columns={PATH_USERGROUP_COLS}
			pagination
			getRowId={({ id }) => id}
			paginationMode="server"
			sortingMode="server"
			rowCount={coreResponse.rowCount}
			rows={coreResponse.rows ?? []}
			mutation={coreResponse.mutation}
			onPageChange={coreResponse.onPageChange}
			page={coreResponse.page}
			pageSize={coreResponse.pageSize}
			onPageSizeChange={coreResponse.onPageSizeChange}
		/>
	);
}

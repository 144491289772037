import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useCommonStore } from "common/store";

import { getStatus } from "common/utils/statusUtils";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { StatusText } from "pages/ports/constants";
import {
	PortColumnRenderProps,
	PortStatus as PortStatusOptions,
} from "pages/ports/types";

export function PortStatusEnforced({
	port,
	viewOnly,
	useFacetStore = useCommonStore,
}: PortColumnRenderProps) {
	const facetState = useFacetStore(state => state.facets);

	const isSelected =
		!viewOnly &&
		facetState?.get("listenportenforced")?.get(port.listenPortEnforced)
			?.isSelected;

	if (!port.lpId) {
		return <Box style={{ width: 160, minHeight: 45 }} />;
	}

	const status =
		StatusText[port?.listenPortEnforced || PortStatusOptions.Unreviewed];
	const statusDetails = getStatus(status);

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
			<Tooltip title={window.getCTTranslatedText(statusDetails?.status ?? "")}>
				<span>
					<CTDatagridButton
						asText={viewOnly === true}
						isDisabled={viewOnly === true}
						useFacetStore={useFacetStore}
						facetName={"listenportenforced"}
						facetOption={port.listenPortEnforced}
					>
						{window.getCTTranslatedText(statusDetails?.status)}
					</CTDatagridButton>
				</span>
			</Tooltip>

			{statusDetails?.description && (
				<Typography
					sx={{ pl: 1 }}
					variant="caption"
					color={isSelected ? "primary" : "inherit"}
				>
					{window.getCTTranslatedText(statusDetails?.description)}
				</Typography>
			)}
		</Stack>
	);
}

import { useMutation } from "@tanstack/react-query";
import { Scope } from "modules/scope-metadata/types";

export interface SummarizeReqParams {
	criteria: string;
	facetField: string;
	facetFieldFilter: string;
	scope: Scope;
}

interface SummarizedFacetData {
	field: string;
	hasMore: boolean;
	values: { [key: string]: number };
}

export interface SummarizeResponse {
	Facet: SummarizedFacetData;
}

export function useSummarizeAPI(fieldName: string) {
	return useMutation<SummarizeResponse, Error, SummarizeReqParams>([
		`summarize_${fieldName}`,
		"fields/actions/summarize",
	]);
}

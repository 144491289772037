import { create } from "zustand";
import { SnackBarSeverity, SnackbarInterface } from "./types";

export const useSnackbarStore = create<SnackbarInterface>()(set => ({
	snackbarOpen: false,
	snackbarType: SnackBarSeverity.Success,
	snackbarMessage: "",
	snackbarVariables: {},
	setSnackbar: (
		snackbarOpen: boolean,
		snackbarType = SnackBarSeverity.Success,
		snackbarMessage = "",
		snackbarVariables?: { [key: string]: string | number }
	) => {
		set({
			snackbarOpen: snackbarOpen,
			snackbarType: snackbarType,
			snackbarMessage: window.getCTTranslatedText(
				snackbarMessage,
				snackbarVariables
			),
		});
	},
}));

import { Box, Chip, Stack, Typography } from "@mui/material";
import { getRelativeLastObserved } from "common/utils";
import { AnnotationsListResult } from "modules/annotation-drawer/type";

interface AnnotationsListProps {
	annotationsList?: AnnotationsListResult;
}

export const AnnotationsList = ({ annotationsList }: AnnotationsListProps) => {
	return (
		<Stack sx={{ flex: "1 1 0", overflow: "auto", py: 2 }}>
			<Box sx={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}>
				{annotationsList?.items?.map(annotation => (
					<Stack key={annotation.commentId} sx={{ mb: 4.5 }} spacing={2}>
						<Stack
							direction="row"
							justifyContent={"space-between"}
							alignItems="flex-end"
							ml={0}
							spacing={2}
						>
							<Typography
								variant="body1"
								sx={{
									textDecoration: "underline",
									color: theme => theme.palette.text.secondary,
								}}
							>
								{annotation.author}
							</Typography>
							<Typography
								variant="body2"
								ml={2}
								sx={{
									color: theme => theme.palette.text.secondary,
								}}
							>
								{getRelativeLastObserved(annotation.createdAt || "")}
							</Typography>
						</Stack>

						<Chip
							label={annotation.comment}
							size="medium"
							sx={{
								width: "fit-content",
								color: theme => theme.palette.text.secondary,
								fontSize: "14px",
								background: theme =>
									theme.palette.mode === "light"
										? theme.palette.background.default
										: "",
								border: "1px solid rgba(0, 0, 0, 0.05)",
							}}
						/>
					</Stack>
				))}
			</Box>
		</Stack>
	);
};

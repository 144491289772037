import * as isCidr from "is-cidr";
import { isIP } from "is-ip";

export const isValidIP = (inputIpValue?: string): boolean => {
	if (!inputIpValue) {
		return false;
	}
	let input: string = inputIpValue.trim();
	if (input.startsWith("0.0.0.0") || input.startsWith("255.255.255.255"))
		return false;
	const RX_IPV4_OCTET = "(?:[0-1]?\\d{1,2}|2[0-4]\\d|25[0-5])";
	const RX_IPV4_CIDR = "(/[0-9]|/[1-2][0-9]|/3[0-2])";
	const RX_IPv4 = `(?:${RX_IPV4_OCTET}(?:\\.)){3}(?:${RX_IPV4_OCTET}(?:${RX_IPV4_CIDR}|$))`;
	const RX_IPv6 =
		"(?:(?:[\\dA-Fa-f]{1,4}(?::|$)){8}" +
		"|(?=(?:[^:\\s]|:[^:\\s])*::(?:[^:\\s]|:[^:\\s])*$)" +
		"[\\dA-Fa-f]{0,4}(?:::?(?:[\\dA-Fa-f]{1,4}|$)){1,6})";
	const RX_any = "(?:any)";
	const IP = new RegExp(
		"^(?:" + RX_IPv4 + "|" + RX_IPv6 + "|" + RX_any + ")$",
		"i"
	);
	if (input && !IP.test(input)) {
		return false;
	} else {
		return true;
	}
};

export const isValidIp = (value?: string) => {
	if (!value) {
		return false;
	}
	return isIP(value);
};

export const isValidSubnet = (value?: string) => {
	if (!value) {
		return false;
	}
	return isCidr.v4(value) || isCidr.v6(value);
};

export function ipToLong(ipAddress: string) {
	// Split the IP address into its octets
	const octets = ipAddress.split(".").map(Number);

	// Convert each octet to its corresponding position in a 32-bit integer
	const longValue =
		(octets[0] << 24) + (octets[1] << 16) + (octets[2] << 8) + octets[3];

	// Use >>> 0 to ensure the result is treated as an unsigned 32-bit integer
	return longValue >>> 0;
}

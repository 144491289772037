import {
	// Alert,
	Stack,
} from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { usePortStore } from "pages/ports/store";
import { PortStoreType } from "pages/ports/store/types";
import { PortStatus } from "pages/ports/types";
// import { useEffect, useState } from "react";

// import { StatusOptionsText as PortStatusOptionText } from "pages/ports/constants";
import { PortStatusDropdown } from "../port-status-dropdown";
// import { StatusText } from "pages/ports/constants";

interface PortStatusToolbarProps {
	updatePortstatus: ((status: PortStatus | undefined) => void) | undefined;
	useStore?: PortStoreType;
	preSelectedPortStatus?: PortStatus | undefined;
}

export const PortStatusToolbar = ({
	updatePortstatus,
	useStore = usePortStore,
	preSelectedPortStatus,
}: PortStatusToolbarProps) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const onSelectPortStatus = (status: PortStatus) => {
		if (updatePortstatus) {
			updatePortstatus(status);
		}
	};

	return (
		<>
			<Stack direction={"row"} alignItems="flex-end" sx={{ width: "100%" }}>
				<Stack alignItems="flex-end">
					{userPermissions.has("UPDATE_PORT") && (
						<PortStatusDropdown
							onSelect={onSelectPortStatus}
							preSelectedPortStatus={preSelectedPortStatus}
							useStore={useStore}
						></PortStatusDropdown>
					)}
				</Stack>
			</Stack>
			{/* {selectedPortStatus && (
        <Stack sx={{ mb: 2.5, width: "100%" }}>
          <Alert sx={{ width: "100%" }} severity={"info"}>
            {`You are changing port status to ${PortStatusOptionText[selectedPortStatus]}. Changes will take effect after you click on confirm`}
          </Alert>
        </Stack>
      )} */}
		</>
	);
};

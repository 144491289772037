import { Box, Stack, Tooltip } from "@mui/material";
import { DataGridButton } from "common/atoms/data-grid-button";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { VisxAttributesBlock } from "modules/attributes-block";
import numeral from "numeral";
import { useState } from "react";
import { TagPolicy } from "../../types";
import { AssignedTemplatesTags } from "../tag-templates/components/assigned-templates-tags";

export function TemplateAssignments({
	policy,
	viewOnly = false,
	asCard = false,
	onUpdate,
}: {
	policy?: TagPolicy;
	viewOnly?: boolean;
	asCard?: boolean;
	onUpdate?: VoidFunction;
}) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const [showAssignedTemplates, setShowAssignedTemplates] =
		useState<boolean>(false);

	const [showAssignNew, setShowAssignDrawer] = useState(false);

	if (!asCard && !policy?.tagBasedPolicyId) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	const open = () => {
		setShowAssignedTemplates(true);
	};

	const onClose = () => {
		setShowAssignedTemplates(false);
	};
	if (asCard) {
		return (
			<>
				<VisxAttributesBlock
					isLoading={false}
					title="Templates"
					count={policy?.templatesAssigned ?? 0}
					canAssign={
						userPermissions.has("UPDATE_ASSET") &&
						userPermissions.has("UPDATE_TEMPLATE")
					}
					showAssigned={() => setShowAssignedTemplates(true)}
					assign={() => {
						setShowAssignDrawer(true);
					}}
				/>

				{showAssignNew && (
					<AddToTemplateDrawer
						isOpen={showAssignNew}
						page={"tags"}
						title="Assign Template"
						rules={[]}
						onCancel={() => {
							setShowAssignDrawer(false);
						}}
						onConfirm={() => {
							setShowAssignDrawer(false);
							onUpdate?.();
						}}
						btnTitle={"assign"}
						criteria={policy?.criteria ?? ""}
						multiselect={true}
						policyId={policy?.tagBasedPolicyId}
						hasPermission={userPermissions.has("UPDATE_TAGBASEDPOLICY")}
						actionType={TemplateActionType.assign}
					/>
				)}

				{showAssignedTemplates && (
					<AssignedTemplatesTags
						criteria={policy?.criteria}
						multiselect={true}
						policyId={policy?.tagBasedPolicyId}
						open={showAssignedTemplates}
						onClose={onClose}
					/>
				)}
			</>
		);
	}

	return (
		<>
			<Stack
				sx={{ maxWidth: "100%" }}
				alignItems="left"
				justifyContent="center"
			>
				<Tooltip title={window.getCTTranslatedText("View Assigned Templates")}>
					<DataGridButton
						asText={viewOnly === true}
						disabled={viewOnly === true}
						variant={"text"}
						color={"primary"}
						onClick={open}
					>
						{numeral(policy?.templatesAssigned ?? 0).format("0,0")}
					</DataGridButton>
				</Tooltip>
			</Stack>
			{showAssignedTemplates && (
				<AssignedTemplatesTags
					criteria={policy?.criteria}
					multiselect={true}
					policyId={policy?.tagBasedPolicyId}
					open={showAssignedTemplates}
					onClose={onClose}
				/>
			)}
		</>
	);
}

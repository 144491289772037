import { Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { Scope } from "modules/scope-metadata/types";
import { FACET_OPTION_MAPPING } from "../users/constants";
import { UserGroupDataGrid } from "./components/user-group-data-grid";
import { USERGROUP_FACET_ORDER } from "./constants";
import { useUserGroupsAPI } from "./hooks";
import { useUserGroupStore } from "./store";
import { UserGroup } from "./types";

export const UserGroups = () => {
	const theme = useTheme();

	const coreResponse = useCore<UserGroup>({
		useStore: useUserGroupStore,
		facetGroupInfo: USERGROUP_FACET_ORDER,
		scope: Scope.SCIM_GROUP,
		facetOptionsDisplayMapping: FACET_OPTION_MAPPING,
		useApi: useUserGroupsAPI,
		pageSize: 100,
	});

	return (
		<Stack
			direction={"column"}
			spacing={2}
			sx={{ height: "100%", width: "100%" }}
		>
			<Stack direction={"row"} alignItems="center">
				<Stack sx={{ flex: 1 }}>
					<FacetOpenCloseController
						facetsOpen={coreResponse.facetsOpen}
						setFacetsOpen={coreResponse.setFacetsOpen}
					>
						<FacetControllerGroup
							config={coreResponse?.facetConfig}
							value={coreResponse.facetState}
							onChange={coreResponse.updateFacet}
						/>
					</FacetOpenCloseController>
				</Stack>
			</Stack>

			<Paper
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					flex: 1,
					overflow: "hidden",
				}}
			>
				<UserGroupDataGrid
					rows={coreResponse.rows}
					rowCount={coreResponse.rowCount}
					metadata={coreResponse.metadata}
					mutation={coreResponse.mutation}
					onPageChange={coreResponse.onPageChange}
					page={coreResponse.page}
					pageSize={coreResponse.pageSize}
					onPageSizeChange={coreResponse.onPageSizeChange}
					onSortChange={coreResponse.onSortChange}
					triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
					getExportStatus={coreResponse?.getExportStatus}
					getUrlToDownload={coreResponse?.getUrlToDownload}
					resetDownloadUrl={coreResponse?.resetDownloadUrl}
					rawData={coreResponse?.rawData}
				/>
			</Paper>
		</Stack>
	);
};

import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useCommonStore } from "common/store";
import { getStatus } from "common/utils/statusUtils";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { StatusText } from "pages/ports/constants";

import {
	PortColumnRenderProps,
	PortCategory as PortCategoryOptions,
} from "pages/ports/types";

export function PortCategory({
	port,
	viewOnly,
	useFacetStore = useCommonStore,
}: PortColumnRenderProps) {
	const facetState = useFacetStore(state => state.facets);

	const isSelected =
		!viewOnly &&
		facetState?.get("portcategory")?.get(port.listenPortCategoryName)
			?.isSelected;

	if (!port.lpId) {
		return <Box />;
	}

	const status =
		StatusText[
			port?.listenPortCategoryName || PortCategoryOptions.ApplicationSystem
		];

	const statusDetails = getStatus(status);

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
			<Tooltip
				title={
					viewOnly
						? ""
						: `${window.getCTTranslatedText(
								"Show only for"
							)} ${window.getCTTranslatedText(statusDetails?.status ?? "")}`
				}
			>
				<span>
					<CTDatagridButton
						asText={viewOnly === true}
						isDisabled={viewOnly === true}
						useFacetStore={useFacetStore}
						facetName={"portcategory"}
						facetOption={port.listenPortCategoryName}
					>
						{window.getCTTranslatedText(statusDetails?.status)}
					</CTDatagridButton>
				</span>
			</Tooltip>
			{statusDetails?.description && (
				<Typography
					variant="caption"
					color={isSelected ? "primary" : "inherit"}
					sx={{ pl: 1 }}
				>
					{window.getCTTranslatedText(statusDetails?.description)}
				</Typography>
			)}
		</Stack>
	);
}

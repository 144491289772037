import { ExpandCircleDownOutlined } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { CTDrawer } from "common/molecules/drawer";
import { NamedNetwork, Path } from "pages/paths/types";
import { useState } from "react";
import { PathUserGroupsDataGrid } from "./PathUserGroupDataGrid";

interface PathUserGroupsProps {
	title: string;
	path: Path;
	userGroupType: string;
	usergrouphitusergroupid: string | NamedNetwork | string[];
}

export function PathUserGroup({
	title,
	path,
	userGroupType,
	usergrouphitusergroupid,
}: PathUserGroupsProps) {
	const [showDetails, setShowDetails] = useState(false);

	return (
		<>
			<Tooltip title={"Show usergroup hits"}>
				<IconButton
					sx={{
						"& svg": {
							transform: "rotate(218deg)",
						},
					}}
					aria-label="Show usergroups hits"
					onClick={() => {
						setShowDetails(true);
					}}
				>
					<ExpandCircleDownOutlined
						sx={{ opacity: 0.6 }}
						fontSize="small"
						color="info"
					/>
				</IconButton>
			</Tooltip>

			<CTDrawer
				title={title}
				open={showDetails}
				onClose={() => {
					setShowDetails(false);
				}}
			>
				<Stack sx={{ width: "100%", height: "100%" }}>
					{showDetails && (
						<PathUserGroupsDataGrid
							path={path}
							userGroupType={userGroupType}
							usergrouphitusergroupid={usergrouphitusergroupid}
						/>
					)}
				</Stack>
			</CTDrawer>
		</>
	);
}

import { Link, Stack, Tooltip } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

export function AssetCount(props: any) {
	return (
		<Stack
			sx={{ maxWidth: "100%" }}
			alignItems="center"
			justifyContent="center"
		>
			<Stack justifyContent="center" alignItems="center">
				<Tooltip title={window.getCTTranslatedText("View Assets")}>
					<Link
						typography={"body2"}
						component={RouterLink}
						to={`/assets?filters=${encodeURIComponent(
							`${props?.tag.key}=${props?.tag.value}`
						)}`}
						sx={{ color: "primary.main" }}
					>
						{`${props?.tag.count} ${window.getCTTranslatedText("assets")}`}
					</Link>
				</Tooltip>
			</Stack>
		</Stack>
	);
}

import {
	Checkbox,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	DialogContentText,
	Stack,
	Typography,
} from "@mui/material";
import EventEmitter from "events";
import { acceptComplianceCheck } from "hooks/useComplianceController/helper";
import { useEffect, useRef, useState } from "react";

const name = "M. D. Anderson";
const owner =
	"State of Texas and The University of Texas M. D. Anderson Cancer Center";
const achronym = "UTMDACC";
const contact = "713-745-9000";

const notificationText = `^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
LEGAL NOTICE
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
UNAUTHORIZED USE OF THIS COMPUTER SYSTEM IS PROHIBITED
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

This computer system is the property of the v(o) (v(a)). It may be used only as authorized by v(a). Individuals accessing or using this computer system have no expectation of privacy with respect to such access or usage except as may otherwise be provided by applicable privacy laws. Pursuant to Texas Administrative Code Section 202.7, usage is subject to security testing and monitoring as appropriate, and misuse is subject to criminal prosecution. Report any suspected unauthorized activity to the Information Security Department at v(c).
PREPARATORY TO RESEARCH ATTESTATION. To the extent this system is used to access protected health information (PHI) for preparatory to research purposes (e.g., development of research questions, determination of study feasibility, preparing a research protocol, or identifying prospective research participants), the user attests that the user is seeking to use PHI for preparatory to research purposes, no PHI will be removed from v(n), and it is necessary to access PHI (as opposed to de-identified data) for those preparatory to research purposes.

^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
`;

const complianceCheckEventEmitter = new EventEmitter();

export function SystemUseNotification({
	currentTenantID,
	acceptCallback,
	cancelCallback,
}: {
	currentTenantID: string | undefined;
	acceptCallback?: Function;
	cancelCallback?: Function;
}) {
	const [open, setOpen] = useState(true);
	const [agreement, setAgreement] = useState(false);

	const handleSetAgreement = () => {
		setAgreement(!agreement);
	};

	const handleClose = (
		event?: {},
		reason?: "backdropClick" | "escapeKeyDown"
	) => {
		if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
			setOpen(false);
		}
	};

	const descriptionElementRef = useRef<HTMLElement>(null);

	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	useEffect(() => {
		const listener = () => {
			window.location.href = "/";
		};
		complianceCheckEventEmitter.addListener("change", listener);
		return () => {
			complianceCheckEventEmitter.removeListener("change", listener);
		};
	}, []);

	const acceptComplianceAgreement = () => {
		if (currentTenantID) {
			acceptComplianceCheck(currentTenantID);
			handleClose();
			if (acceptCallback) {
				acceptCallback();
			} else {
				complianceCheckEventEmitter.emit("change", false);
			}
		}
	};

	const cancelComplianceAgreement = () => {
		if (cancelCallback) {
			cancelCallback();
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll={"paper"}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				maxWidth="lg"
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="scroll-dialog-title">Terms of Service</DialogTitle>
				<DialogContent dividers={true} sx={{ width: "1000px" }}>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
						sx={{ whiteSpace: "pre-wrap", py: 4 }}
					>
						{processText(notificationText)}
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						alignItems: "center",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={4}
						sx={{ px: 2, py: 1, width: "100%" }}
					>
						<Stack
							justifyItems="flex-start"
							alignItems={"center"}
							justifyContent="flex-start"
							direction="row"
							spacing={1}
						>
							<Checkbox
								sx={{ p: 0, mr: 2 }}
								required={true}
								checked={agreement}
								onChange={handleSetAgreement}
							/>
							<Typography
								onClick={handleSetAgreement}
								sx={{ cursor: "pointer", userSelect: "none" }}
							>
								I accept and acknowledge the Terms of Service
							</Typography>
						</Stack>
						<Stack
							justifyItems="flex-end"
							alignItems={"flex-end"}
							justifyContent="flex-end"
							direction={"row"}
							spacing={4}
						>
							<Button
								variant="outlined"
								color={"secondary"}
								onClick={() => cancelComplianceAgreement()}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								disabled={!agreement}
								onClick={() => acceptComplianceAgreement()}
							>
								Accept
							</Button>
						</Stack>
					</Stack>
				</DialogActions>
			</Dialog>
		</>
	);
}

function processText(text: string) {
	let str = text;

	str = str.replaceAll("v(n)", name);
	str = str.replaceAll("v(o)", owner);
	str = str.replaceAll("v(a)", achronym);
	str = str.replaceAll("v(c)", contact);

	return str;
}

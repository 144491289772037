import {
	UserInfoType,
	useKnowledgeBaseAPI,
} from "modules/analytics/KnowledgeBase";
import { useEffect, useState } from "react";

const KnowledgeBaseURL = "https://docs.ng.colortokens.com";

const checkIfUrlIsKnowledgeBaseUrl = (url: string) => {
	return url.includes(KnowledgeBaseURL);
};

export const useKnowledgeBaseUrl = (urls: string[]) => {
	const [isKnowledgeBaseUrl, setIsKnowledgeBaseUrl] = useState(false);
	const { data } = useKnowledgeBaseAPI(isKnowledgeBaseUrl);
	const [userInfo, setUserInfo] = useState<UserInfoType | null>(null);

	useEffect(() => {
		if (urls?.some(checkIfUrlIsKnowledgeBaseUrl)) {
			setIsKnowledgeBaseUrl(true);
		}
	}, [urls]);

	useEffect(() => {
		if (data?.username && data?.hmacHash) {
			setUserInfo({
				username: data.username,
				hmac: {
					hash: data.hmacHash,
					expiration: data.expirationFinalDate,
				},
			});
		}
	}, [data]);

	const getUpdatedUrl = (url: string) => {
		if (checkIfUrlIsKnowledgeBaseUrl(url)) {
			return `${url}?hmac=${encodeURIComponent(JSON.stringify(userInfo))}`;
		}

		return url;
	};

	return {
		getUpdatedUrl,
		userInfo,
	};
};

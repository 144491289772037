import { CircularProgress, Grid } from "@mui/material";

export function AssetLoader({ asset }: any) {
	return (
		<Grid
			container
			spacing={{ md: 4, lg: 3 }}
			sx={{
				height: "100%",
				width: "100%",
				justifyContent: "center",
				alignItems: "center",
				textAlign: "center",
			}}
		>
			<Grid item md={12} lg={12}>
				<CircularProgress />
			</Grid>
		</Grid>
	);
}

import { GridColDef } from "@mui/x-data-grid-pro";
import { UserAssetCount } from "../user-asset";
import { UserEmail } from "../user-email";
import { UserGroupCount } from "../user-group-count";
import { UserName } from "../user-name";
import { UserSourceIDP } from "../user-source-idp";
import { UserStatus } from "../user-status";

export const BASE_USERS_COLUMNS: GridColDef[] = [
	{
		field: "displayName",
		headerName: "User Name",
		width: 140,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
		sortable: true,
		renderCell: params => {
			return <UserName user={params.row} />;
		},
	},
	{
		field: "userEmail",
		headerName: "UPN",
		width: 140,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
		sortable: true,
		renderCell: params => {
			return <UserEmail user={params.row} />;
		},
	},
	{
		field: "idpName",
		headerName: "IdP",
		width: 140,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
		sortable: true,
		renderCell: params => {
			return <UserSourceIDP user={params.row} />;
		},
	},
	{
		field: "userAssets",
		headerName: "Endpoints",
		width: 140,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
		sortable: true,
		renderCell: params => {
			return <UserAssetCount user={params.row} />;
		},
	},
	{
		field: "active",
		headerName: "User Status",
		width: 140,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
		sortable: true,
		renderCell: params => {
			return <UserStatus user={params.row} />;
		},
	},
	{
		field: "userGroups",
		headerName: "User Groups",
		width: 140,
		flex: 0.4,
		sortable: true,
		renderCell: params => {
			return <UserGroupCount user={params.row} />;
		},
	},
];

import { List, Paper, Skeleton, Stack } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { MAX_CUSTOM_TAGS_COUNT } from "pages/tags/constants";
import { TagKeyInt } from "pages/tags/types";
import React from "react";
import { CreateTag } from "../create-tag";
import { TagItem } from "../tag-item";
import { useTagList } from "./hooks";

export const TagList = () => {
	const SKELETON_LOADER_COUNT = 10;
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const { isLoading, tagList, createdCustomTagsCount } = useTagList();

	if (isLoading || !tagList) {
		return (
			<Stack spacing={2}>
				{[...Array(SKELETON_LOADER_COUNT)].map((_, index) => (
					<Paper key={`tagListSkeleton-${index}`}>
						<Skeleton
							variant="rounded"
							width="100%"
							height={80}
							sx={{
								flexShrink: 0,
							}}
						/>
					</Paper>
				))}
			</Stack>
		);
	}

	return (
		<>
			<Stack
				sx={{
					width: "100%",
					height: "100%",
				}}
				spacing={2}
			>
				{userPermissions.has("CREATE_FIELD") &&
					createdCustomTagsCount < MAX_CUSTOM_TAGS_COUNT && <CreateTag />}
				<List dense={false} disablePadding>
					{(tagList ?? [])?.map((tag: TagKeyInt) => {
						return (
							<React.Fragment key={`${tag.key}`}>
								<TagItem tag={tag} />
							</React.Fragment>
						);
					})}
				</List>
			</Stack>
		</>
	);
};

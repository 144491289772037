import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { useKnowledgeBaseUrl } from "common/hooks/useKnowledgeBaseUrl";
import { Field, StaticData } from "modules/form-fields/type";
import {
	INTEGRATIONS,
	IntegrationConfigType,
	getIntegrationEnum,
} from "pages/Integration/constants";
import { CopyInstructions } from "pages/agents/components/agent-download-page";
import {
	TerminalParagraph,
	TerminalScreen,
} from "pages/agents/components/agent-download-page/Terminal";

interface StaticDataProps {
	field: Field;
	integration: IntegrationConfigType;
	showHeading?: boolean;
}

const rulesTitleWrapper = {
	py: 0,
	px: 0,
	borderRadius: 1,
};

export const IntegrationStaticData = ({
	field,
	integration,
	showHeading = true,
}: StaticDataProps) => {
	return (
		<Grid sx={{ gridColumnStart: 1, gridColumnEnd: 3, mt: 4 }}>
			<Box style={{ minWidth: "100%" }} sx={{ mb: 4 }}>
				<Paper sx={{ minWidth: "100%" }} elevation={2}>
					<Stack
						alignItems="flex-start"
						justifyContent="flex-start"
						spacing={0}
					>
						<Stack
							alignItems={"flex-start"}
							justifyContent="flex-start"
							spacing={0}
							style={{ position: "relative", height: "100%" }}
							sx={{
								...rulesTitleWrapper,
								width: "100%",
								background: theme =>
									theme.palette.mode === "dark"
										? theme.palette.grey["A700"]
										: theme.palette.grey[50],
							}}
						>
							{showHeading && (
								<Stack
									direction="row"
									alignItems="center"
									justifyContent={"space-between"}
									sx={{
										width: "100%",
									}}
								>
									<Stack
										alignItems="center"
										sx={{
											my: 3,
											px: 3,
										}}
									>
										<Typography variant="body1">
											{window.getCTTranslatedText(field.displayName)}
										</Typography>
									</Stack>
								</Stack>
							)}
							{field.staticData?.map((item, index) => {
								const showSteps =
									getIntegrationEnum(integration) !==
										INTEGRATIONS.OTHERS_SIEM && !item?.heading;

								return (
									<Stack
										key={`staticData-${index}`}
										sx={{
											width: "100%",
											px: 3,
											py: 1,
											borderRadius: "none !important",
										}}
										component={Paper}
									>
										<Typography variant="body2" sx={{ mx: 0, mt: 2 }}>
											{showSteps
												? window.getCTTranslatedText("stepCount", {
														count: index + 1,
													})
												: item.heading}
										</Typography>

										{item?.subHeading && (
											<Typography variant="body1" sx={{ mx: 0, my: 1 }}>
												{window.getCTTranslatedText(item.subHeading)}
											</Typography>
										)}
										{item.content && getContentData(item)}
										{item.clipboardText && getClipboardData(item)}
										{item.linkText && item.linkUrl && (
											<ConfigureData item={item} />
										)}
									</Stack>
								);
							})}
						</Stack>
					</Stack>
				</Paper>
			</Box>
		</Grid>
	);
};

const getContentData = (item: StaticData) => {
	return item.content?.map((content: string, index: number) => (
		<Typography key={`${content}-key`} variant="body2" sx={{ mx: 0, mb: 1 }}>
			{window.getCTTranslatedText(content ?? "")}
		</Typography>
	));
};

const ConfigureData = ({ item }: { item: StaticData }) => {
	const { getUpdatedUrl } = useKnowledgeBaseUrl(item.linkUrl ?? []);

	return (item.linkText?.length ?? 0) > 1 ? (
		<ul>
			{item?.linkText?.map((link: string, index: number) => {
				return (
					<li key={link}>
						<Typography
							key={link}
							component="a"
							href={getUpdatedUrl(item?.linkUrl?.[index] ?? "")}
							target="_blank"
							variant="body2"
							sx={{ color: "primary.main" }}
						>
							{window.getCTTranslatedText(item?.linkText?.[index] ?? "")}
						</Typography>
					</li>
				);
			})}
		</ul>
	) : (
		<>
			{item?.linkText?.map((link: string, index: number) => {
				return (
					<Typography
						key={link}
						component="a"
						href={getUpdatedUrl(item?.linkUrl?.[index] ?? "")}
						target="_blank"
						variant="body2"
						sx={{ color: "primary.main" }}
					>
						{window.getCTTranslatedText(item?.linkText?.[index] ?? "")}
					</Typography>
				);
			})}
		</>
	);
};

const getClipboardData = (item: StaticData) => {
	return (
		<TerminalScreen sx={{ mb: 1 }}>
			<TerminalParagraph sx={{ display: "flex", alignItems: "center" }}>
				<Stack sx={{ flex: 1 }}>
					{window.getCTTranslatedText(item.clipboardText ?? "")}
				</Stack>
				<Stack direction="row" justifyContent="flex-end">
					<CopyInstructions code={item.clipboardText} />
				</Stack>
			</TerminalParagraph>
		</TerminalScreen>
	);
};

import { IconsProps } from "./common";

export const HelmIcon = ({
	width = "32px",
	height = "32px",
	color = "#000",
}: IconsProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill="none"
			viewBox="0 0 32 32"
		>
			<g clipPath="url(#clip0_6085_45450)">
				<path
					fill={color}
					fillRule="evenodd"
					d="M2.063 12.581h1.822v2.442h2.202v-2.442h1.822v6.86H6.087v-2.616H3.885v2.617H2.063v-6.86zM10 19.441v-6.86h4.31v1.491h-2.488v1.116h2.202v1.518h-2.202v1.263h2.488v1.473H10zm6.308 0v-6.86h1.823v5.05h2.498v1.81h-4.32zm7.142-6.86l2.83 2.543 2.822-2.543h.823v6.86h-1.831V15.92l-1.813 1.637-1.823-1.628v3.513h-1.831v-6.86h.823z"
					clipRule="evenodd"
				></path>
				<mask
					id="mask0_6085_45450"
					style={{ maskType: "luminance" }}
					width="29"
					height="15"
					x="2"
					y="22"
					maskUnits="userSpaceOnUse"
				>
					<path
						fill="#fff"
						fillRule="evenodd"
						d="M2.088 36.832h28.857V22.257H2.088v14.575z"
						clipRule="evenodd"
					></path>
				</mask>
				<g
					fill={color}
					fillRule="evenodd"
					clipRule="evenodd"
					mask="url(#mask0_6085_45450)"
				>
					<path d="M22.688 23.76c.523-.363 1.73.45 2.695 1.814.965 1.364 1.323 2.764.8 3.127-.524.362-1.73-.45-2.696-1.814-.965-1.364-1.323-2.764-.8-3.127zM10.219 23.76c-.523-.363-1.73.45-2.695 1.814-.965 1.364-1.323 2.764-.8 3.127.523.362 1.73-.45 2.695-1.814.965-1.364 1.323-2.764.8-3.127zM16.447 25.896c-.64 0-1.157 1.35-1.157 3.016s.518 3.016 1.157 3.016 1.157-1.35 1.157-3.016c0-1.665-.518-3.015-1.157-3.015z"></path>
				</g>
				<mask
					id="mask1_6085_45450"
					style={{ maskType: "luminance" }}
					width="29"
					height="15"
					x="2"
					y="22"
					maskUnits="userSpaceOnUse"
				>
					<path
						fill="#fff"
						fillRule="evenodd"
						d="M2.088 36.832h28.857V22.257H2.088v14.575z"
						clipRule="evenodd"
					></path>
				</mask>
				<g mask="url(#mask1_6085_45450)">
					<path
						stroke={color}
						d="M25.25 21.051a10.63 10.63 0 01-9.096 5.112c-3.75 0-7.048-1.937-8.94-4.863m.203-11.776a10.626 10.626 0 018.737-4.56c3.627 0 6.829 1.81 8.747 4.575"
					></path>
				</g>
				<mask
					id="mask2_6085_45450"
					style={{ maskType: "luminance" }}
					width="29"
					height="15"
					x="2"
					y="-5"
					maskUnits="userSpaceOnUse"
				>
					<path
						fill="#fff"
						fillRule="evenodd"
						d="M2.088-4.65h28.857V9.926H2.088V-4.649z"
						clipRule="evenodd"
					></path>
				</mask>
				<g
					fill={color}
					fillRule="evenodd"
					clipRule="evenodd"
					mask="url(#mask2_6085_45450)"
				>
					<path d="M22.688 8.198c.523.363 1.73-.45 2.695-1.814.965-1.364 1.323-2.764.8-3.127-.524-.362-1.73.45-2.696 1.814-.965 1.364-1.323 2.764-.8 3.127zM10.219 8.198c-.523.363-1.73-.45-2.695-1.814-.965-1.364-1.323-2.764-.8-3.127.523-.362 1.73.45 2.695 1.814.965 1.364 1.323 2.764.8 3.127zM16.447 6.061c-.64 0-1.157-1.35-1.157-3.015 0-1.666.518-3.016 1.157-3.016s1.157 1.35 1.157 3.016c0 1.665-.518 3.015-1.157 3.015z"></path>
				</g>
				<mask
					id="mask3_6085_45450"
					style={{ maskType: "luminance" }}
					width="29"
					height="15"
					x="2"
					y="-5"
					maskUnits="userSpaceOnUse"
				>
					<path
						fill="#fff"
						fillRule="evenodd"
						d="M2.088-4.65h28.857V9.926H2.088V-4.649z"
						clipRule="evenodd"
					></path>
				</mask>
				<g mask="url(#mask3_6085_45450)">
					<path
						stroke={color}
						d="M25.25 10.908a10.63 10.63 0 00-9.096-5.112c-3.75 0-7.048 1.937-8.94 4.862m.203 11.777a10.627 10.627 0 008.737 4.56c3.627 0 6.829-1.81 8.747-4.576"
					></path>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_6085_45450">
					<path fill="#fff" d="M0 0H28V32H0z" transform="translate(2)"></path>
				</clipPath>
			</defs>
		</svg>
	);
};

import { Paper, Stack, Typography } from "@mui/material";
import { ReferralForm } from "./components/referral-form";

export function Referral() {
	return (
		<Stack
			alignItems={"center"}
			component={Paper}
			spacing="2"
			sx={{ p: 4, minHeight: "100%" }}
		>
			<Typography variant="h5">ColorTokens Referral Program</Typography>
			<Typography variant="body1">
				Refer us to your friends and win cash rewards on their successful signup
			</Typography>

			<ReferralForm />
		</Stack>
	);
}

import { Stack } from "@mui/material";
import { useCommonStore } from "common/store";
import { DataGridStatusButton } from "modules/data-grid-status-button";
import { IconColor } from "modules/data-grid-status-button/DataGridStatusButton";
import {
	UserGroup,
	UserGroupStatusName,
	HostAndUserGroupStatusState,
} from "pages/users-segmentation/components/user-groups/types";

export interface UserGroupColumnRenderProps {
	readonly userGroup: UserGroup;
	readonly viewOnly?: boolean;
}

export function UserGroupStatus({
	userGroup,
	viewOnly,
}: UserGroupColumnRenderProps) {
	const facetState = useCommonStore(state => state.facets);
	const updateFacetOption = useCommonStore(state => state.updateFacetOption);

	const typeFacetName = "usergroupstatus";
	const typeOptionName = userGroup?.userGroupStatus?.toString();

	const isSelected =
		!viewOnly &&
		facetState?.get(typeFacetName)?.get(typeOptionName)?.isSelected;

	let iconColor = IconColor.Disabled;
	let text = "";
	let tooltipText = "Not available for user segmentation";

	switch (userGroup?.userGroupStatus) {
		case HostAndUserGroupStatusState.Active:
			iconColor = IconColor.Success;
			text = UserGroupStatusName.ACTIVE;
			tooltipText = "Available for user segmentation";
			break;

		case HostAndUserGroupStatusState.ActivePending:
			text = UserGroupStatusName.ACTIVATING;
			break;

		case HostAndUserGroupStatusState.InActivePending:
			text = UserGroupStatusName.DEACTIVATING;
			break;

		default:
			text = UserGroupStatusName.INACTIVE;
			break;
	}

	const handleButtonClick = (
		facetName: string,
		optionName: string,
		isSelected: boolean | undefined
	) => {
		if (!viewOnly) {
			updateFacetOption({
				facetName,
				optionName,
				value: !isSelected,
			});
		}
	};

	return (
		<Stack
			sx={{
				height: "100%",
				justifyContent: "space-evenly",
				width: "100%",
				overflow: "hidden",
				textOverflow: "ellipsis",
			}}
		>
			<Stack direction="row">
				<DataGridStatusButton
					tooltipText={tooltipText}
					displayText={text}
					viewOnly={viewOnly}
					color={iconColor}
					isSelected={isSelected}
					onClick={() =>
						handleButtonClick(typeFacetName, typeOptionName, isSelected)
					}
				/>
			</Stack>
		</Stack>
	);
}

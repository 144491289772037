import { Box, Stack, Typography } from "@mui/material";
import { AssetColumnRenderProps } from "pages/assets/types";

export function AssetVendor({ asset, viewOnly }: AssetColumnRenderProps) {
	if (!asset?.vendorInfo) {
		return <Box style={{ width: 70 }} />;
	}

	return (
		<Stack
			sx={{ width: !viewOnly ? "100%" : undefined }}
			direction={viewOnly ? "row" : "column"}
			alignItems={viewOnly ? "center" : undefined}
		>
			<Typography variant="body2">{asset?.vendorInfo}</Typography>
		</Stack>
	);
}

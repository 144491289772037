import { Box, Button, Tooltip } from "@mui/material";
import { useState } from "react";
import {
	PathRecommendationDrawer,
	PathRecommendationPageType,
} from "./PathRecommendationDrawer";
import { PortRecommendationDrawer } from "./PortRecommendationDrawer";

import { useCommonStore } from "common/store";
import { useCriteriaBuilder } from "modules/core/Core";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { useAssetDetailCommonStore } from "pages/asset/components/asset-detail/components/asset-review-drawer/store/common";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import {
	usePrivateNNCheckboxSelectionStore,
	usePublicNNCheckboxSelectionStore,
} from "./components/IPs-datagrid/store/useCheckboxSelectionStore";

export enum ButtonVariant {
	OUTLINED = "outlined",
	CONTAINED = "contained",
	TEXT = "text",
}

interface RecommendationButtonProps {
	criteria?: string;
	page: PathRecommendationPageType;
	policyId?: string;
	variant?: ButtonVariant;
	disabled?: boolean;
}

export const RecommendationsButton = ({
	criteria,
	page,
	policyId,
	variant,
	disabled,
}: RecommendationButtonProps) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const resetPublicSelection = usePublicNNCheckboxSelectionStore(
		useShallow(state => state.resetSelection)
	);
	const resetPrivateSelection = usePrivateNNCheckboxSelectionStore(
		useShallow(state => state.resetSelection)
	);

	const onViewLogsClick = () => {
		setIsDrawerOpen(true);
	};
	const onDrawerClick = () => {
		setIsDrawerOpen(false);
		resetPublicSelection();
		resetPrivateSelection();
	};

	const useFacetStoreLocal =
		page === PathRecommendationPageType.Asset
			? useAssetDetailCommonStore
			: useCommonStore;

	const facetState = useFacetStoreLocal(state => state.facets);
	let { data: assetMetadata } = useScopeMetadata({ scope: Scope.Asset });
	const facetAssetCriteria = useCriteriaBuilder("", facetState, assetMetadata);

	const pathStore = useMemo(() => {
		if (page === PathRecommendationPageType.Asset) {
			return useAssetDetailCommonStore;
		}
		if (page === PathRecommendationPageType.Paths) {
			return useCommonStore;
		}

		return undefined;
	}, [page]);

	const portStore = useMemo(() => {
		if (page === PathRecommendationPageType.Port) {
			return useCommonStore;
		}

		return undefined;
	}, [page]);

	return (
		<>
			{isDrawerOpen &&
				criteria &&
				(page === PathRecommendationPageType.Asset ||
					page === PathRecommendationPageType.Tags ||
					page === PathRecommendationPageType.Paths) && (
					<PathRecommendationDrawer
						isOpen={isDrawerOpen}
						onClose={onDrawerClick}
						criteria={criteria}
						page={page}
						policyId={policyId}
						useFacetStore={pathStore}
					/>
				)}

			{isDrawerOpen && criteria && page === PathRecommendationPageType.Port && (
				<PortRecommendationDrawer
					isOpen={isDrawerOpen}
					onClose={onDrawerClick}
					criteria={criteria}
					useFacetStore={portStore}
				/>
			)}
			<Tooltip title={window.getCTTranslatedText("disabledRecommendation")}>
				<Box sx={{ minWidth: 100 }}>
					<Button
						variant={variant ?? "contained"}
						disabled={
							!criteria ||
							disabled ||
							(page !== PathRecommendationPageType.Asset &&
								page !== PathRecommendationPageType.Tags &&
								facetAssetCriteria === "*")
						}
						onClick={() => onViewLogsClick()}
						sx={{ whiteSpace: "nowrap" }}
					>
						{window.getCTTranslatedText(
							page === PathRecommendationPageType.Asset
								? "Recommend"
								: "Recommendations"
						)}
					</Button>
				</Box>
			</Tooltip>
		</>
	);
};

import { Tooltip, Typography } from "@mui/material";

import { AuditLogColumnRenderProps } from "pages/monitoring/types";

export const AuditMessage = ({
	audit,
	viewOnly,
}: AuditLogColumnRenderProps) => {
	return (
		<Tooltip title={audit.message ?? ""}>
			<Typography variant="body2" noWrap>
				{audit.message ?? ""}
			</Typography>
		</Tooltip>
	);
};

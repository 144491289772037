import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
	Checkbox,
	FormControlLabel,
	IconButton,
	Menu,
	MenuItem,
	Stack,
} from "@mui/material";
import { useState } from "react";
import { CheckBoxWithOptionsProps } from "./types";

export const CheckBoxWithOptions = <T,>(props: CheckBoxWithOptionsProps<T>) => {
	const [selectedOption, setSelectedOption] = useState(
		props.defaultOption ?? null
	);
	const [anchor, setAnchor] = useState<Element>();

	return (
		<Stack
			direction={"row"}
			sx={{
				...props.sx,
				backgroundColor: Boolean(anchor)
					? theme =>
							theme.palette.mode === "dark"
								? theme.palette.grey[800]
								: theme.palette.grey.A200
					: "",
			}}
		>
			<FormControlLabel
				label={props.label}
				control={
					<Checkbox
						{...props.checkBoxProps}
						checked={props.value?.checked ?? false}
						onChange={e => {
							props.onChange(e, {
								checked: e.target.checked,
								option: props.checkBoxProps?.indeterminate
									? undefined
									: selectedOption,
							});
						}}
						sx={{ ...props.checkBoxProps?.sx, p: 0 }}
					/>
				}
				sx={{
					m: 0,
					px: 0.5,
					py: 1,
					"&:hover": {
						backgroundColor: theme =>
							theme.palette.mode === "dark"
								? theme.palette.grey[800]
								: theme.palette.grey.A200,
						cursor: "pointer",
					},
				}}
			/>

			<IconButton
				size="small"
				aria-label="options"
				{...props.dropdownIconButtonProps}
				sx={{
					...props.dropdownIconButtonProps?.sx,
					p: 0,
					borderRadius: 0,
				}}
				onClick={e => {
					setAnchor(e.currentTarget);
				}}
			>
				<ArrowDropDownIcon fontSize="small" />
			</IconButton>

			<Menu
				id={props?.menuProps?.id ?? "checkbox-menu"}
				anchorEl={anchor}
				keepMounted
				open={Boolean(anchor)}
				onClose={() => {
					setAnchor(undefined);
				}}
			>
				{props.options.map(o => {
					return (
						<MenuItem
							key={`${o.id}`}
							selected={selectedOption?.id === o.id}
							onClick={e => {
								setSelectedOption(o);
								props.onChange(e, {
									checked: true,
									option: o,
								});
								setAnchor(undefined);
							}}
						>
							{window.getCTTranslatedText(o.label)}
						</MenuItem>
					);
				})}
			</Menu>
		</Stack>
	);
};

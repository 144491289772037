import { Stack } from "@mui/material";
import { CTScopeAutoSuggest } from "modules/ct-scope-auto-suggest";
import { Suggestion } from "modules/ct-scope-auto-suggest/types";
import { Scope } from "modules/scope-metadata/types";
import { Appliance } from "pages/appliances/types";
import { useState } from "react";

interface HAStandbyProps {
	peerAppliances?: Array<string>;
	agentId?: string;
	setSelectedPeerAppliance: (value: Appliance) => void;
	disabled: boolean;
}

const minimumConfigVersion = "'harole' = NULL AND 'currentversion' > '3.0.625'";

export function HAStandby({
	agentId,
	peerAppliances,
	setSelectedPeerAppliance,
	disabled = false,
}: HAStandbyProps) {
	const [applianceSuggestion, setApplianceSuggestion] = useState<
		Suggestion | undefined
	>(
		peerAppliances?.length
			? {
					type: Scope.Appliance,
					value: peerAppliances[0],
				}
			: undefined
	);

	function handleUpdate(primaryAppliances?: Suggestion) {
		setApplianceSuggestion(primaryAppliances);
		setSelectedPeerAppliance(primaryAppliances?.rawData);
	}

	const additionalCriteria =
		(peerAppliances?.length ?? 0) > 0
			? `'agentid' in ('${peerAppliances?.[0]}')`
			: minimumConfigVersion;

	return (
		<Stack>
			<CTScopeAutoSuggest
				displayName={window.getCTTranslatedText("Peer Gatekeeper")}
				handleUpdate={handleUpdate}
				showLabel={true}
				selectedValue={applianceSuggestion}
				required={Boolean(false)}
				agentId={agentId}
				scopeConfig={[
					{ scope: Scope.Appliance, additionalCriteria: additionalCriteria },
				]}
				disabled={disabled}
			/>
		</Stack>
	);
}

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { alpha, styled } from "@mui/material/styles";

export const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `0`,
	"&:before": {
		display: "none",
	},
	"& .MuiButtonBase-root.MuiAccordionSummary-root": {
		minHeight: 22,
		maxWidth: "100%",
	},
	"& .MuiAccordionSummary-content": {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		maxWidth: "100%",
		minWidth: 0,
	},
	"&": {
		backgroundColor: "transparent",
	},
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	"&.Mui-expanded": {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
		minHeight: 0,
	},
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	background:
		theme.palette.mode === "dark"
			? `linear-gradient(180deg, ${alpha(
					theme.palette.common.white,
					0.08
				)} 0%, ${alpha(theme.palette.common.white, 0.08)} 100%), ${
					theme.palette.background.paper
				}`
			: "unset",
	backgroundColor:
		theme.palette.mode === "dark"
			? theme.palette.background.paper
			: theme.palette.background.default,
}));

import { IconsProps } from "./common";

export const TryDemo = ({ width = "20px", height = "20px" }: IconsProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 387 258"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.2">
				<path
					d="M322.5 225.75C340.237 225.75 354.589 211.238 354.589 193.5L354.75 32.25C354.75 14.5125 340.237 0 322.5 0H64.5C46.7625 0 32.25 14.5125 32.25 32.25V193.5C32.25 211.238 46.7625 225.75 64.5 225.75H0V258H387V225.75H322.5ZM64.5 32.25H322.5V193.5H64.5V32.25Z"
					fill="#02adb5"
				/>
				<rect x="91" y="52" width="205" height="121" fill="#00ACC1" />
				<path d="M224 113L179 138.981L179 87.0192L224 113Z" fill="#F44336" />
			</g>
		</svg>
	);
};

import {
	DataGridProProps,
	GridEventListener,
	GridRowId,
	GridRowParams,
	useGridApiRef,
} from "@mui/x-data-grid-pro";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { TEMPLATE_GROUP_TABLE_COLUMNS } from "./constant";

interface TemplateGroupTableProps {
	rows: Array<any>;
	getDetailPanelContent: (params: GridRowParams<any>) => ReactNode;
}

function TemplateGroupTable({
	rows,
	getDetailPanelContent,
}: TemplateGroupTableProps) {
	const apiRef = useGridApiRef();
	const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<
		GridRowId[]
	>([]);

	const DefaultDetailPanelExpandedRowIds = rows?.[0]?.id;

	const handleDetailPanelExpandedRowIdsChange = useCallback(
		(newIds: GridRowId[]) => {
			setDetailPanelExpandedRowIds(newIds);
		},
		[]
	);

	const handleRowClickEvent: GridEventListener<"rowClick"> = params => {
		apiRef?.current?.toggleDetailPanel(params?.id);
	};

	const getDetailPanelHeight = useCallback<
		NonNullable<DataGridProProps["getDetailPanelHeight"]>
	>(() => "auto" as const, []);

	useEffect(() => {
		if (DefaultDetailPanelExpandedRowIds) {
			setDetailPanelExpandedRowIds([DefaultDetailPanelExpandedRowIds]);
		}
	}, [DefaultDetailPanelExpandedRowIds]);

	return (
		<DataGrid
			rows={rows}
			rowCount={rows.length}
			columns={TEMPLATE_GROUP_TABLE_COLUMNS}
			detailPanelExpandedRowIds={detailPanelExpandedRowIds}
			onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
			getDetailPanelContent={getDetailPanelContent}
			getDetailPanelHeight={getDetailPanelHeight}
			onRowClick={handleRowClickEvent}
			apiRef={apiRef}
		/>
	);
}

export default TemplateGroupTable;

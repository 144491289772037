import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { AuthState } from "hooks/useAuthState";
import { useEffect, useMemo, useState } from "react";
import { useUserPermissionsStore } from "./store";

export function useUserPermissionsAPI(authState: AuthState) {
	return useQuery<Array<string>, Error>(["auth", "/auth/user/permissions"], {
		enabled: authState === AuthState.LoggedIn,
	});
}

interface UserPermissionProps {
	authState: AuthState;
	onLogout: Function;
}

export function useUserPermission({
	authState,
	onLogout,
}: UserPermissionProps) {
	const setPermissions = useUserPermissionsStore(state => state.setPermissions);
	const [userPermissions, setUserPermissions] = useState<
		Array<string> | undefined
	>(undefined);

	let { data, error } = useUserPermissionsAPI(authState);
	useEffect(() => {
		let axiosError = error as AxiosError<any>;
		if (axiosError?.response?.status === 403) {
			onLogout();
		}
	}, [error, onLogout]);

	const userPerm: any = useMemo(() => {
		if (!data || authState !== AuthState.LoggedIn) {
			return;
		}
		setUserPermissions(data);
		return data;
	}, [data, authState]);

	useEffect(() => {
		if (userPermissions && setPermissions) {
			if (Array.isArray(userPermissions)) {
				setPermissions(userPermissions);
			}
		}
	}, [setPermissions, userPermissions, userPerm]);

	return {
		userPermissions,
	};
}

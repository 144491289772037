import { Box, Stack } from "@mui/material";
import { AssetColumnRenderProps } from "pages/assets/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

interface AssetStatusProps extends AssetColumnRenderProps {
	statusKey: string;
	value: string;
	facetKey: string;
}

export function AssetSecurityStatus({
	asset,
	viewOnly,
	statusKey,
	value,
	facetKey,
}: AssetStatusProps) {
	if (!asset?.assetId || !statusKey) {
		return <Box style={{ width: 70 }} />;
	}

	return (
		<Stack
			sx={{ width: !viewOnly ? "100%" : undefined }}
			direction={viewOnly ? "row" : "column"}
			alignItems={viewOnly ? "center" : undefined}
		>
			<CTDatagridButton
				isDisabled={viewOnly === true}
				asText={viewOnly === true}
				facetName={facetKey}
				facetOption={value}
			>
				{window.getCTTranslatedText(value)}
			</CTDatagridButton>
		</Stack>
	);
}

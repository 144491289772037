import { useTheme } from "@mui/material";
import { IconsProps } from "./common";

export const UnsecuredIcon = ({
	width = "16px",
	height = "20px",
	color,
	x = 0,
	y = 0,
}: IconsProps) => {
	const theme = useTheme();
	color = color || theme.palette.text.disabled;
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			x={x}
			y={y}
		>
			<path
				d="M8 3.8147e-06L0 3V9.09C0 14.14 3.41 18.85 8 20C12.59 18.85 16 14.14 16 9.09V3L8 3.8147e-06ZM14 9.09C14 13.09 11.45 16.79 8 17.92C4.55 16.79 2 13.1 2 9.09V4.39L8 2.14L14 4.39V9.09Z"
				fill={color}
			/>
			<path d="M9 12H7V14H9V12Z" fill={color} />
			<path d="M9 5H7V10H9V5Z" fill={color} />
		</svg>
	);
};

import { useMutation } from "@tanstack/react-query";
import { CreateFirewallGroupRequest } from "../types";

function useCreateFirewallHostGroup() {
	return useMutation<any, Error, CreateFirewallGroupRequest>([
		"create-firewall-host-group",
		"edrs/crowdstrike/firewall-host-groups",
	]);
}
export default useCreateFirewallHostGroup;

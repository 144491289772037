import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	DialogActions,
	DialogContent,
	Drawer,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { CircularCenteredLoader } from "common/atoms/loader";
import { NoDataFound } from "common/molecules/no-data-found";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { DiagnosticOption } from "pages/agents/types";
import { useEffect, useState } from "react";
import { Toolbar } from "common/atoms/toolbar";

interface ApplianceDiagnosticDetailsDrawerProps {
	isOpen?: boolean;
	onClose?: () => void;
	label: string;
	areOptionsLoading: boolean;
	diagnosticOptions?: any[];
	downloadPrefix: string;
}

export const ApplianceDiagnosticDetailsDrawer = ({
	isOpen,
	onClose,
	label,
	areOptionsLoading,
	diagnosticOptions,
	downloadPrefix,
}: ApplianceDiagnosticDetailsDrawerProps) => {
	const [selectedOption, setSelectedOption] = useState<DiagnosticOption>();

	useEffect(() => {
		if (diagnosticOptions) {
			setSelectedOption(diagnosticOptions[0]);
		}
	}, [diagnosticOptions]);

	const onChange = (event: SelectChangeEvent) => {
		setSelectedOption(
			diagnosticOptions?.find(option => option.value === event.target.value)
		);
	};

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					minWidth: 500,
					overflow: "hidden",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}
				sx={{ mb: 2, m: 3 }}
			>
				<Typography variant="h5">
					<b>{window.getCTTranslatedText("Diagnostics")}</b>
				</Typography>
				<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
					<IconButton size="small" aria-label="close drawer" onClick={onClose}>
						<CloseIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</Stack>

			{diagnosticOptions && !diagnosticOptions.length ? (
				<NoDataFound text="No data" />
			) : (
				<>
					<DialogContent>
						{areOptionsLoading || !diagnosticOptions?.length ? (
							<CircularCenteredLoader />
						) : (
							<>
								<Stack>
									<FormControl variant="standard">
										<InputLabel id={label}>{label}</InputLabel>
										<Select
											labelId={label}
											value={selectedOption?.value ?? ""}
											label={window.getCTTranslatedText(label)}
											onChange={onChange}
										>
											{diagnosticOptions?.map(option => (
												<MenuItem key={option.value} value={option.value}>
													{window.getCTTranslatedText(option?.label)}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Stack>
								<Stack
									direction="column"
									alignItems="flex-start"
									spacing={1}
									sx={{ mt: 2 }}
								>
									<Box>
										<Typography variant="body2">
											{window.getCTTranslatedText("Size")}:{" "}
											{selectedOption?.fileSize}
										</Typography>
									</Box>
								</Stack>
							</>
						)}
					</DialogContent>
					<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
						<ToolbarAction
							loading={areOptionsLoading}
							save={() => {}}
							href={`${downloadPrefix}${selectedOption?.id}?download=true`}
							cancel={onClose}
							actionBtnText={"download"}
						></ToolbarAction>
					</DialogActions>
				</>
			)}
		</Drawer>
	);
};

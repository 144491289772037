import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import { Stack, Tooltip } from "@mui/material";
import { TemplateColumnRenderProps } from "../../types";

export function TemplatePendingFirewall({
	template,
	viewOnly,
}: TemplateColumnRenderProps) {
	const iconProps = { sx: { width: 24, height: 24, mt: 1.25 } };
	let icon = <AutoDeleteIcon {...iconProps}></AutoDeleteIcon>;
	let text = `AwaitingRemovePushToFirewall`;

	return template.templateUnassignmentsPendingFirewallSynchronize > 0 ? (
		<Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
			<Tooltip title={window.getCTTranslatedText(text)}>{icon}</Tooltip>
		</Stack>
	) : null;
}

import { Box, IconButton, Tooltip } from "@mui/material";
import { NetworkColumnRenderProps } from "pages/networks/types";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useState } from "react";
import { NetworkRemoveEntity } from "../network-remove-entity";

interface NetworkDialogProps extends NetworkColumnRenderProps {
	page?: string;
	policyId?: string;
}

export function RemoveNetworkDialog({
	network,
	page = "assets",
	policyId,
}: NetworkDialogProps) {
	const [allowRemove, setAllowRemove] = useState(false);

	const handleAllowRemove = (event: any, value: boolean) => {
		if (event) {
			event.stopPropagation();
		}
		setAllowRemove(value);
	};

	if (!network.namedNetworkId) {
		return <Box style={{ width: 100 }} />;
	}

	const isDisabled = network.assignedByTagBasedPolicy;

	return (
		<Box>
			{isDisabled ? (
				<Tooltip
					title={window.getCTTranslatedText(
						"Named network assigned by policies can not be removed"
					)}
				>
					<IconButton
						aria-label="Remove named network"
						title="Remove named network"
					>
						<DeleteOutlineIcon color="disabled" />
					</IconButton>
				</Tooltip>
			) : (
				<IconButton
					aria-label="Remove named network"
					title="Remove named network"
					onClick={$event => handleAllowRemove($event, true)}
				>
					<DeleteOutlineIcon color="action" />
				</IconButton>
			)}
			{allowRemove && (
				<NetworkRemoveEntity
					network={network}
					allowRemove={allowRemove}
					handleAllowRemove={handleAllowRemove}
					page={page}
					policyId={policyId}
				/>
			)}
		</Box>
	);
}

import { GridColDef } from "@mui/x-data-grid-pro";
import { Usergroup } from "./types";

export const PATH_USERGROUP_COLS: GridColDef<Usergroup>[] = [
	{
		field: "userName",
		headerName: "Username",
		width: 140,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
		sortable: false,
	},
	{
		field: "lastHitObserved",
		headerName: "Last Observed",
		width: 140,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
		sortable: false,
	},
];

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import {
	GridColDef,
	GridRenderCellParams,
	GridRowId,
} from "@mui/x-data-grid-pro";
import { DataGridButton } from "common/atoms/data-grid-button";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { useExportCSV } from "modules/export-csv/hooks";
import { PortAgg } from "modules/recommendation-workflows/hooks/usePortRecommendations";
import { Scope } from "modules/scope-metadata/types";
import numeral from "numeral";
import { AssignPort } from "pages/templates/components/template-form-drawer/components/template-form/components/assign-ports";
import {
	PortFormInt,
	TemplateFormMode,
} from "pages/templates/components/template-form-drawer/components/template-form/types";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import { useAggregateAPI } from "../path-recommendations/PathRecommendations";

interface PortRecommendationProps {
	setPortSelection: (selectionModel: PortAgg[]) => void;
	data: PortAgg[];
	isLoading: boolean;
}

const PORT_RECOMMENDATION_COLUMNS: GridColDef<PortAgg>[] = [
	{
		field: "portProtocol",
		headerName: "Port Details",
		flex: 1,
		valueGetter: params => `${params.row?.protocol}  ${params.row?.port}`,
		renderCell: params => {
			return (
				<Stack
					direction={"row"}
					alignItems="center"
					justifyContent="flex-start"
					sx={{ maxWidth: "100%" }}
				>
					{params.row?.protocol && (
						<DataGridButton
							asText={true}
							disabled={true}
							variant={"text"}
							color={"inherit"}
							onClick={() => null}
						>
							{params.row?.protocol}
						</DataGridButton>
					)}

					<DataGridButton
						asText={true}
						disabled={true}
						variant={"text"}
						color={"inherit"}
						onClick={() => null}
					>
						{params.row?.port}
					</DataGridButton>
				</Stack>
			);
		},
	},
	{
		field: "assetWithObservedActivityTrafficWithLastMonth",
		headerName: "Observed activity in last month",
		flex: 1,
		renderCell: params => {
			return (
				<Typography variant="body2">
					{`${numeral(
						params.row.assetWithObservedActivityTrafficWithLastMonth
					).format("0a")} ${window.getCTTranslatedText(
						pluralize(
							"asset",
							params.row.assetWithObservedActivityTrafficWithLastMonth
						)
					)}`}
				</Typography>
			);
		},
	},
	{
		field: "usedByAssets",
		headerName: "Used by",
		flex: 1,
		renderCell: params => {
			return (
				<Stack>
					<Typography variant="body2">
						{`${numeral(params.row.usedByAssets).format(
							"0a"
						)} ${window.getCTTranslatedText(
							pluralize("asset", params.row.usedByAssets)
						)}`}
					</Typography>

					<Typography
						variant="caption"
						color={() => {
							if (params.row.coverage >= 0.5 && params.row.coverage < 0.7) {
								return "info.main";
							}
							if (params.row.coverage >= 0.7) {
								return "success.main";
							}
							return undefined;
						}}
					>
						{`${numeral(params.row.coverage).format(
							"0%"
						)} ${window.getCTTranslatedText("coverage")}`}
					</Typography>
				</Stack>
			);
		},
	},
];

function getRowId(agg: PortAgg) {
	return `${agg.protocol}-${agg.port}`;
}

export const PortRecommendations = ({
	setPortSelection,
	data,
	isLoading,
}: PortRecommendationProps) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const [selection, setSelection] = useState<Array<GridRowId>>([]);

	const [recommendationList, setRecommendationList] = useState(data ?? []);
	const [defaultRules, setDefaultRules] = useState<PortFormInt[]>([]);

	useEffect(() => {
		setRecommendationList(data);
		setSelection(data?.filter(r => r.coverage >= 0.5)?.map(getRowId) ?? []);
	}, [data]);

	const updateSelections = (selectedPortIds: GridRowId[]) => {
		setSelection(selectedPortIds);
	};

	const columns = useMemo(() => {
		const deleteHeader: GridColDef<PortAgg>[] = [
			{
				align: "right",
				field: "action",
				headerName: "",
				sortable: false,
				width: 80,
				minWidth: 80,
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					if (!params.row?.showDelete) {
						return;
					}
					const handleDelete = (params: GridRenderCellParams) => {
						setRecommendationList(prev => {
							return prev.filter(
								row =>
									!(
										row.protocol === params.row.protocol &&
										row.port === params.row.port
									)
							);
						});
					};

					return (
						<Tooltip title={window.getCTTranslatedText("Remove")}>
							<IconButton
								aria-label="Remove"
								onClick={() => handleDelete(params)}
							>
								<DeleteOutlineIcon color={"action"} />
							</IconButton>
						</Tooltip>
					);
				},
			},
		];
		return [...PORT_RECOMMENDATION_COLUMNS, ...deleteHeader];
	}, []);

	useEffect(() => {
		setRecommendationList(prev => {
			const newRecommendation = defaultRules.reduce<PortAgg[]>(
				(acc, newPort) => {
					const isDuplicate = prev.some(
						_port =>
							_port.port === newPort.listenPort &&
							_port.protocol === newPort.listenPortProtocol
					);

					if (!isDuplicate) {
						acc.push({
							port: newPort.listenPort,
							protocol: newPort.listenPortProtocol,
							usedByAssets: 0,
							assetWithObservedActivityTrafficWithLastMonth: 0,
							coverage: 0,
							showDelete: true,
						});
					}

					return acc;
				},
				[]
			);
			setSelection(prevSelection => [
				...prevSelection,
				...newRecommendation.map(recommendation => getRowId(recommendation)),
			]);
			return [...newRecommendation, ...prev];
		});
	}, [defaultRules]);

	useEffect(() => {
		if (selection && selection?.length > 0) {
			const map = new Map(selection.map(id => [id, true]));

			setPortSelection(
				recommendationList.filter(port => map.get(getRowId(port)))
			);
		} else {
			setPortSelection([]);
		}
	}, [selection, recommendationList, setPortSelection]);

	const {
		triggerExportAsCsv,
		getExportStatus,
		getUrlToDownload,
		resetDownloadUrl,
	} = useExportCSV({
		useApi: useAggregateAPI,
		searchCriteria: "",
		page: 0,
		sort: [],
		sourceCriteria: "",
		destinationCriteria: "",
		scope: Scope.Port,
		frontendOnly: true,
	});

	const selectedRawData: Array<PortAgg> | undefined = useMemo(() => {
		let result = (recommendationList ?? [])?.filter((row: PortAgg) => {
			return selection.indexOf(getRowId(row)) !== -1;
		});
		return result?.length > 0 ? result : recommendationList;
	}, [selection, recommendationList]);

	return (
		<Stack
			alignItems="flex-start"
			spacing={0}
			direction={"column"}
			sx={{ width: "100%", flex: 1, overflow: "hidden" }}
		>
			<Stack
				direction={"row"}
				sx={{ width: "100%" }}
				justifyContent={"flex-end"}
			>
				<AssignPort
					containerProps={{
						variant: "outlined",
					}}
					portList={defaultRules}
					setPortList={setDefaultRules}
					mode={TemplateFormMode.ADD}
					hideSource={true}
					showInlineActionButton={true}
					hideProcess={true}
				/>
			</Stack>

			<Paper
				sx={{
					width: "100%",
					pt: 3,
					flex: 1,
					overflow: "hidden",
				}}
			>
				<DataGrid<PortAgg>
					checkboxSelection={userPermissions.has("UPDATE_TEMPLATE")}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						updateSelections(selectionModel);
					}}
					initialState={{
						sorting: {
							sortModel: [{ field: "usedByAssets", sort: "desc" }],
						},
					}}
					rowHeight={64}
					columns={columns}
					getRowId={getRowId}
					paginationMode="client"
					sortingMode="client"
					pagination
					isLoading={isLoading}
					rows={recommendationList || []}
					rowCount={recommendationList.length}
					triggerExportAsCsv={triggerExportAsCsv}
					getExportStatus={getExportStatus}
					getUrlToDownload={getUrlToDownload}
					resetDownloadUrl={resetDownloadUrl}
					frontendOnly={true}
					selectedRawData={selectedRawData}
				/>
			</Paper>
		</Stack>
	);
};

import { CTDrawer } from "common/molecules/drawer";
import { AssetAttributesBlock } from "modules/attributes-block";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { useTagPolicyListAPI } from "pages/tags/components/tag-policy-list/TagPolicyList";
import { TagPolicyDataGrid } from "pages/tags/components/tag-policy-list/components/TagPolicyDataGrid";
import { useTagPolicyFacetStore } from "pages/tags/components/tag-policy-list/store";
import { useEffect, useState } from "react";
import { useTagPolicyStore } from "pages/tags/components/tag-policy-list/store";

export function AssetTbp({
	criteria,
	count,
}: {
	criteria: string;
	count: number;
}) {
	const [showPolicies, setShowPolicies] = useState(false);
	const { data: metaData } = useScopeMetadata({ scope: Scope.TagPolicy });
	const updateMetadata = useTagPolicyFacetStore(state => state.updateMetadata);
	const apiRefreshRequest = useTagPolicyStore(state => state.apiRefreshRequest);

	useEffect(() => {
		updateMetadata(metaData);
	}, [metaData, updateMetadata]);

	const api = useTagPolicyListAPI();
	const mutate = api.mutate;

	useEffect(() => {
		if (!showPolicies) {
			return;
		}
		mutate({
			criteria,
			pagination: {
				offset: 0,
				limit: 100,
			},
		});
	}, [criteria, showPolicies, mutate, apiRefreshRequest]);

	return (
		<>
			<AssetAttributesBlock
				assign={() => {}}
				count={count ?? 0}
				canAssign={false}
				isLoading={api.isLoading}
				showAssigned={() => {
					setShowPolicies(true);
				}}
				title={"Segments"}
			/>

			<CTDrawer
				open={showPolicies}
				onClose={() => {
					setShowPolicies(false);
				}}
				title={window.getCTTranslatedText("Targeting Segments")}
			>
				<TagPolicyDataGrid
					rowCount={api.data?.metadata?.total ?? 0}
					rows={api.data?.items}
					hiddenColumns={HIDDEN_COLS}
					checkboxSelection={false}
				/>
			</CTDrawer>
		</>
	);
}

const HIDDEN_COLS = ["matchingAssets"];
